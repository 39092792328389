import { Component } from 'react'
import PropTypes from 'prop-types'
import './with-icon.scss'
import { Bem } from '@common/utils'
import { BUTTON_SIZES } from '../index'
import Icon from '../../icon'

const cn = new Bem({
  name: 'with-icon',
  prefix: 'pfx-'
})

export const withIcon = (WrappedComponent) => {
  return class WithIcon extends Component {

    static propTypes = {
      icon: PropTypes.string,
      iconSize: PropTypes.oneOf(['small', 'medium', 'large'])
    }

    static defaultProps = {
      iconSize: 'medium'
    }

    render () {
      const {
        icon,
        iconSize,
        children,
        ...passThroughProps
      } = this.props

      return (
        <WrappedComponent {...passThroughProps}>
          <Icon
            icon={icon}
            size={null}
            className={cn('icon', iconSize)}/>
          { children }
        </WrappedComponent>
      )
    }
  }
}
