const apiActionCreator = (apiCall, successAction, failureAction, inProgressAction, apiParams) => async dispatch => {
  if (inProgressAction) {
    dispatch({ type: inProgressAction })
  }

  try {
    let response = await apiCall(apiParams)
    dispatch({ type: successAction, payload: response.data })
  } catch (error) {
    dispatch({ type: failureAction, error: error })
  }
}

export default apiActionCreator
