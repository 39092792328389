import React, { useCallback, useEffect, useState } from 'react'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import { get } from '@common/utils'
import { resendEmailConfirmation } from '@api/user'
import { getBanners } from '@common/http'
import Linkton from '@components/linkton'
import { selectCurrentUser } from '@store/selectors/backendData'
import Icon from '@components/icon'
import DismissedBannersLocalStorage from './bannerLocalStorage'
import Typography from '@components/typography'
import dayjs from 'dayjs'
import * as styles from './Banner.module.scss'

const bannerIcons = {
  error: 'info',
  warning: 'info',
  info: 'info',
  promotion: 'Speaker'
}
const frequencyToUnitMap = new Map([
  ['yearly', 'years'],
  ['monthly', 'months'],
  ['weekly', 'weeks'],
  ['daily', 'days'],
  ['once', 'once'],
  ['disabled', undefined]
])
const isInternalBannerLink = (link) => link?.startsWith('snd://') ?? false

const Banner = (props) => {
  const { hasFlashMessage, currentUser } = props

  const [banners, setBanners] = useState([])

  const fetchBanners = useCallback(async () => {
    const { data } = await getBanners()
    const banners = data?.data ?? []

    const allDismissedBanners = DismissedBannersLocalStorage.all()

    const filteredBanners = banners.filter(({ attributes, id }) => {
      const { frequency = 'disabled' } = attributes
      const dismissed = allDismissedBanners[id]

      const unit = frequencyToUnitMap.get(frequency)

      if (unit === 'once') {
        return !dismissed
      }

      if (!dismissed || !unit) {
        return true
      }

      const { dismissedAt } = dismissed

      return dayjs(dismissedAt).add(1, unit).isBefore(Date.now())
    })

    setBanners(filteredBanners)
  }, [])

  // fetch banners
  useEffect(() => {
    if (currentUser) fetchBanners()
  }, [currentUser, fetchBanners])

  const banner = banners?.[0]

  const closeBanner = (hasPressedCta = false) => {
    if (!banner) return

    if (isInternalBannerLink(banner.attributes.ctaLink) && hasPressedCta) {
      switch (banner.attributes.ctaLink) {
        case 'snd://resend_verification_email': {
          resendEmailConfirmation()
          break
        }
        default: {
          break
        }
      }
    }

    const { frequency = 'disabled' } = banner.attributes
    if (frequencyToUnitMap.get(frequency)) {
      DismissedBannersLocalStorage.set(banner.id, Date.now())
    }

    setBanners([])
  }

  if (hasFlashMessage || !banner) return null

  const {
    severity = 'warning',
    header,
    body,
    ctaLabel = '',
    ctaLink = undefined,
    showDismissButton
  } = banner.attributes

  const showCtaButton = !!(ctaLink && ctaLabel)

  return (
    <div className={`${styles.container} ${styles[severity]}`}>
      <div className={styles.bannerContent}>
        <Icon className={styles.icon} size="16px" icon={bannerIcons[severity]} />
        <div className={styles.text}>
          <Typography variant="body" as="h3">
            {header}
          </Typography>
          <Typography variant="body-xs" as="p">
            {body}
          </Typography>
        </div>
      </div>
      <div className={styles.bannerActions}>
        {showCtaButton && (
          <Linkton
            color='black'
            type='outline'
            onClick={() => closeBanner(true)}
            tag='anchor'
            href={!isInternalBannerLink(ctaLink) ? ctaLink : undefined}
            target={!isInternalBannerLink(ctaLink) ? '_blank' : undefined}
          >
            {ctaLabel}
          </Linkton>
        )}
        {((showDismissButton || !showCtaButton) && (
          <Icon className={styles.icon} size="16px" icon="cancel" onClick={() => closeBanner()} />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    hasFlashMessage: get(state, 'flashMessage.show'),
    currentUser: selectCurrentUser(state)
  }
}

export default createProvider(connect(mapStateToProps)(Banner))
