import React, { useCallback, useState } from 'react'
import { rootCn, linkCn, paragraphCn, titleCn, buttonCn } from './SubscriptionPage.cn'
import './SubscriptionPage.style.scss'
import Linkton, { SIZE } from '@components/linkton'
import ChargebeeService from '@common/ChargebeeService'
import axios from '@api'
import JsonApiSerializer from 'jsonapi-serializer'
import { StudioConfiguration } from '@common/config'

const SubscriptionPage = ({ portalSection, subscription: initialSubscription, organizationId, ownerHasValidPaymentSource: ownerHasValidPaymentSourceInitial, hostedPage, portalSession, domain }) => {
  const [subscription, setSubscription] = useState(initialSubscription ?? {})
  const [ownerHasValidPaymentSource, setOwnerHasValidPaymentSource] = useState(ownerHasValidPaymentSourceInitial)

  const [isPollingForChange, setIsPollingForChange] = useState(false)

  const chargebeeService = new ChargebeeService({
    site: StudioConfiguration.chargebee.site,
    domain
  })

  const organizationSubscriptionToken = subscription.token
  const hasActiveSubscription = subscription.status === 'active'
  const hasSubscriptionOrPaymentMethod = hasActiveSubscription || ownerHasValidPaymentSource === true

  const pollForChange = useCallback(() => {
    setIsPollingForChange(true)
    const organizationUrl = StudioConfiguration.app.endpoints.makeOrganizationApiUrl(organizationId)

    const deserializer = new JsonApiSerializer.Deserializer({
      keyForAttribute: 'camelCase',
    })

    let interval = setInterval(async () => {
      let orgData = await axios.get(organizationUrl, {
        withCredentials: true
      })
      orgData = await deserializer.deserialize(orgData.data)
      if (!orgData.subscription) return

      const isSubStillSame = subscription.updatedAt === orgData.subscription.updatedAt &&
      subscription.hasScheduledCancellation === orgData.subscription.cancellationScheduled &&
      subscription.status === orgData.subscription.status

      if (!isSubStillSame) {
        clearInterval(interval)
        interval = undefined
        setIsPollingForChange(false)
        setSubscription(orgData.subscription)
        setOwnerHasValidPaymentSource(orgData.owner?.hasValidPaymentSource ?? false)
      }
    }, 2000)
  }, [organizationId, subscription])

  const openPortal = useCallback(() => {
    return chargebeeService.openPortal({ section: portalSection, session: portalSession.response.portalSession, token: organizationSubscriptionToken })
  }, [portalSection, organizationSubscriptionToken])

  const openCheckout = useCallback(() => {
    return chargebeeService.openCheckout({ hostedPage: hostedPage.response.hostedPage })
  }, [organizationSubscriptionToken, pollForChange])

  const onOpenChargebee = useCallback(async () => {
    if (hasSubscriptionOrPaymentMethod) {
      await openPortal()
    } else {
      await openCheckout()
      pollForChange()
    }
  }, [hasSubscriptionOrPaymentMethod])

  return (<div className={rootCn}>
    <h3 className={titleCn}>Buy Soundation Education for your school</h3>

    <p className={paragraphCn}>All students get Soundation Education for free; you only pay a license per teacher and year. Your school can pay via credit card, wire transfer (USD, EUR, GBP, or SEK), or check in the US.</p>

    <p className={paragraphCn}><a className={linkCn} href='https://soundationmusic.typeform.com/to/Bsw0fk9y' target='_blank'>Click here</a> to request a price quote for your purchase order.</p>
    <Linkton isDisabled={isPollingForChange} size={SIZE.LARGE} className={buttonCn} onClick={onOpenChargebee}>{hasSubscriptionOrPaymentMethod ? 'Manage subscription' : 'Buy using credit card or wire transfer'}</Linkton>

    <p className={paragraphCn}>Contact <a className={linkCn} href='mailto:edu@soundation.com'>edu@soundation.com</a> to pay via check (only US schools).</p>
  </div>)
}

export default SubscriptionPage
