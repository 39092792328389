import React, { useEffect, useState, useCallback } from 'react'
import Markdown from 'react-markdown'
import { uniqBy } from 'lodash'
import { getNewsItem, getNewsComments, denormalizeResponse, deleteComment, likeNewsItem, dislikeNewsItem } from '@common/http'
import { parseNewsItemContent } from '@common/utils'
import Spinner from '@components/spinner'
import UnorderedList from '@components/unordered-list'
import OrderedList from '@components/ordered-list'
import CompactPlayer from '@components/inline-player/compact'
import Linkton from '@components/linkton'
import CommunityTitle from '../community-title'
import CommunityTag from '../community-tag'
import CommunityPageStats from '../community-page-stats'
import CommunityComments from '../community-comments'
import * as styles from './CommunityNewsItemPage.module.scss'

const CommunityNewsItemPage = (props) => {
  const { newsId, isAdminUser } = props

  const [newsItem, setNewsItem] = useState(null)
  const [isLiked, setIsLiked] = useState(false)

  useEffect(() => {
    setIsLiked(newsItem?.currentUserLiked ?? false)
  }, [newsItem])

  // fetch news item
  useEffect(() => {
    if (newsId) {
      getNewsItem(newsId).then(({ data }) => {
        setNewsItem(denormalizeResponse(data))
      })
    }
  }, [newsId])

  const [comments, setComments] = useState([])

  const fetchPage = useCallback(async (page) => {
    const { data } = await getNewsComments(newsItem.id, { page, items: 20 })
    const denormalizedComments = denormalizeResponse(data)
    setComments((comments) => uniqBy([...comments, ...denormalizedComments], (item) => item.id))
    return { nextPage: data.meta?.next_page }
  }, [newsItem])

  const toggleLike = useCallback(() => {
    if (!newsItem) return

    setIsLiked(!isLiked)

    if (!isLiked) {
      likeNewsItem(newsItem.id)
    } else {
      dislikeNewsItem(newsItem.id)
    }
  }, [newsItem, isLiked])

  const handleDeleteComment = (commentId) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      deleteComment(commentId)
    }
  }

  if (!newsItem) return <Spinner />

  const parsed = parseNewsItemContent({ createdAt: newsItem.createdAt, news: newsItem })
  const { img, title, body, timestamp, stats, externalUrl, tag, audioPreviews } = parsed

  const renderItem = () => {
    const renderHeader = (screen) => (
      <div className={`${styles.header} ${screen === 'mobile' ? styles.headerMobile : styles.headerDesktop}`}>
        <CommunityTitle title={title} showOnDesktop />
        <CommunityPageStats stats={stats} />
      </div>
    )

    return (
      <div>
        {renderHeader('mobile')}

        {!!img && <img className={styles.image} src={img.urlXLarge} alt={img.alt} />}

        <div>
          {renderHeader('desktop')}
          {!!tag && <CommunityTag className={styles.urlTag} icon={tag.icon} text={tag.type} />}
          <small className={styles.timestamp}>{timestamp}</small>
          <div className={styles.content}>
            <Markdown
              components={{
                a: ({ node, ...props }) => <a {...props} className={styles.link} target='_blank' />,
                ul: ({ node, ...props }) => <UnorderedList {...props} />,
                ol: ({ node, ...props }) => <OrderedList {...props} />
              }}
            >
              {body}
            </Markdown>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.hero}>
        <div className={styles.contentContainer}>
          {renderItem()}

          {!!audioPreviews?.length && (
            <div className={styles.audioPreviews}>
              {audioPreviews.map((audioPreview) => (
                <div className={styles.playerRow}>
                  <CompactPlayer
                    key={audioPreview.url}
                    className={styles.player}
                    visible='always'
                    songUrl={audioPreview.url}
                    size='medium'
                    square
                    highlightOnPlay
                    layout='landing2020'
                    transparent
                  />
                  <span>{audioPreview.title}</span>
                </div>
              ))}
            </div>
          )}

          <div className={styles.actions}>
            {!!externalUrl && <Linkton className={styles.action} tag='anchor' type='outline' icon='Log-in' iconPlacement='left' href={externalUrl.url} target='_blank'>{externalUrl.text}</Linkton>}
            <CommunityLikeButton isLiked={isLiked} toggleLike={toggleLike} />
          </div>
        </div>
      </div>

      <div className={styles.comments}>
        <div className={styles.hero}>
          <div className={styles.contentContainer}>
            <h3 className={styles.commentsHeader}>Comments</h3>
            <CommunityWriteComment newsId={newsItem.id} />
            <CommunityComments
              comments={comments}
              onDeleteComment={handleDeleteComment}
              fetchPage={fetchPage}
              isAdmin={isAdminUser}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommunityNewsItemPage
