import '@styles/index.scss'
import './baseline.jsx'
import * as containers from './containers/index.jsx'
import * as components from './components/components.jsx'
import {
  assignToGlobalScope,
  canUseDOM
} from './common/utils'
import {
  dataLayerDidLoad,
  mergeWithFrontendLayer
} from './common/analytics'
import { trackFirstVisit, onDomContentLoaded, setValidateConfig } from './bootstrap'

setValidateConfig()

// These globals scope assignments are required to get server-side rendering working
// Not the pretties thing
assignToGlobalScope(containers)
assignToGlobalScope(components)

// These globals are needed to provided communication between old part of application and the react stack.
// Check _gtmhead.html.erb for reference.
assignToGlobalScope({ mergeWithFrontendLayer })
assignToGlobalScope({ dataLayerDidLoad })

// Triggered when browser app is loaded
if (canUseDOM()) {
  document.addEventListener('DOMContentLoaded', () => {
    onDomContentLoaded()
  })

  trackFirstVisit()
}
