import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import { ViewPager, Frame, Track, View } from 'react-view-pager'
import Icon from '../icon/index'
import Circle from '../circle/index'
import DotNav from '../dot-nav/index'

const cn = new Bem({
  name: 'references-carousel',
  prefix: 'pfx-'
})

const referenceShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  jobTitle: PropTypes.string,
  reference: PropTypes.string.isRequired
})

class ReferencesCarousel extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeSlide: 0
    }
  }

  renderSlideItem ({ name, jobTitle, reference }, i) {
    return (
      <View className={cn('slide')} key={i}>
        <h3 className={cn('slide-name')}>{name}</h3>
        <p className={cn('slide-job-title')}>{jobTitle}</p>
        <p className={cn('slide-paragraph')}>{reference}</p>
      </View>
    )
  }

  renderAvatar (url, idx) {
    return (<Circle className={cn('circle', { active: idx === this.state.activeSlide })} url={url} key={idx} />)
  }

  onViewChange (activeSlide) {
    this.setState({
      activeSlide
    })
  }

  render () {
    return (
      <div className={cn()}>
        <ViewPager className={cn('view-pager')}>
          <div className={cn('avatar')}>
            { this.props.references.map((item, idx) => this.renderAvatar(item.avatar, idx)) }
          </div>
          <div className={cn('arrow', 'left')}>
            <Icon icon='previous' size={80} onClick={() => this.track.prev()} />
          </div>
          <Frame className={cn('frame')}>
            <Track
              currentView={this.state.activeSlide}
              ref={c => this.track = c}
              infinite
              className={cn('track', null, 'pfx-grabbable')}
              onViewChange={(newView) => this.onViewChange(newView[0])}
            >
              { this.props.references.map(this.renderSlideItem) }
            </Track>
          </Frame>
          <div className={cn('arrow', 'right')}>
            <Icon icon='next' size={80} onClick={() => this.track.next()} />
          </div>
        </ViewPager>
        <DotNav
          className={cn('dot-nav')}
          handleChange={(newView) => this.onViewChange(newView)}
          index={this.state.activeSlide}
          count={this.props.references.length} />
      </div>
    )
  }
}

ReferencesCarousel.propTypes = {
  references: PropTypes.arrayOf(referenceShape)
}
ReferencesCarousel.defaultProps = {}

export default ReferencesCarousel
