'use client'

import React, { useEffect, useState } from "react";
import { MessageHandler } from "../../common/messageHandler";

const AuthAccess = ({ success, accountType }) => {
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    switch (success) {
      case "signed_in": {
        MessageHandler.postSignInMessage();
        break;
      }
      case "signed_up": {
        MessageHandler.postSignedUpMessage(accountType);
        break;
      }
      case "purchase": {
        MessageHandler.postPurchaseMessage();
        break;
      }
      case "consent_agreed": {
        MessageHandler.postConsentAgreedMessage();
        break;
      }
      default:
        setIsInvalid(true);
    }
  }, [success]);

  return (
    <div style={{ color: "white" }}>
      {isInvalid ? "Close the window and try again" : null}
    </div>
  );
};

export default AuthAccess;
