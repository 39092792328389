import React from 'react'
import BEM from 'react-bem-helper'
import PropTypes from 'prop-types'
import './style.scss'
import InlinePlayer from './inline-player'
import { get } from '../../common/utils'
import createProvider from '@store/provider'
import { connect } from 'react-redux'

const bem = BEM({
  prefix: 'pfx-',
  name: 'inline-player'
})

class CompactInlinePlayer extends React.Component {
  constructor (props) {
    super(props)
    this.state = { isPlaying: false }
  }

  handleOnPlayChange = ({ isPlaying }) => {
    this.setState({ isPlaying })
  };

  render () {
    const { isPlaying } = this.state

    const {
      size,
      layout,
      title,
      transparent,
      description,
      className,
      isDisabled,
      highlightOnPlay
    } = this.props

    return (
      <div {...bem('compact-container', ['size-' + size, layout], className)}>
        <div {...bem('compact-player', undefined)}>
          <InlinePlayer
            onPlayChange={this.handleOnPlayChange}
            songUrl={this.props.songUrl}
            fileName={this.props.fileName}
            id={this.props.id}
            isDisabled={isDisabled}
          />
        </div>

        {!!(title || description) && <div
          {...bem('compact-title-container', {
            playing: isPlaying,
            transparent
          })}
        >
          <div
            {...bem('compact-title', {
              playing: isPlaying,
              highlight: highlightOnPlay && isPlaying
            })}
          >
            {title}
            {description && (
              <span {...bem('compact-description')}>{description}</span>
            )}
          </div>
        </div>}
      </div>
    )
  }
}

CompactInlinePlayer.propTypes = {
  isPlaying: PropTypes.bool,
  isDisabled: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  songUrl: PropTypes.string,
  fileName: PropTypes.string,
  id: PropTypes.string,
  size: PropTypes.oneOf(['big', 'medium', 'small', 'medium-small']),
  layout: PropTypes.oneOf(['landing2020', 'landing2021']),
  /** Gives the text-container a transparent background */
  transparent: PropTypes.bool,
  className: PropTypes.string,
  highlightOnPlay: PropTypes.bool
}

CompactInlinePlayer.defaultProps = {
  size: 'big',
  layout: 'landing2020',
  hasBackground: true,
  highlightOnPlay: false
}

const mapState = (state, ownProps) => {
  return {
    isPlaying:
      get(state, 'player.isPlaying') &&
      get(state, 'player.songUrl') === ownProps.songUrl
  }
}
export default createProvider(connect(mapState)(CompactInlinePlayer, false))
