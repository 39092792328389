import { PureComponent } from 'react'
import BEM from 'react-bem-helper'
import PropTypes from 'prop-types'
import Image from '@components/image'
import './style.scss'
import InlinePlayer from './inline-player'

const bem = BEM({
  prefix: 'pfx-',
  name: 'inline-player'
})

export default class TileInlinePlayer extends PureComponent {
  render () {
    return (
      <div {...bem('tile-container', 'size-' + this.props.size, this.props.className)}>
        <Image alt='Player Title' src={this.props.image} {...bem('tile-image')} />
        <InlinePlayer songUrl={this.props.songUrl} id={this.props.id} />
        <div {...bem('tile-title')}>{ this.props.title }</div>
      </div>
    )
  }
}

TileInlinePlayer.propTypes = {
  image: PropTypes.string,
  imageType: PropTypes.oneOf(['src', 'svg']),
  title: PropTypes.string,
  id: PropTypes.string,
  songUrl: PropTypes.string,
  size: PropTypes.oneOf(['medium', 'small'])
}

TileInlinePlayer.defaultProps = {
  size: 'medium',
  imageType: 'src'
}
