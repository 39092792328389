'use client'

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gotoLocation, get, getEduHostnameByEnv } from "@common/utils";
import { LINKS } from "@common/consts";
import Logo from "../logo";
import Linkton, { SIZE, TAG, TYPE, HOVER, COLOR } from "../linkton";
import Link from "@components/link";
import UserDropdown from "@components/user-dropdown";
import HamburgerToggle from "@components/hamburger-toggle";
import createProvider from "../../store/provider";
import { closeMobileMenu, openMobileMenu } from "../../store/actions/app";
import UserNotification from "../../components/user-notification";
import { REFERRAL_ENABLED_PAYMENT_METHODS } from "../../common/consts";
import { isUserSet } from "@common/user";

import {
  menuLogoCn,
  menuContainerCn,
  menuLogoImgCn,
  mainMenuCn,
  signupMenuCn,
  megaMenuCnFn,
  hamburgerMenuCn,
  currentUserCn,
  userNotificationButtonCn,
  inviteFriendCn,
} from "./MegaMenu.cn.js";
import { mobileOverlayCn } from "./components/MobileMenu.cn";
import MobileMenu from "./components/MobileMenu";
import { StrapiContextProvider } from "../../contexts/StrapiContext";
import { MenuItems } from "./components/MenuItems";
import "./MegaMenu.style.scss";

class MegaMenu extends React.Component {
  toggleMobileMenu = () => {
    const { mobileOpen, openMobileMenu, closeMobileMenu } = this.props;
    if (mobileOpen) {
      closeMobileMenu();
    } else {
      openMobileMenu();
    }
  };

  renderMenuItems = (navigation) => {
    if (!navigation || navigation.length === 0) return null;

    return <MenuItems navigation={navigation} />;
  };

  getSignupUrl() {
    const { eduMode } = this.props
    if (eduMode) {
      return null
    } else {
      return LINKS.AUTH_PLANS
    }
  }

  render() {
    const { mobileOpen, currentUser, account, avatarUrl, navigation, eduMode, env } = this.props;
    const paymentMethod = get(account, "currentPaymentMethod");
    const referralEnabled =
      REFERRAL_ENABLED_PAYMENT_METHODS.indexOf(paymentMethod) !== -1;
    const isUserLoggedIn = !!isUserSet(currentUser);

    const menu = Array.isArray(navigation) ? navigation : [];
    const signUpUrl = this.getSignupUrl();
    const eduLink = getEduHostnameByEnv(env);

    return (
      <React.Fragment>
        <StrapiContextProvider navigation={navigation}>
          <header className={megaMenuCnFn({ user: isUserLoggedIn })}>
            <div className={menuContainerCn}>
              <div className={menuLogoCn}>
                <Logo
                  type="icon"
                  color="light"
                  onClick={() => gotoLocation("/")}
                  alt="Soundation Logo"
                  className={menuLogoImgCn}
                  edu={eduMode}
                />
              </div>
              <div className={mainMenuCn} data-turbolinks='false'>{this.renderMenuItems(menu)}</div>
              <div className={currentUserCn}>
                {currentUser && currentUser.emailBounced && (
                  <UserNotification type="error">
                    Hey! It looks like something is wrong with your email
                    address.
                    <br />
                    <Link
                      href="/account/manage"
                      className={userNotificationButtonCn}
                      type="secondary-full-inverse"
                      size="small"
                    >
                      Change email
                    </Link>
                  </UserNotification>
                )}

                {referralEnabled && (
                  <div
                    className={inviteFriendCn}
                    onClick={() => gotoLocation(LINKS.ACCOUNT_REFERRAL)}
                  >
                    Invite a friend
                  </div>
                )}

                {isUserLoggedIn && (
                  <UserDropdown
                    currentUser={currentUser}
                    avatarUrl={avatarUrl}
                    eduMode={this.props.eduMode}
                  />
                )}
              </div>
              {!isUserLoggedIn && (
                <div className={signupMenuCn}>
                  {!eduMode ? <Linkton
                    size={SIZE.MEDIUM}
                    hover={HOVER.OUTLINE}
                    tag={TAG.ANCHOR}
                    href={eduLink}
                    type={TYPE.OUTLINE}
                    color={COLOR.YELLOW}
                  >
                    Soundation Education
                  </Linkton> : null}
                  <Linkton
                    size={SIZE.MEDIUM}
                    hover={HOVER.OUTLINE}
                    tag={TAG.ANCHOR}
                    href={LINKS.AUTH_SIGN_IN}
                    type={TYPE.OUTLINE}
                  >
                    Log in
                  </Linkton>
                  {signUpUrl && (
                    <Linkton
                      size={SIZE.MEDIUM}
                      hover={HOVER.OUTLINE}
                      tag={TAG.ANCHOR}
                      href={signUpUrl}
                    >
                      Sign up
                    </Linkton>
                  )}
                </div>
              )}
              <div className={hamburgerMenuCn}>
                <HamburgerToggle
                  isOpen={mobileOpen}
                  onClick={this.toggleMobileMenu}
                />
              </div>
            </div>
          </header>

          <MobileMenu
            navigation={menu}
            toggleMenu={this.toggleMobileMenu}
            mobileOpen={mobileOpen}
            isUserLoggedIn={isUserLoggedIn}
          />
          {mobileOpen && (
            <div className={mobileOverlayCn} onClick={this.toggleMobileMenu} />
          )}
        </StrapiContextProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mobileLimitationSideOpen: get(state, "app.mobileLimitationSideOpen"),
    currentUser: get(state, "backendData.currentUser"),
    account: get(state, "backendData.account"),
    avatarUrl: get(state, "backendData.avatarUrl"),
    mobileOpen: get(state, "app.mobileMenuOpen"),
  };
};

const mapDispatchToProps = {
  openMobileMenu,
  closeMobileMenu,
};

MegaMenu.propTypes = {
  currentUser: PropTypes.object,
  avatarUrl: PropTypes.string,
  mobileOpen: PropTypes.bool,
  eduMode: PropTypes.bool,
};

MegaMenu.defaultProps = {
  currentUser: null,
  navigation: [],
};

export default createProvider(
  connect(mapStateToProps, mapDispatchToProps)(MegaMenu)
);
