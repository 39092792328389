import { WEBPACK_DEFINE } from "@common/consts";
import { RelationTypesIds } from "@common/strapi.consts";
import { AspectRatio } from "@common/imageDimensions";
import { orderBy } from "lodash";
import qs from "query-string";

export const isUrlAbsolute = (url = "") =>
  url.indexOf("://") > 0 || url.indexOf("//") === 0;

export const getStrapiMediaUrl = ({ url, ...rest } = {}) => {
  if (!url) return undefined;

  if (isUrlAbsolute(url)) {
    return url;
  }

  // There, probably won't ever be any relative media urls in strapi when
  // env is not LOCAL: But If there will be, this code is in place to handle it.
  if (WEBPACK_DEFINE.ENV !== "LOCAL") {
    return `${WEBPACK_DEFINE.STRAPI_URL}${url}`;
  }
  // FIXME Strapi is currently running on docker network and is not accessible from strapi:1337
  // strapi:1337 does not exist on the host machine so we need to change to localhost
  return `//localhost:${WEBPACK_DEFINE.STRAPI_PORT}${url}`;
};

export const getBreakPointFromIndex = (
  breakpointIndex = 1,
  orientation = "portrait"
) => {
  if (breakpointIndex < 2) {
    return "xlarge";
  }

  if (breakpointIndex < 3) {
    return "large";
  }

  if (breakpointIndex < 4 || orientation === "landscape") {
    return "medium";
  }

  return "small";
};

export const isMediaVideo = ({ mime }) => {
  return mime?.startsWith("video");
};

export const getStrapiImage = (original = {}, { thumbnail = false } = {}) => {
  const { formats, alternativeText, url, width, height } = original || {};
  if (!url) {
    return {};
  }

  const originalSrc = getStrapiMediaUrl({ url });

  const formatEntries = Object.values(formats || {}) || [];

  const allFormats = orderBy(
    [...(formatEntries || []), original],
    (a) => a?.width || 0,
    "asc"
  ).filter(({ width }) => !!width);

  const sources = allFormats.map((format) => {
    // find image for higher DPI displays
    const doubleWidth = allFormats.find(
      ({ width }) => width >= format.width * 2 || width > format.width
    );

    // find image for higher DPI displays
    const tripleWidth = allFormats.find(
      ({ width }) => width >= format.width * 3
    );

    const srcset = [
      getStrapiMediaUrl(format),
      ...(doubleWidth ? [getStrapiMediaUrl(doubleWidth)] : []),
      ...(tripleWidth ? [getStrapiMediaUrl(tripleWidth)] : []),
    ]
      .filter((src) => (src?.length || 0) > 0)
      .map((src, index) => `${src} ${index + 1}x`)
      .join(", ");

    return {
      srcset: srcset,
      media: `(max-width: ${format.width}px)`,
      size: {
        width: format.width,
        height: format.height,
      },
      original: getStrapiMediaUrl(format),
      type: format.mime,
    };
  });

  return {
    alt: alternativeText,
    src: originalSrc,
    // currently we do not provide a backup url from strapi
    fallbackSrc: originalSrc,
    aspectRatio:
      width && height ? AspectRatio.fromDimensions({ height, width }) : null,
    sources,
    size: {
      height,
      width,
    },
  };
};

/**
 * Gets data from the collection type Audio File
 */
export const getStrapiAudio = (file = {}) => {
  const { audio, name, thumbnail } = file || {};

  if (!audio) return null;

  return {
    ...file,
    name: name ?? audio.caption,
    url: getStrapiMediaUrl(audio),
    thumbnail: thumbnail ? getStrapiImage(thumbnail) : undefined,
  };
};

/**
 * Strapi api response data structure is rather complex and in relations
 * contains a lot of data.attribute properties. This is annoying to work with.
 *
 * Therefore we remove the data.attribute properties from the object
 *
 * @param {*} data
 */
export const normalizeStrapiResponse = (data) => {
  const recurse = (strapiData) => {
    const isObject = (value) =>
      Object.prototype.toString.call(value) === "[object Object]";
    const isArray = (value) =>
      Object.prototype.toString.call(value) === "[object Array]";

    const flatten = (value) => {
      if (!value.attributes) return value;
      return Object.assign({ id: value.id }, value.attributes);
    };

    if (isArray(strapiData)) {
      return strapiData.map((item) => recurse(item));
    }

    if (isObject(strapiData)) {
      if (isArray(strapiData.data)) {
        strapiData = Object.assign([], strapiData.data);
      } else if (isObject(strapiData.data)) {
        strapiData = flatten(Object.assign({}, strapiData.data));
      } else if (strapiData.data === null) {
        strapiData = null;
      } else {
        strapiData = flatten(strapiData);
      }

      for (const key in strapiData) {
        strapiData[key] = recurse(strapiData[key]);
      }

      return strapiData;
    }

    return strapiData;
  };
  return recurse(Object.assign({}, data));
};

export const isComponent = (data, uid) => {
  if (!uid || !data) return false;
  return data.__component === uid;
};

export const getTemplateLink = (templateId, options = {}) => {
  const url = new URL(
    `${WEBPACK_DEFINE.STUDIO_URL}/templates/${templateId}/new-project`
  );

  const filteredOptions = Object.fromEntries(
    Object.entries(options).filter(([_, v]) => v !== null && v !== undefined)
  );

  // There is a missmatch between the url query param and the options used in the studio
  // It's called featurePackId in strapi but packIdToLoad in the studio
  if (filteredOptions && "featurePackId" in filteredOptions) {
    filteredOptions.packIdToLoad = filteredOptions.featurePackId;
    delete filteredOptions.featurePackId;
  }

  url.search = qs.stringify(filteredOptions, {
    skipNull: true,
    skipEmptyString: true,
  });

  return `${url}`;
};

export const getCtaUrlFromDynamicLink = (link, navigation) => {
    let url = null

    if (isComponent(link, RelationTypesIds.page)) {
      const { node } = navigation.searchById(link.page)
      url = navigation.getPath(node)
    }

    if (isComponent(link, RelationTypesIds.link)) {
      url = link.externalLink.url
    }

    return url
}
/** Returns true if the site-configuration for the component/preview matches the site config, also if no site-configuration is set for the component at all */
export const isEntityMatchingSiteConfig = (entity, siteConfig) => {
  const noSiteConfigSetForEntity = !entity.s_c?.name
  return noSiteConfigSetForEntity || (entity.s_c.name === siteConfig.name)
};
