import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { created, modified } from '@store/actions/group'
import GroupForm from '@components/group-form'
import { createGroup, editGroup, getSignatureForFileUpload, uploadToS3, denormalizeResponse } from '@common/http'
import { get } from '@common/utils'
import * as styles from './CommunityGroupForm.module.scss'

export class CommunityGroupForm extends Component {
  static propTypes = {
    createGroupAllowed: PropTypes.bool,
    type: PropTypes.oneOf(['newGroupHollow', 'newGroupGradient', 'editGroupGrey', 'editGroupOutline', 'editGroupFull']),
    group: PropTypes.shape({
      data: PropTypes.shape({
        id: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        attributes: PropTypes.shape({
          name: PropTypes.string.isRequired,
          kind: PropTypes.string.isRequired,
          privacySetting: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          groupLink: PropTypes.string.isRequired,
          sidebarTracks: PropTypes.string.isRequired,
          trackSorting: PropTypes.string.isRequired,
          commentsSetting: PropTypes.string.isRequired,
          enableFollowing: PropTypes.bool.isRequired,
          enableVoting: PropTypes.bool.isRequired,
          membersCanPostTracks: PropTypes.bool.isRequired,
          pictureUrl: PropTypes.string
        })
      })
    }),
    created: PropTypes.func
  }

  static sortByOptions = [
    { value: 'date_posted_asc', label: 'Date posted ascending' },
    { value: 'date_posted_desc', label: 'Date posted descending' },
    { value: 'votes', label: 'Number of votes' },
    { value: 'shuffle', label: 'Shuffle' }
  ]

  static numberOfTracksOptions = [
    { value: '0', label: '0' },
    { value: '3', label: '3' },
    { value: '5', label: '5' },
    { value: '10', label: '10' }
  ]

  static commentsOptions = [
    { value: 'anybody', label: 'On' },
    { value: 'off', label: 'Off' },
    { value: 'members', label: 'Members only' }
  ]

  static additionalSettingsOptions = [
    { value: 'membersCanPostTracks', label: 'Members can post tracks' },
    { value: 'enableFollowing', label: 'People can follow tracks' }
  ]

  static privacyOptions = [
    { value: 'closed', label: 'Private - Only members can view the group' },
    { value: 'viewable', label: 'Public - Anybody can view but invite only' },
    { value: 'joinable', label: 'Open - Anybody can join' }
  ]

  static categoryOptions = [
    { value: 'general', label: 'General' },
    { value: 'music_genre', label: 'Music Genre' },
    { value: 'contest', label: 'Contest' },
    { value: 'band', label: 'Band' },
    { value: 'educational', label: 'Educational' }
  ]

  constructor (props) {
    super()

    this.onUploadRequest = this.onUploadRequest.bind(this)
    this.onUploadRejected = this.onUploadRejected.bind(this)

    const { group } = props

    // when editing an existing group
    if (group) {
      const additionalSettings = []
      if (group.data.attributes.enableFollowing) {
        additionalSettings.push('enableFollowing')
      }
      if (group.data.attributes.membersCanPostTracks) {
        additionalSettings.push('membersCanPostTracks')
      }
      this.state = {
        isFormOpen: true,
        name: group.data.attributes.name,
        category: group.data.attributes.kind,
        privacy: group.data.attributes.privacySetting,
        description: group.data.attributes.description,
        groupLink: group.data.attributes.groupLink,
        numberOfTracks: group.data.attributes.sidebarTracks,
        sortBy: group.data.attributes.trackSorting,
        comments: group.data.attributes.commentsSetting,
        pictureUrl: group.data.attributes.pictureUrl,
        additionalSettings,
        id: group.data.attributes.groupLink,
        errors: null,
        uploaded: false,
        uploadProgress: 0
      }
    } else {
      // when creating a new group
      this.state = {
        name: '',
        category: 'general',
        privacy: 'closed',
        description: '',
        groupLink: '',
        numberOfTracks: '5',
        sortBy: 'date_posted_desc',
        comments: 'anybody',
        pictureUrl: null,
        additionalSettings: ['membersCanPostTracks', 'enableFollowing'],
        uploaded: false,
        uploadProgress: 0,
        uploadResult: null,
        id: null,
        errors: null,
        filename: null,
        isLoading: false
      }
    }
  }

  render () {
    const { uploaded, uploadProgress, id, errors, isLoading } = this.state

    return <div className={styles.container}>
      <div className={styles.innerContainer}>
        <h2 className={styles.title}>Create group</h2>
        <GroupForm
          image={null}
          name={this.state.name}
          category={this.state.category}
          privacy={this.state.privacy}
          description={this.state.description}
          categoryOptions={CommunityGroupForm.categoryOptions}
          privacyOptions={CommunityGroupForm.privacyOptions}
          groupLink={this.state.groupLink}
          numberOfTracks={this.state.numberOfTracks}
          numberOfTracksOptions={CommunityGroupForm.numberOfTracksOptions}
          sortBy={this.state.sortBy}
          sortByOptions={CommunityGroupForm.sortByOptions}
          commentsOptions={CommunityGroupForm.commentsOptions}
          comments={this.state.comments}
          pictureUrl={this.state.pictureUrl}
          additionalSettingsOptions={CommunityGroupForm.additionalSettingsOptions}
          additionalSettings={this.state.additionalSettings}
          onChange={change => this.onChange(change)}
          onClose={() => this.closeForm()}
          onSubmit={() => this.submitForm()}
          formHeader={id === null ? 'Create a group' : 'Edit group'}
          submitButtonLabel={id === null ? 'Create a group' : 'Save changes'}
          uploaded={uploaded}
          uploadProgress={uploadProgress}
          onUploadRequest={this.onUploadRequest}
          onUploadRejected={this.onUploadRejected}
          errors={errors}
          isLoading={isLoading}
        />
      </div>
    </div>
  }

  async onUploadRequest (file) {
    this.setState({
      uploaded: false,
      uploadProgress: 0,
      isLoading: true,
      uploadResult: null,
      filename: file.name
    })

    const { fields, headers, method, url, filename } = await getSignatureForFileUpload(file.name, file.type)
    const onProgress = (progressEvent) => {
      this.setState({
        uploadProgress: progressEvent.loaded / progressEvent.total
      })
    }
    await uploadToS3({ fields, headers, method, url, file, onProgress })

    const uploadResult = JSON.stringify({
      id: filename,
      metadata: {}
    })

    // give it some time to show the progress bar
    setTimeout(() => {
      this.setState({
        uploaded: false, // setting uploaded to false and filename to null to bring uploader to initial state but with
        filename: null, // image preview
        uploadProgress: 0,
        isLoading: false,
        uploadResult,
        pictureUrl: file.preview
      })
    }, 500)
  }

  async onUploadRejected () {
    await this.setState({
      uploaded: false,
      uploadProgress: 0,
      uploadResult: null
    })
  }

  onChange (change) {
    let errorsChanged = false

    const errors = Object.keys(change).reduce((memo, value) => {
      if (memo[value]) {
        delete memo[value]
        errorsChanged = true
      }
      return memo
    }, Object.assign({}, this.state.errors))

    const newState = Object.assign({}, change)
    if (errorsChanged) {
      Object.assign(newState, { errors })
    }

    this.setState(newState)
  }

  async submitForm () {
    const { state } = this
    const requestData = { group: {
      name: state.name,
      kind: state.category,
      description: state.description,
      image: state.uploadResult,
      privacy_setting: state.privacy,
      comments_setting: state.comments,
      members_can_post_tracks: state.additionalSettings.includes('membersCanPostTracks'),
      enable_following: state.additionalSettings.includes('enableFollowing'),
      sidebar_tracks: state.numberOfTracks,
      track_sorting: state.sortBy,
      group_link: state.groupLink
    } }

    await this.setState({
      isLoading: true
    })

    try {
      if (state.id) {
        const result = await editGroup(state.id, requestData)
        const group = denormalizeResponse(result.data)
        this.props.modified(group)
      } else {
        const result = await createGroup(requestData)
        const group = denormalizeResponse(result.data)
        this.props.created(group)
      }
    } catch (rejected) {
      const formErrors = get(rejected, 'response.data.errors')
      if (formErrors) {
        this.setState({
          errors: formErrors
        })
      }
    } finally {
      await this.setState({
        isLoading: false
      })
    }
  }
}

const mapStateToProps = state => {
  return {
    createGroupAllowed: get(state, 'backendData.acl.creatingGroupsPermitted', false)
  }
}

export default createProvider(connect(mapStateToProps, { created, modified })(CommunityGroupForm))
