import { useCallback } from 'react'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import { progressChange } from '@store/actions/player'
import * as styles from './Waveform.module.scss'

/** Waveform with progress tracking */
export function Waveform ({ directWaveformUrl, duration, progress, fetchProgress, onProgressChange, showFetchProgress = true }) {
  const handleWaveformMouseDown = useCallback((e) => {
    if (!duration) return

    const waveform = e.currentTarget

    const xpos = e.nativeEvent.offsetX === undefined ? e.nativeEvent.layerX : e.nativeEvent.offsetX
    const p = xpos / waveform.offsetWidth
    const currentTime = p * duration

    onProgressChange(currentTime)

    waveform.onmousemove = (e) => {
      const xpos = e.offsetX === undefined ? e.layerX : e.offsetX
      const p = xpos / waveform.offsetWidth
      const currentTime = p * duration
      onProgressChange(currentTime)
    }
    waveform.onmouseup = (e) => {
      waveform.onmousemove = null
    }
  }, [duration, onProgressChange])

  return (
    <div
      className={styles.waveform}
      style={{
        maskImage: 'url("' + directWaveformUrl + '")',
        WebkitMaskImage: 'url("' + directWaveformUrl + '")'
      }}
      onMouseDown={handleWaveformMouseDown}
    >
      {!!showFetchProgress && <div className={styles.fetchProgress} style={{ width: (fetchProgress * 100) + '%' }} />}
      <div className={styles.progress} style={{ width: (progress * 100) + '%' }} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    progress: state.player.progress,
    fetchProgress: state.player.fetchProgress
  }
}
const mapDispatchToProps = {
  onProgressChange: progressChange
}
const WaveFormConnected = createProvider(
  connect(mapStateToProps, mapDispatchToProps)(Waveform)
)
export default WaveFormConnected
