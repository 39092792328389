'use client'

import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector, connect } from 'react-redux'
import { selectCurrentUser } from '@store/selectors/backendData'
import { Bem } from "@common/utils";
import { isEntityMatchingSiteConfig } from "@common/strapi.helpers";
import BlockArea, { BlockAreaPropTypes } from "../../components/block-area";
import "./StrapiLayoutPage.style.scss";
import { StrapiContextProvider } from "../../contexts/StrapiContext";
import createProvider from "../../store/provider";
import { Logger } from "../../common/logger";

const cn = new Bem({
  name: "strapi-layout-page",
  prefix: "pfx-",
});

const StrapiLayoutPageLogger = new Logger({
  name: "StrapiLayoutPage",
});

const StrapiLayoutPage = ({ page, navigation, config }) => {
  StrapiLayoutPageLogger.log("Rendering page", { page, navigation, config });

  // filter out blocks destined for a different env
  const siteSpecificBlocks = useMemo(() => page.blocks.filter((b) => isEntityMatchingSiteConfig(b, config)), [page.blocks, config])

  const currentUser = useSelector(selectCurrentUser)

  return (
    <StrapiContextProvider navigation={navigation} config={config} page={page}>
      <article className={cn('', { padding: !currentUser })}>
        <BlockArea blocks={siteSpecificBlocks} />
      </article>
    </StrapiContextProvider>
  );
};

StrapiLayoutPage.propTypes = {
  page: PropTypes.shape({
    blocks: BlockAreaPropTypes.blocks.isRequired,
    type: PropTypes.oneOf(["article", "article overview", "home"]).isRequired,
    // Not sure if we need this in react since the seo will probably be rendered completely by the server
    seo: PropTypes.shape({}).isRequired,
    __soundation: PropTypes.shape({
      apiPath: PropTypes.string.isRequired,
      apiContentTypePath: PropTypes.string.isRequired,
      contentType: PropTypes.string.isRequired,
    }),
  }),
  navigation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  config: PropTypes.shape({}).isRequired,
};

const ConnectedStrapiLayoutPage = createProvider(connect(null)(StrapiLayoutPage));
export default ConnectedStrapiLayoutPage;
