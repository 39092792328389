'use client'

import PropTypes from "prop-types";
import React, { createContext, useCallback, useMemo } from "react";
import { Logger } from "../../common/logger";
import {
  getCrumbsFromNavigation,
  findNavigationNode,
  searchById,
  getPath
} from "./StrapiContext.utils";

const StrapiContextLogger = new Logger({
  name: "StrapiContext",
});

const StrapiContext = createContext({
  navigation: {
    data: [],
    breadcrumbs: [],
    search: () => null,
    getPath: () => "",
    searchById: () => null,
  },
  templates: {
    empty: {
      name: null,
      templateId: null,
      options: {}
    },
  },
});

const StrapiContextProvider = ({ navigation, config, children, page }) => {
  const search = useCallback(
    (matcher) => {
      if (!matcher) return null;
      return findNavigationNode(navigation, matcher);
    },
    [navigation]
  );

  // for page only!
  const breadcrumbs = useMemo(() => {
    const path = page ? getPath(navigation.find((n) => n?.related?.id === page?.id)) : undefined
    return path ? getCrumbsFromNavigation(navigation, path) : []
  }, [navigation]);

  StrapiContextLogger.log("StrapiContextProvider", {
    navigation,
    breadcrumbs,
    config,
  });

  return (
    <StrapiContext.Provider
      value={{
        navigation: {
          data: navigation,
          breadcrumbs,
          getPath,
          search,
          searchById: ({ id, uids }) => searchById({ id, uids, navigation })
        },
        templates: {
          empty: config?.emptyTemplate,
        },
        config
      }}
    >
      {children}
    </StrapiContext.Provider>
  );
};

StrapiContextProvider.propTypes = {
  children: PropTypes.node,
  navigation: PropTypes.arrayOf(PropTypes.shape({})),
};

export { StrapiContextProvider, StrapiContext };
