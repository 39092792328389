import React from 'react'
import {withBindHeightToWidth} from "../carousel/components/with-bind-height-to-width";
import {withPlayer} from "../carousel/components/with-player";
import {Bem} from "../../common/utils";
import './album-cover.scss'

const cn = new Bem({
  name: 'album-cover',
  prefix: 'pfx-'
})

class AlbumCover extends React.Component {
  render() {
    const {coverUrl, className} = this.props;
    return (
      <div
        className={cn(null, null, className)}
        style={{
          backgroundImage: 'url(' + coverUrl + ')',
        }}>
      </div>
    )
  }
}

const AlbumCoverResponsive = withBindHeightToWidth(AlbumCover)
const AlbumCoverWithPlayer = withPlayer(AlbumCover)


export {
  AlbumCover,
  AlbumCoverResponsive,
  AlbumCoverWithPlayer,
}

export default withBindHeightToWidth(withPlayer(AlbumCover))