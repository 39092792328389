// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "1260px";
var _3 = "1020px";
var _4 = "668px";
var _5 = "1550px";
var _6 = "pfx-Spinner-module__container--BUhNh";
var _7 = "1140px";
var _8 = "940px";
var _9 = "100%";
var _a = "15px";
var _b = "620px";
var _c = "#f53535";
var _d = "rgba(0,0,0,.2)";
var _e = "rgba(255,255,255,.38)";
var _f = "pfx-Spinner-module__disabled-link--zoYvS";
var _10 = ".38";
var _11 = "rgba(255,255,255,.2)";
var _12 = "#0025ff";
var _13 = "#f60";
var _14 = "#41ead4";
var _15 = "#2cdd78";
var _16 = "#66ab23";
var _17 = "#f5f5f5";
var _18 = "#c3c3c3";
var _19 = "#ececec";
var _1a = "#252525";
var _1b = "#272727";
var _1c = "#c6c6c5";
var _1d = "#333";
var _1e = "#3c3c3c";
var _1f = "#9c9b9b";
var _20 = "#8c8c8c";
var _21 = "#6f6f6e";
var _22 = "#3c3c3b";
var _23 = "#373737";
var _24 = "#6a6a69";
var _25 = "#6f6f6f";
var _26 = "#9f9e9e";
var _27 = "#282828";
var _28 = "#828181";
var _29 = "#1d1d1d";
var _2a = "#979797";
var _2b = "#9c9b9b";
var _2c = "#a7a7a6";
var _2d = "#c6c6c5";
var _2e = "#d8d8d7";
var _2f = "#dddddc";
var _30 = "72px";
var _31 = "40px";
var _32 = "36px";
var _33 = "25px";
var _34 = "18px";
var _35 = "13px";
var _36 = "#0025ff";
var _37 = "rgba(255,255,255,.12)";
var _38 = "#6535f5";
var _39 = "2px";
var _3a = "56px";
var _3b = "56px";
var _3c = "#41ead4";
var _3d = "#f60";
var _3e = "#f83600";
var _3f = "#881f00";
var _40 = "18px";
var _41 = "15px";
var _42 = "red";
var _43 = "pfx-Spinner-module__rotation--lXbrT";
var _44 = "pfx-Spinner-module__spinner--VKMrR";
var _45 = "13px";
var _46 = "rgba(255,255,255,.07)";
var _47 = "#35465c";
var _48 = "#55acee";
var _49 = "#fff";
var _4a = "#ffce36";
var _4b = "#ffcd00";
var _4c = "red";
export { _1 as "black", _2 as "breakLarge", _3 as "breakMedium", _4 as "breakSmall", _5 as "breakVLarge", _6 as "container", _7 as "containerLarge", _8 as "containerMedium", _9 as "containerMobile", _a as "containerPaddingMobile", _b as "containerSmall", _c as "danger", _d as "darkElement", _e as "disabled", _f as "disabledLink", _10 as "disabledOpacity", _11 as "extraLightElement", _12 as "facebook", _13 as "google", _14 as "green", _15 as "green2", _16 as "greenColor", _17 as "grey1", _18 as "grey10", _19 as "grey2", _1a as "grey25", _1b as "grey27", _1c as "grey3", _1d as "grey33", _1e as "grey3C", _1f as "grey4", _20 as "grey41", _21 as "grey5", _22 as "grey6", _23 as "grey61", _24 as "grey6A", _25 as "grey6F", _26 as "grey7", _27 as "grey8", _28 as "grey82", _29 as "grey9", _2a as "grey97", _2b as "grey9C", _2c as "greyA7", _2d as "greyC6", _2e as "greyD8", _2f as "greyDd", _30 as "header1", _31 as "header2", _32 as "header3", _33 as "header4", _34 as "header5", _35 as "header6", _36 as "klein", _37 as "lightElement", _38 as "lilac", _39 as "menuBorder", _3a as "menuHeight", _3b as "menuMobileHeight", _3c as "ocean", _3d as "orange", _3e as "orange2", _3f as "orangeDark", _40 as "paragraph", _41 as "paragraphSmall", _42 as "red", _43 as "rotation", _44 as "spinner", _45 as "subHeaderSmall", _46 as "subtleLightElement", _47 as "tumblr", _48 as "twitter", _49 as "white", _4a as "yellow", _4b as "yellowDeep", _4c as "youtube" }
