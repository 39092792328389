import { Component, Fragment } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import LazyImage from '../lazy-image'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import { get } from '@common/utils'
import {ImageDimensions} from "../../common/imageDimensions";

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-press'
})

const images = {
  musictech: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/press_musictech.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/press_musictech.png',
    alt: 'Musictech Logo',
    srcSet: 'https://cdn.soundation.com/rtc_landing/press_musictech.png 1x, https://cdn.soundation.com/rtc_landing/press_musictech-x2.png 2x',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/press_musictech.png'))
  },
  musicradar: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/press_musicradar.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/press_musicradar.png',
    alt: 'Musicradar Logo',
    srcSet: 'https://cdn.soundation.com/rtc_landing/press_musicradar.png 1x, https://cdn.soundation.com/rtc_landing/press_musicradar-x2.png 2x',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/press_musicradar.png'))
  },
  dmn: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/press_dmn.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/press_dmn.png',
    alt: 'Digital Music Views Logo',
    srcSet: 'https://cdn.soundation.com/rtc_landing/press_dmn.png 1x, https://cdn.soundation.com/rtc_landing/press_dmn-x2.png 2x',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/press_dmn.png'))
  },
  kvr: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/press_kvr.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/press_kvr.png',
    alt: 'Kvr Logo',
    srcSet: 'https://cdn.soundation.com/rtc_landing/press_kvr.png 1x, https://cdn.soundation.com/rtc_landing/press_kvr-x2.png 2x',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/press_kvr.png'))
  }
}

class LandingPress extends Component {
  renderSingle = (image, key) => {
    const { dimensions, ...rest } = image
    return (
      <div key={key} {...bem('item', key)} style={{width: dimensions.width + 'px' }}>
        <LazyImage {...rest} aspectRatio={dimensions.getAspectRatio()} />
      </div>
    )
  }

  renderSet = (keys) => {
    return keys.map(key => this.renderSingle(images[key], key))
  }

  renderMobile () {
    return (
      <div {...bem()}>
        <div>
          <div {...bem('row')}>
            { this.renderSet(['musicradar', 'kvr']) }
          </div>
          <div {...bem('row')}>
            { this.renderSet(['musictech', 'dmn']) }
          </div>
        </div>
      </div>
    )
  }

  renderDesktop () {
    return (
      <div {...bem()}>
        { this.renderSet(['musictech', 'musicradar', 'dmn', 'kvr']) }
      </div>
    )
  }

  render () {
    if (this.props.screenBreakPointIndex === 4) {
      return this.renderMobile()
    } else {
      return this.renderDesktop()
    }

  }
}

const mapStateToProps = (state) => ({ screenBreakPointIndex: get(state, 'app.screenBreakPointIndex') })

export default createProvider(
  connect(mapStateToProps)(LandingPress)
)
