import { Fragment, useCallback, useEffect, useMemo } from 'react'
import { connect } from "react-redux"
import createProvider from "@store/provider"
import { getUserTracks, getRecentTracks, getFollowedTracks, getFeaturedTracks, getTrendingTracks } from '@common/http'
import { getCommunityEditMyTrackContextMenuItem } from '@common/utils'
import { COMMUNITY_CAROUSEL_ITEMS } from '@common/consts'
import useDenormalizeCommunityTracks from '@root/hooks/community/useDenormalizeCommunityTracks'
import usePaginate from '@root/hooks/usePaginate'
import CommunityTracksSectionByType from '../community-tracks-section-by-type'
import CommunityTitle from '../community-title'
import CommunityTrackCard from '../community-track-card'
import CommunitySubPage from '../community-sub-page'
import * as styles from './CommunityTracks.module.scss'

function CommunityTracks (props) {
  const { currentUser, type } = props

  const isMyTracksPage = type === 'my-tracks'
  const isTrendingTracksPage = type === 'trending'
  const isFollowedTracksPage = type === 'followed'
  const isRecentTracksPage = type === 'recent'
  const isFeaturedTracksPage = type === 'featured'
  const isTracksHomePage = !isMyTracksPage && !isTrendingTracksPage && !isFollowedTracksPage && !isRecentTracksPage && !isFeaturedTracksPage

  const title = useMemo(() => {
    if (isMyTracksPage) return 'My tracks'
    if (isTrendingTracksPage) return 'Trending tracks'
    if (isFollowedTracksPage) return 'Tracks by users and groups you follow'
    if (isRecentTracksPage) return 'New tracks'
    if (isFeaturedTracksPage) return 'Featured tracks'
    if (isTracksHomePage) return 'Tracks'
  }, [isMyTracksPage, isTrendingTracksPage, isFollowedTracksPage, isRecentTracksPage, isFeaturedTracksPage, isTracksHomePage])

  const fetchUserTracks = useCallback(async ({ page }) => {
    return getUserTracks({ page, items: isTracksHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isTracksHomePage])
  const { data: userTracks, isLoading: isLoadingUserTracks, fetchNextPage: fetchUserTracksPage, nextPage: userTracksNextPage } = usePaginate({ fetcher: fetchUserTracks })
  useEffect(() => {
    if (currentUser && (isTracksHomePage || isMyTracksPage)) fetchUserTracksPage()
  }, [isTracksHomePage, isMyTracksPage, fetchUserTracksPage, currentUser])

  const fetchFollowedTracks = useCallback(async ({ page }) => {
    return getFollowedTracks({ page, items: isTracksHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isTracksHomePage])
  const { data: followedTracks, isLoading: isLoadingFollowedTracks, fetchNextPage: fetchFollowedTracksPage, nextPage: followedTracksNextPage } = usePaginate({ fetcher: fetchFollowedTracks })
  useEffect(() => {
    if (currentUser && (isTracksHomePage || isFollowedTracksPage)) fetchFollowedTracksPage()
  }, [isTracksHomePage, isFollowedTracksPage, fetchFollowedTracksPage, currentUser])

  const fetchRecentTracks = useCallback(async ({ page }) => {
    return getRecentTracks({ page, items: isTracksHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isTracksHomePage])
  const { data: recentTracks, isLoading: isLoadingRecentTracks, fetchNextPage: fetchRecentTracksPage, nextPage: recentTracksNextPage } = usePaginate({ fetcher: fetchRecentTracks })
  useEffect(() => {
    if (isTracksHomePage || isRecentTracksPage) fetchRecentTracksPage()
  }, [isTracksHomePage, isRecentTracksPage, fetchRecentTracksPage])

  const fetchTrendingTracks = useCallback(async ({ page }) => {
    return getTrendingTracks({ page, items: isTracksHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isTracksHomePage])
  const { data: trendingTracks, isLoading: isLoadingTrendingTracks, fetchNextPage: fetchTrendingTracksPage, nextPage: trendingTracksNextPage } = usePaginate({ fetcher: fetchTrendingTracks })
  useEffect(() => {
    if (isTracksHomePage || isTrendingTracksPage) fetchTrendingTracksPage()
  }, [isTracksHomePage, isTrendingTracksPage, fetchRecentTracksPage])

  const fetchFeaturedTracks = useCallback(async ({ page }) => {
    return getFeaturedTracks({ page, items: isTracksHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isTracksHomePage])
  const { data: featuredTracks, isLoading: isLoadingFeaturedTracks, fetchNextPage: fetchFeaturedTracksPage, nextPage: featuredTracksNextPage } = usePaginate({ fetcher: fetchFeaturedTracks })
  useEffect(() => {
    if (isTracksHomePage || isFeaturedTracksPage) fetchFeaturedTracksPage()
  }, [isTracksHomePage, isFeaturedTracksPage, fetchFeaturedTracksPage])

  const fetchNextSubPage = useMemo(() => {
    if (isRecentTracksPage) return fetchRecentTracksPage
    if (isTrendingTracksPage) return fetchTrendingTracksPage
    if (isFeaturedTracksPage) return fetchFeaturedTracksPage
    if (isFollowedTracksPage) return fetchFollowedTracksPage
    if (isMyTracksPage) return fetchUserTracksPage
  }, [isRecentTracksPage, isTrendingTracksPage, isFeaturedTracksPage, isFollowedTracksPage, isMyTracksPage, fetchRecentTracksPage, fetchTrendingTracksPage, fetchFollowedTracksPage, fetchUserTracksPage, fetchFeaturedTracksPage])
  const isLoadingSubPage = useMemo(() => {
    if (isRecentTracksPage) return isLoadingRecentTracks
    if (isTrendingTracksPage) return isLoadingTrendingTracks
    if (isFeaturedTracksPage) return isLoadingFeaturedTracks
    if (isFollowedTracksPage) return isLoadingFollowedTracks
    if (isMyTracksPage) return isLoadingUserTracks
  }, [isLoadingTrendingTracks, isLoadingRecentTracks, isFeaturedTracksPage, isFollowedTracksPage, isLoadingUserTracks, isRecentTracksPage, isLoadingFeaturedTracks, isTrendingTracksPage, isLoadingFollowedTracks, isMyTracksPage])
  const subPageTracks = useMemo(() => {
    if (isRecentTracksPage) return recentTracks
    if (isTrendingTracksPage) return trendingTracks
    if (isFeaturedTracksPage) return featuredTracks
    if (isFollowedTracksPage) return followedTracks
    if (isMyTracksPage) return userTracks
  }, [isRecentTracksPage, isTrendingTracksPage, isFeaturedTracksPage, isFollowedTracksPage, isMyTracksPage, recentTracks, trendingTracks, featuredTracks, followedTracks, userTracks])

  const denormalizedMixdowns = useDenormalizeCommunityTracks(subPageTracks)

  return (
    <div>
      {isTracksHomePage ? (
        <Fragment>
          <CommunityTitle className={styles.title} title='Tracks' />

          {!!currentUser && <CommunityTracksSectionByType myTracks={userTracks} isLoading={isLoadingUserTracks} showMore={!!userTracksNextPage} isFirstSection />}
          {!!currentUser && <CommunityTracksSectionByType followedTracks={followedTracks} isLoading={isLoadingFollowedTracks} showMore={!!followedTracksNextPage} />}
          <CommunityTracksSectionByType featuredTracks={featuredTracks} isLoading={isLoadingFeaturedTracks} showMore={!!featuredTracksNextPage} isFirstSection={!currentUser} />
          <CommunityTracksSectionByType trendingTracks={trendingTracks} isLoading={isLoadingTrendingTracks} showMore={!!trendingTracksNextPage} />
          <CommunityTracksSectionByType recentTracks={recentTracks} isLoading={isLoadingRecentTracks} showMore={!!recentTracksNextPage} />
        </Fragment>
      ) : (
        <CommunitySubPage
          title={title}
          landscapeOnMobile
          fetchNextPage={fetchNextSubPage}
          isLoading={isLoadingSubPage}
        >
          {denormalizedMixdowns.map(({ id, mixdown }) => <CommunityTrackCard key={id} mixdown={mixdown} isSubPage contextMenuItems={isMyTracksPage ? [getCommunityEditMyTrackContextMenuItem(id)] : []} />)}
        </CommunitySubPage>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.backendData.currentUser
  }
}
export default createProvider(connect(mapStateToProps)(CommunityTracks))
