import React from 'react'
import './style.scss'
import {
  Bem,
} from '../../common/utils'
import {If, Then, Else} from 'react-if'
import { LINKS } from '../../common/consts'
import Linkton, { COLOR, TYPE } from "@components/linkton";

const cn = new Bem({
  name: 'project-access-denied',
  prefix: 'pfx-'
})

class ProjectAccessDeniedView extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    const { currentUser, organization } = this.props

    return (
      <div className={cn(undefined)}>
        <div className={cn('container', ['black', 'wide'], { space: true })}>
          <div className={cn('header')}>
            <h4 className={cn('header-text', 'left-align')}>You need access!</h4>
          </div>
          <div className={cn('description')}>
            <p>You need a Soundation Education account with {organization.name} to view this project.</p>
            <div className={cn('text-separator', ['small'])}/>
            <If condition={currentUser}>
              <Then>
                <p>Please contact your account owner for more information.</p>
                <div className={cn('text-separator', ['small'])}/>
                <div>
                  <Linkton
                    className={cn("org-action-button")}
                    tag="anchor"
                    type={TYPE.OUTLINE}
                    padding="large"
                    color={COLOR.OCEAN}
                    hover="none"
                    href={LINKS.STUDIO}>
                    Go to dashboard
                  </Linkton>
                </div>
              </Then>
              <Else>
                <p>If you do have an account with {organization.name}, please log in below.</p>
                <div className={cn('text-separator', ['small'])}/>
                <div>
                  <Linkton
                    className={cn("org-action-button")}
                    tag="anchor"
                    type={TYPE.OUTLINE}
                    padding="large"
                    color={COLOR.OCEAN}
                    hover="none"
                    href={LINKS.LOGIN}>
                    Log in
                  </Linkton>
                </div>
              </Else>
            </If>
            <div className={cn('text-separator', ['small'])}/>
            <p>Soundation is an online music studio where you can make music and collaborate without boundaries.</p>
          </div>
        </div>
      </div>
    )
  }
}

ProjectAccessDeniedView.propTypes = {
}

ProjectAccessDeniedView.defaultProps = {}

export default ProjectAccessDeniedView
