import React, { useCallback } from 'react'
import useInfiniteScrolling from '@hooks/useInfiniteScrolling'
import Icon from '@components/icon'
import Spinner from '@components/spinner'
import Notification from './Notification'
import * as styles from './NotificationsModal.module.scss'

const NotificationsModal = ({ notifications, onMarkRead, onClose, isLoading, nextPage, fetchNextPage }) => {
  const scrollCallback = useCallback(() => {
    if (nextPage && !isLoading) {
      fetchNextPage()
    }
  }, [isLoading, nextPage])
  const handleScroll = useInfiniteScrolling(scrollCallback, 100)

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <h3 className={styles.title}>Notifications</h3>

        <Icon icon='new-close' onClick={onClose} size={16} />
      </div>

      <div className={styles.spinnerContainer}>
        {isLoading && <Spinner className={styles.spinner} />}
      </div>

      <div className={styles.body} onScroll={handleScroll}>
        {notifications.length ? (
          notifications.map((notification) => (
            <Notification
              key={notification.id}
              notification={notification}
              onMarkRead={onMarkRead}
            />
          ))
        ) : (
          <p className={styles.placeholder}>
            This is where your notifications will be
          </p>
        )}
      </div>
    </div>
  )
}

export default NotificationsModal
