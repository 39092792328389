import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import './style.scss'

const cn = new Bem({
  name: 'glitch-text',
  prefix: 'pfx-'
})

class GlitchText extends React.Component {
  render () {
    const { children, elementType, className } = this.props

    if (typeof children !== 'string') return null
    if (typeof elementType !== 'string') return null

    const ComponentType = elementType || 'span'

    return (
      <ComponentType className={cn('', '', className)} data-text={children}>
        {children}
      </ComponentType>
    )
  }
}

GlitchText.propTypes = {
  children: PropTypes.bool.isRequired,
  elementType: PropTypes.string,
  className: PropTypes.string
}

GlitchText.defaultProps = {
  className: PropTypes.string,
  elementType: 'span'
}

export default GlitchText
