import React, { Component } from 'react'
import './style.scss'
import { Bem, get } from '@common/utils'
import { pause, play, stop, progressChange, likeRequest, dislikeRequest } from '@store/actions/player'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import Icon from '../icon'
import { Modal } from '../modal'
import { LINKS } from '../../common/consts'
import { canUseDOM } from '../../common/utils'
import { SCREEN_BREAKPOINTS } from '@common/consts'

const cn = new Bem({
  name: 'global-player-control',
  prefix: 'pfx-'
})

const isWebkit = canUseDOM() && 'WebkitAppearance' in document.documentElement.style && navigator && !navigator.userAgent.match(/Edge/)

export class GlobalPlayerControl extends Component {
  constructor (props) {
    super(props)
    this.state = {
      modalOpened: false
    }
  }

  componentDidMount () {
    if (canUseDOM() && !isWebkit) {
      this.waveformImage = new Image()
      this.waveformImage.crossOrigin = 'use-credentials'
      this.waveformImage.onload = () => {
        this.drawWaveform()
      }
      if (this.props.directWaveformUrl) {
        this.waveformImage.src = this.props.directWaveformUrl
      }
    }
  }

  componentDidUpdate () {
    if (this.props.directWaveformUrl && this.waveformImage) {
      this.waveformImage.src = this.props.directWaveformUrl
    }
  }

  secondsToString (seconds) {
    if (typeof seconds !== 'number') {
      return '--:--'
    }
    return Math.floor(seconds / 60).toString(10).padStart(2, '0') + ':' + Math.floor(seconds % 60).toString(10).padStart(2, '0')
  }

  handleWaveformMouseDown (e) {
    const { dispatch, duration } = this.props
    const waveform = e.currentTarget

    const xpos = e.nativeEvent.offsetX === undefined ? e.nativeEvent.layerX : e.nativeEvent.offsetX
    const p = xpos / waveform.offsetWidth
    const currentTime = p * duration

    dispatch(progressChange(currentTime))

    waveform.onmousemove = (e) => {
      const xpos = e.offsetX === undefined ? e.layerX : e.offsetX
      const p = xpos / waveform.offsetWidth
      const currentTime = p * duration
      dispatch(progressChange(currentTime))
    }
    waveform.onmouseup = (e) => {
      waveform.onmousemove = null
    }
  }

  onSocialClick (e) {
    if (e.altKey || e.metaKey || e.shiftKey) {
      return
    }
    e.preventDefault()
    window.open(e.target.getAttribute('href'), e.target.getAttribute('title'), 'width=600,height=600')
  }

  renderModalBody () {
    const { title, permalink, profileLink, shortLinkId } = this.props

    const fullPermalink = (canUseDOM() ? window.location.origin : '') + `${LINKS.USER}/${profileLink}/track/${permalink}`
    const fullShortlink = (canUseDOM() ? window.location.origin : '') + `${LINKS.TRACK_SHORT}/${shortLinkId}`

    return (
      <div className={cn('share-sheet')}>
        <label className={cn('share-sheet-label')} >Copy link</label>
        <input className={cn('share-sheet-input')} type='text' value={fullPermalink} />

        <label className={cn('share-sheet-label')} >Embed code</label>
        <textarea className={cn('share-sheet-textarea')} name=''>{ '<iframe width="100%" height="130" src="' + fullPermalink + '" frameborder="0" allowfullscreen></iframe>' }</textarea>

        <label className={cn('share-sheet-label')} >Share to</label>
        <div className={cn('share-sheet-social')}>
          <a
            href={'https://www.facebook.com/dialog/share?app_id=374199152604565&display=popup&href=' + encodeURIComponent(fullPermalink) + '&redirect_uri=' + encodeURIComponent(fullPermalink)}
            className={cn('share-sheet-social-icon')} title='Share to Facebook'
            onClick={this.onSocialClick}>
            <Icon icon='facebook' size={24} />
          </a>
          <a href={'https://twitter.com/share?url=' + encodeURIComponent(fullShortlink)}
            className={cn('share-sheet-social-icon')} title='Share to Twitter'
            onClick={this.onSocialClick}>
            <Icon icon='twitter' size={24} />
          </a>
        </div>
      </div>
    )
  }

  drawWaveform () {
    if (isWebkit) return
    const context = this.refs.canvas.getContext('2d')
    const { width, height } = this.refs.canvas

    context.clearRect(0, 0, width, height)
    context.globalCompositeOperation = 'source-over'
    context.fillStyle = '#ececec'
    context.fillRect(0, 0, width, height)
    context.fillStyle = '#c6c6c5'
    context.fillRect(0, 0, width * this.props.fetchProgress, height)
    context.fillStyle = '#0025ff'
    context.fillRect(0, 0, width * this.props.progress, height)
    context.globalCompositeOperation = 'destination-in'
    context.drawImage(this.waveformImage, 0, 0, width, height)
  }

  render () {
    const {
      dispatch,
      title,
      username,
      duration,
      directWaveformUrl,
      isPlaying,
      time,
      progress,
      fetchProgress,
      doesUserLike,
      currentUser,
      profileLink,
      shortLinkId,
      permalink,
      isMobile
    } = this.props
    const { modalOpened } = this.state

    const titleElement = profileLink && permalink
      && <a className={cn('link')} href={`${LINKS.USER}/${profileLink}/track/${permalink}`}>{title}</a>
      || title

    const usernameElement = profileLink
      && <a className={cn('link')} href={`${LINKS.USER}/${profileLink}`}>{username}</a>
      || username

    const timeElement = <span>{ this.secondsToString(time) }/{ this.secondsToString(duration) }</span>

    return (
      <div className={cn(null, title ? null : 'hidden')}>
        {/* todo replace with Waveform component if bringing this back */}
        <div className={cn('waveform-container', {'empty': !directWaveformUrl})}>
          {
            directWaveformUrl && (
              isWebkit &&
              <div
                className={cn('waveform')} style={isWebkit ? {
                maskImage: 'url("' + directWaveformUrl + '")',
                WebkitMaskImage: 'url("' + directWaveformUrl + '")'
              } : {}}
                onMouseDown={(e) => this.handleWaveformMouseDown(e)}>
                <div className={cn('waveform-progress', 'fetch')} style={{width: (fetchProgress * 100) + '%'}}/>
                <div className={cn('waveform-progress', 'play')} style={{width: (progress * 100) + '%'}}/>
              </div>
              ||
              <canvas
                ref='canvas'
                width={canUseDOM() ? window.innerWidth : 100}
                height={48}
                onMouseDown={(e) => this.handleWaveformMouseDown(e)}
              />
            )
          }
        </div>
        <div className={cn('controls')}>
          <div
            className={ cn('column', ['with-icon', 'no-limiter']) }
            onClick={() => dispatch(isPlaying ? pause() : play())}>
            <Icon
              size={18}
              icon={isPlaying ? 'new-pasue' : 'new-play'}
            />
          </div>
          { isMobile && <div className={cn('column', ['flexible', 'with-rows'])}>
              <div className={cn('row')}>
                { titleElement }
                <span> - </span>
                { usernameElement }
              </div>
              <div className={cn('row', 'small')}>
                { timeElement }
              </div>
            </div>
            || [
              (<div className={cn('column', 'flexible')} key='title'>
                { titleElement }
                <span> - </span>
                { usernameElement }
              </div>),
              (<div className={cn('column', 'no-limiter')} key='time'>
                { timeElement }
              </div>)
            ]
          }


          { currentUser && typeof doesUserLike !== 'undefined' && null !== doesUserLike && <div
            className={cn('column', 'with-icon')}
            onClick={() => dispatch(doesUserLike ? dislikeRequest() : likeRequest())}>
            <Icon
              size={18}
              icon={doesUserLike ? 'new-hearth' : 'new-hearth-outline'} />
          </div>}

          {
            shortLinkId && permalink && profileLink
            && <div
              className={cn('column', 'with-icon')}
              onClick={() => this.setState({modalOpened: true})}>
                <Icon
                  size={18}
                  icon='new-share1'/>
            </div>
          }

        </div>

        {
          shortLinkId && permalink && profileLink
          && <Modal
            isOpen={modalOpened}
            children={() => this.renderModalBody()}
            title={<div><h2>{title}</h2><h3 className={cn('share-screen-title-username')}>by {username}</h3></div>}
            onCloseButtonClick={() => this.setState({modalOpened: false})}/>
        }

      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    title: get(state, 'player.title'),
    username: get(state, 'player.username'),
    duration: get(state, 'player.duration'),
    directWaveformUrl: get(state, 'player.directWaveformUrl'),
    permalink: get(state, 'player.permalink'),
    profileLink: get(state, 'player.profileLink'),
    shortLinkId: get(state, 'player.shortLinkId'),
    isPlaying: get(state, 'player.isPlaying'),
    isPaused: get(state, 'player.isPaused'),
    songUrl: get(state, 'player.songUrl'),
    time: get(state, 'player.time'),
    progress: get(state, 'player.progress'),
    fetchProgress: get(state, 'player.fetchProgress'),
    doesUserLike: get(state, 'player.doesUserLike'),
    currentUser: get(state, 'backendData.currentUser'),
    isMobile: get(state, 'app.screenBreakPointIndex') >= SCREEN_BREAKPOINTS.length - 1,
  }
}

const mapDispatchToProps = dispatch => {
  return { play, pause, stop }
}

GlobalPlayerControl.propTypes = {}

GlobalPlayerControl.defaultProps = {}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(GlobalPlayerControl, false))
