import { useState, useEffect } from 'react'
import { thousandsToK } from '@common/utils'
import { denormalizeResponse } from '@common/http'

// denormalize incoming tracks and return them
export default function useDenormalizeCommunityTracks (normalizedTracks) {
  const [tracks, setTracks] = useState([])

  useEffect(() => {
    if (normalizedTracks) {
      const denormalized = denormalizeResponse(normalizedTracks)
      if (!denormalized) return

      denormalized.forEach((track) => {
        let mixdown
        if (track.mixdown) {
          mixdown = track.mixdown
        } else if (track.type === 'mixdown') {
          mixdown = track
          // type is 'track' when the track is a user track
        } else if (track.type === 'track') {
          mixdown = track
        }
        track.mixdown = {
          ...mixdown,
          commentsCount: thousandsToK(mixdown.commentsCount),
          likes: thousandsToK(mixdown.likes),
          plays: thousandsToK(mixdown.plays)
        }
      })

      setTracks(denormalized)
    } else {
      setTracks([])
    }
  }, [normalizedTracks])

  return tracks
}
