import { LINKS } from '../../common/consts'
import { Bem, replaceHref } from '../../common/utils'
import createStore from '@store/index'
import { closeMobileLimitationSidebar } from '../../store/actions/app'
import Button from '../../components/button/index'

const store = createStore()

const cn = new Bem({
  name: 'mobile-studio-limitation-sidebar',
  prefix: 'pfx-'
})

export const NO_MOBILE_APP = () => [
  <img src='/assets/icon-soundation-popup.png' className={cn('image')} alt='Soundation' />,
  <div className={cn('text-container')}>
    <h3 className={cn('header')}>Hey You!</h3>
    <p className={cn('light-text')}>We don’t have a mobile application yet...</p>
    <p className={cn('light-text')}>
      ...but we have <span className={cn('black')}>millions of users</span> creating great music
      with our web studio!
    </p>
  </div>,
  <Button
    className={cn('button', null, 'pfx-button--secondary-full')}
    onClick={() => {
      closeMobileLimitationSidebar()(store.dispatch)
      replaceHref(LINKS.TRACKS)
    }}
  >
    Explore music
  </Button>
].map((jsxEle, idx) => React.cloneElement(jsxEle, { key: idx }))

export const NO_MOBILE_APP_REMIX = () => [
  <img src='/assets/icon-soundation-popup.png' className={cn('image')} alt='Soundation' />,
  <div className={cn('text-container')}>
    <h3 className={cn('header')}>Hey You!</h3>
    <p className={cn('light-text')}>We don’t have a mobile app for our studio yet. Get on your laptop to start remixing!</p>
  </div>,
  <Button
    className={cn('button', null, 'pfx-button--secondary-full')}
    onClick={() => {
      closeMobileLimitationSidebar()(store.dispatch)
    }}
  >
    Back to Remix Challenge
  </Button>
].map((jsxEle, idx) => React.cloneElement(jsxEle, { key: idx }))
