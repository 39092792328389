import React from 'react'
import NextImage from 'next/image'

/** The bundler used by next returns an object with a "src" property, whereas webpack used in rails returns a string (url or base64).
 * @param img is an imported (static) image file. E.g. import img from '@images/some-imageebp', or a remote url */
export const getSrcType = (src = '') => {
  if (typeof src === 'string') {
    return 'basic'
  }

  if (src.src) {
    return 'static-next'
  }

  return 'basic'
}

/** Replaces the basic img html element. Next and rails webpack configs return different img imports, so we abstract the difference away in this component so the developer can use it like a regular img element.
 * The component returned also differs based on the type of img import. Next uses a special "next/image" component for static images, whereas rails uses a regular img element. Using the "next/image" component is not essential, we can use the regular img element for all cases.
 * Keep in mind that for static files, "src" is an import and not a path! E.g. import src from '@images/some-image.png'. For remote urls however, "src" can be a string, e.g. "example.com/file.png".
 */
const Image = ({ src, ...rest }) => {
  const type = getSrcType(src)

  if (type === 'static-next') return <NextImage src={src} {...rest} />

  return <img src={src} {...rest} />
}

export default Image
