import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Label from '@components/label'
import ImageUploader from '@components/image-uploader'
import SelectField from '../select-field'
import Linkton, { SIZE, COLOR, TYPE, HOVER } from '../linkton'
import { Bem } from '../../common/utils'
import FormElement from '../form-element'
import { TextAreaUncontrolled } from '../text-area/text-area'
import SelectDropdown from '@components/select-dropdown'
import { JUST_REQUIRED, LINK } from '../../common/validator-models'
import TextField, { LAYOUT } from '../text-field'
import Switcher from '../switcher'
import CommunityToggle from '@containers/community/community-toggle'
import { LINKS } from '../../common/consts'
import './style.scss'

const cn = Bem({
  name: 'group-form',
  prefix: 'pfx-'
})

export default class GroupForm extends Component {
  static propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    category: PropTypes.string,
    privacy: PropTypes.string,
    description: PropTypes.string,
    categoryOptions: SelectField.propTypes.options,
    privacyOptions: SelectField.propTypes.options,
    groupLink: PropTypes.string,
    numberOfTracks: PropTypes.string,
    numberOfTracksOptions: SelectField.propTypes.options,
    sortBy: PropTypes.string,
    sortByOptions: SelectField.propTypes.options,
    commentsOptions: SelectField.propTypes.options,
    comments: PropTypes.string,
    additionalSettingsOptions: SelectField.propTypes.options,
    additionalSettings: PropTypes.array,
    pictureUrl: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    submitButtonLabel: PropTypes.string,
    uploaded: PropTypes.bool,
    uploadProgress: PropTypes.number,
    onUploadRequest: PropTypes.func,
    onUploadRejected: PropTypes.func,
    formHeader: PropTypes.string.isRequired,
    errors: PropTypes.objectOf(PropTypes.string),
    isLoading: PropTypes.bool
  }
  static defaultProps = {
    additionalSettings: []
  }

  state = {
    uploaded: false,
    uploadProgress: 0,
    isGroupLinkFocused: false
  }

  onSubmit = async () => {
    const { onSubmit } = this.props
    const validation = await this.validate()

    if (validation) {
      onSubmit()
    }
  }

  renderNameInput () {
    const { name, errors } = this.props
    const error = errors && errors.name
    return <div>
      <Label label='GROUP NAME (REQUIRED)' />
      <FormElement
        validation={JUST_REQUIRED}
        field={'name'}
        className={cn('form-element')}
        ref={ref => this.nameRef = ref}
        onChange={value => this.onChange({ name: value })}
        error={error}
        errorSize
        onFocus={() => this.setState({ isNameFocused: true })}
        onBlur={() => this.setState({ isNameFocused: false })}
      >
        <TextField placeholder='Enter a name' value={name} layout='dark' />
      </FormElement>
    </div>
  }

  renderCategorySelect () {
    const { category, categoryOptions } = this.props
    return <SelectDropdown
      label='GROUP CATEGORY'
      items={categoryOptions}
      value={category}
      className={cn('form-element')}
      dropdownClassName={cn('select-dropdown')}
      dropdownProps={{ containerClassName: cn('select-container') }}
      itemClassName={cn('dropdown-item')}
      onSelect={this.onSelectCategory}
    />
  }

  renderPrivacySelect () {
    const { privacy, privacyOptions } = this.props
    return <SelectDropdown
      label='PRIVACY'
      items={privacyOptions}
      value={privacy}
      className={cn('form-element')}
      dropdownClassName={cn('select-dropdown')}
      dropdownProps={{ containerClassName: cn('select-container') }}
      itemClassName={cn('dropdown-item')}
      onSelect={this.onSelectPrivacy}
    />
  }

  renderDescriptionTextarea () {
    const { description, errors } = this.props
    const error = errors && errors.description
    return <div>
      <Label label='DESCRIPTION' />
      <FormElement
        className={cn('form-element')}
        field={'description'}
        value={description}
        onChange={value => this.onChange({ description: value })}
        error={error}
        errorSize
      >
        <TextAreaUncontrolled
          className={cn('textarea')}
          layout={'dark'}
          isTransparent={false}
          value={description}
          isModern
          placeholder={'What is your group about? Try to be short and sweet.'}
        />
      </FormElement>
    </div>
  }

  getUploaderPlaceholder () {
    const { pictureUrl } = this.props

    if (pictureUrl) {
      return <div className={cn('uploader-placeholder', 'with-image')} style={{ backgroundImage: 'url("' + pictureUrl + '")' }}>
        <Linkton icon='upload' iconPlacement='left' type={TYPE.FULL} size={SIZE.MEDIUM}>Change image</Linkton>
      </div>
    } else {
      return <div className={cn('uploader-placeholder')}>
        <Linkton icon='upload' iconPlacement='left' type={TYPE.FULL} size={SIZE.MEDIUM}>Upload</Linkton>
        <div className={cn('uploader-placeholder-drop-area')}>Or drop file here.</div>
      </div>
    }
  }

  renderGroupLink () {
    const { groupLink, errors } = this.props
    const error = errors && errors.groupLink

    return (
      <div className={cn('form-element')}>
        <Label label='GROUP LINK' />
        <FormElement
          field={'groupLink'}
          onChange={value => this.onChange({ groupLink: value })}
          ref={ref => this.groupLinkRef = ref}
          error={error}
          errorSize='small'
          validation={LINK}
        >
          <TextField placeholder='Group link' value={groupLink} layout={LAYOUT.DARK} />
        </FormElement>
        <small className={cn('group-link-description')}>https://soundation.com{LINKS.GROUP}/{groupLink}</small>
      </div>
    )
  }

  renderNumberOfTracks () {
    const { numberOfTracks, numberOfTracksOptions } = this.props
    return <SelectDropdown
      label={'NUMBER OF TRACKS DISPLAYED'}
      items={numberOfTracksOptions}
      value={numberOfTracks}
      className={cn('form-element')}
      dropdownClassName={cn('select-dropdown')}
      dropdownProps={{ containerClassName: cn('select-container') }}
      itemClassName={cn('dropdown-item')}
      onSelect={this.onSelectNumberOfTracks}
    />
  }
  onSelectNumberOfTracks = (value) => {
    this.onChange({ numberOfTracks: value })
  }

  renderSortBy () {
    const { sortBy, sortByOptions } = this.props
    return <SelectDropdown
      label={'SORT TRACKS BY'}
      items={sortByOptions}
      value={sortBy}
      className={cn('form-element')}
      dropdownClassName={cn('select-dropdown')}
      dropdownProps={{ containerClassName: cn('select-container') }}
      itemClassName={cn('dropdown-item')}
      onSelect={this.onSelectSortBy}
    />
  }
  onSelectSortBy = (value) => {
    this.onChange({ sortBy: value })
  }

  renderComments () {
    const { comments, commentsOptions } = this.props
    return <div className={cn('form-element')}>
      <Label label='COMMENTS' />
      <Switcher
        theme='dark'
        className={cn('comments-switcher')}
        buttons={commentsOptions}
        selected={comments}
        modifiers={{ seamless: true }}
        onUpdate={value => this.onChange({ comments: value })}
      />
    </div>
  }

  renderAdditional () {
    const { additionalSettingsOptions, additionalSettings = [] } = this.props

    return <div className={cn('form-element')}>
      <Label label='ADDITIONAL SETTINGS' />
      {additionalSettingsOptions.map((option) => (
        <CommunityToggle
          key={option.value}
          name={option.value}
          label={option.label}
          value={additionalSettings.indexOf(option.value) !== -1}
          onToggle={({ name, value }) => {
            const settings = new Set(additionalSettings)

            if (value) settings.add(name)
            else settings.delete(name)

            this.onChange({
              additionalSettings: Array.from(settings)
            })
          }}
        />
      ))}
    </div>
  }

  onSelectCategory = (value) => {
    this.onChange({ category: value })
  }
  onSelectPrivacy = (value) => {
    this.onChange({ privacy: value })
  }

  onChange (change) {
    const { onChange } = this.props
    if (onChange) {
      onChange(change)
    }
  }

  async validate (check = true) {
    if (check) {
      await this.nameRef.validate()
    }

    if (this.nameRef.hasError()) {
      return false
    }
    if (this.groupLinkRef.hasError()) {
      return false
    }
    return true
  }

  renderGroupImage () {
    const { uploaded, uploadProgress, onUploadRequest, onUploadRejected, pictureUrl } = this.props

    return <div className={cn('group-form')}>
      <Label className={cn('upload-form-label')} label='GROUP IMAGE' />
      <ImageUploader
        className={cn('uploader')}
        existingPictureUrl={pictureUrl}
        onUploadRequest={onUploadRequest}
        onRejected={onUploadRejected}
        uploaded={uploaded}
        uploadProgress={uploadProgress}
      />
    </div>
  }

  render () {
    const { submitButtonLabel, isLoading } = this.props

    return (
      <div className={cn('')}>
        <div>
          <div className={cn('basic')}>
            { this.renderGroupImage() }
            { this.renderNameInput() }
            { this.renderCategorySelect() }
            { this.renderPrivacySelect() }
            { this.renderDescriptionTextarea() }
          </div>

          <div className={cn('advanced')}>
            { this.renderGroupLink() }
            { this.renderNumberOfTracks() }
            { this.renderSortBy() }
            { this.renderComments() }
            { this.renderAdditional() }
          </div>
        </div>

        <div className={cn('buttons')}>
          <Linkton
            key={'submit'}
            className={cn('button')}
            color={COLOR.PRIMARY}
            hover={HOVER.TRANSPARENCY}
            data-turbolinks='false'
            onClick={() => this.onSubmit()}
            size={16}
            padding={75}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            { submitButtonLabel }
          </Linkton>
        </div>
      </div>
    )
  }
}
