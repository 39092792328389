import React from 'react'
import { Bem } from '../../common/utils'
import './style.scss'

const cn = Bem({
  prefix: 'pfx-',
  name: 'form-label'
})

const FormLabel = (props) => {
  const { children, isFocus, className, ...restProps } = props

  return <label className={cn('', {focus: isFocus}, className)} {...restProps}>{children}</label>
}

export default FormLabel
