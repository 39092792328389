import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import createProvider from '@store/provider'
import { denormalizeResponse, searchCommunity, getPieces } from '@common/http'
import { COMMUNITY_CAROUSEL_ITEMS, PROJECT_SORT_BY } from '@common/consts'
import { selectCurrentUser } from '@store/selectors/backendData'
import { selectIsStudioSupportedDevice } from '@store/selectors/app'
import { setFlashMessageByValues as setFlashMessageByValuesAction } from '@store/actions/flashMessage'
import usePaginate from '@hooks/usePaginate'
import useDenormalizeCommunityTracks from '@hooks/community/useDenormalizeCommunityTracks'
import usePrevious from '@hooks/usePrevious'
import CommunitySearch, { makeCommunitySearchUrl, COMMUNITY_SEARCH_PARAMS } from '../community-search'
import CommunityGroupsSection from '../community-groups-section'
import CommunityProjectsSection from '../community-projects-section'
import CommunityTracksSection from '../community-tracks-section'
import CommunityTrackCard from '../community-track-card'
import CommunityGroupCard from '../community-group-card'
import CommunityProjectCard from '../community-project-card'
import CommunityUserCard from '../community-user-card'
import CommunityUsersSection from '../community-users-section'
import CommunitySubPage from '../community-sub-page'
import CommunitySectionPlaceholder from '../community-section-placeholder'
import * as styles from './CommunitySearchPage.module.scss'
import { throttle } from 'lodash'

const RANK_SORT_VALUE = 'rank'
const sortBy = {
  [COMMUNITY_SEARCH_PARAMS.values.type.group]: {
    relevant: { label: 'Relevant', value: RANK_SORT_VALUE },
    created_at: { label: 'Recent', value: 'created_at' },
    group_memberships_count: { label: 'Members', value: 'followers_count' },
    tracks_count: { label: 'Tracks', value: 'tracks_count' }
  },
  [COMMUNITY_SEARCH_PARAMS.values.type.track]: {
    'relevant': { label: 'Relevant', value: RANK_SORT_VALUE },
    'mixdowns.created_at': { label: 'Recent', value: 'created_at' },
    'mixdowns.plays': { label: 'Plays', value: 'plays' }
  },
  [COMMUNITY_SEARCH_PARAMS.values.type.user]: {
    relevant: { label: 'Relevant', value: RANK_SORT_VALUE },
    recent: { label: 'Recent', value: 'created_at' },
    mixdowns_count: { label: 'Tracks', value: 'mixdowns_count' }
  },
  [COMMUNITY_SEARCH_PARAMS.values.type.project]: PROJECT_SORT_BY
}
const DEFAULT_SORT = {
  [COMMUNITY_SEARCH_PARAMS.values.type.group]: RANK_SORT_VALUE,
  [COMMUNITY_SEARCH_PARAMS.values.type.track]: RANK_SORT_VALUE,
  [COMMUNITY_SEARCH_PARAMS.values.type.user]: RANK_SORT_VALUE,
  [COMMUNITY_SEARCH_PARAMS.values.type.project]: PROJECT_SORT_BY.updatedAt.value
}

const CommunitySearchPage = (props) => {
  let { term, type, sortBy: sortByValue, setFlashMessageByValues } = props
  type = type || COMMUNITY_SEARCH_PARAMS.values.type.all

  const isStudioSupportedDevice = useSelector(selectIsStudioSupportedDevice)
  const currentUser = useSelector(selectCurrentUser)
  const canSearchForProjects = !!(currentUser && isStudioSupportedDevice)

  const [sort, setSort] = useState(sortByValue || DEFAULT_SORT[type])

  const isSubPage = type !== COMMUNITY_SEARCH_PARAMS.values.type.all

  const fetcher = useCallback(async ({ page }) => {
    if (!term) return

    const entities = isSubPage ? [type] : [COMMUNITY_SEARCH_PARAMS.values.type.track, COMMUNITY_SEARCH_PARAMS.values.type.user, COMMUNITY_SEARCH_PARAMS.values.type.group]
    const pageSize = isSubPage ? 20 : COMMUNITY_CAROUSEL_ITEMS

    const searchGroupsUsersTracks = () => searchCommunity({ term, entities, pageSize, page, sortBy: isSubPage ? sort : undefined })
    const searchProjects = () => getPieces({ items: pageSize, page, sort: isSubPage ? sort : undefined, q: term })

    if (type === COMMUNITY_SEARCH_PARAMS.values.type.all) {
      const [{ data: communitySearch }, { data: pieces }] = await Promise.all([searchGroupsUsersTracks(), canSearchForProjects ? searchProjects() : {}])
      return {
        data: {
          data: {
            ...communitySearch.data,
            projects: canSearchForProjects ? { data: pieces.data, included: pieces.included, meta: pieces.meta } : undefined
          }
        }
      }
    } else if (type === COMMUNITY_SEARCH_PARAMS.values.type.project) {
      if (!canSearchForProjects) return { data: { data: { projects: undefined } } }

      const { data: pieces } = await searchProjects()
      return {
        data: {
          data: {
            projects: pieces
          },
          meta: pieces.meta
        }
      }
    }

    return searchGroupsUsersTracks()
  }, [isSubPage, type, sort, term, canSearchForProjects])

  const upsertData = useCallback((cur, incoming) => {
    const { data: { tracks: incomingTracks, groups: incomingGroups, users: incomingUsers, projects: incomingProjects } } = incoming

    return {
      tracks: { data: [...(cur.tracks?.data ?? []), ...(incomingTracks?.data || [])], included: [...(cur.tracks?.included ?? []), ...(incomingTracks?.included ?? [])] },
      groups: { data: [...(cur.groups?.data ?? []), ...(incomingGroups?.data || [])] },
      users: { data: [...(cur.users?.data ?? []), ...(incomingUsers?.data || [])] },
      projects: { data: [...(cur.projects?.data ?? []), ...(incomingProjects?.data || [])], included: [...(cur.projects?.included ?? []), ...(incomingProjects?.included ?? [])] },
    }
  }, [])

  const { isLoading, data, fetchNextPage, reset } = usePaginate({ fetcher, setter: upsertData })
  const fetchNextPageThrottled = useMemo(() => throttle(fetchNextPage, 200, { trailing: false }), [fetchNextPage])
  const { tracks, groups, users, projects } = data

  const handleSort = useCallback((newSort) => setSort(newSort), [])

  // Fetch on mount
  useEffect(fetchNextPageThrottled, [])

  const reFetchData = useCallback(() => {
    reset()
    setTimeout(fetchNextPageThrottled, 0)
  }, [reset, fetchNextPageThrottled])

  // Reset and re-request when the sort changes
  const previousSort = usePrevious(sort)
  useEffect(() => {
    if (previousSort !== sort) {
      reFetchData
    }
  }, [previousSort, sort, reFetchData])

  const denormalizedMixdowns = useDenormalizeCommunityTracks(tracks)
  const renderSubPageCards = () => {
    if (type === COMMUNITY_SEARCH_PARAMS.values.type.track) {
      return denormalizedMixdowns.map(({ id, mixdown }) => <CommunityTrackCard isSubPage key={id} mixdown={mixdown} />)
    }
    if (type === COMMUNITY_SEARCH_PARAMS.values.type.user) {
      return users?.data ? denormalizeResponse(users).map((user) => <CommunityUserCard isSubPage key={user.id} user={user} />) : null
    }
    if (type === COMMUNITY_SEARCH_PARAMS.values.type.group) {
      return groups?.data ? denormalizeResponse(groups).map((group) => <CommunityGroupCard isSubPage key={group.id} group={group} />) : null
    }
    if (type === COMMUNITY_SEARCH_PARAMS.values.type.project) {
      return projects?.data ? denormalizeResponse(projects).map((piece) => <CommunityProjectCard key={piece.id} piece={piece} reloadProject={reFetchData} reloadAll={reFetchData} onError={setFlashMessageByValues} currentUser={currentUser} isSubPage />) : null
    }
  }

  return (
    <div>
      {!isSubPage ? (
        <Fragment>
          <CommunitySearch isSearchPage />
          {!!tracks && <CommunityTracksSection isFirstSection tracks={tracks} pageLink={makeCommunitySearchUrl({ type: COMMUNITY_SEARCH_PARAMS.values.type.track, term })} label='Tracks' subHeader={!tracks.data?.length && <CommunitySectionPlaceholder icon='Project-file' message='No tracks were found' />} />}
          {!!users && <CommunityUsersSection className={styles.section} users={users} label='Users' showMoreLink={makeCommunitySearchUrl({ type: COMMUNITY_SEARCH_PARAMS.values.type.user, term })} subHeader={!users.data?.length && <CommunitySectionPlaceholder icon='Project-file' message='No users were found' />} />}
          {!!groups && <CommunityGroupsSection className={styles.section} groups={groups} label='Groups' showMoreLink={makeCommunitySearchUrl({ type: COMMUNITY_SEARCH_PARAMS.values.type.group, term })} subHeader={!groups.data?.length && <CommunitySectionPlaceholder icon='Project-file' message='No groups were found' />} />}
          {!!projects && !!canSearchForProjects && <CommunityProjectsSection className={styles.section} pieces={projects} denormalize showCreateProject={false} reloadProject={reFetchData} reloadAll={reFetchData} onError={setFlashMessageByValues} currentUser={currentUser} label='My projects' showMoreLink={makeCommunitySearchUrl({ type: COMMUNITY_SEARCH_PARAMS.values.type.project, term })} subHeader={!projects.data?.length && <CommunitySectionPlaceholder icon='Project-file' message='No projects were found' />} />}
        </Fragment>
      ) : (
        <CommunitySubPage
          title={`"${term}" ${type}s`}
          sortValue={sort}
          sortOptions={Object.values(sortBy[type])}
          onSort={handleSort}
          fetchNextPage={fetchNextPageThrottled}
          isLoading={isLoading}
          landscapeOnMobile={[COMMUNITY_SEARCH_PARAMS.values.type.track, COMMUNITY_SEARCH_PARAMS.values.type.user].includes(type)}
        >
          {renderSubPageCards()}
        </CommunitySubPage>
      )}
    </div>
  )
}

const mapDispatchToProps = {
  setFlashMessageByValues: setFlashMessageByValuesAction
}

export default createProvider(connect(null, mapDispatchToProps)(CommunitySearchPage), false)
