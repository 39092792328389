import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bem, Enum } from '@common/utils'
import './style.scss'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'heading'
})

class Heading extends Component {

  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
    level: PropTypes.oneOf([1, 2, 3])
  }

  static defaultProps = {
    level: 1
  }

  render () {
    const { children, level, className } = this.props
    const Tag = 'h' + level

    return <Tag className={cn('', 'level-' + level, className)}>{ children }</Tag>
  }

}

export default Heading