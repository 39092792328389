import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import {
  Bem,
  Enum,
  gotoLocation,
  unescapeHtml,
  generateLink,
  capitalizeFirstLetter,
  canUseDOM,
  getOriginFromUrl,
  getLocationHref,
  get,
  getLoginRedirectData,
  clearLoginRedirectData,
  requestUpgrade,
  goToAuthSuccess,
  getAuthSuccessUrl
} from '../../common/utils'
import TextField from '../../components/text-field/index'
import TextSeparator from '../../components/text-separator/index'
import SelectField from '../../components/select-field/index'
import { default as SocialLoginButtons, PROVIDERS as SocialLoginProviders } from '../../components/social-login-buttons/index'
import {USER_EMAIL, PASSWORD, CAPTCHA, CONFIRM_PASSWORD, CONSENTS, JUST_REQUIRED } from '../../common/validator-models'
import FormElement from '../../components/form-element/index'
import Form from '../../components/form/index'
import Button from '../../components/button/index'
import {first, forEach, reduce, noop, identity, pickBy, isString} from 'lodash'
import { createUser, login, resetPassword, updatePasswod } from '../../common/http'
import {LINKS, URLS, GTM_PARAMS_TRIGGERS, LOCAL_STORAGE_KEYS, GTM_EVENTS, COUNTRIES_DROPDOWN} from '../../common/consts'
import {If, Then, Else} from 'react-if'
import toCase from 'to-case'
import queryString from 'query-string'
import ConsentsCheckboxes from '@components/consents/consents-checkboxes'
import ConsentsForm from '../../components/consents/consents-form'
import store from "store";
import {connect} from "react-redux";
import createProvider from "../../store/provider";
import {setSignInRedirect} from "../../store/actions/app";
import {sendAnalyticsEvent} from "../../common/analytics";
import { MessageHandler } from "../../common/messageHandler";
import AuthSuccess from '../../components/auth-success'
import Icon from '@components/icon'
import JSONAPISerializer from "jsonapi-serializer";
import { SUCCESSFUL_SIGN_UP_PARAM } from './auth-gateway-view.consts'

const cn = new Bem({
  name: 'auth-gateway-view',
  prefix: 'pfx-'
})

export const PLANS = new Enum('FREE', 'INTRO', 'POWER', 'PREMIUM', 'STARTER', 'CREATOR', 'PRO')
export const TABS = new Enum('SIGN_UP', 'EDU_SIGN_UP', 'SIGN_IN', 'FORGOT_PASSWORD_BEFORE', 'FORGOT_PASSWORD_AFTER', 'CONFIRM_CONSENTS', 'SUCCESS')

const orgSignUpFormScheme = {
  firstName: {
    validation: JUST_REQUIRED,
  },
  lastName: {
    validation: JUST_REQUIRED,
  },
  role: {
    validation: JUST_REQUIRED
  },
  name: {
    validation: JUST_REQUIRED
  },
  country: {
    validation: JUST_REQUIRED
  }
}

const signInFormScheme = {
  email: {
    validation: USER_EMAIL
  },
  password: {
    validation: PASSWORD
  }
}

const forgotPasswordBeforeScheme = {
  email: {
    validation: USER_EMAIL
  }
}

const forgotPasswordAfterScheme = {
  password: {
    validation: PASSWORD
  },
  passwordConfirmation: {
    validation: CONFIRM_PASSWORD
  }
}

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: "camelCase",
});

class AuthGatewayView extends React.Component {
  constructor(props) {
    super(props)
    let {queryParams} = this.props

    this.localStorageSignInRedirectUrl = store.get(LOCAL_STORAGE_KEYS.SIGN_IN_REDIRECT_URL)
    this.state = {
      success: queryParams.success,
      tab: queryParams.tab,
      plan: queryParams.plan,
      couponCode: queryParams.couponCode,
      period: queryParams.period,
      productType: queryParams.productType,
      forgotPasswordBeforeSuccessful: false,
      referralId: queryParams.referralId,
      signUpRedirectUrl: this.getSignUpRedirectUrl(queryParams.plan, queryParams.productType, queryParams.couponCode, queryParams.period, queryParams.referralId),
      signInRedirectUrl: this.getSignInRedirectUrl(queryParams.plan, queryParams.productType, queryParams.couponCode, queryParams.period, queryParams.referralId),
      confirmConsentRedirectUrl: this.getConsentsRedirectUrl(queryParams.plan, queryParams.productType, queryParams.couponCode, queryParams.period, queryParams.referralId),
      showErrors: false,
    }
    this.formStateKeyPrefix = 'formResponse'

    this.onFormSignUpSubmit = this.onFormSignUpSubmit.bind(this)
    this.onFormSignInSubmit = this.onFormSignInSubmit.bind(this)
    this.onFormOrgSignUpSubmit = this.onFormOrgSignUpSubmit.bind(this)
    this.onFormForgotPasswordAfterSubmit = this.onFormForgotPasswordAfterSubmit.bind(this)
    this.onFormForgotPasswordBeforeSubmit = this.onFormForgotPasswordBeforeSubmit.bind(this)
    this.goToProjects = this.goToProjects.bind(this)
  }

  getOrigin() {
    if (canUseDOM()) {
      return window.location.origin
    } else {
      return URLS.PROD
    }
  }

  getSignUpFormScheme() {
    const signUpFormScheme = {
      email: {
        validation: USER_EMAIL
      },
      password: {
        validation: PASSWORD
      },
      passwordConfirmation: {
        validation: CONFIRM_PASSWORD
      },
      captcha: {
        validation: CAPTCHA
      },
      consents: {
        validation: CONSENTS(this.props.eduMode)
      }
    }
    return signUpFormScheme
  }

  getConsentsRedirectUrl(plan) {
    const baseLink = this.getOrigin() + LINKS.AUTH + '?' + queryString.stringify({
      tab: TABS.CONFIRM_CONSENTS,
      signUpConsent: true,
    })

    if (!plan || plan === PLANS.FREE) {
      return baseLink
    }

    return baseLink + '&' + queryString.stringify(this.getExtraParams())
  }

  getSignUpRedirectUrl(plan) {
    if (!plan || plan === PLANS.FREE) {
      if (MessageHandler.isInStudioLoginFlowPopup()) {
        return getAuthSuccessUrl("signed_up")
      }

      return this.getSignInRedirectUrl()
    } else {
      return this.getOrigin() + LINKS.AUTH_PLANS + '?' + queryString.stringify(this.getExtraParams())
    }
  }

  goToProjects() {
    return this.props.eduMode ? LINKS.STUDIO : LINKS.COMMUNITY
  }

  getSignInRedirectUrl(plan) {
    const loginRedirectData = getLoginRedirectData()

    if (loginRedirectData && loginRedirectData.url) {
      if (MessageHandler.isInStudioLoginFlowPopup()) {
        return getAuthSuccessUrl('signed_in')
      }
      return loginRedirectData.url
    } else if (!plan || plan === PLANS.FREE) {
      const { redirectTo } = this.props.queryParams

      if (MessageHandler.isInStudioLoginFlowPopup()) {
        return getAuthSuccessUrl('signed_in')
      }

      if (redirectTo === 'studio') {
        return this.goToProjects()
      } else if (isString(redirectTo)) {
        return redirectTo
      } else {
        return this.localStorageSignInRedirectUrl || this.goToProjects()
      }
    } else {
      return this.getOrigin() + LINKS.AUTH_PLANS + '?' + queryString.stringify(this.getExtraParams())
    }
  }

  startLoading() {
    this.setState({isLoading: true})
    // Brutal hack, will be handled later
    $('.pfx-auth-gateway-view__form-element--submit > button').addClass('pfx-button--loading')
  }

  stopLoading() {
    this.setState({isLoading: false})
    // Brutal hack, will be handled later
    $('.pfx-auth-gateway-view__form-element--submit > button').removeClass('pfx-button--loading')
  }

  componentDidMount() {
    if (!this.state.plan && this.state.tab === TABS.SING_UP) {
      this.setLocation(LINKS.AUTH_PLANS)
    }

    if (!this.state.tab) {
      this.setLocation(LINKS.AUTH, {tab: TABS.SIGN_IN})
    }
  }

  isOrganizationOwnerSignUp() {
    const { queryParams, eduMode } = this.props
    if (eduMode && !!queryParams.name && !!queryParams.firstName) {
      return true
    }

    return false
  }

  isOrganizationMemberSignUp() {
    const { queryParams, eduMode } = this.props
    if (eduMode && !queryParams.name) {
      return true
    }

    return false
  }

  setLastResponse(key, res, callback = noop) {
    let stateKey = `${this.formStateKeyPrefix}${capitalizeFirstLetter(key)}`
    if (res && res.data) {
      if (res.data.error) {
        res.data.error = reduce(res.data.error, (memo, errors, key) => {
          forEach(errors, (error) => {
            const parsedKey = toCase.constant(key).replace(/_/g, ' ')
            memo.push(`${capitalizeFirstLetter(parsedKey)} ${error}`)
          })
          return memo
        }, [])
        res.data.error = res.data.error.concat(res.data.captchaError).filter(identity)
      }

      if (res.data.loginError) {
        res.data.error = res.data.loginError
      }

      this.setState({
        [stateKey]: res.data
      }, (state) => callback(this.state[stateKey]))
    }
  }

  setLocation(url, params = {}) {
    const location = getLocationHref(url, params)
    window.location.replace(location);
    this.setQueryParam(params)
  }

  setQueryParam({tab, plan} = {}) {
    this.setState({tab, plan})
  }

  async onFormSignUpSubmit(form) {
    if (form.isValid) {
      let formData = await this.prepareSignUpRequest(form)
      this.startLoading()
      const res = await createUser(formData)
      const {setSignInRedirect} = this.props;

      const onFinished = (url, options) => {
        const checkoutNeeded = url === LINKS.AUTH_PLANS
        if (this.props.queryParams.closeAfterAuthentication === 'true' && !checkoutNeeded) {
          window.close()
        } else {
          gotoLocation(url, options)
        }
      }

      this.setLastResponse('signup', res, async () => {
        this.stopLoading()
        if (!this.countErrors('formResponseSignup')) {
          const redirectUrl = this.getSignInRedirectUrl()
          if (MessageHandler.isInStudioLoginFlowPopup()) {
            MessageHandler.postSignedUpMessage(this.state.plan)
          }

          if (res.request.responseURL.includes('/auth/verify_email')) {
            gotoLocation(res.request.responseURL)
            return
          }

          if (this.props.eduMode) {
            clearLoginRedirectData()
            setSignInRedirect(null)
            if (res.request.responseURL.includes('/auth/select_organization')) {
              onFinished(res.request.responseURL)
            } else {
              onFinished(redirectUrl)
            }
          } else {
            if (this.state.plan == PLANS.FREE) {
              const isSameOrigin = getOriginFromUrl(redirectUrl) === window.location.origin;

              if (!this.props.eduMode) {
                await sendAnalyticsEvent(GTM_EVENTS.USER_SIGN_UP)
              }

              if (MessageHandler.isInStudioLoginFlowPopup()) {
                goToAuthSuccess('signed_up')
                return
              }

              clearLoginRedirectData()
              setSignInRedirect(null)
              onFinished(redirectUrl, isSameOrigin ? {[SUCCESSFUL_SIGN_UP_PARAM]: true} : null)
            } else {
              onFinished(
                LINKS.AUTH_PLANS,
                {
                  [GTM_PARAMS_TRIGGERS.EMAIL_SIGN_UP]: true,
                  [SUCCESSFUL_SIGN_UP_PARAM]: true,
                  ...this.getExtraParams()
                }
              )
            }
          }
        }
      })
    }
  }

  async onFormOrgSignUpSubmit(form) {
    if (form.isValid) {
      let formData = this.prepareOrgSignUpRequest(form)
      const location = getLocationHref(LINKS.AUTH, {
        tab: TABS.SIGN_UP,
        ...formData.organization,
        ...this.getExtraParams()
      })
      gotoLocation(location)
    }
  }

  async onFormSignInSubmit(form) {
    if (form.isValid) {
      const formData = this.prepareSignInRequest(form)
      this.startLoading()
      const res = await login(formData)
      this.setLastResponse('signin', res, () => {
        if (!this.countErrors('formResponseSignin')) {
          if (this.props.queryParams.closeAfterAuthentication === 'true') {
            window.close()
          } else {
            gotoLocation(this.state.signInRedirectUrl)
            setSignInRedirect(null)
            this.stopLoading()
          }
        } else {
          this.stopLoading()
        }
      })
    }
  }

  async onFormForgotPasswordBeforeSubmit(form) {
    if (form.isValid) {
      const formData = this.prepareRequestPasswordRequest(form)
      this.startLoading()
      const res = await resetPassword(formData)
      this.setLastResponse('forgotPasswordBefore', res, () => {
        if (!this.countErrors('formResponseForgotpasswordbefore')) {
          this.setState({
            forgotPasswordBeforeSuccessful: true
          })
        } else {
          this.stopLoading()
        }
      })
    }
  }

  prepareRequestPassword(form) {
    const {values, formAuthenticityToken} = form
    return {
      utf8: '✓',
      authenticity_token: formAuthenticityToken,
      user: {
        reset_password_token: this.props.queryParams.resetPasswordToken,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      },
      _method: 'put'
    }
  }

  async onFormForgotPasswordAfterSubmit(form) {
    if (form.isValid) {
      const formData = this.prepareRequestPassword(form)
      this.startLoading()
      const res = await updatePasswod(formData)
      this.setLastResponse('forgotPasswordAfter', res, () => {
        if (!this.countErrors('formResponseForgotpasswordafter')) {
          gotoLocation(this.getSignInRedirectUrl())
        } else {
          this.stopLoading()
        }
      })
    }
  }

  countErrors(form) {
    return get(this.state, `${form}.error.length`)
  }

  prepareSignInRequest(form) {
    const {values, formAuthenticityToken} = form
    return {
      utf8: '✓',
      authenticity_token: formAuthenticityToken,
      user: {
        email: values.email,
        password: values.password
      }
    }
  }

  prepareRequestPasswordRequest(form) {
    const {values, formAuthenticityToken} = form

    return {
      utf8: '✓',
      authenticity_token: formAuthenticityToken,
      user: {
        email: values.email
      }
    }
  }

  async prepareSignUpRequest(form) {
    const { queryParams, eduMode } = this.props
    const {values, formAuthenticityToken} = form
    const { referralId } = this.state

    window.executeRecaptchaForRegistration()
    const captcha = document.getElementById('g-recaptcha-response-data-registration').value

    let consents

    if (eduMode) {
      consents = { terms: true }
    } else {
      consents = {
        age: get(values, 'consents.age', false),
        marketing: get(values, 'consents.marketing', false),
        terms: get(values, 'consents.terms', false)
      }
    }

    const data = pickBy({
      'utf8': '✓',
      'authenticity_token': formAuthenticityToken,
      'user': {
        email: values.email,
        password: values.password,
        password_confirmation: values.passwordConfirmation
      },
      'organization': {
        name: queryParams.name,
        country: queryParams.country,
        first_name: queryParams.firstName,
        last_name: queryParams.lastName,
        role: queryParams.role
      },
      'consents': consents,
      'g-recaptcha-response': captcha,
      'referral_id': referralId
    })

    return data
  }

  prepareOrgSignUpRequest(form) {
    const {values, formAuthenticityToken} = form

    return pickBy({
      'utf8': '✓',
      'authenticity_token': formAuthenticityToken,
      'organization': {
        name: values.name,
        country: values.country,
        role: values.role,
        first_name: values.firstName,
        last_name: values.lastName
      },
      'g-recaptcha-response': values.captcha,
    })
  }

  signUpSubmitButtonText() {
    const { eduMode } = this.props
    if (eduMode) {
      if (this.isOrganizationMemberSignUp()) {
        return 'Create account'
      } else if (this.isOrganizationOwnerSignUp()) {
        return 'Start 30-days free trial'
      }
    } else {
      return 'Continue'
    }
  }

  renderSignUpForm() {
    const {formAuthenticityToken, queryParams, eduMode } = this.props
    const { formResponseSignup } = this.state

    const error = get(formResponseSignup, 'error', false)

    return (
      <div className={cn('container', 'space')}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>Sign up</h4>
        </div>
        <If condition={this.isOrganizationOwnerSignUp()}>
          <div className={cn('edu-welcome-container')}>
            <Icon icon='academy' size={32} className={cn('edu-academy-icon')} />
            <div>
              <p className={cn('edu-welcome-title')}>{queryParams.firstName}, you are now the account owner of {queryParams.name}!</p>
              <p className={cn('edu-welcome-description')}>Your 30 day trial subscription is now ready. Invite teachers, create classes and invite students.</p>
            </div>
          </div>
        </If>
        <If condition={eduMode}>
          <div className={cn('edu-last-step')}>
            <If condition={this.isOrganizationOwnerSignUp()}>
              <Then>
                <p>The last step, choose how you want to log in.</p>
              </Then>
              <Else>
                <p>Create your personal account.</p>
              </Else>
            </If>
          </div>
        </If>
        <SocialLoginButtons
          providers={eduMode ? [SocialLoginProviders.GOOGLE] : [SocialLoginProviders.GOOGLE, SocialLoginProviders.FACEBOOK]}
          signUpRedirectUrl={this.state.signUpRedirectUrl}
          signInRedirectUrl={this.state.signInRedirectUrl}
          confirmConsentRedirectUrl={this.state.confirmConsentRedirectUrl}
          eduMode={eduMode}
          className={cn('social')}/>
        <TextSeparator className={cn('text-separator')}/>
        <If condition={!!error}>
          <div className={cn('block-message', null, 'pfx--red')}>
            {error}
          </div>
        </If>
        <Form
          scheme={this.getSignUpFormScheme()}
          onChange={this.onFormSignUpChange}
          onSubmit={this.onFormSignUpSubmit}
          className={cn('form')}
          formAuthenticityToken={formAuthenticityToken}>
          <FormElement field='email' className={cn('form-element', 'input')}>
            <TextField label='Email' type='text'/>
          </FormElement>
          <FormElement field='password' className={cn('form-element', 'input')}>
            <TextField label='Password' type='password'/>
          </FormElement>
          <FormElement field='passwordConfirmation' className={cn('form-element', 'input')} include={['password']}>
            <TextField label='Repeat password' type='password'/>
          </FormElement>
          <If condition={!eduMode}>
            <FormElement field='consents' className={cn('form-element')}>
              <ConsentsCheckboxes eduMode={eduMode}/>
            </FormElement>
          </If>
          <FormElement submit className={cn('form-element', 'submit')}>
            <Button
              type={eduMode ? 'klein-full-no-hover' : 'outline-black'}
              isLoading={this.state.isLoading}
              className={cn('submit')}>{this.signUpSubmitButtonText()}</Button>
          </FormElement>
        </Form>
        <If condition={eduMode}>
          <p className={cn('bottom-text')}>By proceeding, you agree to our <a href='https://soundation.com/terms-of-service' target='_blank'>Terms of Service</a> and <a href='https://soundation.com/privacy-policy' target='_blank'>Privacy Policy</a></p>
        </If>
        <p className={cn('bottom-text')}>Already have an account? <a
          href={generateLink(LINKS.AUTH, {tab: TABS.SIGN_IN, ...this.getExtraParams()})}>Log in</a></p>

      </div>
    )

  }

  renderOrgSignUpForm() {
    const {formAuthenticityToken, orgRoleOptions } = this.props
    const { formResponseOrgSignup } = this.state

    const error = get(formResponseOrgSignup, 'error', false)

    return (
      <div className={cn('container', 'space')}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>Create school account</h4>
        </div>
        <p className={cn('header-description')}>Get a 30-day free trial, no strings attached.</p>
        <If condition={!!error}>
          <div className={cn('block-message', null, 'pfx--red')}>
            {error}
          </div>
        </If>
        <Form
          scheme={orgSignUpFormScheme}
          onSubmit={this.onFormOrgSignUpSubmit}
          onChange={this.onFormOrgSignUpChange}
          className={cn('form')}
          formAuthenticityToken={formAuthenticityToken}>
          <FormElement field='firstName' className={cn('form-element', 'input')}>
            <TextField label='First name' type='text'/>
          </FormElement>
          <FormElement field='lastName' className={cn('form-element', 'input')}>
            <TextField label='Last name' type='text'/>
          </FormElement>
          <FormElement field='role' className={cn('form-element', 'input')}>
            <SelectField
              label={'Label'}
              defaultValue={orgRoleOptions[0].value}
              options={orgRoleOptions}
              className={cn('form-element')} />
          </FormElement>
          <FormElement field='name' className={cn('form-element', 'input')}>
            <TextField label='School / organization name' type='text'/>
          </FormElement>
          <FormElement field='country' className={cn('form-element', 'input')}>
            <SelectField
              label={'Country'}
              defaultValue={COUNTRIES_DROPDOWN[0].value}
              options={COUNTRIES_DROPDOWN}
              className={cn('form-element')} />
          </FormElement>
          <FormElement submit className={cn('form-element', 'submit')}>
            <Button
              type='klein-full-no-hover'
              isLoading={this.state.isLoading}
              className={cn('submit')}>Start 30-day free trial</Button>
          </FormElement>
        </Form>
      </div>
    )

  }

  renderSignInForm() {
    const { formAuthenticityToken, eduMode } = this.props
    const {formResponseSignin} = this.state
    const error = get(formResponseSignin, 'error', false)

    return (
      <div className={cn('container', 'space')}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>Log in</h4>
        </div>
        <SocialLoginButtons
          providers={eduMode ? [SocialLoginProviders.GOOGLE] : [SocialLoginProviders.GOOGLE, SocialLoginProviders.FACEBOOK]}
          className={cn('social')}
          labelPrefix='Log in'
          eduMode={eduMode}
          signUpRedirectUrl={this.state.signUpRedirectUrl}
          confirmConsentRedirectUrl={this.state.confirmConsentRedirectUrl}
          signInRedirectUrl={this.state.signInRedirectUrl}/>
        <TextSeparator className={cn('text-separator')}/>
        <If condition={!!error}>
          <div className={cn('block-message', null, 'pfx--red')}>
            {error}
          </div>
        </If>
        <Form
          scheme={signInFormScheme}
          onChange={this.onFormSignInChange}
          onSubmit={this.onFormSignInSubmit}
          className={cn('form')}
          isLoading={this.state.isLoading}
          formAuthenticityToken={formAuthenticityToken}>
          <FormElement field='email' className={cn('form-element', 'input')}>
            <TextField label='Email' type='text'/>
          </FormElement>
          <FormElement field='password' className={cn('form-element', 'input')}>
            <TextField label='Password' type='password'/>
          </FormElement>
          <div className={cn('forgot-password-link')}>
            <a href={generateLink(LINKS.AUTH, {tab: TABS.FORGOT_PASSWORD_BEFORE, ...this.getExtraParams()})}>Forgot password?</a>
          </div>
          <FormElement submit className={cn('form-element', 'submit')}>
            <Button
              isLoading={this.state.isLoading}
              type='outline-black'
              className={cn('submit')}> LOG IN </Button>
          </FormElement>
        </Form>
        <If condition={!eduMode}>
          <p className={cn('bottom-text')}>Don’t have an account? <a href={this.getSignUpLink()}>Sign up</a></p>
        </If>
      </div>
    )
  }

  renderCurrentForm() {
    switch (this.state.tab) {
      case TABS.SIGN_UP:
        return this.renderSignUpForm()
      case TABS.EDU_SIGN_UP:
        return this.renderOrgSignUpForm()
      case TABS.SIGN_IN:
        return this.renderSignInForm()
      case TABS.FORGOT_PASSWORD_BEFORE:
        return this.renderForgotPasswordBefore()
      case TABS.FORGOT_PASSWORD_AFTER:
        return this.renderForgotPasswordAfter()
      case TABS.CONFIRM_CONSENTS:
        return this.renderConfirmConsentsForm()
      case TABS.SUCCESS:
        return <AuthSuccess success={this.state.success} accountType={this.state.plan} />
    }
  }

  onConsentsFormAgree(res) {
    const {plan} = this.state
    const {currentUser} = this.props
    const params = this.getExtraParams()

    if (MessageHandler.isInStudioLoginFlowPopup()) {
      if (res.request.responseURL.includes('/auth/select_organization')) {
        gotoLocation(res.request.responseURL)
      } else if (!plan) {
        gotoLocation(this.getSignInRedirectUrl(), params)
      } else {
        gotoLocation(LINKS.AUTH_PLANS, params)
      }
      return
    }

    if (!currentUser) {
      params[GTM_PARAMS_TRIGGERS.EMAIL_SIGN_UP] = true
    }
    if (res.data.redirectTo) {
      gotoLocation(res.data.redirectTo)
    } else if (res.request.responseURL.includes('/auth/select_organization')) {
        gotoLocation(res.request.responseURL)
    } else if (!plan) {
      gotoLocation(this.getSignInRedirectUrl(), params)
    } else {
      gotoLocation(LINKS.AUTH_PLANS, params)
    }
  }

  renderConfirmConsentsForm() {
    const {formAuthenticityToken, currentUser, queryParams, eduMode} = this.props
    return (
      <div className={cn('container', {space: true})}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>We care about your privacy!</h4>
        </div>
        <div className={cn('wrapper')}>
          {!queryParams.signUpConsent && (
            <p className={cn('gdpr-info')}>We are updating our terms of service and privacy policy to strengthen the
              protection of your data.</p>
          )}
          <ConsentsForm
            onAgree={(backendResponse) => this.onConsentsFormAgree(backendResponse)}
            onLogout={() => gotoLocation('/')}
            currentUser={currentUser}
            queryParams={queryParams}
            eduMode={eduMode}
            formAuthenticityToken={formAuthenticityToken}/>
        </div>
      </div>
    )
  }

  renderForgotPasswordAfter() {
    const {formAuthenticityToken} = this.props
    let {formResponseForgotpasswordafter} = this.state
    const error = get(formResponseForgotpasswordafter, 'error', false)
    return (
      <div className={cn('container', {space: true})}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>Set new password!</h4>
        </div>
        <div className={cn('wrapper')}>
          <If condition={!!error}>
            <div className={cn('block-message', null, 'pfx--red')}>
              {error}
            </div>
          </If>
          <Form
            scheme={forgotPasswordAfterScheme}
            onSubmit={this.onFormForgotPasswordAfterSubmit}
            className={cn('form')}
            formAuthenticityToken={formAuthenticityToken}>
            <FormElement field='password' className={cn('form-element', 'input')}>
              <TextField label='New password' type='password'/>
            </FormElement>
            <FormElement field='passwordConfirmation' className={cn('form-element', 'input')} include={['password']}>
              <TextField label='Repeat new password' type='password'/>
            </FormElement>
            <FormElement submit className={cn('form-element', 'submit')}>
              <Button
                type='outline-black'
                isLoading={this.state.isLoading} className={cn('submit')}> RESET PASSWORD </Button>
            </FormElement>
          </Form>
        </div>
      </div>
    )
  }

  renderForgotPasswordBefore() {
    const { formAuthenticityToken, eduMode } = this.props
    let {forgotPasswordBeforeSuccessful, formResponseForgotpasswordbefore} = this.state
    const error = get(formResponseForgotpasswordbefore, 'error', false)

    return (
      <div className={cn('container', {space: true})}>
        <div className={cn('header')}>
          <a href="/"><img src="/assets/soundation-icon-dark.svg" alt="Soundation Logo" className={cn('logo')}/></a>
          <h4 className={cn('header-text')}>Reset password</h4>
        </div>
        <div className={cn('wrapper', {hide: forgotPasswordBeforeSuccessful})}>
          <p className={cn('forgot-paragraph')}>
            Enter your username or email address that you used to register. We'll send you an email with a link to reset
            your password.
          </p>
          <If condition={!!error}>
            <div className={cn('block-message', null, 'pfx--red')}>
              {error}
            </div>
          </If>
          <Form
            scheme={forgotPasswordBeforeScheme}
            onSubmit={this.onFormForgotPasswordBeforeSubmit}
            className={cn('form')}
            formAuthenticityToken={formAuthenticityToken}>
            <FormElement field='email' className={cn('form-element', 'input')}>
              <TextField label='Email' type='text'/>
            </FormElement>
            <FormElement submit className={cn('form-element', 'submit')}>
              <Button
                type='outline-black'
                isLoading={this.state.isLoading} className={cn('submit')}> RESET PASSWORD </Button>
            </FormElement>
          </Form>
          <If condition={!eduMode}>
            <p className={cn('bottom-text')}>Don’t have an account? <a href={this.getSignUpLink()}>Sign up</a>
            </p>
          </If>
        </div>
        <div className={cn('wrapper', {hide: !forgotPasswordBeforeSuccessful}, 'pfx--align-center')}>
          <img alt='Lock Icon' className={cn('forgot-success-image')} src='assets/soundation-big-icon-lock.svg'/>
          <p className={cn('forgot-success-paragraph', null, 'pfx--bold ')}>Check your inbox.</p>
          <p className={cn('forgot-success-paragraph')}>We’ve sent you a link with instructions to change your current
            password.</p>
          <Button
            type='outline-black'
            onClick={() => gotoLocation(LINKS.AUTH, {tab: TABS.SIGN_IN})}
            className={cn('forgot-success-button')}>OKAY</Button>
        </div>
      </div>
    )
  }

  getExtraParams () {
    const { queryParams } = this.props
    if (queryParams.plan) {
      return {
        plan: queryParams.plan,
        period: queryParams.period,
        product_type: queryParams.productType,
        coupon_code: queryParams.couponCode,
        referral_id: queryParams.referralId,
        close_after_authentication: queryParams.closeAfterAuthentication
      }
    }
    return {}
  }

  getSignUpLink () {
    const { queryParams, eduMode } = this.props
    const loginRedirectData = getLoginRedirectData()
    if (queryParams.couponCode || queryParams.referralId) {
      return getLocationHref(LINKS.AUTH, {
        tab: eduMode ? TABS.EDU_SIGN_UP : TABS.SIGN_UP,
        ...this.getExtraParams()
      })
    } else if (loginRedirectData && loginRedirectData.url) {
      return requestUpgrade(false, loginRedirectData.url, loginRedirectData.label)
    } else {
      if (eduMode) {
        return getLocationHref(LINKS.AUTH, {
          tab: TABS.EDU_SIGN_UP,
          ...this.getExtraParams()
        })
      } else {
        return generateLink(LINKS.AUTH_PLANS)
      }
    }
  }

  render () {
    return (
      <div className={cn(undefined, { popup: MessageHandler.isInStudioLoginFlowPopup() })}>
        {this.renderCurrentForm()}
      </div>
    )
  }
}

AuthGatewayView.propTypes = {
  eduMode: PropTypes.bool,
  formAuthenticityToken: PropTypes.string.isRequired,
  queryParams: PropTypes.shape({
    tab: PropTypes.string,
    plan: PropTypes.string,
    couponCode: PropTypes.string,
    period: PropTypes.string,
    productType: PropTypes.string,
    referralId: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.object
}

AuthGatewayView.defaultProps = {}

const mapDispatchToProps = {
  setSignInRedirect
}

export default createProvider(connect(null, mapDispatchToProps)(AuthGatewayView))
