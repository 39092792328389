import { get } from 'lodash'

const dataLayerSelector = state => get(state, 'analytics.dataLayer.data', {})
const gtmSettingsSelector = state => get(state, 'analytics.gtmSettings.data', {})

export const analyticsDataSelector = state => {
  return {
    dataLayer: dataLayerSelector(state),
    gtmSettings: gtmSettingsSelector(state)
  }
}
