import { Component } from 'react'
import BEM from 'react-bem-helper'

import Linkton, { COLOR, HOVER, TYPE, TAG } from '@components/linkton'
import { LINKS } from '../../common/consts'

import './style.scss'

const bem = BEM({
  prefix: 'pfx-',
  name: 'secret-passage'
})

export default class SecretPassageView extends Component {
  render () {
    return (
      <div {...bem()}>
        <p {...bem('header')}>You have found the secret passage to Soundation's free plan.</p>
        <Linkton
          type={TYPE.FULL}
          color={COLOR.OCEAN}
          thickness={2}
          tag={TAG.ANCHOR}
          hover={HOVER.NONE}
          size={16}
          href={`${LINKS.AUTH}?plan=free&product_type=subscription&tab=sign_up`}
        >Sign up for free plan</Linkton>
      </div>
    )
  }
}
