import { useEffect, useState } from 'react'

/** Based on https://github.com/jackmoore/autosize */
const useAutoResizeTextArea = ({ allowResize = true }) => {
  const [textarea, setTextarea] = useState()

  const resetTextareaHeight = () => {
    if (!textarea) return

    textarea.style.height = ''
  }

  useEffect(() => {
    if (!textarea) return undefined

    const computed = window.getComputedStyle(textarea)
    let previousHeight = null

    function cacheScrollTops(el) {
      const arr = []

      while (el && el.parentNode && el.parentNode instanceof Element) {
        if (el.parentNode.scrollTop) {
          arr.push([el.parentNode, el.parentNode.scrollTop])
        }
        el = el.parentNode
      }

      return () =>
        arr.forEach(([node, scrollTop]) => {
          node.style.scrollBehavior = 'auto'
          node.scrollTop = scrollTop
          node.style.scrollBehavior = ''
        })
    }

    const resizeTextarea = ({
      restoreTextAlign = null,
      testForHeightReduction = true
    }) => {
      const initialOverflowY = computed.overflowY

      if (textarea.scrollHeight === 0) {
        // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
        return
      }

      // disallow vertical resizing
      if (computed.resize === 'vertical') {
        textarea.style.resize = 'none'
      } else if (computed.resize === 'both') {
        textarea.style.resize = 'horizontal'
      }
      if (allowResize === false) textarea.style.resize = 'none'

      let restoreScrollTops

      // remove inline height style to accurately measure situations where the textarea should shrink
      // however, skip this step if the new value appends to the previous value, as textarea height should only have grown
      if (testForHeightReduction) {
        // ensure the scrollTop values of parent elements are not modified as a consequence of shrinking the textarea height
        restoreScrollTops = cacheScrollTops(textarea)
        textarea.style.height = ''
      }

      let newHeight

      if (computed.boxSizing === 'content-box') {
        newHeight = textarea.scrollHeight - (parseFloat(computed.paddingTop) + parseFloat(computed.paddingBottom))
      } else {
        newHeight = textarea.scrollHeight + parseFloat(computed.borderTopWidth) + parseFloat(computed.borderBottomWidth)
      }

      if (computed.maxHeight !== 'none' && newHeight > parseFloat(computed.maxHeight)) {
        if (computed.overflowY === 'hidden') {
          textarea.style.overflow = 'scroll'
        }
        newHeight = parseFloat(computed.maxHeight)
      } else if (computed.overflowY !== 'hidden') {
        textarea.style.overflow = 'hidden'
      }

      textarea.style.height = `${newHeight}px`

      if (restoreTextAlign) {
        textarea.style.textAlign = restoreTextAlign
      }

      if (restoreScrollTops) {
        restoreScrollTops()
      }

      if (previousHeight !== newHeight) {
        textarea.dispatchEvent(new Event('autosize:resized', { bubbles: true }))
        previousHeight = newHeight
      }

      if (initialOverflowY !== computed.overflow && !restoreTextAlign) {
        const { textAlign } = computed

        if (computed.overflow === 'hidden') {
          // Webkit fails to reflow text after overflow is hidden,
          // even if hiding overflow would allow text to fit more compactly.
          // The following is intended to force the necessary text reflow.
          textarea.style.textAlign = textAlign === 'start' ? 'end' : 'start'
        }

        resizeTextarea({
          restoreTextAlign: textAlign,
          testForHeightReduction: true
        })
      }
    }

    const fullSetHeight = () => {
      resizeTextarea({
        testForHeightReduction: true,
        restoreTextAlign: null
      })
    }

    let previousValue = textarea.value

    const handleInput = () => {
      resizeTextarea({
        // if previousValue is '', check for height shrinkage because the placeholder may be taking up space instead
        // if new value is merely appending to previous value, skip checking for height deduction
        testForHeightReduction: previousValue === '' || !textarea.value.startsWith(previousValue),
        restoreTextAlign: null
      })

      previousValue = textarea.value
    }

    textarea.addEventListener('input', handleInput)
    window.addEventListener('resize', fullSetHeight)

    textarea.style.overflowX = 'hidden'
    textarea.style.wordWrap = 'break-word'

    fullSetHeight()

    return () => {
      textarea.removeEventListener('input', handleInput)
      window.removeEventListener('resize', fullSetHeight)
    }
  }, [allowResize, textarea])

  return { setTextarea, textarea, resetTextareaHeight }
}

export default useAutoResizeTextArea
