// extracted by mini-css-extract-plugin
var _1 = "pfx-Conversation-module__avatar--ea6pr";
var _2 = "pfx-Conversation-module__avatar-img--Yy_8t";
var _3 = "#000";
var _4 = "1260px";
var _5 = "1020px";
var _6 = "668px";
var _7 = "1550px";
var _8 = "pfx-Conversation-module__community-title--RT9cK";
var _9 = "pfx-Conversation-module__container--mkuOy";
var _a = "1140px";
var _b = "940px";
var _c = "100%";
var _d = "15px";
var _e = "620px";
var _f = "#f53535";
var _10 = "rgba(0,0,0,.2)";
var _11 = "rgba(255,255,255,.38)";
var _12 = ".38";
var _13 = "rgba(255,255,255,.2)";
var _14 = "#0025ff";
var _15 = "pfx-Conversation-module__glitch-anim--jv5LT";
var _16 = "pfx-Conversation-module__glitch-anim-2--u1F1c";
var _17 = "#f60";
var _18 = "#41ead4";
var _19 = "#2cdd78";
var _1a = "#66ab23";
var _1b = "#f5f5f5";
var _1c = "#c3c3c3";
var _1d = "#ececec";
var _1e = "#252525";
var _1f = "#272727";
var _20 = "#c6c6c5";
var _21 = "#333";
var _22 = "#3c3c3c";
var _23 = "#9c9b9b";
var _24 = "#8c8c8c";
var _25 = "#6f6f6e";
var _26 = "#3c3c3b";
var _27 = "#373737";
var _28 = "#6a6a69";
var _29 = "#6f6f6f";
var _2a = "#9f9e9e";
var _2b = "#282828";
var _2c = "#828181";
var _2d = "#1d1d1d";
var _2e = "#979797";
var _2f = "#9c9b9b";
var _30 = "#a7a7a6";
var _31 = "#c6c6c5";
var _32 = "#d8d8d7";
var _33 = "#dddddc";
var _34 = "72px";
var _35 = "40px";
var _36 = "36px";
var _37 = "25px";
var _38 = "18px";
var _39 = "13px";
var _3a = "#0025ff";
var _3b = "rgba(255,255,255,.12)";
var _3c = "#6535f5";
var _3d = "2px";
var _3e = "56px";
var _3f = "56px";
var _40 = "pfx-Conversation-module__messages--G9YXi";
var _41 = "#41ead4";
var _42 = "#f60";
var _43 = "#f83600";
var _44 = "#881f00";
var _45 = "18px";
var _46 = "15px";
var _47 = "pfx-Conversation-module__pfx-icon--I486K";
var _48 = "red";
var _49 = "13px";
var _4a = "pfx-Conversation-module__submit--dtb05";
var _4b = "pfx-Conversation-module__submit-button--dO_iP";
var _4c = "rgba(255,255,255,.07)";
var _4d = "pfx-Conversation-module__textarea--E7AGT";
var _4e = "#35465c";
var _4f = "#55acee";
var _50 = "#fff";
var _51 = "pfx-Conversation-module__write-message--C5ppX";
var _52 = "pfx-Conversation-module__write-message-container--w_da2";
var _53 = "#ffce36";
var _54 = "#ffcd00";
var _55 = "red";
export { _1 as "avatar", _2 as "avatarImg", _3 as "black", _4 as "breakLarge", _5 as "breakMedium", _6 as "breakSmall", _7 as "breakVLarge", _8 as "communityTitle", _9 as "container", _a as "containerLarge", _b as "containerMedium", _c as "containerMobile", _d as "containerPaddingMobile", _e as "containerSmall", _f as "danger", _10 as "darkElement", _11 as "disabled", _12 as "disabledOpacity", _13 as "extraLightElement", _14 as "facebook", _15 as "glitchAnim", _16 as "glitchAnim2", _17 as "google", _18 as "green", _19 as "green2", _1a as "greenColor", _1b as "grey1", _1c as "grey10", _1d as "grey2", _1e as "grey25", _1f as "grey27", _20 as "grey3", _21 as "grey33", _22 as "grey3C", _23 as "grey4", _24 as "grey41", _25 as "grey5", _26 as "grey6", _27 as "grey61", _28 as "grey6A", _29 as "grey6F", _2a as "grey7", _2b as "grey8", _2c as "grey82", _2d as "grey9", _2e as "grey97", _2f as "grey9C", _30 as "greyA7", _31 as "greyC6", _32 as "greyD8", _33 as "greyDd", _34 as "header1", _35 as "header2", _36 as "header3", _37 as "header4", _38 as "header5", _39 as "header6", _3a as "klein", _3b as "lightElement", _3c as "lilac", _3d as "menuBorder", _3e as "menuHeight", _3f as "menuMobileHeight", _40 as "messages", _41 as "ocean", _42 as "orange", _43 as "orange2", _44 as "orangeDark", _45 as "paragraph", _46 as "paragraphSmall", _47 as "pfxIcon", _48 as "red", _49 as "subHeaderSmall", _4a as "submit", _4b as "submitButton", _4c as "subtleLightElement", _4d as "textarea", _4e as "tumblr", _4f as "twitter", _50 as "white", _51 as "writeMessage", _52 as "writeMessageContainer", _53 as "yellow", _54 as "yellowDeep", _55 as "youtube" }
