import React, { Component } from 'react';
import { Bem } from '@common/utils';
import PropTypes from 'prop-types';
import './style.scss';

const cn = new Bem({
  name: 'media-query',
  prefix: 'pfx-',
});

class MediaQuery extends Component {
  static propTypes = {
    minDevice: PropTypes.oneOf(['mobile', 'small', 'medium', 'large']),
    maxDevice: PropTypes.oneOf(['mobile', 'small', 'medium', 'large']),
  };

  static defaultProps = {};

  render() {
    const { children, className, minDevice, maxDevice } = this.props;

    return (
      <div
        className={cn(
          '',
          {
            [`min-device-${minDevice}`]: !!minDevice,
            'max-device': !!maxDevice,
            [`max-device-${maxDevice}`]: !!maxDevice,
          },
          className
        )}>
        {children}
      </div>
    );
  }
}

export default MediaQuery;
