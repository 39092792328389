// extracted by mini-css-extract-plugin
var _1 = "pfx-CommunityGroupHero-module__actions--nT_EF";
var _2 = "pfx-CommunityGroupHero-module__actions-container--iTyuJ";
var _3 = "pfx-CommunityGroupHero-module__admin--U6YbS";
var _4 = "#000";
var _5 = "1260px";
var _6 = "1020px";
var _7 = "668px";
var _8 = "1550px";
var _9 = "pfx-CommunityGroupHero-module__button--u_LVH";
var _a = "pfx-CommunityGroupHero-module__container--WbrQS";
var _b = "1140px";
var _c = "940px";
var _d = "100%";
var _e = "15px";
var _f = "620px";
var _10 = "#f53535";
var _11 = "rgba(0,0,0,.2)";
var _12 = "pfx-CommunityGroupHero-module__description--jw7bb";
var _13 = "pfx-CommunityGroupHero-module__description-container-desktop--aHogq";
var _14 = "pfx-CommunityGroupHero-module__description-mobile--aawud";
var _15 = "pfx-CommunityGroupHero-module__description-show-all--eJRpy";
var _16 = "pfx-CommunityGroupHero-module__desktop-dropdown--txSNI";
var _17 = "rgba(255,255,255,.38)";
var _18 = ".38";
var _19 = "pfx-CommunityGroupHero-module__dropdown--dIS4H";
var _1a = "pfx-CommunityGroupHero-module__dropdown-menu-item--p7dFC";
var _1b = "rgba(255,255,255,.2)";
var _1c = "#0025ff";
var _1d = "pfx-CommunityGroupHero-module__glitch-anim--azPU_";
var _1e = "pfx-CommunityGroupHero-module__glitch-anim-2--cfWS2";
var _1f = "#f60";
var _20 = "#41ead4";
var _21 = "#2cdd78";
var _22 = "#66ab23";
var _23 = "#f5f5f5";
var _24 = "#c3c3c3";
var _25 = "#ececec";
var _26 = "#252525";
var _27 = "#272727";
var _28 = "#c6c6c5";
var _29 = "#333";
var _2a = "#3c3c3c";
var _2b = "#9c9b9b";
var _2c = "#8c8c8c";
var _2d = "#6f6f6e";
var _2e = "#3c3c3b";
var _2f = "#373737";
var _30 = "#6a6a69";
var _31 = "#6f6f6f";
var _32 = "#9f9e9e";
var _33 = "#282828";
var _34 = "#828181";
var _35 = "#1d1d1d";
var _36 = "#979797";
var _37 = "#9c9b9b";
var _38 = "#a7a7a6";
var _39 = "#c6c6c5";
var _3a = "#d8d8d7";
var _3b = "#dddddc";
var _3c = "72px";
var _3d = "40px";
var _3e = "36px";
var _3f = "25px";
var _40 = "18px";
var _41 = "13px";
var _42 = "pfx-CommunityGroupHero-module__image--Ox7CA";
var _43 = "pfx-CommunityGroupHero-module__image-container-desktop--PYZiN";
var _44 = "pfx-CommunityGroupHero-module__image-container-mobile--S4xP5";
var _45 = "#0025ff";
var _46 = "rgba(255,255,255,.12)";
var _47 = "#6535f5";
var _48 = "pfx-CommunityGroupHero-module__main--s7747";
var _49 = "2px";
var _4a = "56px";
var _4b = "56px";
var _4c = "pfx-CommunityGroupHero-module__mobile-dropdown--gi2oS";
var _4d = "#41ead4";
var _4e = "#f60";
var _4f = "#f83600";
var _50 = "#881f00";
var _51 = "18px";
var _52 = "15px";
var _53 = "pfx-CommunityGroupHero-module__pfx-icon--Fn1Hl";
var _54 = "red";
var _55 = "pfx-CommunityGroupHero-module__show-more-description--DKqnd";
var _56 = "13px";
var _57 = "rgba(255,255,255,.07)";
var _58 = "#35465c";
var _59 = "#55acee";
var _5a = "#fff";
var _5b = "#ffce36";
var _5c = "#ffcd00";
var _5d = "red";
export { _1 as "actions", _2 as "actionsContainer", _3 as "admin", _4 as "black", _5 as "breakLarge", _6 as "breakMedium", _7 as "breakSmall", _8 as "breakVLarge", _9 as "button", _a as "container", _b as "containerLarge", _c as "containerMedium", _d as "containerMobile", _e as "containerPaddingMobile", _f as "containerSmall", _10 as "danger", _11 as "darkElement", _12 as "description", _13 as "descriptionContainerDesktop", _14 as "descriptionMobile", _15 as "descriptionShowAll", _16 as "desktopDropdown", _17 as "disabled", _18 as "disabledOpacity", _19 as "dropdown", _1a as "dropdownMenuItem", _1b as "extraLightElement", _1c as "facebook", _1d as "glitchAnim", _1e as "glitchAnim2", _1f as "google", _20 as "green", _21 as "green2", _22 as "greenColor", _23 as "grey1", _24 as "grey10", _25 as "grey2", _26 as "grey25", _27 as "grey27", _28 as "grey3", _29 as "grey33", _2a as "grey3C", _2b as "grey4", _2c as "grey41", _2d as "grey5", _2e as "grey6", _2f as "grey61", _30 as "grey6A", _31 as "grey6F", _32 as "grey7", _33 as "grey8", _34 as "grey82", _35 as "grey9", _36 as "grey97", _37 as "grey9C", _38 as "greyA7", _39 as "greyC6", _3a as "greyD8", _3b as "greyDd", _3c as "header1", _3d as "header2", _3e as "header3", _3f as "header4", _40 as "header5", _41 as "header6", _42 as "image", _43 as "imageContainerDesktop", _44 as "imageContainerMobile", _45 as "klein", _46 as "lightElement", _47 as "lilac", _48 as "main", _49 as "menuBorder", _4a as "menuHeight", _4b as "menuMobileHeight", _4c as "mobileDropdown", _4d as "ocean", _4e as "orange", _4f as "orange2", _50 as "orangeDark", _51 as "paragraph", _52 as "paragraphSmall", _53 as "pfxIcon", _54 as "red", _55 as "showMoreDescription", _56 as "subHeaderSmall", _57 as "subtleLightElement", _58 as "tumblr", _59 as "twitter", _5a as "white", _5b as "yellow", _5c as "yellowDeep", _5d as "youtube" }
