import React, { useCallback } from 'react'
import Icon from '@components/icon'
import { slide as SlideMenu } from 'react-burger-menu'
import * as styles from './HamburgerMenu.module.scss'

const HamburgerMenu = (props) => {
  const { children, className = '' } = props

  const renderCloseIcon = useCallback(() => <Icon className={styles.close} icon='new-close' size={18} />, [])

  return (
    <div className={`${styles.container} ${className}`}>
      <SlideMenu customCrossIcon={renderCloseIcon()}>
        {children}
      </SlideMenu>
    </div>
  )
}

export default React.memo(HamburgerMenu)
