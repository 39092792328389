import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { canUseDOM, gotoLocation } from '../../common/utils'
import { ACCOUNT_TYPES, LINKS, MAX_IMAGE_SIZE_MB } from '../../common/consts'
import ProfileHero from '../../components/profile-hero'
import { followUser, unfollowUser, getSignatureForFileUpload, uploadToS3, updateAccountProfile } from '../../common/http'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'

const withHoc = WrappedComponent => class ProfileHeroHoc extends Component {

  static propTypes = {
    userGuid: PropTypes.string.isRequired,
    userId: PropTypes.number.isRequired,
    userName: PropTypes.string.isRequired,
    profileLink: PropTypes.string.isRequired,
    avatarUrl: PropTypes.string.isRequired,
    coverPhotoUrl: PropTypes.string,
    editUrl: PropTypes.string,
    isSoundationCrew: PropTypes.bool.isRequired,
    isCurrentUser: PropTypes.bool.isRequired,
    isCurrentUserAdmin: PropTypes.bool.isRequired,
    currentUserFollows: PropTypes.bool.isRequired,
    canChangeCoverImage: PropTypes.bool.isRequired,
    canDelete: PropTypes.bool.isRequired,
    canReport: PropTypes.bool.isRequired,
    accountType: PropTypes.number.isRequired,
    isLoggedIn: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      currentUserFollows: props.currentUserFollows,
      coverPhotoUrl: props.accountType !== ACCOUNT_TYPES.FREE ? props.coverPhotoUrl : null,
      uploadProgress: 0,
    }
  }

  async requestFollow () {
    await followUser(this.props.profileLink)
    await this.setState({
      currentUserFollows: true
    })
  }

  async requestUnfollow () {
    await unfollowUser(this.props.profileLink)
    await this.setState({
      currentUserFollows: false
    })
  }

  onFollowChangeRequested (follow) {
    if (follow) {
      return this.requestFollow()
    } else {
      return this.requestUnfollow()
    }
  }

  onUpgradeAccountClick () {
    gotoLocation(LINKS.PRICING)
  }

  onEditAccountClick () {
    const { isCurrentUser, isCurrentUserAdmin, editUrl } = this.props
    if (isCurrentUser) {
      gotoLocation(LINKS.ACCOUNT_PROFILE)
    } else if (isCurrentUserAdmin) {
      if (canUseDOM()) {
        window.open(editUrl, '_blank')
      }
    }
  }

  async onCoverImageChange (file) {
    if (!file) {
      return
    }

    if (file.size >= MAX_IMAGE_SIZE_MB * 1024*1024) {
      alert(`Cannot upload ${file.name} as its size exceeds the ${MAX_IMAGE_SIZE_MB}MB limit.`)
      return
    }

    try {
      this.setState({
        uploadProgress: 0.05
      })
      const { fields, filename, headers, method, url } = await getSignatureForFileUpload(file.name, file.type)
      await uploadToS3({ fields, headers, method, url, file, onProgress: (progressEvent) => this.setState({ uploadProgress: progressEvent.loaded / progressEvent.total }) })

      await updateAccountProfile({
        userGuid: this.props.userGuid,
        data: { coverPhoto: { id: filename, metadata: {} } }
      })

      const preview = URL.createObjectURL(file)
      await this.setState({
        coverPhotoUrl: preview,
        uploadProgress: 0
      })
    } catch (e) {
      this.setState({
        uploadProgress: 0
      })
      alert('Could not upload a file')
    }
  }

  render () {
    const { editUrl, currentUserFollows, coverPhotoUrl: dummy, ...restProps } = this.props
    const { coverPhotoUrl, uploadProgress } = this.state

    return <WrappedComponent
      onFollowChangeRequested={follow => this.onFollowChangeRequested(follow)}
      onUpgradeAccountClick={() => this.onUpgradeAccountClick()}
      onEditAccountClick={() => this.onEditAccountClick()}
      currentUserFollows={this.state.currentUserFollows}
      onCoverImageChange={file => this.onCoverImageChange(file)}
      coverPhotoUrl={coverPhotoUrl}
      coverPhotoUploadProgress={uploadProgress}
      {...restProps}
    />
  }

}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.backendData.currentUser !== null
  }
}

export default createProvider(connect(mapStateToProps)(withHoc(ProfileHero)))
