import { Fragment, memo, useCallback, useMemo } from 'react'
import { DropdownControlled } from '@components/dropdown'
import BottomSheet from '@components/bottom-sheet'
import createProvider from '@store/provider'
import { isMobileSelector } from '@store/selectors/app'
import { connect } from 'react-redux'

// renders either a dropdown or a bottom sheet depending on device width
export const DropdownOrBottomSheet = memo(({ isMobile, isOpen, onToggle, toggler, children, dropdownProps = {}, dropdownClassName = '', bottomSheetClassName = '' }) => {
  // dropdown already does this, so we need to do it for bottom sheet as well
  const BottomSheetToggle = useMemo(() => {
    return React.cloneElement(
      toggler,
      {
        ...toggler.props,
        onClick: (e) => {
          if (toggler.props.onClick) toggler.props.onClick(e)
          onToggle(!isOpen)
        }
      },
      toggler.props.children
    )
  }, [toggler])
  const handleClose = useCallback(() => onToggle(false), [onToggle])

  return (
    isMobile ? (
      <Fragment>
        {BottomSheetToggle}
        <BottomSheet onClose={handleClose} isOpen={isOpen} className={bottomSheetClassName}>
          {children}
        </BottomSheet>
      </Fragment>
    ) : (
      <DropdownControlled
        className={dropdownClassName}
        toggle={toggler}
        isOpen={isOpen}
        onToggle={onToggle}
        closeOnInsideClick={false} // to make it identical to bottom sheet. You can close it programatically from the event handlers added to the children passed.
        {...dropdownProps}
      >
        {children}
      </DropdownControlled>
    )
  )
})
DropdownOrBottomSheet.displayName = 'DropdownOrBottomSheet'

const mapStateToProps = (state) => ({
  isMobile: isMobileSelector(state)
})
const DropdownOrBottomSheetConnected = createProvider(connect(mapStateToProps)(DropdownOrBottomSheet))
DropdownOrBottomSheetConnected.displayName = 'DropdownOrBottomSheetConnected'
export default DropdownOrBottomSheetConnected
