import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import RenderMarkdown from "@components/render-markdown";
import "./MarkdownBlock.style.scss";

const cn = new Bem({
  name: "markdown-block",
  prefix: "pfx-",
});

export const MarkdownBlockComponentId = "blocks.markdown";

const MarkdownBlock = ({ markdown }) => {
  return (
    <div className={cn()}>
      <div className={cn("wrapper")}>
        <RenderMarkdown markdownContent={markdown} />
      </div>
    </div>
  );
};

export const MarkdownBlockPropTypes = {
  markdown: PropTypes.string.isRequired,
};

MarkdownBlock.propTypes = PropTypes.shape(MarkdownBlockPropTypes).isRequired;

export default MarkdownBlock;
