// extracted by mini-css-extract-plugin
var _1 = "pfx-CommunityNewsItemPage-module__action--R4Hyu";
var _2 = "pfx-CommunityNewsItemPage-module__actions--f0etp";
var _3 = "pfx-CommunityNewsItemPage-module__audio-previews--iMtRw";
var _4 = "#000";
var _5 = "1260px";
var _6 = "1020px";
var _7 = "668px";
var _8 = "1550px";
var _9 = "pfx-CommunityNewsItemPage-module__comments--Uyovm";
var _a = "pfx-CommunityNewsItemPage-module__comments-header--q8jow";
var _b = "pfx-CommunityNewsItemPage-module__container--NB174";
var _c = "1140px";
var _d = "940px";
var _e = "100%";
var _f = "15px";
var _10 = "620px";
var _11 = "pfx-CommunityNewsItemPage-module__content--dWxMP";
var _12 = "pfx-CommunityNewsItemPage-module__content-container--aBEGT";
var _13 = "#f53535";
var _14 = "rgba(0,0,0,.2)";
var _15 = "rgba(255,255,255,.38)";
var _16 = ".38";
var _17 = "rgba(255,255,255,.2)";
var _18 = "#0025ff";
var _19 = "pfx-CommunityNewsItemPage-module__glitch-anim--Fmf9g";
var _1a = "pfx-CommunityNewsItemPage-module__glitch-anim-2--adGRA";
var _1b = "#f60";
var _1c = "#41ead4";
var _1d = "#2cdd78";
var _1e = "#66ab23";
var _1f = "#f5f5f5";
var _20 = "#c3c3c3";
var _21 = "#ececec";
var _22 = "#252525";
var _23 = "#272727";
var _24 = "#c6c6c5";
var _25 = "#333";
var _26 = "#3c3c3c";
var _27 = "#9c9b9b";
var _28 = "#8c8c8c";
var _29 = "#6f6f6e";
var _2a = "#3c3c3b";
var _2b = "#373737";
var _2c = "#6a6a69";
var _2d = "#6f6f6f";
var _2e = "#9f9e9e";
var _2f = "#282828";
var _30 = "#828181";
var _31 = "#1d1d1d";
var _32 = "#979797";
var _33 = "#9c9b9b";
var _34 = "#a7a7a6";
var _35 = "#c6c6c5";
var _36 = "#d8d8d7";
var _37 = "#dddddc";
var _38 = "pfx-CommunityNewsItemPage-module__header--vmoDS";
var _39 = "72px";
var _3a = "40px";
var _3b = "36px";
var _3c = "25px";
var _3d = "18px";
var _3e = "13px";
var _3f = "pfx-CommunityNewsItemPage-module__header-desktop--dDnbh";
var _40 = "pfx-CommunityNewsItemPage-module__header-mobile--SC9dq";
var _41 = "pfx-CommunityNewsItemPage-module__hero--Tey9t";
var _42 = "pfx-CommunityNewsItemPage-module__image--dmnuF";
var _43 = "#0025ff";
var _44 = "rgba(255,255,255,.12)";
var _45 = "#6535f5";
var _46 = "pfx-CommunityNewsItemPage-module__link--hBKK3";
var _47 = "2px";
var _48 = "56px";
var _49 = "56px";
var _4a = "#41ead4";
var _4b = "#f60";
var _4c = "#f83600";
var _4d = "#881f00";
var _4e = "18px";
var _4f = "15px";
var _50 = "pfx-CommunityNewsItemPage-module__player--Kr58f";
var _51 = "pfx-CommunityNewsItemPage-module__player-row--lkTkp";
var _52 = "red";
var _53 = "pfx-CommunityNewsItemPage-module__stat--i2yNp";
var _54 = "pfx-CommunityNewsItemPage-module__stats-desktop--MsDjZ";
var _55 = "13px";
var _56 = "rgba(255,255,255,.07)";
var _57 = "pfx-CommunityNewsItemPage-module__timestamp--TQel6";
var _58 = "pfx-CommunityNewsItemPage-module__title-desktop--QNOoV";
var _59 = "#35465c";
var _5a = "#55acee";
var _5b = "pfx-CommunityNewsItemPage-module__url-tag--pydEX";
var _5c = "#fff";
var _5d = "#ffce36";
var _5e = "#ffcd00";
var _5f = "red";
export { _1 as "action", _2 as "actions", _3 as "audioPreviews", _4 as "black", _5 as "breakLarge", _6 as "breakMedium", _7 as "breakSmall", _8 as "breakVLarge", _9 as "comments", _a as "commentsHeader", _b as "container", _c as "containerLarge", _d as "containerMedium", _e as "containerMobile", _f as "containerPaddingMobile", _10 as "containerSmall", _11 as "content", _12 as "contentContainer", _13 as "danger", _14 as "darkElement", _15 as "disabled", _16 as "disabledOpacity", _17 as "extraLightElement", _18 as "facebook", _19 as "glitchAnim", _1a as "glitchAnim2", _1b as "google", _1c as "green", _1d as "green2", _1e as "greenColor", _1f as "grey1", _20 as "grey10", _21 as "grey2", _22 as "grey25", _23 as "grey27", _24 as "grey3", _25 as "grey33", _26 as "grey3C", _27 as "grey4", _28 as "grey41", _29 as "grey5", _2a as "grey6", _2b as "grey61", _2c as "grey6A", _2d as "grey6F", _2e as "grey7", _2f as "grey8", _30 as "grey82", _31 as "grey9", _32 as "grey97", _33 as "grey9C", _34 as "greyA7", _35 as "greyC6", _36 as "greyD8", _37 as "greyDd", _38 as "header", _39 as "header1", _3a as "header2", _3b as "header3", _3c as "header4", _3d as "header5", _3e as "header6", _3f as "headerDesktop", _40 as "headerMobile", _41 as "hero", _42 as "image", _43 as "klein", _44 as "lightElement", _45 as "lilac", _46 as "link", _47 as "menuBorder", _48 as "menuHeight", _49 as "menuMobileHeight", _4a as "ocean", _4b as "orange", _4c as "orange2", _4d as "orangeDark", _4e as "paragraph", _4f as "paragraphSmall", _50 as "player", _51 as "playerRow", _52 as "red", _53 as "stat", _54 as "statsDesktop", _55 as "subHeaderSmall", _56 as "subtleLightElement", _57 as "timestamp", _58 as "titleDesktop", _59 as "tumblr", _5a as "twitter", _5b as "urlTag", _5c as "white", _5d as "yellow", _5e as "yellowDeep", _5f as "youtube" }
