import React, { Component } from 'react'
import { Bem } from '@common/utils'
import PropTypes from 'prop-types'
import { DISCORD } from '@common/consts'
import './style.scss'

const cn = new Bem({
  name: 'discord-widget',
  prefix: 'pfx-'
})

class DiscordWidgetBot extends Component {
  constructor(props) {
    super(props)
  }
  static propTypes = {
    height: PropTypes.string,
    width: PropTypes.string
  }

  static defaultProps = {
    height: '600',
    width: '100%'
  }

  render() {
    const { height, width, className } = this.props

    const url = `https://e.widgetbot.io/channels/${DISCORD.server}/${DISCORD.channels.halloweek}`
    return (
      <div className={cn('', '', className)} style={{ '--iframe-height': `${height}px` }}>
        <iframe
          className={cn('iframe')}
          src={url}
          height={height}
          width={width}></iframe>
      </div>
    )
  }
}

export default DiscordWidgetBot
