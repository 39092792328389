import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { denormalizeResponse, getUserFollowers, getTracksForUser, getUserGroups, getUserFollowing } from '@common/http'
import Spinner from '@components/spinner'
import CommunityTracksSection from '../community-tracks-section'
import CommunityGroupsSection from '../community-groups-section'
import CommunityUsersSection from '../community-users-section'
import CommunitySectionPlaceholder from '../community-section-placeholder'
import CommunitySection from '../community-section'
import { LINKS, COMMUNITY_CAROUSEL_ITEMS } from '@common/consts'
import { extractGroupsFromMemberships, extractUsersFromFollowings } from '@common/utils'
import * as styles from './CommunityUserSections.module.scss'

// user page sections: tracks/groups/followers/followings
const CommunityUserSections = (props) => {
  const { isProfileOwner, userGuid, userProfileLink } = props

  const [followers, setFollowers] = useState({ data: [] })
  const [followings, setFollowings] = useState({ data: [] })
  const [mixdowns, setMixdowns] = useState([])
  const [groups, setGroups] = useState([])
  const [isLoading, setIsLoading] = useState({ followers, followings, mixdowns, groups })
  const [hasNextPage, setHasNextPage] = useState({ followers: false, followings: false, mixdowns: false, groups: false })

  const updateIsLoading = useCallback((obj) => setIsLoading((cur) => ({ ...cur, ...obj })), [])

  // fetch sections
  useEffect(() => {
    updateIsLoading({ followers: true })
    getUserFollowers(userGuid, { items: COMMUNITY_CAROUSEL_ITEMS }).then(({ data }) => {
      const users = extractUsersFromFollowings(data, 'follower')
      setFollowers({ data: users })
      setHasNextPage((cur) => ({ ...cur, followers: !!data.meta?.next_page }))
    }).finally(() => updateIsLoading({ followers: false }))
  }, [userGuid, updateIsLoading])
  useEffect(() => {
    updateIsLoading({ followings: true })
    getUserFollowing(userGuid, { items: COMMUNITY_CAROUSEL_ITEMS }).then(({ data }) => {
      const users = extractUsersFromFollowings(data, 'followable')
      setFollowings({ data: users })
      setHasNextPage((cur) => ({ ...cur, followings: !!data.meta?.next_page }))
    }).finally(() => updateIsLoading({ followings: false }))
  }, [userGuid, updateIsLoading])
  useEffect(() => {
    updateIsLoading({ groups: true })
    getUserGroups(userGuid, { items: COMMUNITY_CAROUSEL_ITEMS }).then(({ data }) => {
      const groups = extractGroupsFromMemberships(denormalizeResponse(data))
      setGroups(groups)
      setHasNextPage((cur) => ({ ...cur, groups: !!data.meta?.next_page }))
    }).finally(() => updateIsLoading({ groups: false }))
  }, [userGuid, updateIsLoading])
  useEffect(() => {
    updateIsLoading({ mixdowns: true })
    getTracksForUser(userGuid, { items: COMMUNITY_CAROUSEL_ITEMS }).then(({ data }) => {
      setMixdowns(data)
      setHasNextPage((cur) => ({ ...cur, mixdowns: !!data.meta?.next_page }))
    }).finally(() => updateIsLoading({ mixdowns: false }))
  }, [userGuid, updateIsLoading])

  const renderSection = (label, items, renderFn, icon, message) => {
    const isSectionEmpty = Array.isArray(items) ? !items.length : !items.data.length
    if (isSectionEmpty) {
      if (isProfileOwner) return <CommunitySection label={label} items={[]} subHeader={<CommunitySectionPlaceholder icon={icon} message={message} />} />
      return null
    }

    return renderFn(items, label)
  }

  const replaceProfileLink = (link) => link.replace(':profileLink', userProfileLink)

  return (
    <div className={styles.container}>
      {renderSection('Tracks', mixdowns, (mixdowns, label) => <CommunityTracksSection isFirstSection tracks={mixdowns} label={label} pageLink={hasNextPage.mixdowns ? replaceProfileLink(LINKS.USER_TRACKS) : null} />, 'Project-file', <Fragment>You don't have any published tracks yet. <a className={styles.link} href={LINKS.COMMUNITY}>Create a track in the studio</a> and share it with the community</Fragment>)}
      {renderSection('Groups', groups, (groups, label) => <CommunityGroupsSection denormalize={false} groups={groups} label={label} showMoreLink={hasNextPage.groups ? replaceProfileLink(LINKS.USER_GROUPS) : null} />, 'Sofa', <Fragment>You haven't joined any groups yet. <a className={styles.link} href={LINKS.GROUPS}>Find one to join</a> to share your tracks and find likeminded creators</Fragment>)}
      {renderSection('Followers', followers, (followers, label) => <CommunityUsersSection users={followers} label={label} showMoreLink={hasNextPage.followers ? replaceProfileLink(LINKS.USER_FOLLOWERS) : null} />, 'share', 'You don\'t have any followers yet.')}
      {renderSection('Following', followings, (followings, label) => <CommunityUsersSection users={followings} label={label} showMoreLink={hasNextPage.followings ? replaceProfileLink(LINKS.USER_FOLLOWING) : null} />, 'User', <Fragment>You aren't following any users yet. <a className={styles.link} href={LINKS.USERS}>Find one to follow</a> and start to build your own community</Fragment>)}

      {!!Object.values(isLoading).includes(true) && <Spinner />}
    </div>
  )
}

export default CommunityUserSections
