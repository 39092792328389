import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {Bem, gotoStudio, gotoLocation, gotoAuth} from '../../../common/utils'
import Button from '../../../components/button/index'
import * as React from 'react'
import './remix-hero.scss'
import PropTypes from 'prop-types'
import {followGroup, joinGroup, unfollowGroup} from '../../../common/http'
import {debounce} from 'lodash'
import mobileDetect from '@common/mobile-detection'
import {LIMITATION_SIDEBAR} from '@containers/mobile-studio-limitation-sidebar/index'
import {openMobileLimitationSidebar} from '@store/actions/app'
import createProvider from '@store/provider'
import {connect} from 'react-redux'
import {sendAnalyticsEvent} from '../../../common/analytics'
import {GTM_EVENTS} from '../../../common/consts'
import BackgroundVideo from "../../../components/background-video/index";

const cn = new Bem({
  name: 'remix-hero',
  prefix: 'pfx-'
})

dayjs.extend(relativeTime)

class RemixHero extends React.Component {
  static propTypes = {
    followersCount: PropTypes.number.isRequired,
    publishedTrackCount: PropTypes.number.isRequired,
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    heroImageUrl: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    isCurrentUserGroupFollower: PropTypes.bool,
    isCurrentUserAdmin: PropTypes.bool,
    id: PropTypes.number,
    slug: PropTypes.string,
    subtitle: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.state = {
      isCurrentUserGroupFollower: this.props.isCurrentUserGroupFollower
    }
  }

  isFinished() {
    if (!this.props.endDate) return false
    return Date.now() > new Date(this.props.endDate).getTime()
  }

  renderStatsItem = (label, count) => {
    return (
      <div className={cn('summary-stats-item')}>
        <div className={cn('summary-stats-count')}>{count}</div>
        <div className={cn('summary-stats-label', null, 'pfx--mono')}>{label}</div>
      </div>
    )
  }

  async startRemix() {
    const {currentUser, slug} = this.props
    await sendAnalyticsEvent(GTM_EVENTS.GTM_STARTED_REMIX, 'Start Remix')
    gotoStudio(currentUser, false, slug)
  }

  gotoStudio = async () => {
    const {isCurrentUserGroupMember, groupId} = this.props

    if (mobileDetect.mobile()) {
      this.props.openMobileLimitationSidebar(LIMITATION_SIDEBAR.NO_MOBILE_APP_REMIX)
      return
    }

    if (isCurrentUserGroupMember) {
      return this.startRemix()
    }

    try {
      await joinGroup(groupId)
      this.startRemix()
    } catch (e) {
      this.startRemix()
    }
  }

  updateHeroHeight = debounce(ref => {
    if (ref) {
      ref.style.height = ref.clientHeight + 'px'
    }
  }, 300)

  shouldShowVideo() {
    const {videoMp4Url, videoWebmUrl} = this.props
    return !!(videoMp4Url || videoWebmUrl)
  }

  renderHeroImage = ({name, endDate, winnerAnnouncementAt, heroImageUrl, slug, currentUser, videoMp4Url, videoWebmUrl}) => {
    const backgroundImage = !this.shouldShowVideo() ? {backgroundImage: 'url(' + heroImageUrl + ')'} : null

    return (
      <div
        className={cn('hero')}
        style={backgroundImage}
        ref={r => this.updateHeroHeight(r)}
      >
        {
          this.shouldShowVideo() &&
          <BackgroundVideo
            urls={[videoWebmUrl, videoMp4Url]}
            className={cn('hero-video-container')}
            poster={{src: heroImageUrl}} />
        }
        <div className={cn('hero-container', null, 'container container--wider')}>
          <h1 className={cn('hero-header')}> {name} </h1>
          {
            !this.isFinished()
              ? (<a href='#' className={cn('hero-cta')}>
                <h1
                  data-pfx-require-sign-in
                  className={cn('hero-header')}
                  onClick={() => this.gotoStudio(currentUser, slug)}
                >
                  Start remixing
                </h1>
              </a>)
              : (<div href='#' className={cn('hero-cta', 'closed')}>
                <h1
                  data-pfx-require-sign-in
                  className={cn('hero-header')}
                >
                  Closed
                </h1>
              </div>)
          }
          <ul className={cn('hero-dates')}>
            <li className={cn('hero-date')}>Deadline: {endDate && dayjs(endDate).format('D MMMM YYYY')}</li>
            <li className={cn('hero-date')}>
              Winner announced: {winnerAnnouncementAt && dayjs(winnerAnnouncementAt).format('D MMMM YYYY')}
            </li>
          </ul>
        </div>
      </div>
    )
  }

  async handleFollow(isFollowing) {
    const {groupId, currentUser} = this.props

    if (!currentUser) {
      return gotoAuth()
    }

    this.setState({followButtonLoading: true})

    if (isFollowing) {
      await unfollowGroup(groupId)
    } else {
      await followGroup(groupId)
    }

    this.setState({
      followButtonLoading: false,
      isCurrentUserGroupFollower: !isFollowing
    })
  }

  renderSummaryPanel = ({
                          followersCount,
                          publishedTrackCount,
                          slug,
                          currentUser,
                          isCurrentUserGroupFollower,
                          followButtonLoading,
                          isCurrentUserAdmin,
                          id,
                          subtitle
                        }) => {
    return (
      <div className={cn('summary', null, 'container container--wider')}>
        <div className={cn('summary-details')}>
          <div className={cn('summary-name')}>{subtitle}</div>
        </div>
        <div className={cn('summary-stats')}>
          {this.renderStatsItem('Tracks', publishedTrackCount)}
          {this.renderStatsItem('Following', followersCount)}
        </div>
        <div className={cn('summary-buttons')}>
          {isCurrentUserAdmin && (
            <Button onClick={() => gotoLocation(`/account/groups/${id}/edit`)} type='outline'>
              Edit group
            </Button>
          )}
          <Button
            isLoading={followButtonLoading}
            data-pfx-require-sign-in
            onClick={() => this.handleFollow(isCurrentUserGroupFollower)}
            className={cn('summary-button')}
            type='outline'
          >
            {isCurrentUserGroupFollower ? 'Unfollow' : 'Follow'}
          </Button>
          <Button
            data-pfx-require-sign-in
            className={cn('summary-button')}
            onClick={() => this.gotoStudio(currentUser, slug)}
          >
            Start remix
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {
      followersCount,
      publishedTrackCount,
      groupId,
      name,
      endDate,
      winnerAnnouncementAt,
      heroImageUrl,
      currentUser,
      isCurrentUserAdmin,
      id,
      slug,
      subtitle,
      videoMp4Url,
      videoWebmUrl
    } = this.props

    const {followButtonLoading, isCurrentUserGroupFollower} = this.state

    return (
      <div className={cn()}>
        {this.renderHeroImage({
          name,
          endDate,
          winnerAnnouncementAt,
          heroImageUrl,
          groupId,
          currentUser,
          slug,
          videoWebmUrl,
          videoMp4Url
        })}
        {this.renderSummaryPanel({
          followersCount,
          publishedTrackCount,
          groupId,
          currentUser,
          followButtonLoading,
          isCurrentUserGroupFollower,
          isCurrentUserAdmin,
          id,
          slug,
          subtitle
        })}
      </div>
    )
  }
}

const mapDispatchToProps = {
  openMobileLimitationSidebar
}

export default createProvider(
  connect(
    null,
    mapDispatchToProps
  )(RemixHero)
)
