import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import Image from '@components/image'
import './PlayButton.style.scss'
import PlayIcon from '@images/play.svg'
import PauseIcon from '@images/pause.svg'

const cn = new Bem({
  name: 'play-button',
  prefix: 'pfx-'
})

const PlayButton = ({
  onClick,
  isActive,
  className,
  buttonClassName,
  isPlaying = false,
  isDisabled = false,
  size = 'normal',
  theme = 'blue'
}) => {
  const { icon, alt } = isPlaying
    ? { icon: PauseIcon, alt: 'Pause button' }
    : { icon: PlayIcon, alt: 'Play button' }

  return (
    <button onClick={onClick} className={cn('', '', className)} disabled={isDisabled}>
      <div
        className={cn(
          'play-button-bg',
          { active: isActive, [theme]: !!theme, [`size-${size}`]: !!size },
          buttonClassName
        )}
      >
        <Image
          className={cn('play-button', { active: isPlaying, disabled: isDisabled })}
          alt={alt}
          src={icon}
        />
      </div>
    </button>
  )
}

PlayButton.propTypes = {
  onClick: PropTypes.func,
  isActive: PropTypes.bool,
  isPlaying: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  theme: PropTypes.oneOf(['blue', 'dark']),
  size: PropTypes.oneOf(['normal', 'inherit'])
}

export { PlayButton }
