import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import './style.scss'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'img-with-fallback'
})

const ImgWithFallback = ({ src, fallbackSrc, alt, className }) => {
  const getExt = (path) => path.split('.').pop().toLowerCase()

  return (
    <picture className={className}>
      {fallbackSrc && <source srcSet={fallbackSrc} type={`image/${getExt(fallbackSrc)}`} />}
      <img src={src} alt={alt} />
    </picture>
  )
}

ImgWithFallback.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string
}

export default ImgWithFallback
