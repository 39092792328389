import { first, map } from 'lodash'
import { MONTHLY_ID, YEARLY_ID } from '@components/price-plans/base-config'
import { PRODUCT_TYPE, DISCOUNT_TYPE } from './consts'

export const getColumn = (pricingTableConfig, plan) => {
  return first(
    pricingTableConfig
      ? pricingTableConfig.filter(column => column.name.toLowerCase() === plan)
      : null
  )
}

export const translateBackendPeroid = period => {
  return period === 'year' || period === 'yearly' ? YEARLY_ID : MONTHLY_ID
}

export const getTotalCartPrice = ({ products, withCurrency, currency }) => {
  const total = products.reduce((sum, product) => sum + product.priceForCurrency, 0).toFixed(2)

  if (withCurrency) {
    return total + ' ' + currency
  } else {
    return Number(total)
  }
}

export const getButtonPrice = ({ productType, products, currency, subscriptionTotal }) => {
  switch (productType) {
    case PRODUCT_TYPE.SUBSCRIPTION:
      return `${(subscriptionTotal || 0).toFixed(2)} ${currency}`
    case PRODUCT_TYPE.SOUND:
      return getTotalCartPrice({ products, currency, withCurrency: true })
  }
}

export const prepareChargeRequest = ({
  discountAppliedCode,
  productType,
  products,
  accountType,
  activePeriod,
  referralId,
  paymentMethodId
}) => {
  switch (productType) {
    case PRODUCT_TYPE.SOUND:
      return {
        productIds: map(products, product => product.id),
        paymentMethodId
      }
    case PRODUCT_TYPE.SUBSCRIPTION:
      return {
        periodName: activePeriod + 'ly',
        accountType,
        coupon: discountAppliedCode,
        referralId,
        paymentMethodId
      }
  }
}

export const prepareDiscountMessage = ({
  discountAmount,
  discountValue,
  discountType,
  currency
}) => {
  let discountValueText

  switch (discountType) {
    case DISCOUNT_TYPE.PERCENTAGE:
      discountValueText = `${discountAmount}% off`
      break
    case DISCOUNT_TYPE.AMOUNT:
      discountValueText = `${discountValue} ${currency.toUpperCase()}`
      break
  }

  return `${discountValueText} discount was applied. Your cart has been updated.`
}
