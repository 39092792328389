import { actionTypes } from './types/analytics'
import { fetchDataLayer } from '@api/analytics'
import apiActionCreator from './apiActionCreator'
import { getGtmSettings } from '@common/analytics'

export const loadDataLayer = () => apiActionCreator(
  fetchDataLayer,
  actionTypes.LOAD_DATA_LAYER_SUCCESS,
  actionTypes.LOAD_DATA_LAYER_FAIL,
  actionTypes.LOAD_DATA_LAYER
)

export const loadGtmSettings = () => async dispatch => {
  dispatch({ type: actionTypes.LOAD_GTM_SETTINGS })
  getGtmSettings()
    .then(response => {
      dispatch({ type: actionTypes.LOAD_GTM_SETTINGS_SUCCESS, payload: response })
    })
    .catch(error => {
      dispatch({ type: actionTypes.LOAD_GTM_SETTINGS_FAIL, error: error })
    })
}
