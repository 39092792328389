import {extend, includes, isFunction} from 'lodash'
import { getDataLayer } from './http'
import store from 'store'
import { canUseDOM } from '@common/utils'
import {LOCAL_STORAGE_KEYS, FACEBOOK_CONTENT_TYPES} from "./consts";

const dataLayerLoadedCallbacks = []
const onGtmSettingsLoadedResolvers = []

let pendingEvents = []
let isDataLayerLoaded = false
let GTM_PROJECT_SETTINGS = null

const getFrontendDataLayer = (backendLayer = {}) => {
  const firstVisit = store.get(LOCAL_STORAGE_KEYS.FIRST_VISIT)
  const { createdAt, accountAssignedAt } = backendLayer
  const createAtTimestamp = new Date(createdAt).valueOf()
  const accountAssignedAtTimestamp = new Date(accountAssignedAt).valueOf()
  const calculateMinutes = (t1, t2) => {
    return Math.round((t1 - t2) / 1000 / 60)
  }

  let minutesToSignUp = null
  let minutesToUpgrade = null

  if (createdAt && createAtTimestamp >= firstVisit) {
    minutesToSignUp = calculateMinutes(createAtTimestamp, firstVisit)
  }

  if (accountAssignedAt && accountAssignedAtTimestamp >= createAtTimestamp) {
    minutesToUpgrade = calculateMinutes(accountAssignedAtTimestamp, createAtTimestamp)
  }

  return {
    firstVisit: new Date(firstVisit).toISOString(),
    minutesToSignUp,
    minutesToUpgrade,
  }
}

const getDataLayerFlat = () => window.dataLayer.reduce((obj, memo) => Object.assign(obj, memo), {})

const sendAnalyticsEvent = async (event, eventLabel, eventValue) => {
  if (!window.google_tag_manager) { return }

  await getGtmSettings()
  const dataLayer = await getDataLayer()
  window.dataLayer.push(dataLayer.data)
  return new Promise(resolve => {
    window.dataLayer.push({
      event,
      eventCallback: d => resolve(d),
      eventLabel,
      eventValue
    })
  })
}

const processPendingEvents = () => {
  pendingEvents.forEach((promise) => new Promise(promise))
  pendingEvents = []
}

const mergeWithFrontendLayer = (backendLayer) => extend(backendLayer, getFrontendDataLayer(backendLayer))

const onDataLayerLoaded = (callback) => dataLayerLoadedCallbacks.push(callback)

const dataLayerDidLoad = () => dataLayerLoadedCallbacks.forEach((c) => c())

const onGtmSettingsLoaded = () => new Promise(resolve => {
  resolve(onGtmSettingsLoadedResolvers.push(resolve))
})

const getGtmSettings = async () => {
  if (GTM_PROJECT_SETTINGS) {
    return GTM_PROJECT_SETTINGS
  } else {
    return onGtmSettingsLoaded()
  }
}

const isAnalyticsLoaded = () => isDataLayerLoaded

const getContentNameBasedOnSignUpUrl = () => {
  const url = store.get(LOCAL_STORAGE_KEYS.SIGN_IN_REDIRECT_URL)

  if (includes(url, '/group/')) {
    return FACEBOOK_CONTENT_TYPES.REMIX
  }

  return FACEBOOK_CONTENT_TYPES.ORGANIC

}

const getContentValueBasedOnSignUpUrl = () => {
  const url = store.get(LOCAL_STORAGE_KEYS.SIGN_IN_REDIRECT_URL)
  const contentName = getContentNameBasedOnSignUpUrl()

  if (contentName === FACEBOOK_CONTENT_TYPES.REMIX) {
    return url.replace(/\//g, '').split('group').pop().split('?').shift().split('#').shift()
  }

  return null
}

onDataLayerLoaded(() => {
  isDataLayerLoaded = true
  processPendingEvents()
})

if (canUseDOM()) {
  window._gtmSettingsLoaded = (settings) => {
    GTM_PROJECT_SETTINGS = Object.freeze(settings)
    onGtmSettingsLoadedResolvers.forEach(resolve => resolve(GTM_PROJECT_SETTINGS))
  }
}

export {
  getDataLayer,
  getDataLayerFlat,
  mergeWithFrontendLayer,
  sendAnalyticsEvent,
  onDataLayerLoaded,
  dataLayerDidLoad,
  onGtmSettingsLoaded,
  isAnalyticsLoaded,
  getGtmSettings,
  getContentNameBasedOnSignUpUrl,
  getContentValueBasedOnSignUpUrl,
}
