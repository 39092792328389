// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "pfx-Notification-module__body--Hgc5h";
var _3 = "1260px";
var _4 = "1020px";
var _5 = "668px";
var _6 = "1550px";
var _7 = "pfx-Notification-module__btn--SOgOZ";
var _8 = "1140px";
var _9 = "940px";
var _a = "100%";
var _b = "15px";
var _c = "620px";
var _d = "pfx-Notification-module__ctas-row--_fAG4";
var _e = "#f53535";
var _f = "rgba(0,0,0,.2)";
var _10 = "rgba(255,255,255,.38)";
var _11 = ".38";
var _12 = "rgba(255,255,255,.2)";
var _13 = "#0025ff";
var _14 = "pfx-Notification-module__first-row--ObD97";
var _15 = "pfx-Notification-module__glitch-anim--GZXiF";
var _16 = "pfx-Notification-module__glitch-anim-2--lZVs1";
var _17 = "#f60";
var _18 = "#41ead4";
var _19 = "#2cdd78";
var _1a = "#66ab23";
var _1b = "#f5f5f5";
var _1c = "#c3c3c3";
var _1d = "#ececec";
var _1e = "#252525";
var _1f = "#272727";
var _20 = "#c6c6c5";
var _21 = "#333";
var _22 = "#3c3c3c";
var _23 = "#9c9b9b";
var _24 = "#8c8c8c";
var _25 = "#6f6f6e";
var _26 = "#3c3c3b";
var _27 = "#373737";
var _28 = "#6a6a69";
var _29 = "#6f6f6f";
var _2a = "#9f9e9e";
var _2b = "#282828";
var _2c = "#828181";
var _2d = "#1d1d1d";
var _2e = "#979797";
var _2f = "#9c9b9b";
var _30 = "#a7a7a6";
var _31 = "#c6c6c5";
var _32 = "#d8d8d7";
var _33 = "#dddddc";
var _34 = "72px";
var _35 = "40px";
var _36 = "36px";
var _37 = "25px";
var _38 = "18px";
var _39 = "13px";
var _3a = "#0025ff";
var _3b = "rgba(255,255,255,.12)";
var _3c = "#6535f5";
var _3d = "2px";
var _3e = "56px";
var _3f = "56px";
var _40 = "pfx-Notification-module__message--P09CB";
var _41 = "#41ead4";
var _42 = "#f60";
var _43 = "#f83600";
var _44 = "#881f00";
var _45 = "18px";
var _46 = "15px";
var _47 = "pfx-Notification-module__read-status--VNbQb";
var _48 = "pfx-Notification-module__read-status--visible--fC6Kr";
var _49 = "red";
var _4a = "pfx-Notification-module__root--_UbPV";
var _4b = "pfx-Notification-module__stamp-and-msg--n7UgE";
var _4c = "pfx-Notification-module__status-container--Z2C_K";
var _4d = "13px";
var _4e = "rgba(255,255,255,.07)";
var _4f = "pfx-Notification-module__thumbnail--JPElW";
var _50 = "pfx-Notification-module__thumbnail-container--xfAOG";
var _51 = "pfx-Notification-module__thumbnail-wide--aZwbS";
var _52 = "pfx-Notification-module__timestamp--ZTyA8";
var _53 = "#35465c";
var _54 = "#55acee";
var _55 = "pfx-Notification-module__unread--gT25A";
var _56 = "#fff";
var _57 = "#ffce36";
var _58 = "#ffcd00";
var _59 = "red";
export { _1 as "black", _2 as "body", _3 as "breakLarge", _4 as "breakMedium", _5 as "breakSmall", _6 as "breakVLarge", _7 as "btn", _8 as "containerLarge", _9 as "containerMedium", _a as "containerMobile", _b as "containerPaddingMobile", _c as "containerSmall", _d as "ctasRow", _e as "danger", _f as "darkElement", _10 as "disabled", _11 as "disabledOpacity", _12 as "extraLightElement", _13 as "facebook", _14 as "firstRow", _15 as "glitchAnim", _16 as "glitchAnim2", _17 as "google", _18 as "green", _19 as "green2", _1a as "greenColor", _1b as "grey1", _1c as "grey10", _1d as "grey2", _1e as "grey25", _1f as "grey27", _20 as "grey3", _21 as "grey33", _22 as "grey3C", _23 as "grey4", _24 as "grey41", _25 as "grey5", _26 as "grey6", _27 as "grey61", _28 as "grey6A", _29 as "grey6F", _2a as "grey7", _2b as "grey8", _2c as "grey82", _2d as "grey9", _2e as "grey97", _2f as "grey9C", _30 as "greyA7", _31 as "greyC6", _32 as "greyD8", _33 as "greyDd", _34 as "header1", _35 as "header2", _36 as "header3", _37 as "header4", _38 as "header5", _39 as "header6", _3a as "klein", _3b as "lightElement", _3c as "lilac", _3d as "menuBorder", _3e as "menuHeight", _3f as "menuMobileHeight", _40 as "message", _41 as "ocean", _42 as "orange", _43 as "orange2", _44 as "orangeDark", _45 as "paragraph", _46 as "paragraphSmall", _47 as "readStatus", _48 as "readStatusVisible", _49 as "red", _4a as "root", _4b as "stampAndMsg", _4c as "statusContainer", _4d as "subHeaderSmall", _4e as "subtleLightElement", _4f as "thumbnail", _50 as "thumbnailContainer", _51 as "thumbnailWide", _52 as "timestamp", _53 as "tumblr", _54 as "twitter", _55 as "unread", _56 as "white", _57 as "yellow", _58 as "yellowDeep", _59 as "youtube" }
