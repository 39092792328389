/** All of the code here is run on the front once the document is loaded */
import reduxStore from './store'
import processQueryParams from './queryParams'
import trackFirstVisit from './trackFirstVisit'
import setValidateConfig from './validateConfig'
import { checkIfHalloweek } from '../store/actions/campaign'
import { setSignInRedirect } from '../store/actions/app'
import { isAuthLocation, setDocumentScroll } from '../common/utils'
import { onDataLayerLoaded, isAnalyticsLoaded } from '../common/analytics'

function onDomContentLoaded() {
    setDocumentScroll(true)
    if (!isAuthLocation()) {
        setSignInRedirect(null)(reduxStore.dispatch)
    }

    if (isAnalyticsLoaded()) {
        processQueryParams(reduxStore)
    } else {
        onDataLayerLoaded(() => processQueryParams())
        if (window.dataLayerDidLoad) { window.dataLayerDidLoad() }
    }

    reduxStore.dispatch(checkIfHalloweek())
}

export { trackFirstVisit, setValidateConfig, onDomContentLoaded }
