import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CommunityActionSearch from '@containers/community/community-action-search'
import Linkton from '@components/linkton'
import { Bem, get } from '@common/utils'
import { denormalizeResponse, searchCommunity } from '@common/http'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import { dismissSticky } from '@store/actions/group'
import './style.scss'

const cn = Bem({
  prefix: 'pfx-',
  name: 'invite-members'
})

class InviteMembers extends Component {
  static propTypes = {
    groupId: PropTypes.number.isRequired
  }

  state = {
    isSearchOpen: false
  }

  searchForUser = async (query) => {
    const { groupId } = this.props

    if (query) {
      const { data } = await searchCommunity({ term: query, entities: ['user'], group: groupId, pageSize: 150 })
      const users = denormalizeResponse(data.data.users)

      return users.filter((user) => !user.isMember).map((user) => ({
        id: user.id,
        img: user.avatarSmallUrl,
        name: user.username,
        extraName: user.realName,
        link: `/user/${user.profileLink}`,
        actionable: !user.isInvited,
        actionLabel: 'Invite',
        actionDoneLabel: 'Invited',
        onAction: () => this.handleInvite(user.id)
      }))
    }
  }

  toggleSearch = (value) => {
    this.setState({ isSearchOpen: value })
  }
  closeSearch = () => {
    this.setState({ isSearchOpen: false })
  }

  handleInvite = (userId) => {
    const { groupLink } = this.props
    const url = `/group/${groupLink}/invite/${userId}`
    window.location.href = url
  }

  render () {
    const { groupName } = this.props
    const { isSearchOpen } = this.state
    const title = `Invite to ${groupName}`

    return (
      <CommunityActionSearch open={isSearchOpen} onClose={this.closeSearch} onToggle={this.toggleSearch} onSearch={this.searchForUser} title={title} entityName='users' toggler={
        <div className={cn()}>
          <Linkton
            isResponsive
            className={cn('button', '', 'invite')}
            href={'#'}
            icon='plus'
            type='outline'
            iconPlacement='left'
          >
            Invite
          </Linkton>
        </div>
      } />
    )
  }
}

export default createProvider(
  connect(
    null,
    { dismissSticky }
  )(InviteMembers)
)
