'use client'
import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, Enum, getLocationHref, gotoLocation } from '../../common/utils'
import { LINKS } from '../../common/consts'
import Button from '../button/index'
import Icon from '../icon/index'
import { MessageHandler, oAuthPopupWindowName } from '../../common/messageHandler'
import { StudioConfiguration } from '../../common/config'
import GoogleButton from 'react-google-button'

const cn = new Bem({
  name: 'social-login-buttons',
  prefix: 'pfx-'
})

export const PROVIDERS = new Enum('FACEBOOK', 'GOOGLE')

class SocialLoginButtons extends React.Component {
  constructor (props) {
    super(props)

    this.PROVIDERS_DATA = {
      facebook: {
        url: LINKS.SOCIAL_LOGIN.FACEBOOK,
        label: `${this.props.labelPrefix} with Facebook`,
        icon: 'facebook'
      },
      google: {
        url: `${LINKS.SOCIAL_LOGIN.GOOGLE}`,
        label: `${this.props.labelPrefix} with Google`,
        icon: 'google'
      }
    }

    this.popupRef = React.createRef(null)
    this.timeoutRef = React.createRef(null)
  }

  componentDidMount () {
    if (MessageHandler.isInStudioLoginFlowPopup()) {
      // Setup listener for messages sent to the Window
      window.addEventListener('message', (event) => {
        const { action, payload } = event.data
        const { common, loginFlow } = StudioConfiguration.popup
        // [Event name]: Should close popup window
        const oAuthMessages = {
          [loginFlow.events.signIn]: true,
          [common.events.consentAgreed]: false,
          [loginFlow.events.signInOAuth]: true
        }
        const oAuthMessagesEventNames = Object.keys(oAuthMessages)

        // If the message came from the OAuth popup window and the popup is open, then re-play the message, so it will be picked up by the listener in the LegacyLoginFlowModal. This is because we are launching a new window from the modal
        if (this.isMessageFromOAuthPopup(event) && oAuthMessagesEventNames.includes(event.data.action)) {
          MessageHandler.postMessage(action, payload)

          if (!oAuthMessages[event.data.action]) return

          this.popupRef.current.close()
        }
      })
    }
  }

  componentWillUnmount () {
    if (this.popupRef.current) {
      this.popupRef.current.close()
    }

    if (this.timeoutRef.current) {
      clearInterval(this.timeoutRef.current)
    }
  }

  isMessageFromOAuthPopup = (event) => (
    event.origin === window.location.origin &&
    event.source &&
    event.source.name === oAuthPopupWindowName &&
    !!this.popupRef && !!this.popupRef.current
  )

  getRedirectParams () {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    return {
      signUpRedirectUrl: this.props.signUpRedirectUrl,
      signInRedirectUrl: this.props.signInRedirectUrl,
      confirmConsentRedirectUrl: this.props.confirmConsentRedirectUrl,
      name: params.get('name'),
      country: params.get('country'),
      first_name: params.get('first_name'),
      last_name: params.get('last_name'),
      role: params.get('role')
    }
  }

  handleOnClose = () => {
    this.popupRef.current = null
  }

  openOAuthLoginPopup = (provider) => {
    const { popupRef, timeoutRef, handleOnClose } = this
    const { url } = provider
    const width = 500
    const height = 600

    const urlWithParams = getLocationHref(url, this.getRedirectParams())

    if (!popupRef.current) {
      const left = width ? document.body.clientWidth / 2 - width / 2 : 0
      const top = height ? document.body.clientHeight / 2 - height / 2 : 0

      const defaultFeatures =
        'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no'

      const dynamicFeatures = `width=${width}, height=${height}, top=${top}, left=${left}`

      popupRef.current = window.open(
        urlWithParams,
        oAuthPopupWindowName,
        `${defaultFeatures}, ${dynamicFeatures}`
      )

      if (popupRef.current) {
        // this seems to be the best way to know if the window has closed...
        timeoutRef.current = setInterval(() => {
          if (timeoutRef.current && popupRef.current.closed) {
            clearInterval(timeoutRef.current)
            handleOnClose()
          }
        }, 200)
      }
    }
  }

  onClickSocialButton = (providerData) => {
    if (MessageHandler.isInStudioLoginFlowPopup()) {
      this.openOAuthLoginPopup(providerData)
      return
    }

    gotoLocation(providerData.url, this.getRedirectParams())
  }

  renderProviderButton (providerName, providerData) {
    const { eduMode } = this.props
    const handleOnClick = () => {
      this.onClickSocialButton(providerData)
    }

    const renderButton = () => {
      return (
        <Button
          type="hollow"
          className={cn("button", providerName)}
          key={providerName}
          onClick={handleOnClick}
        >
          <div className={cn("flex-wrapper")}>
            <Icon className={cn("icon")} icon={providerData.icon} size={20} />
            <span className={cn("text")}>{providerData.label}</span>
          </div>
        </Button>
      );
    };

    switch (providerName) {
      case PROVIDERS.GOOGLE: {
        if (eduMode) {
          return (
            <GoogleButton
              label={providerData.label}
              type="light"
              className={cn("button", providerName)}
              onClick={handleOnClick}
            />
          );
        }
        return renderButton()
      }
      default: {
        return renderButton()
      }
    }
  }

  render () {
    const { providers } = this.props
    return (
      <div className={cn()}>
        { providers.map((provider) => this.renderProviderButton(provider, this.PROVIDERS_DATA[provider])) }
      </div>
    )
  }
}

SocialLoginButtons.propTypes = {
  eduMode: PropTypes.bool,
  providers: PropTypes.arrayOf(
    PropTypes.oneOf([PROVIDERS.GOOGLE, PROVIDERS.FACEBOOK])
  ).isRequired,
  labelPrefix: PropTypes.string,
  signUpRedirectUrl: PropTypes.string,
  signInRedirectUrl: PropTypes.string,
  confirmConsentRedirectUrl: PropTypes.string
}

SocialLoginButtons.defaultProps = {
  providers: [PROVIDERS.GOOGLE, PROVIDERS.FACEBOOK],
  labelPrefix: 'Sign up',
  signUpRedirectUrl: null,
  signInRedirectUrl: null,
  confirmConsentRedirectUrl: null
}

export default SocialLoginButtons
