import { useEffect, useState } from 'react'

const ORDERS = {
    VISIBLE: 0,
    REMAINING: 1,
    HIDDEN: 2
}

/** Only renders elements for which there is enough space in the container. Returns the count of rendered elements.
 * @example
 * ``` 
 * const { renderedCount } = useOverflow(containerRef, itemsRef, remainingRef)
 * return (
 *  <div ref={containerRef}>
 *   <div ref={itemsRef}>
 *    <p>item 1</p>
 *    <p>item 2</p>
 *    <p ref={remainingRef}>+{renderedCount}</p>
 *   </div>
 *  </div>
 * )
 * ```
 */
export default function useOverflow (outerContainerRef, itemsContainerRef, remainingRef) {
    const [containerWidth, setContainerWidth] = useState(null)
    const [orderedItems, setOrderedItems] = useState([])
    const [renderedCount, setRenderedCount] = useState(0)

    // compute width for container on resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                setContainerWidth(entry.contentRect.width)
            }
        })

        if (outerContainerRef.current) resizeObserver.observe(outerContainerRef.current)
    }, [outerContainerRef])

    // compute width for each element on resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const widths = []
                let i = 0
                let remainderWidth = 0

                // sort by order to show offset widths as they are rendered
                const childrenSortedByOrder = Array.from(entry.target.children).sort((b, a) => (b.style.order ?? 0) - (a.style.order ?? 0))
                for (const child of childrenSortedByOrder) {
                    const isRemainder = child === remainingRef.current

                    let width = child.offsetLeft + child.offsetWidth

                    if (isRemainder) {
                        const previousWidth = widths[i - 1]?.width ?? 0
                        width = width - previousWidth
                        remainderWidth = width
                    } else {
                        // remove the remainder width from the calculation, else hidden items will require an extra "remainderWidth" to become visible since it's inserted between them and visible items.
                        width -= remainderWidth
                    }

                    widths.push({ child, width, isRemainder })
                    i += 1
                }

                setOrderedItems(widths)
            }
        })

        if (itemsContainerRef.current) resizeObserver.observe(itemsContainerRef.current)
    }, [itemsContainerRef])

    // show items which we can fit, placing the "remaining count" last; right after visible items. Set the count of visible items.
    useEffect(() => {
        const remainingCountWidth = orderedItems.find(({ isRemainder }) => isRemainder)?.width ?? 0
        orderedItems.forEach((item) => {
            const { child, width, isRemainder } = item

            if (isRemainder) {
                child.style.order = ORDERS.REMAINING
            } else {
                const shown = width <= (containerWidth - remainingCountWidth)
                item.shown = shown
                child.style.visibility = shown ? 'visible' : 'hidden'
                child.style.order = shown ? ORDERS.VISIBLE : ORDERS.HIDDEN
            }
        })

        const countOfShownElements = orderedItems.filter(({ isRemainder, shown }) => !isRemainder && shown).length
        setRenderedCount(countOfShownElements)
    }, [orderedItems, containerWidth])

    return { renderedCount }
}
