import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import './style.scss'

const cn = new Bem({
  name: 'crt-container',
  prefix: 'pfx-'
})

class CrtContainer extends React.Component {
  render () {
    const { children, className, ref, useOverlay, hasFlicker = false, hasScanline = false } = this.props
    return (
      <div className={cn('', { overlay: useOverlay, scanlines: hasScanline }, className)} ref={ref}>
        {hasFlicker && <div className={cn('flicker')} />}
        <div className={cn('static-noise', { flicker: hasFlicker })} />
        {children}
      </div>
    )
  }
}

CrtContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  hasFlicker: PropTypes.bool,
  hasScanline: PropTypes.bool,
  useOverlay: PropTypes.bool
}

CrtContainer.defaultProps = {
  children: null,
  className: undefined,
  hasFlicker: false,
  hasScanline: false,
  useOverlay: true
}

export default CrtContainer
