'use client'

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import Linkton, { COLOR, TYPE, HOVER } from '../linkton'
import { sendAnalyticsEvent } from '@common/analytics'
import Button from '@components/button'
import { GTM_EVENTS } from '@common/consts'

import './index.scss'

const cn = new Bem({
  name: 'cookie-consent',
  prefix: 'pfx-'
})

const CookieConsent = ({ localStorageProperty = 'shouldHideConsent', layout = 'default' }) => {
  const [shouldHideConsent, setShouldHideConsent] = useState(true)

  // get shouldHideConsent from localStorage
  useEffect(() => {
    setShouldHideConsent(localStorage.getItem(localStorageProperty) === 'true')
  }, [])

  const saveApproval = () => {
    localStorage.setItem(localStorageProperty, true)
    setShouldHideConsent(true)
    sendAnalyticsEvent(GTM_EVENTS.GTM_EVENT_COOKIE_CONSENT_GRANTED)
  }

  const renderButton = () => {
    if (layout === 'default') {
      return (
        <Button type='primary' onClick={saveApproval}>
          Got it
        </Button>
      )
    } else {
      return (
        <Linkton
          type={TYPE.OUTLINE}
          hover={HOVER.NONE}
          size={16}
          padding={100}
          onClick={saveApproval}
          color={COLOR.KLEIN}
          thickness={2}
        >
          Got it
        </Linkton>
      )
    }
  }

  if (!shouldHideConsent) {
    return (
      <div className={cn('', layout)}>
        <div className={cn('text')}>
          We use cookies to improve your experience.{' '}
          <a
            target='_blank'
            className={cn('link')}
            href='https://www.cookiesandyou.com/'
            rel='nofollow'
          >
            Learn more
          </a>
        </div>
        <div className={cn('button')}>{renderButton()}</div>
      </div>
    )
  } else {
    return null
  }
}

CookieConsent.propTypes = {
  localStorageProperty: PropTypes.string.isRequired,
  layout: PropTypes.oneOf(['default', 'landing2021'])
}

export default CookieConsent
