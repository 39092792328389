import React from 'react'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import { denormalizeResponse } from '@common/http'
import { selectCurrentUser } from '@store/selectors/backendData'
import { setFlashMessageByValues as setFlashMessageByValuesAction } from '@store/actions/flashMessage'
import CommunityProjectCard from '../community-project-card'
import CommunitySection from '../community-section'
import CommunityCreateProjectButton from '../community-create-project-button'
import * as styles from './CommunityProjectsSection.module.scss'

const CommunityProjectsSection = (props) => {
  const { className = '', pieces, label, showMoreLink, subHeader, reloadProject, reloadAll, setFlashMessageByValues, currentUser, denormalize = false, showCreateProject = true } = props

  const denormalizedPieces = denormalize ? denormalizeResponse(pieces) : pieces
  const section = {
    items: denormalizedPieces.map((piece) => <CommunityProjectCard key={piece.id} piece={piece} reloadProject={reloadProject} reloadAll={reloadAll} onError={setFlashMessageByValues} currentUser={currentUser} />),
    label,
    showMoreLink,
    subHeader: subHeader || (showCreateProject ? <CommunityCreateProjectButton className={styles.createProjectBtn} /> : null)
  }

  return (
    <CommunitySection
      className={className}
      items={section.items}
      label={section.label}
      showMoreLink={section.showMoreLink}
      subHeader={section.subHeader}
    />
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: selectCurrentUser(state)
  }
}
const mapDispatchToProps = {
  setFlashMessageByValues: setFlashMessageByValuesAction
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(CommunityProjectsSection), false)
