import React, { useCallback, useEffect, useState, Fragment, useRef, useMemo } from 'react'
import createProvider from '@store/provider'
import useInfiniteScrolling from '@hooks/useInfiniteScrolling'
import usePrevious from '@hooks/usePrevious'
import { connect, useSelector } from 'react-redux'
import Linkton from '@components/linkton'
import TextArea from '@components/text-area/text-area'
import { LINKS } from '@common/consts'
import { throttle } from 'lodash'
import { fetchConversation, fetchConversationMessages, denormalizeResponse, sendConversationMessage, markConversationAsRead } from '@common/http'
import { selectCurrentUser } from '@store/selectors/backendData'
import Spinner from '@components/spinner'
import usePaginate from '@hooks/usePaginate'
import useAutoResizeTextArea from '@hooks/useAutoResizeTextArea'
import Message from '../message'
import CommunityTitle from '@containers/community/community-title'
import * as styles from './Conversation.module.scss'

const Conversation = (props) => {
  const { id } = props
  const messagesContainerRef = useRef(null)

  const currentUser = useSelector(selectCurrentUser)

  const [conversation, setConversation] = useState(null)
  const [newMessage, setNewMessage] = useState('')
  const [defaultAvatarUrl, setDefaultAvatarUrl] = useState(null)

  // fetch conversation on mount
  useEffect(() => {
    fetchConversation({ id }).then((res) => {
      setConversation(denormalizeResponse(res.data))
    })
  }, [id])

  const fetcher = useCallback(async ({ page }) => {
    const res = await fetchConversationMessages({ id, page })
    setDefaultAvatarUrl(res.data.meta.default_avatar_url)
    return res
  }, [id])
  const { isLoading, data, page, fetchNextPage, reset } = usePaginate({ fetcher })
  const denormalized = denormalizeResponse(data)
  const sortedMessages = denormalized.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt))
  const fetchNextPageThrottled = useMemo(() => throttle(fetchNextPage, 250), [fetchNextPage])

  const scrollToBottom = useCallback(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }, [])

  const handleScroll = useInfiniteScrolling(fetchNextPageThrottled, 100, 'up')

  const { setTextarea: assignTextareaEl, resetTextareaHeight } = useAutoResizeTextArea({ allowResize: false })

  // scroll to bottom on data fetching of first page
  const previousPage = usePrevious(page)
  useEffect(() => {
    if (previousPage !== page && page === 1) {
      setTimeout(scrollToBottom, 100) // timeout to wait for messages to render so we have something to scroll
    }
  }, [scrollToBottom, page, previousPage, data])

  // mark conversation as id
  useEffect(() => {
    if (conversation) {
      markConversationAsRead(conversation.id)
    }
  }, [conversation])

  // fetch convos on mount
  useEffect(fetchNextPageThrottled, [])

  const submitMessage = () => {
    if (!newMessage) return

    sendConversationMessage({ conversationId: conversation.id, content: newMessage }).then(() => {
      setNewMessage('')
      reset()
      resetTextareaHeight()
      fetchNextPage()
    })
  }

  const conversationParticipants = conversation?.users?.filter((user) => user.id !== currentUser.guid)

  return (
    <div className={styles.container}>
      {!conversation ? (
        <Spinner />
      ) : (
        <Fragment>
          <CommunityTitle className={styles.communityTitle} title={conversationParticipants.map((user) => user.username).join(',')} showOnDesktop />

          {isLoading && <Spinner />}
          <div className={styles.messages} ref={messagesContainerRef} onScroll={handleScroll}>
            {sortedMessages.map((message) => (
              <Message
                key={message.id}
                users={[message.user]}
                lastUpdated={message.updatedAt}
                message={message.content}
                messageUrl={`${LINKS.MESSAGES}/${conversation.id}`}
                defaultAvatarUrl={defaultAvatarUrl}
              />
            ))}
          </div>

          <div className={styles.writeMessageContainer}>
            <div className={styles.writeMessage}>
              <div className={styles.avatar}>
                <img className={styles.avatarImg} src={currentUser.avatarSmallUrl} />
              </div>
              <TextArea ref={(r) => r && assignTextareaEl(r)} className={styles.textarea} maxLength='2000' onChange={setNewMessage} fontSize='small' isTransparent={false} value={newMessage} placeholder='Write a message' />
              <div className={styles.submit}>
                <Linkton className={styles.submitButton} onClick={submitMessage}>Send</Linkton>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default createProvider(connect(null, null)(Conversation, false))
