import React, { useState } from 'react'
import Modal from '@components/modal'
import CommunityButton from '@components/community-button'
import { extractError } from '@common/utils'
import { deleteAccount } from '@api/user'
import * as styles from './DeleteAccount.module.scss'

const DeleteAccount = (props) => {
  const { isOpen, closeModal, guid } = props

  const [error, setError] = useState()

  const handleDeleteAccount = () => {
    deleteAccount(guid).catch((err) => setError(extractError(err)))
  }

  const handleCloseModal = () => {
    setError(undefined)
    closeModal()
  }

  if (!guid) return null

  return (
    <Modal
      isOpen={isOpen}
      headerClassName={styles.header}
      bodyClassName={styles.body}
      windowClassName={styles.window}
      theme='dark'
      title='Delete account'
      onCloseButtonClick={handleCloseModal}
    >
      <div>
        <h2 className={styles.text}>Are you sure?</h2>
        <h3 className={styles.text}>You cannot undo this action</h3>
        {!!error && <h4 className={styles.error}>{error}</h4>}
        <div className={styles.buttons}>
          <CommunityButton fullWidthOnMobile={false} color='secondary_light' onClick={handleCloseModal}>Cancel</CommunityButton>
          <CommunityButton fullWidthOnMobile={false} color='danger' onClick={handleDeleteAccount}>Delete account</CommunityButton>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAccount
