// extracted by mini-css-extract-plugin
var _1 = "pfx-CommunityProjectCard-module__avatars--ta92L";
var _2 = "pfx-CommunityProjectCard-module__badge--vE2By";
var _3 = "#000";
var _4 = "1260px";
var _5 = "1020px";
var _6 = "668px";
var _7 = "1550px";
var _8 = "pfx-CommunityProjectCard-module__card--P745x";
var _9 = "pfx-CommunityProjectCard-module__container--U4dt5";
var _a = "1140px";
var _b = "940px";
var _c = "100%";
var _d = "15px";
var _e = "620px";
var _f = "pfx-CommunityProjectCard-module__cover--XR6xr";
var _10 = "#f53535";
var _11 = "rgba(0,0,0,.2)";
var _12 = "pfx-CommunityProjectCard-module__delete-project--ACwkL";
var _13 = "rgba(255,255,255,.38)";
var _14 = ".38";
var _15 = "rgba(255,255,255,.2)";
var _16 = "#0025ff";
var _17 = "pfx-CommunityProjectCard-module__glitch-anim--TZh1h";
var _18 = "pfx-CommunityProjectCard-module__glitch-anim-2--sQVeC";
var _19 = "#f60";
var _1a = "#41ead4";
var _1b = "#2cdd78";
var _1c = "#66ab23";
var _1d = "#f5f5f5";
var _1e = "#c3c3c3";
var _1f = "#ececec";
var _20 = "#252525";
var _21 = "#272727";
var _22 = "#c6c6c5";
var _23 = "#333";
var _24 = "#3c3c3c";
var _25 = "#9c9b9b";
var _26 = "#8c8c8c";
var _27 = "#6f6f6e";
var _28 = "#3c3c3b";
var _29 = "#373737";
var _2a = "#6a6a69";
var _2b = "#6f6f6f";
var _2c = "#9f9e9e";
var _2d = "#282828";
var _2e = "#828181";
var _2f = "#1d1d1d";
var _30 = "#979797";
var _31 = "#9c9b9b";
var _32 = "#a7a7a6";
var _33 = "#c6c6c5";
var _34 = "#d8d8d7";
var _35 = "#dddddc";
var _36 = "72px";
var _37 = "40px";
var _38 = "36px";
var _39 = "25px";
var _3a = "18px";
var _3b = "13px";
var _3c = "#0025ff";
var _3d = "pfx-CommunityProjectCard-module__last-context-item-in-section--eyUnD";
var _3e = "rgba(255,255,255,.12)";
var _3f = "#6535f5";
var _40 = "2px";
var _41 = "56px";
var _42 = "56px";
var _43 = "#41ead4";
var _44 = "#f60";
var _45 = "#f83600";
var _46 = "#881f00";
var _47 = "18px";
var _48 = "15px";
var _49 = "red";
var _4a = "13px";
var _4b = "rgba(255,255,255,.07)";
var _4c = "pfx-CommunityProjectCard-module__title-edit-mode--g7hRo";
var _4d = "pfx-CommunityProjectCard-module__title-input--N9opN";
var _4e = "#35465c";
var _4f = "#55acee";
var _50 = "#fff";
var _51 = "#ffce36";
var _52 = "#ffcd00";
var _53 = "red";
export { _1 as "avatars", _2 as "badge", _3 as "black", _4 as "breakLarge", _5 as "breakMedium", _6 as "breakSmall", _7 as "breakVLarge", _8 as "card", _9 as "container", _a as "containerLarge", _b as "containerMedium", _c as "containerMobile", _d as "containerPaddingMobile", _e as "containerSmall", _f as "cover", _10 as "danger", _11 as "darkElement", _12 as "deleteProject", _13 as "disabled", _14 as "disabledOpacity", _15 as "extraLightElement", _16 as "facebook", _17 as "glitchAnim", _18 as "glitchAnim2", _19 as "google", _1a as "green", _1b as "green2", _1c as "greenColor", _1d as "grey1", _1e as "grey10", _1f as "grey2", _20 as "grey25", _21 as "grey27", _22 as "grey3", _23 as "grey33", _24 as "grey3C", _25 as "grey4", _26 as "grey41", _27 as "grey5", _28 as "grey6", _29 as "grey61", _2a as "grey6A", _2b as "grey6F", _2c as "grey7", _2d as "grey8", _2e as "grey82", _2f as "grey9", _30 as "grey97", _31 as "grey9C", _32 as "greyA7", _33 as "greyC6", _34 as "greyD8", _35 as "greyDd", _36 as "header1", _37 as "header2", _38 as "header3", _39 as "header4", _3a as "header5", _3b as "header6", _3c as "klein", _3d as "lastContextItemInSection", _3e as "lightElement", _3f as "lilac", _40 as "menuBorder", _41 as "menuHeight", _42 as "menuMobileHeight", _43 as "ocean", _44 as "orange", _45 as "orange2", _46 as "orangeDark", _47 as "paragraph", _48 as "paragraphSmall", _49 as "red", _4a as "subHeaderSmall", _4b as "subtleLightElement", _4c as "titleEditMode", _4d as "titleInput", _4e as "tumblr", _4f as "twitter", _50 as "white", _51 as "yellow", _52 as "yellowDeep", _53 as "youtube" }
