import React from 'react'
import { parseNewsItemContent } from '@common/utils'
import Icon from '@components/icon'
import Markdown from 'react-markdown'
import CommunityCard from '../community-card'
import CommunityTag from '../community-tag'
import * as styles from './CommunityFeedCard.module.scss'

const CommunityFeedCard = React.forwardRef((props, ref) => {
  const { item, fillPage = false } = props

  const { id, createdAt, news } = item

  const renderTag = (tag, keepSpaceEvenIfNoTag = false) => {
    if (!tag && !keepSpaceEvenIfNoTag) return null

    return <CommunityTag className={`${styles.urlTag} ${!tag && keepSpaceEvenIfNoTag && styles.hiddenTag}`} icon={tag?.icon ?? ''} text={tag?.type ?? ''} />
  }
  const renderTimestamp = (timestamp, inCard) => {
    return <small className={`${styles.timestamp} ${!!inCard && styles.timestampInCard}`}>{timestamp}</small>
  }
  const renderBody = (body, inCard) => {
    return (
      <p className={`${styles.content} ${!!inCard && styles.contentInCard}`}>
        <Markdown 
          components={{
            a: ({node, ...props}) => <a {...props} className={styles.link} target='_blank' />,
            p: ({node, ...props}) => inCard ? <span {...props} /> : <p {...props} />
          }}
        >
          {body}
        </Markdown>
      </p>
    )
  }

  const { img, tag, timestamp, stats, title, body } = parseNewsItemContent({ createdAt, news })
  const newsLink = news.selfLink

  return (
    fillPage ? (
      <a ref={ref} key={id} className={styles.card} href={newsLink}>
        {!!img && <img className={styles.image} src={img.urlLarge} alt={img.alt} />}

        <div className={styles.body}>
          <div>
            {!!title && <h4 className={styles.title}>{title}</h4>}
            {renderTag(tag)}
            {renderTimestamp(timestamp, false)}
            {renderBody(body, false)}
          </div>

          <div className={styles.stats}>
            {stats.map((stat) => (
              <div className={styles.stat}>
                <Icon icon={stat.icon} size={16} />
                <span>{stat.count}</span>
              </div>
            ))}
          </div>
        </div>
      </a>
    ) : (
      <CommunityCard
        key={id}
        link={newsLink}
        ref={ref}
        picture={{ src: img.urlLarge, alt: img.alt, aspectRatio: '4x3' }}
        title={(
          <div>
            <h4 className={styles.cardTitle}>{title}</h4>
            {renderTag(tag, true)}
            {renderTimestamp(timestamp, true)}
          </div>
        )}
        subtitle={renderBody(body, true)}
        subtitleLines={3}
        stats={stats}
      />
    )
  )
})

CommunityFeedCard.displayName = 'CommunityFeedCard'
export default CommunityFeedCard
