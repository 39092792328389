import React, { Component } from 'react'
import './style.scss'
import { Bem } from '@common/utils'
import Link from '../link'
import PropTypes from 'prop-types'
import Button from '../button'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'

dayjs.extend(advancedFormat)

const cn = new Bem({
  prefix: 'pfx-',
  name: 'install-chrome'
})

export default class InstallChrome extends Component
{

  static propTypes = {
    type: PropTypes.oneOf(['remix', 'flash']),
    dueDate: PropTypes.string,
    onIgnore: PropTypes.func
  }

  static defaultProps = {
    type: 'remix'
  }

  getHeader () {
    if (this.props.type === 'remix') {
      return 'Upgrade to Chrome!'
    } else if (this.props.type === 'flash') {
      return 'Get Chrome'
    }
  }

  getBody () {
    if (this.props.type === 'remix') {
      return 'Your browser doesn’t support the features needed in our studio for the remix competition. Please download the Chrome Web Browser and try again.'
    } else if (this.props.type === 'flash') {
      const out = [<p className={cn('message-content-paragraph')} key={'b'}>To use the latest version of Soundation Studio and ensure the best online music production experience, you’ll need a Chrome browser. It might take some time to get used to it, but we know you’ll love it!</p>]
      const dueDate = this.props.dueDate ? dayjs(this.props.dueDate) : null
      if (dueDate && dayjs().isBefore(dueDate)) {
        out.unshift(
          <p className={cn('message-content-paragraph')} key={'a'}>
            From { dueDate.format('MMMM Do YYYY') } you will no longer be able to use the Flash version of Soundation.
          </p>
        )
      } else if (this.props.dueDate) {
        out.unshift(
          <p className={cn('message-content-paragraph')} key={'a'}>
            Since { dueDate.format('MMMM Do YYYY') } you are no longer able to use the Flash version of Soundation.
          </p>
        )
      }
      return out
    }
  }

  getFooter () {
    if (this.props.type === 'remix') {
      return <Link href='https://www.google.com/chrome/' target='_blank'>Get Chrome</Link>
    } else if (this.props.type === 'flash') {
      const out = [<Link key={'chrome'} href='https://www.google.com/chrome/' target='_blank' type={'gradient_secondary'}>Get Chrome</Link>]

      const dueDate = this.props.dueDate ? dayjs(this.props.dueDate) : null

      if (!dueDate || dayjs().isBefore(dueDate)) {
        out.push(<Button key={'flash'} type={'hollow'} size={'small'} className={cn('doitlater')} onClick={() => this.props.onIgnore()}>I’ll do it later</Button>)
      }

      return out
    }
  }

  render () {
    const { type } = this.props

    return (
      <div className={cn('', type)}>
        <div className={cn('message')}>
          <div className={cn('message-header')}>{ this.getHeader() }</div>
          <div className={cn('message-content')}>{ this.getBody() }</div>
          <div className={cn('message-footer')}>{ this.getFooter() }</div>
        </div>
      </div>
    )
  }
}
