'use client'
import React from "react";
import PropTypes from "prop-types";
import "./style.scss";
import {
  Bem, isWideContainerContext,
  get
} from "../../common/utils";
import { LINKS } from "../../common/consts";
import { mapValues, isFunction, noop } from "lodash";
import Logo from "../../components/logo/index";
import SocialIcons from "../../components/social-icons/index";
import createProvider from "../../store/provider";
import { connect } from "react-redux";
import { openMobileLimitationSidebar } from "../../store/actions/app";
import { Logger } from "../../common/logger";
import { getStrapiLinkFromFooter } from '../../contexts/StrapiContext/StrapiContext.utils';
import {
  StrapiContext,
  StrapiContextProvider,
} from "../../contexts/StrapiContext";
import { withErrorBoundary } from "@components/error-boundary";

const cn = new Bem({
  name: "footer",
  prefix: "pfx-",
});

const FooterLogger = new Logger({
  name: "Footer",
});

class Footer extends React.Component {
  static contextType = StrapiContext;

  constructor(props) {
    super(props);

    const MENU_LINKS = {
      LEARN: {
        url: LINKS.LEARN,
        label: "Learn",
      },
      SUPPORT: {
        url: LINKS.SUPPORT,
        label: "Support",
        target: "_blank",
      },
      COMMUNITY: {
        url: () => (this.currentUser ? LINKS.FEED : LINKS.TRACKS),
        label: "Community",
      },
      SHOP: {
        url: LINKS.SHOP,
        label: "Sound Shop",
      },
      PRICING: {
        url: LINKS.PRICING,
        label: "Pricing",
      },
    };

    const USEFUL_LINKS = {
      FEATURES: {
        url: LINKS.FEATURES,
        label: "Features & Requirements",
      },
      PRIVACY_POLICY: {
        url: LINKS.PRIVACY_POLICY,
        label: "Privacy Policy",
      },
      LICENSING_AGREEMENT: {
        url: LINKS.LICENSING_AGREEMENT,
        label: "Licensing Agreement",
      },
      BLOG: {
        url: LINKS.BLOG,
        label: "Blog",
        target: "_blank",
      },
      EDUCATION: {
        url: LINKS.EDUCATION,
        label: "Soundation 4 Education",
        target: "_blank",
      },
      TERMS_OF_SERVICE: {
        url: LINKS.TERMS_OF_SERVICE,
        label: "Terms of service",
      },
      EDUCATION_PLAN: {
        url: LINKS.EDUCATION_PLAN,
        label: "Soundation Education Plan",
        target: "_blank",
      },
      PRESS: {
        url: LINKS.PRESS,
        label: "Press",
        target: "_blank",
      },
      JOBS: {
        url: LINKS.JOBS,
        label: "Jobs",
        target: "_blank",
      },
    };

    this.state = {
      mobileLimitationSideOpen: false,
      menuLinkList: this.createLinksList([
        MENU_LINKS.LEARN,
        MENU_LINKS.SUPPORT,
        MENU_LINKS.COMMUNITY,
        MENU_LINKS.SHOP,
        MENU_LINKS.PRICING,
      ]),
      usefulLinks: this.createLinksList([
        USEFUL_LINKS.EDUCATION,
        USEFUL_LINKS.FEATURES,
        USEFUL_LINKS.TERMS_OF_SERVICE,
        USEFUL_LINKS.PRIVACY_POLICY,
        USEFUL_LINKS.LICENSING_AGREEMENT,
        USEFUL_LINKS.BLOG,
        USEFUL_LINKS.PRESS,
        USEFUL_LINKS.JOBS,
      ]),
      widePageContext: false,
    };
  }

  createLinksList(sortedLinkList) {
    return sortedLinkList.map((link) => {
      return mapValues(link, (value, key) =>
        isFunction(value) && key !== "onClick" ? value() : value
      );
    });
  }

  componentDidMount() {
    this.setState({
      widePageContext: isWideContainerContext(),
    });
  }

  isInverted() {
    return this.props.theme === "light";
  }

  getStrapiLinkItem = (item) => {
    const { data } = this.context.navigation;

    return getStrapiLinkFromFooter({ item, navigation: data });
  };

  getStrapiLinkColumns = () => {
    const { footer } = this.props;

    if (!footer) return null;
    return (footer.links || []).map(({ name: linkName, collection }) => {
      const { links, name: collectionName } = collection;
      return {
        name: collectionName || linkName,
        links: (links || [])
          .map((item) => this.getStrapiLinkItem(item))
          .filter((item) => !!item),
      };
    });
  };

  renderListItems = (links) => {
    return links.map((link, i) => {
      const { disabled } = link;
      return (
        <div className={cn("list-item", { disabled })} key={`${link.url}_${i}`}>
          <a
            href={link.url}
            onClick={() => (link.onClick || noop)()}
            target={link.target || "_self"}
            className={cn("list-item-link")}
            rel={
              link.url === LINKS.EDUCATION
                ? "noopener noreferrer nofollow"
                : undefined
            }
          >
            {link.label}
          </a>
        </div>
      );
    });
  };

  renderLinkColumns = () => {
    const { usefulLinks, menuLinkList } = this.state;
    const columns = this.getStrapiLinkColumns() || [
      { name: undefined, links: menuLinkList, id: 1 },
      { name: undefined, links: usefulLinks, id: 2 },
    ];

    return columns.map(({ id, name, links }) => {
      return (
        <div className={cn("link-list")} key={`${id}_${name}`}>
          {name && <label className={cn("link-list-title")}>{name}</label>}
          <div>{this.renderListItems(links)}</div>
        </div>
      );
    });
  };

  render() {
    const { footer } = this.props;
    FooterLogger.log("Rendering Footer", footer);

    const supportEmail = footer?.supportEmail || "support@soundation.com";
    const copyright =
      footer?.copyright || `${new Date().getFullYear()} Soundation`;

    return (
      <footer
        className={cn("", {
          inverted: this.isInverted(),
        })}
      >
        <div className={cn("container", "")}>
          <div className={cn("top-container")}>
            <Logo type="icon" color="light" className={cn("logo")} />
            <div className={cn("link-grid")}>{this.renderLinkColumns()}</div>
          </div>
          <div className={cn("bottom-container")}>
            <div className={cn("support-info")}>
              <a
                className={cn("support-email")}
                href={`mailto:${supportEmail}`}
              >
                {supportEmail}
              </a>
              <span className={cn("copyrights")}>&copy; {copyright}</span>
            </div>
            <SocialIcons
              className={cn("social-icons")}
              iconSize={20}
              iconBoxSize={32}
              iconsDistance={8}
              providers={[
                "facebook",
                "twitter",
                "youtube",
                "instagram",
                "discord",
              ]}
            />
          </div>
        </div>
      </footer>
    );
  }
}

const FooterWithStrapiProvider = ({ navigation, ...props }) => {
  const { currentUser = null, theme = 'dark', showBrowserInfo = true } = props

  return (
    <StrapiContextProvider navigation={navigation}>
      <Footer {...props} currentUser={currentUser} theme={theme} showBrowserInfo={showBrowserInfo} />
    </StrapiContextProvider>
  );
};

FooterWithStrapiProvider.propTypes = {
  currentUser: PropTypes.object,
  openMobileLimitationSidebar: PropTypes.func,
  theme: PropTypes.oneOf(["light", "dark", false]),
  showBrowserInfo: PropTypes.bool,
  navigation: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  footer: PropTypes.shape({
    copyright: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        collection: PropTypes.shape({
          name: PropTypes.string,
          links: PropTypes.arrayOf(
            PropTypes.oneOfType([
              PropTypes.shape({
                id: PropTypes.number,
                __component: PropTypes.oneOf(["relations.page-relation"]),
                name: PropTypes.string,
                page: PropTypes.shape({
                  id: PropTypes.number,
                  name: PropTypes.name,
                }),
              }),
              PropTypes.shape({
                id: PropTypes.number,
                _component: PropTypes.oneOf(["relations.link-relation"]),
                name: PropTypes.string,
                externalLink: PropTypes.shape({
                  id: PropTypes.number,
                  name: PropTypes.string,
                  url: PropTypes.string,
                }),
              }),
            ])
          ),
        }),
      })
    ),
  }),
};

const mapDispatchToProps = {
  openMobileLimitationSidebar,
};

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, "backendData.currentUser"),
  };
};

const FooterWithProvider = createProvider(
  connect(mapStateToProps, mapDispatchToProps)(FooterWithStrapiProvider)
);

export default withErrorBoundary(FooterWithProvider, {
  error: 'Failed to render "Footer"',
});
