import { useCallback, useEffect, useState } from 'react'
import Icon from '@components/icon'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import useOpen from '@hooks/useOpen'
import * as styles from './SelectDropdown.module.scss'

const DropdownItem = ({ label, value, selectedValue, onSelect, className }) => {
  const onClick = () => onSelect(value)

  // delay the update of the selected value to avoid the dropdown item flashing while the dropdown is being closed
  const [delayedSelection, setDelayedSelection] = useState(selectedValue)
  useEffect(() => {
    setTimeout(() => setDelayedSelection(selectedValue), 150)
  }, [selectedValue])
  const isSelected = delayedSelection === value

  return (
    <div className={`${styles.option} ${!!isSelected && styles.optionSelected} ${className}`} onClick={onClick}>
      {label}
      {isSelected && <Icon icon='check' size={16} />}
    </div>
  )
}

// renders a bottom sheet picker on small screens, and a dropdown on larger screens
export const SelectDropdown = React.memo((props) => {
  const { label, items, isDisabled = false, onSelect, value: selectedValue, dropdownProps = {}, className = '', togglerClassName = '', dropdownClassName = '', itemClassName = '' } = props

  const { isOpen, close, toggle } = useOpen()

  const renderToggle = useCallback(
    () => {
      return (
        <div className={`${styles.toggler} ${togglerClassName}`}>
          <span className={styles.selectedValue}>{items.find(({ value }) => value === selectedValue)?.label}</span>
          <Icon className={styles.toggle} icon='small-down' size={16} />
        </div>
      )
    }
    , [togglerClassName, items, selectedValue])

  const onSelectItem = useCallback((value) => {
    onSelect(value)
    close()
  }, [onSelect, close])

  const renderItems = useCallback(() => {
    return items.map(({ label, value }) => <DropdownItem key={value} className={itemClassName} label={label} value={value} selectedValue={selectedValue} onSelect={onSelectItem} />)
  }, [items, selectedValue, onSelectItem])

  return (
    <div className={`${styles.container} ${!!isDisabled && styles.disabled} ${className}`}>
      {label && <label className={styles.label}>{label}</label>}

      <DropdownOrBottomSheet
        dropdownClassName={`${styles.dropdown} ${dropdownClassName}`}
        isOpen={isOpen}
        onToggle={toggle}
        toggler={renderToggle()}
        dropdownProps={dropdownProps}
      >
        {renderItems()}
      </DropdownOrBottomSheet>
    </div>
  )
})

SelectDropdown.displayName = 'SelectDropdown'
export default SelectDropdown
