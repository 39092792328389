import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@store/selectors/backendData'
import { LINKS } from '@common/consts'

// use to get the context menu items for tracks in a group. These items are admin options (remove from group, feature/unfeature)
export default function useGroupTracksContextMenuItems({ tracks, groupLink, isGroupAdmin, isAdminUser }) {
    const currentUser = useSelector(selectCurrentUser)

    const items = {}

    if (!groupLink) return items

    tracks.forEach((track) => {
        const { id, mixdown = {}, featured } = track

        const isTrackOwner = !!currentUser && currentUser.guid === mixdown.user.id
        const url = LINKS.GROUP_TRACK.replace(':groupId', groupLink).replace(':trackId', id)

        const trackItems = [
            !!isGroupAdmin && { label: `${featured ? 'Unfeature' : 'Feature'} track`, icon: 'Effects-exploration-2', href: `${url}/${featured ? 'unfeature' : 'feature'}` },
            !!(isTrackOwner || isAdminUser || isGroupAdmin) && { 'label': 'Delete track', 'icon': 'trash', 'href': url, 'data-method': 'delete' }
        ].filter(Boolean)

        items[id] = trackItems
    })

    return items
}
