import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import {
  Bem,
  gotoLocation,
} from '../../common/utils'
import URI from 'urijs';
import axios from 'axios';
import { mapKeys, snakeCase } from 'lodash'
import { createOrganization } from '../../common/http'
import { LINKS, ENDPOINTS } from '../../common/consts'
import {connect} from "react-redux";
import createProvider from "../../store/provider";
import {setSignInRedirect} from "../../store/actions/app";
import { MessageHandler } from "../../common/messageHandler";
import Linkton from "@components/linkton";
import JSONAPISerializer from "jsonapi-serializer";

const cn = new Bem({
  name: 'auth-gateway-view',
  prefix: 'pfx-'
})

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: "camelCase",
});

class DuplicateOrganization extends React.Component {
  constructor(props) {
    super(props)
    let { queryParams } = this.props

    this.state = {
      orgs: null
    }
  }

  startLoading() {
    this.setState({isLoading: true})
    // Brutal hack, will be handled later
    $('.pfx-auth-gateway-view__form-element--submit > button').addClass('pfx-button--loading')
  }

  stopLoading() {
    this.setState({isLoading: false})
    // Brutal hack, will be handled later
    $('.pfx-auth-gateway-view__form-element--submit > button').removeClass('pfx-button--loading')
  }

  async componentDidMount() {
    this.setState({
      orgs: await deserializer.deserialize(this.props.duplicateOrgs),
    })
  }

  async requestAccess(orgId) {
    const url = URI(ENDPOINTS.ORGANIZATIONS)
        .segment(orgId)
        .segment(ENDPOINTS.MEMBERSHIP_REQUESTS)
        .toString();

      try {
        await axios.post(
          url,
          {
            membership_request: { },
          },
          { withCredentials: true }
        );
        gotoLocation(LINKS.STUDIO)
      } catch (e) {
        throw new Error(e);
      }
  }

  async createNewOrganization(org) {
    const data = mapKeys(org, (val, key) => snakeCase(key))
    try {
      await createOrganization(data)
      gotoLocation(LINKS.STUDIO)
    } catch (err) {
      throw new Error(e)
    }
  }

  getInitials(fullName) {
    if (!fullName) {
      return;
    }

    return fullName.split(' ')
      .map(word => word.charAt(0).toUpperCase() + '.')
      .join(' ');
  }

  render () {
    const { queryParams, emailDomain, currentOrg } = this.props
    const { orgs } = this.state
    if (!orgs) { return null; }

    return (
      <div className={cn(undefined, { popup: MessageHandler.isInStudioLoginFlowPopup() })}>
        <div className={cn('container', ['black', 'wide'], { space: true })}>
          <div className={cn('header')}>
            <h4 className={cn('header-text', 'left-align')}>Wait! There is already a school account created with {emailDomain}</h4>
          </div>
          { orgs.map((org) => {
            return (
              <div className={cn('org-action-wrapper')}>
                <p>
                  <span className={'highlight'}>{this.getInitials(org.owner.realName)}</span> has already created a school account for <span className={'highlight'}><b>{org.name}</b></span>
                </p>
                <Linkton
                  className={cn("org-action-button")}
                  tag="button"
                  type={"outline"}
                  padding="large"
                  color={"ocean"}
                  hover="none"
                  onClick={() =>
                    this.requestAccess(org.id, 'admin')
                  }
                >
                  I'm a faculty member and want to join {org.name}
                </Linkton>
                <Linkton
                  className={cn("org-action-button")}
                  tag="button"
                  type={"outline"}
                  padding="large"
                  color={"ocean"}
                  hover="none"
                  onClick={() =>
                    this.requestAccess(org.id, 'student')
                  }
                >
                  I'm a student of {org.name}
                </Linkton>
              </div>
            )
          }) }
          <div className={cn('org-action-wrapper')}>
            <p>No, I don't want to join as an existing school account</p>
            <Linkton
              className={cn("org-action-button")}
              tag="button"
              type={"outline"}
              padding="large"
              color={"ocean"}
              hover="none"
              onClick={() =>
                this.createNewOrganization(currentOrg, 'admin')
              }
            >
              I want to create a new school account for {currentOrg.name}
            </Linkton>
          </div>
          <p className={cn('bottom-text', 'grey')}>Soundation is an online music studio where you can make music and collaborate without boundaries.</p>
        </div>
      </div>
    )
  }
}

DuplicateOrganization.propTypes = {
  duplicateOrgs: PropTypes.object.isRequired,
  currentOrg: PropTypes.object.isRequired,
  queryParams: PropTypes.shape({
    tab: PropTypes.string,
    plan: PropTypes.string,
    couponCode: PropTypes.string,
    period: PropTypes.string,
    productType: PropTypes.string,
    referralId: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.object.isRequired
}

DuplicateOrganization.defaultProps = {}

const mapDispatchToProps = {
  setSignInRedirect
}

export default createProvider(connect(null, mapDispatchToProps)(DuplicateOrganization))
