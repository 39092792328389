import React from 'react'
import './comments.scss'
import { Bem } from '../../common/utils'
import { LINKS } from '@common/consts'
import Icon from '../icon/index'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

const cn = new Bem({
  name: 'comments',
  prefix: 'pfx-'
})

dayjs.extend(relativeTime)

export default class Comment extends React.Component {
  render () {
    const {
      createdAt,
      content,
      username,
      avatarUrl,
      id,
      onDelete,
      onAbuseReport,
      canDelete,
      profileLink
    } = this.props

    return (
      <div className={cn('comment')}>
        <a className={cn('comment-userdata')} href={`${LINKS.USER}/${profileLink}`}>
          <img className={cn('comment-avatar')} src={avatarUrl} alt='Comment Avatar' />
          {`${username}, ${dayjs(createdAt).fromNow()}`}
        </a>
        <p className={cn('comment-content')}>{content}</p>
        <div className={cn('comment-actions')}>
          {canDelete && <Icon icon='new-close' onClick={() => onDelete(id)} size={15} fontSizeRatio={1} />}
          <Icon icon='new-flag' onClick={() => onAbuseReport(id)} size={15} fontSizeRatio={1} />
        </div>
      </div>
    )
  }
}
