import React from 'react'
import Uploader from '../../components/uploader/uploader'
import Button, { BUTTON_TYPES } from '../../components/button'
import './style.scss'
import { Bem } from '@common/utils'
import { registerUpload, uploadFile } from '../../common/http'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'campaign-upload-track'
})

class CampaignUploadTrack extends React.Component {

  state = {
    uploaded: false,
    progress: null,
    filename: null
  }

  render() {
    const { mime } = this.props
    const { uploaded, progress, filename } = this.state

    const placeholder = (<span>
      Drop your track here or
      <Button className={cn('button')} type={BUTTON_TYPES['SECONDARY-FULL']}>Select a file</Button>
    </span>)

    return (<Uploader
      mime={ mime }
      placeholder={ placeholder }
      onUploadRequest={ file => this.uploadFile(file) }
      onRejected={ () => this.onRejected() }
      uploaded={ uploaded }
      progress={ progress }
      moreThanOneErrorMessage={ 'You can only post one track at a time' }
      className={cn('uploader')}
      filename={ filename }
    />)
  }

  onRejected () {
    this.setState({
      uploaded: false,
      progress: 0
    })
    this.onUploaded(null)
  }

  async uploadFile(file) {
    this.setState({
      uploaded: false,
      progress: 0,
      filename: file.name
    })

    try {
      const uploadData = (await registerUpload(file)).data

      const result = await uploadFile(file, uploadData, (progress) => {
        this.setState({
          progress: Math.round(progress.loaded * 100 / progress.total)
        })
      })

      this.setState({
        uploaded: true,
        progress: null
      })

      this.onUploaded(result)
    } catch (err) {
      this.setState({
        progress: null,
        error: 'Failed to upload file. Please try again'
      })
    }
  }

  onUploaded(filename) {
    document.getElementById('mixdown_file_name').value = filename;
    if (filename) {
      document.querySelector('input[type=submit][name=commit]').removeAttribute('disabled');
    } else {
      document.querySelector('input[type=submit][name=commit]').setAttribute('disabled', 'disabled');
    }
  }

}

export default CampaignUploadTrack