import React from 'react'
import './group-updates.scss'
import { Bem } from '@common/utils'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '../../../components/icon/index'
import AdminContent from '../../../components/admin-content/admin-content'

const cn = new Bem({
  name: 'group-updates',
  prefix: 'pfx-'
})

dayjs.extend(relativeTime)

export default class GroupUpdate extends React.Component {
  render () {
    const {
      createdAt,
      content,
      title,
      id,
      notified,
      onDelete,
      onNotifyUpdate,
      canPost
    } = this.props

    return (
      <div className={cn('update')}>
        <div className={cn('update-title')}>{title}</div>
        <div className={cn('update-date')}>{dayjs(createdAt).fromNow()}</div>
        <AdminContent html={content} className={cn('update-content')} />
        {canPost && <div className={cn('update-actions')}>
          <Icon icon='new-close' onClick={() => onDelete(id)} size={15} fontSizeRatio={1} />
          {!notified && <Icon icon='new-bell' onClick={() => onNotifyUpdate(id)} size={15} fontSizeRatio={1} />}
        </div>}
      </div>
    )
  }
}
