import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ACCOUNT_TYPES, LINKS, GTM_EVENTS } from '@common/consts'
import { Bem, getDateDiffInDays, gotoLocation } from '@common/utils'
import Modal from '@components/modal'
import Button, { BUTTON_SIZES } from '@components/button'
import { analytics as analyticsModel } from '@models/analytics'
import { app as appModel } from '@models/app'
import { appDataSelector } from '@store/selectors/app'
import { analyticsDataSelector } from '@store/selectors/analytics'
import { openModalsCountSelector } from '@store/selectors/modal'
import createProvider from '@store/provider'
import { loadDataLayer, loadGtmSettings } from '@store/actions/analytics'
import { updateUpgradeWindowLastShownDate } from '@store/actions/app'
import { sendAnalyticsEvent } from '@common/analytics'
import './style.scss'

const generateBemClass = new Bem({
  name: 'upgrade-account-window',
  prefix: 'pfx-'
})

class UpgradeAccountWindow extends React.PureComponent {
  state = {
    isOpen: this.canShow(this.props)
  }

  componentWillReceiveProps (nextProps) {
    if (!this.canShow(this.props) && this.canShow(nextProps) && !this.state.isOpen) {
      this.setState({ isOpen: true })
    } else if (nextProps.modalsCount > 1) {
      this.setState({ isOpen: false })
    }
  }

  componentDidMount () {
    this.props.loadDataLayer()
    this.props.loadGtmSettings()
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.canShow(prevProps) && this.canShow(this.props) && this.state.isOpen) {
      this.props.updateUpgradeWindowLastShownDate(new Date())
    }
  }

  canShow (props) {
    if (!props || props.modalsCount > 0 || !props.app || !props.analytics) {
      return false
    }

    const popupLastShownDateDiffInDays = getDateDiffInDays(new Date(), props.app.upgradeWindowLastShownDate)
    const gtmSettings = props.analytics.gtmSettings
    const dataLayerSettings = props.analytics.dataLayer

    if (!gtmSettings || !dataLayerSettings || dataLayerSettings.accounttype !== ACCOUNT_TYPES.FREE) {
      return false
    }

    return (!props.app.upgradeWindowLastShownDate || popupLastShownDateDiffInDays >= gtmSettings.NUMBER_OF_DAYS_UPGRADE_POPUP) &&
      dataLayerSettings.signincount >= gtmSettings.NUMBER_OF_LOGINS_UPGRADE_POPUP &&
      dataLayerSettings.songscount >= gtmSettings.NUMBER_OF_SONGS_UPGRADE_POPUP
  }

  handleClose = event => {
    sendAnalyticsEvent(GTM_EVENTS.GTM_EVENT_INTERACTED_WITH_UPGRADE_TO_PREMIUM_POPUP, 'cancel')
    this.setState({ isOpen: false })
  }

  handleUpgradeButtonClick = event => {
    sendAnalyticsEvent(GTM_EVENTS.GTM_EVENT_INTERACTED_WITH_UPGRADE_TO_PREMIUM_POPUP, 'check benefits')
    this.setState({ isOpen: false })
    gotoLocation(LINKS.PRICING)
  }

  render () {
    return (
      <Modal
        title={'Unleash your creativity!'}
        className={generateBemClass('container')}
        isOpen={false}
        footer={() => this.renderModalFooter() }
        onCloseButtonClick={this.handleClose}>
        {() => this.renderBody()}
      </Modal>
    )
  }

  renderModalFooter() {
    return (
      <Button
        type='secondary-full'
        size={BUTTON_SIZES.SMALL}
        onClick={this.handleUpgradeButtonClick}>Check all the benefits</Button>
    )
  }

  renderBody () {
    return (
      <div className={generateBemClass()}>
        <div className={generateBemClass('content')}>
          <ul>
            <li>12000 sounds and loops</li>
            <li>20 sound sets to use as construction kits</li>
            <li>Cloud storage space</li>
            <li>Record audio</li>
            <li>Upload your own sounds</li>
            <li>And much, much more…</li>
          </ul>
        </div>
      </div>
    )
  }
}

UpgradeAccountWindow.propTypes = {
  analytics: analyticsModel.isRequired,
  app: appModel.isRequired,
  modalsCount: PropTypes.number.isRequired,
  loadDataLayer: PropTypes.func.isRequired,
  loadGtmSettings: PropTypes.func.isRequired,
  updateUpgradeWindowLastShownDate: PropTypes.func.isRequired
}

const mapStateToProps = state => {
  return {
    app: appDataSelector(state),
    analytics: analyticsDataSelector(state),
    modalsCount: openModalsCountSelector(state)
  }
}

const mapDispatchToProps = {
  loadDataLayer,
  loadGtmSettings,
  updateUpgradeWindowLastShownDate
}

export { UpgradeAccountWindow }
export default createProvider(connect(mapStateToProps, mapDispatchToProps)(UpgradeAccountWindow))
