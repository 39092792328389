import React from "react";
import { Bem } from "@common/utils";
import PropTypes from "prop-types";
import "./style.scss";
import InlinePlayer from "./inline-player";
import LazyImage from "@components/lazy-image";

const cn = Bem({
  prefix: "pfx-",
  name: "inline-player",
});

export default class ModernPlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPlaying: false };
  }

  handleOnPlayChange = ({ isPlaying }) => {
    this.setState({ isPlaying });
  };

  render() {
    const { isPlaying } = this.state;

    const {
      id,
      songUrl,
      fileName,
      className,
      description,
      title,
      image,
      highlightOnPlay = false,
      visible = "hover",
    } = this.props;

    const hasImage = !!image?.src;

    return (
      <div className={cn("modern-container", { square: true }, className)}>
        <div
          className={cn("modern-player-wrapper", {
            "no-image": !hasImage,
            square: true,
            [`visible-${visible}`]: true,
          })}
        >
          <div className={cn("modern-inner")}>
            {hasImage && (
              <LazyImage
                className={cn("modern-picture", undefined, "picture")}
                imgClassName={cn("modern-image", undefined, ["image", image.className])}
                cover
                src={image.src}
                sources={image.sources}
                size={image.size}
                aspectRatio={image.aspectRatio}
                fallbackSrc={image.fallbackSrc}
                alt={image.alt}
              />
            )}
            <InlinePlayer
              size="large"
              onPlayChange={this.handleOnPlayChange}
              songUrl={songUrl}
              fileName={fileName}
              id={id}
            />
          </div>
        </div>

        <div
          className={cn("modern-text", {
            main: true,
            highlight: highlightOnPlay && isPlaying,
          })}
        >
          {title}

          {description && (
            <div className={cn("modern-text-description", "secondary")}>
              {description}
            </div>
          )}
        </div>
      </div>
    );
  }
}

ModernPlayer.defaultProps = {
  square: true,
  highlightOnPlay: false,
};

ModernPlayer.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({}),
  title: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  songUrl: PropTypes.string,
  fileName: PropTypes.string,
  square: PropTypes.bool,
  visible: PropTypes.oneOf(["hover", "always"]),
  highlightOnPlay: PropTypes.bool,
};

ModernPlayer.defaultProps = {};
