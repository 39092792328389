import React from 'react'
import { Provider } from 'react-redux'
import createStore from '@store/index'

const store = createStore()

const createProvider = (WrappedComponent, convertPropsToCamelCase = true) => {
  return class extends React.Component {

    constructor(props) {
      super(props)
      this.store = createStore()
    }

    render () {
      return (
        <Provider store={this.store}>
          <WrappedComponent {...this.props} dispatch={this.store.dispatch}/>
        </Provider>
      )
    }
  }
}

export { store }
export default createProvider
