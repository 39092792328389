import React from 'react'
import './style.scss'
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { Bem, get } from '../../common/utils';
import {noop} from 'lodash'
import Icon from "../../components/icon/index";
import LoadMore from "../../components/load-more/index";
import {getAccountReferredUsers} from "../../common/http"
import dayjs from 'dayjs'
import buildUrl from 'build-url'
import { GTM_EVENTS, LINKS } from '../../common/consts'
import CopyToClipboard from '../../components/copy-to-clipboard'
import { sendAnalyticsEvent } from "../../common/analytics";
import { getDisplayPrice } from '@components/price-plans/utils';

const cn = new Bem({
  name: 'invite-page',
  prefix: 'pfx-'
});

class InvitePage extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      invites: props.invites
    }
  }

  componentWillReceiveProps(nextProps) {
    const {invites} = this.props;
    if (invites !== nextProps.invites) {
      this.state.invites = nextProps.invites
    }
  }

  renderHero() {
    const { currency, referralReward } = this.props;
    const creditDisplayText = getDisplayPrice(referralReward, currency, 0);

    return (
      <div className={cn('hero')}>
        <div className={cn('hero-container', null, 'container')}>
          <h1 className={cn('hero-header')}>
            Give {creditDisplayText} get {creditDisplayText}
          </h1>
          <h2 className={cn('hero-subheader')}>
            Invite your friends to Soundation.
          </h2>
        </div>
      </div>
    )
  }

  renderBox({header, icon, content}) {
    return (
      <div className={cn('box')}>
        <div className={cn('box-heading')}>
          {icon && <Icon icon={icon} className={cn('box-icon')} size={20}/>}
          <h5 className={cn('box-header')}>{header}</h5>
        </div>
        <div className={cn('box-content')}>
          {content()}
        </div>
      </div>
    )
  }

  renderLinkContainer(referral) {
    const icon = (iconName, onClick) => <Icon icon={iconName} className={cn('link-icon')} size={40} fontSizeRatio={2.5} onClick={onClick} />
    const {shareLinks, shareText} = this.props;
    const windowOptions = 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600'
    const fullReferralUrl = referral && referral.url

    return this.renderBox({header: 'Invite your friends', content: () => !!referral && (
      <div className={cn('link-container', 'box')}>
        <div className={cn('link-top')}>
          <CopyToClipboard
            value={fullReferralUrl}
            className={cn('link-input')}
            onCopy={() => sendAnalyticsEvent(GTM_EVENTS.REFERRAL_LINK_ACTION_COPIED)}
            onTextClick={() => sendAnalyticsEvent(GTM_EVENTS.REFERRAL_LINK_ACTION_TEXT_CLICK)}
          />
          {
            icon('twitter', () => {
              window.open(shareLinks.twitter, '', windowOptions)
              sendAnalyticsEvent(GTM_EVENTS.REFERRAL_LINK_ACTION_TWEETED)
            })
          }
          {
            icon('mail', () => {
              window.location.href = `mailto:?subject=Let's make music together on Soundation&body=${shareText} ${fullReferralUrl} `
              sendAnalyticsEvent(GTM_EVENTS.REFERRAL_LINK_ACTION_EMAILED)
            }
          )}
        </div>
        <div className={cn('link-bottom')}>
          <span className={cn('link-bottom-tip')}>Tip!</span> The more you spread the word, the more rewards you'll unlock.
        </div>
      </div>
    )})
  }

  renderInvitedUser(user = {}) {
    const status = user.prize ? 'Subscribed' : user.subscriptionStartedAt ? 'Trial' : 'Signed up'
    return (
      <tr className={cn('invited-user')}>
        <td className={cn('invited-user-avatar', null, 'pfx--mono')} style={{backgroundImage: 'url(' + user.avatarSmallUrl + ')'}}></td>
        <td className={cn('invited-user-username', null, 'pfx--mono')}>
          {user.username}
          <div className={cn('invited-user-status-mobile', null, 'pfx--mono')}>{status}</div>
        </td>
        <td className={cn('invited-user-status', null, 'pfx--mono')}>
          {status}
        </td>
        <td className={cn('invited-user-date', null, 'pfx--mono')}>
          {dayjs(user.prize && user.prize.createdAt || user.subscriptionStartedAt || user.createdAt).fromNow()}
        </td>
      </tr>
    )
  }

  renderInvites(invites = []) {
    const {invitesLoaded, initialInvitiesTotal} = this.props

    return this.renderBox({header: `Your invites ${!!initialInvitiesTotal ? '('+ initialInvitiesTotal +')' : ''}`, content: () => invitesLoaded && (
      <div className={cn('invites', 'box')}>
        {!invites.length && (
          <div className={cn('invites-no-results', null, 'pfx--sound-grey-5')}>
            When your friends sign up you will see them here
          </div>
        )}
        {!!invites.length && (
          <LoadMore
            className={cn('invites-load-more-list')}
            initialTotal={initialInvitiesTotal}
            backendAction={getAccountReferredUsers}
            onLoadedMore={(data) => this.handleFetchMore(data)}
            itemsPerPage={5}>
            <table className={cn('invited-users')}>
              <tbody>
                { invites.map((user) => this.renderInvitedUser(user)) }
              </tbody>
            </table>
          </LoadMore>
        )}
      </div>
    )})
  }

  handleFetchMore(response) {
    if (response && response.users) {
      this.setState({invites : [...this.state.invites, ...response.users]})
    }
  }

  renderRewards() {
    const { currentUser, invitesLoaded, currency, promotionalCredits, referralReward } = this.props
    const isPaid = !!(currentUser && !!currentUser.accountType)

    const checkoutUrl = buildUrl({ path: LINKS.AUTH_PLANS })

    const creditText = `Get ${getDisplayPrice(referralReward, currency, 0)} in credit for every person you refer to Soundation that pays for a subscription.`
    const component = (
      <div className={cn('rewards', 'box')}>
        {promotionalCredits > 0 && (
          <div className={cn('rewards-count')}>You’ve earned {getDisplayPrice(promotionalCredits, currency, 0)} credits</div>
        )}
        <div className={cn('rewards-grey-text')}>
          {promotionalCredits === 0 && !isPaid && (
            <div className={cn('rewards-grey-text')}>{creditText}</div>
          )}
          {promotionalCredits === 0 && isPaid && (
            <div className={cn('rewards-grey-text')}>
              {creditText} Your reward is automatically deducted on the start of your next billing period
            </div>
          )}
          {promotionalCredits > 0 && !isPaid && (
            <div className={cn('rewards-grey-text')}>
              <div>{creditText}</div>
              <a href={checkoutUrl} className={cn('rewards-link')}>
                Claim your reward
              </a>
            </div>
          )}
          {promotionalCredits > 0 && isPaid && (
            <div className={cn('rewards-grey-text')}>
              <div>{creditText} Your reward is automatically deducted on the start of your next billing period</div>
              <a href={LINKS.ACCOUNT_MANAGE} className={cn('rewards-link')}>
                See your billing status
              </a>
            </div>
          )}
        </div>

      </div>
    )

    return this.renderBox({header: 'Rewards', icon: 'trophy', content: () => invitesLoaded && component})
  }

  renderHint() {
    return (
      <div className={cn('hint')}>
        <Icon icon={'light-bulb'} className={cn('hint-icon')} size={20}/>
        <span>Need help? </span>
        <a href={ LINKS.SUPPORT } className={cn('hint-link')}>Check out the FAQ</a>
      </div>
    )
  }

  render() {
    const {referral} = this.props
    const {invites} = this.state

    return (
      <div className={cn()}>
        {this.renderHero()}
        <div className={cn('content-container', null, 'container')}>
          <div className={cn('content')}>
            <div className={cn('content-left')}>
              {this.renderLinkContainer(referral)}
              {this.renderInvites(invites)}
              {this.renderHint()}
            </div>
            <div className={cn('content-right')}>
              {this.renderRewards()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

InvitePage.propTypes = {
  invites: PropTypes.array,
  referral: PropTypes.object,
  onClamReward: PropTypes.func,
  invitesLoading: PropTypes.bool,
  referralLoading: PropTypes.bool,
  invitesLoaded: PropTypes.bool,
  referralLoaded: PropTypes.bool,
  initialInvitiesTotal: PropTypes.number,
  currentUser: PropTypes.object,
  shareLinks: PropTypes.object,
  prizesCount: PropTypes.number,
  promotionalCredits: PropTypes.number
};

InvitePage.defaultProps = {
  invites: [],
  referral: null,
  onClamReward: noop,
  invitesLoading: true,
  referralLoading: true,
  invitesLoaded: false,
  referralLoaded: false,
  initialInvitiesTotal: 0,
  currentUser: null,
  shareLinks: {}
};

const mapStateToProps = (state) => {
  return {
    referralReward: get(state, 'backendData.referralReward'),
  }
}

export default createProvider(connect(mapStateToProps, null)(InvitePage))
