import React from 'react'
import PropTypes from 'prop-types'
import { Bem, Enum } from '@common/utils'
import createProvider from '../../store/provider'
import {connect} from "react-redux";
import { gotoAuth, get } from '../../common/utils'
import { PFX_REQUIRE_SIGN_IN } from '../../global-events-bindings'

const generateBemClass = new Bem({
  name: 'button',
  prefix: 'pfx-'
})

export const LINK_TYPES = new Enum('HOLLOW', 'PRIMARY_HOLLOW', 'PRIMARY', 'GRADIENT', 'OUTLINE', 'GRADIENT_SECONDARY')
export const LINK_SIZES = new Enum('SMALL', 'MEDIUM', 'LARGE', 'XLARGE')

class Link extends React.Component {

  render () {
    let { className, type, size, children, requireAuth, currentUser, ...props } = this.props
    className = generateBemClass(null, [type, size], className)

    if (requireAuth) {
      let passedOnClick = props.onClick
      props[PFX_REQUIRE_SIGN_IN] = props.href

      props.onClick = (e) => {
        if (!currentUser) {
          e.preventDefault()
          gotoAuth()
        } else if (passedOnClick) {
          passedOnClick(e)
        }
      }
    }
    return <a className={className} {...props}>{children}</a>
  }
}

Link.propTypes = {
  type: PropTypes.oneOf([
    LINK_TYPES.HOLLOW,
    LINK_TYPES.PRIMARY_HOLLOW,
    LINK_TYPES.PRIMARY,
    LINK_TYPES.OUTLINE,
    LINK_TYPES.GRADIENT,
    LINK_TYPES.GRADIENT_SECONDARY
  ]),
  size: PropTypes.oneOf([
    LINK_SIZES.SMALL,
    LINK_SIZES.MEDIUM,
    LINK_SIZES.LARGE,
    LINK_SIZES.XLARGE
  ]),
  requireAuth: PropTypes.bool
}

Link.defaultProps = {
  type: LINK_TYPES.PRIMARY,
  size: LINK_SIZES.MEDIUM,
  requireAuth: false
}

const mapStateToProps = state => {
  return {
    currentUser: get(state, 'backendData.currentUser')
  }
}

export default createProvider(connect(mapStateToProps)(Link))
