import Button from "../button/index";
import React from "react";
import PropTypes from "prop-types";
import {
  Bem,
  canUseDOM,
  gotoLocation,
  extractError,
  requestSignUp,
  requestLogin
} from "../../common/utils";
import "./index.scss";
import URI from "urijs";
import { connect } from "react-redux";
import createProvider from "@store/provider";
import { ENDPOINTS, LINKS } from "@common/consts";
import axios from "axios";
import JSONAPISerializer from "jsonapi-serializer";
import LoadingIndicator from "../loading-indicator";
import Linkton, { COLOR, HOVER, TAG, SIZE } from "../linkton";

const cn = new Bem({
  name: "organization-invite-page",
  prefix: "pfx-",
});

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: "camelCase",
});

const errors = {
  BANNED: "You have been banned by the organization owner",
};

// TODO Common codes in CollabInvitePage and this one could be
// separate component
class OrganizationInvitePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.url = canUseDOM() ? window.location.href : "";
    this.state = {
      isSend: false,
      message: null,
      loading: false,
      error: null,
      organization: null
    };
  }

  async componentDidMount() {
    this.setState({
      organization: await deserializer.deserialize(this.props.organization)
    });
  }

  isDataLoaded = () => {
    return this.state.organization && !this.state.loading;
  };

  hasAlreadyRegistered = () => {
    return !!this.props.registeredOrganization.data
  }

  handleRequestAccess = async () => {
    if (!this.props.currentUser) {
      requestSignUp(
        true,
        window.location.href,
        "GO BACK TO ORGANIZATION INVITATION"
      );
    } else {
      const url = URI(ENDPOINTS.ORGANIZATIONS)
        .segment(this.state.organization.id)
        .segment(ENDPOINTS.MEMBERSHIP_REQUESTS)
        .toString();

      this.setState({ loading: true });

      try {
        const { data } = await axios.post(
          url,
          {
            membership_request: { },
          },
          { withCredentials: true }
        );
        this.setState({ isSend: true, loading: false });
        return data;
      } catch (e) {
        this.setState({ error: extractError(e), loading: false });
        throw new Error(e);
      }
    }
  };

  handleUpdateMembership = async (status) => {
    const hasMembership = this.hasMembership();
    const id = hasMembership
      ? this.props.membership.data.id
      : this.props.membershipId;
    const url = URI(ENDPOINTS.MEMBERS)
      .segment(id)
      .segment("respond")
      .toString();
    this.setState({ loading: true });
    try {
      const { data } = await axios.put(url, status, { withCredentials: true });
      this.setState({ loading: false });
      return data;
    } catch (e) {
      this.setState({ loading: false, error: extractError(e) });
      throw new Error(e);
    }
  };

  handleDecline = async () => {
    if (this.props.membershipId) {
      await this.handleUpdateMembership({ membership: { declined: true } });
    }

    if (this.props.currentUser) {
      if (this.props.eduMode) {
        gotoLocation(LINKS.STUDIO);
      } else {
        gotoLocation(LINKS.FEED);
      }
    } else {
      gotoLocation("/");
    }
  };

  handleAccept = async () => {
    const membership = this.hasMembership();

    if (!this.props.currentUser) {
      return requestSignUp(true, window.location.href, "START COLLABORATING");
    } else if (this.props.membershipId || membership) {
      if (!this.props.isMember) {
        await this.handleUpdateMembership({ membership: { accepted: true } });
      }
      this.gotoStudio();
    } else {
      if (!this.props.isMember) {
        await this.handleRequestAccess();
      }
      this.gotoStudio();
    }
  };

  gotoStudio = () => {
    gotoLocation(LINKS.STUDIO);
  };

  renderOrganizationDetails() {
    const { isMember, ownerUsername, membershipId } = this.props;
    const { organization } = this.state;
    const shouldShowJoinCopy = isMember || !membershipId;

    if (!organization) {
      return null;
    }

    return (
      <div>
        <div className={cn("header")}>
          {!shouldShowJoinCopy ? (
            <div>
              <strong className={cn("highlight-text")}>{ownerUsername}</strong>{" "}
              has invited you to join {organization.name}
            </div>
          ) : (
            <div>
              Join{" "}
              <strong className={cn("highlight-text")}>{ownerUsername}</strong>{" "}
              in {organization.name}<br />
            </div>
          )}
        </div>
      </div>
    );
  }

  renderGoToDashboard = () => {
    return (
      <div className={cn("actions")}>
        <Linkton
          size={SIZE.MEDIUM}
          color={COLOR.OCEAN}
          hover={HOVER.OUTLINE}
          tag={TAG.ANCHOR}
          href={LINKS.STUDIO}
        >
          Go to dashboard
        </Linkton>
      </div>
    );
  };

  renderStatus() {
    const { isSend, error, organization } = this.state;
    const {
      ownerUsername,
      isMember,
      requestedMembership,
      hasReachedTheLimit,
      registeredOrganization
    } = this.props;

    return (
      <div>
        {(isSend ||
          error ||
          hasReachedTheLimit ||
          isMember ||
          requestedMembership ||
          this.hasAlreadyRegistered()) && (
          <div className={cn("status")}>
            {isSend && (
              <>
                <div className={cn("status-accepted")}>
                  Request sent! <br /> We will send you an email as soon as{" "}
                  {ownerUsername} has accepted it.
                </div>
                {this.renderGoToDashboard()}
              </>
            )}
            {error && <div className={cn("status-error")}>{error}</div>}
            {this.hasAlreadyRegistered() && <div className={cn("status-error")}>Your account ({this.props.currentUser.email}) is linked to <b>{registeredOrganization.data.attributes.name}</b><br />To join {organization.name} you need to create a new account with a separate email address</div>}
            {requestedMembership && !isMember && (
              <div className={cn("status-error")}>
                You already requested membership to this {organization.name}.
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  hasMembership = () => {
    const { membership } = this.props;
    return !!(membership && membership.data);
  };

  renderButtons() {
    const { isSend, error, organization } = this.state;
    const {
      isMember,
      requestedMembership,
      hasReachedTheLimit,
      membershipId,
    } = this.props;
    const membership = this.hasMembership();

    if (isSend) return <div />;
    if (this.hasAlreadyRegistered()) return <div />;

    if (error === errors.BANNED) {
      return this.renderGoToDashboard();
    }
    const showDeclineButton = !isMember && !hasReachedTheLimit && !membership;

    const showCollabButton =
      (membershipId || membership) &&
      (!hasReachedTheLimit || isMember);

    const showRequestButton =
      !(isMember || membership || membershipId) &&
      !hasReachedTheLimit;

    return (
      <div>
        <div className={cn("actions")}>
          {showDeclineButton && (
            <Button
              type="secondary"
              size="small"
              className={cn("decline-button")}
              onClick={this.handleDecline}
              disabled={requestedMembership}
            >
              No thanks
            </Button>
          )}

          {showCollabButton && (
            <Button
              size="small"
              onClick={this.handleAccept}
              disabled={requestedMembership && !isMember}
            >
              Join {organization.name}
            </Button>
          )}
          {showRequestButton && (
            <Button
              size="small"
              onClick={this.handleRequestAccess}
              disabled={requestedMembership}
            >
              Request access
            </Button>
          )}
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className={cn("footer")}>
        Soundation is an online music studio where you can make music and
        collaborate without boundaries.
      </div>
    );
  }

  render() {
    return (
      <div className={cn()}>
        {this.renderOrganizationDetails()}
        {this.isDataLoaded() ? (
          <div>
            {this.renderStatus()}
            {this.renderButtons()}
          </div>
        ) : (
          <LoadingIndicator
            color={"white"}
            className={cn("loading-indicator")}
          />
        )}
        {this.renderFooter()}
      </div>
    );
  }
}

OrganizationInvitePage.propTypes = {
  eduMode: PropTypes.bool,
  organization: PropTypes.object.isRequired,
  membershipId: PropTypes.string,
  isMember: PropTypes.bool,
  ownerUsername: PropTypes.string,
  requestedMembership: PropTypes.bool,
  hasReachedTheLimit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.backendData.currentUser,
  };
};

export default createProvider(connect(mapStateToProps)(OrganizationInvitePage));
