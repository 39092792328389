import React from 'react'
import Tag from '@components/tag'
import * as styles from './CommunityTag.module.scss'

const CommunityTag = ({ className = '', ...rest }) => {
  return (
    <Tag className={`${styles.tag} ${className}`} {...rest} />
  )
}

export default CommunityTag
