import {
  CardNumberElement,
  injectStripe,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements'
import React from 'react'
import './checkout-stripe-form.scss'
import PropTypes from 'prop-types'
import { Bem, get } from '@common/utils'
import Icon from '@components/icon/index'
import { identity, isString, noop, upperCase } from 'lodash'
import { Else, If, Then } from 'react-if'
import Button from '@components/button/index'
import ReactTooltip from 'react-tooltip'
import { LINKS } from '@common/consts'
import InputWrapper from '../components/input-wrapper'
import { PFX_REQUIRE_SIGN_IN } from '../../../global-events-bindings'
import Message from '../../../components/message'
import { requireExternalLibrary } from '../../../common/utils'
import LoadingIndicator from '../../../components/loading-indicator'

const cn = new Bem({
  name: 'stripe-form',
  prefix: 'pfx-',
})

class StripeForm extends React.Component {
  constructor(props) {
    super(props)
    this.INVALID_POST_FIX = '-invalid'
    this.BRAND_POST_FIX = '-brand'
    this.CHANGED_POST_FIX = '-changed'

    this.REF_KEYS = {
      stripeCard: 'stripeCard',
      stripeExpiry: 'stripeExpiry',
      stripeCvc: 'stripeCvc',
    }

    this.state = {
      canMakePayment: false,
      isTooltipActive: false,
      init: true,
      anyValue: false,
      stripeLibLoaded: false,
    }

    requireExternalLibrary(LINKS.STRIPE_LIBRARY).then(() => {
      this.setState({ stripeLibLoaded: true })
    })
  }

  handleSubmit = async (ev) => {
    const activeElement = document.querySelector(':focus')
    const isActiveElementDiscountInput =
      activeElement && activeElement.getAttribute('data-pfx-discount-input')

    ev.preventDefault()

    if (!this.isInvalid() && !isActiveElementDiscountInput) {
      this.setState({ loading: true })
      await this.props.onSubmit(this.props.stripe, this.stripeCard, this.props.totalAmount)
      this.setState({ loading: false })
    }
  }

  getErrorMessage(refKey) {
    const { error } = this.props
    const stateRef = this.state[refKey + this.INVALID_POST_FIX]
    return (
      (get(error, 'field') === refKey && get(error, 'message')) ||
      (stateRef && stateRef.message)
    )
  }

  renderDefaultErrorMessage() {
    return (
      <Message type="error">
        A server error occurred. Please try again and if the problems remains,
        please contact our support at{' '}
        <a href="mailto:support@soundation.com">support@soundation.com</a>
      </Message>
    )
  }

  isInvalid() {
    const usedInputRefKeys = [
      this.REF_KEYS.stripeCard,
      this.REF_KEYS.stripeCvc,
      this.REF_KEYS.stripeExpiry,
    ]
    return !!usedInputRefKeys
      .map((refKey) => !this[refKey]._complete)
      .filter(identity).length
  }

  renderInputWrapper({
    Component,
    label,
    className,
    icon,
    refKey,
    renderRightSideComponent = noop,
  }) {
    const baseStyle = {
      base: {
        fontSize: '16px',
      },
      invalid: {
        color: '#000',
      },
    }
    return (
      <InputWrapper
        error={this.getErrorMessage(refKey)}
        label={label}
        className={className}
        renderRightSideComponent={renderRightSideComponent}
        icon={icon}
      >
        <Component
          style={baseStyle}
          className={cn('stripe-component')}
          onChange={(e) => this.onInputChange(refKey, e)}
          onReady={(StripeElement) =>
            this.setupElementRef(refKey, StripeElement)
          }
          disabled={this.isLoading()}
        />
      </InputWrapper>
    )
  }

  setupElementRef(refKey, StripeElement) {
    this[refKey] = StripeElement
  }

  renderCreditCardLogo(brand) {
    let imageUrl

    switch (brand) {
      case 'visa':
        imageUrl = 'assets/payments/visa.png'
        break
      case 'mastercard':
        imageUrl = 'assets/payments/mastercard.png'
        break
      case 'amex':
        imageUrl = 'assets/payments/amex.png'
        break
      default:
        return null
    }

    return <img src={imageUrl} className={cn('current-credit-card')} alt={brand} />
  }

  onInputChange(refKey, e) {
    const state = {
      [refKey + this.INVALID_POST_FIX]: e.error,
      [refKey + this.BRAND_POST_FIX]: e.brand,
      [refKey + this.CHANGED_POST_FIX]: true,
    }

    this.setState(state)
  }

  renderTooltipComponent() {
    const containerClass = cn('tooltip-container')
    return (
      <div className={containerClass}>
        <div data-tip data-for="stripe-tooltip">
          <Icon
            icon="question-mark"
            className={cn('question-mark')}
            size={15}
            fontSizeRatio={1}
          />
        </div>
        <ReactTooltip
          id="stripe-tooltip"
          place="top"
          type="light"
          effect="solid"
          className={cn('stripe-tooltip')}
        >
          <div className={cn('stripe-tooltip-content')}>
            <img
              src="assets/payments/cvc-tooltip.png"
              className={cn('stripe-tooltip-image')}
            />
            <div className={cn('stripe-tooltip-text')}>
              CVC number is the last 3 numbers on the back of your card (4
              numbers on the front if Amex)
            </div>
          </div>
        </ReactTooltip>
      </div>
    )
  }

  isLoading() {
    return this.state.loading || this.props.isLoading
  }

  renderPayButton () {
    const { inTrialMode, subscriptionType } = this.props

    return (
      <div className={cn('row', ['center'])}>
        <Button
          className={cn('submit-button')}
          type={inTrialMode && subscriptionType !== 'addon' ? 'primary-full-no-hover' : 'outline-cyan'}
          onClick={(ev) => this.handleSubmit(ev)}
          disabled={
            !this.state.init ||
            !this.props.currentUser ||
            this.isLoading() ||
            this.props.isButtonDisabled
          }
          isLoading={this.isLoading()}>
          <If condition={inTrialMode && subscriptionType !== 'addon'}>
            <Then>
              <div className={cn('pay-button-content')}>
                <span className={cn('pay-button-text')}>Start free trial</span>
              </div>
            </Then>
            <Else>
              <div className={cn('pay-button-content')}>
                <span className={cn('pay-button-text')}>{`Pay ${this.props.totalAmount}`}</span>
              </div>
            </Else>
          </If>
        </Button>
      </div>
    )
  }

  render() {
    const { stripeLibLoaded } = this.state
    return !stripeLibLoaded ? (
      <LoadingIndicator />
    ) : (
      <form data-turbolinks="false" onSubmit={(e) => this.handleSubmit(e)}>
        <div className={cn('row')}>
          <div className={cn('column')}>
            {this.renderInputWrapper({
              Component: CardNumberElement,
              label: 'Card number',
              className: 'card',
              disabled: true,
              icon: 'credit-card',
              renderRightSideComponent: () =>
                this.renderCreditCardLogo(this.state['stripeCard-brand']),
              refKey: 'stripeCard',
            })}
          </div>
        </div>

        <div className={cn('row')}>
          <div className={cn('column', 'half')}>
            {this.renderInputWrapper({
              Component: CardExpiryElement,
              label: 'Expiry date',
              className: 'expiry',
              icon: 'calendar',
              refKey: 'stripeExpiry',
            })}
          </div>

          <div className={cn('column', 'half')}>
            {this.renderInputWrapper({
              Component: CardCVCElement,
              label: 'CVC number',
              className: 'cvc',
              icon: 'lock',
              refKey: 'stripeCvc',
              renderRightSideComponent: () => this.renderTooltipComponent(),
            })}
          </div>
        </div>

        <div className={cn('discount-input')}>
          {this.props.renderDiscountComponent(this.props, this.state)}
        </div>

        {this.props.renderPromotionalCredit()}

        <If
          condition={
            isString(get(this.props, 'error.message')) &&
            !get(this.props, 'error.field')
          }
        >
          <Message type="error">{get(this.props, 'error.message')}</Message>
        </If>

        <If condition={get(this.props, 'error.message') === true}>
          {this.renderDefaultErrorMessage()}
        </If>

        {this.renderPayButton()}

        <If condition={!this.props.currentUser}>
          <p className="pfx--align-center">
            Please{' '}
            <a
              href={`${LINKS.AUTH}?tab=sign_in`}
              {...{ [PFX_REQUIRE_SIGN_IN]: true }}
            >
              log in
            </a>{' '}
            or{' '}
            <a href={LINKS.AUTH_PLANS} {...{ [PFX_REQUIRE_SIGN_IN]: true }}>
              sign up
            </a>{' '}
            before proceeding to checkout.
          </p>
        </If>
      </form>
    )
  }
}

StripeForm.propTypes = {
  currency: PropTypes.string,
  totalAmount: PropTypes.string,
  currentUser: PropTypes.object,
  onSubmit: PropTypes.func,
  error: PropTypes.any,
  onError: PropTypes.func,
  backendAction: PropTypes.func,
  renderDiscountComponent: PropTypes.func,
  isLoading: PropTypes.bool,
  isButtonDisabled: PropTypes.bool,
}

StripeForm.defaultProps = {
  onSubmit: noop,
  onError: noop,
  renderDiscountComponent: noop,
}

export default injectStripe(StripeForm)
