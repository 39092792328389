import "./style.scss";

import React from "react";
import PropTypes from "prop-types";
import { Bem, pluralize, get, gotoLocation } from "@common/utils";
import { isMonthlyIntro } from "./utils";
import List, { ListItem } from "@components/list";
import {
  LINKS,
  PLAN_PERIOD_STR,
  ACCOUNT_TYPES,
  ACCOUNT_TYPES_STR,
} from "@common/consts";
import Switcher from "@components/switcher";
import PricingValue from "./components/pricing-value";
import { partition } from "lodash";
import Linkton from "@components/linkton";
import { getHostedPageCheckoutNewUrl, getHostedPageCheckoutExistingUrl } from '@api/user';
import ChargebeeService from '@common/ChargebeeService';
import { StudioConfiguration } from '@common/config'

const cn = new Bem({
  name: "price-plans",
  prefix: "pfx-",
});

const periodToMonth = {
  [PLAN_PERIOD_STR.MONTHLY]: "month",
  [PLAN_PERIOD_STR.YEARLY]: "year",
};

class PricePlans extends React.Component {
  constructor(props) {
    super(props);

    const { queryParams } = this.props

    this.state = {
      mobileDetailsOpen: false,
      selectedPeriod: this.props.selectedPeriod || queryParams.period || PLAN_PERIOD_STR.YEARLY
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedPeriod !== this.state.selectedPeriod) {
      this.setState({
        selectedPeriod: nextProps.selectedPeriod,
      });
    }
  }

  async componentDidMount() {
    this.mounted = true;

    const { queryParams } = this.props

    if (queryParams.plan && queryParams.period) {
      const item = this.props.items.find((i) => i.name.toLowerCase() == queryParams.plan)
      let selectedPeriod = get(
        item.columnConfig,
        queryParams.period,
        {}
      );
      await this.onPlanClicked(selectedPeriod)
    }
  }

  prepareCyclesPromotionInfo(selectedPeriod) {
    return `Discount applies to ${selectedPeriod.cycles} ${pluralize(
      periodToMonth[this.state.selectedPeriod],
      selectedPeriod.cycles
    )}`;
  }

  async pushOrderCompleteEvent(hostedPageId, period) {
    const { currentUser } = this.props

    const orderCompleteData = {
      'event': 'order_complete',
      'order_id': hostedPageId,
      'order_value': period.totalPrice,
      'order_currency': period.currency,
      'enhanced_conversion_data': {
        'email': currentUser.email
      }
    }
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(orderCompleteData)
  }

  async onPlanClicked(period) {
    if (!this.props.isUserAuthenticated) {
      period.onLoginRequired()
      return
    }

    const chargebeeService = new ChargebeeService({
      site: StudioConfiguration.chargebee.site,
      domain: this.props.chargebeeDomain
    })

    const onSuccess = async (hostedPageId) => {
      setTimeout(() => {
        this.pushOrderCompleteEvent(hostedPageId, period)
        gotoLocation(`${LINKS.CHECKOUT}?success=true`)
      }, 1500)
    }

    if (period.isUpgrade) {
      const { data } = await getHostedPageCheckoutExistingUrl({ token: this.props.accountSubscription.token, planId: period.planId, coupon: period.couponCode })
      chargebeeService.openCheckout({ hostedPage: data.response.hostedPage }).then(onSuccess)
    } else {
      const { data } = await getHostedPageCheckoutNewUrl({ planId: period.planId, coupon: period.couponCode })
      chargebeeService.openCheckout({ hostedPage: data.response.hostedPage }).then(onSuccess)
    }
  }

  renderButton(column, forceType) {
    const { theme } = this.props;

    let selectedPeriod = get(
      column.columnConfig,
      this.state.selectedPeriod,
      {}
    );
    if (
      !selectedPeriod &&
      this.state.selectedPeriod === PLAN_PERIOD_STR.MONTHLY
    )
      selectedPeriod = get(column.columnConfig, "yearlyToMonthly");
    const type = forceType || (column.highlight ? "full" : "outline");

    return (
      <Linkton
        className={cn("plan-button")}
        tag="button"
        type={type}
        padding="large"
        color={theme === "light" ? "klein_white" : "ocean"}
        hover="none"
        isDisabled={selectedPeriod.shouldDisable}
        onClick={() =>this.onPlanClicked(selectedPeriod) }
      >
        {selectedPeriod.buttonLabel}
      </Linkton>
    );
  }

  renderFeatures(features = [], mobile) {
    return (
      <List className={cn("features", { mobile })}>
        {features.map((item, i) => {
          return (
            <ListItem
              key={i}
              disabled={!item.enable}
              className={cn("features-item")}
            >
              <div
                dangerouslySetInnerHTML={{ __html: item.safeHtml }}
                className={cn("features-item-html-wrapper")}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  getPeriodData(column) {
    const { columnConfig } = column;

    if (!column || !columnConfig) return null;

    const isDisabledIntro = isMonthlyIntro(
      this.state.selectedPeriod,
      ACCOUNT_TYPES[columnConfig.plan.toUpperCase()]
    );
    const {
      monthly = {},
      yearlyToMonthly = {},
      discountedYearlyToMonthly = {},
    } = columnConfig;

    let selectedPeriodData;

    if (isDisabledIntro) {
      selectedPeriodData = yearlyToMonthly;
    } else if (this.state.selectedPeriod === PLAN_PERIOD_STR.YEARLY) {
      const { total, originalTotalPrice } = discountedYearlyToMonthly;
      selectedPeriodData =
        total || originalTotalPrice
          ? discountedYearlyToMonthly
          : yearlyToMonthly;
    } else {
      selectedPeriodData = monthly;
    }

    return selectedPeriodData;
  }

  renderTableColumn(column) {
    const { theme } = this.props;
    const { name, features, highlight } = column;

    const selectedPeriodData = this.getPeriodData(column);
    const isPromotion = selectedPeriodData.couponDiscountAmount > 0;

    const periodName =
      PLAN_PERIOD_STR.MONTHLY === this.state.selectedPeriod
        ? "MONTHLY"
        : "YEARLY";
    const planTitle = `${name} ${periodName}`;

    return (
      <div
        className={cn("column", { highlight, promotion: isPromotion }, theme)}
        key={name}
      >
        <div className={cn("highlight-label")}>
          <div className={cn("highlight-label-wrapper")}>
            {isPromotion
              ? `${selectedPeriodData.couponDiscountPercentage}% off!`
              : "Popular"}
          </div>
        </div>

        <div className={cn("top-container")}>
          <div
            className={cn(
              "plan-name",
              null,
              "pfx-sub-header pfx--align-center"
            )}
          >
            {planTitle}
          </div>
          <PricingValue
            price={selectedPeriodData || {}}
            period={periodToMonth[PLAN_PERIOD_STR.MONTHLY]}
            isPromotion={isPromotion}
            isHighlight={highlight}
          />
        </div>

        {this.renderFeatures(features, null, column.highlight)}

        <div className={cn("actions-container")}>
          {this.renderButton(
            column,
            isPromotion && highlight ? "promotion" : null
          )}
        </div>

        {isPromotion && highlight && (
          <div className={cn("cycles-info")}>
            {this.prepareCyclesPromotionInfo(selectedPeriodData)}
          </div>
        )}
      </div>
    );
  }

  onSwitcherUpdate = (updatedValue) => {
    this.setState({
      selectedPeriod: updatedValue,
    });
  };

  render() {
    const { items, theme, className, isUserAuthenticated, loginLink } = this.props;
    const [[freePlan], paidPlans] = partition(
      items,
      (item) =>
        item.columnConfig && item.columnConfig.plan === ACCOUNT_TYPES_STR.FREE
    );

    let title;
    if (this.props.title) {
      title = this.props.title;
    } else {
      title = "Choose the plan that's right for you";
    }

    return (
      <div className={cn(null, null, [theme, className])}>
        {!isUserAuthenticated && (
          <React.Fragment>
            <p className={cn("sign-in-link-container")}>
              Already a user?
              <a href={loginLink}>Log in</a>
            </p>
          </React.Fragment>
        )}
        <h1 className={cn("main-header", null, ["pfx--align-center", theme])}>
          SOUNDATION PRICING
        </h1>
        <h2 className={cn("header", null, ["pfx--align-center", theme])}>
          {title}
        </h2>

        <div className="d-flex justify-content-center mt-4">
          <Switcher
            theme={theme}
            className={cn("switcher", "mobile", "d-flex")}
            buttons={[
              { label: "Billed annually", value: PLAN_PERIOD_STR.YEARLY },
              { label: "Billed monthly", value: PLAN_PERIOD_STR.MONTHLY },
            ]}
            selected={this.state.selectedPeriod}
            onUpdate={this.onSwitcherUpdate}
          />
        </div>

        {/* paid plans */}
        <div className={cn("table", null, this.props.className)}>
          {paidPlans.map((column) => this.renderTableColumn(column))}
        </div>

        {!isUserAuthenticated && !!freePlan && (
          <div>
            <div className={cn("free-msg-container")}>
              <p className={cn("free-msg", null, theme)}>
                Not sure yet what plan you need? Get started with the Free plan
              </p>
            </div>

            <div className={cn("free-button")}>
              {this.renderButton(freePlan)}
            </div>
          </div>
        )}
      </div>
    );
  }
}

PricePlans.propTypes = {
  items: PropTypes.array.isRequired,
  currentUser: PropTypes.object,
  isUserAuthenticated: PropTypes.bool.isRequired,
  selectedPeriod: PropTypes.oneOf([
    PLAN_PERIOD_STR.MONTHLY,
    PLAN_PERIOD_STR.YEARLY,
  ]),
  className: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.oneOf(["light", "dark"]),
  loginLink: PropTypes.string
};
PricePlans.defaultProps = {
  theme: "dark",
  loginLink: LINKS.AUTH_SIGN_IN,
  queryParams: {}
};

export default PricePlans;
