'use client'

import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '../../common/utils'

const cn = new Bem({
  name: 'icon',
  prefix: 'pfx-'
})

const ICON_TYPES = new Enum('HOLLOW', 'OUTLINE', 'BLANK')

class Icon extends React.Component {
  render () {
    const { forwardedRef, size, type, className, style } = this.props
    const iconStyle = {
      width: size,
      height: size,
      fontSize: (size / this.props.fontSizeRatio),
      cursor: this.props.onClick ? 'pointer' : 'inherit',
      ...style
    }
    return (
      <div
        ref={forwardedRef}
        className={cn(null, type, className)}
        onClick={this.props.onClick}
        onMouseDown={this.props.onMouseDown}
        onMouseEnter={this.props.onMouseEnter}
        onMouseLeave={this.props.onMouseLeave}
        style={iconStyle}>
        <i className={'icon-' + this.props.icon} />
      </div>
    )
  }
}

Icon.propTypes = {
  size: PropTypes.number,
  type: PropTypes.oneOf([
    ICON_TYPES.HOLLOW,
    ICON_TYPES.OUTLINE,
    ICON_TYPES.BLANK
  ]),
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  icon: PropTypes.string,
  fontSizeRatio: PropTypes.number,
  style: PropTypes.object
}

Icon.defaultProps = {
  size: 46,
  type: ICON_TYPES.BLANK,
  fontSizeRatio: 1,
  style: {}
}

export default React.forwardRef((props, ref) => <Icon {...props} forwardedRef={ref} />)
