'use client'
import React from 'react'
import PropTypes from "prop-types";
import Linkton, { COLOR, HOVER, SIZE, TAG, TYPE } from "../linkton";
import { get } from "@common/utils";
import { LINKS } from "@common/consts";
import createProvider from "../../store/provider";
import { connect } from "react-redux";

const typeToEnum = new Proxy(
  {
    full: TYPE.FULL,
    outline: TYPE.OUTLINE,
    hollow: TYPE.HOLLOW,
    underline: TYPE.UNDERLINED_LINK,
  },
  {
    get: (obj, prop) => (prop in obj ? obj[prop] : TYPE.FULL),
  }
);

const toMap = new Proxy(
  {
    signup: LINKS.AUTH_PLANS,
    studio: LINKS.STUDIO,
  },
  {
    get: (obj, prop) => (prop in obj ? obj[prop] : "/"),
  }
);

const CTAButton = ({
  text,
  type = "full",
  to,
  screenBreakPointIndex,
  className,
  dispatch,
  size,
  url,
  ...rest
}) => {
  return (
    <Linkton
      size={size || screenBreakPointIndex < 2 ? SIZE.LARGE : SIZE.MEDIUM}
      type={typeToEnum[type]}
      color={COLOR.OCEAN}
      hover={HOVER.TRANSPARENCY}
      padding={100}
      tag={TAG.ANCHOR}
      href={url || toMap[to]}
      className={className}
      {...rest}
    >
      {text}
    </Linkton>
  );
};

export const CTAButtonPropTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["full", "outline", "hollow", "underline"]),
  to: PropTypes.oneOf(["signup"]),
  url: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
  url: PropTypes.string,
};

CTAButton.propTypes = PropTypes.shape(CTAButtonPropTypes).isRequired;

const mapStateToProps = (state) => ({
  screenBreakPointIndex: get(state, "app.screenBreakPointIndex"),
});

const ConnectedCTAButton = createProvider(connect(mapStateToProps)(CTAButton));

export { ConnectedCTAButton as CTAButton };
