import { actionTypes } from './types/flashMessage'

export const setFlashMessages = messages => dispatch => {
  dispatch({ type: actionTypes.SET_MESSAGES, payload: messages })
}

export const shiftMessage = () => dispatch => {
  dispatch({ type: actionTypes.SHIFT_MESSAGE, payload: null })
}

export const setFlashMessageByValues = ({ text, closeButton, type = 'notice', autoclose = true}) => dispatch => {
  dispatch({ type: actionTypes.SET_BY_VALUES, payload: { text, type, autoclose, closeButton } })
}

export const showFlashMessage = _ => dispatch => {
  dispatch({ type: actionTypes.SHOW })
}

export const hideFlashMessage = _ => dispatch => {
  dispatch({ type: actionTypes.HIDE })
}

export const setAutoclose = autoclose => dispatch => {
  dispatch({ type: actionTypes.SET_AUTOCLOSE, payload: autoclose })
}
