'use client'

import React, { useState } from 'react'
import './style.scss'
import { Bem } from '@common/utils'
import Icon from '../icon'
import PropTypes from 'prop-types'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'user-notification'
})

const UserNotification = (props) => {
  const { children, type } = props

  const [open, setOpen] = useState(false)

  const renderTooltip = () => {
    return (
      <div className={cn('tooltip', null, 'pfx--mono')}>
        { children }
      </div>
    )
  }

  const toggle = () => {
    setOpen((o) => !o)
  }

  return (
    <div className={cn(null, type)}>
      <Icon className={cn('icon')} icon={type === 'success' ? 'success-validation' : 'warning'} size={28} onClick={toggle} />
      { open ? renderTooltip() : ''}
    </div>
  )
}

UserNotification.propTypes = {
  type: PropTypes.oneOf(['error', 'success'])
}

export default UserNotification
