'use client'

import './style.scss'
import React from 'react'
import PropTypes from 'prop-types'
import {
  Bem,
  deepCamelCase,
  Enum,
  gotoLocation,
  replaceHref,
  getStudioHref,
  isWideContainerContext,
  trimLeadingAndTrailingSlash,
  get
} from '@common/utils'
import { includes, isFunction, mapValues, noop } from 'lodash'
import { ENDPOINTS, LINKS } from '@common/consts'
import { TABS } from '@containers/auth-gateway-view/index'
import { isUserSet } from '@common/user'
import Icon from '@components/icon/index'
import Logo from '@components/logo/index'
import UserDropdown from '@components/user-dropdown/index'
import Link from '@components/link'
import mobileDetect from '@common/mobile-detection'
import { connect } from 'react-redux'
import createProvider from '../../store/provider'
import CommunityHeader from '../community/community-header'
import { LIMITATION_SIDEBAR } from '@containers/mobile-studio-limitation-sidebar/index'
import {
  closeMobileMenu,
  openMobileLimitationSidebar,
  openMobileMenu
} from '../../store/actions/app'
import { isReturningUser } from '../../common/utils'
import UserNotification from '../../components/user-notification'
import { REFERRAL_ENABLED_PAYMENT_METHODS } from '../../common/consts'
import Linkton, { SIZE, TYPE, HOVER, COLOR, TAG } from '../../components/linkton'

const cn = new Bem({
  name: 'main-menu',
  prefix: 'pfx-'
})

const CONTROLLER_NAMES = new Enum(
  'HOME',
  'PRODUCTS',
  'ONLINE_STUDIO',
  'MAKE_MUSIC',
  'CATEGORIES',
  'CART',
  'LEARN',
  'PAGES',
  'FEED',
  'ACCOUNT'
)
const MODULE_NAMES = new Enum('COMMUNITY', 'ACCOUNT')
const ACTION_NAMES = new Enum('INDEX', 'ACCOUNTS')

export class MainMenu extends React.Component {
  constructor (props) {
    super(props)
    const MENU_LINKS = {
      STUDIO: this.prepareStudioAnchorConfig(),
      ONLINE_STUDIO: {
        url: LINKS.ONLINE_STUDIO,
        label: 'Online studio',
        isActive: () => this.isMatchForActiveLink(LINKS.ONLINE_STUDIO) || this.isController(CONTROLLER_NAMES.ONLINE_STUDIO)
      },
      MAKE_MUSIC: {
        url: LINKS.MAKE_MUSIC,
        label: 'Make music',
        isActive: () => this.isMatchForActiveLink(LINKS.MAKE_MUSIC) || this.isController(CONTROLLER_NAMES.MAKE_MUSIC)
      },
      LEARN: {
        url: LINKS.LEARN,
        label: 'Learn',
        isActive: () => this.isMatchForActiveLink(LINKS.LEARN) || this.isController(CONTROLLER_NAMES.LEARN)
      },
      COMMUNITY: {
        url: this.currentUser ? LINKS.FEED : LINKS.TRACKS,
        label: 'Community',
        isActive: () => this.isMatchForActiveLink(this.currentUser ? LINKS.FEED : LINKS.TRACKS) || this.isModule(MODULE_NAMES.COMMUNITY)
      },
      SHOP: {
        url: LINKS.SHOP,
        label: 'Sound Shop',
        isActive: () => this.isMatchForActiveLink(LINKS.SHOP) || this.isShop() 
      },
      PRICING: {
        url: this.props.eduMode ? LINKS.EDU_PRICING : LINKS.PRICING,
        label: 'Pricing',
        isActive: () => this.isMatchForActiveLink(LINKS.PRICING) || (this.isController(CONTROLLER_NAMES.PAGES) && this.isAction(ACTION_NAMES.ACCOUNTS))
      },
      INVITE_FRIEND: {
        url: LINKS.ACCOUNT_REFERRAL,
        label: 'Invite Friend',
        mobileOnly: true
      }
    }

    const MENU_BUTTONS = {
      LOGIN: {
        url: LINKS.AUTH,
        size: 'medium',
        label: 'Login',
        type: 'hollow',
        params: {
          tab: TABS.SIGN_IN
        }
      },
      SING_UP: {
        url: LINKS.AUTH_PLANS,
        size: 'medium',
        label: 'Sign up',
        type: 'primary_hollow'
      }
    }

    this.state = {
      linkList: this.createLinksList([
        MENU_LINKS.STUDIO,
        MENU_LINKS.ONLINE_STUDIO,
        MENU_LINKS.MAKE_MUSIC,
        MENU_LINKS.LEARN,
        !this.props.eduMode && MENU_LINKS.COMMUNITY,
        !this.props.eduMode && MENU_LINKS.SHOP,
        MENU_LINKS.PRICING,
        !this.props.eduMode && MENU_LINKS.INVITE_FRIEND
      ].filter(Boolean)),
      buttonList: this.createLinksList([MENU_BUTTONS.LOGIN, MENU_BUTTONS.SING_UP]),
      widePageVersion: false,
      pricingPage: false
    }

    this.currentUserParsed = deepCamelCase(this.props.currentUser)
  }

  componentDidMount () {
    this.setState({
      widePageVersion: isWideContainerContext(),
      pricingPage: window.location.pathname === '/accounts'
    })
  }

  prepareStudioAnchorConfig () {
    let studioHref = getStudioHref()

    if (!this.props.currentUser) {
      if (isReturningUser()) {
        studioHref = LINKS.AUTH + '?tab=sign_in&redirect_to=studio'
      } else {
        studioHref = LINKS.AUTH_PLANS
      }
    }

    let config = {
      label: 'Dashboard'
    }

    if (mobileDetect.mobile()) {
      config.onClick = () => {
        this.props.openMobileLimitationSidebar(LIMITATION_SIDEBAR.NO_MOBILE_APP)
      }
    } else {
      config.url = studioHref
    }

    return config
  }

  toggleMobileMenu = () => {
    if (this.props.mobileOpen) {
      this.props.closeMobileMenu()
    } else {
      this.props.openMobileMenu()
    }
  }

  isAction (action) {
    return this.props.actionName === action
  }

  isModule (module) {
    return this.props.moduleName === module
  }

  isMatchForActiveLink (link) {
    const { activeLink } = this.props
    if (!activeLink) return false

    return trimLeadingAndTrailingSlash(link) === trimLeadingAndTrailingSlash(activeLink)
  }

  isController (controller) {
    return this.props.controllerName === controller
  }

  isShop () {
    const shopControllers = [
      CONTROLLER_NAMES.PRODUCTS,
      CONTROLLER_NAMES.CATEGORIES,
      CONTROLLER_NAMES.CART
    ]
    return includes(shopControllers, this.props.controllerName)
  }

  createLinksList (sortedLinkList) {
    return sortedLinkList.map(link => {
      return mapValues(
        link,
        (value, key) => (isFunction(value) && key !== 'onClick' ? value() : value)
      )
    })
  }

  renderLinks () {
    return this.state.linkList.map((link, i) => {
      return (
        <a
          className={cn('link', { active: link.isActive, 'mobile-only': link.mobileOnly })}
          key={i}
          onClick={() => (link.onClick || noop)()}
          href={link.url}
        >
          <span className={cn('link-text')}>{link.label}</span>
          <div className={cn('link-underline')} />
        </a>
      )
    })
  }

  renderLandingPageLinks () {
    return [
      <Linkton
        key='1'
        className={cn('landing-link')}
        href={LINKS.AUTH_SIGN_IN}
        tag={TAG.ANCHOR}
        type={TYPE.HOLLOW}
        hover={HOVER.UNDERSCORE}
        size={SIZE.LARGE}
        color={COLOR.WHITE}
        padding={25}
      >
        Log in
      </Linkton>,
      <Linkton
        key='0'
        className={cn('landing-link')}
        href={LINKS.AUTH_PLANS}
        tag={TAG.ANCHOR}
        type={TYPE.HOLLOW}
        hover={HOVER.UNDERSCORE}
        size={SIZE.LARGE}
        color={COLOR.OCEAN}
        padding={25}
      >
        Sign up
      </Linkton>
    ]
  }

  renderButtons (size) {
    return this.state.buttonList.map((button, i) => {
      return (
        <a
          className={cn('link', { [button.type]: true })}
          key={i}
          onClick={() => gotoLocation(button.url, button.params)}
          href='#'
        >
          <span className={cn('link-text')}>{button.label}</span>
          <div className={cn('link-underline')} />
        </a>
      )
    })
  }

  isUserSet () {
    return !!isUserSet(this.props.currentUser)
  }

  render () {
    const { currentUser, account, eduMode } = this.props

    const paymentMethod = get(account, 'currentPaymentMethod')
    const referralEnabled = REFERRAL_ENABLED_PAYMENT_METHODS.indexOf(paymentMethod) !== -1

    if (!!currentUser && !eduMode) return <CommunityHeader explicitHomeLink showSearch={false} />

    return (
      <div
        className={cn(null, {
          'user': this.isUserSet(),
          'mobile-open': this.props.mobileOpen,
          'landing-context': this.props.landingPage,
          'pricing-context': this.state.pricingPage,
          'attached': this.props.attached
        })}
      >
        <header className={cn('header')}>
          <div
            className={cn(
              'wrapper',
              null,
              `container ${this.state.widePageVersion ? 'container--fluid' : ''}`
            )}
          >
            <div className={cn('logo')}>
              <Logo
                type='icon'
                color='light'
                alt='Soundation Logo'
                className={cn('logo-img')}
              />
            </div>
            <div className={cn('actions')} data-turbolinks='false'>
              <div className={cn('link-list')}>
                {this.isUserSet() || !this.props.landingPage ? this.renderLinks() : null}
              </div>
              <div className={cn('button-list')}>
                {this.state.widePageVersion && !this.state.pricingPage && !this.isUserSet()
                  ? this.renderLandingPageLinks()
                  : this.renderButtons('medium')}
              </div>
              <div className={cn('current-user')}>
                {
                  currentUser && currentUser.emailBounced
                    ? <UserNotification type='error'>
                      Hey! It looks like something is wrong with your email address.
                      <br/>
                      <Link
                        href='/account/manage'
                        className={cn('user-notification-button')}
                        type='secondary-full-inverse'
                        size='small'>
                        Change email
                      </Link>
                    </UserNotification>
                    : null
                }
                {
                  !eduMode && referralEnabled && <div className={cn('invite-friend')} onClick={() => gotoLocation(LINKS.ACCOUNT_REFERRAL)}>
                    Invite a friend
                  </div>
                }
                <UserDropdown
                  currentUser={currentUser}
                  avatarUrl={this.props.avatarUrl}
                  eduMode={eduMode}
                />
              </div>
              <div className={cn('hamburger')}>
                {this.props.mobileOpen ? (
                  <Icon
                    onClick={this.toggleMobileMenu}
                    type={'blank'}
                    icon={'new-close'}
                    size={20}
                  />
                ) : (
                  <Icon
                    onClick={this.toggleMobileMenu}
                    type={'outline'}
                    icon={'new-hamburger'}
                    size={16}
                  />
                )}
              </div>
            </div>
          </div>
        </header>

        {this.isUserSet() ? (
          <div className={cn('mobile', { active: this.props.mobileOpen })}>
            <div className={cn('overlay')} />
            <div className={cn('mobile-container', null, 'container')}>
              <div className='container'>
                <div>
                  <div className='pfx-sub-header'> Menu </div>
                  <div className={cn('mobile-link-list')}>{this.renderLinks(true)}</div>
                  <div className={cn('mobile-button-list', null, 'pfx--flex-column')}>
                    <hr className={cn('hr')} />
                  </div>
                </div>
                <div className={cn('mobile-footer')}>
                  <a className={cn('secondary-link')} href={LINKS.ACCOUNT_PROFILE}>
                    <Icon type={'blank'} icon={'account'} size={22} />
                    <span>My account</span>
                  </a>
                  <a
                    className={cn('secondary-link')}
                    href='#'
                    onClick={e => {
                      e.preventDefault()
                      replaceHref(ENDPOINTS.LOGOUT)
                    }}
                  >
                    <Icon type={'blank'} icon={'log_out'} size={21} />
                    <span>Log Out</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

MainMenu.propTypes = {
  currentUser: PropTypes.object,
  controllerName: PropTypes.string,
  moduleName: PropTypes.oneOf(['', MODULE_NAMES.COMMUNITY, MODULE_NAMES.ACCOUNT]),
  notificationCount: PropTypes.number,
  actionName: PropTypes.string,
  avatarUrl: PropTypes.string,
  landingPage: PropTypes.bool,
  attached: PropTypes.bool,
  mobileOpen: PropTypes.bool,
}

MainMenu.defaultProps = {
  currentUser: null,
  controllerName: CONTROLLER_NAMES.HOME,
  moduleName: '',
  notificationCount: null,
  actionName: ACTION_NAMES.INDEX,
  attached: false
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: get(props, 'currentUser') ?? get(state, 'backendData.currentUser'),
    account: get(props, 'account') ?? get(state, 'backendData.account'),
    moduleName: get(state, 'backendData.moduleName'),
    controllerName: get(state, 'backendData.controllerName'),
    actionName: get(state, 'backendData.actionName'),
    activeLink: get(props, 'activeLink'), // only passed from next as an alternative to the moduleName/controllerName/actionName passed from rails used to figure out which menu link is active
    notificationCount: get(props, 'notificationCount') ?? get(state, 'backendData.notificationCount'),
    avatarUrl: get(props, 'avatarUrl') ?? get(state, 'backendData.avatarUrl'),
    mobileLimitationSideOpen: get(state, 'app.mobileLimitationSideOpen'),
    mobileOpen: get(state, 'app.mobileMenuOpen')
  }
}

const mapDispatchToProps = {
  openMobileLimitationSidebar,
  openMobileMenu,
  closeMobileMenu
}

export default createProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MainMenu)
)
