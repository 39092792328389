import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, gotoLocation, capitalizeFirstLetter, get, goToAuthSuccess } from '../../common/utils'
import { getCurrencySymbol, prepareTableConfig, isUpgrade, getPrice } from '../../components/price-plans/utils'
import PricingValue from '../../components/pricing-table/components/pricing-value'
import { ACCOUNT_TYPES, GTM_EVENTS, LINKS } from '../../common/consts'
import { If } from 'react-if'
import { sendAnalyticsEvent } from '../../common/analytics'
import { CheckoutThankYouPage } from './containers/checkout-thank-you-page'
import { getColumn } from './services/utils'
import PeriodChooser from './components/period-chooser'
import CheckoutCart from './containers/checkout-cart'
import { PERIOD, PRODUCT_TYPE } from './services/consts'
import CheckoutPaymentForms from './containers/checkout-payment-forms'
import { MessageHandler } from '../../common/messageHandler'

const cn = new Bem({
  name: 'checkout-view',
  prefix: 'pfx-'
})

class CheckoutView extends React.Component {
  constructor (props) {
    super(props)

    const { queryParams, currentUser, currentPaymentPeriod } = props

    if (!queryParams.success && MessageHandler.isInOAuthLoginPopup()) {
      MessageHandler.postSignInOAuthMessage({ url: window.location.href })
      return
    }

    if (!!queryParams.success) {
      if (MessageHandler.isInStudioLoginFlowPopup()) {
        goToAuthSuccess("purchase")
        return
      } else {
        MessageHandler.postPurchaseMessage(true)
      }
    }

    this.state = {
      activePeriod: this.getPeriodFullName(this.props.queryParams.period) || PERIOD.YEAR,
      mobileDetailsOpen: false,
      productType: queryParams.productType || PRODUCT_TYPE.SUBSCRIPTION,
      products: this.props.products,
      successSubmit: !!queryParams.success,
      referralId: queryParams.referralId,
      plan: queryParams.plan
    }

    if (!queryParams.success && queryParams.productType === PRODUCT_TYPE.SUBSCRIPTION) {
      const planIdx = ACCOUNT_TYPES[queryParams.plan.toUpperCase()]
      if (
        queryParams.referralId && currentUser.accountType !== ACCOUNT_TYPES.FREE ||           // deny using referral id for paying users
        currentUser.accountType > planIdx ||                                                  // deny going to checkout with plan lower than the current one
        currentUser.accountType === planIdx && currentPaymentPeriod === queryParams.period || // deny upgrading to that same plan without changing period
        currentPaymentPeriod === 'yearly' && queryParams.period === 'monthly'                  // deny upgrading from yearly to monthly
      ) {
        if (MessageHandler.isInStudioLoginFlowPopup()) {
          MessageHandler.postSignInMessage()
          return
        }

        gotoLocation(LINKS.FEED)
      }
    }

    if (queryParams.productType === PRODUCT_TYPE.SUBSCRIPTION && queryParams.plan) {

      this.pricingTableConfig = prepareTableConfig({
        prices: this.props.priceList,
        user: this.props.currentUser,
        couponCode: null,
        accountSubscription: null
      })
      this.getColumn = getColumn.bind(this, this.pricingTableConfig)
      this.state.activeColumn = this.getColumn(this.props.queryParams.plan)
      this.state.activePrice = this.props.priceList[this.state.plan][this.state.activePeriod]
    }
  }

  componentDidMount () {
    const storageKey = 'currentCheckoutProductType'
    const storageValue = window.sessionStorage.getItem(storageKey)
    if (this.state.successSubmit && storageValue) {
      if (storageValue === PRODUCT_TYPE.SUBSCRIPTION) {
        sendAnalyticsEvent(GTM_EVENTS.USER_ACCOUNT_UPGRADE)
      } else if (storageValue === PRODUCT_TYPE.SOUND) {
        // @todo it is not a priority to track sound shop at this moment
        //   - there is a need to also handle paypal return page
        // sendAnalyticsEvent(GTM_EVENTS.SOUND_SHOP_PURCHASE)
      }
      window.sessionStorage.removeItem(storageKey)
    } else if (!this.state.successSubmit) {
      window.sessionStorage.setItem(storageKey, this.state.productType)
    }
  }

  getPeriodFullName (propPeriod = '') {
    const result = propPeriod.match(/(.*)ly/)
    return result && result[1]
  }

  renderPrice (selectedPeriodData, month, isPromotion = false, highlight = false) {
    return <PricingValue
      price={selectedPeriodData || {}}
      period={month}
      isPromotion={isPromotion}
      isHighlight={highlight}
    />
  }

  isUpgrade = () => {
    if (this.state.productType !== PRODUCT_TYPE.SUBSCRIPTION) {
      return false
    }

    const selectedPeriodData = this.state.activeColumn.columnConfig[this.state.activePeriod + 'ly']

    return isUpgrade(this.props.currentUser, selectedPeriodData && selectedPeriodData.available)
  }

  setMobileMenu (val) {
    this.setState({ mobileDetailsOpen: val })
  }

  onPlanChange (period) {
    this.setState({ activePeriod: period })
  }

  renderHeading () {
    return (
      <div className={cn('header-container')}>
        <img src='/assets/soundation-icon-dark.svg' alt='Soundation Logo' className={cn('logo')} />
        <h2 className={cn('header')}>{this.getTitle()}</h2>
      </div>
    )
  }

  getTitle () {
    let title
    switch (this.state.productType) {
      case PRODUCT_TYPE.SOUND:
        title = 'Secure payment'
        break
      case PRODUCT_TYPE.SUBSCRIPTION:
        title = `Start your ${capitalizeFirstLetter(this.state.plan)} subscription`
        break
    }
    return title
  }

  replaceFullPriceToDiscount(price) {
    price.originalPrice = price.fullPrice
    price.fullPrice = price.total
    return price
  }

  onPriceRefresh = (priceList) => {
    const { queryParams: { plan, period } } = this.props;
    this.setState({ activePrice: priceList[plan][period] });
  }

  logOrderCompleteEvent() {
    let key = 'gtm-order-complete-data'
    let data = sessionStorage.getItem(key)
    if (data) {
      let orderCompleteData = JSON.parse(data)
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(orderCompleteData);
      sessionStorage.removeItem(key);
    }
  }

  renderTypeSpecificOptions () {
    const { productType, activePeriod, products, activePrice } = this.state
    const { currentUser, queryParams: { plan }, currentPaymentPeriod, currency } = this.props

    const currencySymbol = getCurrencySymbol(currency)

    let component
    let priceObj

    // upgrading user? show them how much they're actually going to pay above their current balance.
    if (productType === PRODUCT_TYPE.SUBSCRIPTION && activePrice && activePrice.fullPrice !== activePrice.total) {
      return (
        <div className={cn('referral-price')}>
          <div className={cn('referral-price-header')}>
            Soundation {capitalizeFirstLetter(plan)}
          </div>
          <div className={cn('referral-price-value')}>
            <PricingValue
              price={getPrice(activePrice, true)}
              period={activePrice.cycles === 1 ? `First ${activePeriod}` : activePeriod}
              isPromotion={false}
              isHighlight={false}
            />
          </div>
          <div className={cn('referral-price-description')}>
            {currencySymbol}{activePrice.fullPrice}/{activePeriod} after the discount expires. Cancel anytime.
          </div>
        </div>
      )
    }

    switch (productType) {
      case PRODUCT_TYPE.SUBSCRIPTION:
        priceObj = this.getColumn(plan).columnConfig
        component = <PeriodChooser
          activePeriod={activePeriod}
          plan={plan}
          planId={this.getColumn(plan).type}
          priceObj={priceObj}
          currentUser={currentUser}
          // This should render only price part for chooser (check what’s needed on this)
          renderPrice={(...args) => this.renderPrice(...args)}
          currentPaymentPeriod={currentPaymentPeriod}
          onPlanChange={activePeriod => this.onPlanChange(activePeriod)} />
        break
      case PRODUCT_TYPE.SOUND:
        component = <CheckoutCart
          currency={currency}
          onProductListChange={(products) => this.setState({ products })}
          products={products} />
        break
    }

    return component
  }

  render () {
    const {
      productType,
      successSubmit,
      activeColumn,
      mobileDetailsOpen,
      products,
      activePeriod,
      referralId
    } = this.state

    const {
      headless,
      currency,
      currentUser,
      accountSubscription,
      queryParams,
      currentPaymentMethod,
      stripePublishableKey,
      subscriptionType
    } = this.props

    const shouldDisplayForm = !!(productType === PRODUCT_TYPE.SUBSCRIPTION || (productType === PRODUCT_TYPE.SOUND && products.length))

    if (!!successSubmit) {
      this.logOrderCompleteEvent()
    }

    return (
      <div className={cn()}>
        <If condition={!!successSubmit}>
          <div className={cn('actions-container', null)}>
            { CheckoutThankYouPage({ productType, closePageAfterSuccess: queryParams.closeAfterAuthentication === 'true' }) }
          </div>
        </If>
        { !successSubmit && (
          <div className={cn('container')}>
            { this.renderHeading() }
            <div className={cn('content')}>
              <div className={cn('actions-container', null)}>
                { this.renderTypeSpecificOptions() }
                {shouldDisplayForm && <CheckoutPaymentForms
                  headless={headless}
                  referralId={referralId}
                  productType={productType}
                  activePeriod={activePeriod}
                  products={products}
                  currency={currency}
                  currentUser={currentUser}
                  accountSubscription={accountSubscription}
                  subscriptionType={subscriptionType}
                  plan={queryParams.plan}
                  currentPaymentMethod={currentPaymentMethod}
                  stripePublishableKey={stripePublishableKey}
                  selectedPaymentTab={queryParams.selectedPaymentTab}
                  paypalPaymentError={queryParams.paypalPaymentError}
                  activeColumn={activeColumn}
                  isUpgrade={this.isUpgrade()}
                  discountCode={queryParams.couponCode}
                  onPriceRefresh={this.onPriceRefresh}
                />}
              </div>
            </div>
          </div>
        )}
        { this.pricingTable && this.pricingTable.renderMobileDetails(activeColumn, mobileDetailsOpen, () => this.setMobileMenu(false)) }
      </div>
    )
  }
}

CheckoutView.propTypes = {
  queryParams: PropTypes.shape({
    plan: PropTypes.string, // plan to pick from plans collection
    productType: PropTypes.string.isRequired, // subscription/product
    couponCode: PropTypes.string, // coupon code
    period: PropTypes.string, // preselected subscription period (monthly/yearly)
    success: PropTypes.string,
    referralId: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.object,
  priceList: PropTypes.object,
  products: PropTypes.array,
  currency: PropTypes.string,
  stripePublishableKey: PropTypes.string,
  currentPaymentMethod: PropTypes.string,
  currentPaymentPeriod: PropTypes.string,
  headless: PropTypes.bool
}

CheckoutView.defaultProps = {
  products: [],
  headless: false
}

export default CheckoutView
