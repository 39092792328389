import React from 'react'
import { LINKS } from '@common/consts'
import CommunityButton from '@components/community-button'

const CommunityCreateProjectButton = ({ className = '' }) => {
  return (
    <CommunityButton
      className={className}
      tag='anchor'
      href={LINKS.EMPTY_PROJECT_TEMPLATE}
      icon='plus'
      iconPlacement='left'
    >
      Create project
    </CommunityButton>
  )
}

export default CommunityCreateProjectButton
