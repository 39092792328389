import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import Icon from '../icon'
import './style.scss'

const cn = new Bem({
  name: 'change-image-button',
  prefix: 'pfx-'
})

class ChangeImageButton extends Component {
  triggerClick () {
    this.ref.click()
  }

  onChange (event) {
    const { onChange } = this.props
    if (onChange) {
      onChange(event.target.files[0])
    }
  }

  render () {
    const { className = '', btnClassName = '', label, disabled, uploadProgress } = this.props
    return (<div className={cn('', { disabled }, className)}>
      <input
        type='file'
        className={cn('file-input')}
        onChange={event => this.onChange(event)}
        ref={(r) => this.ref = r}
        accept={'image/jpg,image/jpeg,image/png'}
      />
      <button className={cn('button', null, btnClassName)} onClick={() => !disabled && this.triggerClick()}>
        { uploadProgress ? <div className={cn('upload-progress')} style={{ width: (uploadProgress * 100) + '%' }} /> : '' }
        <Icon icon='Image-new' size={16} className={cn('button-icon')} />
        <span className={cn('button-label')}>
          { label }
        </span>
      </button>
    </div>)
  }
}

ChangeImageButton.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  uploadProgress: PropTypes.number
}

ChangeImageButton.defaultProps = {
  disabled: false
}

export default ChangeImageButton
