import React from 'react'
import { getStudioHref, gotoStudio, get } from '@common/utils'
import { PFX_REQUIRE_SIGN_IN } from '../../global-events-bindings'
import PropTypes from 'prop-types'
import Button, { BUTTON_DEFAULT_PROPS, BUTTON_PROP_TYPES } from './index'
import { pick, keys, noop } from 'lodash'
import { connect } from 'react-redux'
import { openMobileLimitationSidebar } from '@store/actions/app'
import createProvider from '../../store/provider'
import { LIMITATION_SIDEBAR } from '@containers/mobile-studio-limitation-sidebar/index'

function withStudioLink (WrappedButton) {
  return class extends React.Component {
    render () {
      const { currentUser, isEdu, openMobileLimitationSidebar, mobileDevice } = this.props
      const buttonProps = pick(this.props, keys(BUTTON_PROP_TYPES))
      const extendedProps = {
        onClick: () => {
          if (mobileDevice) {
            openMobileLimitationSidebar(LIMITATION_SIDEBAR.NO_MOBILE_APP)
          } else {
            gotoStudio(currentUser, isEdu)
          }
        },
        [PFX_REQUIRE_SIGN_IN]: getStudioHref()
      }

      const props = {
        ...buttonProps,
        ...extendedProps
      }

      return <WrappedButton {...props} />
    }
  }
}

const mapDispatchToProps = {
  openMobileLimitationSidebar
}

const mapStateToProps = state => {
  return {
    mobileDevice: get(state, 'app.mobileDevice'),
    currentUser: get(state, 'backendData.currentUser')
  }
}

export const ButtonWithStudioLink = withStudioLink(Button)
export default createProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ButtonWithStudioLink, false)
)

ButtonWithStudioLink.propTypes = {
  ...BUTTON_PROP_TYPES,
  isEdu: PropTypes.bool,
  currentUser: PropTypes.object,
  mobileDevice: PropTypes.bool,
  openMobileLimitationSidebar: PropTypes.func
}

ButtonWithStudioLink.defaultProps = {
  ...BUTTON_DEFAULT_PROPS,
  currentUser: null,
  mobileDevice: false,
  openMobileLimitationSidebar: noop
}
