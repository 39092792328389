import React, { useCallback, useEffect } from 'react'
import usePaginate from '@root/hooks/usePaginate'
import { getUserGroups, denormalizeResponse } from '@common/http'
import { extractGroupsFromMemberships } from '@common/utils'
import CommunitySubPage from '../community-sub-page'
import CommunityGroupCard from '../community-group-card'

const CommunityUserGroupsSubPage = (props) => {
  const { guid, username } = props

  const fetcher = useCallback(async ({ page }) => {
    return getUserGroups(guid, { page, items: 20 })
  }, [guid])

  const { isLoading, data: memberships, fetchNextPage: fetchGroups } = usePaginate({ fetcher })
  const groups = extractGroupsFromMemberships(denormalizeResponse(memberships))

  // fetch groups on mount
  useEffect(fetchGroups, [])

  return (
    <CommunitySubPage
      title={`"${username}" groups`}
      fetchNextPage={fetchGroups}
      isLoading={isLoading}
      landscapeOnMobile
    >
      {groups.map(({ id, ...rest }) => <CommunityGroupCard key={id} group={{ ...rest }} isSubPage />)}
    </CommunitySubPage>
  )
}

export default CommunityUserGroupsSubPage
