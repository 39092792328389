import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Subscription from '@containers/subscriptions/components/subscription'
import CancelSubscription from '../../containers/subscriptions/components/cancel-subscription'

class CurrentPlan extends Component {
  static propTypes = {
    onSubscriptionReactivate: PropTypes.func,
    subscription: PropTypes.object,
    onUpgrade: PropTypes.func
  }

  state = {
    isCancelModalOpen: false
  }

  render() {
    const { onSubscriptionReactivate, subscription, onUpgrade } = this.props

    const getCtas = () => {
      if (subscription.isCancelledOrNonRenewing) {
        return [
          {
            text: 'Reactivate',
            onClick: () => onSubscriptionReactivate(subscription.id)
          }
        ]
      }

      if (!subscription.id) {
        return [
          {
            text: 'Upgrade',
            onClick: onUpgrade
          }
        ]
      }

      return [
        {
          type: 'outline',
          text: 'Cancel',
          onClick: () => this.setState({ isCancelModalOpen: true })
        },
        {
          isDisabled: subscription.canUpgradeAccount === false,
          text: 'Upgrade',
          onClick: onUpgrade
        }
      ]
    }

    return (
      <React.Fragment>
        <Subscription
          subscription={subscription}
          buttons={getCtas()}
        />
        {!!subscription.id && (
          <CancelSubscription
            subscription={subscription}
            isOpen={this.state.isCancelModalOpen}
            handleClose={() => this.setState({ isCancelModalOpen: false })}
          />
        )}
      </React.Fragment>
    )
  }
}

export default CurrentPlan
