import { actionTypes } from './types/backendData'

export const setCurrentUser = currentUser => dispatch => {
  dispatch({ type: actionTypes.SET_CURRENT_USER, payload: currentUser })
}

export const setAcl = acl => dispatch => {
  dispatch({ type: actionTypes.SET_ACL, payload: acl })
}

export const setReferralReward = amount => dispatch => {
  return dispatch({ type: actionTypes.SET_REFERRAL_REWARD, payload: amount })
}

export const setFeatureFlags = flags => dispatch => {
  return dispatch({ type: actionTypes.SET_FEATURE_FLAGS, payload: flags })
}

export const cancelSubscriptionRequested = payload => {
  return { type: actionTypes.CANCEL_SUBSCRIPTION, payload }
}

export const removeSubscriptionCancellationRequested = (subscriptionId) => {
  return { type: actionTypes.REMOVE_SUBSCRIPTION_CANCELLATION, payload: subscriptionId }
}

export const cancelReasonsRequested = (subscriptionType) => {
  return { type: actionTypes.CANCEL_REASONS, payload: subscriptionType }
}

export const setAccountData = account => dispatch => {
  return dispatch({ type: actionTypes.SET_ACCOUNT_DATA, payload: account })
}

export const getPieces = () => {
  return { type: actionTypes.GET_PIECES }
}

export const getPiecesSuccess = (pieces) => {
  return { type: actionTypes.GET_PIECES_SUCCESS, payload: {pieces} }
}

export const getPiecesFail = (error) => {
  return { type: actionTypes.GET_PIECES_SUCCESS, payload: {error} }
}

export const setModuleControllerAction = (module, controller, action) => {
  return {
    type: actionTypes.SET_MODULE_CONTROLLER_ACTION,
    payload: { module, controller, action }
  }
}

export const setAvatarUrl = (avatarUrl) => {
  return {
    type: actionTypes.SET_AVATAR_URL,
    payload: { avatarUrl }
  }
}

export const setNotificationCount = (notificationCount) => {
  return {
    type: actionTypes.SET_NOTIFICATION_COUNT,
    payload: { notificationCount }
  }
}
