import { Fragment, useCallback, useEffect, useMemo } from 'react'
import connect from 'react-redux/es/connect/connect'
import createProvider from '@store/provider'
import usePaginate from '@hooks/usePaginate'
import { LINKS, COMMUNITY_CAROUSEL_ITEMS } from '@common/consts'
import { extractGroupsFromMemberships } from '@common/utils'
import { denormalizeResponse, getRecentGroups, getTrendingGroups, getUserGroups, getFeaturedGroups } from '@common/http'
import Spinner from '@components/spinner'
import CreateGroup from '@containers/create-group'
import { noop } from 'lodash'
import CommunityTitle from '../community-title'
import CommunitySubPage from '../community-sub-page'
import CommunityGroupsSection from '../community-groups-section'
import CommunityGroupCard from '../community-group-card'
import * as styles from './CommunityGroups.module.scss'

export function CommunityGroups (props) {
  let { isGroupsHomePage, isRecentGroupsPage, isTrendingGroupsPage, isMyGroupsPage, isFeaturedGroupsPage, title, currentUser } = props

  const fetchUserGroups = useCallback(async ({ page }) => {
    return getUserGroups(currentUser.guid, { items: isGroupsHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20, page })
  }, [currentUser, isGroupsHomePage])
  let { isLoading: isLoadingUserGroups, data: memberships, fetchNextPage: fetchMyGroupsPage, nextPage: userGroupsNextPage } = usePaginate({ fetcher: fetchUserGroups })
  const myGroups = useMemo(() => {
    const denormalizedMemberships = denormalizeResponse(memberships)
    return extractGroupsFromMemberships(denormalizedMemberships)
  }, [memberships])
  useEffect(() => {
    if (currentUser) fetchMyGroupsPage()
  }, [currentUser])

  const fetchRecentGroups = useCallback(async ({ page }) => {
    return getRecentGroups({ page, items: isGroupsHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isGroupsHomePage])
  const { data: recentGroups, isLoading: isLoadingRecentGroups, fetchNextPage: fetchRecentGroupsPage, nextPage: recentGroupsNextPage } = usePaginate({ fetcher: fetchRecentGroups })
  useEffect(() => {
    if (isRecentGroupsPage || isGroupsHomePage) fetchRecentGroupsPage()
  }, [isGroupsHomePage, isRecentGroupsPage])

  const fetchFeaturedGroups = useCallback(async ({ page }) => {
    return getFeaturedGroups({ page, items: isGroupsHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isGroupsHomePage])
  const { data: featuredGroups, isLoading: isLoadingFeaturedGroups, fetchNextPage: fetchFeaturedGroupsPage, nextPage: featuredGroupsNextPage } = usePaginate({ fetcher: fetchFeaturedGroups })
  useEffect(() => {
    if (isFeaturedGroupsPage || isGroupsHomePage) fetchFeaturedGroupsPage()
  }, [isGroupsHomePage, isFeaturedGroupsPage])

  const fetchTrendingGroups = useCallback(async ({ page }) => {
    return getTrendingGroups({ page, items: isGroupsHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20 })
  }, [isGroupsHomePage])
  const { data: trendingGroups, isLoading: isLoadingTrendingGroups, fetchNextPage: fetchTrendingGroupsPage, nextPage: trendingGroupsNextPage } = usePaginate({ fetcher: fetchTrendingGroups })
  useEffect(() => {
    if (isTrendingGroupsPage || isGroupsHomePage) fetchTrendingGroupsPage()
  }, [isGroupsHomePage, isTrendingGroupsPage])

  let groups = {}
  let isLoadingSubPage = false
  let fetchNextSubPage = noop
  if (isFeaturedGroupsPage) {
    groups = denormalizeResponse(featuredGroups)
    isLoadingSubPage = isLoadingFeaturedGroups
    fetchNextSubPage = fetchFeaturedGroupsPage
  }
  if (isMyGroupsPage) {
    groups = myGroups
    isLoadingSubPage = isLoadingUserGroups
    fetchNextSubPage = fetchMyGroupsPage
  }
  if (isRecentGroupsPage) {
    groups = denormalizeResponse(recentGroups)
    isLoadingSubPage = isLoadingRecentGroups
    fetchNextSubPage = fetchRecentGroupsPage
  }
  if (isTrendingGroupsPage) {
    groups = denormalizeResponse(trendingGroups)
    isLoadingSubPage = isLoadingTrendingGroups
    fetchNextSubPage = fetchTrendingGroupsPage
  }

  const renderCreateGroup = (showOn) => {
    if (!currentUser) return null

    const platformClass = `createGroupBtn${showOn}`
    return (
      <div className={`${styles.createGroupBtn} ${styles[platformClass]}`}><CreateGroup type='newGroupGradient' /></div>
    )
  }

  const homePageSections = [
    currentUser &&
    {
      label: 'My groups',
      showMoreLink: userGroupsNextPage ? LINKS.MY_GROUPS : null,
      groups: myGroups,
      subHeader: isLoadingUserGroups ? <Spinner /> : renderCreateGroup('Desktop'),
      denormalize: false
    },
    {
      label: 'Featured groups',
      showMoreLink: featuredGroupsNextPage ? LINKS.FEATURED_GROUPS : null,
      groups: featuredGroups,
      subHeader: isLoadingFeaturedGroups && <Spinner />
    },
    {
      label: 'Trending groups',
      showMoreLink: trendingGroupsNextPage ? LINKS.TRENDING_GROUPS : null,
      groups: trendingGroups,
      subHeader: isLoadingTrendingGroups && <Spinner />
    },
    {
      label: 'New groups',
      showMoreLink: recentGroupsNextPage ? LINKS.RECENT_GROUPS : null,
      groups: recentGroups,
      subHeader: isLoadingRecentGroups && <Spinner />
    }
  ].filter(Boolean)

  return (
    <div>
      {isGroupsHomePage ? (
        <Fragment>
          <CommunityTitle className={styles.title} title='Groups' />
          {renderCreateGroup('Mobile')}
          {homePageSections.map((section, i) =>
            <CommunityGroupsSection key={section.label} className={`${styles.section} ${i === 0 && styles.sectionFirst}`} groups={section.groups} label={section.label} showMoreLink={section.showMoreLink} subHeader={section.subHeader} denormalize={section.denormalize} />
          )}
        </Fragment>
      ) : (
        <CommunitySubPage title={title} isLoading={isLoadingSubPage} fetchNextPage={fetchNextSubPage}>
          {groups.map((group) => <CommunityGroupCard key={group.id} group={group} isSubPage />)}
        </CommunitySubPage>
      )}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.backendData.currentUser
  }
}

export default createProvider(connect(mapStateToProps, null)(CommunityGroups))
