import { ACCOUNT_TYPES } from '@common/consts'

const makeFeature = (safeHtml, enable) => {
  return { safeHtml, enable }
}

const FEATURE_PROJECTS = (enabled, limit) => {
  if (limit) {
    return makeFeature(`${limit} projects`, enabled)
  } else {
    return makeFeature('Unlimited projects', enabled)
  }
}

const FEATURE_PROJECTS_LEGACY = (enabled, limit) => {
  if (limit) {
    return makeFeature(`${limit} solo projects`, enabled)
  } else {
    return makeFeature('Unlimited solo projects', enabled)
  }
}

const FEATURE_LOOPS = (enabled, loops = 700) => {
  return makeFeature(`More than ${loops} loops and samples`, true)
}

const FEATURE_COLLAB = (limit) => {
  return makeFeature(`${limit} collab live project${limit === 1 ? '' : 's'} <span class="pfx-beta-label">BETA</span>`, true)
}

const FEATURE_AUDIO_QUALITY = (enabled, isFree) => {
  if (isFree) {
    return makeFeature('Export audio MP3 format', enabled)
  } else {
    return makeFeature('Export audio WAV format', enabled)
  }
}

const FEATURE_GENNIE= (enabled, limit) => {
  return makeFeature(`${limit} AI credits/month`, enabled)
}

const FEATURE_INSTRUMENTS = (enabled) => {
  return makeFeature('Virtual instruments', enabled)
}

const FEATURE_LIVE_AUDIO = (enabled) => {
  return makeFeature('Record live audio', enabled)
}

const FEATURE_IMPORT = (enabled, size = '') => {
  return makeFeature(`${size} storage`, enabled)
}

const FEATURE_IMPORT_LEGACY = (enabled, size = '') => {
  return makeFeature(`Import audio ${size}`, enabled)
}

const FEATURE_STORAGE = (enabled, storage) => {
  if (storage) {
    return makeFeature(`${storage} audio`, enabled)
  } else {
    return makeFeature('Disc storage', enabled)
  }
}

const FEATURE_MIDI_CONTROLLER = (enabled) => {
  return makeFeature('External MIDI controller', enabled)
}

const FEATURE_SOUNDSETS = (enabled, soundsets) => {
  if (soundsets) {
    return makeFeature(`${soundsets} included soundsets`, enabled)
  } else {
    return makeFeature('Included soundsets', enabled)
  }
}

const FEATURE_PARAMETRIC_EQ = (enabled) => {
  return makeFeature('<div><strong>Parametric</strong> EQ</div>', enabled)
}

const FEATURE_AUTOMATION = (enabled) => {
  return makeFeature('Automation', enabled)
}

const FEATURE_EXPORT_PROJECT = (enabled) => {
  return makeFeature('Export projects', enabled)
}

const FEATURE_EMBED_STUDIO = (enabled) => {
  return makeFeature('Embed the studio to any website', enabled)
}

export const YEARLY_ID = 2
export const MONTHLY_ID = 1
export const defaultCurrency = 'USD'

export const baseConfig = {
  FREE: {
    name: 'Free',
    type: ACCOUNT_TYPES.FREE,
    features: [],
    highlight: false
  },
  FREE_LEGACY: {
    name: 'Free',
    type: ACCOUNT_TYPES.FREE,
    features: [
      FEATURE_PROJECTS_LEGACY(true, 10),
      FEATURE_COLLAB(1),
      FEATURE_LOOPS(true),
      FEATURE_INSTRUMENTS(true),
      FEATURE_AUDIO_QUALITY(true, true),
      FEATURE_LIVE_AUDIO(false),
      FEATURE_IMPORT_LEGACY(false),
      FEATURE_AUTOMATION(false),
      FEATURE_EXPORT_PROJECT(false),
      FEATURE_MIDI_CONTROLLER(false),
      FEATURE_SOUNDSETS(false),
      FEATURE_PARAMETRIC_EQ(false),
    ],
    highlight: false
  },
  INTRO: {
    name: 'Intro',
    type: ACCOUNT_TYPES.INTRO,
    features: [
      FEATURE_PROJECTS_LEGACY(true),
      FEATURE_COLLAB(3),
      FEATURE_LOOPS(true),
      FEATURE_INSTRUMENTS(true),
      FEATURE_AUDIO_QUALITY(true),
      FEATURE_LIVE_AUDIO(true),
      FEATURE_IMPORT_LEGACY(true, '(100MB)'),
      FEATURE_AUTOMATION(true),
      FEATURE_EXPORT_PROJECT(true),
      FEATURE_MIDI_CONTROLLER(true),
      FEATURE_SOUNDSETS(false),
      FEATURE_PARAMETRIC_EQ(false),
    ],
    highlight: false
  },
  PREMIUM: {
    name: 'Premium',
    type: ACCOUNT_TYPES.PREMIUM,
    features: [
      FEATURE_PROJECTS_LEGACY(true),
      FEATURE_COLLAB(10),
      FEATURE_LOOPS(true, 12000),
      FEATURE_INSTRUMENTS(true),
      FEATURE_AUDIO_QUALITY(true),
      FEATURE_LIVE_AUDIO(true),
      FEATURE_IMPORT_LEGACY(true, '5GB'),
      FEATURE_AUTOMATION(true),
      FEATURE_EXPORT_PROJECT(true),
      FEATURE_MIDI_CONTROLLER(true),
      FEATURE_SOUNDSETS(true, 20),
      FEATURE_PARAMETRIC_EQ(true),
    ],
    highlight: true
  },
  STARTER: {
    name: 'Starter',
    type: ACCOUNT_TYPES.STARTER,
    features: [
      FEATURE_PROJECTS(true),
      FEATURE_LOOPS(true, '12,000'),
      FEATURE_AUDIO_QUALITY(true, true),
      FEATURE_GENNIE(true, 25),
      FEATURE_IMPORT(true, '10GB'),
    ],
    highlight: false
  },
  CREATOR: {
    name: 'Creator',
    type: ACCOUNT_TYPES.CREATOR,
    features: [
      FEATURE_PROJECTS(true),
      FEATURE_LOOPS(true, '15,000'),
      FEATURE_AUDIO_QUALITY(true, false),
      FEATURE_GENNIE(true, 100),
      FEATURE_IMPORT(true, '100GB'),
    ],
    highlight: true
  },
  PRO: {
    name: 'Pro',
    type: ACCOUNT_TYPES.PRO,
    features: [
      FEATURE_PROJECTS(true),
      FEATURE_LOOPS(true, '25,000'),
      FEATURE_AUDIO_QUALITY(true, false),
      FEATURE_GENNIE(true, 500),
      FEATURE_IMPORT(true, '1TB'),
    ],
    highlight: false
  },
  EUROPA: {
    name: 'Europa',
    type: ACCOUNT_TYPES.EUROPA,
    highlight: false
  }
}
