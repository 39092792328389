import React from 'react'
import './style.scss'
import {
  Bem,
} from '../../common/utils'
import { ENDPOINTS } from '../../common/consts'
import {connect} from "react-redux";
import {If, Then, Else} from 'react-if'
import createProvider from "../../store/provider";
import { MessageHandler } from "../../common/messageHandler";
import { resendEmailConfirmation } from '@api/user'
import Linkton, { COLOR, TYPE } from "@components/linkton";

const cn = new Bem({
  name: 'verify-email-view',
  prefix: 'pfx-'
})

class VerifyEmailView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      emailSent: false
    }
  }

  async resendEmail() {
    if (this.emailConfirmationInFlight) return

    const { emailSent } = this.state

    if (emailSent || this.emailConfirmationInFlight) {
      return
    }

    try {
      this.emailConfirmationInFlight = true

      await resendEmailConfirmation()

      this.setState({ emailSent: true })
    } finally {
      this.emailConfirmationInFlight = false
    }
  }

  render () {
    const { emailSent } = this.state

    return (
      <div className={cn(undefined, { popup: MessageHandler.isInStudioLoginFlowPopup() })}>
        <div className={cn('container', ['black', 'wide'], { space: true })}>
          <div className={cn('header')}>
            <h4 className={cn('header-text', 'left-align')}>An email has been sent to you!</h4>
          </div>
          <div className={cn('description')}>
            <p>Please click the verify email link in the email to activate your account. The link in the email will expire in 24 hours.</p>
            <div className={cn('text-separator', ['small'])}/>
            <If condition={emailSent}>
              <Then>
                <p>The email has been sent. Don't forget to check your spam folder.</p>
              </Then>
              <Else>
                <p><a onClick={() => this.resendEmail()} className={cn('link')}>Click here</a> if you haven't received an email</p>
              </Else>
            </If>
            <div className={cn('text-separator', ['small'])}/>
            <div>
              <Linkton
                className={cn("org-action-button")}
                tag="anchor"
                type={TYPE.OUTLINE}
                padding="large"
                color={COLOR.WHITE}
                hover="none"
                href={ENDPOINTS.LOGOUT}>
                Log out
              </Linkton>
            </div>
            <div className={cn('text-separator', ['small'])}/>
            <p>Soundation is an online music studio where you can make music and collaborate without boundaries.</p>
          </div>
        </div>
      </div>
    )
  }
}

VerifyEmailView.propTypes = {
}

VerifyEmailView.defaultProps = {}

const mapDispatchToProps = {
}

export default createProvider(connect(null, mapDispatchToProps)(VerifyEmailView))
