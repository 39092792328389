'use client'
import React from "react";
import { Bem } from "@common/utils";
import PropTypes from "prop-types";
import "./style.scss";
import { connect } from "react-redux";
import { AspectRatio } from "../../common/imageDimensions";
import createProvider from "@store/provider";

const cn = new Bem({
  prefix: "pfx-",
  name: "lazy-image",
});

class LazyImage extends React.Component {
  state = {
    isVisible: false,
    isLoaded: false,
    maxWidth: undefined,
  };

  static propTypes = {
    aspectRatio: PropTypes.instanceOf(AspectRatio),
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        media: PropTypes.string,
        srcset: PropTypes.string,
      })
    ),
    placeholder: PropTypes.string,
    className: PropTypes.string,
    cover: PropTypes.bool,
    imgClassName: PropTypes.string,
    autoMaxSize: PropTypes.bool,
  };

  static defaultProps = {
    cover: true,
  };

  render() {
    const { isVisible, isLoaded, maxWidth } = this.state;
    const {
      aspectRatio,
      className,
      placeholder,
      src,
      sources,
      windowScrollTop,
      windowHeight,
      dispatch,
      imgClassName,
      cover,
      fallbackSrc,
      size,
      autoMaxSize,
      ...passThrough
    } = this.props;

    const ar = aspectRatio?.toCssVariables();
    return (
      <picture
        ref={(ref) => (this.ref = ref)}
        className={cn(null, { "aspect-ratio": !!aspectRatio }, className)}
        style={{
          "--max-width": maxWidth ? `${maxWidth}px` : undefined,
          ...ar,
        }}
      >
        {placeholder && (
          <div
            className={cn("low-quality", {
              hidden: isVisible && isLoaded,
              cover,
            })}
            style={{ backgroundImage: `url(${placeholder})` }}
          />
        )}
        {sources?.map(({ media, srcset, type }) => (
          <source
            key={`${srcset}_${media}`}
            type={type}
            media={media}
            srcSet={srcset}
          />
        ))}
        <img
          ref={(ref) => (this.imgRef = ref)}
          className={cn(
            "high-quality",
            { visible: isVisible && isLoaded, cover },
            imgClassName
          )}
          src={isVisible && src ? src : null}
          {...passThrough}
        />
      </picture>
    );
  }

  componentDidMount() {
    const { autoMaxSize } = this.props;
    if (this.imgRef)
      this.imgRef.onload = () => {
        const newState = {
          isLoaded: true,
          maxWidth: autoMaxSize ? this.imgRef.naturalWidth : undefined,
        };

        this.setState(newState);
      };

    if (typeof window.IntersectionObserver !== "undefined") {
      const observer = new window.IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          this.setState({ isVisible: true });
          observer.unobserve(this.ref);
        }
      });
      observer.observe(this.ref);
    } else if (this.doesElementIntersect()) {
      this.setState({ isVisible: true });
    }
  }

  doesElementIntersect() {
    const { windowHeight } = this.props;
    if (!this.ref) return false;
    const boundingBox = this.ref.getBoundingClientRect();

    return (
      boundingBox.top + boundingBox.height > 0 && boundingBox.top < windowHeight
    );
  }

  componentDidUpdate() {
    const { isVisible } = this.state;

    if (
      !isVisible &&
      typeof window.IntersectionObserver === "undefined" &&
      this.doesElementIntersect()
    ) {
      this.setState({ isVisible: true });
    }
  }
}

const mapStateToProps = (state) => {
  return {
    windowScrollTop: state.app.windowScrollTop,
    windowHeight: state.app.windowHeight,
  };
};

export default createProvider(connect(mapStateToProps)(LazyImage));
