import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import { capitalizeFirstLetter, canUpgrade, getEmptyPrice } from '@components/price-plans/utils'
import RadioButtonGroup from '@components/radio-button/index'
import * as React from 'react'
import { If } from 'react-if'
import { PERIOD } from '../services/consts.jsx'
import { noop } from 'lodash'
import { PLANS } from '../../auth-gateway-view/index'
import './period-chooser.scss'

const cn = new Bem({
  name: 'checkout-view',
  prefix: 'pfx-'
})

class PeriodChooser extends React.Component {
  canUpgradeToPeriod (period) {
    const { currentUser } = this.props
    return canUpgrade(currentUser, this.props.priceObj[period + 'ly'].available)
  }

  isPeriodDisabled (period, plan) {
    const { available } = this.props.priceObj[period + 'ly']
    return (period === PERIOD.MONTH && plan === PLANS.INTRO) || !available
  }

  shouldDisableButton (period, plan) {
    return this.isPeriodDisabled(period, plan) || !this.canUpgradeToPeriod(period)
  }

  renderButton (period) {
    const { plan, onPeriodChange, renderPrice, priceObj } = this.props
    const isBtnDisabled = this.shouldDisableButton(period, plan)
    const isPeriodDisabled = this.isPeriodDisabled(period, plan)

    let currentPrice
    let bottomPrice
    let disableText = null

    if (period === PERIOD.MONTH) {
      currentPrice = isPeriodDisabled ? getEmptyPrice(priceObj.monthly.currency) : priceObj.monthly
      bottomPrice = priceObj.monthlyToYearly
    } else {
      currentPrice = isPeriodDisabled ? getEmptyPrice(priceObj.monthly.currency) : priceObj.discountedYearlyToMonthly
      bottomPrice = priceObj.yearly
    }

    if (this.isPeriodDisabled(period, plan)) {
      disableText = `${capitalizeFirstLetter(period)}ly billing is unavailable for this plan`
    } else if (!this.canUpgradeToPeriod(period)) {
      disableText = 'You can only upgrade to yearly subscription'
    }

    return (
      <div className={cn('radio-button')} disabled={isBtnDisabled} value={!isBtnDisabled && period} onChange={onPeriodChange}>
        <div className={cn('radio-button-price')}>
          {renderPrice(currentPrice, 'month')}
          <If condition={period === PERIOD.MONTH}>
            <div className={cn('radio-text-container')}>
              <If condition={!!disableText}>
                <div>{disableText}</div>
              </If>
              <If condition={!isBtnDisabled}>
                <div>
                  <div className={cn('radio-text')}>Billed monthly</div>
                  <div className={cn('radio-text')}>up to {bottomPrice.totalDisplayPrice} a year</div>
                </div>
              </If>

            </div>
          </If>
          <If condition={period === PERIOD.YEAR}>
            <div className={cn('radio-text-container')}>
              <If condition={!!disableText}>
                <div>{disableText}</div>
              </If>
              <If condition={!isBtnDisabled}>
                <div>
                  <div className={cn('radio-text')}>Billed annually as</div>
                  <div className={cn('radio-text')}>one payment of {bottomPrice.totalDisplayPrice}</div>
                </div>
              </If>
            </div>
          </If>
        </div>
      </div>
    )
  }

  render () {
    const { activePeriod, onPlanChange } = this.props
    return (
      <RadioButtonGroup
        value={activePeriod}
        className={cn('radio-buttons')}
        onChange={(val) => onPlanChange(val)}>
        { this.renderButton(PERIOD.YEAR) }
        { this.renderButton(PERIOD.MONTH) }
      </RadioButtonGroup>
    )
  }
}

PeriodChooser.propTypes = {
  onPlanChange: PropTypes.func,
  activePeriod: PropTypes.string,
  priceObj: PropTypes.object,
  plan: PropTypes.string,
  currentUser: PropTypes.object,
  currentPaymentPeriod: PropTypes.string,
  renderPrice: PropTypes.func
}
PeriodChooser.defaultProps = {
  onPlanChange: noop,
  renderPrice: noop
}

export default PeriodChooser
