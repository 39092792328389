import React from 'react'
import * as styles from './Section.module.scss'

const Section = ({ children, style }) => {
  return (
    <div className={styles.container} style={style}>
      <div className={styles.innerContainer}>
        {children}
      </div>
    </div>
  )
}

export default Section
