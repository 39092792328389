import React from 'react'
import './group-updates.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '@common/utils'
import { map } from 'lodash'
import Button from "@components/button/index";
import FormElement from "@components/form-element/index";
import Form from "@components/form/index";
import {JUST_REQUIRED} from "@common/validator-models";
import TextArea from "../../../components/text-area/text-area";
import TextFieldSimple from "../../../components/text-field/text-field-simple";
import GroupUpdate from './group-update.jsx'

const cn = new Bem({
  name: 'group-updates',
  prefix: 'pfx-'
})

const groupUpdateFormScheme = {
  content: {
    validation: JUST_REQUIRED
  },
  title: {
    validation: JUST_REQUIRED
  }
}

class GroupUpdates extends React.Component {

  state = {}

  async onFormSubmit(form) {
    if (form.isValid) {
      this.setState({isLoading: true})
      await this.props.onPost(form.values)
      this.setState({isLoading: false})
    }
  }

  handleDeleteUpdate(updateId) {
    const {onDelete} = this.props;
    if(confirm("Are you sure you want to delete this update?")) {
      onDelete(updateId)
    }
  }

  handleNotifyUpdate(updateId) {
    const {onNotify} = this.props;
    if(confirm("Are you sure you email all user within that group?")) {
      onNotify(updateId)
    }
  }

  render () {
    const {
      canPost,
      groupUpdatesList,
      className
    } = this.props

    return (
      <div className={cn(null, null, className)}>
        <h3 className={cn('header', null, 'pfx--white')}>
          Latest updates
        </h3>
        {canPost && <div className={cn('form-container')}>
          <Form
            scheme={groupUpdateFormScheme}
            onSubmit={(form) => this.onFormSubmit(form)}
            className={cn('form')}>
            <FormElement field='title' className={cn('form-element')}>
              <TextFieldSimple
                className={cn('title-input')}
                placeholder='Title' >
              </TextFieldSimple>
            </FormElement>
            <FormElement field='content' className={cn('form-element')}>
              <TextArea
                className={cn('textarea')}
                placeholder='Update content'>
              </TextArea>
            </FormElement>
            <FormElement submit className={cn('form-element', 'submit')}>
              <Button
                type='outline'
                isLoading={this.state.isLoading}>Post update</Button>
            </FormElement>
          </Form>
        </div>}
        <div className={cn('list')}>
          {map(groupUpdatesList, ({createdAt, content, title, id, notified}, idx) => (
            <GroupUpdate
              createdAt={createdAt}
              content={content}
              title={title}
              id={id}
              canPost={canPost}
              notified={notified}
              onDelete={(updateId) => this.handleDeleteUpdate(updateId)}
              onNotifyUpdate={(updateId) => this.handleNotifyUpdate(updateId)}
              key={idx} />
          ))}
        </div>

      </div>
    )
  }
}

GroupUpdates.propTypes = {
  canPost: PropTypes.bool,
  onPost: PropTypes.func,
  totalGroupUpdatesCount: PropTypes.number,
  groupUpdatesList: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    username: PropTypes.string,
    avatarUrl: PropTypes.string,
    profileLink: PropTypes.string
  })),
  onDelete: PropTypes.func,
  onNotify: PropTypes.func,
}

GroupUpdates.defaultProps = {
  canPost: true,
  onPost: () => Promise.resolve(),
  onDelete: () => Promise.resolve(),
  onNotify: () => Promise.resolve(),
  groupUpdatesList: []
}

export default GroupUpdates
