import { Component } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import LazyImage from '../lazy-image'
import { CarouselResponsive } from '@components/carousel/carousel'
import renderTopControls from '../carousel/components/top-controls.jsx'
import {AspectRatio} from "../../common/imageDimensions";

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-testimonials'
})


const images = {
  sloc: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/test_sloc.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/test_sloc.png',
    alt: 'SLOCM3Z Profile Picture',
    srcSet: 'https://cdn.soundation.com/rtc_landing/test_sloc.png 1x, https://cdn.soundation.com/rtc_landing/test_sloc-x2.png 2x',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/test_sloc.png'))
  },
  graw: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/test_graw.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/test_graw.png',
    alt: 'GRAWL!X Profile Picture',
    srcSet: 'https://cdn.soundation.com/rtc_landing/test_graw.png 1x, https://cdn.soundation.com/rtc_landing/test_graw-x2.png 2x',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/test_graw.png'))
  },
  retta: {
    placeholder: require('lqip-loader?emitFile=false!@images/landing/test_retta.png').preSrc,
    src: 'https://cdn.soundation.com/rtc_landing/test_retta.png',
    alt: 'Retta Don Profile Picture',
    srcSet: 'https://cdn.soundation.com/rtc_landing/test_retta.png 1x, https://cdn.soundation.com/rtc_landing/test_retta-x2.png 2x',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/test_retta.png'))
  }
}

const testimonials = [
  {
    username: 'SLOCM3Z',
    plays: 74016,
    genres: ['Dubstep', 'Chillout', 'Indie'],
    quotation: 'The studio’s simplicity helped get me into audio production. It put mine and many others\' music careers out on a great start and I can\'t thank Soundation enough for that.',
    image: images.sloc
  },
  {
    username: 'GRAWL!X',
    plays: 87810,
    genres: ['Downtempo', 'Soul', 'Synthwave'],
    quotation: 'Soundation is without question the best online DAW on the internet. There\'s no other place like this. I’ve met lifelong friends here and here\'s hoping that many of you will as well!',
    image: images.graw
  },
  {
    username: 'Retta Don',
    plays: 19071,
    genres: ['Hiphop', 'Electronica', 'Trap'],
    quotation: 'As a beginner in music production, I was able to start making music right away with Soundation. It’s easy to use and the huge Premium sound library really opens up my creativity.',
    image: images.retta
  },
]

export default class LandingTestimonials extends Component {

  renderPlaysNumber(value) {
    return (value >= 1000 ? this.renderPlaysNumber(Math.floor(value / 1000)) + ',' : '') +
      (value % 1000).toString(10).padStart(value >= 1000 ? 3 : 0, '0')
  }

  renderTestimonial(user) {
    return (
      <div key={user.username} {...bem('user')}>
        <LazyImage {...user.image} />
        <div {...bem('user-details')}>
          <h3 {...bem('username')}>{ user.username }</h3>
          <div {...bem('plays')}>{ this.renderPlaysNumber(user.plays) } plays</div>
          <div {...bem('genres')}>
            {
              user.genres.map((genre) => (
                <span key={genre} {...bem('genre')}>{ genre }</span>
              ))
            }
          </div>
        </div>
        <div {...bem('quotation')}>{ user.quotation }</div>
      </div>
    )
  }

  renderTopControls = (options) => {
    return <div {...bem('carousel-controls')}>{ renderTopControls({...options, iconSize: 16, indicateActive: true}) }</div>
  }

  render () {
    return (
      <div {...bem()}>
        <CarouselResponsive
          renderProgressBar={null}
          theme={'light'}
          marginByScreenSize={[150, 120, 100, 20, 20]}
          viewsByScreenSize={[3, 3, 3, 3, 1]}
          renderTopControls={this.renderTopControls}
          infinite={false}
          {...bem('carousel')}
        >
          { testimonials.map(user => this.renderTestimonial(user))}
        </CarouselResponsive>
      </div>
    )
  }
}
