import React from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Bem } from "@common/utils";
import { BlockStylesContext } from "./BlockStyles.context";

import "./BlockStyles.style.scss";

const cn = new Bem({
  name: "block-styles",
  prefix: "pfx-",
});

const defaultTheme = {
  bg: "transparent",
};

const BlockStyles = ({
  isFirst = false,
  children,
  className,
  overflow,
  spacing = true,
  width = "normal",
  theme = {},
}) => {
  const modifiers = {
    first: isFirst,
    [`no-spacing`]: !spacing,
    [`width-${width}`]: true,
  };

  if (overflow) {
    modifiers[`overflow--${overflow}`] = true;
  }
  return (
    <BlockStylesContext.Provider
      value={{ isFirst, width, overflow, theme, spacing }}
    >
      <div
        className={cn(undefined, modifiers, className)}
        style={{
          "--bg-color": get(theme, "background", defaultTheme.bg),
          "--bg-fade": get(theme, "backgroundFadeTo", undefined),
        }}
      >
        <div className={cn("wrapper", className)}>{children}</div>
      </div>
    </BlockStylesContext.Provider>
  );
};

BlockStyles.propTypes = {
  isFirst: PropTypes.bool,
  theme: PropTypes.shape({
    background: PropTypes.string,
    backgroundFadeTo: PropTypes.string,
  }),
  width: PropTypes.oneOf(["narrow", "normal", "wide"]),
  overflow: PropTypes.oneOf(["right"]),
  backgroundFadeTo: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  spacing: PropTypes.bool,
}

export { BlockStyles };
