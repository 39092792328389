import React, { useEffect, useState } from 'react'
import ModalOrBottomSheet from '@components/modal-or-bottom-sheet'
import Linkton from '@components/linkton'
import Icon from '@components/icon'
import TextArea from '@components/text-area/text-area'
import { sendUserMessage } from '@common/http'
import * as styles from './CommunityMessageUser.module.scss'

const CommunityMessageUser = (props) => {
  const { isOpen, userId, userName, onClose } = props

  const [message, setMessage] = useState('')
  const [wasSentOk, setWasSentOk] = useState(false)

  // reset on open/close
  useEffect(() => {
    setMessage('')
  }, [isOpen])

  // reverse the effect of setWasSentOk after 2 seconds
  useEffect(() => {
    if (wasSentOk) {
      setTimeout(() => {
        onClose()
        setWasSentOk(false)
      }, 2000)
    }
  }, [wasSentOk, onClose])

  const handleSendMessage = (e) => {
    e.preventDefault()

    sendUserMessage(userId, message)
      .then(() => setWasSentOk(true))
      .catch(() => window.location.reload())

    return false
  }

  return (
    <ModalOrBottomSheet open={isOpen} closeShareModal={onClose}>
      {userId ? (
        <div className={styles.container}>
          {wasSentOk ? (
            <div className={styles.success}>
              <div>Message sent to {userName}!</div>
              <div className={styles.successIcon}><Icon icon='check' size={32} /></div>
            </div>
          ) : (
            <form onSubmit={handleSendMessage} data-turbolinks={false}>
              <div className={styles.header}>
                <div>Message {userName}</div>
                <Icon icon='new-close' onClick={onClose} size={16} />
              </div>

              <label className={styles.label}>
                MESSAGE
                <TextArea className={styles.textarea} maxLength='2000' onChange={setMessage} fontSize='small' isTransparent={false} value={message} placeholder='Enter your message...' />
              </label>

              <div className={styles.btnContainer}>
                <Linkton className={styles.btn} icon='arrow-forward' iconPlacement='left' isSubmit>Send</Linkton>
              </div>
            </form>
          )}
        </div>
      ) : null}
    </ModalOrBottomSheet>
  )
}

export default CommunityMessageUser
