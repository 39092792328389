import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { LINKS } from '@common/consts'
import parse, { domToReact } from 'html-react-parser'
import DottedMenu from '@components/dotted-menu'
import Icon from '@components/icon'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import InviteMembers from '@containers/invite-members'
import Linkton from '@components/linkton'
import CommunityAvatars from '../community-avatars'
import CommunityAddTrack from '../community-add-track'
import * as styles from './CommunityGroupHero.module.scss'

const CommunityGroupHero = (props) => {
  const { group, coverUrl, members, membershipCount, isGroupAdmin, isGroupMember, isGroupFollower, canAddTracks, canJoin, canFollow, followable, joinable, currentUser } = props
  const itemsContainerRef = useRef(null)
  const imageRef = useRef(null)
  const remainingRef = useRef(null)

  const [showWholeDescription, setShowWholeDescription] = useState(false)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const groupPath = `/group/${group.groupLink}`
  const groupIdPath = `/group/${group.id}`

  const [remainingHeight, setRemainingHeight] = useState(0)

  const showMore = () => setShowWholeDescription((cur) => !cur)

  const description = useMemo(() => {
    if (!group.description) return null

    const renderChild = (domNode) => {
      if (domNode.children.length === 1 && domNode.children[0].type === 'text') {
        return domNode.children[0].data
      }

      return domToReact(domNode.children, options)
    }

    const options = {
      replace(domNode) {
        if (domNode.type === 'text') return <span>{domNode.data}</span>

        if (domNode.type === 'tag') {
          if (domNode.name === 'p') {
            return <p>{renderChild(domNode)}</p>
          }
          if (domNode.name === 'a') {
            return <a {...domNode.attribs}>{renderChild(domNode)}</a>
          }
          if (domNode.name === 'strong' || domNode.name === 'b') {
            return <strong>{renderChild(domNode)}</strong>
          }
          if (domNode.name === 'h1') {
            return <h1>{renderChild(domNode)}</h1>
          }
          if (domNode.name === 'h2') {
            return <h2>{renderChild(domNode)}</h2>
          }
          if (domNode.name === 'h3') {
            return <h3>{renderChild(domNode)}</h3>
          }
          if (domNode.name === 'h4') {
            return <h4>{renderChild(domNode)}</h4>
          }
          if (domNode.name === 'span') {
            return <span>{renderChild(domNode)}</span>
          }
        }

        return <div>{renderChild(domNode)}</div>
      }
    }

    return (
      parse(group.description, options)
    )
  }, [group.description])
  const renderImage = useCallback(() => <img ref={imageRef} className={styles.image} src={coverUrl} alt={group.name} />, [coverUrl, group.name])

  const hasMoreActions = !!isGroupAdmin || (!isGroupAdmin && !!isGroupMember) || (!isGroupAdmin && !!canFollow && isGroupFollower)

  const renderDropdown = useCallback((desktop) => {
    return hasMoreActions && (
      <DropdownOrBottomSheet
        dropdownClassName={styles.dropdown}
        dropdownProps={{ align: 'end' }}
        isOpen={isContextMenuOpen}
        toggler={desktop ? <DottedMenu /> : <Linkton className={styles.button} type='outline' icon='dots-menu' iconPlacement='left'>More</Linkton>}
        onToggle={setIsContextMenuOpen}
      >
        {!!isGroupAdmin && <a className={styles.dropdownMenuItem} href={LINKS.GROUP_EDIT.replace(':id', group.groupLink)} target='_top'><Icon icon='new-pencil-edit' size={18} /> Edit group</a>}
        {!isGroupAdmin && !!isGroupMember && <a className={styles.dropdownMenuItem} href={`${groupPath}/leave`} data-method='post'><Icon icon='cancel' size={18} /> Leave</a>}
        {!isGroupAdmin && !!canFollow && !!isGroupFollower && <a className={styles.dropdownMenuItem} href={`${groupIdPath}/unfollow`} data-method='post'><Icon icon='New-project' size={18} /> Unfollow tracks</a>}
      </DropdownOrBottomSheet>
    )
  }, [hasMoreActions, group, isGroupAdmin, isGroupMember, isGroupFollower, groupPath, groupIdPath, canFollow, isContextMenuOpen])

  // see if there's unrendered content in the element
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        const { target } = entry
        if (target) {
          if (target.scrollHeight > target.clientHeight) {
            setRemainingHeight(target.scrollHeight - target.clientHeight)
          }
        }
      }
    })

    if (itemsContainerRef.current) resizeObserver.observe(itemsContainerRef.current)
  }, [itemsContainerRef])

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.imageContainerMobile}>{renderImage()}</div>

        {!!group.description && (
          <div className={styles.descriptionContainerDesktop}>
            <div ref={itemsContainerRef} className={`${styles.description} ${!!showWholeDescription && styles.descriptionShowAll}`}>
              {description}
            </div>
            {remainingHeight > 0 && <p ref={remainingRef} className={styles.showMoreDescription} onClick={showMore}>Show {showWholeDescription ? 'less' : 'more'}</p>}
          </div>
        )}

        <CommunityAvatars count={membershipCount} users={members} morePath={`${groupPath}/members`} />

        <div className={styles.actionsContainer}>
          <div className={styles.actions}>
            {!!isGroupAdmin && <InviteMembers groupId={group.id} groupLink={group.groupLink} groupName={group.name} />}
            {!!canAddTracks && <CommunityAddTrack groupId={group.id} groupLink={group.groupLink} groupName={group.name} />}
            {(currentUser ? !isGroupAdmin && !!canJoin : joinable) && <Linkton className={styles.button} type='outline' tag='anchor' icon='plus' iconPlacement='left' href={currentUser ? `${groupPath}/join` : LINKS.AUTH_SIGN_UP} data-method={currentUser ? 'post' : 'get'}>Join group</Linkton>}
            {(currentUser ? !isGroupAdmin && !!canFollow && !isGroupFollower : followable) && <Linkton className={styles.button} type='outline' tag='anchor' icon='New-project' iconPlacement='left' href={currentUser ? `${groupIdPath}/follow` : LINKS.AUTH_SIGN_UP} data-method={currentUser ? 'post' : 'get'}>Follow tracks</Linkton>}
            <div className={styles.mobileDropdown}>{renderDropdown(false)}</div>
          </div>

          <div className={styles.desktopDropdown}>
            {renderDropdown(true)}
          </div>
        </div>

        {!!isGroupAdmin && <p className={styles.admin}>You are admin</p>}

        {<div className={`${styles.description} ${styles.descriptionMobile}`}>{description}</div>}
      </div>

      <div className={styles.imageContainerDesktop}>
        {renderImage()}
      </div>
    </div>
  )
}

export default CommunityGroupHero
