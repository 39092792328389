import { Component } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import LandingTestimonials from '../landing-testimonials'
import LandingFeaturedTracks from '../landing-featured-tracks'
import PropTypes from 'prop-types'

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-community'
})

export default class LandingCommunity extends Component {
  render () {
    return (
      <div {...bem()}>
        <div {...bem('container')}>
          <h2 {...bem('header')}>
            Publish your music and build a fan base.
          </h2>
          <ul {...bem('stats')}>
            <li>150,000 monthly active users</li>
            <li>10,000 songs posted monthly</li>
          </ul>
          <LandingTestimonials />
          <LandingFeaturedTracks tracks={this.props.tracks} />
        </div>
      </div>
    )
  }
}

LandingCommunity.propTypes = {
  tracks: PropTypes.array
}
