import React from "react";
import PropTypes from "prop-types";

import { LINKS, ENDPOINTS } from "@common/consts";
import Icon from "../../icon";
import Linkton, { SIZE, TAG, TYPE } from "../../linkton";
import {
  mobileMenuCnFn,
  mobileMenuContentCn,
  mobileMenuItemCn,
  mobileMenuSubItemCn,
  mobileMenuIconCn,
  mobileMenuItemListCn,
  mobileMenuHeaderCn,
  mobileMenuHeaderTitleCn,
  mobileMenuCloseIconCn,
  mobileMenuBackIconCn,
  signupMenuCn,
  mobileMenuSlideCnFn,
} from "./MobileMenu.cn.js";
import "./MobileMenu.style.scss";
import { prepareNavItem, filteredMenuItems } from "../MegaMenu.utils";
import HamburgerToggle from "@components/hamburger-toggle";
import {
  StrapiContext,
  useStrapiContext,
} from "../../../contexts/StrapiContext";

const SLIDE_ANIMATION_MODIFIERS = {
  IN: "slide-in",
  OUT: "slide-out",
};

const SLIDE_ANIMATION_PREFIX = ".pfx-mobile-menu__slide--";

const MenuItemWrapper = ({
  item,
  children,
  isLinkable,
  className,
  onLinkClick,
  onItemClick,
}) => {
  const { navigation } = useStrapiContext();

  const handleOnLinkClick = () => {
    onLinkClick?.();
  };

  const handleOnItemClick = (event) => {
    event.preventDefault();
    onItemClick(item);
  };

  if (!isLinkable || (item?.items?.length || 0) > 0) {
    return (
      <div className={className} onClick={handleOnItemClick}>
        {children}
      </div>
    );
  }

  return (
    <a
      className={className}
      href={navigation.getPath(item)}
      onClick={handleOnLinkClick}
    >
      {children}
    </a>
  );
};

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);

    const { navigation } = props;

    this.state = {
      menus: [
        {
          title: null,
          path: "",
          items: navigation || [],
        },
      ],
      activeMenuIndex: 0,
      transition: null,
    };
  }

  onClickMenuItem = (item) => {
    const { menus, transition } = this.state;
    const { navigation } = this.context;
    if (transition) return;

    const menusClone = [...menus];

    menusClone.push({
      title: item.title || null,
      path: navigation.getPath(item),
      items: item.items,
    });

    this.setState(
      {
        menus: menusClone,
        transition: SLIDE_ANIMATION_MODIFIERS.IN,
      },
      () => {
        const slide = document.querySelector(
          `${SLIDE_ANIMATION_PREFIX}${SLIDE_ANIMATION_MODIFIERS.IN}`
        );
        slide.addEventListener("animationend", () => {
          this.setState({
            transition: null,
          });
        });
      }
    );
  };

  stepBackClick = () => {
    const { menus, transition } = this.state;

    if (transition) return;

    this.setState(
      {
        transition: SLIDE_ANIMATION_MODIFIERS.OUT,
      },
      () => {
        const slide = document.querySelector(
          `${SLIDE_ANIMATION_PREFIX}${SLIDE_ANIMATION_MODIFIERS.OUT}`
        );
        const menusClone = [...menus];
        menusClone.pop();

        slide.addEventListener("animationend", () => {
          this.setState({
            menus: menusClone,
            transition: null,
          });
        });
      }
    );
  };

  onLinkClick = () => {
    const { toggleMenu } = this.props;
    toggleMenu();
  };

  renderMenuItems = (navItems, isTopLevel) => {
    if (!navItems || navItems.length === 0) return null;

    const { filteredItems, showReadMore } = filteredMenuItems(navItems);
    const { menus } = this.state;
    const { navigation } = this.context;
    return (
      <div className={mobileMenuItemListCn}>
        {filteredItems.map((navItem) => {
          const { item, items, isLinkable } = prepareNavItem(navItem);

          if (item.menuAttached === false) {
            return null;
          }

          return (
            <MenuItemWrapper
              onLinkClick={this.onLinkClick}
              onItemClick={this.onClickMenuItem}
              item={item}
              isLinkable={isLinkable}
              className={isTopLevel ? mobileMenuItemCn : mobileMenuSubItemCn}
              key={`${item.title}_${item.id}`}
            >
              <div>{item.title}</div>
              {items && (
                <Icon
                  type="blank"
                  icon="next"
                  size={20}
                  className={mobileMenuIconCn}
                />
              )}
            </MenuItemWrapper>
          );
        })}
        {showReadMore && (
          <MenuItemWrapper
            onLinkClick={this.onLinkClick}
            item={{
              path: navigation.getPath(menus[menus.length - 1]),
            }}
            className={mobileMenuSubItemCn}
            isLinkable
          >
            <div>See all</div>
          </MenuItemWrapper>
        )}
      </div>
    );
  };

  render() {
    const { mobileOpen, toggleMenu, isUserLoggedIn } = this.props;
    const { menus, transition } = this.state;

    return (
      <div
        className={mobileMenuCnFn({ active: mobileOpen, user: isUserLoggedIn })}
      >
        <div className={mobileMenuContentCn}>
          {(menus || []).map((menu, index) => {
            if (menu.menuAttached === false) {
              return false;
            }

            const modifier = {};

            if (index + 1 === menus.length) {
              modifier[transition] = true;
            }

            return (
              <div
                className={mobileMenuSlideCnFn(modifier)}
                key={`${menu.title}_${menu.id}`}
              >
                <HamburgerToggle
                  isOpen
                  onClick={toggleMenu}
                  size={32}
                  className={mobileMenuCloseIconCn}
                />

                <div className={mobileMenuContentCn}>
                  <div className={mobileMenuHeaderCn}>
                    {index > 0 && (
                      <div className={mobileMenuHeaderTitleCn}>
                        <Icon
                          onClick={this.stepBackClick}
                          type="blank"
                          icon="previous"
                          size={20}
                          className={mobileMenuBackIconCn}
                        />
                        <span>{menu.title}</span>
                      </div>
                    )}
                    {index === 0 && <div className={mobileMenuHeaderTitleCn} />}
                  </div>

                  {this.renderMenuItems(menu.items, index === 0)}
                </div>
              </div>
            );
          })}
        </div>

        <div className={signupMenuCn}>
          <Linkton
            size={SIZE.LARGE}
            tag={TAG.ANCHOR}
            href={isUserLoggedIn ? LINKS.ACCOUNT_PROFILE : LINKS.AUTH_SIGN_IN}
            type={TYPE.OUTLINE}
            onClick={this.onLinkClick}
          >
            {isUserLoggedIn ? "My account" : "Login"}
          </Linkton>
          <Linkton
            size={SIZE.LARGE}
            tag={TAG.ANCHOR}
            href={isUserLoggedIn ? ENDPOINTS.LOGOUT : LINKS.AUTH_PLANS}
          >
            {isUserLoggedIn ? "Logout" : "Sign up"}
          </Linkton>
        </div>
      </div>
    );
  }
}

MobileMenu.contextType = StrapiContext;

MobileMenu.propTypes = PropTypes.shape({
  items: PropTypes.array,
  isUserLoggedIn: PropTypes.boolean,
}).isRequired;

export default MobileMenu;
