// Currently our testing stack is not respecting webpack config.
// this is workaround to get DefinePlugin working
// Proper fix would be to find sort of jest-webpack solution and integrate it
// import cssVariables from '@styles/variables.scss'

import { Enum } from "./utils";

const WEBPACK_DEFINE = {}

try {
  // the define plugin used in the rails webpack can populate the values for rails builds, and for the next bundler any NEXT_PUBLIC_ variables are inlined into the js bundle
  WEBPACK_DEFINE.STUDIO_URL = typeof STUDIO_URL !== 'undefined' ? STUDIO_URL : process.env.NEXT_PUBLIC_STUDIO_URL
  WEBPACK_DEFINE.ENV = typeof ENV !== 'undefined' ? ENV : process.env.NEXT_PUBLIC_FE_ENV
  WEBPACK_DEFINE.RTC_URL = RTC_URL
  WEBPACK_DEFINE.SOUNDATION_URL = SOUNDATION_URL
  WEBPACK_DEFINE.EMPTY_PROJECT_TEMPLATE = EMPTY_PROJECT_TEMPLATE
  WEBPACK_DEFINE.STRAPI_URL = STRAPI_URL
  WEBPACK_DEFINE.API_URL = API_URL
  WEBPACK_DEFINE.DEFAULT_SOLO_THUMBNAIL = DEFAULT_SOLO_THUMBNAIL
  WEBPACK_DEFINE.DEFAULT_COLLAB_THUMBNAIL = DEFAULT_COLLAB_THUMBNAIL
  WEBPACK_DEFINE.EMBED_STUDIO_CSS_URL = EMBED_STUDIO_CSS_URL
  WEBPACK_DEFINE.EMBED_STUDIO_JS_URL = EMBED_STUDIO_JS_URL
} catch (e) {
  if (!WEBPACK_DEFINE.API_URL) WEBPACK_DEFINE.API_URL = 'MOCK_VALUE'
  if (!WEBPACK_DEFINE.STUDIO_URL) WEBPACK_DEFINE.STUDIO_URL = 'MOCK_VALUE'
  if (!WEBPACK_DEFINE.SOUNDATION_URL) WEBPACK_DEFINE.SOUNDATION_URL = 'http://localhost:3000'
  if (!WEBPACK_DEFINE.RTC_URL) WEBPACK_DEFINE.RTC_URL = 'http://localhost:3010'
  if (!WEBPACK_DEFINE.STRAPI_URL) WEBPACK_DEFINE.STRAPI_URL = 'MOCK_VALUE'
  if (!WEBPACK_DEFINE.STRAPI_PORT) WEBPACK_DEFINE.STRAPI_PORT = 'MOCK_VALUE'
  if (!WEBPACK_DEFINE.DEFAULT_SOLO_THUMBNAIL) WEBPACK_DEFINE.DEFAULT_SOLO_THUMBNAIL = 'https://thumbnail-cdn.soundation.com/solo_thumbnail.png'
  if (!WEBPACK_DEFINE.DEFAULT_COLLAB_THUMBNAIL) WEBPACK_DEFINE.DEFAULT_COLLAB_THUMBNAIL = 'https://thumbnail-cdn.soundation.com/collab_thumbnail.png'
  if (!WEBPACK_DEFINE.ENV) WEBPACK_DEFINE.ENV = 'LOCAL'
  if (!WEBPACK_DEFINE.EMBED_STUDIO_CSS_URL) WEBPACK_DEFINE.EMBED_STUDIO_CSS_URL = "MOCK_VALUE"
  if (!WEBPACK_DEFINE.EMBED_STUDIO_JS_URL) WEBPACK_DEFINE.EMBED_STUDIO_JS_URL = "MOCK_VALUE"
}

export { WEBPACK_DEFINE }

export const LINKS = {
  ACCOUNT: '/account',
  ACCOUNT_PROFILE: '/account/profile',
  ACCOUNT_MANAGE: '/account/manage',
  ACCOUNT_REFERRAL: '/account/referrals',
  STUDIO: WEBPACK_DEFINE.STUDIO_URL,
  STUDIO_PROJECT: `${WEBPACK_DEFINE.STUDIO_URL}/project/:id`,
  STUDIO_TEMPLATE: `${WEBPACK_DEFINE.STUDIO_URL}/templates/:id/new-project`,
  RTC: WEBPACK_DEFINE.RTC_URL,
  RTC_BASE: WEBPACK_DEFINE.RTC_URL.replace(/https?:/, ''),
  SUPPORT: 'https://soundation.com/support/',
  EMPTY_PROJECT_TEMPLATE: WEBPACK_DEFINE.EMPTY_PROJECT_TEMPLATE,
  MESSAGES: '/account/messages',
  STUDIO_FLASH: '//soundation.com/studio',
  LEARN: '/learn/',
  FEED: '/feed',
  TUTORIALS: '/tutorials',
  TRACKS: '/tracks',
  DOWNLOAD: '/download',
  FOLLOWED_TRACKS: '/tracks?type=followed',
  FEATURED_TRACKS: '/tracks?type=featured',
  TRENDING_TRACKS: '/tracks?type=trending',
  RECENT_TRACKS: '/tracks?type=recent',
  MY_TRACKS: '/account/tracks',
  EDIT_MY_TRACK: '/account/tracks/:id/edit',
  GROUP_TRACK: '/group/:groupId/track/:trackId',
  USER_TRACK: '/user/:profileLink/track/:mixdownPermalink',
  USERS: '/users',
  USER_TRACKS: '/user/:profileLink/tracks',
  USER_GROUPS: '/user/:profileLink/groups',
  USER_FOLLOWERS: '/user/:profileLink/followers',
  USER_FOLLOWING: '/user/:profileLink/following',
  TOP_USERS: '/users?sort=top_users',
  TRENDING_USERS: '/users?sort=trending_users',
  COMMUNITY_SEARCH: '/community/search',
  COMMENTS: '/comments',
  GROUPS: '/groups',
  GROUP_PAGE: '/group/:groupLink',
  MY_GROUPS: '/account/groups',
  TRENDING_GROUPS: '/groups' + '?type=popular',
  FEATURED_GROUPS: '/groups' + '?type=featured',
  RECENT_GROUPS: '/groups?type=new',
  COMMUNITY: '/community',
  MY_PROJECTS: '/account/projects',
  PLAYER: '/player',
  SHOP: '/products',
  PRICING: '/accounts',
  EDU_PRICING: '/pricing',
  LOGIN: '/login',
  SING_UP: '/singup',
  FEATURES: '/features',
  MEMBERSHIPS: '/memberships',
  PRIVACY_POLICY: '/privacy-policy',
  HALLOWEEK_JAM: '/group/halloween-jam',
  TERMS_OF_SERVICE: '/terms-of-service',
  LICENSING_AGREEMENT: '/licensing-agreement',
  BLOG: '//soundation.com/station/',
  AUTH: '/auth',
  AUTH_SIGN_IN: '/auth?tab=sign_in',
  AUTH_SIGN_UP: '/auth?tab=sign_up',
  AUTH_SUCCESS: '/auth?tab=success',
  AUTH_FREE: '/auth?plan=free&tab=sign_up',
  AUTH_PLANS: '/auth/plans',
  AUTH_CONSENTS: '/auth/confirm_consents',
  AUTH_EDU_SIGN_UP: '/auth?tab=edu_sign_up',
  CART: '/cart',
  CART_REMOVE: '/cart/remove',
  SOCIAL: {
    FACEBOOK: '//www.facebook.com/soundation',
    TWITTER: '//twitter.com/soundationmusic',
    TUMBLR: '//soundationstudio.tumblr.com/',
    YOUTUBE: '//www.youtube.com/user/SoundationStudio',
    GOOGLE: '//plus.google.com/113152729183962496232',
    INSTAGRAM: '//www.instagram.com/soundationmusic/',
    TIKTOK: '//www.tiktok.com/@soundationmusic',
    DISCORD: '//discord.gg/Bz8dvw8'
  },
  SOCIAL_LOGIN: {
    FACEBOOK: '/users/auth/facebook',
    GOOGLE: '/users/auth/google_oauth2'
  },
  CHECKOUT: '/checkout',
  EDUCATION: '//www.musicfirst.com/applications/soundation4education',
  GROUP: '/group',
  GROUP_NEW: '/group/new',
  GROUP_EDIT: '/group/:id/edit',
  USER: '/user',
  USER_PAGE: '/user/:profileLink',
  TRACK_SHORT: '/t',
  INSTALL_CHROME: '/studio/install-chrome',
  STRIPE_LIBRARY: 'https://js.stripe.com/v3/',
  EDUCATION_PLAN: '//soundation.com/station/soundation-education/',
  PRESS: '//soundation.com/station/press',
  JOBS: '//soundation.com/station/jobs/',
  STRAPI: WEBPACK_DEFINE.STRAPI_URL,
  ONLINE_STUDIO: '/online-studio',
  MAKE_MUSIC: '/make-music',
  EMBED_CSS: WEBPACK_DEFINE.EMBED_STUDIO_CSS_URL,
  EMBED_JS: WEBPACK_DEFINE.EMBED_STUDIO_JS_URL,
  DEFAULT_SOLO_THUMBNAIL: WEBPACK_DEFINE.DEFAULT_SOLO_THUMBNAIL,
  DEFAULT_COLLAB_THUMBNAIL: WEBPACK_DEFINE.DEFAULT_COLLAB_THUMBNAIL
}

export const ENDPOINTS = {
  ACCOUNT_PROFILE: '/account/profile',
  USERS: '/users',
  TRENDING_USERS: '/api/users/trending',
  TOP_USERS: '/api/users/top',
  USER: '/user',
  USERS_API: '/api/users',
  USERS_SIGN_IN: 'users/sign_in',
  LOGOUT: '/logout',
  USER_PASSWORD: '/users/password',
  CLONE_PROJECT: `${LINKS.RTC}/v1/collaboration/:id/clone`,
  CHANGE_USER_PASSWORD: '/api/users/:guid/change_password',
  PAYPAL_GATEWAY: '/account/get',
  CHARGE: '/api/stripe_payments/charges',
  SUBSCRIPTION: '/api/subscriptions',
  REMOVE_SUBSCRIPTION_CANCELLATION: '/api/subscriptions/:id/remove_cancellation',
  PAYMENT_INTENT: '/api/stripe_payments/payment_intents',
  CART_REMOVE: '/cart/remove',
  CART_CHECKOUT: '/cart/checkout',
  DATA_LAYER: '/datalayer',
  BANNERS: '/api/banners',
  CANCEL_SUBSCRIPTION: '/api/subscriptions/:id/cancel',
  CANCEL_REASON_TEMPLATES: '/api/cancel_reason_templates',
  CONSENTS: `/api/accounts/consents`,
  FLAGS_API: '/api/flags',
  RESEND_EMAIL_CONFIRMATION: '/users/confirmation',
  CHECK_SUBSCRIPTION_PRICE: '/api/subscription_prices',
  GROUPS: '/api/groups',
  RECENT_GROUPS: '/api/groups/recent',
  TRENDING_GROUPS: '/api/groups/trending',
  FEATURED_GROUPS: '/api/groups/featured',
  GROUPS_MEMBERS: '/api/groups/:group_link/memberships',
  GROUP_TRACKS: '/api/groups/:group_link/group_tracks',
  GROUP_INVITATION_ACCEPT: '/api/groups/:group_link/accept_invitation',
  ACCOUNT_REFERRAL: '/api/accounts/referrals',
  ACCOUNT_REFERRED_USERS: '/api/accounts/referred_users',
  ACCOUNT: '/api/account',
  ACCOUNTS: '/api/accounts',
  FILES: '/api/files',
  FEED: '/api/feed_items',
  TRACKS: '/api/tracks',
  MY_TRACKS: '/api/accounts/tracks',
  COMMENTS: '/api/comments',
  GROUP_COMMENTS: '/api/groups/:group_id/comments',
  TRACK_COMMENTS: '/api/tracks/:track_id/comments',
  NEWS: '/api/news',
  NEWS_ITEM: '/api/news/:id',
  NEWS_COMMENTS: '/api/news/:news_id/comments',
  NEWS_LIKES: '/api/news/:news_id/likes',
  CONVERSATIONS: '/api/conversations',
  CONVERSATION: '/api/conversations/:id',
  CONVERSATION_MARK_AS_READ: '/api/conversations/:id/mark_as_read',
  CONVERSATION_MESSAGES: '/api/conversations/:id/messages',
  NEW_CONVERSATIONS: '/api/conversations/new_conversations',
  MESSAGES: '/api/messages',
  NOTIFICATIONS: '/api/notifications',
  EDIT_MIXDOWN: '/api/mixdowns/:id',
  FOLLOWED_TRACKS: '/api/mixdowns/following',
  SEARCH_COMMUNITY: '/api/community/search',
  USER_TRACKS: '/api/users/:id/mixdowns',
  USER_GROUPS: '/api/users/:id/group_memberships',
  USER_FOLLOWERS: '/api/users/:id/followers',
  USER_FOLLOWINGS: '/api/users/:id/followings',
  INC_PLAYS: '/api/mixdowns/:id/plays',
  FEATURED_TRACKS: '/api/mixdowns/featured',
  RECENT_TRACKS: '/api/mixdowns/recent',
  TRENDING_TRACKS: '/api/mixdowns/trending',
  SURVEYS: '/api/surveys',
  UPLOADS: '/api/uploads',
  UPLOADS_SIGN: '/api/image_uploads/presign',
  PROJECTS: '/api/projects',
  PROJECT_TEMPLATES: '/api/project_templates',
  MEMBERSHIP_REQUESTS: '/membership_requests',
  MEMBERS: '/api/members/',
  PROJECT_MEMBER: '/api/projects/:project_id/members/:id',
  SONGS: '/snd_api/songs',
  PIECES: '/api/accounts/pieces',
  HALLOWEEK: '/api/halloweek',
  GA_ORDER_COMPLETED_EVENT: '/api/gtm/order_completed',
  WORKSPACES: '/api/workspaces',
  ORGANIZATIONS: '/api/organizations',
  CHARGEBEE: {
    HOSTED_PAGES_CHECKOUT_NEW: '/api/chargebee/hosted_pages/checkout_new',
    HOSTED_PAGES_CHECKOUT_EXISTING: '/api/chargebee/hosted_pages/:id/checkout_existing',
    PORTAL_SESSIONS: '/api/chargebee/portal_sessions'
  }
}

export const URLS = {
  PROD: 'https://www.soundation.com'
}

export const ACCOUNT_TYPES = {
  FREE: 0,
  INTRO: 1,
  POWER: 2,
  PREMIUM: 3,
  STARTER: 4,
  CREATOR: 5,
  PRO: 6,
  EUROPA: 7,
}

export const ACCOUNT_TYPES_IDX = {
  0: 'free',
  1: 'intro',
  2: 'power',
  3: 'premium',
  4: 'starter',
  5: 'creator',
  6: 'pro',
  7: 'europa'
}

export const ACCOUNT_TYPES_STR = {
  FREE: 'free',
  INTRO: 'intro',
  POWER: 'power',
  PREMIUM: 'premium',
  STARTER: 'starter',
  CREATOR: 'creator',
  PRO: 'pro',
  EUROPA: 'europa',
}

export const ACCOUNT_NAMES = {
  [ACCOUNT_TYPES.FREE]: 'Free Account',
  [ACCOUNT_TYPES.INTRO]: 'Intro Account',
  [ACCOUNT_TYPES.POWER]: 'Power User Account',
  [ACCOUNT_TYPES.PREMIUM]: 'Premium Account',
  [ACCOUNT_TYPES.STARTER]: 'Starter Account',
  [ACCOUNT_TYPES.CREATOR]: 'Creator Account',
  [ACCOUNT_TYPES.PRO]: 'Pro Account',
  [ACCOUNT_TYPES.EUROPA]: 'Europa Addon'
}

export const PLAN_PERIOD = {
  MONTHLY: 1,
  YEARLY: 2
}

export const PLAN_PERIOD_STR = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

export const GTM_EVENTS = {
  USER_SIGN_UP: 'USER_SIGN_UP',
  ORGANIZATION_SIGN_UP: 'ORGANIZATION_SIGN_UP',
  USER_ACCOUNT_UPGRADE: 'USER_ACCOUNT_UPGRADE',
  SOUND_SHOP_PURCHASE: 'SOUND_SHOP_PURCHASE',
  REFERRAL_LINK_ACTION_TEXT_CLICK: 'REFERRAL_LINK_ACTION_TEXT_CLICK',
  REFERRAL_LINK_ACTION_COPIED: 'REFERRAL_LINK_ACTION_COPIED',
  REFERRAL_LINK_ACTION_TWEETED: 'REFERRAL_LINK_ACTION_TWEETED',
  REFERRAL_LINK_ACTION_FB_SHARED: 'REFERRAL_LINK_ACTION_FB_SHARED',
  REFERRAL_LINK_ACTION_EMAILED: 'REFERRAL_LINK_ACTION_EMAILED',
  GTM_EVENT_INTERACTED_WITH_UPGRADE_TO_PREMIUM_POPUP: 'GTM_EVENT_INTERACTED_WITH_UPGRADE_TO_PREMIUM_POPUP',
  GTM_EVENT_POSTED_CAMPAIGN_TRACK: 'GTM_EVENT_POSTED_CAMPAIGN_TRACK',
  GTM_STARTED_REMIX: 'GTM_STARTED_REMIX',
  GTM_EVENT_COOKIE_CONSENT_GRANTED: 'GTM_EVENT_COOKIE_CONSENT_GRANTED',
  GTM_PLAYED_VIDEO: "GTM_PLAYED_VIDEO"
}

export const GTM_PARAMS_TRIGGERS = {
  FACEBOOK_SIGN_UP: 'facebook_sign_up',
  GOOGLE_SIGN_UP: 'google_sign_up',
  EMAIL_SIGN_UP: 'email_sign_up',
  CAMPAIGN_TRACK_POSTED_SUCCESS: 'campaign_success',
  CAMPAIGN_ID: 'campaign_id'
}

export const STATUS_CODES = {
  unprocessable_content: 422
}

export const SCREEN_BREAKPOINT_DEVICES = {
  SCREEN_DESKTOP_BIG: 0,
  SCREEN_DESKTOP_REGULAR: 1,
  SCREEN_TABLET_BIG: 2,
  SCREEN_TABLET_REGULAR: 3,
  SCREEN_MOBILE: 4
}

export const isDeviceBigger = (device, compare, inclusive = true) => {
  return device < compare || inclusive && device === compare
}

export const SCREEN_BREAKPOINTS = [
  '1550px',
  '1260px',
  '1020px',
  '668px',
].map((v) => parseInt(v))

export const LOCAL_STORAGE_KEYS = {
  SIGN_IN_REDIRECT_URL: 'signInRedirectUrl',
  FIRST_VISIT: 'firstVisit',
  RETURNING_USER: 'returningUser'
}

export const FACEBOOK_CONTENT_TYPES = {
  ORGANIC: 'organic',
  REMIX: 'remix'
}

export const CURRENCIES = {
  USD: 1,
  EUR: 2,
  GBP: 3,
  SEK: 4
}

export const CURRENCIES_IDX = {
  1: 'USD',
  2: 'EUR',
  3: 'GBP',
  4: 'SEK',
}

export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
  GBP: '£',
  SEK: 'kr',
}

export const PAYMENT_METHOD = {
  stripe: 'stripe',
  paypal: 'paypal',
  chargebee: 'chargebee',
  none: null
}

export const REFERRAL_ENABLED_PAYMENT_METHODS = [
  PAYMENT_METHOD.stripe,
  PAYMENT_METHOD.chargebee,
  PAYMENT_METHOD.none
]

export const DISCOUNT_TYPE = new Enum('AMOUNT', 'PERCENTAGE')

export const PROJECTS_LIMIT_PER_PLAN = {
  [ACCOUNT_TYPES.FREE]: 1,
  [ACCOUNT_TYPES.INTRO]: 3,
  [ACCOUNT_TYPES.POWER]: 3,
  [ACCOUNT_TYPES.PREMIUM]: 10,
  [ACCOUNT_TYPES.STARTER]: 10,
  [ACCOUNT_TYPES.CREATOR]: 99000,
  [ACCOUNT_TYPES.PRO]: 99000
}

export const PIECE_TYPE = {
  PROJECT: 'Project',
  SONG: 'Song'
}

export const DISCORD = {
  inviteUrl: 'https://discord.gg/hU9cQ3f',
  server: '672763048083324928',
  channels: {
    halloweek: '767672661294972940',
    general: '675336286780915712'
  }
}

export const COUNTRIES_DROPDOWN = [
  {
    "label": "United States",
    "value": "United States"
  },
  {
    "label": "United Kingdom",
    "value": "United Kingdom"
  },
  {
    "label": "Afghanistan",
    "value": "Afghanistan"
  },
  {
    "label": "Albania",
    "value": "Albania"
  },
  {
    "label": "Algeria",
    "value": "Algeria"
  },
  {
    "label": "Andorra",
    "value": "Andorra"
  },
  {
    "label": "Angola",
    "value": "Angola"
  },
  {
    "label": "Antigua and Barbuda",
    "value": "Antigua and Barbuda"
  },
  {
    "label": "Argentina",
    "value": "Argentina"
  },
  {
    "label": "Armenia",
    "value": "Armenia"
  },
  {
    "label": "Australia",
    "value": "Australia"
  },
  {
    "label": "Austria",
    "value": "Austria"
  },
  {
    "label": "Azerbaijan",
    "value": "Azerbaijan"
  },
  {
    "label": "Bahrain",
    "value": "Bahrain"
  },
  {
    "label": "Bangladesh",
    "value": "Bangladesh"
  },
  {
    "label": "Barbados",
    "value": "Barbados"
  },
  {
    "label": "Belarus",
    "value": "Belarus"
  },
  {
    "label": "Belgium",
    "value": "Belgium"
  },
  {
    "label": "Belize",
    "value": "Belize"
  },
  {
    "label": "Benin",
    "value": "Benin"
  },
  {
    "label": "Bhutan",
    "value": "Bhutan"
  },
  {
    "label": "Bolivia",
    "value": "Bolivia"
  },
  {
    "label": "Bosnia and Herzegovina",
    "value": "Bosnia and Herzegovina"
  },
  {
    "label": "Botswana",
    "value": "Botswana"
  },
  {
    "label": "Brazil",
    "value": "Brazil"
  },
  {
    "label": "Brunei",
    "value": "Brunei"
  },
  {
    "label": "Bulgaria",
    "value": "Bulgaria"
  },
  {
    "label": "Burkina Faso",
    "value": "Burkina Faso"
  },
  {
    "label": "Burundi",
    "value": "Burundi"
  },
  {
    "label": "Cabo Verde",
    "value": "Cabo Verde"
  },
  {
    "label": "Cambodia",
    "value": "Cambodia"
  },
  {
    "label": "Cameroon",
    "value": "Cameroon"
  },
  {
    "label": "Canada",
    "value": "Canada"
  },
  {
    "label": "Central African Republic",
    "value": "Central African Republic"
  },
  {
    "label": "Chad",
    "value": "Chad"
  },
  {
    "label": "Channel Islands",
    "value": "Channel Islands"
  },
  {
    "label": "Chile",
    "value": "Chile"
  },
  {
    "label": "China",
    "value": "China"
  },
  {
    "label": "Colombia",
    "value": "Colombia"
  },
  {
    "label": "Comoros",
    "value": "Comoros"
  },
  {
    "label": "Congo",
    "value": "Congo"
  },
  {
    "label": "Costa Rica",
    "value": "Costa Rica"
  },
  {
    "label": "Côte d’Ivoire",
    "value": "Côte d’Ivoire"
  },
  {
    "label": "Croatia",
    "value": "Croatia"
  },
  {
    "label": "Cuba",
    "value": "Cuba"
  },
  {
    "label": "Cyprus",
    "value": "Cyprus"
  },
  {
    "label": "Czech Republic",
    "value": "Czech Republic"
  },
  {
    "label": "Denmark",
    "value": "Denmark"
  },
  {
    "label": "Djibouti",
    "value": "Djibouti"
  },
  {
    "label": "Dominica",
    "value": "Dominica"
  },
  {
    "label": "Dominican Republic",
    "value": "Dominican Republic"
  },
  {
    "label": "DR Congo",
    "value": "DR Congo"
  },
  {
    "label": "Ecuador",
    "value": "Ecuador"
  },
  {
    "label": "Egypt",
    "value": "Egypt"
  },
  {
    "label": "El Salvador",
    "value": "El Salvador"
  },
  {
    "label": "Equatorial Guinea",
    "value": "Equatorial Guinea"
  },
  {
    "label": "Eritrea",
    "value": "Eritrea"
  },
  {
    "label": "Estonia",
    "value": "Estonia"
  },
  {
    "label": "Eswatini",
    "value": "Eswatini"
  },
  {
    "label": "Ethiopia",
    "value": "Ethiopia"
  },
  {
    "label": "Faeroe Islands",
    "value": "Faeroe Islands"
  },
  {
    "label": "Finland",
    "value": "Finland"
  },
  {
    "label": "France",
    "value": "France"
  },
  {
    "label": "French Guiana",
    "value": "French Guiana"
  },
  {
    "label": "Gabon",
    "value": "Gabon"
  },
  {
    "label": "Gambia",
    "value": "Gambia"
  },
  {
    "label": "Georgia",
    "value": "Georgia"
  },
  {
    "label": "Germany",
    "value": "Germany"
  },
  {
    "label": "Ghana",
    "value": "Ghana"
  },
  {
    "label": "Gibraltar",
    "value": "Gibraltar"
  },
  {
    "label": "Greece",
    "value": "Greece"
  },
  {
    "label": "Grenada",
    "value": "Grenada"
  },
  {
    "label": "Guatemala",
    "value": "Guatemala"
  },
  {
    "label": "Guinea",
    "value": "Guinea"
  },
  {
    "label": "Guinea-Bissau",
    "value": "Guinea-Bissau"
  },
  {
    "label": "Guyana",
    "value": "Guyana"
  },
  {
    "label": "Haiti",
    "value": "Haiti"
  },
  {
    "label": "Holy See",
    "value": "Holy See"
  },
  {
    "label": "Honduras",
    "value": "Honduras"
  },
  {
    "label": "Hong Kong",
    "value": "Hong Kong"
  },
  {
    "label": "Hungary",
    "value": "Hungary"
  },
  {
    "label": "Iceland",
    "value": "Iceland"
  },
  {
    "label": "India",
    "value": "India"
  },
  {
    "label": "Indonesia",
    "value": "Indonesia"
  },
  {
    "label": "Iran",
    "value": "Iran"
  },
  {
    "label": "Iraq",
    "value": "Iraq"
  },
  {
    "label": "Ireland",
    "value": "Ireland"
  },
  {
    "label": "Isle of Man",
    "value": "Isle of Man"
  },
  {
    "label": "Israel",
    "value": "Israel"
  },
  {
    "label": "Italy",
    "value": "Italy"
  },
  {
    "label": "Jamaica",
    "value": "Jamaica"
  },
  {
    "label": "Japan",
    "value": "Japan"
  },
  {
    "label": "Jordan",
    "value": "Jordan"
  },
  {
    "label": "Kazakhstan",
    "value": "Kazakhstan"
  },
  {
    "label": "Kenya",
    "value": "Kenya"
  },
  {
    "label": "Kuwait",
    "value": "Kuwait"
  },
  {
    "label": "Kyrgyzstan",
    "value": "Kyrgyzstan"
  },
  {
    "label": "Laos",
    "value": "Laos"
  },
  {
    "label": "Latvia",
    "value": "Latvia"
  },
  {
    "label": "Lebanon",
    "value": "Lebanon"
  },
  {
    "label": "Lesotho",
    "value": "Lesotho"
  },
  {
    "label": "Liberia",
    "value": "Liberia"
  },
  {
    "label": "Libya",
    "value": "Libya"
  },
  {
    "label": "Liechtenstein",
    "value": "Liechtenstein"
  },
  {
    "label": "Lithuania",
    "value": "Lithuania"
  },
  {
    "label": "Luxembourg",
    "value": "Luxembourg"
  },
  {
    "label": "Macao",
    "value": "Macao"
  },
  {
    "label": "Madagascar",
    "value": "Madagascar"
  },
  {
    "label": "Malawi",
    "value": "Malawi"
  },
  {
    "label": "Malaysia",
    "value": "Malaysia"
  },
  {
    "label": "Maldives",
    "value": "Maldives"
  },
  {
    "label": "Mali",
    "value": "Mali"
  },
  {
    "label": "Malta",
    "value": "Malta"
  },
  {
    "label": "Mauritania",
    "value": "Mauritania"
  },
  {
    "label": "Mauritius",
    "value": "Mauritius"
  },
  {
    "label": "Mayotte",
    "value": "Mayotte"
  },
  {
    "label": "Mexico",
    "value": "Mexico"
  },
  {
    "label": "Moldova",
    "value": "Moldova"
  },
  {
    "label": "Monaco",
    "value": "Monaco"
  },
  {
    "label": "Mongolia",
    "value": "Mongolia"
  },
  {
    "label": "Montenegro",
    "value": "Montenegro"
  },
  {
    "label": "Morocco",
    "value": "Morocco"
  },
  {
    "label": "Mozambique",
    "value": "Mozambique"
  },
  {
    "label": "Myanmar",
    "value": "Myanmar"
  },
  {
    "label": "Namibia",
    "value": "Namibia"
  },
  {
    "label": "Nepal",
    "value": "Nepal"
  },
  {
    "label": "Netherlands",
    "value": "Netherlands"
  },
  {
    "label": "New Zealand",
    "value": "New Zealand"
  },
  {
    "label": "Nicaragua",
    "value": "Nicaragua"
  },
  {
    "label": "Niger",
    "value": "Niger"
  },
  {
    "label": "Nigeria",
    "value": "Nigeria"
  },
  {
    "label": "North Korea",
    "value": "North Korea"
  },
  {
    "label": "North Macedonia",
    "value": "North Macedonia"
  },
  {
    "label": "Norway",
    "value": "Norway"
  },
  {
    "label": "Oman",
    "value": "Oman"
  },
  {
    "label": "Pakistan",
    "value": "Pakistan"
  },
  {
    "label": "Panama",
    "value": "Panama"
  },
  {
    "label": "Paraguay",
    "value": "Paraguay"
  },
  {
    "label": "Peru",
    "value": "Peru"
  },
  {
    "label": "Philippines",
    "value": "Philippines"
  },
  {
    "label": "Poland",
    "value": "Poland"
  },
  {
    "label": "Portugal",
    "value": "Portugal"
  },
  {
    "label": "Qatar",
    "value": "Qatar"
  },
  {
    "label": "Réunion",
    "value": "Réunion"
  },
  {
    "label": "Romania",
    "value": "Romania"
  },
  {
    "label": "Russia",
    "value": "Russia"
  },
  {
    "label": "Rwanda",
    "value": "Rwanda"
  },
  {
    "label": "Saint Helena",
    "value": "Saint Helena"
  },
  {
    "label": "Saint Kitts and Nevis",
    "value": "Saint Kitts and Nevis"
  },
  {
    "label": "Saint Lucia",
    "value": "Saint Lucia"
  },
  {
    "label": "Saint Vincent and the Grenadines",
    "value": "Saint Vincent and the Grenadines"
  },
  {
    "label": "San Marino",
    "value": "San Marino"
  },
  {
    "label": "Sao Tome & Principe",
    "value": "Sao Tome & Principe"
  },
  {
    "label": "Saudi Arabia",
    "value": "Saudi Arabia"
  },
  {
    "label": "Senegal",
    "value": "Senegal"
  },
  {
    "label": "Serbia",
    "value": "Serbia"
  },
  {
    "label": "Seychelles",
    "value": "Seychelles"
  },
  {
    "label": "Sierra Leone",
    "value": "Sierra Leone"
  },
  {
    "label": "Singapore",
    "value": "Singapore"
  },
  {
    "label": "Slovakia",
    "value": "Slovakia"
  },
  {
    "label": "Slovenia",
    "value": "Slovenia"
  },
  {
    "label": "Somalia",
    "value": "Somalia"
  },
  {
    "label": "South Africa",
    "value": "South Africa"
  },
  {
    "label": "South Korea",
    "value": "South Korea"
  },
  {
    "label": "South Sudan",
    "value": "South Sudan"
  },
  {
    "label": "Spain",
    "value": "Spain"
  },
  {
    "label": "Sri Lanka",
    "value": "Sri Lanka"
  },
  {
    "label": "State of Palestine",
    "value": "State of Palestine"
  },
  {
    "label": "Sudan",
    "value": "Sudan"
  },
  {
    "label": "Suriname",
    "value": "Suriname"
  },
  {
    "label": "Sweden",
    "value": "Sweden"
  },
  {
    "label": "Switzerland",
    "value": "Switzerland"
  },
  {
    "label": "Syria",
    "value": "Syria"
  },
  {
    "label": "Taiwan",
    "value": "Taiwan"
  },
  {
    "label": "Tajikistan",
    "value": "Tajikistan"
  },
  {
    "label": "Tanzania",
    "value": "Tanzania"
  },
  {
    "label": "Thailand",
    "value": "Thailand"
  },
  {
    "label": "The Bahamas",
    "value": "The Bahamas"
  },
  {
    "label": "Timor-Leste",
    "value": "Timor-Leste"
  },
  {
    "label": "Togo",
    "value": "Togo"
  },
  {
    "label": "Trinidad and Tobago",
    "value": "Trinidad and Tobago"
  },
  {
    "label": "Tunisia",
    "value": "Tunisia"
  },
  {
    "label": "Turkey",
    "value": "Turkey"
  },
  {
    "label": "Turkmenistan",
    "value": "Turkmenistan"
  },
  {
    "label": "Uganda",
    "value": "Uganda"
  },
  {
    "label": "Ukraine",
    "value": "Ukraine"
  },
  {
    "label": "United Arab Emirates",
    "value": "United Arab Emirates"
  },
  {
    "label": "Uruguay",
    "value": "Uruguay"
  },
  {
    "label": "Uzbekistan",
    "value": "Uzbekistan"
  },
  {
    "label": "Venezuela",
    "value": "Venezuela"
  },
  {
    "label": "Vietnam",
    "value": "Vietnam"
  },
  {
    "label": "Western Sahara",
    "value": "Western Sahara"
  },
  {
    "label": "Yemen",
    "value": "Yemen"
  },
  {
    "label": "Zambia",
    "value": "Zambia"
  },
  {
    "label": "Zimbabwe",
    "value": "Zimbabwe"
  }
]

export const MIXDOWN_STATUS = {
  WAITING: 0,
  PROCESSING: 1,
  DONE: 2,
  ERROR: 3
}

export const PROJECT_SORT_BY = {
  updatedAt: { label: 'Last edited', value: 'updated_at' },
  createdAt: { label: 'Created', value: 'created_at' },
  alphabetical: { label: 'Alphabetical', value: 'title' }
}

export const COMMUNITY_BREAKPOINT = 720

export const MAX_IMAGE_SIZE_MB = 6

export const COMMUNITY_CAROUSEL_ITEMS = 10
