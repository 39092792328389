import React from 'react'
import Toggle from '@components/toggle'
import { withTooltip } from '@components/with-tooltip'
import * as styles from './CommunityToggle.module.scss'

const ToggleWithTooltip = withTooltip(Toggle)

const CommunityToggle = (props) => {
  const { label, name, value, disabled, tooltip, onToggle } = props

  const ToggleComponent = tooltip ? ToggleWithTooltip : Toggle

  return (
    <div className={styles.container}>
      <label className={styles.toggleLabel}>{label}</label>
      {/* the form only picks up truthy checkbox values, so this is necessary for it to pick up falsy values */}
      {!disabled && <input type='hidden' name={name} value='0' />}
      {<ToggleComponent className={!!disabled && styles.disabled} value={value} name={name} label={label} onToggle={onToggle} tooltip={tooltip ? { className: tooltip.className, content: tooltip.content, placement: 'left-span' } : null} />}
    </div>
  )
}

export default CommunityToggle
