import React, { useState } from 'react'
import ModalOrBottomSheet from '@components/modal-or-bottom-sheet'
import { changeUserPassword } from '@api/user'
import Label from '../label'
import Icon from '@components/icon'
import { extractError } from '@common/utils'
import { TextFieldControlled } from '@components/text-field'
import CommunityButton from '@components/community-button'
import * as styles from './EditUserPassword.module.scss'

const initialState = {
  currentPassword: '',
  password: '',
  passwordConfirmation: ''
}

const EditUserPassword = (props) => {
  const { guid, isOpen, onClose } = props

  const [error, setError] = useState(null)
  const [form, setForm] = useState(initialState)
  const [success, setSuccess] = useState(false)

  const handleClose = () => {
    setForm(initialState)
    setError(null)
    setSuccess(false)
    onClose()
  }

  const handleSubmit = () => {
    if (!(form.currentPassword?.length >= 6 && form.password?.length >= 6 && form.passwordConfirmation?.length >= 6)) return setError('Password must be at least 6 characters long')
    if (form.password !== form.passwordConfirmation) return setError('Passwords do not match')

    changeUserPassword(guid, form)
      .then(() => setSuccess(true))
      .catch((e) => setError(extractError(e)))
  }

  return (
    <ModalOrBottomSheet
      open={isOpen}
      closeShareModal={handleClose}
      windowClassName={styles.container}
    >
      <div className={styles.innerContainer}>
        <div className={styles.header}>
          <h3 className={styles.headerTitle}>Change password</h3>
          <Icon icon='new-close' onClick={handleClose} size={16} />
        </div>

        <div className={styles.body}>
          {success ? <h3 className={styles.success}>Password has been successfully changed</h3> : (
            <React.Fragment>
              <Label>CURRENT PASSWORD</Label>
              <TextFieldControlled layout='dark' onChange={(v) => setForm((cur) => ({ ...cur, currentPassword: v }))} />

              <Label>NEW PASSWORD</Label>
              <TextFieldControlled layout='dark' onChange={(v) => setForm((cur) => ({ ...cur, password: v }))} />

              <Label>CONFIRM PASSWORD</Label>
              <TextFieldControlled layout='dark' onChange={(v) => setForm((cur) => ({ ...cur, passwordConfirmation: v }))} />

              <div className={`${styles.error} ${!error && styles.hideError}`}>{error}</div>

              <div className={styles.actions}>
                <CommunityButton fullWidthOnMobile={false} onClick={handleSubmit}>Change password</CommunityButton>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </ModalOrBottomSheet>
  )
}

export default EditUserPassword
