import React from "react";
import { Bem } from "@common/utils";
import PropTypes from "prop-types";
import "./style.scss";
import InlinePlayer from "./inline-player";
import LazyImage from "@components/lazy-image";

const cn = Bem({
  prefix: "pfx-",
  name: "inline-player",
});

export default class OverlayInlinePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isPlaying: false };
  }

  handleOnPlayChange = ({ isPlaying }) => {
    this.setState({ isPlaying });
  };

  render() {
    const { isPlaying } = this.state;

    const {
      id,
      uniquePlayerId,
      songUrl,
      fileName,
      className,
      description,
      title,
      image,
      isDisabled,
      highlightOnPlay = false,
      square = true,
      visible = "hover",
    } = this.props;

    const hasImage = !!image?.src;

    return (
      <div className={cn("square-container", { square }, className)}>
        <div
          className={cn("square-player-wrapper", {
            "no-image": !hasImage,
            square,
            [`visible-${visible}`]: true,
          })}
        >
          <div className={cn("square-inner")}>
            {hasImage && (
              <LazyImage
                className={cn("square-picture", undefined, "picture")}
                imgClassName={cn("square-image", undefined, ["image", image.className ?? ''])}
                cover
                src={image.src}
                fallbackSrc={image.fallbackSrc}
                sources={image.sources}
                size={image.size}
                aspectRatio={image.aspectRatio}
                alt={image.alt}
              />
            )}
            <InlinePlayer
              onPlayChange={this.handleOnPlayChange}
              id={id}
              songUrl={songUrl}
              uniquePlayerId={uniquePlayerId}
              fileName={fileName}
              isDisabled={isDisabled}
            />
          </div>
        </div>

        {title && (
          <>
            <div
              className={cn("square-text", {
                main: true,
                highlight: highlightOnPlay && isPlaying,
              })}
            >
              {title}
            </div>
            {description && (
              <div className={cn("square-text", "secondary")}>
                {description}
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

OverlayInlinePlayer.defaultProps = {
  square: true,
  highlightOnPlay: false,
};

OverlayInlinePlayer.propTypes = {
  className: PropTypes.string,
  image: PropTypes.shape({}),
  title: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string,
  songUrl: PropTypes.string,
  fileName: PropTypes.string,
  uniquePlayerId: PropTypes.string,
  square: PropTypes.bool,
  visible: PropTypes.oneOf(["hover", "always"]),
  highlightOnPlay: PropTypes.bool,
  isDisabled: PropTypes.bool
};

OverlayInlinePlayer.defaultProps = {};
