import React from 'react'
import './remix-group-view.scss'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import { get } from 'lodash'
import createProvider from "../../store/provider"
import { connect } from "react-redux"
import RemixContent from "./containers/remix-content"
import RemixHero from "./containers/remix-hero"
import RemixSidebar from "./containers/remix-sidebar"
import { denormalizeResponse } from '../../common/http'

const cn = new Bem({
  name: 'remix-group-view',
  prefix: 'pfx-'
})

class RemixGroupView extends React.Component {

  static propTypes = {
    isCurrentUserAdmin: PropTypes.bool,
    isCurrentUserGroupMember: PropTypes.bool,
    screenBreakPointIndex: PropTypes.number,
    isCurrentUserGroupFollower: PropTypes.bool,
    groupData: PropTypes.shape({
      createdAt: PropTypes.string,
      description: PropTypes.string,
      followersCount: PropTypes.number,
      contestantsCount: PropTypes.number,
      groupLink: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
      tracksCount: PropTypes.number,
      campaign: PropTypes.shape({
        artistName: PropTypes.string,
        description: PropTypes.string,
        endDate: PropTypes.string,
        heroImageUrl: PropTypes.string,
        id: PropTypes.number,
        startDate: PropTypes.string,
        trackCoverUrl: PropTypes.string,
        trackFileUrl: PropTypes.string,
        videoUrl: PropTypes.string,
        trackName: PropTypes.string,
        winnerAnnouncementAt: PropTypes.string,
        videoMp4Url: PropTypes.string,
        videoWebmUrl: PropTypes.string
      }),
      comments: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        createdAt: PropTypes.string,
        id: PropTypes.number,
        status: PropTypes.number,
        user: PropTypes.shape({
          avatarSmallUrl: PropTypes.string,
          id: PropTypes.number,
          profileLink: PropTypes.string,
          username: PropTypes.string,
        })
      })),
      contestants: PropTypes.arrayOf(PropTypes.shape({
        avatarSmallUrl: PropTypes.string,
        id: PropTypes.number,
        profileLink: PropTypes.string,
        username: PropTypes.string
      })),
      news: PropTypes.arrayOf(PropTypes.shape({
        content: PropTypes.string,
        createdAt: PropTypes.string,
        id: PropTypes.number,
        title: PropTypes.string,
        updatedAt: PropTypes.string,
      }))
    })
  }

  static mapStateToProps = (state) => {
    return {
      screenBreakPointIndex: get(state, 'app.screenBreakPointIndex'),
      currentUser: get(state, 'backendData.currentUser')
    }
  }

  static CONTESTANTS_TO_SHOW_BY_BREAKPOINT = [24, 24, 20, 20, 20]
  static SHOW_ACCORDION_BY_BREAKPOINT = [false, false, false, true, true]

  constructor(props) {
    super(props)
    this.state = {
      groupData: denormalizeResponse(props.groupData)
    }
  }

  render() {
    const {
      isCurrentUserAdmin,
      isCurrentUserGroupMember,
      screenBreakPointIndex,
      currentUser,
      isCurrentUserGroupFollower
    } = this.props
    const { groupData } = this.state

    const {
      createdAt,
      followersCount,
      contestantsCount,
      groupLink: groupId,
      tracksCount: publishedTrackCount,
      campaign: {
        artistName,
        description: campaignDescription,
        endDate,
        heroImageUrl,
        trackCoverUrl,
        trackFileUrl,
        videoUrl,
        trackName,
        winnerAnnouncementAt,
        slug,
        artistDescription,
        subtitle,
        name,
        videoMp4Url,
        videoWebmUrl,
        trackDuration,
        trackWaveformUrl
      },
      contestants,
      news: initialUpdates,
      tracks: initialTracks,
      comments: initialComments,
      id,
    } = groupData

    const {
      CONTESTANTS_TO_SHOW_BY_BREAKPOINT,
      SHOW_ACCORDION_BY_BREAKPOINT
    } = RemixGroupView

    const contestantsToShow = CONTESTANTS_TO_SHOW_BY_BREAKPOINT[screenBreakPointIndex]
    const isAccordionEnabled = SHOW_ACCORDION_BY_BREAKPOINT[screenBreakPointIndex]

    return (
      <div className={cn()}>
        <RemixHero
          endDate={endDate}
          winnerAnnouncementAt={winnerAnnouncementAt}
          heroImageUrl={heroImageUrl}
          followersCount={followersCount}
          publishedTrackCount={publishedTrackCount}
          currentUser={currentUser}
          isCurrentUserGroupFollower={isCurrentUserGroupFollower}
          groupId={groupId}
          isCurrentUserAdmin={isCurrentUserAdmin}
          isCurrentUserGroupMember={isCurrentUserGroupMember}
          id={id}
          slug={slug}
          subtitle={subtitle}
          name={name}
          videoMp4Url={videoMp4Url}
          videoWebmUrl={videoWebmUrl}
        />
        <div className={cn('main', null, 'container container--wider')}>
          <div className={cn('main-row', null, 'row')}>
            <div className={cn('main-content', null, 'col-xs-9')}>
              <RemixContent
                campaignDescription={campaignDescription}
                canPostUpdates={isCurrentUserAdmin}
                canDeleteComments={isCurrentUserAdmin}
                currentUser={currentUser}
                isMember={isCurrentUserGroupMember}
                groupId={groupId}
                slug={slug}
                initialComments={initialComments}
                initialTracks={initialTracks}
                initialUpdates={initialUpdates}
              />
            </div>
            <div className={cn('main-sidebar', null, 'col-xs-3')}>
              <RemixSidebar
                trackDuration={trackDuration}
                trackDirectWaveformUrl={trackWaveformUrl}
                trackName={trackName}
                artistName={artistName}
                trackCoverUrl={trackCoverUrl}
                trackFileUrl={trackFileUrl}
                videoUrl={videoUrl}
                artistDescription={artistDescription}
                contestants={contestants}
                contestantsCount={contestantsCount}
                isAccordionEnabled={isAccordionEnabled}
                contestantsToShow={contestantsToShow}
                groupId={groupId}
                screenBreakPointIndex={screenBreakPointIndex}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default createProvider(
  connect(RemixGroupView.mapStateToProps, null)(RemixGroupView)
)
