import React from "react";
import BEM from "react-bem-helper";
import PropTypes from "prop-types";
import "./style.scss";
import createProvider from "@store/provider";
import { connect } from "react-redux";
import { pause, play, stop } from "@store/actions/player";
import { createIsPlayingSelector } from "./inline-player.selectors";
import { PlayButton } from "../play-button";

const bem = BEM({
  prefix: "pfx-",
  name: "inline-player",
});

class InlinePlayer extends React.Component {
  componentDidUpdate(prevProps, props) {
    const { isPlaying, onPlayChange } = this.props;
    const { isPlaying: prevIsPlaying } = prevProps;

    if (isPlaying !== prevIsPlaying) {
      onPlayChange?.({ isPlaying });
    }
  }

  onClick = (e) => {
    e.preventDefault()
    this.props.stop()
    if (!this.props.isPlaying) {
      this.props.play({
        songUrl: this.props.songUrl,
        fileName: this.props.fileName,
        id: this.props.id,
        playerId: this.props.uniquePlayerId
      })
    }
  };

  render() {
    const { size, isPlaying, isDisabled } = this.props;

    return (
      <PlayButton
        size={size && size !== "normal" ? "inherit" : size}
        isPlaying={isPlaying}
        isDisabled={isDisabled}
        className={bem("", { playing: isPlaying, [size]: !!size }).className}
        buttonClassName={bem("icon").className}
        onClick={this.onClick}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    isPlaying: createIsPlayingSelector(ownProps.songUrl, ownProps.uniquePlayerId)(state),
  };
};

const mapDispatch = { play, stop, pause };

export default createProvider(
  connect(mapState, mapDispatch)(InlinePlayer, false)
);

InlinePlayer.propTypes = {
  visible: PropTypes.oneOf(["hover", "always"]),
  id: PropTypes.string,
  uniquePlayerId: PropTypes.string, // pass so the player can be uniquely identified relative to other players playing the same file (used to prevent 2 players playing the same file showing as playing)
  songUrl: PropTypes.string,
  fileName: PropTypes.string,
  onPlayChange: PropTypes.func,
  size: PropTypes.oneOf(["normal", "large"]),
  isDisabled: PropTypes.bool
};
