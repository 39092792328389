import { WEBPACK_DEFINE } from "./consts";

class Logger {
  name = "Soundation";

  styles = {};

  loggers = {
    log: console.log,
    warn: console.log,
    error: console.log,
  };

  constructor({ name, styles = {}, enable = false }) {
    this.name = name;

    this.styles = Object.entries({
      color: "#41ead4",
      background: "black",
      border: "1px solid 41ead4",
      padding: "2px",
      ...styles,
    })
      .map(([key, value]) => `${key}: ${value}`)
      .join(";");

    if (WEBPACK_DEFINE.ENV !== "LOCAL" && !enable) {
      Object.keys(this.loggers).forEach((key) => {
        this.loggers[key] = () => {};
      });
    }
  }

  getMessage(...args) {
    return [`%c[${this.name}]`, this.styles, ...args];
  }

  log(...args) {
    this.loggers.log(...this.getMessage(...args));
  }

  warn(...args) {
    this.loggers.warn(...this.getMessage(...args));
  }

  error(...args) {
    this.loggers.error(...this.getMessage(...args));
  }
}

export { Logger };
