import { memo } from 'react'
import BottomSheet from '@components/bottom-sheet'
import Modal from '@components/modal'
import createProvider from '@store/provider'
import connect from 'react-redux/es/connect/connect'
import { isMobileSelector } from '@store/selectors/app'
import * as styles from './ModalOrBottomSheet.module.scss'

// renders modal on desktop, bottom sheet on mobile
export const ModalOrBottomSheet = memo(({ open, closeShareModal, isMobile, children, windowClassName }) => {
  return (
    isMobile
      ? <BottomSheet onClose={closeShareModal} isOpen={open}>{children}</BottomSheet>
      : <Modal windowClassName={windowClassName} onCloseButtonClick={closeShareModal} isOpen={open} headerClassName={styles.hideShareModalHeader}>{children}</Modal>
  )
})

const mapStateToProps = (state) => {
  return {
    isMobile: isMobileSelector(state)
  }
}
export default createProvider(connect(mapStateToProps, null)(ModalOrBottomSheet))
