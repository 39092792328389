import React from 'react'
import { noop } from 'lodash'
import { MAX_IMAGE_SIZE_MB } from '@common/consts'
import Linkton from '@components/linkton'
import Uploader from '@components/uploader/uploader'
import * as styles from './ImageUploader.module.scss'

const ImageUploader = (props) => {
  const { onUploadRequest = noop, onUploadRejected = noop, uploaded = false, uploadProgress = 0, maxSizeKbs = MAX_IMAGE_SIZE_MB * 1024, className = '', existingPictureUrl, name } = props

  const [pictureUrl, setPictureUrl] = React.useState(existingPictureUrl)

  const renderPlaceholder = () => {
    const classes = pictureUrl ? styles.placeholderWithImage : styles.placeholder
    const style = pictureUrl ? { backgroundImage: `url("${pictureUrl}")` } : {}

    return (
      <div className={classes} style={style}>
        <Linkton icon='upload' iconPlacement='left' type='full' size='medium'>{pictureUrl ? 'Change image' : 'Upload'}</Linkton>
        {!pictureUrl && <div className={styles.placeholderDropArea}>Or drop file here.</div>}
      </div>
    )
  }

  const handleUploadRequested = async (file) => {
    setPictureUrl(file.preview)
    return onUploadRequest(file)
  }

  return (
    <Uploader
      className={className}
      name={name}
      theme='checkered'
      mime='.png,.jpeg,.jpg,.webp,.svg,.gif'
      moreThanOneErrorMessage={'You can upload only one image'}
      dropzoneIcon={'new-share1'}
      details={`JPG, PNG, WebP, SVG, or GIF with a maximum file size of ${maxSizeKbs / 1024}mb.`}
      placeholder={renderPlaceholder()}
      onUploadRequest={handleUploadRequested}
      onRejected={onUploadRejected}
      uploaded={uploaded}
      progress={uploadProgress}
      maxSize={maxSizeKbs * 1024}
      filename=''
    />
  )
}

export default ImageUploader
