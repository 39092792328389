class StorageService {
    baseKey
    storage

    constructor({ key, storage = localStorage }) {
        this.baseKey = key
        this.storage = storage
    }

    get = (prefix = '') => {
        const value = this.parse(this.storage.getItem(this.keyWithPrefix(prefix)))
        return value
    }

    set = (value, prefix = '') => {
        const stringified = this.stringify(value)
        if (stringified) {
            this.storage.setItem(this.keyWithPrefix(prefix), stringified)
        }
    }

    remove = (prefix = '') => {
        this.storage.removeItem(this.keyWithPrefix(prefix))
    }

    stringify = (value) => {
        try {
            return JSON.stringify(value) || null
        } catch (ex) {
            return null
        }
    }

    parse = (stringToParse = null) => {
        try {
            return !stringToParse ? null : (JSON.parse(stringToParse))
        } catch (e) {
            return null
        }
    }

    keyWithPrefix = (prefix = '') => {
        return `${prefix}${this.baseKey}`
    }
}

export default StorageService
