import React, { Component } from 'react'
import { Bem } from '@common/utils'
import { reportUser } from '@common/http'
import { LINKS, COMMUNITY_BREAKPOINT } from '@common/consts'
import Linkton from '@components/linkton'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import DottedMenu from '@components/dotted-menu'
import Icon from '@components/icon'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import CommunityMessageUser from '@containers/community/community-message-user'
import CommunityShareModal from '@containers/community/community-share-modal'
import PropTypes from 'prop-types'
import ChangeImageButton from '../change-image-button'
import { withTooltip } from '../with-tooltip'
import './style.scss'

const cn = Bem({
  prefix: 'pfx-',
  name: 'profile-hero'
})

const ChangeImageButtonWithTooltip = withTooltip(ChangeImageButton)

class ProfileHero extends Component {
  state = {
    followLoading: false,
    isContextMenuOpen: false,
    isChatOpen: false,
    isShareOpen: false
  }

  render () {
    const {
      userId,
      userName,
      profileLink,
      avatarUrl,
      canChangeCoverImage,
      onCoverImageChange,
      isCurrentUser,
      coverPhotoUrl,
      coverPhotoUploadProgress,
      canDelete,
      canReport
    } = this.props
    const { isContextMenuOpen, isChatOpen, isShareOpen } = this.state

    const style = {}
    if (coverPhotoUrl) {
      style.backgroundImage = `url(${coverPhotoUrl})`
    }

    const userPage = window.location.origin + LINKS.USER_PAGE.replace(':profileLink', profileLink)

    return (
      <div className={cn(null, null, 'container')}>
        <div className={cn('cover-photo-container')} style={style}>
          <div className={cn('top')}>
            {
              isCurrentUser && <div>
                {
                  canChangeCoverImage
                    ? <div className={cn('change-image-wrapper')}><ChangeImageButton className={cn('change-image')} btnClassName={cn('change-image-btn')} onChange={onCoverImageChange} label='Change cover image' uploadProgress={coverPhotoUploadProgress} /></div>
                    : <ChangeImageButtonWithTooltip wrapperClassName={cn('change-image-wrapper')} className={cn('change-image')} btnClassName={cn('change-image-btn')} label='Change cover image' disabled tooltip={{ content: this.getUpgradeTooltipContent(), placement: 'bottom-right', className: cn('change-image-tooltip') }} />
                }
              </div>
            }
          </div>

          <div className={cn('middle')}>
            <div className={cn('info-avatar')} style={{ backgroundImage: 'url(' + avatarUrl + ')' }} />
          </div>
        </div>

        <div className={cn('bottom')}>
          <div className={cn('actions')}>
            { this.getActions() }
          </div>

          {(canDelete || canReport) && (
            <DropdownOrBottomSheet
              dropdownClassName={cn('context-menu')}
              dropdownProps={{ align: 'end' }}
              isOpen={isContextMenuOpen}
              toggler={this.renderToggler()}
              onToggle={this.toggleContextMenu}
            >
              {canDelete && (
                <a className={cn('context-menu-item')} href={`${LINKS.USER}/${userId}?referer=${window.origin}/${LINKS.USERS}`} data-confirm='Are you 110% sure you want to destroy this user?' data-method='delete' rel='nofollow'>
                  <Icon icon='trash' size={20} />
                  <span>Delete</span>
                </a>
              )}
              {canReport && (
                <div className={cn('context-menu-item')} onClick={this.reportUser}>
                  <Icon icon='Block' size={20} />
                  <span>Report</span>
                </div>
              )}
            </DropdownOrBottomSheet>
          )}
        </div>

        <CommunityMessageUser isOpen={isChatOpen} userId={userId} userName={userName} onClose={this.closeChat} />
        <CommunityShareModal
          isOpen={isShareOpen}
          title={userName}
          link={userPage}
          facebookLink={encodeURIComponent(userPage)}
          twitterLink={encodeURIComponent(userPage)}
          onClose={this.closeShare}
        />
      </div>
    )
  }

  reportUser = () => {
    const { userGuid } = this.props

    reportUser(userGuid).then(() => {
      window.location.href = LINKS.USERS
    })
  }

  renderToggler = () => {
    const { isDesktop } = this.props

    return (
      isDesktop
        ? <DottedMenu className={cn('dotted-menu')} />
        : <Linkton className={cn('actions-item', { 'with-margin': true })} type='outline' icon='dots-menu' iconPlacement='left'>More</Linkton>
    )
  }

  getActions () {
    const actions = []
    const { isCurrentUser, currentUserFollows, isCurrentUserAdmin, onEditAccountClick, isLoggedIn } = this.props
    const { followLoading } = this.state

    if (!isCurrentUser && isLoggedIn) {
      if (currentUserFollows) {
        actions.push(<Linkton isLoading={followLoading} className={cn('actions-item')} icon='check' iconPlacement='left' type='outline' onClick={() => this.requestFollowChange(false)}>Following</Linkton>)
      } else {
        actions.push(<Linkton isLoading={followLoading} className={cn('actions-item')} icon='plus' iconPlacement='left' type='outline' onClick={() => this.requestFollowChange(true)}>Follow</Linkton>)
      }
      actions.push(<Linkton type='outline' className={cn('actions-item')} icon='chat' iconPlacement='left' onClick={this.openChat}>Message</Linkton>)
    }
    actions.push(<Linkton type='outline' className={cn('actions-item')} icon='Share' iconPlacement='left' onClick={this.openShare}>Share</Linkton>)

    if (isCurrentUser || isCurrentUserAdmin) {
      actions.push(
        <Linkton
          className={cn('actions-item')}
          icon='new-pencil-edit'
          iconPlacement='left'
          key='edit'
          type='outline'
          onClick={onEditAccountClick}
        >
          Edit
        </Linkton>
      )
    }

    return actions
  }

  openChat = () => {
    this.setState({ isChatOpen: true })
  }
  closeChat = () => {
    this.setState({ isChatOpen: false })
  }

  openShare = () => {
    this.setState({ isShareOpen: true })
  }
  closeShare = () => {
    this.setState({ isShareOpen: false })
  }

  toggleContextMenu = () => {
    this.setState({ isContextMenuOpen: !this.state.isContextMenuOpen })
  }

  requestFollowChange (newValue) {
    const { onFollowChangeRequested } = this.props
    this.setState({ followLoading: true })
    onFollowChangeRequested(newValue).finally(() => {
      this.setState({ followLoading: false })
    })
  }

  getUpgradeTooltipContent () {
    const { onUpgradeAccountClick } = this.props
    return <div className={cn('upgrade-tooltip')}>
      <p className={cn('upgrade-tooltip-text')}>Upgrade to change cover image</p>
      <div className={cn('upgrade-tooltip-button-container')}><Linkton padding={75} onClick={onUpgradeAccountClick}>Upgrade</Linkton></div>
    </div>
  }
}

ProfileHero.propTypes = {
  userId: PropTypes.number.isRequired,
  userGuid: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  coverPhotoUrl: PropTypes.string,
  isCurrentUser: PropTypes.bool.isRequired,
  isCurrentUserAdmin: PropTypes.bool.isRequired,
  currentUserFollows: PropTypes.bool.isRequired,
  canChangeCoverImage: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  canReport: PropTypes.bool.isRequired,
  onFollowChangeRequested: PropTypes.func,
  onUpgradeAccountClick: PropTypes.func,
  onEditAccountClick: PropTypes.func,
  onCoverImageChange: PropTypes.func,
  coverPhotoUploadProgress: PropTypes.number,
  isLoggedIn: PropTypes.bool.isRequired,
  isDesktop: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => {
  return {
    isDesktop: state.app.windowSize >= COMMUNITY_BREAKPOINT
  }
}

export default createProvider(connect(mapStateToProps)(ProfileHero))
