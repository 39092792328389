import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'

const cn = new Bem({
  name: 'text-separator',
  prefix: 'pfx-'
})

const TextSeparator = (props) => {
  const { text } = props

  return (
    <div className={cn(null, null, props.className)}>
      <div className={cn('line')} />
      <div className={cn('text')}>{text}</div>
      <div className={cn('line')} />
    </div>
  )
}

TextSeparator.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string
}
TextSeparator.defaultProps = {
  text: 'or'
}

export default TextSeparator
