import React, { Component } from 'react'
import { Bem } from '@common/utils'
import PropTypes from 'prop-types'
import Icon from '../icon'
import { withTooltip } from '../with-tooltip'
import Linkton, { COLOR } from '../linkton'
import './style.scss'

const cn = Bem({
  prefix: 'pfx-',
  name: 'create-group-button'
})

const AnchorWithTooltip = withTooltip('a')
const LinktonWithTooltip = withTooltip(Linkton)

export default class CreateGroupButton extends Component {

  static propTypes = {
    isAllowed: PropTypes.bool.isRequired,
    onUpgradeAccountClick: PropTypes.func,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['hollow', 'gradient']),
  }

  getUpgradeTooltipContent () {
    const { onUpgradeAccountClick } = this.props

    return <div className={cn('upgrade-tooltip')}>
      <p className={cn('upgrade-tooltip-text')}>Upgrade to create a group and unlock more features.</p>
      <Linkton color={COLOR.BLACK} onClick={onUpgradeAccountClick} className={cn('upgrade-tooltip-button')}>Go premium</Linkton>
    </div>
  }

  renderHollow () {
    const { isAllowed, onClick } = this.props

    return <div className={cn('', {disabled: !isAllowed})}>
      {
        isAllowed
          ? <a className={cn('link')} onClick={onClick}>
            <Icon icon={'new-thin-plus'} size={14} className={cn('link-icon')}/>
            Create group
          </a>
          : <AnchorWithTooltip className={cn('link')} tooltip={{placement: 'bottom-right', content: this.getUpgradeTooltipContent()}}>
            <Icon icon={'new-thin-plus'} size={14} className={cn('link-icon')}/>
            Create group
          </AnchorWithTooltip>
      }
    </div>
  }

  renderGradient () {
    const { isAllowed, onClick } = this.props

    if (isAllowed) {
      return <Linkton onClick={onClick}>
        <div className={cn('content')}>
          <Icon icon={'plus'} size={14} className={cn('link-icon')} />
          Create group
        </div>
      </Linkton>
    } else {
      return <LinktonWithTooltip isDisabled tooltip={{ placement: 'bottom-right', content: this.getUpgradeTooltipContent() }}>
        <div className={cn('content')}>
          <Icon icon={'plus'} size={14} className={cn('link-icon')} />
          Create group
        </div>
      </LinktonWithTooltip>
    }
  }

  render () {
    const { type } = this.props

    if (type === 'hollow') {
      return this.renderHollow()
    } else if (type === 'gradient') {
      return this.renderGradient()
    }
  }
}