import React, { Component } from 'react'
import './style.scss'
import { Bem } from '@common/utils'
import PropTypes from 'prop-types'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'with-tooltip'
})

export const withTooltip = (WrappedComponent) =>
  class WithTooltip extends Component {
    static defaultStateDerived = {
      content: '',
      delay: {
        show: 500,
        hide: 100
      },
      placement: 'top'
    }

    static defaultState = {
      open: false,
      openStage: 'closed'
    }

    static propTypes = {
      tooltip: PropTypes.shape({
        content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
        delay: PropTypes.shape({
          show: PropTypes.number,
          hide: PropTypes.number
        }),
        placement: PropTypes.oneOf([
          'top', 'right', 'bottom', 'left',
          'top-left', 'top-right', 'top-span',
          'bottom-left', 'bottom-right', 'bottom-span',
          'left-top', 'left-bottom', 'left-span',
          'right-top', 'right-bottom', 'right-span'
        ])
      })
    }

    constructor (props) {
      super(props)
      this.state = Object.assign({}, this.mapPropsToState(props), WithTooltip.defaultState)
    }

    mapPropsToState (props) {
      return Object.assign({}, WithTooltip.defaultStateDerived, props.tooltip)
    }

    componentWillReceiveProps (nextProps) {
      this.setState(this.mapPropsToState(nextProps))
    }

    render () {
      const { open, placement, content } = this.state
      const { tooltip, wrapperClassName, ...otherProps } = this.props

      return (<div
        className={cn('wrapper', null, wrapperClassName)}
        onMouseOver={this.requestOpen.bind(this)}
        onMouseOut={this.requestClose.bind(this)}
      >
        <WrappedComponent {...otherProps} />
        <div className={cn('box', { open, [placement]: true }, tooltip.className)}>
          { content }
        </div>
      </div>)
    }

    open () {
      this.setState({
        open: true,
        openStage: 'opened'
      })
    }

    requestOpen () {
      const { openStage, delay } = this.state

      if (openStage !== 'openRequested') {
        this.setState({
          openStage: 'openRequested'
        })
        setTimeout(() => {
          const { openStage } = this.state
          if (openStage === 'openRequested') {
            this.open()
          }
        }, delay.show
        )
      }
    }

    close () {
      this.setState({
        open: false,
        openStage: 'closed'
      })
    }

    requestClose () {
      const { openStage, delay } = this.state

      if (openStage !== 'closeRequested') {
        this.setState({
          openStage: 'closeRequested'
        })
        setTimeout(() => {
          const { openStage } = this.state
          if (openStage === 'closeRequested') {
            this.close()
          }
        }, delay.hide)
      }
    }
  }
