// extracted by mini-css-extract-plugin
var _1 = "pfx-EditProfile-module__actions--wbWTD";
var _2 = "pfx-EditProfile-module__avatar--pztuF";
var _3 = "pfx-EditProfile-module__avatar-btn--PBXLO";
var _4 = "pfx-EditProfile-module__avatar-container--fw12z";
var _5 = "pfx-EditProfile-module__avatar-initial--VaQGt";
var _6 = "pfx-EditProfile-module__avatar-initial-text--IlleG";
var _7 = "pfx-EditProfile-module__avatar-placeholder--SOz_g";
var _8 = "#000";
var _9 = "1260px";
var _a = "1020px";
var _b = "668px";
var _c = "1550px";
var _d = "pfx-EditProfile-module__button--rRhtb";
var _e = "pfx-EditProfile-module__container--fymcd";
var _f = "1140px";
var _10 = "940px";
var _11 = "100%";
var _12 = "15px";
var _13 = "620px";
var _14 = "#f53535";
var _15 = "rgba(0,0,0,.2)";
var _16 = "rgba(255,255,255,.38)";
var _17 = ".38";
var _18 = "pfx-EditProfile-module__error--guYLm";
var _19 = "rgba(255,255,255,.2)";
var _1a = "#0025ff";
var _1b = "pfx-EditProfile-module__fineprint--kcA0a";
var _1c = "pfx-EditProfile-module__form--OjZS9";
var _1d = "pfx-EditProfile-module__glitch-anim--I03c5";
var _1e = "pfx-EditProfile-module__glitch-anim-2--X_qO_";
var _1f = "#f60";
var _20 = "#41ead4";
var _21 = "#2cdd78";
var _22 = "#66ab23";
var _23 = "#f5f5f5";
var _24 = "#c3c3c3";
var _25 = "#ececec";
var _26 = "#252525";
var _27 = "#272727";
var _28 = "#c6c6c5";
var _29 = "#333";
var _2a = "#3c3c3c";
var _2b = "#9c9b9b";
var _2c = "#8c8c8c";
var _2d = "#6f6f6e";
var _2e = "#3c3c3b";
var _2f = "#373737";
var _30 = "#6a6a69";
var _31 = "#6f6f6f";
var _32 = "#9f9e9e";
var _33 = "#282828";
var _34 = "#828181";
var _35 = "#1d1d1d";
var _36 = "#979797";
var _37 = "#9c9b9b";
var _38 = "#a7a7a6";
var _39 = "#c6c6c5";
var _3a = "#d8d8d7";
var _3b = "#dddddc";
var _3c = "72px";
var _3d = "40px";
var _3e = "36px";
var _3f = "25px";
var _40 = "18px";
var _41 = "13px";
var _42 = "pfx-EditProfile-module__inner-container--B0ZwN";
var _43 = "#0025ff";
var _44 = "rgba(255,255,255,.12)";
var _45 = "#6535f5";
var _46 = "2px";
var _47 = "56px";
var _48 = "56px";
var _49 = "#41ead4";
var _4a = "#f60";
var _4b = "#f83600";
var _4c = "#881f00";
var _4d = "18px";
var _4e = "15px";
var _4f = "pfx-EditProfile-module__pfx-icon--BZi3X";
var _50 = "red";
var _51 = "pfx-EditProfile-module__slug--ZWQuD";
var _52 = "13px";
var _53 = "rgba(255,255,255,.07)";
var _54 = "pfx-EditProfile-module__title--rDGXj";
var _55 = "#35465c";
var _56 = "#55acee";
var _57 = "#fff";
var _58 = "#ffce36";
var _59 = "#ffcd00";
var _5a = "red";
export { _1 as "actions", _2 as "avatar", _3 as "avatarBtn", _4 as "avatarContainer", _5 as "avatarInitial", _6 as "avatarInitialText", _7 as "avatarPlaceholder", _8 as "black", _9 as "breakLarge", _a as "breakMedium", _b as "breakSmall", _c as "breakVLarge", _d as "button", _e as "container", _f as "containerLarge", _10 as "containerMedium", _11 as "containerMobile", _12 as "containerPaddingMobile", _13 as "containerSmall", _14 as "danger", _15 as "darkElement", _16 as "disabled", _17 as "disabledOpacity", _18 as "error", _19 as "extraLightElement", _1a as "facebook", _1b as "fineprint", _1c as "form", _1d as "glitchAnim", _1e as "glitchAnim2", _1f as "google", _20 as "green", _21 as "green2", _22 as "greenColor", _23 as "grey1", _24 as "grey10", _25 as "grey2", _26 as "grey25", _27 as "grey27", _28 as "grey3", _29 as "grey33", _2a as "grey3C", _2b as "grey4", _2c as "grey41", _2d as "grey5", _2e as "grey6", _2f as "grey61", _30 as "grey6A", _31 as "grey6F", _32 as "grey7", _33 as "grey8", _34 as "grey82", _35 as "grey9", _36 as "grey97", _37 as "grey9C", _38 as "greyA7", _39 as "greyC6", _3a as "greyD8", _3b as "greyDd", _3c as "header1", _3d as "header2", _3e as "header3", _3f as "header4", _40 as "header5", _41 as "header6", _42 as "innerContainer", _43 as "klein", _44 as "lightElement", _45 as "lilac", _46 as "menuBorder", _47 as "menuHeight", _48 as "menuMobileHeight", _49 as "ocean", _4a as "orange", _4b as "orange2", _4c as "orangeDark", _4d as "paragraph", _4e as "paragraphSmall", _4f as "pfxIcon", _50 as "red", _51 as "slug", _52 as "subHeaderSmall", _53 as "subtleLightElement", _54 as "title", _55 as "tumblr", _56 as "twitter", _57 as "white", _58 as "yellow", _59 as "yellowDeep", _5a as "youtube" }
