import { Component } from 'react'
import BEM from 'react-bem-helper'
import Linkton, { COLOR, HOVER, SIZE, TAG, TYPE } from '../linkton'
import './style.scss'
import BackgroundVideo from '../background-video'
import ScrollBait from '../scroll-bait'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import { get } from '@common/utils'
import { LINKS } from '@common/consts'
import { canUseDOM } from '../../common/utils'
import {AspectRatio} from "../../common/imageDimensions";

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-hero'
})

const images = {
  desktop: {
    src: 'https://cdn.soundation.com/rtc_landing/hero-desktop-collab-still.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/hero-desktop-collab-still.jpg',
    alt: 'Hero Image',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/hero-desktop-collab-still.webp'))
  },
  tablet: {
    src: 'https://cdn.soundation.com/rtc_landing/hero-tablet-collab-still.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/hero-tablet-collab-still.jpg',
    alt: 'Hero Image',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/hero-tablet-collab-still.webp'))
  },
  mobile: {
    src: 'https://cdn.soundation.com/rtc_landing/hero-mobile-collab-still.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/hero-mobile-collab-still.jpg',
    alt: 'Hero Image',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/hero-mobile-collab-still.webp'))
  }
}

class LandingHero extends Component {

  getVideo = () => {
    if (this.props.screenBreakPointIndex < 2) {
      return [
        'https://cdn.soundation.com/rtc_landing/hero-desktop-collab.webm',
        'https://cdn.soundation.com/rtc_landing/hero-desktop-collab.mp4'
      ]
    } else if (this.props.screenBreakPointIndex < 3) {
      return [
        'https://cdn.soundation.com/rtc_landing/hero-tablet-collab.webm',
        'https://cdn.soundation.com/rtc_landing/hero-tablet-collab.mp4'
      ]
    } else if (this.props.screenBreakPointIndex < 4 || this.props.orientation === 'landscape') {
      return [
        'https://cdn.soundation.com/rtc_landing/hero-tablet-collab-lq.webm',
        'https://cdn.soundation.com/rtc_landing/hero-tablet-collab-lq.mp4'
      ]
    } else {
      return [
        'https://cdn.soundation.com/rtc_landing/hero-mobile-collab.webm',
        'https://cdn.soundation.com/rtc_landing/hero-mobile-collab.mp4'
      ]
    }
  }

  getPoster = () => {
    if (this.props.screenBreakPointIndex < 2) {
      return images.desktop
    } else if (this.props.screenBreakPointIndex < 4 || this.props.orientation === 'landscape') {
      return images.tablet
    } else {
      return images.mobile
    }
  }

  render () {
    const poster = this.getPoster()
    const style = canUseDOM() ? {'--vh': window.innerHeight / 100 + 'px'} : {}
    return (
      <div {...bem('', {halloweekBanner: this.props.isHalloweek && !this.props.halloweekBannerDissmissed})} style={style}>
        <div {...bem('background')}>
          <div
            {...bem('background-filler', 'left') }
          />
          <div
            {...bem('video-overcontainer')}
            style={{
              maxWidth: 'calc(100vh * ' + poster.aspectRatio.value + ')'
            }}
          >
            <div
              {...bem('video-container')}
              style={{
                paddingTop: poster.aspectRatio.toPaddingTop()
              }}
            >
              <BackgroundVideo
                urls={ this.getVideo() }
                poster={ poster }
                {...bem('video')}
                />
            </div>
          </div>
          <div
            {...bem('background-filler', 'right')}
          />
        </div>
        <div {...bem('content-container')}>
          <div {...bem('content')}>
            <div {...bem('content-filler-1')} />
            <h1 {...bem('header')}>Make music online.</h1>
            <div {...bem('sub-header')}>Get creative and make songs directly in your browser.</div>
            <Linkton
              size={this.props.screenBreakPointIndex < 2 ? SIZE.EXTRA_LARGE : SIZE.MEDIUM}
              type={TYPE.FULL}
              color={COLOR.OCEAN}
              hover={HOVER.OUTLINE}
              padding={100}
              tag={TAG.ANCHOR}
              href={LINKS.AUTH_PLANS}
              {...bem('button')}
            >Join for free</Linkton>
            <div {...bem('content-filler-2')} />
          </div>
        </div>
        <ScrollBait {...bem('scroll-bait')} />
      </div>
    )
  }

}

const mapStateToProps = (state) => ({
  screenBreakPointIndex: get(state, 'app.screenBreakPointIndex'),
  orientation: get(state, 'app.windowSize') >= get(state, 'app.windowHeight') ? 'landscape' : 'portrait',
  isHalloweek: get(state, 'campaign.isHalloweek'),
  halloweekBannerDissmissed: get(state, 'app.halloweekBannerDissmissed'),
})

export default createProvider(
  connect(mapStateToProps)(LandingHero)
)
