import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { LINKS } from '@common/consts'
import { getUserNotifications, fetchNewConversations, markNotificationAsRead, denormalizeResponse } from '@common/http'
import usePaginate from '@hooks/usePaginate'
import useOpen from '@hooks/useOpen'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import connect from 'react-redux/es/connect/connect'
import NotificationsModal from '@containers/notifications-modal'
import Logo, { LOGO_TYPES } from '@components/logo'
import Icon from '@components/icon'
import { DropdownControlled } from '@components/dropdown'
import Linkton from '@components/linkton'
import rtcClient from '@root/rtc-client'
import CommunityUserMenu from '../community-user-menu'
import CommunitySearch from '../community-search'
import * as styles from './CommunityHeader.module.scss'

function CommunityHeader(props) {
  const { footer = {}, showSearch = true, explicitHomeLink = false } = props

  const currentUser = useSelector(selectCurrentUser)

  const [newNotificationsCount, setNewNotificationsCount] = useState(0)
  const [newConversationsCount, setNewConversationsCount] = useState(0)

  const footerLinks = footer.links?.map(({ externalLink, name }) => ({ label: name, url: externalLink.url })) ?? []

  // fetch new messages count
  useEffect(() => {
    if (currentUser) {
      fetchNewConversations().then(({ data }) => {
        setNewConversationsCount(data.meta.total)
      })
    }
  }, [currentUser])

  const fetchNotifications = useCallback(async ({ page }) => {
    const { data } = await getUserNotifications({ page, items: 25 })
    setNewNotificationsCount(data.meta?.unread_count ?? 0)
    return { data }
  }, [])
  let { isLoading: isLoadingNotifications, data, fetchNextPage: fetchNextPageNotifications, nextPage: notificationsNextPage, reset: resetNotifications } = usePaginate({ fetcher: fetchNotifications })
  const notifications = denormalizeResponse(data)

  const refetchNotifications = useCallback(() => {
    resetNotifications()
    fetchNextPageNotifications()
  }, [resetNotifications, fetchNextPageNotifications])

  useEffect(() => {
    rtcClient.on('new-notification', refetchNotifications)

    return () => {
      rtcClient.off('new-notification', refetchNotifications)
      rtcClient.destroy()
    }
  }, [fetchNextPageNotifications, resetNotifications])

  // fetch user notifications
  useEffect(() => {
    if (currentUser) fetchNextPageNotifications()
  }, [currentUser])

  const { isOpen: isNotificationsOpen, close: closeNotifications, setIsOpen: setIsNotificationsOpen } = useOpen()
  const handleMarkRead = (id) => {
    markNotificationAsRead(id)
    setNewNotificationsCount(cur => cur - 1)
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <div className={styles.leftSection}>
          <div className={styles.hamburgerMenu}>
            <CommunityUserMenu
              currentUser={currentUser}
              includeNavigation={false}
              footerLinks={footerLinks}
              mode='hamburger'
            />
          </div>
          <Logo className={styles.logo} type={LOGO_TYPES.ICON} url={LINKS.COMMUNITY} />
          {!!explicitHomeLink && <Linkton className={styles.homeLink} tag='anchor' type='outline' href={LINKS.COMMUNITY} icon='Home-ver-2' iconPlacement='left'>Home</Linkton>}
        </div>

        {!!showSearch && <CommunitySearch className={styles.search} />}

        <div className={`${styles.rightSection} ${!!currentUser && styles.rightSectionAuth}`}>
          {!!showSearch && (
            <div className={styles.togglerContainer}>
              <a href={LINKS.COMMUNITY_SEARCH}><Icon icon='search' className={styles.mobileSearchIcon} size={21} /></a>
            </div>
          )}

          {currentUser ? (
            <Fragment>
              <div className={styles.togglerContainer}>
                <a href={LINKS.MESSAGES}>
                  <Icon size={21} className={styles.messagesIcon} icon='e-mail' />
                </a>
                {!!newConversationsCount && <div className={styles.activityIndicator} />}
              </div>
              <div className={styles.togglerContainer}>
                {!!newNotificationsCount && <div className={styles.activityIndicator} />}
                <DropdownControlled
                  isOpen={isNotificationsOpen}
                  onToggle={setIsNotificationsOpen}
                  closeOnInsideClick={false}
                  align='end'
                  toggle={<Icon icon='notification' size={21} className={styles.notificationsIcon} />}
                >
                  <NotificationsModal
                    notifications={notifications}
                    newNotificationsCount={newNotificationsCount}
                    isLoading={isLoadingNotifications}
                    nextPage={notificationsNextPage}
                    fetchNextPage={fetchNextPageNotifications}
                    onMarkRead={handleMarkRead}
                    onClose={closeNotifications}
                  />
                </DropdownControlled>
              </div>

              <div className={styles.avatarMenu}>
                <CommunityUserMenu
                  currentUser={currentUser}
                  avatarUrl={currentUser.avatarSmallUrl}
                  includeNavigation={false}
                  footerLinks={footerLinks}
                  mode='dropdown'
                />
              </div>
            </Fragment>
          ) : (
            <div className={styles.guestButtons}>
              <Linkton className={styles.guestButton} tag='anchor' type='outline' href={LINKS.AUTH_SIGN_IN}>Log in</Linkton>
              <Linkton className={styles.guestButton} tag='anchor' href={LINKS.AUTH_SIGN_UP}>Sign up</Linkton>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default createProvider(connect(null, null)(CommunityHeader))
