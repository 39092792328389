// THIS COMPONENT IS NOT RECOMMENDED WAY TO INCLUDE VIDEOS
// PLEASE USE IT ONLY FOR BACKGROUND VIDEOS THAT ARE MUTED AND HAVE A NEED OF AUTOPLAY
// FOR THE REST PLEASE USE NATIVE <VIDEO> TAG

// REASON FOR THIS COMPONENT IS THE FACT THAT REACT IGNORES MUTED ATTRIBUTE
// MORE INFO HERE https://github.com/facebook/react/issues/10389
import React from 'react'
import PropTypes from 'prop-types';
import {Bem, canUseDOM} from '../../common/utils';
import './style.scss'
import {noop, isEqual} from 'lodash'

const cn = new Bem({
  name: 'background-video',
  prefix: 'pfx-'
});

class BackgroundVideo extends React.Component {
  state = {
    loaded: false
  }

  instantiateVideo = () => {
    if (canUseDOM()) {
      const video = document.createElement('video');
      const {urls} = this.props

      video.autoplay = true;
      video.loop = true;
      video.muted = true; // fixes autoplay in chrome
      video.setAttribute('playsinline', 'true'); // fixes autoplay in webkit (ie. mobile safari)
      video.oncanplay = () => this.setState({loaded: true})

      urls.forEach((path) => {
        if (path) {
          const ext = path.split('.').pop().toLowerCase()
          const source = document.createElement('source');
          source.src = path;
          source.type = `video/${ext}`;
          video.appendChild(source);
        }
      })

      this.videoContainer.appendChild(video);
    }
  }

  componentDidMount() {
    this.instantiateVideo()
  }

  componentDidUpdate (prevProps) {
    if (!isEqual(prevProps.urls, this.props.urls)) {
      const children = [...this.videoContainer.childNodes]
      for (const child of children) {
        if (child.tagName === 'VIDEO') {
          this.videoContainer.removeChild(child)
        }
      }
      this.instantiateVideo()
    }
  }

  render() {
    const {className, poster, onClick} = this.props
    const {loaded} = this.state

    return (
      <div
        className={cn(null, {loaded}, className)}
        onClick={() => onClick()}
        ref={(ref) => {
          this.videoContainer = ref;
        }}>
        {
          !!poster
          && <ImgWithFallback
            className={cn('poster')}
            src={poster.src}
            fallbackSrc={poster.fallbackSrc}
            alt={poster.alt} />
        }
      </div>
    );
  }
}

BackgroundVideo.propTypes = {
  className: PropTypes.string,
  poster: PropTypes.object,
  urls: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func
};
BackgroundVideo.defaultProps = {
  onClick: noop
};

export default BackgroundVideo;
