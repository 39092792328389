import * as React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import { noop } from 'lodash'
import './text-area.scss'

const cn = new Bem({
  name: 'text-area',
  prefix: 'pfx-'
})

const TextArea = React.forwardRef((props, ref) => {
  const { className, onChange, layout, isTransparent, isModern, fontSize, ...restProps } = props

  return (
    <textarea
      ref={ref}
      {...restProps}
      className={cn(null, { [layout]: true, transparent: isTransparent, modern: isModern, smallFont: fontSize === 'small' }, className)}
      onChange={(e) => onChange(e.target.value)}
    />
  )
})

export const TextAreaUncontrolled = React.forwardRef((props, ref) => {
  const [value, setValue] = React.useState(props.value)

  const handleChange = React.useCallback((v) => {
    setValue(v)
    props.onChange(v)
  }, [props.onChange])

  return (
    <TextArea ref={ref} {...props} value={value} onChange={handleChange} />
  )
})

TextArea.propTypes = {
  onChange: PropTypes.func,
  layout: PropTypes.oneOf(['light', 'dark']),
  isModern: PropTypes.bool, // use for newer layouts implemented in the community in 2024
  fontSize: PropTypes.oneOf(['small', 'medium']),
  isTransparent: PropTypes.bool
}

TextArea.defaultProps = {
  onChange: noop,
  layout: 'dark',
  isModern: false,
  isTransparent: true,
  fontSize: 'medium'
}

export default TextArea
