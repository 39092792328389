import { Component } from "react";
import { Bem } from "@common/utils";
import PropTypes from "prop-types";
import { sendAnalyticsEvent } from "@common/analytics";
import { GTM_EVENTS } from "@common/consts";
import "./style.scss";

const cn = new Bem({
  prefix: "pfx-",
  name: "video-player",
});

const isVideoFile = (url = "") => {
  return [".mp4"].some((fe) => url.endsWith(fe));
};

export default class VideoPlayer extends Component {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    embed: PropTypes.bool,
  };

  static defaultProps = {
    embed: true,
  };

  componentDidMount() {
    const { videoUrl } = this.props;
    sendAnalyticsEvent(GTM_EVENTS.GTM_PLAYED_VIDEO, videoUrl);
  }

  renderVideo = () => {
    const { videoUrl } = this.props;

    if (isVideoFile(videoUrl)) {
      return (
        <video
          autoPlay={false}
          controls={true}
          className={cn("video")}
          src={videoUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
      );
    }

    return (
      <iframe
        className={cn("video")}
        src={videoUrl}
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    );
  };

  render() {
    return <div className={cn()}>{this.renderVideo()}</div>;
  }
}
