import axios from "axios";
import { unescapeHtml } from "./utils";
import mapKeysDeep from "map-keys-deep-lodash";
import { camelCase, now } from "lodash";
import { ENDPOINTS, PROJECT_SORT_BY } from "./consts";
import buildUrl from "build-url";
import JsonApiSerializer from "jsonapi-serializer";

export const defaultOptions = {
  transformResponse: [
    function (data) {
      let response;
      try {
        response = mapKeysDeep(JSON.parse(unescapeHtml(data)), (val, key) =>
          camelCase(key)
        );
      } catch (e) {
        response = data;
      }
      return response;
    },
  ],
};

export const getCsrfToken = () => {
  return $('meta[name="csrf-token"]').attr('content')
}

// a sync version for denormalizing response. Useful for server-side rendering, plus it gives us full control over denormalizing the response.
export function denormalizeResponse({ data, included }) {
  if (!data) return data

  /** Points to the relationship object. This method is circular-structures friendly. */
  function addRelationships(item, includedItems) {
    const getRelationshipFromIncluded = (relationship) => includedItems.find((ii) => ii.type === relationship.type && ii.id === relationship.id) ?? relationship

    Object.keys(item.relationships).forEach((relationKey) => {
      const relationship = item.relationships[relationKey]?.data

      if (relationship) {
        item[relationKey] = Array.isArray(relationship) ? relationship.map(getRelationshipFromIncluded) : getRelationshipFromIncluded(relationship)
      }
    })

    delete item.relationships
  }

  function addAttributes(item) {
    return { id: item.id, type: item.type, selfLink: item.links?.self, ...(item.attributes ?? {}), relationships: item.relationships }
  }

  const dataItems = Array.isArray(data) ? data.map(addAttributes) : addAttributes(data)
  const includedItems = included?.map(addAttributes) ?? [];

  [...(Array.isArray(dataItems) ? dataItems : [dataItems]), ...includedItems].forEach((item) => {
    if (item.relationships) addRelationships(item, includedItems)
  })

  return dataItems
}

const deserializer = new JsonApiSerializer.Deserializer({
  keyForAttribute: "camelCase",
});

const axiosWithDeserializer = axios.create({
  baseURL: "/",
});

axiosWithDeserializer.interceptors.response.use(async (response) => {
  const deserialized = await deserializer.deserialize(response.data);
  return { ...response, data: deserialized };
});

export async function updateConsents(payload) {
  return axios.post(ENDPOINTS.CONSENTS, payload, defaultOptions);
}

export async function createUser(formData) {
  return axios.post(ENDPOINTS.USERS, formData, defaultOptions);
}

export async function createOrganization(data) {
  return axios.post(ENDPOINTS.ORGANIZATIONS, data, defaultOptions);
}

export async function login(formData) {
  return axios.post(ENDPOINTS.USERS_SIGN_IN, formData, defaultOptions);
}

export async function logout() {
  return axios.get(ENDPOINTS.LOGOUT, null, defaultOptions);
}

export async function resetPassword(formData) {
  return axios.post(ENDPOINTS.USER_PASSWORD, formData, defaultOptions);
}

export async function updatePasswod(formData) {
  return axios.put(ENDPOINTS.USER_PASSWORD, formData, defaultOptions);
}

export async function createCharge({
  product_ids,
  payment_method_id,
  payment_intent_id,
}) {
  return axios.post(
    ENDPOINTS.CHARGE,
    { product_ids, payment_method_id, payment_intent_id },
    defaultOptions
  );
}

export async function createSubscription({
  account_type,
  period_name,
  coupon,
  referral_id,
  payment_method_id,
  payment_intent_id,
}) {
  return axios.post(
    ENDPOINTS.SUBSCRIPTION,
    {
      account_type,
      period_name,
      coupon,
      referral_id,
      payment_method_id,
      payment_intent_id,
    },
    defaultOptions
  );
}

export async function createPaymentIntent({
  account_type,
  period_name,
  coupon,
  referral_id,
  payment_method_id,
}) {
  return axios.post(
    ENDPOINTS.PAYMENT_INTENT,
    { account_type, period_name, coupon, referral_id, payment_method_id },
    defaultOptions
  );
}

export async function removeProduct(id) {
  return axios.get(`${ENDPOINTS.CART_REMOVE}/${id}`, null, defaultOptions);
}

export async function getDataLayer() {
  return axios.get(`${ENDPOINTS.DATA_LAYER}/`, null, defaultOptions);
}

export async function checkSubscriptionPrice({
  code,
  period,
  accountType,
  referralId,
}) {
  const url = buildUrl({
    path: ENDPOINTS.CHECK_SUBSCRIPTION_PRICE,
    queryParams: {
      code,
      period,
      account_type: accountType,
      referral_id: referralId,
    },
  });
  return axios.get(url, defaultOptions);
}

export async function getGroupComments(groupId, { page, items, startFrom }) {
  const url = buildUrl({
    path: `${ENDPOINTS.GROUPS}/${groupId}/comments`,
    queryParams: {
      page: page || 1,
      items: items || 5,
      start_from: startFrom || now(),
    },
  });

  return axios.get(url, null, defaultOptions);
}

export async function getGroupMixdowns(
  groupLink,
  { page, items, startFrom, sortBy, sortOrder }
) {
  const url = buildUrl({
    path: `${ENDPOINTS.GROUPS}/${groupLink}/tracks`,
    queryParams: {
      page: page || 1,
      items: items || 5,
      start_from: startFrom || now(),
      column: sortBy || "created_at",
      order: sortOrder || "desc",
    },
  });

  return axios.get(url, null, defaultOptions);
}

export async function getGroupTracks(
  groupLink,
  { type, page, items, startFrom, sortBy, sortOrder }
) {
  const url = buildUrl({
    path: `${ENDPOINTS.GROUP_TRACKS.replace(':group_link', groupLink)}`,
    queryParams: {
      type: type || '',
      page: page || 1,
      items: items || 5,
      start_from: startFrom || now(),
      column: sortBy || "created_at",
      order: sortOrder || "desc",
    },
  });

  return axios.get(url, null, defaultOptions);
}

export async function reportGroupComment(groupId, commentId, reason) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/comments/${commentId}/report`,
    { reason },
    defaultOptions
  );
}

export async function reportUser(userId) {
  const reason = window.prompt('Please describe why you want to report this user')

  if (reason) {
    await axios.post(`${ENDPOINTS.USER}/${userId}/report`, {
      reason,
      authenticity_token: getCsrfToken()
    })
  } else {
    if (reason != null) {
      window.alert('Error! You need to tell us why you are reporting this user.')
    }
  }
}

export async function postGroupComment(groupId, content) {
  return axios.post(
    ENDPOINTS.GROUP_COMMENTS.replace(':group_id', groupId),
    { content },
    defaultOptions
  );
}

export async function getGroupNews(groupId) {
  return axios.get(`${ENDPOINTS.GROUPS}/${groupId}/news`, null, defaultOptions);
}

export async function postGroupNews(groupId, { content, title }) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/news`,
    { content, title },
    defaultOptions
  );
}

export async function deleteGroupNews(groupId, newsId) {
  return axios.delete(
    `${ENDPOINTS.GROUPS}/${groupId}/news/${newsId}`,
    null,
    defaultOptions
  );
}

export async function getNewsComments(newsId, { page, items = 20 }) {
  return axios.get(ENDPOINTS.NEWS_COMMENTS.replace(':news_id', newsId), { params: { page, items } }, defaultOptions);
}

export async function postNewsComment(newsId, content) {
  return axios.post(ENDPOINTS.NEWS_COMMENTS.replace(':news_id', newsId), { content }, defaultOptions);
}

export async function likeNewsItem(newsId) {
  return axios.post(ENDPOINTS.NEWS_LIKES.replace(':news_id', newsId), null, defaultOptions);
}

export async function dislikeNewsItem(newsId) {
  return axios.delete(ENDPOINTS.NEWS_LIKES.replace(':news_id', newsId), null, defaultOptions);
}

export async function deleteGroupTrack(groupId, trackId) {
  return axios.delete(
    `${ENDPOINTS.GROUPS}/${groupId}/tracks/${trackId}`,
    null,
    defaultOptions
  );
}

export async function followGroup(groupId) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/follows`,
    null,
    defaultOptions
  );
}

export async function unfollowGroup(groupId) {
  return axios.delete(
    `${ENDPOINTS.GROUPS}/${groupId}/follows`,
    null,
    defaultOptions
  );
}

export async function acceptGroupInvitation(groupLink) {
  return axios.post(ENDPOINTS.GROUP_INVITATION_ACCEPT.replace(':group_link', groupLink), null, defaultOptions);
}

export async function deleteGroupComment(groupId, commentId) {
  return axios.delete(
    `${ENDPOINTS.GROUPS}/${groupId}/comments/${commentId}`,
    null,
    defaultOptions
  );
}

// for deleting a comment on a track
export async function deleteComment(commentId) {
  return axios.delete(
    `${ENDPOINTS.COMMENTS}/${commentId}`,
  )
}

export async function sendGroupNewsNotification(groupId, newsId) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/news/${newsId}/notifications`,
    null,
    defaultOptions
  );
}

export async function markNotificationAsRead(id) {
  return axios.put(`${ENDPOINTS.NOTIFICATIONS}/${id}`, { notification: { read: true } }, defaultOptions);
}

export async function getUserTrack() {
  return axios.get(`${ENDPOINTS.ACCOUNTS}/tracks`, null, defaultOptions);
}

export async function getUserTracks(options) {
  const { page = 1, items = 5 } = options ?? {};
  return axios.get(`${ENDPOINTS.MY_TRACKS}`, { params: { page, items }, withCredentials: true, headers: { Accept: 'application/json' } }, defaultOptions);
}

export async function getTracksForUser(userId, { page, items }) {
  return axios.get(`${ENDPOINTS.USER_TRACKS.replace(':id', userId)}`, { params: { page, items } }, defaultOptions);
}

export async function getUserGroups(userId, { page, items }) {
  return axios.get(`${ENDPOINTS.USER_GROUPS.replace(':id', userId)}`, { params: { page, items } }, defaultOptions);
}

export async function getRecentGroups({ page, items }) {
  return axios.get(ENDPOINTS.RECENT_GROUPS, { params: { page, items } }, defaultOptions);
}

export async function getTrendingGroups({ page, items }) {
  return axios.get(ENDPOINTS.TRENDING_GROUPS, { params: { page, items } }, defaultOptions);
}

export async function getFeaturedGroups({ page, items }) {
  return axios.get(ENDPOINTS.FEATURED_GROUPS, { params: { page, items } }, defaultOptions);
}

export async function getUserFollowers(userId, { page, items }) {
  return axios.get(`${ENDPOINTS.USER_FOLLOWERS.replace(':id', userId)}`, { params: { page, items } }, defaultOptions);
}

export async function getUserFollowing(userId, { page, items }) {
  return axios.get(`${ENDPOINTS.USER_FOLLOWINGS.replace(':id', userId)}`, { params: { page, items } }, defaultOptions);
}

// tracks by groups and users which the user follows
export async function getFollowedTracks(options) {
  const { page = 1, items = 5 } = options ?? {};
  return axios.get(`${ENDPOINTS.FEED}?type=tracks`, { params: { page, items }, withCredentials: true, headers: { Accept: 'application/json' } }, defaultOptions);
}

export async function getRecentTracks({ page, items }) {
  return axios.get(ENDPOINTS.RECENT_TRACKS, { params: { page, items } }, defaultOptions);
}

export async function getTrendingTracks({ page, items }) {
  return axios.get(ENDPOINTS.TRENDING_TRACKS, { params: { page, items } }, defaultOptions);
}

export async function getNewsFeed({ page = 1, items }) {
  return axios.get(`${ENDPOINTS.FEED}?type=news`, { withCredentials: true, headers: { Accept: 'application/json' }, params: { page, items } }, defaultOptions);
}

export async function getTutorialsFeed({ page = 1, items }) {
  return axios.get(`${ENDPOINTS.FEED}?type=tutorials`, { withCredentials: true, headers: { Accept: 'application/json' }, params: { page, items } }, defaultOptions);
}

export async function getNewsItem(newsId) {
  return axios.get(`${ENDPOINTS.NEWS_ITEM.replace(':id', newsId)}`, null, defaultOptions)
}

export async function getTrackComments(trackId, { page, items }) {
  return axios.get(`${ENDPOINTS.TRACKS}/${trackId}/comments`, { params: { page, items }, withCredentials: true, headers: { Accept: 'application/json' } }, defaultOptions)
}

export async function postTrackComment(trackId, content) {
  return axios.post(ENDPOINTS.TRACK_COMMENTS.replace(':track_id', trackId), { content }, defaultOptions)
}

export async function postTrackToGroup(groupId, mixdownId) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/group_tracks`,
    { mixdown_id: mixdownId },
    defaultOptions
  );
}

export async function getAccountTracksByGroup(groupId) {
  return axios.get(
    `${ENDPOINTS.ACCOUNTS}/groups/${groupId}/tracks`,
    {},
    defaultOptions
  );
}

export async function registerUpload(file) {
  return await axios.post(
    ENDPOINTS.FILES,
    { content_length: file.size },
    defaultOptions
  );
}

export async function getHalloweekData() {
  return axios.get(`${ENDPOINTS.HALLOWEEK}/`, null, defaultOptions);
}

export async function uploadFile(file, data, onUploadProgress) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = (e) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", data.uploadUrl, true);
      xhr.setRequestHeader("Content-Type", file.type);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          xhr.status === 200 ? resolve(data.filename) : reject(xhr.status);
        }
      };
      xhr.upload.addEventListener("progress", onUploadProgress);
      xhr.send(e.target["result"]);
    };
  });
}

export async function getSignatureForFileUpload(nameOfFile, contentType) {
  const { data } = await axios.get(ENDPOINTS.UPLOADS_SIGN, { params: { filename: nameOfFile, content_type: contentType } });

  const { filename, ...fields } = data.fields
  const { headers, method, url } = data

  return {
    headers,
    method,
    url,
    fields,
    filename
  }
}

export async function uploadFileNew(file, onUploadProgress) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    if (typeof onUploadProgress === "function") {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          onUploadProgress(event.loaded / event.total);
        }
      });
    }

    xhr.open("POST", ENDPOINTS.UPLOADS, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        xhr.status === 200 ? resolve(xhr.response) : reject(xhr.responseText || xhr.response || xhr.statusText);
      }
    };

    xhr.send(formData);
  });
}

export async function uploadToS3({ fields, headers, url, method, file, onProgress }) {
  const form = new FormData()

  const fieldKeys = Object.keys(fields)
  for (const key of fieldKeys) {
    form.append(key, fields[key])
  }
  form.append('file', file)

  await axios[method](url, form, {
    headers,
    onUploadProgress: onProgress
  })
}

export async function getUserTrackByGroup(groupId) {
  return axios.get(
    `${ENDPOINTS.ACCOUNTS}/groups/${groupId}/tracks`,
    null,
    defaultOptions
  );
}

export async function likeTrack(trackId) {
  return axios.post(`${ENDPOINTS.TRACKS}/${trackId}/likes`);
}

export async function dislikeTrack(trackId) {
  return axios.delete(`${ENDPOINTS.TRACKS}/${trackId}/likes`);
}

export async function joinGroup(groupId) {
  return axios.post(
    `${ENDPOINTS.GROUPS}/${groupId}/membership`,
    null,
    defaultOptions
  );
}

export async function leaveGroup(groupId) {
  return axios.delete(
    `${ENDPOINTS.GROUPS}/${groupId}/membership`,
    null,
    defaultOptions
  );
}

export async function getDownloadStemsUrl(groupId) {
  return await axios.get(
    `${ENDPOINTS.GROUPS}/${groupId}/stems_download_links/new`
  );
}

export async function incrementPlays(mixdownId) {
  try {
    return axios.post(ENDPOINTS.INC_PLAYS.replace(':id', mixdownId), {
      withCredentials: true,
    })
  } catch (e) {
    console.error('error incrementing play:', e)
  }
}

export async function followUser(profileLink) {
  return axios.post(
    `${ENDPOINTS.USERS_API}/${profileLink}/follows`,
    null,
    defaultOptions
  );
}

export async function unfollowUser(profileLink) {
  return axios.delete(
    `${ENDPOINTS.USERS_API}/${profileLink}/follows`,
    null,
    defaultOptions
  );
}

export async function updateAccount(data) {
  return axios.patch(ENDPOINTS.ACCOUNT, data, defaultOptions);
}

export async function createGroup(data) {
  return axios.post(ENDPOINTS.GROUPS, data, defaultOptions);
}

export async function editGroup(id, data) {
  return axios.put(`${ENDPOINTS.GROUPS}/${id}`, data, defaultOptions);
}

export async function getAccountReferral() {
  return axios.get(`${ENDPOINTS.ACCOUNT_REFERRAL}`);
}

export async function getFeaturedTracks({ page, items } = {}) {
  return axios.get(`${ENDPOINTS.FEATURED_TRACKS}`, { params: { page, items } }, defaultOptions);
}

export async function getAccountReferredUsers({ page, items }) {
  const url = buildUrl({
    path: ENDPOINTS.ACCOUNT_REFERRED_USERS,
    queryParams: {
      page: page || 1,
      items: items || 5,
    },
  });

  return axios.get(url);
}

export async function fetchConversations({ page }) {
  return axios.get(`${ENDPOINTS.CONVERSATIONS}`, { params: { page, items: 25 } }, defaultOptions);
}
export async function fetchConversation({ id }) {
  return axios.get(`${ENDPOINTS.CONVERSATION.replace(':id', id)}`, defaultOptions);
}
export async function fetchConversationMessages({ id, page }) {
  return axios.get(`${ENDPOINTS.CONVERSATION_MESSAGES.replace(':id', id)}`, { params: { page, items: 20 } }, defaultOptions);
}
export async function fetchNewConversations() {
  return axios.get(`${ENDPOINTS.NEW_CONVERSATIONS}`, defaultOptions);
}
export async function markConversationAsRead(id) {
  return axios.put(`${ENDPOINTS.CONVERSATION_MARK_AS_READ.replace(':id', id)}`, null, defaultOptions);
}
export async function sendConversationMessage({ conversationId, content }) {
  return axios.post(`${ENDPOINTS.MESSAGES}`, {
    message: {
      conversation_id: conversationId, content
    }
  }, defaultOptions);
}
export async function sendUserMessage(userId, message) {
  return axios.post(`${ENDPOINTS.MESSAGES}`, {
    message: {
      user_id: userId, content: message
    }
  }, defaultOptions);
}

export async function updateAccountProfile({ userGuid, data }) {
  const obj = {}
  const keyMapping = {
    pageUrlSlug: 'profile_link',
    description: 'about',
    facebookLink: 'facebook',
    twitterLink: 'twitter',
    instagramLink: 'instagram',
    tiktokLink: 'tiktok',
    youtubeLink: 'youtube',
    coverPhoto: 'cover_photo',
    username: 'username',
    realName: 'real_name',
    email: 'email',
    avatar: 'profile_image'
  }
  Object.keys(data).forEach((key) => {
    if ([undefined].includes(data[key])) return
    obj[keyMapping[key]] = key === 'coverPhoto' ? JSON.stringify(data[key]) : data[key]
  })

  return axios.put(`${ENDPOINTS.USERS_API}/${userGuid}`, { user: obj })
}

export async function getUserNotifications(params) {
  return axios.get(ENDPOINTS.NOTIFICATIONS, { params })
}

export async function getGroupMembers(groupId, params) {
  return axios.get(ENDPOINTS.GROUPS_MEMBERS.replace(':group_link', groupId), { params })
}

export async function searchCommunity({ term, entities, pageSize, page, sortBy, ...rest }) {
  return axios.get(ENDPOINTS.SEARCH_COMMUNITY, {
    params: {
      items: pageSize,
      page,
      types: entities,
      q: term,
      // different entities use different sortBy params, that's why we can't sort by if there's more than 1 entity
      sort: entities.length === 1 ? sortBy : undefined,
      ...rest
    }
  })
}

export async function editMixdown({ id, data }) {
  return axios.put(ENDPOINTS.EDIT_MIXDOWN.replace(':id', id), data)
}

export async function deleteMixdown({ id }) {
  return axios.delete(ENDPOINTS.EDIT_MIXDOWN.replace(':id', id))
}

export async function getTrendingUsers({ items, page }) {
  return axios.get(ENDPOINTS.TRENDING_USERS, { params: { items, page } })
}

export async function getTopUsers({ items, page }) {
  return axios.get(ENDPOINTS.TOP_USERS, { params: { items, page } })
}

export async function getSurveys() {
  return axios.get(ENDPOINTS.SURVEYS)
}

export async function getBanners() {
  return axios.get(ENDPOINTS.BANNERS)
}

export async function getPieces({ items, page, sort = 'updated_at', q = '' }) {
  return axios.get(ENDPOINTS.PIECES, {
    params: {
      items,
      page,
      workspaces: false,
      q,
      order: sort === PROJECT_SORT_BY.alphabetical.value ? 'ASC' : 'DESC',
      sort
    }
  })
}

export async function enableTemplateForProject(projectId) {
  return axios.post(ENDPOINTS.PROJECT_TEMPLATES, {
    project_template: { project_id: projectId }
  })
}

export async function disableTemplateForProject(templateId) {
  return axios.delete(`${ENDPOINTS.PROJECT_TEMPLATES}/${templateId}`)
}

export async function getProject(projectId) {
  return axios.get(`${ENDPOINTS.PROJECTS}/${projectId}`)
}

export async function deleteProject(projectId) {
  return axios.delete(`${ENDPOINTS.PROJECTS}/${projectId}`)
}

export async function updateProject(projectId, project) {
  return axios.put(`${ENDPOINTS.PROJECTS}/${projectId}`, { project })
}

export async function cloneProject(projectId) {
  return axios.post(ENDPOINTS.CLONE_PROJECT.replace(':id', projectId), {}, { withCredentials: true })
}

export async function removeProjectMember(projectId, memberId) {
  return axios.delete(ENDPOINTS.PROJECT_MEMBER.replace(':project_id', projectId).replace(':id', memberId))
}

export async function deleteSoloProject(songId) {
  return axios.delete(`${ENDPOINTS.SONGS}/${songId}`)
}
