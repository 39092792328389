import { MAX_MENU_ITEMS } from "./MegaMenu.consts";

export const filteredMenuItems = (items = []) => {
  let filteredItems = (items || []).filter(
    ({ menuAttached }) => menuAttached !== false
  );

  const showReadMore = (filteredItems?.length || 0) > MAX_MENU_ITEMS;

  filteredItems = (filteredItems || []).slice(0, MAX_MENU_ITEMS);

  return { filteredItems, showReadMore };
};

export const shouldOnlyUseFirstNonWrapper = ({ items, type } = {}) => {
  return (items?.length || 0) === 1 && !items[0].items?.length;
};

export const prepareNavItem = (item = {}) => {
  const { filteredItems } = filteredMenuItems(item.items);
  const hasItems = (filteredItems?.length || 0) > 0;

  item.items = filteredItems || []

  const noDropdown = shouldOnlyUseFirstNonWrapper(item);

  const finalItem = noDropdown ? filteredItems[0] : item;

  const isWrapper = item.type === "WRAPPER";

  return {
    item: finalItem,
    isLinkable: noDropdown || !hasItems || !isWrapper,
    items: noDropdown || !hasItems ? null : filteredItems,
  };
};
