import React from 'react'
import PropTypes from 'prop-types'
import Image from '@components/image'
import { Bem, Enum } from '../../common/utils'
import { LINKS } from '../../common/consts'
import Icon from '../icon/index'
import Button from '../button/index'
import './style.scss'

const cn = new Bem({
  name: 'social-icons',
  prefix: 'pfx-'
})

const PROVIDERS = new Enum('INSTAGRAM', 'TIKTOK', 'YOUTUBE', 'FACEBOOK', 'TWITTER', 'TUMBLR', 'GOOGLE', 'DISCORD')

const SocialIcons = (props) => {
  const ICON_LIST = {
    FACEBOOK: {
      image: 'https://cdn.soundation.com/images/facebook_secondary.svg',
      icon: PROVIDERS.FACEBOOK,
      url: LINKS.SOCIAL.FACEBOOK,
      label: 'Facebook'
    },
    TWITTER: {
      image: 'https://cdn.soundation.com/images/X.svg',
      icon: PROVIDERS.TWITTER,
      url: LINKS.SOCIAL.TWITTER,
      label: 'Twitter'
    },
    TUMBLR: {
      icon: PROVIDERS.TUMBLR,
      url: LINKS.SOCIAL.TUMBLR,
      label: 'Tumblr'
    },
    YOUTUBE: {
      icon: 'youtube2021',
      url: LINKS.SOCIAL.YOUTUBE,
      label: 'Youtube'
    },
    INSTAGRAM: {
      icon: 'instagram2021',
      url: LINKS.SOCIAL.INSTAGRAM,
      label: 'Instagram'
    },
    TIKTOK: {
      image: 'https://cdn.soundation.com/images/tiktok.svg',
      url: LINKS.SOCIAL.TIKTOK,
      label: 'Tiktok'
    },
    GOOGLE: {
      icon: PROVIDERS.GOOGLE,
      url: LINKS.SOCIAL.GOOGLE,
      label: 'Google Plus'
    },
    DISCORD: {
      icon: PROVIDERS.DISCORD,
      url: LINKS.SOCIAL.DISCORD,
      label: 'Discord'
    }
  }

  let {
    className,
    iconSize,
    iconBoxSize,
    providers = [
      PROVIDERS.FACEBOOK,
      PROVIDERS.TWITTER,
      PROVIDERS.YOUTUBE,
      PROVIDERS.INSTAGRAM
    ],
    type = 'hollow',
    showLabels = false,
    urls = {},
    iconsDistance = 20
  } = props

  if (!iconSize) iconSize = showLabels ? 40 : 18
  if (!iconBoxSize) iconBoxSize = iconSize

  const iconStyle = {
    width: `${iconBoxSize}px`,
    height: `${iconBoxSize}px`,
    marginRight: `${iconsDistance}px`
  }

  const renderIcons = (providersList = providers) => {
    return providersList.map((provider, i) => {
      let style = iconStyle
      if (i === providersList.length - 1) style.marginRight = '0'

      const listItem = ICON_LIST[provider.toUpperCase()]
      const url = urls[provider] || listItem.url

      const renderIcon = (button = false) => {
        const className = cn('root', button ? { [provider]: provider, icon: true } : null)
        const handler = button ? () => window.open(url, '_blank') : undefined
        return listItem.image ? (
          <div className={cn('image-container')} style={{ width: style.width, height: style.height, marginRight: style.marginRight }}>
            <Image key={i} src={listItem.image} style={{ width: iconSize, height: iconSize }} className={className} onClick={handler} />
          </div>
        ) : (
          <Icon
            key={i}
            className={className}
            onClick={handler}
            icon={listItem.icon}
            size={iconSize}
            type='blank'
            style={{ width: style.width, height: style.height, marginRight: style.marginRight }}
          />
        )
      }

      if (showLabels) {
        return (
          <Button
            type={type}
            key={i}
            onClick={() => window.open(url, '_blank')}
            size='extra-small'
            className={cn('root', { [provider]: provider, button: true })}>
            <span>
              { listItem.label }
            </span>
            {renderIcon(false)}
          </Button>
        )
      } else {
        return renderIcon(true)
      }
    })
  }
  return (
    <div className={`${cn(null, { column: showLabels })} ${className}`}>
      { renderIcons() }
    </div>
  )
}

SocialIcons.propTypes = {
  providers: PropTypes.arrayOf(PropTypes.oneOf([
    PROVIDERS.FACEBOOK,
    PROVIDERS.TWITTER,
    PROVIDERS.YOUTUBE,
    PROVIDERS.INSTAGRAM,
    PROVIDERS.DISCORD
  ])),
  type: PropTypes.string,
  urls: PropTypes.object,
  showLabels: PropTypes.bool,
  iconSize: PropTypes.number,
  iconBoxSize: PropTypes.number,
  iconsDistance: PropTypes.number
}

export default SocialIcons
