import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Icon from '@components/icon'
import { Bem, get, getCookie, setCookie } from '@common/utils'
import { getSurveys } from '@common/http'
import { createPopup } from '@typeform/embed'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'

import './styles.scss'

const cn = new Bem({
  name: 'popover-survey',
  prefix: 'pfx-'
})

const HIDE_SURVEYS_COOKIE_NAME = '_soundation_com_hide_surveys'

class PopoverSurvey extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      popupForm: null
    }
    this.containerRef = null
    this.setContainerRef = element => this.containerRef = element
  }

  componentDidMount() {
    const { isDesktop, currentUser } = this.props
    const hideSurveys = getCookie(HIDE_SURVEYS_COOKIE_NAME)
    if (!currentUser || !isDesktop || hideSurveys) return

    getSurveys().then((response) => {
      const { data } = response.data
      if (!data) return

      const { formId } = data.attributes
      if (!formId) return

      const container = this.containerRef

      const popupForm = createPopup(formId, {
        container,
        hideHeaders: false,
        hideFooter: true,
        width: 420,
        height: 450,
        open: 'load',
        hidden: {
          user_id: currentUser.id
        },
        onReady: () => {
          this.setState({
            popupForm,
            show: true
          })
        }
      })

      window.popupform = popupForm
    })
  }

  onCloseButtonClick = () => {
    this.setState({ show: false })
    setCookie(HIDE_SURVEYS_COOKIE_NAME, true, 1)
    this.state.popupForm.close()
  }

  onMouseEnter = () => {
    document.body.style.overflowY = 'hidden'
  }

  onMouseLeave = () => {
    document.body.style.overflowY = 'auto'
  }

  render() {
    const { show } = this.state

    return (
      <div
        className={cn(null, { show })}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        ref={this.setContainerRef}
      >
        <a
          className={'typeform-close'}
          onClick={this.onCloseButtonClick}
        >
          <Icon icon='new-close' size={16} className={cn('close')} />
        </a>
      </div >
    )
  }
}

PopoverSurvey.propTypes = {
  isDesktop: PropTypes.bool,
  currentUser: PropTypes.any
}

PopoverSurvey.defaultProps = {
  isDesktop: false
}

const mapStateToProps = (state) => ({
  isDesktop: (get(state, 'app.screenBreakPointIndex') < 2),
  currentUser: selectCurrentUser(state)
})

export default createProvider(connect(mapStateToProps)(PopoverSurvey))
