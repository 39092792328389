import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import {Parser} from 'html-to-react'
import './admin-content.scss'

const cn = new Bem({
  name: 'admin-content',
  prefix: 'pfx-'
})

class AdminContent extends React.Component {

  constructor(props) {
    super(props)
    this.htmlToReactParser = new Parser();
    this.state = {
      reactElements: this.htmlToReactParser.parse(props.html)
    }
    
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      reactElements: this.htmlToReactParser.parse(nextProps.html)
    })
  }

  render () {
    const {className} = this.props
    const {reactElements} = this.state

    return (
      <div className={cn(null, null, className)}>
        {reactElements}
      </div>
    )
  }
}

AdminContent.propTypes = {
  html: PropTypes.string
}

AdminContent.defaultProps = {
  html: ''
}

export default AdminContent
