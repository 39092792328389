import { Bem } from '@common/utils'
import './top-controls.scss'
import Icon from '../../icon/index'

const cn = new Bem({
  name: 'carousel-top',
  prefix: 'pfx-'
})

export default ({
  title,
  onViewAllClick,
  track,
  shouldRenderTopControlsActions,
  viewsToShow,
  viewsCount,
  activeIndices,
  theme,
  iconSize=16,
  indicateActive=false
}) => {
  const autoMode = viewsToShow === 'auto'
  const currentMinIndex = activeIndices.length ? Math.min(...activeIndices) : 0
  const currentMaxIndex = activeIndices.length ? Math.max(...activeIndices) : 0
  const lastAvailableIndex = viewsCount - 1
  let maxNextPageStep = lastAvailableIndex - currentMaxIndex
  let step = viewsToShow

  function tryScrollToNextPage () {
    if (autoMode) {
      step = 2
      const nextStep = (currentMinIndex + step) <= lastAvailableIndex
        ? currentMinIndex + step
        : 0
      track.scrollTo(nextStep)
    } else {
      if (maxNextPageStep > step) {
        track.scrollTo(currentMinIndex + step)
      } else if (maxNextPageStep === 0) {
        // we're on the last availabe step, just start from beginning
        track.scrollTo(maxNextPageStep)
      } else {
        // scroll to next availabe step
        track.scrollTo(currentMinIndex + maxNextPageStep)
      }
    }
  }

  function tryScrollToPrevPage () {
    if (autoMode) {
      step = 2
      const prevStep = (currentMinIndex - step) > 0
        ? currentMinIndex - step
        : 0
      track.scrollTo(prevStep)
    } else {
      const maxPrevPageStep = currentMinIndex - step
      // backwards
      if (maxPrevPageStep > 0) {
        track.scrollTo(maxPrevPageStep)
      } else {
        track.scrollTo(0)
      }
    }
  }

  return (
    <div className={cn(null, 'theme-' + theme)}>
      <h2 className={cn('title')}>{title}</h2>
      <div className={cn('controls')}>
        {onViewAllClick &&
          shouldRenderTopControlsActions && (
          <div className={cn('view-all')} onClick={e => onViewAllClick(e)}>
              View all
          </div>
        )}
        {shouldRenderTopControlsActions && [
          <Icon
            size={iconSize}
            icon='new-arrow-left'
            key='new-arrow-left'
            className={cn('arrow', {left: true, active: indicateActive && currentMinIndex > 0})}
            onClick={tryScrollToPrevPage}
          />,
          <Icon
            size={iconSize}
            icon='new-arrow-right'
            key='new-arrow-right'
            className={cn('arrow', {right: true, active: indicateActive && currentMaxIndex < lastAvailableIndex})}
            onClick={tryScrollToNextPage}
          />
        ]}
      </div>
    </div>
  )
}
