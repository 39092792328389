'use client'
import React from 'react'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import './style.scss'
import { uniqueId } from 'lodash'
import FormLabel from '../form-label'

const cn = Bem({
  prefix: 'pfx-',
  name: 'select-field'
})

export default class SelectField extends React.Component {

  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    isDisabled: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired
    })).isRequired
  }

  state = {
    isFocus: false
  }

  constructor (props) {
    super(props)
    this.id = uniqueId()
  }

  async onFocus (e) {
    await this.setState({
      isFocus: true
    })
    this.props.onFocus && this.props.onFocus(e)
  }

  async onBlur (e) {
    await this.setState({
      isFocus: false
    })
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange(e) {
    this.props.onChange && this.props.onChange(e.target.value)
  }

  render () {
    const { className, options, isDisabled, label, value, onFocus, onBlur, onChange, ...restProps } = this.props
    const { isFocus } = this.state

    return <div className={cn('', {focus: isFocus}, className)}>
      { label && <FormLabel isFocus={isFocus} htmlFor={this.id}>{ label }</FormLabel> }
      <div className={cn('container')}>
        <select
          value={value}
          ref={ref => this.inputRef = ref}
          disabled={isDisabled}
          className={cn('select', {disabled: isDisabled})}
          id={this.id}
          onFocus={e => this.onFocus(e)}
          onBlur={e => this.onBlur(e)}
          onChange={e => this.onChange(e)}
          {...restProps}
        >
          { options.map(option => <option key={option.value} value={option.value}>{option.label}</option>) }
        </select>
      </div>
    </div>
  }

}
