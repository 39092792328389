import { useCallback, useRef } from 'react'

/** @param scrollDirection: 'down'|'up' */
const useInfiniteScrolling = (callback, offset, scrollDirection = 'down') => {
  const prevScroll = useRef(0)

  const handleScroll = useCallback(
    (e) => {
      const target = e.target
      const reachedOffset =
        scrollDirection === 'down'
          ? target.clientHeight + target.scrollTop >= target.scrollHeight - offset
          : target.scrollTop <= offset

      const isScrollingInDirection =
        scrollDirection === 'down' ? target.scrollTop > prevScroll.current : target.scrollTop < prevScroll.current
      if (reachedOffset && isScrollingInDirection) {
        callback()
      }

      prevScroll.current = target.scrollTop
    },
    [callback, offset, scrollDirection]
  )

  return handleScroll
}

export default useInfiniteScrolling
