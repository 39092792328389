import React, { Fragment, useCallback } from 'react'
import { useSelector, connect } from 'react-redux'
import createProvider from '@store/provider'
import { selectAccount } from '@store/selectors/backendData'
import HamburgerMenu from '@components/hamburger-menu'
import Icon from '@components/icon'
import Dropdown from '@components/dropdown'
import { LINKS, ENDPOINTS, REFERRAL_ENABLED_PAYMENT_METHODS } from '@common/consts'
import { replaceHref } from '@common/utils'
import * as styles from './CommunityUserMenu.module.scss'

const CommunityUserMenu = (props) => {
  const { currentUser, footerLinks, className = '', mode } = props

  const account = useSelector(selectAccount)
  const referralEnabled = !!account && REFERRAL_ENABLED_PAYMENT_METHODS.indexOf(account.currentPaymentMethod) !== -1

  const renderDropdownToggle = useCallback(() => (
    <div className={styles.toggler}>
      <img className={styles.avatar} src={currentUser.avatarSmallUrl} alt={currentUser.username} />
      <Icon className={styles.togglerChevron} icon='arrow-down' size={12} />
    </div>
  ), [currentUser])
  const renderMainSection = useCallback(() => (
    <Fragment>
      {currentUser ? (
        <Fragment>
          <a className={styles.user} href={`${LINKS.USER}/${currentUser.profileLink}`}>
            <img src={currentUser.avatarSmallUrl} alt={currentUser.username} title={currentUser.username} className={styles.avatar} />
            <div className={styles.userInfo}>
              <span className={styles.username}>{currentUser.username}</span>
              <span className={styles.viewProfile}>View profile</span>
            </div>
          </a>

          <div className={styles.divider} />
        </Fragment>
      ) : null}

      {mode === 'hamburger' && (
        <Fragment>
          <a className={styles.item} href={LINKS.COMMUNITY}><Icon icon='Home-ver-2' size={20} /> Home</a>

          <div className={styles.divider} />

          <a className={styles.item} href={LINKS.GROUPS}><Icon icon='Sofa' size={20} /> Groups</a>
          <a className={styles.item} href={LINKS.TRACKS}><Icon icon='Keys-or-notes' size={20} /> Tracks</a>
          <a className={styles.item} href={LINKS.USERS}><Icon icon='User' size={20} /> Users</a>
          <a className={styles.item} href={LINKS.FEED}><Icon icon='Speaker' size={20} /> News</a>
          <a className={styles.item} href={LINKS.TUTORIALS}><Icon icon='academy' size={20} /> Tutorials</a>

          <div className={styles.divider} />
        </Fragment>
      )}

      {currentUser ? (
        <Fragment>
          <a className={styles.item} href={LINKS.ACCOUNT}><Icon icon='MyPage' size={20} /> My account</a>
          {!!referralEnabled && <a className={styles.item} href={LINKS.ACCOUNT_REFERRAL}><Icon icon='Collab-2' size={20} /> Invite a friend</a>}
          <a className={styles.item} href={LINKS.PRICING}><Icon icon='Plans' size={20} /> Plans</a>

          <div className={styles.divider} />

          <a className={styles.item} onClick={() => replaceHref(ENDPOINTS.LOGOUT)}><Icon className={styles.logoutIcon} icon='arrow-forward' size={20} /> Log out</a>
        </Fragment>
      ) : (
        <Fragment>
          <a className={styles.item} href={LINKS.AUTH_SIGN_UP}><Icon icon='Add-user' size={20} /> Join for free</a>
          <a className={styles.item} href={LINKS.AUTH_SIGN_IN}><Icon icon='arrow-forward' size={20} /> Log in</a>
        </Fragment>
      )}

      <div className={styles.divider} />
    </Fragment>
  ), [currentUser, mode])
  const renderExternalLinksSection = useCallback(() => (
    footerLinks.map((link, index) => (
      <a key={index} className={`${styles.fineprint} ${index === 0 && styles.leadingFineprint} ${index === footerLinks.length - 1 && styles.trailingFineprint}`} href={link.url} target='_blank'>{link.label}</a>
    ))
  ), [footerLinks])

  return (
    mode === 'dropdown' ? (
      <Dropdown className={`${styles.dropdown} ${className}`} align='end' toggle={renderDropdownToggle()}>
        {renderMainSection()}
        {renderExternalLinksSection()}
      </Dropdown>
    ) : (
      <HamburgerMenu className={`${styles.hamburgerMenu} ${className}`}>
        <div>{renderMainSection()}</div>
        <div>{renderExternalLinksSection()}</div>
      </HamburgerMenu>
    )
  )
}

export default createProvider(connect(null)(CommunityUserMenu))
