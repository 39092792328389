import React from 'react'
import Icon from '@components/icon'
import { formatDistance } from 'date-fns'
import * as styles from './Message.module.scss'

const Message = React.forwardRef((props, ref) => {
  const { isUnread, users = [], lastUpdated, message, messageUrl, isMessagePreview = false, defaultAvatarUrl, className = '' } = props

  const usersWithDeletedUserFallback = !users.length ? [{ avatarSmallUrl: defaultAvatarUrl }] : users

  return (
    <div ref={ref} className={`${styles.container} ${className}`}>
      <div className={styles.unreadIndicatorContainer}>
        {isUnread && <div className={styles.unreadIndicator} />}
      </div>

      <div className={styles.avatars}>
        {usersWithDeletedUserFallback.map((user) => {
          return (
            <a key={user?.id} href={user?.selfLink}>
              <img src={user?.avatarSmallUrl ?? defaultAvatarUrl} />
            </a>
          )
        })}
      </div>

      <div className={`${styles.content} ${isMessagePreview && styles.contentPreview}`}>
        <div className={styles.usersAndAge}>
          {usersWithDeletedUserFallback.map((user) => {
            return (
              <a key={user?.id} href={user?.selfLink} className={styles.username}>
                {user?.username || 'Deleted user'}
              </a>
            )
          })}
          <time className={styles.age}>{formatDistance(new Date(lastUpdated), new Date())}</time>
        </div>

        {message && (
          <div className={`${styles.body} ${isMessagePreview && styles.bodyPreview}`}>
            {message}
          </div>
        )}
      </div>

      {isMessagePreview && (
        <div className={styles.viewMessage}>
          <a className={styles.read} href={messageUrl}>
            Read <Icon icon='small-right' size={16} />
          </a>
        </div>
      )}
    </div>
  )
})

Message.displayName = 'Message'
export default Message
