import { ENDPOINTS } from '@common/consts'
import axios from '@api'

export async function getAccount () {
  return axios.get(ENDPOINTS.ACCOUNT)
}

export async function getSubscriptions () {
  return axios.get(ENDPOINTS.SUBSCRIPTION)
}

export const cancelSubscription = async ({ id, cancelReasonId, followUp }) => {
  try {
    const url = ENDPOINTS.CANCEL_SUBSCRIPTION.replace(':id', id)
    const res = await axios.put(url, {
      cancel_reason: {
        cancel_reason_template_id: cancelReasonId,
        follow_up: followUp
      }
    })
    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

export const removeSubscriptionCancellation = async (subscriptionId) => {
  try {
    const url = ENDPOINTS.REMOVE_SUBSCRIPTION_CANCELLATION.replace(':id', subscriptionId)
    const res = await axios.post(url)
    return res.data
  } catch (e) {
    throw new Error(e)
  }
}

export const getCancelReasonTemplates = async (subscriptionType = 'account') => {
  const params = {
    sub_type: subscriptionType
  }

  const queryParams = new URLSearchParams(params);

  return axios.get(`${ENDPOINTS.CANCEL_REASON_TEMPLATES}?${queryParams}`)
}

export const resendEmailConfirmation = data => {
  return axios.post(ENDPOINTS.RESEND_EMAIL_CONFIRMATION, data)
}

export const getHostedPageCheckoutNewUrl = async ({ planId, coupon }) => {
  let params = { plan_id: planId }
  if (coupon) {
    params = {
      ...params,
      coupon,
    }
  }
  const query_params = new URLSearchParams(params);
  return axios.get(`${ENDPOINTS.CHARGEBEE.HOSTED_PAGES_CHECKOUT_NEW}?${query_params}`)
}

export const getHostedPageCheckoutExistingUrl = async ({ token, planId, coupon }) => {
  let params = { plan_id: planId }
  if (coupon) {
    params = {
      ...params,
      coupon
    }
  }
  const url = ENDPOINTS.CHARGEBEE.HOSTED_PAGES_CHECKOUT_EXISTING.replace(':id', token)
  const query_params = new URLSearchParams(params);

  return axios.get(`${url}?${query_params}`)
}

export const getChargebeePortalSession = async () => {
  return axios.post(ENDPOINTS.CHARGEBEE.PORTAL_SESSIONS)
}

export const deleteAccount = async (userId) => {
  await axios.delete(`${ENDPOINTS.USERS_API}/${userId}`)
  window.location.href = '/'
}

export const changeUserPassword = async (userGuid, data) => {
  return axios.put(ENDPOINTS.CHANGE_USER_PASSWORD.replace(':guid', userGuid), {
    user: {
      current_password: data.currentPassword,
      password: data.password,
      password_confirmation: data.passwordConfirmation
    }
  })
}
