import './checkout-cart.scss'
import PropTypes from 'prop-types';
import {Bem, gotoLocation} from '../../../common/utils';
import Icon from "../../../components/icon/index";
import Button from "../../../components/button/index";
import {LINKS} from "../../../common/consts";
import {If} from "react-if";
import {removeProduct} from "../../../common/http";
import * as React from "react";
import {noop} from 'lodash'
import {getTotalCartPrice} from "../services/utils";

const cn = new Bem({
  name: 'checkout-view',
  prefix: 'pfx-'
});

class CheckoutCart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: props.products
    }
  }

  componentWillReceiveProps(nextProps) {
    const {products} = this.props;
    if (products !== nextProps.products) {
      this.state.products = nextProps.products
    }
  }

  toggleChartProducts () {
    this.setState({
      cartProductVisible: !this.state.cartProductVisible
    })
  }

  async removeCartProduct (id) {
    const { products } = this.state
    const { onProductListChange } = this.props

    this.setState({
      products: products.filter((product) => product.id !== id)
    }, () => onProductListChange(this.state.products))
    return removeProduct(id)
  }

  renderProduct (product) {
    return (
      <div className={cn('cart-product')} key={product.id}>
        <div className={cn('cart-product-column', 'left-side')}>
          <div className={cn('cart-product-column', 'image')}>
            <img
              alt={product.title}
              src={product.cloudfrontUrlSmall}
              className={cn('cart-product-image')} />
          </div>
          <div className={cn('cart-product-column', 'text')}>
            <div className={cn('cart-product-title')}>{product.title}</div>
            <div className={cn('cart-product-price')}>
              <strong className={cn('cart-price-text', null, 'pfx--mono')}>
                { product.priceForCurrency }
              </strong>
              <span className={cn('cart-price-text', null, 'pfx--mono')}>
                { ` ${product.currency}` }
              </span>
            </div>
          </div>
        </div>

        <div className={cn('cart-product-column', 'actions')}>
          <Icon
            icon='new-close'
            type='hollow'
            className={cn('cart-product-remove')}
            size={20}
            onClick={() => this.removeCartProduct(product.id)} />
        </div>
      </div>
    )
  }


  render() {
    const {cartProductVisible, products} = this.state
    const {currency} = this.props

    if (!products.length) {
      return (
        <div className={cn('no-products')}>
          <Icon icon='cart-2' size={70} fontSizeRatio={1} />
          <p className={cn('no-products-text')}>
            It seems that your cart is empty. <br />
            Back to the shop and choose some interesting stuff.
          </p>
          <Button
            type='outline-black'
            onClick={() => gotoLocation(LINKS.SHOP)}> Back to sound shop </Button>
        </div>
      )
    }

    return (
      <div className={cn('cart')}>
        <div className={cn('cart-header', null, 'pfx--mono')}>
          { products.length } items in your cart
        </div>
        <If condition={!!cartProductVisible}>
          <div className={cn('cart-products')}>
            { products.map(product => this.renderProduct(product)) }
          </div>
        </If>
        <div className={cn('cart-bottom')}>
          <Button
            className={cn('toggle-button')}
            size='small'
            type='outline-black'
            onClick={() => this.toggleChartProducts()}>
            { cartProductVisible ? 'HIDE ITEMS' : 'SHOW ITEMS' }
          </Button>
          <Icon
            type='hollow'
            size={50}
            onClick={() => this.toggleChartProducts()}
            icon={cartProductVisible ? 'arrow-up' : 'arrow-down'}
            className={cn('toggle-icon')} />
          <div className={cn('cart-price')}>
            <div className={cn('cart-total')}>Total price</div>
            <div className={cn('cart-total-price', null, 'pfx--mono')}>
              { getTotalCartPrice({ withCurrency: true, products, currency}) }
            </div>
          </div>
        </div>
      </div>
    )
  }
}

CheckoutCart.propTypes = {
  products: PropTypes.array,
  onProductListChange: PropTypes.func,
  currency: PropTypes.string
};

CheckoutCart.defaultProps = {
  products: [],
  onProductListChange: noop,
  currency: ''
};

export default CheckoutCart;
