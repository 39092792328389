import { useCallback, useState } from 'react'
import axios from 'axios'

export default function useCommunityTrackLike ({ fullTrackLink, initialIsLiked }) {
    const [isLiked, setIsLiked] = useState(initialIsLiked)

    const toggle = useCallback(() => {
        const headers = {
            withCredentials: true,
            headers: {
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
                Accept: 'application/json'
            }
        }

        setIsLiked(!isLiked)

        if (!isLiked) {
            axios.post(`${fullTrackLink}/like`, {}, headers)
        } else {
            axios.delete(`${fullTrackLink}/unlike`, headers)
        }
    }, [fullTrackLink, isLiked])

    return { toggle, isLiked }
}
