import React, { useMemo } from 'react'
import queryString from 'query-string'
import { connect, useSelector } from 'react-redux'
import createProvider from '@store/provider'
import { selectIsStudioSupportedDevice } from '@store/selectors/app'
import { LINKS } from '@common/consts'
import { gotoLocation } from '@common/utils'
import SearchField from '@components/search-field'
import CommunityTitle from '../community-title'
import * as styles from './CommunitySearch.module.scss'

export const COMMUNITY_SEARCH_PARAMS = {
  keys: {
    term: 'term',
    type: 'type'
  },
  values: {
    type: {
      all: 'all',
      user: 'user',
      track: 'mixdown',
      group: 'group',
      project: 'project'
    }
  }
}

export const makeCommunitySearchUrl = ({ type, term }) => {
  return queryString.stringifyUrl({
    url: LINKS.COMMUNITY_SEARCH,
    query: {
      [COMMUNITY_SEARCH_PARAMS.keys.type]: type,
      [COMMUNITY_SEARCH_PARAMS.keys.term]: term
    }
  })
}

const CommunitySearch = ({ isSearchPage = false, className = '' }) => {
  const isStudioSupportedDevice = useSelector(selectIsStudioSupportedDevice)

  const handleSearch = (search) => {
    const url = makeCommunitySearchUrl({ type: COMMUNITY_SEARCH_PARAMS.values.type.all, term: search })
    gotoLocation(url, {})
  }

  const searchParam = useMemo(() => new URLSearchParams(window.location.search)?.get(COMMUNITY_SEARCH_PARAMS.keys.term), [])

  const getPlaceholder = () => {
    if (isStudioSupportedDevice) {
      return 'Search project, user, track, or group'
    }
    return 'Search user, track, or group'
  }

  return (
    <div className={`${styles.container} ${isSearchPage && styles.searchPage} ${className}`}>
      {!!isSearchPage && <CommunityTitle className={styles.title} title='Search' />}
      <SearchField initialValue={searchParam} onSearch={handleSearch} placeholder={getPlaceholder()} />
    </div>
  )
}

export default createProvider(connect(null)(CommunitySearch))
