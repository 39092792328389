import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'

const cn = new Bem({
  name: 'slide-bar',
  prefix: 'pfx-'
})

const SlideBar = (props) => {
  const { open = false } = props
  return (
    <div className={cn(null, { open }, props.className)}>
      { props.children }
    </div>
  )
}

SlideBar.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any
}

export default SlideBar
