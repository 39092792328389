import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CancelSubscription from '@containers/subscriptions/components/cancel-subscription'
import Subscription from '@containers/subscriptions/components/subscription'

class CurrentAddons extends Component {

  static propTypes = {
    addons: PropTypes.object
  }

  state = {
    cancelModalId: null
  }

  closeCancelModal = () => this.setState({ cancelModalId: null })

  render() {
    const { addons, onSubscriptionReactivate } = this.props

    return (
      <React.Fragment>
        {addons.map((subscription) => {
          let ctaButtons = []

          if (subscription.isCancelledOrNonRenewing) {
            ctaButtons = [
              {
                text: 'Reactivate',
                onClick: () => onSubscriptionReactivate(subscription.id)
              }
            ]
          } else {
            ctaButtons = [
              {
                type: 'outline',
                text: 'Cancel',
                onClick: () => this.setState({ cancelModalId: subscription.id })
              }
            ]
          }

          return (
            <div>
              <Subscription
                subscription={subscription}
                buttons={ctaButtons}
              />
              <CancelSubscription
                subscription={subscription}
                isOpen={this.state.cancelModalId === subscription.id}
                handleClose={this.closeCancelModal}
              />
            </div>
          )
        })}
      </React.Fragment>
    )
  }
}

export default CurrentAddons
