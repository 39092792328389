'use client'

import React from "react";
import PropTypes from "prop-types";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { error: errorMessage } = this.props;
    // TODO Log to sentry
    console.error(errorMessage || "Failed to render", error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { fallback, children, renderFallback, FallbackComponent } =
      this.props;

    if (hasError) {
      if (typeof renderFallback === "function") {
        return renderFallback();
      }

      if (FallbackComponent) {
        return <FallbackComponent />;
      }

      if (React.isValidElement(fallback)) {
        return fallback;
      }

      return null;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  error: PropTypes.string.isRequired,
  fallback: PropTypes.node,
  renderFallback: PropTypes.func,
  FallbackComponent: PropTypes.node,
};

export { ErrorBoundary };
