import React, { useCallback, useEffect, useState } from 'react'
import { denormalizeResponse, getNewsFeed, getTutorialsFeed } from '@common/http'
import usePaginate from '@root/hooks/usePaginate'
import useIntersection from '@root/hooks/useIntersection'
import Spinner from '@components/spinner'
import CommunityTitle from '../community-title'
import CommunityFeedCard from '../community-feed-card'
import * as styles from './CommunityFeed.module.scss'

const CommunityFeed = (props) => {
  const { type } = props
  const [lastElementRef, setLastElementRef] = useState({ current: null })

  const fetchFeedItems = useCallback(async ({ page }) => {
    return (type === 'news' ? getNewsFeed({ page }) : getTutorialsFeed({ page }))
  }, [type])
  const { data, isLoading, fetchNextPage } = usePaginate({ fetcher: fetchFeedItems })
  useIntersection({ callback: fetchNextPage, elementRef: lastElementRef })

  const setLastElementRef_ = useCallback((r) => {
    setLastElementRef({ current: r })
  }, [])

  useEffect(fetchNextPage, [])

  const items = denormalizeResponse(data)

  return (
    <div>
      <CommunityTitle title={type === 'news' ? 'News' : 'Tutorials'} className={styles.pageTitle} />

      {items.map((item, i) => <CommunityFeedCard key={item.id} ref={i === items.length - 1 ? setLastElementRef_ : null} item={item} fillPage />)}
      {isLoading && <Spinner />}
    </div>
  )
}

export default CommunityFeed
