import { get } from "../../common/utils";

export const createIsPlayingSelector = (songUrl, playerId = '') => {
  if (!songUrl) return () => false;

  return (state) => {
    return (
      get(state, "player.isPlaying") && get(state, "player.songUrl") === songUrl && (playerId ? get(state, "player.playerId") === playerId : true)
    );
  };
};
