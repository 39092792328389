import React from 'react'
import CommunityTag from '../community-tag'
import * as styles from './CommunityPageStats.module.scss'

const CommunityPageStats = (props) => {
  const { stats } = props

  return (
    <div className={styles.container}>
      {stats.map(({ icon, count }) => <CommunityTag icon={icon} text={count} />)}
    </div>
  )
}

export default CommunityPageStats
