import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { dissmissHalloweekBanner } from '@store/actions/app'
import { gotoLocation, Bem, get } from '@common/utils'
import { LINKS } from '@common/consts'
import CrtContainer from '@components/crt-container'
import GlitchText from '@components/glitch-text'
import Linkton, { TYPE, HOVER, COLOR } from '@components/linkton'
import './style.scss'

const cn = new Bem({
  name: 'halloweek-banner',
  prefix: 'pfx-'
})

class HalloweekBanner extends React.Component {
  navigateToHalloweekLandingPage = () => {
    gotoLocation(LINKS.HALLOWEEK_JAM)
  }

  render() {
    const { isHalloweek, isDissmissed } = this.props

    if (!isHalloweek || isDissmissed) return null

    return (
      <CrtContainer className={cn()} hasFlicker hasScanline>
        <div className={cn('crt')}>
          <GlitchText elementType='h2' className={cn('header')}>
            Halloween Jam
          </GlitchText>
          <GlitchText elementType='p' className={cn('text')}>
            Get 7 days of free unlimited collaboration.
          </GlitchText>
        </div>
        <div className={cn('button-container')}>
          <Linkton
            type={TYPE.OUTLINE}
            hover={HOVER.GLITCH}
            color={COLOR.WHITE}
            size={18}
            onClick={this.props.dissmissHalloweekBanner}
            className={cn('button', 'close')}>
            Close
          </Linkton>

          <Linkton
            type={TYPE.OUTLINE}
            hover={HOVER.GLITCH}
            color={COLOR.OCEAN}
            size={18}
            onClick={this.navigateToHalloweekLandingPage}
            className={cn('button')}>
            Join
          </Linkton>
        </div>
      </CrtContainer>
    )
  }
}

HalloweekBanner.propTypes = {
  isHalloweek: PropTypes.bool.isRequired,
  isDissmissed: PropTypes.bool.isRequired,
  dissmissHalloweekBanner: PropTypes.func.isRequired
}

const mapDispatchToProps = {
  dissmissHalloweekBanner
}

const mapStateToProps = (state) => {
  return {
    isHalloweek: get(state, 'campaign.isHalloweek'),
    isDissmissed: get(state, 'app.halloweekBannerDissmissed')
  }
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(HalloweekBanner, false))
