import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import ReactModal from 'react-modal'
import { v4 as uuidv4 } from 'uuid'
import { Bem } from '@common/utils'
import Icon from '@components/icon'
import { open as openModal, close as closeModal } from '@store/actions/modal'
import { openModalsIdsSelector } from '@store/selectors/modal'
import './style.scss'

const cn = new Bem({
  name: 'modal',
  prefix: 'pfx-'
})

class Modal extends React.Component {
  id = uuidv4()

  componentDidMount () {
    if (this.props.isOpen) {
      this.props.openModal(this.id)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!prevProps.isOpen && this.props.isOpen && !this.isIdInStore()) {
      this.props.openModal(this.id)
    } else if (prevProps.isOpen && !this.props.isOpen && this.isIdInStore()) {
      this.props.closeModal(this.id)
    }
  }

  componentWillUnmount () {
    this.isIdInStore() && this.props.closeModal(this.id)
  }

  isIdInStore = () => this.props.openModalIds && this.props.openModalIds.filter(id => id === this.id).length > 0

  render () {
    const {
      theme,
      className: containerClassName,
      overlayClassName,
      onCloseButtonClick,
      children,
      title,
      windowStyle,
      bodyStyle,
      footer,
      footerStyle,
      windowClassName,
      headerClassName,
      bodyClassName,
      footerClassName,
      ...props
    } = this.props

    return (
      <ReactModal
        key='Modal'
        portalClassName={cn(null, theme)}
        className={cn('container', '', containerClassName)}
        overlayClassName={cn('background', null, overlayClassName)}
        ariaHideApp={false}
        {...props}
      >
        <div className={cn('window', '', windowClassName)} style={windowStyle}>
          <div className={cn('header', '', headerClassName)}>
            <div className={cn('title')}>{title}</div>
            <Icon className={cn('icon')} icon={theme === 'dark' ? 'cancel' : 'new-close'} size={22} onClick={onCloseButtonClick} />
          </div>
          <div className={cn('body', '', bodyClassName)} style={bodyStyle}>
            {typeof children === 'function' ? children() : children}
          </div>
          {
            footer
            && <div className={cn('footer', '', footerClassName)} style={footerStyle}>{footer()}</div>
          }
        </div>
      </ReactModal>
    )
  }
}

Modal.propTypes = {
  ...ReactModal.propTypes,
  theme: PropTypes.oneOf(['light', 'dark']),
  isOpen: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  windowStyle: PropTypes.object,
  bodyStyle: PropTypes.object,
  footer: PropTypes.func,
  windowClassName: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  className: PropTypes.string,
  overlayClassName: PropTypes.string
}

Modal.defaultProps = {
  isOpen: false,
  theme: 'light'
}

const mapStateToProps = state => {
  return {
    openModalIds: openModalsIdsSelector(state)
  }
}

const mapDispatchToProps = {
  openModal,
  closeModal
}

export { Modal }
export default createProvider(connect(mapStateToProps, mapDispatchToProps)(Modal))
