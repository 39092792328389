import './uploader.scss'
import { Bem } from '../../common/utils'
import * as React from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import Icon from '../icon/index'

const cn = new Bem({
  name: 'uploader',
  prefix: 'pfx-'
})

class Uploader extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: null
    }
  }

  render () {
    const {
      theme,
      mime,
      placeholder,
      uploaded,
      progress,
      dropzoneIcon,
      className,
      details,
      onUploadRequest,
      onRejected,
      moreThanOneErrorMessage,
      filename,
      forwardedRef,
      ...restProps
    } = this.props
    const { error } = this.state

    let dropzoneContent

    if (error) {
      dropzoneContent = (<div className={cn('error-message')}>
        { error }
      </div>)
    } else if (uploaded) {
      dropzoneContent = (<div className={cn('dropzone-content')}>
        <div className={cn('upload-message')}>
          <div className={cn('icon-wrapper')}>
            <Icon className={cn('icon', 'success')} icon='success-validation' size={29} />
          </div>
          <div className={cn('filename')}>
            { filename }
          </div>
        </div>
      </div>)
    } else if (filename) {
      dropzoneContent = (<div className={cn('dropzone-content')}>
        Uploading file: "{ filename }"
      </div>)
    } else {
      dropzoneContent = (<div className={cn('dropzone-content')}>
        { placeholder }
      </div>)
    }

    const progressWidth = progress === 1 ? 0 : progress

    return (
      <div className={cn('', theme, className)}>
        <Dropzone
          ref={forwardedRef}
          className={cn('dropzone', { success: uploaded })}
          accept={mime}
          multiple={false}
          onDrop={this.onDrop.bind(this)}
          style={{
            'position': 'absolute', // otherwise it makes inline position: relative
            '--progress': 100 * (progressWidth || 0) + '%',
            '--indicator-display': 'none'
          }}
          acceptStyle={{
            'backgroundColor': 'rgba(3, 138, 58, 0.1)',
            '--indicator-display': 'flex'
          }}
          rejectStyle={{
            backgroundColor: 'rgba(88, 3, 38, 0.1)',
            color: '#c78888'
          }}
          {...restProps}
        >
          <div className={cn('dropzone-border', 'top')} />
          <div className={cn('dropzone-border', 'right')} />
          <div className={cn('dropzone-border', 'bottom')} />
          <div className={cn('dropzone-border', 'left')} />
          { dropzoneContent }
          <div className={cn('dropzone-drag-indicator')}>
            <Icon className={cn('dropzone-drag-indicator-icon')} icon={dropzoneIcon} size={50} />
          </div>
          { details && <div className={cn('dropzone-details')}>
            {details}
          </div> }
          <div className={cn('progress-indicator')} />
        </Dropzone>
      </div>
    )
  }

  async onDrop (acceptedFiles, rejectedFiles) {
    const { onUploadRequest, onRejected, moreThanOneErrorMessage } = this.props

    if (acceptedFiles.length > 0) {
      this.setState({
        error: null
      })

      try {
        await onUploadRequest(acceptedFiles[0])
      } catch (e) {
        onRejected()
        this.setState({
          error: typeof e === 'string' ? e : e?.message
        })
      }
    } else if (rejectedFiles.length > 0) {
      onRejected()

      if (rejectedFiles.length > 1) {
        this.setState({
          error: moreThanOneErrorMessage
        })
      } else {
        this.setState({
          error: `File "${rejectedFiles[0].name}" cannot be processed`
        })
      }
    }
  }
}

Uploader.propTypes = {
  theme: PropTypes.oneOf(['classic', 'checkered']),
  mime: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onUploadRequest: PropTypes.func.isRequired,
  onRejected: PropTypes.func.isRequired,
  uploaded: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  moreThanOneErrorMessage: PropTypes.string.isRequired,
  dropzoneIcon: PropTypes.string.isRequired,
  className: PropTypes.string,
  details: PropTypes.string,
  filename: PropTypes.string
}

Uploader.defaultProps = {
  mime: '*',
  placeholder: 'Drop your file here'
}

export default React.forwardRef((p, ref) => <Uploader {...p} forwardedRef={ref} />)
