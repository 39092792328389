import React, { useState } from 'react'
import { LINKS } from '@common/consts'
import { gotoLocation } from '@common/utils'
import { postGroupComment, postTrackComment, postNewsComment } from '@common/http'
import createProvider from '@store/provider'
import { connect, useSelector } from 'react-redux'
import { selectCurrentUser } from '@store/selectors/backendData'
import useAutoResizeTextArea from '@hooks/useAutoResizeTextArea'
import TextArea from '@components/text-area/text-area'
import Icon from '@components/icon'
import Linkton from '@components/linkton'
import * as styles from './CommunityWriteComment.module.scss'

const CommunityWriteComment = (props) => {
  const { mixdownId, newsId, groupId } = props

  const currentUser = useSelector(selectCurrentUser)

  const [comment, setComment] = useState('')

  const { setTextarea: assignTextareaEl } = useAutoResizeTextArea({ allowResize: false })

  const handleSubmit = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (comment) {
      if (groupId) await postGroupComment(groupId, comment)
      if (mixdownId) await postTrackComment(mixdownId, comment)
      if (newsId) await postNewsComment(newsId, comment)
      gotoLocation(window.location.href)
    }

    return false
  }

  if (!currentUser) {
    return (
      <div className={styles.login}>
        <Icon icon='Log-in' size={24} />
        <small>Login to comment!</small>
        <Linkton className={styles.button} size='medium' tag='anchor' href={LINKS.AUTH_SIGN_IN}>Login</Linkton>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <form data-turbolinks={false} isDisabled={!comment} onSubmit={handleSubmit}>
        <div className={styles.avatarAndInput}>
          <img className={styles.img} src={currentUser.avatarSmallUrl} alt={`${currentUser.username} avatar`} />
          <TextArea ref={(r) => r && assignTextareaEl(r)} name='comment[content]' className={styles.textarea} maxLength='600' onChange={setComment} fontSize='small' isTransparent={false} value={comment} placeholder='Write a comment' />
        </div>

        <div className={styles.submit}>
          <Linkton className={styles.submitCta} isSubmit isDisabled={!comment}>Comment</Linkton>
        </div>
      </form>
    </div>
  )
}

export default createProvider(connect(null)(CommunityWriteComment, false))
