import React, { useCallback } from "react";
import PropTypes from "prop-types";

import Icon from "../../icon";
import {
  dropdownMenuCn,
  dropdownColumnCn,
  dropdownParentItem,
  dropdownItemFn,
  dropdownContentCn,
  dropdownParentIcon,
  dropdownParentItemInnerCn,
  dropdownItemListCn,
} from "./DropdownMenu.cn";
import "./DropdownMenu.style.scss";

import { filteredMenuItems } from "../MegaMenu.utils";
import { useStrapiContext } from "../../../contexts/StrapiContext";

const DropdownMenu = ({ items: columns }) => {
  const { navigation } = useStrapiContext();

  const renderItem = useCallback(({ link, modifiers }) => {
    return (
      <a className={dropdownItemFn(modifiers)} href={navigation.getPath(link)}>
        <span>{link.title}</span>
        <Icon type="blank" icon="next" size={12} />
      </a>
    );
  }, []);

  return (
    <div className={dropdownMenuCn}>
      <div className={dropdownContentCn}>
        {(columns || []).map((column) => {
          if (column.menuAttached === false) {
            return null;
          }

          const { filteredItems, showReadMore } = filteredMenuItems(
            column.items || []
          );

          return (
            <div className={dropdownColumnCn} key={column.title}>
              <a className={dropdownParentItem} href={navigation.getPath(column)}>
                <div className={dropdownParentItemInnerCn}>
                  {column.title}
                  <Icon
                    type="blank"
                    icon="next"
                    size={12}
                    className={dropdownParentIcon}
                  />
                </div>
              </a>
              <div className={dropdownItemListCn}>
                {filteredItems.map((link) => {
                  if (link.menuAttached === false) {
                    return null;
                  }

                  return <div key={link.title}>{renderItem({ link })}</div>;
                })}
                {showReadMore
                  ? renderItem({
                      link: { ...(column || {}), title: "See all" },
                      modifiers: { all: true },
                    })
                  : null}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

DropdownMenu.propTypes = PropTypes.shape({
  items: PropTypes.array,
}).isRequired;

export default DropdownMenu;
