import { ErrorBoundary } from "./ErrorBoundary";

function withErrorBoundary(WrappedComponent, errorBoundaryProps = {}) {
  return (props = {}) => (
    <ErrorBoundary {...errorBoundaryProps}>
      <WrappedComponent {...props} />
    </ErrorBoundary>
  );
}

export { withErrorBoundary };
