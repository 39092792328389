import PropTypes from 'prop-types'
import { Enum } from '../common/utils'

const FLASH_ALERTS_TYPES = new Enum('ALERT', 'NOTICE', 'ERROR')

export const flashMessageModel = {
  text: PropTypes.string,
  type: PropTypes.oneOf([
    FLASH_ALERTS_TYPES.ALERT,
    FLASH_ALERTS_TYPES.ERROR,
    FLASH_ALERTS_TYPES.NOTICE
  ]),
  autoclose: PropTypes.bool,
  closeButton: PropTypes.bool,
}
