import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { If } from 'react-if'
import { Bem, deepCamelCase, gotoLocation, replaceHref } from '../../common/utils'
import Dropdown from '../../components/dropdown'
import Icon from '../icon/index'
import { ENDPOINTS, LINKS } from '../../common/consts'

const cn = new Bem({
  name: 'user-dropdown',
  prefix: 'pfx-'
})

class UserDropdown extends React.Component {
  constructor (props) {
    super(props)
    this.currentUserParsed = deepCamelCase(this.props.currentUser)
  }

  renderToggle () {
    const { avatarUrl } = this.props

    return (
      <div className={cn('user')}>
        <span className={cn('avatar')} data-hj-suppress style={{ backgroundImage: 'url(' + avatarUrl + ')' }} />
        <Icon icon='arrow-down' className={cn('arrow')} size={12} />
      </div>
    )
  }

  render () {
    return (
      <Dropdown className={cn()} toggle={this.renderToggle()} gap={4}>
        {!this.props.eduMode ? <div className={cn('item', { 'with-divider': true })} onClick={() => gotoLocation(LINKS.ACCOUNT_PROFILE)}>
          <Icon className={cn('icon')} icon='account' size={17} />
          <span className={cn('text')}>My account</span>
        </div> : null}
        <div className={cn('item')} onClick={() => replaceHref(ENDPOINTS.LOGOUT)}>
          <Icon className={cn('icon')} icon='log_out' size={15} />
          <span className={cn('text')}>Logout</span>
        </div>
      </Dropdown>
    )
  }
}

UserDropdown.propTypes = {
  currentUser: PropTypes.object,
  eduMode: PropTypes.bool
}

UserDropdown.defaultProps = {}

export default UserDropdown
