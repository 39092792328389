import React, { Component } from 'react'
import { Bem } from '@common/utils'
import './style.scss'
import PropTypes from 'prop-types'

const cn = new Bem({
  name: 'loading-indicator',
  prefix: 'pfx-'
})

export default class LoadingIndicator extends Component {

  static propTypes = {
    type: PropTypes.oneOf(['inline', 'fixed']),
    color: PropTypes.oneOf(['black', 'white'])
  }

  static defaultProps = {
    type: 'inline',
    color: 'black'
  }

  render () {
    const { type, color, className } = this.props
    return (
      <div className={cn(null, [type, color], className)}>
        <div className={cn('animation')}></div>
      </div>
    )
  }
}
