import './text-field-simple.scss'
import PropTypes from 'prop-types';
import {Bem} from '../../common/utils';
import {noop} from 'lodash'
import * as React from "react";

const cn = new Bem({
  name: 'simple-input',
  prefix: 'pfx-'
});

class TextFieldSimple extends React.Component {
  render() {
    const {className, onChange} = this.props;
    return (
      <input
        {...this.props}
        className={cn(null, null, className)}
        onChange={(e) => onChange(e.target.value)} />
    )
  }
}

TextFieldSimple.propTypes = {
  onChange: PropTypes.func,
};

TextFieldSimple.defaultProps = {
  onChange: noop,
};

export default TextFieldSimple;
