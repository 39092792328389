import { denormalizeResponse } from '@common/http'
import CommunityGroupCard from '../community-group-card/CommunityGroupCard'
import CommunitySection from '../community-section'

export default function CommunityGroupsSection (props) {
  const { className = '', groups, label, showMoreLink, subHeader, denormalize = true } = props

  const section = {
    items: (!denormalize ? groups : denormalizeResponse(groups)).map((group) => <CommunityGroupCard key={group.id} group={group} isSubPage={false} />),
    label,
    showMoreLink,
    subHeader
  }

  return (
    <CommunitySection className={className} items={section.items} label={section.label} showMoreLink={section.showMoreLink} subHeader={section.subHeader} />
  )
}
