import React from 'react'
import './input-wrapper.scss'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import { noop } from 'lodash'
import Icon from '../../../components/icon/index'

const cn = new Bem({
  name: 'input-wrapper',
  prefix: 'pfx-'
})

class InputWrapper extends React.Component {
  render () {
    const {
      children,
      label,
      className,
      icon,
      renderRightSideComponent,
      error,
      disabled
    } = this.props

    return (
      <div className={cn('container', { error }, className)}>
        { label && <div className={cn('label', null, 'pfx--mono')}> { label } </div> }
        <div className={cn('component', { error, disabled })}>
          { icon && <Icon icon={icon} size={18} fontSizeRatio={1} /> }
          { children }
          { renderRightSideComponent(this.props) }
        </div>
        { error && <div className={cn('error')}>{error}</div> }
      </div>
    )
  }
}

InputWrapper.propTypes = {
  renderRightSideComponent: PropTypes.func,
  error: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.element.isRequired
}

InputWrapper.defaultProps = {
  renderRightSideComponent: noop,
  error: null,
  label: null,
  className: null,
  icon: null,
  disabled: false
}

export default InputWrapper
