import React from 'react'
import './style.scss'
import { Bem, canUseDOM } from '../../common/utils'
import Icon from '../icon/index'
import PropTypes from 'prop-types'

const cn = new Bem({
  name: 'section-separator',
  prefix: 'pfx-'
})

class SectionSeparator extends React.Component {
  scrollToElement () {
    if (canUseDOM() && this.props.scrollTo) {
      const scrollTop = $(`#${this.props.scrollTo}`).position().top - 40
      $('body, html').animate({ scrollTop }, 500)
    }
  }

  render () {
    return (
      <div className={cn(null, null, 'pfx--flex pfx--flex-column pfx--flex-center')}>
        {this.props.children}
        <Icon type='hollow' icon='scroll' className='pfx--orange' onClick={this.props.scrollTo ? this.scrollToElement.bind(this) : null} />
      </div>
    )
  }
}

SectionSeparator.propTypes = {
  scrollTo: PropTypes.string
}
SectionSeparator.defaultProps = {}

export default SectionSeparator
