import validate from 'validate.js'

const REQUIRE_MESSAGE = 'This field is required'

validate.validators.terms = (value = {}) => {
  if (!value.age || !value.terms) {
    return 'You must accept Terms of Service, Privacy Policy and Age Consent.'
  }
}

validate.validators.eduTerms = (value = {}) => {
  if (!value.terms) {
    return 'You must accept Terms of Service and Privacy Policy'
  }
}

export const USER_EMAIL = () => {
  return {
    presence: { message: REQUIRE_MESSAGE },
    email: { message: 'Please enter valid email' },
    format: {
      pattern: /^[^\/%,\$#!^&*()]+$/,
      message: 'Your email address contains invalid characters'
    }
  }
}
export const PASSWORD = () => {
  return {
    presence: { message: REQUIRE_MESSAGE },
    length: { minimum: 6, message: 'Password should have at least 6 characters' }
  }
}

export const CONFIRM_PASSWORD = () => {
  return {
    presence: { message: REQUIRE_MESSAGE },
    equality: { attribute: 'password', message: 'Confirm password is not equal to password' }
  }
}

export const CAPTCHA = () => {
  return {
    presence: { message: REQUIRE_MESSAGE }
  }
}

export const CONSENTS = (eduMode = false) => {
  if (eduMode) {
    return { eduTerms: { message: REQUIRE_MESSAGE } }
  } else {
    return { terms: { message: REQUIRE_MESSAGE } }
  }
}

export const JUST_REQUIRED = () => {
  return {
    presence: { message: REQUIRE_MESSAGE }
  }
}

export const LINK = () => {
  return {
    format: {
      pattern: /^[a-zA-Z0-9_-]*$/,
      message: 'Link contains invalid characters'
    }
  }
}

export const INCLUSION = (within) => {
  return {
    presence: { message: REQUIRE_MESSAGE },
    inclusion: {
      within,
    }
  }
}
