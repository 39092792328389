import React from 'react'
import Icon from '@components/icon'
import * as styles from './Tag.module.scss'

const Tag = (props) => {
  const { icon, text, className = '' } = props

  return (
    <div className={`${styles.container} ${className}`}>
      <Icon icon={icon} size={12} />
      <span>{text}</span>
    </div>
  )
}

export default Tag
