import React from 'react'
import {
  postGroupNews,
  getGroupNews,
  deleteGroupNews,
} from '@common/http'
import {sendGroupNewsNotification} from "../../../common/http";
import {connect} from "react-redux";
import createProvider from "../../../store/provider";
import {setFlashMessageByValues} from "../../../store/actions/flashMessage";

export default (WrapperGroupsComponent, groupId, initialUpdateList = []) => {
  class GroupUpdatesWithDataSource extends React.Component {

    state = {
      groupUpdatesList: initialUpdateList,
    }

    async componentWillMount() {
      this.setState({loading: true})
      await this.refreshUpdates()
      this.setState({loading: false})
    }

    refreshUpdates = async () => {
      const {data} = await getGroupNews(groupId)

      if (data.news) {
        this.setState({
          groupUpdatesList: data.news
        })
      }
    }

    handleNewUpdate = async ({content, title}) => {
      const {setFlashMessageByValues} = this.props
      this.setState({loading: true})
      await postGroupNews(groupId, {content, title})
      await this.refreshUpdates()
      this.setState({loading: false})
      setFlashMessageByValues({
        text: 'Update posted successfully!',
      })
    }

    handleDeleteUpdate = async (newsId) => {
      const {setFlashMessageByValues} = this.props
      this.setState({loading: true})
      await deleteGroupNews(groupId, newsId)
      await this.refreshUpdates()
      this.setState({loading: false})
      setFlashMessageByValues({
        text: 'Update deleted successfully!',
      })
    }

    handleNotify = async (newsId) => {
      const {setFlashMessageByValues} = this.props
      await sendGroupNewsNotification(groupId, newsId)
      await this.refreshUpdates()
      setFlashMessageByValues({
        text: 'You have notified users about this update via email',
      })
    }

    render() {
      return (
        <WrapperGroupsComponent
          groupUpdatesList={this.state.groupUpdatesList}
          onPost={this.handleNewUpdate}
          onNotify={this.handleNotify}
          onDelete={this.handleDeleteUpdate}
          {...this.props} />
      );
    }
  }

  const mapDispatchToProps = {
    setFlashMessageByValues
  }

  return createProvider(connect(null, mapDispatchToProps)(GroupUpdatesWithDataSource))
}