import React, { useCallback, useEffect } from 'react'
import usePaginate from '@root/hooks/usePaginate'
import { denormalizeResponse, getGroupMembers } from '@common/http'
import CommunitySubPage from '../community-sub-page'
import CommunityUserCard from '../community-user-card'

const CommunityGroupUsersSubPage = (props) => {
  const { groupName, groupId } = props

  const pageTitle = `Members of ${groupName}`

  const fetcher = useCallback(async ({ page }) => getGroupMembers(groupId, { page, items: 20 }), [groupId])
  const { isLoading, data: members, fetchNextPage } = usePaginate({ fetcher })

  const denormalizedUsers = denormalizeResponse(members).map(({ user }) => user)

  // fetch on mount
  useEffect(fetchNextPage, [])

  return (
    <CommunitySubPage
      title={pageTitle}
      landscapeOnMobile
      isLoading={isLoading}
      fetchNextPage={fetchNextPage}
    >
      {denormalizedUsers.map((user) => <CommunityUserCard key={user.id} user={user} isSubPage />)}
    </CommunitySubPage>
  )
}

export default CommunityGroupUsersSubPage
