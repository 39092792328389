import React, { Component } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import Linkton, { HOVER, SIZE, TAG } from '../linkton'
import { LINKS } from '@common/consts'

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-join'
})

export default class LandingJoin extends Component {
  render () {
    return (
      <div {...bem()}>
        <h3 {...bem('header')}>Enough scrolling, let's get started.</h3>
        <Linkton
          size={SIZE.LARGE}
          hover={HOVER.OUTLINE}
          tag={TAG.ANCHOR}
          href={LINKS.AUTH_PLANS}
        >Join for free</Linkton>
      </div>
    )
  }
}
