import React from 'react'
import CommunityButton from '@components/community-button'
import LazyImage from '@components/lazy-image'
import Badge from '@components/badge'
import { toLocaleFormat } from '@common/utils'
import * as styles from './Subscription.module.scss'

const Subscription = ({
  subscription,
  buttons = []
}) => {
  const {
    logo,
    name,
    statusText,
    nextPayment,
    description,
    active,
    isCancelledOrNonRenewing
  } = subscription

  const renderPayment = ({ payment, isCanceled = false }) => {
    const renderContent = () => {
      if (payment === null) return ''

      const language = navigator.languages ? navigator.languages[0] : navigator.language

      const currency = new Intl.NumberFormat(language, {
        style: 'currency',
        currency: payment.currency,
        minimumFractionDigits: 2
      }).format(payment.price)
      const date = toLocaleFormat(payment.date)

      if (isCanceled) return `You will be downgraded ${date}`

      return `Next payment of ${currency} is due ${date}`
    }

    return <p className={styles.body}>{renderContent()}</p>
  }

  const getBadgeVariant = () => {
    if (isCancelledOrNonRenewing) return 'danger'
    if (active) return 'highlight'
    return 'normal'
  }

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <LazyImage alt='Logo' src={logo} className={styles.logo} />

        <p className={styles.title}>{name}</p>

        {statusText ? (
          <Badge variant={getBadgeVariant()} size='small' theme='clean'>{statusText}</Badge>
        ) : null}
      </div>

      {description ? <p className={styles.body}>{description}</p> : null}

      {renderPayment({ payment: nextPayment, isCanceled: isCancelledOrNonRenewing })}

      <div className={styles.buttons}>
        {buttons.map((button) => {
          return <CommunityButton fullWidthOnMobile={false} key={button.text} size='medium' {...button}>{button.text}</CommunityButton>
        })}
      </div>
    </div>
  )
}

export default Subscription
