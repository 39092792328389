import './style.scss'
import { Bem, get } from '@common/utils'
import { connect } from 'react-redux'
import {
  getPromoPeriod,
  prepareTableConfig
} from '@components/price-plans/utils'
import { setFlashMessageByValues } from '@store/actions/flashMessage'
import PropTypes from 'prop-types'
import React from 'react'
import createProvider from '@store/provider'
import {
  clearCustomSuccessPaymentButton,
  clearLoginRedirectData
} from '../../common/utils'
import PricePlans from '@components/price-plans'
import automationSvg from "@images/Automation.svg";
import instrumentsAndEffectsSvg from "@images/Instruments&effects.svg";
import midiControllerSvg from "@images/MIDI controller.svg";
import exportSvg from "@images/Export.svg";

const cn = new Bem({
  name: 'pricing-view',
  prefix: 'pfx-'
})

class PricingView extends React.Component {
  constructor (props) {
    super(props)

    const { couponCode, customButton, closeAfterAuthentication } = this.props.queryParams
    const { currentUser, priceList, accountSubscription } = props

    if (!customButton) {
      clearCustomSuccessPaymentButton()
      clearLoginRedirectData()
    }

    let pricingTableConfig = prepareTableConfig({
      prices: priceList,
      user: currentUser,
      couponCode: couponCode,
      accountSubscription: accountSubscription,
      closeAfterAuthentication
    })

    if (!couponCode) {
      pricingTableConfig = pricingTableConfig.map((plan) => {
        if (plan.columnConfig) {
          delete plan.columnConfig.discountedYearlyToMonthly
        }
        return plan
      })
    }

    this.state = {
      pricingTable: pricingTableConfig,
      promoPeriod: getPromoPeriod(this.props.priceList)
    }
  }

  componentDidMount () {
    this.handleCouponError()
  }

  renderFeatureItem (imgSrc, header, text) {
    const { theme } = this.props

    return (
      <div className={cn('features-item', null, 'pfx--flex pfx--flex-column pfx--flex-center pfx--align-center')}>
        <img
          src={imgSrc}
          alt={text}
          className={cn('features-item-icon', null, theme)}
        />
        <h3 className={cn('features-item-header', null, theme)}>
          {header}
        </h3>
        <p className={cn('features-item-paragraph', null, theme)}>{text}</p>
      </div>
    )
  }

  handleCouponError () {
    const { setFlashMessageByValues, priceList } = this.props

    if (priceList.error) {
      setFlashMessageByValues({ text: priceList.error.message, autoclose: false })
    }
  }

  render () {
    const { theme, currentUser, chargebeeDomain, accountSubscription, queryParams } = this.props

    return (
      <div className={cn()}>
        <div className={cn('pricing-table-wrapper')}>
          <div className={cn('table-container', null, 'container')} style={{ padding: 0 }}>
            <PricePlans
              items={this.state.pricingTable}
              currentUser={currentUser}
              selectedPeriod={this.state.promoPeriod}
              isUserAuthenticated={!!this.props.currentUser}
              accountSubscription={accountSubscription}
              chargebeeDomain={chargebeeDomain}
              queryParams={queryParams}
              theme={theme}
            />
          </div>
        </div>

        <div className={cn('section', null, theme)} id='pfx-premium-features'>
          <div className='container'>
            <h1 className={`pfx--align-center ${theme}`}>
              All plans include
            </h1>

            <div className='row'>
              <div className={cn('features-column', null, 'col-xs-3')}>
                {
                  this.renderFeatureItem(
                    instrumentsAndEffectsSvg,
                    'Instruments & effects',
                    'Easy-to-use drum machines and synthesizers. Shape your' +
                    ' unique sound with effects such as delay, reverb and EQ.'
                  )
                }
              </div>
              <div className={cn('features-column', null, 'col-xs-3')}>
                {
                  this.renderFeatureItem(
                    automationSvg,
                    'Automation',
                    'Automate any knob or slider to add movement and life to your mix.'
                  )
                }
              </div>
              <div className={cn('features-column', null, 'col-xs-3')}>
                {
                  this.renderFeatureItem(
                    midiControllerSvg,
                    'MIDI controller',
                    'Control Soundation with your MIDI device and customize mappings.'
                  )
                }
              </div>
              <div className={cn('features-column', null, 'col-xs-3')}>
                {
                  this.renderFeatureItem(
                    exportSvg,
                    'Export',
                    'Finalize and export your tracks. Export MIDI to collaborate in Soundation alongside any desktop DAW.'
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFlashMessageByValues
}

PricingView.propTypes = {
  queryParams: PropTypes.shape({
    couponCode: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.object,
  priceList: PropTypes.object,
  theme: PropTypes.oneOf(['light', 'dark'])
}
PricingView.defaultProps = {
  theme: 'dark'
}

const mapStateToProps = state => {
  return {
    activeRecurringSubscription: get(state, 'backendData.currentUser.activeRecurringSubscription')
  }
}
export default createProvider(connect(mapStateToProps, mapDispatchToProps)(PricingView))
