import React from 'react'
import './carousel.scss'
import PropTypes from 'prop-types'
import { Bem, get } from '@common/utils'
import { range } from 'lodash'
import { ViewPager, Frame, Track, View } from 'react-view-pager'
import createProvider from "../../store/provider";
import {connect} from "react-redux";
import renderProgressBar from './components/progress-bar.jsx'
import renderTopControls from './components/top-controls.jsx'
import SingleViewPlaceholder from './components/single-view-placeholder'
import {endDragging, startDragging} from "../../store/actions/app";
import {populateArray} from "../../common/utils";

const cn = new Bem({
  name: 'carousel',
  prefix: 'pfx-'
})

export default class Carousel extends React.Component {
  static VIEWS_TO_SHOW_BY_SCREEN_SIZE = [4, 4, 3, 3, 3]
  static VIEWS_TO_MOVE_BY_SCREEN_SIZE = [4, 4, 3, 3, 3]

  static propTypes = {
    title: PropTypes.string,
    onViewAllClick: PropTypes.func,
    viewsToMove: PropTypes.number.isRequired,
    viewsToShow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    renderProgressBar: PropTypes.func,
    renderTopControls: PropTypes.func,
    renderSingleView: PropTypes.func,
    children: PropTypes.array,
    className: PropTypes.string,
    theme: PropTypes.string,
    infinite: PropTypes.bool,
    contain: PropTypes.bool,
    margin: PropTypes.number,
    onViewChange: PropTypes.func
  }

  static defaultProps = {
    viewsToShow: 4,
    viewsToMove: 2,
    renderProgressBar,
    renderTopControls,
    theme: 'dark',
    infinite: true,
    contain: true
  }

  constructor(props) {
    super(props)
    this.state = {
      activeIndices: range(props.viewsToShow)
    }
  }

  onViewChange = (activeIndices) => {
    this.setState({activeIndices})
    this.props.onViewChange && this.props.onViewChange(activeIndices)
  }

  renderChildren(children = []) {
    const {viewsToShow} = this.props
    let newChildren = children

    if (children.length < viewsToShow) {
      const diff = viewsToShow - children.length
      const placeholders = populateArray(diff, (idx) => <SingleViewPlaceholder key={`placeholder-${idx}`} />)
      newChildren = [...children, ...placeholders]
    }

    return React.Children.map(newChildren, (child, idx) => {
      return (
        <View key={idx} className={cn('view')}>{child}</View>
      )
    })
  }

  onSwipeMove = () => {
    const {dispatch, startDragging} = this.props
    dispatch(startDragging())
  }

  onSwipeEnd = () => {
    const {dispatch, endDragging, dragging} = this.props
    if(dragging) {
      setTimeout(() => {
        dispatch(endDragging())
      })
    }
  }

  componentDidMount () {
    this.props.onViewChange && this.props.onViewChange(this.state.activeIndices)
  }

  render() {
    const {
      viewsToMove,
      viewsToShow,
      title,
      onViewAllClick,
      renderTopControls,
      renderProgressBar,
      children,
      className,
      theme
    } = this.props

    const style = {}
    if (this.props.margin) {
      style['--margin'] = this.props.margin + 'px'
    }

    const { activeIndices, track } = this.state;
    const viewsCount = children.length
    const shouldRenderProgressBar = renderProgressBar && (viewsCount > viewsToShow || viewsToShow === 'auto')
    const shouldRenderTopControlsActions = renderTopControls && (viewsCount > viewsToShow || viewsToShow === 'auto')
    return (
      <div className={cn(null, 'theme-' + theme, className)} style={style}>
        <ViewPager>
          { renderTopControls && renderTopControls({title, onViewAllClick, track, shouldRenderTopControlsActions, viewsToShow, viewsCount, activeIndices, theme}) }
          <Frame className={cn('frame')}>
            <Track
              className={cn('track')}
              ref={track => !this.state.track && this.setState({track})}
              viewsToShow={viewsToShow}
              viewsToMove={(!renderProgressBar || shouldRenderProgressBar) ? viewsToMove : 0}
              onViewChange={this.onViewChange}
              onSwipeStart={this.onSwipeStart}
              onSwipeMove={this.onSwipeMove}
              onSwipeEnd={this.onSwipeEnd}
              onScroll={this.onScroll}
              infinite={this.props.infinite}
              contain={this.props.contain}>
              {this.renderChildren(children)}
            </Track>
          </Frame>
          { shouldRenderProgressBar && renderProgressBar({viewsCount, activeIndices, viewsToShow}) }
        </ViewPager>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    viewsToShow: (ownProps.viewsByScreenSize || Carousel.VIEWS_TO_SHOW_BY_SCREEN_SIZE)[get(state, 'app.screenBreakPointIndex')],
    viewsToMove: (ownProps.viewsByScreenSize || Carousel.VIEWS_TO_MOVE_BY_SCREEN_SIZE)[get(state, 'app.screenBreakPointIndex')],
    margin: ownProps.marginByScreenSize ? ownProps.marginByScreenSize[get(state, 'app.screenBreakPointIndex')] : undefined,
    dragging: get(state, 'app.dragging'),
    ...ownProps
  }
}

const mapDispatchToPros = {
  startDragging,
  endDragging
}

export const CarouselResponsive = createProvider(connect(mapStateToProps, mapDispatchToPros)(Carousel), false)
