import React from "react";
import { findDOMNode } from "react-dom";
import createProvider from "../../../store/provider";
import { connect } from "react-redux";
import { canUseDOM, get } from "../../../common/utils";

export const withBindHeightToWidth = (WrappedComponent) => {
  class WithBindHeightToWidth extends React.Component {
    observer = undefined;

    componentDidMount() {
      this.updateStyle();
      if (canUseDOM()) {
        setTimeout(() => {
          this.updateStyle();
        }, 2000);
      }
    }

    componentDidUpdate() {
      this.updateStyle();
    }

    componentWillUnmount() {
      if (this.observer) {
        this.observer.unobserve();
      }
    }

    setRef = (r) => {
      if (!r) return;
      this.wrapperElement = findDOMNode(this.wrapperRef);

      if (canUseDOM() && this.wrapperRef) {
        if (this.observer) {
          this.observer.unobserve();
        }

        this.observer = new window.ResizeObserver(([entry]) => {
          this.updateStyle();
        });

        this.observer.observe(this.wrapperRef);
      }
    };

    updateStyle = () => {
      if (canUseDOM()) {
        window.requestAnimationFrame(() => {
          if (this.wrapperRef) {
            if (this.wrapperRef.clientWidth) {
              this.wrapperRef.style.height = this.wrapperRef.clientWidth + "px";
            } else if (this.wrapperRef.style.clientHeight) {
              this.wrapperRef.style.width = this.wrapperRef.clientHeight + "px";
            }
          }
        });
      }
    };

    render() {
      const { windowSize, ...passThroughProps } = this.props;
      if (WrappedComponent) {
        return <WrappedComponent {...passThroughProps} ref={this.setRef} />;
      }

      return <div ref={this.setRef} />;
    }
  }

  const mapStateToProps = (state) => {
    return {
      windowSize: get(state, "app.windowSize"),
    };
  };

  return createProvider(
    connect(mapStateToProps, null)(WithBindHeightToWidth, false)
  );
};
