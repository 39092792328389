import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Bem } from '@common/utils'
import './style.scss'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'linkton',
})

export default WrappedComponent => class LinktonWithIcon extends Component {

  static propTypes = {
    icon: PropTypes.string.isRequired
  }

  static defaultProps = WrappedComponent.defaultProps

  render () {
    const { icon, children, className, ...restProps } = this.props
    const { size, padding } = this.props

    return <WrappedComponent className={cn('', 'with-icon', className)} {...restProps}>
      <div className={cn('icon-wrapper')}>
        <i className={cn('icon', '', 'icon-' + icon)}/>
      </div>
      <div className={cn('content-wrapper', ['size-' + size, 'padding-' + padding])}>
        { children }
      </div>
    </WrappedComponent>
  }

}