import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '../../common/utils'

const cn = new Bem({
  name: 'list',
  prefix: 'pfx-'
})

const LIST_TYPES = new Enum('GRADIENT', 'WHITE', 'BLACK')

export default class List extends React.Component {
  render () {
    const { type } = this.props

    const children = this.props.children.map((child) => {
      return React.cloneElement(child, { type }, child.props.children)
    })

    return (
      <ul className={cn(null, this.props.type, this.props.className)}>
        { children }
      </ul>
    )
  }
}

List.propTypes = {
  children: PropTypes.any,
  type: PropTypes.oneOf([
    LIST_TYPES.GRADIENT,
    LIST_TYPES.WHITE,
    LIST_TYPES.BLACK
  ]),
  className: PropTypes.string
}
List.defaultProps = {
  type: LIST_TYPES.GRADIENT
}

export class ListItem extends React.Component {
  render () {
    const { disabled } = this.props
    const style = {}
    const modifiers = [this.props.type]

    if (this.props.onClick) {
      style.cursor = 'pointer'
    }

    if (disabled) {
      modifiers.push('disabled')
    }

    return (
      <li onClick={this.props.onClick}
        className={cn('item', modifiers, this.props.className)}> { this.props.children } </li>
    )
  }
}

ListItem.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func
}
ListItem.defaultProps = {}
