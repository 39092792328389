import { Bem } from "@common/utils";

const cn = new Bem({
  name: "multi-list-item",
  prefix: "pfx-",
});

export const multiListItemCnFn = (type) => cn(undefined, type);

export const multiListItemChildCn = cn("child");

export const multiListItemStyleCnFn = (type) => cn("style", type);
