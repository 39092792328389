import { Component } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import BackgroundVideo from '../background-video'
import createProvider from '../../store/provider'
import {connect} from 'react-redux'
import { get } from '@common/utils'
import {AspectRatio} from "../../common/imageDimensions";

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-collab-live'
})

const images = {
  desktop: {
    src: 'https://cdn.soundation.com/rtc_landing/collab-desktop-still.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/collab-desktop-still.png',
    alt: 'Collab Live',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/collab-desktop-still.png'))
  },
  mobile: {
    src: 'https://cdn.soundation.com/rtc_landing/collab-mobile-still.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/collab-mobile-still.png',
    alt: 'Collab Live',
    aspectRatio: AspectRatio.fromDimensions(require('image-dimensions-loader!@images/landing/collab-mobile-still.png'))
  },
}


class LandingCollabLive extends Component {

  getVideoUrl = () => {
    if (this.props.screenBreakPointIndex === 4) {
      return [
        'https://cdn.soundation.com/rtc_landing/collab-mobile.webm',
        'https://cdn.soundation.com/rtc_landing/collab-mobile.mp4'
      ]
    } else {
      return [
        'https://cdn.soundation.com/rtc_landing/collab-desktop.webm',
        'https://cdn.soundation.com/rtc_landing/collab-desktop.mp4'
      ]
    }
  }

  getPosterImage = () => {
    if (this.props.screenBreakPointIndex === 4) {
      return images.mobile
    } else {
      return images.desktop
    }
  }

  render () {
    const posterImage = this.getPosterImage()

    return (
      <div {...bem()} style={{paddingTop: posterImage.aspectRatio.toPaddingTop()}}>
        <BackgroundVideo
          urls={this.getVideoUrl()}
          poster={posterImage}
          {...bem('video')}
        />
        <div {...bem('content')}>
          <h2 {...bem('header')}>Collab Live.</h2>
          <p {...bem('description')}>
            Team up and produce music with friends in multiplayer mode — 100% synced in real-time.
          </p>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ screenBreakPointIndex: get(state, 'app.screenBreakPointIndex') })

export default createProvider(
  connect(mapStateToProps)(LandingCollabLive)
)
