import React from "react";
import PropTypes from "prop-types";
import Markdown from "markdown-to-jsx";
import { Bem } from "@common/utils";
import { getStrapiMediaUrl } from "@common/strapi.helpers";
import MultiList, { MultiListItem } from "@components/multi-list";

import "./RenderMarkdown.style.scss";
import LazyImage from "../lazy-image";

const cn = new Bem({
  name: "render-markdown",
  prefix: "pfx-",
});

const getTagAttributes = (attributes = {}, tag) => {
  if (!tag) return {};
  return attributes[tag] || {};
};

const generateOverrides = ({ attributes = {} }) => ({
  img: ({ src, ...props }) => {
    return (
      <LazyImage
        {...props}
        imgClassName={cn("image")}
        cover={false}
        src={getStrapiMediaUrl({ url: src })}
      />
    );
  },
  ol: (props) => (
    <MultiList {...props} className={cn("list", "unordered")} type="ordered" />
  ),
  ul: (props) => (
    <MultiList
      {...props}
      className={cn(
        "list",
        "unordered",
        getTagAttributes(attributes, "ul").className
      )}
      type="unordered"
    />
  ),
  li: {
    component: (props) => {
      return (
        <MultiListItem
          {...props}
          className={getTagAttributes(attributes, "li").className}
        />
      );
    },
  },
  strong: {
    props: {
      className: cn(
        "strong",
        undefined,
        getTagAttributes(attributes, "strong").className
      ),
    },
  },
  p: {
    props: {
      className: cn(
        "paragraph",
        undefined,
        getTagAttributes(attributes, "p").className
      ),
    },
  },
  a: {
    props: {
      className: cn(
        "link",
        undefined,
        getTagAttributes(attributes, "a").className
      ),
    },
  },
  table: {
    props: {
      className: cn(
        "table",
        undefined,
        getTagAttributes(attributes, "table").className
      ),
    },
  },
  blockquote: {
    props: {
      className: cn(
        "blockquote",
        undefined,
        getTagAttributes(attributes, "blockquote").className
      ),
    },
  },
  ...["h1", "h2", "h3", "h4", "h5"].reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: {
        props: {
          className: cn(
            "heading",
            curr,
            getTagAttributes(attributes, curr).className
          ),
        },
      },
    }),
    {}
  ),
});

const RenderMarkdown = (props) => {
  const { markdownContent, attributes } = props;

  return (
    <div className={cn()}>
      <Markdown
        options={{
          wrapper: React.Fragment,
          forceBlock: true,
          overrides: generateOverrides({ attributes }),
        }}
      >
        {markdownContent}
      </Markdown>
    </div>
  );
};

RenderMarkdown.propTypes = {
  markdownContent: PropTypes.string.isRequired,
  attributes: PropTypes.shape({
    h1: PropTypes.shape({
      className: PropTypes.string,
    }),
    h2: PropTypes.shape({
      className: PropTypes.string,
    }),
    h3: PropTypes.shape({
      className: PropTypes.string,
    }),
    p: PropTypes.shape({
      className: PropTypes.string,
    }),
    a: PropTypes.shape({
      className: PropTypes.string,
    }),
    strong: PropTypes.shape({
      className: PropTypes.string,
    }),
    li: PropTypes.shape({
      className: PropTypes.string,
    }),
    ul: PropTypes.shape({
      className: PropTypes.string,
    }),
    ol: PropTypes.shape({
      className: PropTypes.string,
    }),
    blockquote: PropTypes.shape({
      className: PropTypes.string,
    }),
  }),
};

export default RenderMarkdown;
