import { ACCOUNT_TYPES, ACCOUNT_TYPES_IDX, ACCOUNT_TYPES_STR, LINKS, PLAN_PERIOD_STR } from '@common/consts'
import { baseConfig } from './base-config'
import { pick, clone, mapValues, each, invert, isObject, isString, cloneDeep } from 'lodash'
import { gotoLocation, isFreeAccount, isPaidAccount, isReturningUser, get } from '@common/utils'
import { TABS } from '@containers/auth-gateway-view'
import currencies from 'iso-currencies'

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const getFraction = (price) => {
  if (price === null || price === undefined) return null
  let priceFraction = (price.toString().split('.')[1] || '').padEnd(2, '0')

  return priceFraction
}

export const getPriceDecimalString = (fraction) => {
  return parseInt(fraction, 10).toString()
}

export const getPriceIntegerString = (price) => {
  return Math.floor(price).toString()
}

export const getCurrencySymbol = (currency) => {
  return currencies.information(currency).symbol
}

export const getPrice = (backendPrice, shouldProcessPromotion = false) => {
  if (backendPrice.total == null) return getEmptyPrice(backendPrice.currency)
  const currency = backendPrice.currency.toUpperCase()
  const discount = {}

  const totalPrice = backendPrice.total
  const totalDisplayPrice = getDisplayPrice(totalPrice, currency);
  const fraction = getFraction(totalPrice);
  const originalTotalPrice = backendPrice.fullPrice

  if (backendPrice.totalDiscountAmount > 0 && shouldProcessPromotion) {
    discount.fullPrice = backendPrice.fullPrice
    discount.discount = getDisplayPrice(backendPrice.totalDiscountAmount, currency)
    discount.discountPercentage = backendPrice.totalDiscountPercentage.toString()

    discount.couponDiscountAmount = backendPrice.couponDiscountAmount
    discount.couponDiscountPercentage = backendPrice.couponDiscountPercentage.toString()
    discount.refunableCredits = getDisplayPrice(backendPrice.refundableCredits, currency)
    discount.appliedPromoCredits = getDisplayPrice(backendPrice.appliedPromoCredits, currency)
    discount.leftOverPromoCredits = getDisplayPrice(backendPrice.leftOverPromoCredits, currency)
    discount.cycles = backendPrice.cycles || 0
  }

  return {
    currency,
    totalPrice,
    totalDisplayPrice,
    originalTotalPrice,
    fullPrice: originalTotalPrice,
    available: backendPrice.available,
    currencySymbol: getCurrencySymbol(currency),
    totalPriceInt: getPriceIntegerString(backendPrice.total),
    totalPriceFraction: fraction,
    totalPriceDecimal: getPriceDecimalString(fraction),
    period: backendPrice.period,
    ...discount
  }
}

export const getEmptyPrice = (currency) => {
  return getPrice({
    available: false,
    currency: 'usd',
    total: 0,
    fullPrice: 0
  }, currency)
}

export const getDisplayPrice = (price, currency, fractionDigits = 2) => {
  let options = {
    minimumFractionDigits: fractionDigits,
  }
  if (currency) {
    options = {
      ...options,
      style: 'currency',
      currency: currency,
    }
  }
  let text = new Intl.NumberFormat('en-US', options).format(price)

  if (currency && currency.toUpperCase() === 'SEK' && text.includes('SEK')) {
    text = `${text.replace('SEK', '').trim()} kr`;
  }

  return text;
}

export const convertToPeriod = (period = PLAN_PERIOD_STR.MONTHLY) => {
  return (pricePeriod, withPromotion = false) => {
    let total, fullPrice, priceObj

    if (period === PLAN_PERIOD_STR.MONTHLY) {
      total = pricePeriod.total / 12
      fullPrice = pricePeriod.fullPrice && pricePeriod.fullPrice / 12
    } else {
      total = pricePeriod.total * 12
      fullPrice = pricePeriod.fullPrice && pricePeriod.fullPrice * 12
    }

    priceObj = {
      ...pricePeriod,
      total: Math.ceil(total * 100) / 100
    }

    if (pricePeriod.fullPrice) {
      priceObj.fullPrice = Math.ceil(fullPrice * 100) / 100
    }

    return getPrice(priceObj, withPromotion)
  }
}

export const convertYearlyToMonthly = convertToPeriod(PLAN_PERIOD_STR.MONTHLY)
export const convertMonthlyToYearly = convertToPeriod(PLAN_PERIOD_STR.YEARLY)

export function parsePricing (priceList, accountType = -1) {
  const prices = mapValues(priceList, (backendPrice, plan) => {
    const shouldProcessPromotion = ACCOUNT_TYPES_IDX[Number(accountType)] === plan
    const yearly = getPrice(backendPrice.yearly, shouldProcessPromotion)
    const monthly = getPrice(backendPrice.monthly, shouldProcessPromotion)

    return {
      yearly,
      monthly,
      monthlyToYearly: convertMonthlyToYearly({
        available: monthly.available,
        currency: monthly.currency,
        total: monthly.fullPrice || monthly.totalPrice,
        fullPrice: monthly.fullPrice,
      }),
      yearlyToMonthly: convertYearlyToMonthly({
        available: yearly.available,
        currency: yearly.currency,
        total: yearly.fullPrice || yearly.totalPrice,
        fullPrice: yearly.fullPrice,
      }),
      discountedYearlyToMonthly: convertYearlyToMonthly(backendPrice.yearly, true),
      plan
    }
  })
  return prices
}

export const isMonthlyIntro = (period, type) => {
  // special case -> intro doesn't have a total price
  return period === PLAN_PERIOD_STR.MONTHLY && type === ACCOUNT_TYPES.INTRO
}

export const getButtonLabel = ({ name, columnConfig } = {}, user, { available: isAvailable, period, couponDiscountAmount, currency } = {}, activeRecurringSubscription) => {
  const { plan } = columnConfig
  const userAccountType = get(user, 'accountType')

  if (plan === ACCOUNT_TYPES_STR.FREE) {
    return 'Start with the Free plan'
  }

  if (!user) {
    return `Get the ${name} plan`
  }

  if (activeRecurringSubscription && plan === activeRecurringSubscription.account && period === activeRecurringSubscription.frequency) {
    return 'Your current plan'
  }

  if (!isAvailable) {
    return 'Unavailable'
  }

  // for promotions
  if (couponDiscountAmount !== undefined && couponDiscountAmount > 0) {
    return `Save ${couponDiscountAmount} ${currency.toUpperCase()}`
  }

  // for free accounts
  if (isFreeAccount(userAccountType)) {
    return `Get the ${name} plan`
  }

  // for paid accounts
  if (isPaidAccount(userAccountType)) {
    if (activeRecurringSubscription && activeRecurringSubscription.status === 'in_trial') {
      return `Switch to ${name}`
    } else {
      return `Get the ${name} plan`
    }
  }
}

export const isUpgrade = (user, available) => {
  const userAccountType = get(user, 'accountType')
  // Coupons only available for free accounts
  return userAccountType > 0 && available
}

export const canUpgrade = (user, available) => {
  const userAccountType = get(user, 'accountType')
  return userAccountType >= 0 && available
}

export const getPromoPeriod = (prices) => {
  const pickedPlans = pick(prices, [ACCOUNT_TYPES_STR.FREE, ACCOUNT_TYPES_STR.INTRO, ACCOUNT_TYPES_STR.PREMIUM, ACCOUNT_TYPES_STR.STARTER, ACCOUNT_TYPES_STR.CREATOR, ACCOUNT_TYPES_STR.PRO])
  let result

  each(pickedPlans, (plan) => {
    if (result) return false

    each(plan, (period, periodKey) => {
      if (period.couponType) {
        result = periodKey
        return false
      }
    })
  })

  return result
}

export const prepareTableConfig = ({
  prices,
  user,
  couponCode,
  accountSubscription,
  referralId,
  closeAfterAuthentication
}) => {
  let accountType = get(prices, 'coupon.accountType')
  const config = cloneDeep(baseConfig)

  if (isString(accountType)) {
    const upperCasedAccount = accountType.toUpperCase()
    accountType = ACCOUNT_TYPES[upperCasedAccount]

    each(config, (configItem, account) => {
      configItem.highlight = account === upperCasedAccount
    })
  }

  const parsedPrices = parsePricing(pick(prices, [
    ACCOUNT_TYPES_STR.FREE,
    ACCOUNT_TYPES_STR.INTRO,
    ACCOUNT_TYPES_STR.PREMIUM,
    ACCOUNT_TYPES_STR.STARTER,
    ACCOUNT_TYPES_STR.CREATOR,
    ACCOUNT_TYPES_STR.PRO,
    ACCOUNT_TYPES_STR.EUROPA,
  ]), accountType)
  const tableItems = [config.FREE, config.STARTER, config.CREATOR, config.PRO, config.EUROPA]
  const ACCOUNT_TYPES_REVERSE = invert(ACCOUNT_TYPES)

  let results = tableItems.map((item) => {
    item.columnConfig = clone(parsedPrices[ACCOUNT_TYPES_REVERSE[item.type].toLowerCase()])

    each(item.columnConfig, (periodData, periodName, parent) => {
      if (isObject(periodData)) {
        const monthlyIntro = isMonthlyIntro(periodName, item.type)
        const returningUser = isReturningUser()
        const planId = `${parent.plan}_${periodData.period}_${periodData.currency.toLowerCase()}`
        // special case with intro monthly
        periodData.buttonLabel = monthlyIntro ? 'Only available annually' : getButtonLabel(item, user, periodData, accountSubscription)
        // TODO: fix redirections and prepare them for coupon handling
        periodData.onLoginRequired = (link = LINKS.AUTH) => gotoLocation(
          link,
          {
            tab: (returningUser && couponCode ? TABS.SIGN_IN : TABS.SIGN_UP),
            plan: parent.plan,
            period: periodName,
            product_type: 'subscription',
            referral_id: referralId,
            coupon_code: couponCode,
            close_after_authentication: closeAfterAuthentication
          }
        )
        periodData.shouldDisable = monthlyIntro || !periodData.available
        periodData.isUpgrade = isUpgrade(user, periodData.available)
        periodData.planId = planId
        periodData.couponCode = couponCode
      }
    })

    return item
  })

  results = results.filter((item) => item.columnConfig)
  return results
}
