import React from 'react'
import './with-selectable.scss'
import {Bem} from "@common/utils";
import Icon from "../icon/index";

const cn = new Bem({
  name: 'with-selectable',
  prefix: 'pfx-'
})

export const withSelectable = (WrappedComponent) => {
  class WithSelectable extends React.Component {

    static propTypes = {
      isSelected: false,
    }

    constructor(props) {
      super(props)
      this.state = {
        isSelected: props.isSelected
      }
    }

    componentWillReceiveProps(nextProps) {
      this.state.isSelected = nextProps.isSelected
    }


    handleOnClick = () => {
      const { isSelected } = this.state;
      this.setState({isSelected: !isSelected})
    }

    render() {
      const {
        className,
        ...passThroughProps
      } = this.props;

      const {isSelected} = this.state
      
      return (
        <div className={cn(null, null, className)} onClick={() => this.handleOnClick()}>
          <div className={cn('overlay', {selected: isSelected})}>
            <Icon icon='new-tick' size={20} />
          </div>
          <WrappedComponent {...passThroughProps} />
        </div>
      )
    }
  }

  return WithSelectable
}