import { useCallback, useEffect, useRef, useState } from 'react'
import parse, { domToReact } from 'html-react-parser'
import createProvider from '@store/provider'
import { connect, useSelector } from 'react-redux'
import { selectCurrentUser } from '@store/selectors/backendData'
import { reportUser } from '@common/http'
import useIntersection from '@root/hooks/useIntersection'
import Icon from '@components/icon'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import DottedMenu from '@components/dotted-menu'
import Spinner from '@components/spinner'
import dayjs from 'dayjs'
import * as styles from './CommunityComments.module.scss'

const COMMENT_STATUS = {
  Approved: { status: 0, statusText: 'Approved' },
  Flagged: { status: 1, statusText: 'Flagged' },
  Abuse: { status: 2, statusText: 'Abuse' },
  Spam: { status: 3, statusText: 'Spam' },
  Inappropriate: { status: 4, statusText: 'Inappropriate' },
  Settled: { status: 5, statusText: 'Settled' }
}

function CommunityComments (props) {
  const { comments = [], isAdmin, fetchPage, onDeleteComment } = props
  const lastCommentRef = useRef(null)

  const currentUser = useSelector(selectCurrentUser)

  const [dropdown, setDropdown] = useState({ openForCommentId: null })
  const [isLoading, setIsLoading] = useState(false)
  const [nextPage, setNextPage] = useState(1)

  const toggleDropdown = (id) => {
    setDropdown(({ openForCommentId }) => {
      if (openForCommentId === id) return { openForCommentId: null }
      return { openForCommentId: id }
    })
  }

  const fetchNextPage = useCallback(() => {
    if (nextPage === null || isLoading) return

    setIsLoading(true)
    fetchPage(nextPage)
      .then(({ nextPage }) => setNextPage(nextPage))
      .finally(() => setIsLoading(false))
  }, [nextPage, isLoading])
  // fetch first page on mount
  useEffect(() => { fetchNextPage() }, [])
  // fetch more comments as the user gets closer to the bottom
  useIntersection({ callback: fetchNextPage, elementRef: lastCommentRef })

  const setLastCommentRef = useCallback((r) => { lastCommentRef.current = r }, [])

  const renderContent = (content) => {
    const renderChild = (domNode) => {
      if (domNode.children.length === 1 && domNode.children[0].type === 'text') {
        return domNode.children[0].data
      }

      return domToReact(domNode.children, options)
    }

    const options = {
      replace(domNode) {
        if (domNode.type === 'text') return <span>{domNode.data}</span>

        if (domNode.type === 'tag') {
          if (domNode.name === 'a') {
            return <a className={styles.linkInContent} {...domNode.attribs}>{renderChild(domNode)}</a>
          }

          return <span>{renderChild(domNode)}</span>
        }

        return <span>{renderChild(domNode)}</span>
      }
    }

    return (
      parse(content, options)
    )
  }

  if (!comments.length) return null

  return (
    <div>
      {comments.map((comment, i) => {
        if (!comment.user) return null

        const canDelete = currentUser && (isAdmin || comment.user.id === currentUser.guid)
        const canReport = comment.status === COMMENT_STATUS.Approved.status && currentUser && currentUser.guid !== comment.user.id && !comment.user.admin

        return (
          <div ref={i === comments.length - 1 ? setLastCommentRef : null} className={styles.comment} key={comment.id}>
            <div className={styles.avatarAndComment}>
              <a className={styles.avatar} href={comment.user.selfLink}>
                <img className={styles.avatarImg} src={comment.user.avatarSmallUrl} alt={`${comment.user.username} Avatar`} />
              </a>

              <div className={styles.content}>
                <h5>
                  <a className={styles.commentAuthor} href={comment.user.selfLink}>{comment.user.username}</a>
                  <span className={styles.age}>{dayjs(comment.createdAt).fromNow()}</span>
                </h5>

                {!!comment.user.admin && <div className={styles.crewTag}>Soundation Crew</div>}

                <h5 className={styles.commentContent}>
                  {comment.status > COMMENT_STATUS.Flagged.status && comment.status < COMMENT_STATUS.Settled.status ? (
                    <span class={styles.flaggedComment}>{comment.statusText}</span>
                  ) : renderContent(comment.content)}
                </h5>
              </div>
            </div>

            {(canReport || canDelete) && (
              <DropdownOrBottomSheet
                dropdownClassName={styles.dropdown}
                isOpen={dropdown.openForCommentId === comment.id}
                toggler={<DottedMenu />}
                onToggle={(value) => toggleDropdown(!value ? null : comment.id)}
              >
                {canDelete && (
                  <a className={styles.dropdownMenuItem} href='' onClick={() => onDeleteComment(comment.id)} rel='nofollow'>
                    <Icon icon='trash' size={20} />
                    <span>Delete</span>
                  </a>
                )}
                {canReport && (
                  <a href='' onClick={() => reportUser(comment.user.id)} className={styles.dropdownMenuItem}>
                    <Icon icon='Block' size={20} />
                    <span>Report</span>
                  </a>
                )}
              </DropdownOrBottomSheet>
            )}
          </div>
        )
      })}

      {isLoading && <Spinner className={styles.spinner} />}
    </div>
  )
}

export default createProvider(connect(null)(CommunityComments, false))
