import React, { useMemo } from 'react'
import { LINKS } from '@common/consts'
import parse, { domToReact } from 'html-react-parser'
import CommunityCard from '../community-card'

const CommunityGroupCard = React.forwardRef((props, ref) => {
  const { group, isSubPage = false } = props

  const description = useMemo(() => {
    if (!group.description) return null

    const renderChild = (domNode) => {
      if (domNode.children.length === 1 && domNode.children[0].type === 'text') {
        return domNode.children[0].data
      }

      return domToReact(domNode.children, options)
    }

    const options = {
      replace (domNode) {
        if (domNode.type === 'text') return <span>{domNode.data}</span>
        return <span>{renderChild(domNode)}</span>
      }
    }

    return (
      parse(group.description, options)
    )
  }, [group.description])

  return (
    <div ref={ref}>
      <CommunityCard
        isSubPage={isSubPage}
        picture={{ src: group.pictureUrl, alt: group.name, aspectRatio: '4x3' }}
        link={group.selfLink || `${LINKS.GROUP}/${group.groupLink}`}
        title={group.name}
        subtitle={description}
        subtitleLines={2}
        stats={[
          { count: group.followersCount, icon: 'Members' },
          { count: group.tracksCount, icon: 'project-file' },
          { count: group.groupMembershipsCount, icon: 'share' }
        ]}
      />
    </div>
  )
})

export default CommunityGroupCard
