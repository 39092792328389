import './style.scss'
import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Bem, get } from '@common/utils'
import { getFraction, getPriceIntegerString } from '@components/price-plans/utils'

const cn = new Bem({
  name: 'pricing-value',
  prefix: 'pfx-'
})

export default class PricingValue extends PureComponent {
  render () {
    const { price, period, isPromotion, isHighlight } = this.props

    const fullPrice = get(price, 'fullPrice');
    const fullPriceInt = getPriceIntegerString(fullPrice);
    const fullPriceFaction = getFraction(fullPrice);

    const displayPriceInt = isPromotion ? price.totalPriceInt : fullPriceInt;
    const displayPriceFraction = isPromotion ? price.totalPriceFraction : fullPriceFaction;

    return (
      <div className={cn(null, { promotion: isPromotion, highlight: isHighlight })}>
        <div className={cn('price')}>
          <div className={cn('price-currency')}>{price.currencySymbol}</div>
          <div className={cn('price-int')}>{displayPriceInt}</div>
          <div className={cn('price-extra')}>
            <div className={cn('price-decimal')}>{displayPriceFraction}</div>
            <div className={cn('price-period', null, 'pfx-sub-header')}>{`/${period}`}</div>
          </div>
        </div>
        <div className={cn('price-full', this.props.centered ? 'center' : null)}>{`${price.currencySymbol}${price.fullPrice}/${period}`}</div>
      </div>
    )
  }
}

PricingValue.defaultProps = {
  displayFullPrice: false,
}

PricingValue.propTypes = {
  price: PropTypes.object.isRequired,
  period: PropTypes.string.isRequired,
  isPromotion: PropTypes.bool.isRequired,
  isHighlight: PropTypes.bool.isRequired,
  centered: PropTypes.bool,
  displayFullPrice: PropTypes.bool,
}
