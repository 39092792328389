import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import { noop } from 'lodash'

const cn = new Bem({
  name: 'radio-button-group',
  prefix: 'pfx-'
})

class RadioButtonGroup extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      value: this.props.value
    }
  }

  setValue (value) {
    this.setState({ value })
  }

  renderDot (active) {
    return (
      <div className={cn('dot', { active })} />
    )
  }

  render () {
    const wrappedChildren = this.props.children.map((child, i) => {
      const onClick = (e) => {
        if (child.onClick) child.onClick(e)
        this.setValue(child.props.value)
        this.props.onChange(child.props.value)
      }

      const disabled = child.props.disabled
      const active = child.props.value === this.state.value
      return (
        <div className={cn('button', { active, disabled })} key={i} onClick={onClick}>
          { this.renderDot(active) }
          { child }
        </div>
      )
    })

    return (
      <div className={cn(null, this.props.layout, this.props.className)}>
        { wrappedChildren }
      </div>
    )
  }
}

RadioButtonGroup.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  layout: PropTypes.oneOf(['buttons', 'plain'])
}

RadioButtonGroup.defaultProps = {
  onChange: noop,
  layout: 'buttons'
}

export default RadioButtonGroup
