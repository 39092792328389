import { Bem } from '../../../common/utils'

const cn = Bem({
  name: 'mega-dropdown-menu',
  prefix: 'pfx-'
})

export const dropdownMenuCn = cn()

export const dropdownContentCn = cn('content')

export const dropdownColumnCn = cn('column')

export const dropdownParentItem = cn('parent-item')

export const dropdownParentItemInnerCn = cn('parent-item-inner')

export const dropdownParentIcon = cn('parent-icon')

export const dropdownItemListCn = cn('item-list')

export const dropdownItemFn = (modifiers) => cn('item', modifiers)
