import * as React from 'react'
import createStore from '@store/index.jsx'
import { setFlashMessages } from '@store/actions/flashMessage'

const store = createStore()

class BackendProxyFlashMessage extends React.Component {
  componentDidMount () {
    const { backendFlashMessage } = this.props

    const messages = backendFlashMessage.map(flashMessage => {
      const [type, message] = flashMessage
      let autoclose = true, closeButton, text
      if (typeof message === 'object') {
        closeButton = message.closeButton
        autoclose = message.autoclose
        text = message.text
      } else {
        text = message
      }
      if (type && text) {
        return { text, type, autoclose, closeButton }
      }
    })

    messages.sort((a, b) => b.autoclose - a.autoclose)
    setFlashMessages(messages)(store.dispatch)
  }

  render () {
    return null
  }
}

export default BackendProxyFlashMessage
