import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import Logo from '../../components/logo/index'
import { isUserSet } from '../../common/user'

const cn = new Bem({
  name: 'mini-header',
  prefix: 'pfx-'
})

// inverse the logo color
const LogoColor = { light: 'dark', dark: 'light' }

class MiniHeader extends React.Component {
  hasUserData () {
    return isUserSet(this.props.currentUser)
  }

  render () {
    const { theme } = this.props

    return (
      <div className={cn('container', undefined, theme)}>
        <Logo className={cn('logo')} color={LogoColor[theme]} type='logotype' />
      </div>
    )
  }
}

MiniHeader.propTypes = {
  currentUser: PropTypes.object,
  theme: PropTypes.oneOf(['light', 'dark'])
}
MiniHeader.defaultProps = {
  theme: 'dark'
}

export default MiniHeader
