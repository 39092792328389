import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { LINKS, COMMUNITY_CAROUSEL_ITEMS } from '@common/consts'
import createProvider from '@store/provider'
import { connect, useSelector } from 'react-redux'
import { extractUsersFromFollowings } from '@common/utils'
import { selectCurrentUser } from '@store/selectors/backendData'
import { denormalizeResponse, getTrendingUsers, getTopUsers, getUserFollowing, getUserFollowers } from '@common/http'
import usePaginate from '@root/hooks/usePaginate'
import CommunityTitle from '../community-title'
import CommunityUsersSection from '../community-users-section'
import CommunityUserCard from '../community-user-card'
import CommunitySubPage from '../community-sub-page'
import * as styles from './CommunityUsers.module.scss'

const SUBPAGES_LABELS = {
  top_users: 'Top users',
  new_users: 'New users'
}

const CommunityUsers = (props) => {
  const { sort, search } = props

  const isUsersHomePage = !sort && !search

  const currentUser = useSelector(selectCurrentUser)

  const fetchTrending = useCallback(async ({ page }) => {
    return getTrendingUsers({ items: isUsersHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20, page })
  }, [isUsersHomePage])
  const { data: trendingUsers, isLoading: isTrendingLoading, fetchNextPage: fetchTrendingUsers, nextPage: trendingUsersNextPage } = usePaginate({ fetcher: fetchTrending })
  const fetchTop = useCallback(async ({ page }) => {
    return getTopUsers({ items: isUsersHomePage ? COMMUNITY_CAROUSEL_ITEMS : 20, page })
  }, [isUsersHomePage])
  const { data: topUsers, isLoading: isTopLoading, fetchNextPage: fetchTopUsers, nextPage: topUsersNextPage } = usePaginate({ fetcher: fetchTop })
  const fetchFollowed = useCallback(async ({ page }) => {
    const { data } = await getUserFollowing(currentUser.guid, { items: COMMUNITY_CAROUSEL_ITEMS, page })
    const users = extractUsersFromFollowings(data, 'followable')
    return { data: { data: users, included: [], meta: data.meta } }
  }, [currentUser])
  const { data: followedUsers, isLoading: isFollowedLoading, fetchNextPage: fetchFollowedUsers, nextPage: followedUsersNextPage } = usePaginate({ fetcher: fetchFollowed })
  const fetchFollowers = useCallback(async ({ page }) => {
    const { data } = await getUserFollowers(currentUser.guid, { items: COMMUNITY_CAROUSEL_ITEMS, page })
    const users = extractUsersFromFollowings(data, 'follower')
    return { data: { data: users, included: [], meta: data.meta } }
  }, [currentUser])
  const { data: followers, isLoading: isFollowerLoading, fetchNextPage: fetchFollowerUsers, nextPage: followersNextPage } = usePaginate({ fetcher: fetchFollowers })

  useEffect(() => {
    if (isUsersHomePage || sort === 'top_users') fetchTopUsers()
    if (isUsersHomePage || sort === 'trending_users') fetchTrendingUsers()
    if (isUsersHomePage && currentUser) {
      fetchFollowedUsers()
      fetchFollowerUsers()
    }
  }, [currentUser, isUsersHomePage, sort])

  const sections = [
    {
      label: 'Top users',
      users: topUsers,
      isLoading: isTopLoading,
      showMoreLink: topUsersNextPage ? LINKS.TOP_USERS : null
    },
    {
      label: 'Trending users',
      users: trendingUsers,
      isLoading: isTrendingLoading,
      showMoreLink: trendingUsersNextPage ? LINKS.TRENDING_USERS : null
    },
    !!currentUser && {
      label: 'Users you follow',
      users: followedUsers,
      isLoading: isFollowedLoading,
      showMoreLink: followedUsersNextPage ? LINKS.USER_FOLLOWING.replace(':profileLink', currentUser.profileLink) : null
    },
    !!currentUser && {
      label: 'Users following you',
      users: followers,
      isLoading: isFollowerLoading,
      showMoreLink: followersNextPage ? LINKS.USER_FOLLOWERS.replace(':profileLink', currentUser.profileLink) : null
    }
  ].filter(Boolean)

  let subPageTitle
  if (search) subPageTitle = `Search results for "${search}"`
  else if (sort) subPageTitle = SUBPAGES_LABELS[sort]

  const subpage = useMemo(() => {
    if (sort === 'top_users') {
      return {
        users: topUsers,
        fetchNextPage: fetchTopUsers,
        isLoading: isTopLoading
      }
    }
    if (sort === 'trending_users') {
      return {
        users: trendingUsers,
        fetchNextPage: fetchTrendingUsers,
        isLoading: isTrendingLoading
      }
    }
  }, [sort, topUsers, trendingUsers, isTopLoading, isTrendingLoading, fetchTopUsers, fetchTrendingUsers])

  return (
    isUsersHomePage ? (
      <Fragment>
        <CommunityTitle title='Users' />

        {sections.filter(({ users }) => users.data.length).map((section, i) =>
          <CommunityUsersSection key={section.label} users={section.users} label={section.label} showMoreLink={section.showMoreLink} className={`${styles.section} ${i === 0 && styles.sectionFirst}`} />
        )}
      </Fragment>
    ) : (
      <CommunitySubPage
        title={subPageTitle}
        landscapeOnMobile
        fetchNextPage={subpage.fetchNextPage}
        isLoading={subpage.isLoading}
      >
        {denormalizeResponse(subpage.users).map((user) => <CommunityUserCard key={user.id} user={user} isSubPage />)}
      </CommunitySubPage>
    )
  )
}

export default createProvider(connect(null)(CommunityUsers))
