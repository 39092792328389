import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Linkton from '@components/linkton'
import CreateGroupButton from '../../components/create-group-button'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { LINKS } from '../../common/consts'
import { gotoLocation, get } from '../../common/utils'
import * as styles from './CreateGroup.module.scss'

export class CreateGroup extends Component {
  static propTypes = {
    createGroupAllowed: PropTypes.bool,
    type: PropTypes.oneOf(['newGroupHollow', 'newGroupGradient', 'editGroupGrey', 'editGroupOutline', 'editGroupFull']),
    groupLink: PropTypes.string
  }

  getButton () {
    const { createGroupAllowed, type } = this.props

    switch (type) {
      case 'newGroupHollow':
        return <CreateGroupButton type={'hollow'} isAllowed={createGroupAllowed} onClick={() => this.openNewGroupForm()} onUpgradeAccountClick={() => this.upgradeAccount()} />
      case 'newGroupGradient':
        return <CreateGroupButton type={'gradient'} isAllowed={createGroupAllowed} onClick={() => this.openNewGroupForm()} onUpgradeAccountClick={() => this.upgradeAccount()} />
      case 'editGroupGrey':
        return <a className={'pfx-button pfx-button--admin'} onClick={this.openEditGroupForm}>Edit</a>
      case 'editGroupOutline':
        return <Linkton type='outline' color='primary' icon='new-pencil-edit' iconPlacement='left' onClick={() => this.openEditGroupForm()}>Edit group</Linkton>
      case 'editGroupFull':
        return <Linkton type='full' color='primary' icon='new-pencil-edit' iconPlacement='left' onClick={() => this.openEditGroupForm()}>Edit group</Linkton>
    }
  }

  upgradeAccount () {
    gotoLocation(LINKS.AUTH_PLANS)
  }

  openNewGroupForm () {
    window.open(LINKS.GROUP_NEW, '_top')
  }

  openEditGroupForm () {
    const { groupLink } = this.props
    window.open(LINKS.GROUP_EDIT.replace(':id', groupLink), '_top')
  }

  render () {
    return <div className={styles.container}>
      { this.getButton() }
    </div>
  }
}

const mapStateToProps = state => {
  return {
    createGroupAllowed: get(state, 'backendData.acl.creatingGroupsPermitted', false)
  }
}

export default createProvider(connect(mapStateToProps)(CreateGroup))
