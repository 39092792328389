import React from 'react'
import './comments.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '@common/utils'
import { map } from 'lodash'
import Button from "../button/index";
import FormElement from "../form-element/index";
import Form from "../form/index";
import {JUST_REQUIRED} from "../../common/validator-models";
import TextArea from "../text-area/text-area";
import Comment from './comment.jsx'

const cn = new Bem({
  name: 'comments',
  prefix: 'pfx-'
})

const commentFormScheme = {
  content: {
    validation: JUST_REQUIRED
  }
}

class Comments extends React.Component {
  state = {}

  async onFormSubmit(form) {
    if (form.isValid) {
      this.setState({isLoading: true})
      await this.props.onPost(form.values)
      this.refs.form.reset()
      this.setState({isLoading: false})
    }
  }

  handleAbuseReport(commentId) {
    const reason = prompt("Please describe why you want to report this user");
    const {onReport} = this.props;
    if (reason) {
      onReport({reason, commentId})
    }
  }

  handleDelete(commentId) {
    const {onDelete} = this.props;
    if(confirm("Are you sure you want to delete this comment?")) {
      onDelete(commentId)
    }
  }

  render () {
    const {
      canPost,
      onFetchMore,
      commentsList,
      className,
      canDelete,
      totalCommentsCount,
      shouldShowViewMore
    } = this.props

    const shouldShowList = !!totalCommentsCount
    const shouldShowHeader = shouldShowList || canPost

    return (
      <div className={cn(null, null, className)}>
        {shouldShowHeader && <h3 className={cn('header', null, 'pfx--white')}>
          {`Comments (${totalCommentsCount})`}
        </h3>}
        {canPost && <div className={cn('form-container')}>
          <Form
            scheme={commentFormScheme}
            onSubmit={(form) => this.onFormSubmit(form)}
            className={cn('form')}
            ref='form'>
            <FormElement field='content' className={cn('form-element')}>
              <TextArea
                className={cn('textarea', null, 'pfx-textarea')}
                placeholder='Join the discussion'>
              </TextArea>
            </FormElement>
            <FormElement submit className={cn('form-element', 'submit')}>
              <Button
                type='outline'
                isLoading={this.state.isLoading}
                className={cn('Post comment')}>Post comment</Button>
            </FormElement>
          </Form>
        </div>}
        {shouldShowList && <div className={cn('list')}>
          {map(commentsList, ({createdAt, content, username, avatarUrl, profileLink, id}, idx) => (
            <Comment
              key={idx}
              createdAt={createdAt}
              content={content}
              username={username}
              avatarUrl={avatarUrl}
              profileLink={profileLink}
              id={id}
              onDelete={(id) => this.handleDelete(id)}
              onAbuseReport={(id) => this.handleAbuseReport(id)}
              canDelete={canDelete}
            />
          ))}
        </div>}
        {shouldShowViewMore && shouldShowViewMore && <div className={cn('view-more')} onClick={() => onFetchMore()}>
          View more comments
        </div>}
      </div>
    )
  }
}

Comments.propTypes = {
  canPost: PropTypes.bool,
  onPost: PropTypes.func,
  onFetchMore: PropTypes.func,
  onDelete: PropTypes.func,
  onReport: PropTypes.func,
  totalCommentsCount: PropTypes.number,
  canDelete: PropTypes.bool,
  commentsList: PropTypes.arrayOf(PropTypes.shape({
    createdAt: PropTypes.string,
    content: PropTypes.string,
    username: PropTypes.string,
    avatarUrl: PropTypes.string,
    profileLink: PropTypes.string,
    id: PropTypes.number
  })),
  shouldShowViewMore: PropTypes.bool
}

Comments.defaultProps = {
  canPost: true,
  onPost: () => Promise.resolve(),
  onFetchMore: () => Promise.resolve(),
  onDelete: () => Promise.resolve(),
  onReport: () => Promise.resolve(),
  commentsList: [],
  canDelete: false,
}

export default Comments
