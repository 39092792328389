'use client'
import './style.scss'
import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Bem, Enum } from '../../common/utils'
import SlideBar from '../../components/slide-bar/index'
import Icon from '../../components/icon/index'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import { closeMobileLimitationSidebar, openMobileLimitationSidebar } from '../../store/actions/app'
import { NO_MOBILE_APP, NO_MOBILE_APP_REMIX } from './templates'

const cn = new Bem({
  name: 'mobile-studio-limitation-sidebar',
  prefix: 'pfx-'
})

export const LIMITATION_SIDEBAR = new Enum('NO_MOBILE_APP', 'NO_MOBILE_APP_REMIX')

const templates = new Map([
  [LIMITATION_SIDEBAR.NO_MOBILE_APP, NO_MOBILE_APP],
  [LIMITATION_SIDEBAR.NO_MOBILE_APP_REMIX, NO_MOBILE_APP_REMIX]
])

class MobileStudioLimitationSidebar extends React.Component {

  get isOpen () {
    const { open = false } = get(this.props, 'mobileLimitationSide', {})
    const { isMobileMenuOpen } = this.props

    return open && !isMobileMenuOpen
  }

  get componentName () {
    const { componentName = LIMITATION_SIDEBAR.NO_MOBILE_APP } = get(this.props, 'mobileLimitationSide', {})
    return componentName
  }

  render () {
    return (
      <SlideBar className={cn()} open={this.isOpen}>
        <Icon
          className={cn('icon')}
          icon='new-close'
          size={20}
          onClick={() => {
            this.props.closeMobileLimitationSidebar()
          }}
        />
        {templates.get(this.componentName) && templates.get(this.componentName)()}
      </SlideBar>
    )
  }
}

MobileStudioLimitationSidebar.propTypes = {
  mobileLimitationSide: PropTypes.shape({
    open: PropTypes.bool,
    componentName: PropTypes.string
  })
}

const mapStateToProps = state => {
  return {
    mobileLimitationSide: get(state, 'app.mobileLimitationSide'),
    isMobileMenuOpen: get(state, 'app.mobileMenuOpen')
  }
}

const mapDispatchToProps = {
  closeMobileLimitationSidebar,
  openMobileLimitationSidebar
}

export default createProvider(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MobileStudioLimitationSidebar)
)
