import { useCallback, useEffect, useMemo, useState } from 'react'

// use `usePaginate` if you want a hook with pagination. This is only for single requests. You can either make the request immediately, or wait for the user to call it.
const useRequest = ({ callback, callImmediately = true, processResponse }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState()
    const [response, setResponse] = useState()

    const makeRequest = useCallback(() => {
        setIsLoading(true)
        callback()
            .then((res) => {
                if (processResponse) setResponse({ ...res, ...processResponse(res) })
                else setResponse(res)
            })
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false))
    }, [callback, processResponse])

    useEffect(() => {
        if (callImmediately) makeRequest()
    }, [])

    const nextPage = useMemo(() => {
        if (!response) return null

        const { nextPage, next_page } = response.data?.meta || {}
        return nextPage ?? next_page ?? null
    }, [response])

    return { isLoading, error, response, data: response?.data, nextPage, makeRequest }
}

export default useRequest
