import createProvider from '@store/provider'
import { connect } from 'react-redux'
import InlinePlayer from '@components/inline-player/modern'
import CompactPlayer from '@components/inline-player/compact'
import Linkton from '@components/linkton'
import Waveform from '@components/waveform'
import CommunityLikeButton from '../community-like-button'
import useOpen from '@hooks/useOpen'
import useCommunityTrackLike from '@hooks/community/useCommunityTrackLike'
import { LINKS, COMMUNITY_BREAKPOINT } from '@common/consts'
import { isFreeAccount } from '@common/utils'
import { denormalizeResponse } from '@common/http'
import CommunityShareTrack from '../community-share-track'
import * as styles from './CommunityTrackPlayer.module.scss'

function CommunityTrackPlayer (props) {
  const { mixdown, currentUser } = props
  const track = denormalizeResponse(mixdown)

  const { isOpen: isShareOpen, close: closeShareModal, open: openShareModal } = useOpen()

  const { toggle: toggleTrackLike, isLiked } = useCommunityTrackLike({ fullTrackLink: track.selfLink, initialIsLiked: track.currentUserLiked })

  const isOwner = currentUser?.guid === track.user.id
  const isPayingUser = currentUser && !isFreeAccount(currentUser.accountType)

  const match = window.matchMedia(`(min-width: ${COMMUNITY_BREAKPOINT}px)`)
  const isMobile = !match.matches
  const cover = isMobile ? track.imageXlargeUrl : track.imageLargeUrl

  return (
    <div>
      <InlinePlayer
        className={styles.inlinePlayer}
        visible='always'
        id={track.id}
        songUrl={track.audioMp3Url}
        image={{ src: cover }}
        onPlayChange={() => false}
        fileName={track.fileName}
        size='large'
        square
        highlightOnPlay
      />

      <div className={styles.imgAndWaveform}>
        <img className={styles.coverImg} src={cover} />
        <div className={styles.waveformContainer}>
          <Waveform
            directWaveformUrl={track.directWaveformUrl}
            duration={track.duration / 1000}
            showFetchProgress={false}
          />
        </div>
      </div>

      <div className={styles.actions}>
        <CompactPlayer
          className={styles.compactPlayer}
          visible='always'
          songUrl={track.audioMp3Url}
          id={track.id}
          image={{ src: cover }}
          onPlayChange={() => false}
          fileName={track.fileName}
          size='medium'
          square
          highlightOnPlay
          layout='landing2020'
          transparent
        />

        <CommunityLikeButton isLiked={isLiked} toggleLike={toggleTrackLike} />
        <Linkton icon='Share' iconPlacement='left' type='outline' className={styles.actionBtn} onClick={openShareModal}>Share</Linkton>
        {(!!track.allowDownload || isOwner) && isPayingUser && <Linkton tag='anchor' href={track.selfLink + '/download'} icon='download' iconPlacement='left' type='outline' className={styles.actionBtn}>Download</Linkton>}
        {isOwner && <Linkton tag='anchor' href={LINKS.EDIT_MY_TRACK.replace(':id', track.id)} icon='pen' iconPlacement='left' type='outline' className={styles.actionBtn}>Edit</Linkton>}
      </div>

      <CommunityShareTrack mixdown={track} isOpen={isShareOpen} onClose={closeShareModal} />
    </div>
  )
}

const mapStateToProps = (state) => {
  const { currentUser } = state.backendData

  return {
    currentUser
  }
}

export default createProvider(
  connect(mapStateToProps)(CommunityTrackPlayer)
)
