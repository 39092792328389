import { Component } from 'react'
import BEM from 'react-bem-helper'
import './style.scss'
import { CarouselResponsive } from '@components/carousel/carousel'
import TileInlinePlayer from '../inline-player/tile'
import CompactInlinePlayer from '../inline-player/compact'
import BackgroundVideo from '../background-video'
import renderTopControls from '../carousel/components/top-controls.jsx'
import createProvider from '../../store/provider'
import {connect} from 'react-redux'
import { get } from '@common/utils'
import {ImageDimensions} from "../../common/imageDimensions";
import dubstep from '@images/landing/dubstep.svg';
import edm from '@images/landing/edm.svg';
import hiphop from '@images/landing/hiphop.svg';
import dnb from '@images/landing/dnb.svg';
import electronica from '@images/landing/electronica.svg';

const bem = BEM({
  prefix: 'pfx-',
  name: 'landing-studio-features'
})

const images = {
  fakie: {
    src: 'https://cdn.soundation.com/rtc_landing/fakie.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/fakie.png',
    alt: 'Fakie',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/fakie.png'))
  },
  gm2: {
    src: 'https://cdn.soundation.com/rtc_landing/gm2.webp',
    fallbackSrc: 'https://cdn.soundation.com/rtc_landing/gm2.png',
    alt: 'GM2',
    dimensions: new ImageDimensions(require('image-dimensions-loader!@images/landing/gm2.png'))
  },
}

class LandingStudioFeatures extends Component {
  renderTopControls = (options) => {
    return <div {...bem('carousel-controls')}>{ renderTopControls({...options, iconSize: 21, indicateActive: true}) }</div>
  }

  renderLoopsAndSamples () {
    const playerSize = this.props.screenBreakPointIndex > 2 ? 'small' : 'medium'

    return (
      <div {...bem('section')}>
        <div {...bem('section-item')}>
          <h3 {...bem('section-header')}>
            Loops and samples
          </h3>
          <p {...bem('section-description')}>
            20,000+ ready-to-mix basslines, drum beats and melodies in the library.
          </p>
          <CarouselResponsive
            theme={'dark'}
            renderProgressBar={null}
            renderTopControls={this.renderTopControls}
            viewsByScreenSize={[5, 5, 5, 5, 2]}
            marginByScreenSize={[58, 58, 8, 8, 28]}
            infinite={false}
            {...bem('starter-kits')}
          >
            <TileInlinePlayer
              songUrl={'https://s3.amazonaws.com/soundation.misc/preview_feature_tags/Dubstep+preview.mp3'}
              title={'Dubstep kit'}
              image={dubstep}
              imageType={'svg'}
              {...bem('starter-kit')}
              size={playerSize}
            />
            <TileInlinePlayer
              songUrl={'https://s3.amazonaws.com/soundation.misc/preview_feature_tags/EDM+preview.mp3'}
              title={'EDM kit'}
              image={edm}
              imageType={'svg'}
              {...bem('starter-kit')}
              size={playerSize}
            />
            <TileInlinePlayer
              songUrl={'https://s3.amazonaws.com/soundation.misc/preview_feature_tags/Hiphop+preview.mp3'}
              title={'Hip hop kit'}
              image={hiphop}
              imageType={'svg'}
              {...bem('starter-kit')}
              size={playerSize}
            />
            <TileInlinePlayer
              songUrl={'https://s3.amazonaws.com/soundation.misc/preview_feature_tags/DnB+preview.mp3'}
              title={'Drum \'n\' bass kit'}
              image={dnb}
              imageType={'svg'}
              {...bem('starter-kit')}
              size={playerSize}
            />
            <TileInlinePlayer
              songUrl={'https://s3.amazonaws.com/soundation.misc/preview_feature_tags/_Electronica_Starter_Kit_Demo.wav'}
              title={'Electronica kit'}
              image={electronica}
              imageType={'svg'}
              {...bem('starter-kit')}
              size={playerSize}
            />
          </CarouselResponsive>
        </div>

        <div {...bem('section-clear')} />

      </div>
    )
  }

  isTablet () {
    return this.props.screenBreakPointIndex >= 2 && this.props.screenBreakPointIndex <= 3
  }

  isMobile () {
    return this.props.screenBreakPointIndex === 4
  }

  renderInstruments () {
    const playerSize = this.isTablet() ? 'small' : this.isMobile() ? 'medium' : 'big'

    return (
      <div {...bem('section', 'float')}>
        <div />
        <div>
          <h3 {...bem('section-header')}>
            Software instruments
          </h3>
          <p {...bem('section-description')}>
            Easy-to-use drum machines, samplers and synthesizers.
          </p>
        </div>

        <div {...bem('video-container')} style={{
          width: images.gm2.dimensions.width + 'px',
        }}>
          <div
            {...bem('video-aspect-ratio')}
            style={{
              paddingTop: images.gm2.dimensions.getAspectRatio().toPaddingTop()
            }}
          >
            <BackgroundVideo
              urls={[
                'https://cdn.soundation.com/rtc_landing/gm2.webm',
                'https://cdn.soundation.com/rtc_landing/gm2.mp4'
              ]}
              poster={images.gm2}
              {...bem('video')}
            />
          </div>
        </div>

        <div {...bem('section-previews')}>
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-vas.mp3'}
            title={'VA synth'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-gm2.mp3'}
            title={'GM-2'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-wubmachine.mp3'}
            title={'Wub Machine'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-supersaw.mp3'}
            title={'Supersaw'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-spc.mp3'}
            title={'SPC'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/inst-fms.mp3'}
            title={'FM Synth'}
            size={playerSize}
          />
        </div>

        <div {...bem('section-clear')} />
      </div>
    )
  }

  renderEffects() {
    const playerSize = this.isTablet() ? 'small' : this.isMobile() ? 'medium' : 'big'

    return (
      <div {...bem('section', 'float')}>
        <div>
          <h3 {...bem('section-header')}>
            Audio effects
          </h3>
          <p {...bem('section-description')}>
            Shape your sound with a wide range of effects — anything from reverb to distortion to equalizer.
          </p>
        </div>

        <div {...bem('video-container')} style={{
          width: images.fakie.dimensions.width + 'px'
        }}>
          <div
            {...bem('video-aspect-ratio')}
            style={{
              paddingTop: images.fakie.dimensions.getAspectRatio().toPaddingTop()
            }}
          >
            <BackgroundVideo
              urls={[
                'https://cdn.soundation.com/rtc_landing/fakie.webm',
                'https://cdn.soundation.com/rtc_landing/fakie.mp4'
              ]}
              poster={images.fakie}
              {...bem('video')}
            />
          </div>
        </div>

        <div {...bem('section-previews')}>
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-tremolo.mp3'}
            title={'Tremolo'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-distortion.mp3'}
            title={'Distortion'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-fakie.mp3'}
            title={'Fakie'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-reverb.mp3'}
            title={'Reverb'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-phaser.mp3'}
            title={'Phaser'}
            size={playerSize}
          />
          <CompactInlinePlayer
            songUrl={'https://cdn.soundation.com/rtc_landing/fx-delay.mp3'}
            title={'Delay'}
            size={playerSize}
          />
        </div>

        <div />

        <div {...bem('section-clear')} />

      </div>
    )
  }

  render () {
    return (
      <div {...bem()}>
        <div {...bem('container')}>
          <h2 {...bem('header')}>Create your own beats with pro music tools.</h2>
          { this.renderLoopsAndSamples() }
          { this.renderInstruments() }
          { this.renderEffects() }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({ screenBreakPointIndex: get(state, 'app.screenBreakPointIndex') })

export default createProvider(
  connect(mapStateToProps)(LandingStudioFeatures)
)
