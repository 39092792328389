'use client'

import React from 'react'
import createProvider from "../../store/provider";
import connect from "react-redux/es/connect/connect";
import {
  setAcl,
  setCurrentUser,
  setAccountData,
  setModuleControllerAction,
  setNotificationCount,
  setAvatarUrl,
  setReferralReward,
  setFeatureFlags
} from '../../store/actions/backendData'

const mapDispatchToProps = {
  setCurrentUser,
  setAcl,
  setAccountData,
  setModuleControllerAction,
  setNotificationCount,
  setAvatarUrl,
  setReferralReward,
  setFeatureFlags
}

class BackendDataDispatcher extends React.Component {
  componentDidMount () {
    const {
      currentUser,
      setCurrentUser,
      acl,
      setAcl,
      account,
      setAccountData,
      referralReward,
      setReferralReward,
      featureFlags,
      setFeatureFlags
    } = this.props;
    setCurrentUser(currentUser)
    setAcl(acl)
    setAccountData(account)
    setReferralReward(referralReward)
    setFeatureFlags(featureFlags)
    this.props.setModuleControllerAction(this.props.moduleName, this.props.controllerName, this.props.actionName)
    this.props.setNotificationCount(this.props.notificationCount)
    this.props.setAvatarUrl(this.props.avatarUrl)
  }

  render () {
    return null
  }
}

export default createProvider(connect(null, mapDispatchToProps)(BackendDataDispatcher))
