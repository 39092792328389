import { createTypes } from 'redux-action-creator'

const actionTypes = createTypes([
  'SET_MESSAGES',
  'SHIFT_MESSAGE',
  'SET_BY_VALUES',
  'SET_BY_COMMON_COMPONENT',
  'SHOW',
  'HIDE',
  'SET_AUTOCLOSE'
], 'FLASH_MESSAGE')

export { actionTypes }
