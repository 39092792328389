import { connect } from 'react-redux'
import createProvider from '@store/provider'
import useDenormalizeCommunityTracks from '@root/hooks/community/useDenormalizeCommunityTracks'
import useGroupTracksContextMenuItems from '@root/hooks/community/useGroupTracksContextMenuItems'
import CommunitySection from '../community-section'
import CommunityTrackCard from '../community-track-card'
import * as styles from './CommunityTracksSection.module.scss'

function CommunityTracksSection (props) {
  const { tracks: normalizedTracks, pageLink, group, isAdminUser, label, subHeader, isFirstSection, contextMenuItems = {} } = props

  const tracks = useDenormalizeCommunityTracks(normalizedTracks)

  const tracksContextMenuItems = useGroupTracksContextMenuItems({ tracks, groupLink: group?.link, isGroupAdmin: group?.isGroupAdmin, isAdminUser })

  const section = {
    items: tracks.map((track) => (
      <CommunityTrackCard
        key={track.id}
        mixdown={track.mixdown}
        contextMenuItems={[...(tracksContextMenuItems[track.id] || []), ...(contextMenuItems[track.id] || [])].filter(Boolean)}
      />
    )),
    label,
    showMoreLink: pageLink,
    subHeader
  }

  return (
    <CommunitySection className={`${styles.section} ${isFirstSection ? styles.firstSection : ''}`} items={section.items} label={section.label} showMoreLink={section.showMoreLink} subHeader={section.subHeader} />
  )
}

export default createProvider(connect(null, null)(CommunityTracksSection))
