import React from 'react'
import Icon from '@components/icon'
import Image from '@components/image'
import CopyTextButton from '@components/copy-text-button'
import ModalOrBottomSheet from '@components/modal-or-bottom-sheet'
import * as styles from './CommunityShareModal.module.scss'

const CommunityShareModal = (props) => {
  const { isOpen, title, link, embedLink, facebookLink, twitterLink, onClose } = props

  const embedSnippet = embedLink ? '<iframe width="100%" height="130" src="' + embedLink + '" frameborder="0" allowfullscreen></iframe>' : null

  const onSocialClick = (e) => {
    if (e.altKey || e.metaKey || e.shiftKey) {
      return
    }
    e.preventDefault()
    const anchor = e.currentTarget
    window.open(anchor.getAttribute('href'), anchor.getAttribute('data-title'), 'width=600,height=600')
  }

  return (
    <ModalOrBottomSheet open={isOpen} closeShareModal={onClose}>
      {link ? (
        <div className={styles.shareSheet}>
          <div className={styles.shareSheetHeader}>
            <div className={styles.headerTitle}>{title}</div>
            <Icon icon='new-close' onClick={onClose} size={16} />
          </div>

          <label className={styles.shareSheetLabel}>TRACK LINK</label>
          <div className={styles.copyRow}>
            <div className={styles.shareSheetInput}>{link}</div>
            <CopyTextButton className={styles.shareSheetCopyButton} isOutline={false} textToBeCopied={link} />
          </div>

          {!!embedSnippet && (
            <div>
              <label className={styles.shareSheetLabel}>EMBED SNIPPET</label>
              <div className={styles.shareSheetTextarea}>{embedSnippet}</div>
              <div className={styles.copySnippet}>
                <CopyTextButton className={styles.copySnippetBtn} textToBeCopied={embedSnippet} />
              </div>
            </div>
          )}

          <label className={styles.shareSheetLabel}>SHARE TO SOCIAL MEDIA</label>
          <div className={styles.shareSheetSocial}>
            {!!facebookLink && (
              <a
                href={'https://www.facebook.com/dialog/share?app_id=374199152604565&display=popup&href=' + facebookLink + '&redirect_uri=' + facebookLink}
                className={styles.shareSheetSocialIcon} data-title='Share to Facebook'
                onClick={onSocialClick}>
                <Image src='https://cdn.soundation.com/images/facebook_secondary.svg' className={styles.socialMediaIcon} />
              </a>
            )}
            {!!twitterLink && (
              <a href={'https://twitter.com/share?url=' + twitterLink}
                className={styles.shareSheetSocialIcon} data-title='Share to Twitter'
                onClick={onSocialClick}>
                <Image src='https://cdn.soundation.com/images/X.svg' className={styles.socialMediaIcon} />
              </a>
            )}
          </div>
        </div>
      ) : null}
    </ModalOrBottomSheet>
  )
}

export default CommunityShareModal
