import React from 'react'
import { getCommunityEditMyTrackContextMenuItem } from '@common/utils'
import { LINKS } from '@common/consts'
import Spinner from '@components/spinner'
import CommunityTracksSection from '../community-tracks-section'
import CommunitySectionPlaceholder from '../community-section-placeholder'

export const PAGE_LINKS = {
  myTracks: LINKS.MY_TRACKS,
  followedTracks: LINKS.FOLLOWED_TRACKS,
  featured: LINKS.FEATURED_TRACKS,
  trending: LINKS.TRENDING_TRACKS,
  recent: LINKS.RECENT_TRACKS
}
export const PAGE_LABELS = {
  myTracks: 'My Tracks',
  followedTracks: 'Tracks by users and groups you follow',
  featured: 'Featured tracks',
  trending: 'Trending tracks',
  recent: 'New tracks'
}

/** Will return the appropriate CommunityTracksSection component based on which type of tracks is passed */
const CommunityTracksSectionByType = ({ myTracks, followedTracks, featuredTracks, trendingTracks, recentTracks, isLoading, showMore = true, ...rest }) => {
  const sections = {
    myTracks: {
      title: PAGE_LABELS.myTracks,
      tracks: myTracks?.data?.length ? myTracks : [],
      pageLink: myTracks?.data?.length && showMore ? PAGE_LINKS.myTracks : null,
      subHeader: !myTracks?.data?.length ? <CommunitySectionPlaceholder icon='Project-file' message='You don’t have any published tracks yet. Create a track in the studio and share it with the community.' /> : null,
      contextMenuItems: myTracks?.data?.length ? myTracks.data.reduce((acc, track) => {
        acc[track.id] = [getCommunityEditMyTrackContextMenuItem(track.id)]
        return acc
      }, {}) : {}
    },
    followedTracks: {
      title: PAGE_LABELS.followedTracks,
      tracks: followedTracks?.data?.length ? followedTracks : [],
      pageLink: followedTracks?.data?.length && showMore ? PAGE_LINKS.followedTracks : null,
      subHeader: !followedTracks?.data?.length ? <CommunitySectionPlaceholder icon='Project-file' message='Tracks posted by users and groups you follow will appear here. Start following groups and users you like.' /> : null
    },
    featuredTracks: {
      title: PAGE_LABELS.featured,
      tracks: featuredTracks,
      pageLink: showMore ? PAGE_LINKS.featured : null
    },
    trendingTracks: {
      title: PAGE_LABELS.trending,
      tracks: trendingTracks,
      pageLink: showMore ? PAGE_LINKS.trending : null
    },
    recentTracks: {
      title: PAGE_LABELS.recent,
      tracks: recentTracks,
      pageLink: showMore ? PAGE_LINKS.recent : null
    }
  }

  let key
  if (myTracks) key = 'myTracks'
  else if (followedTracks) key = 'followedTracks'
  else if (featuredTracks) key = 'featuredTracks'
  else if (trendingTracks) key = 'trendingTracks'
  else if (recentTracks) key = 'recentTracks'

  if (key) {
    return <CommunityTracksSection tracks={sections[key].tracks} pageLink={sections[key].pageLink} label={sections[key].title} subHeader={isLoading ? <Spinner /> : sections[key].subHeader} contextMenuItems={sections[key].contextMenuItems} {...rest} />
  }

  return null
}

export default CommunityTracksSectionByType
