import React, { useCallback, useEffect } from 'react'
import usePaginate from '@root/hooks/usePaginate'
import { getUserFollowing, denormalizeResponse } from '@common/http'
import { extractUsersFromFollowings } from '@common/utils'
import CommunitySubPage from '../community-sub-page'
import CommunityUserCard from '../community-user-card'

const CommunityUserFollowingSubPage = (props) => {
  const { guid, username } = props

  const fetcher = useCallback(async ({ page }) => {
    return getUserFollowing(guid, { page, items: 20 })
  }, [guid])

  const { isLoading, data, fetchNextPage } = usePaginate({ fetcher })
  const users = { data: extractUsersFromFollowings(data, 'followable') }
  const followings = denormalizeResponse(users)

  // fetch groups on mount
  useEffect(fetchNextPage, [])

  return (
    <CommunitySubPage
      title={`"${username}" following`}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
      landscapeOnMobile
    >
      {followings.map(({ id, ...rest }) => <CommunityUserCard key={id} user={{ ...rest }} isSubPage />)}
    </CommunitySubPage>
  )
}

export default CommunityUserFollowingSubPage
