import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@components/modal'
import Icon from '@components/icon'
import connect from 'react-redux/es/connect/connect'
import Linkton from '@components/linkton'
import { Bem, toLocaleFormat } from '@common/utils'
import './style.scss'
import { cancelReasonsRequested, cancelSubscriptionRequested } from '@store/actions/backendData'
import createProvider from '@store/provider'
import { find } from 'lodash'

const cn = new Bem({
  name: 'cancelation-body',
  prefix: 'pfx-'
})

class CancelSubscription extends React.Component {

  componentDidMount() {
    let subscriptionType;
    if (this.props.subscription.type === 'AccountSubscription') {
      subscriptionType = 'account'
    } else {
      subscriptionType = 'addon'
    }
    this.props.cancelReasonsRequested(subscriptionType)
  }

  state = {
    selectedReason: null,
    validated: false,
    followUp: '',
    isValid: null
  }

  handleChange = event => {
    this.setState({ reason: event.target.value })
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          headerClassName={cn('header')}
          bodyClassName={cn(null)}
          title={'Cancel subscription'}
        >
          {() => this.renderModalBody()}
        </Modal>
      </div>
    )
  }

  handleCancelSubscription = () => {
    if (this.isValid()) {
      this.props.cancelSubscriptionRequested({
        id: this.props.subscription.id,
        cancelReasonId: this.state.selectedReason,
        followUp: this.state.followUp,
        callback: this.props.handleClose
      })
    } else {
      this.validate()
    }
  }

  validate() {
    this.setState({
      validated: true,
      isValid: this.isValid()
    })
  }

  hasFollowUp(id) {
    const reason = find(this.props.reasons, { id })
    return !!(reason && reason.followUp)
  }

  isValid = () => {
    const { selectedReason, followUp } = this.state
    const { cancelReasonRequired } = this.props

    if (!cancelReasonRequired) {
      return true
    }

    if (!selectedReason) {
      return false
    } else if (this.hasFollowUp(selectedReason) && !followUp.trim()) {
      return false
    } else {
      return true
    }
  }

  isSelected = (id) => {
    return this.state.selectedReason === id
  }

  selectReason = (id) => {
    this.setState({
      selectedReason: id,
      followUp: '',
      validated: false
    })
  }

  handleFollowUpChange = async (e) => {
    await this.setState({
      followUp: e.target.value
    })

    if (this.state.validated) {
      this.validate()
    }
  }

  renderModalBody() {
    const { reasons, subscription, handleClose } = this.props
    const { followUp, isValid, validated, selectedReason } = this.state
    const followUpError = Boolean(!isValid && validated)
    const selectedReasonData = find(reasons, { id: selectedReason })
    const hasFollowUp = selectedReasonData && selectedReasonData.followUp

    const isAccountSubscription = subscription.type === 'AccountSubscription'

    return (
      <div>
        <div className={cn('modal-header')}>
          <div className={cn('title')}>{isAccountSubscription ? 'Are you done making music?' : `Cancel ${subscription.name}`}</div>

          <Icon icon='cancel' size={22} onClick={handleClose} />
        </div>

        <p className={cn('paragraph')}>If you&apos;re sure you&apos;d like to cancel your subscription, please note that your subscription will
          remain active until {toLocaleFormat(subscription.nextPayment.date)}, and you will no longer be
          charged.
        </p>
        {isAccountSubscription && (
          <p className={cn('paragraph')}>
            Come back anytime! If you restart your subscription, you&apos;ll have access to all your saved projects
            and all of the premium features. Please let us know why you&apos;re canceling so we can improve.
          </p>
        )}
        <div className={cn('text-container')}>
          {reasons && reasons.map((reason) => {
            return <div key={reason.id}>
              <Checkbox
                value={this.isSelected(reason.id) || false}
                className={cn('checkbox')}
                labelClassName={cn('checkbox-label')}
                theme='clean'
                size='small'
                onChange={val => this.selectReason(reason.id)}
                label={reason.title} />
            </div>
          })}
        </div>
        {hasFollowUp && this.isSelected(selectedReason) && <div>
          <div className={cn('follow-up', { error: followUpError })}>{selectedReasonData.followUp} (required)</div>
          <textarea
            onChange={this.handleFollowUpChange}
            className={cn('text-area', { error: followUpError })}
            value={followUp}
          />
        </div>}

        {!!(validated && !selectedReason) && <div className={cn('error')}>
          You must select a reason
        </div>}
        <div className={cn('actions')}>
          <Linkton
            onClick={handleClose}
            color='secondary_light'
          >
            Never mind
          </Linkton>
          <Linkton
            onClick={this.handleCancelSubscription}
            isLoading={this.props.loading}
            color='danger'
          >
            Cancel subscription
          </Linkton>
        </div>

      </div>
    )
  }
}

CancelSubscription.propTypes = {
  cancelReasonRequired: PropTypes.bool,
  reasons: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    followUp: PropTypes.string
  })).isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
}

CancelSubscription.defaultProps = {
  cancelReasonRequired: true
}

const mapStateToProps = (state) => {
  return {
    reasons: state.backendData.cancelReasons,
    loading: state.backendData.cancelReasonsLoading,
    error: state.backendData.cancelReasonsError
  }
}

const mapDispatchToProps = {
  cancelSubscriptionRequested,
  cancelReasonsRequested
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(CancelSubscription))
