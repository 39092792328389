/**
 * This service handles interaction with the chargebee API
 *
 * @see https://www.chargebee.com/checkout-portal-docs/api.html#chargebee-object
 */
export default class ChargebeeService {
  #instance

  #chargebee

  constructor (chargebeeConfig) {
    this.chargebeeConfig = chargebeeConfig
  }

  static handleError = (error, data) => {
    console.error('error in chargebee:', error)
    console.error('data in chargebee error:', data)
  }

  requireInstance = (cb) => {
    return async (params) => {
      const inst = this.#chargebee?.getInstance()
      if (!inst) {
        await this.init()
      }

      return cb(inst || this.#instance, params)
    }
  }

  init = async () => {
    if (this.#instance) return

    const loadScript = () => new Promise((resolve) => {
      const js = document.createElement('script')
      js.id = 'chargebee'
      js.src = 'https://js.chargebee.com/v2/chargebee.js'
      js.async = true

      js.onload = () => resolve()
      document.head.appendChild(js)
    })
    await loadScript()

    this.#chargebee = window.Chargebee
    const instance = this.#chargebee?.init(this.chargebeeConfig)

    if (!instance) {
      Chargebee.handleError(new Error("Chargebee couldn't be initialized"), {
        header: 'Failed to initialize payment.',
        description: 'Please try again later.',
        id: 'chargebee-initialization'
      })

      return
    }

    this.#instance = instance
  }

  /**
   * Opens the checkout modal
   */
  openCheckout = this.requireInstance(async (instance, { hostedPage }) => {
    return new Promise((resolve, reject) => {
      try {
        instance.openCheckout({
          hostedPage () {
            return new Promise((res) => res(hostedPage))
          },
          success: resolve
        })
      } catch (e) {
        Chargebee.handleError(e, {
          header: 'Failed to open checkout.',
          description: 'Please try again later.',
          id: 'chargebee-checkout'
        })
        reject(e)
      }
    })
  })

  openPortal = this.requireInstance(
    async (instance, { section, token, session }) => {
      return new Promise((resolve, reject) => {
        try {
          instance.setPortalSession(function () {
            return new Promise((res) => res(session))
          })
          const cbPortal = instance.createChargebeePortal()

          const forwardOptions =
                    token && section
                      ? {
                        sectionType: section,
                        params: {
                          subscriptionId: token
                        }
                      }
                      : undefined

          cbPortal?.open({},forwardOptions)
        } catch (e) {
          Chargebee.handleError(e, {
            header: 'Failed to open portal.',
            description: 'Please try again later.',
            id: 'chargebee-portal'
          })
          reject(e)
        }
      })
    }
  )
}
