import { Bem } from '../../common/utils'

const cn = new Bem({
  name: 'accordion',
  prefix: 'pfx-'
})

export const rootCn = cn()
export const itemCn = cn('item')
export const titleContainerCn = cn('title-container')
export const titleCn = cn('title')
export const bodyContainerCnFn = (modifiers) => cn('body-container', modifiers)
export const bodyCn = cn('body')
export const chevronCnFn = (modifiers) => cn('chevron', modifiers)
