import React from 'react'

/** Stores previous value provided [usually a state variable] and returns it */
const usePrevious = (value) => {
  const ref = React.useRef(value)

  React.useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export default usePrevious
