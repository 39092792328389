import { Connection } from '@soundation/sharedb/lib/client'
import RCWebSocket from 'reconnecting-websocket'
import { debounce } from 'lodash'
import { StudioConfiguration } from '@common/config'
import { encode, decode } from '@msgpack/msgpack'
import EventListener from './eventListener'

const CONFIG = {
  keepAliveInterval: 30 * 1000,
  minReconnectionDelay: 100
}

export class RtcClient extends EventListener {
  socketUrl = StudioConfiguration.rtc.paths.rtcServiceVersionProtocol + StudioConfiguration.rtc.urls.base

  keepAliveInterval = CONFIG.keepAliveInterval

  connection

  constructor () {
    super()
    this.createNotificationsListener()
  }

  schedulePingSocket = debounce(() => {
    if (this.connection && this.connection.canSend) {
      this.connection.send({ '': 0 })
      this.schedulePingSocket()
    }
  }, this.keepAliveInterval)

  getConnectionId = () => {
    return this.connection ? this.connection.id : ''
  }

  createNotificationsListener = () => {
    const socket = new RCWebSocket(this.socketUrl, undefined, {
      minReconnectionDelay: CONFIG.minReconnectionDelay
    })

    this.socket = socket
    this.socket.binaryType = 'arraybuffer'
    this.connection = new Connection(this.socket, { encode, decode })

    if (this.connection) {
      this.connection.on('connecting', this.schedulePingSocket)
      this.connection.on('notification', (notification) => {
        if (notification?.type === 'new-notification') {
          this.emit('new-notification', { notification })
        }
      })
    }
  }

  destroy = () => {
    try {
      if (this.connection) {
        this.connection.removeListener('connecting', this.schedulePingSocket)
        this.connection.removeListener('send', this.schedulePingSocket)
        this.connection.removeListener('receive', this.schedulePingSocket)
      }
      if (this.socket) {
        this.socket.removeEventListener('open', this.onOnline)
        this.socket.removeEventListener('close', this.onConnectionLost)
        this.socket.close()
      }
    } catch (e) {}
  }
}

const rtcClient = new RtcClient()
export default rtcClient
