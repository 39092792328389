import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { ErrorBoundary, withErrorBoundary } from "@components/error-boundary";
import { isComponent } from "@common/strapi.helpers";
import dynamic from 'next/dynamic'

const GetStartedCTABlock = dynamic(() => import("../blocks/get-started-cta-block"));
const GetStartedCTABlockComponentId = "cta.get-started";

const BreadcrumbsBlock = dynamic(() => import("../blocks/breadcrumbs-block"))
const BreadcrumbsBlockComponentId = "blocks.breadcrumbs";

const MarkdownBlock = dynamic(() => import("../blocks/markdown-block/MarkdownBlock"))
const MarkdownBlockComponentId = "blocks.markdown";

const Faq = dynamic(() => import("../blocks/faq-block"))
const FaqComponentId = 'blocks.faq'

const SubPagesListBlock = dynamic(() => import("../blocks/subpages-list-block"))
const SubPagesListBlockComponentId = "blocks.subpages-list"

const ComboListBlock = dynamic(() => import("../blocks/combo-list-block"))
const ComboListBlockComponentId = "blocks.combo-list"

const ImageBlock = dynamic(() => import("../blocks/image-block"))
const ImageBlockComponentId = "blocks.image";

const VideoBlock = dynamic(() => import("../blocks/video-block"))
const VideoBlockComponentId = "blocks.video";

const AudioListBlock = dynamic(() => import("../blocks/audio-list-block"))
const AudioListBlockComponentId = "blocks.audio-list";

const ProjectTemplateListBlock = dynamic(() => import("../blocks/project-template-list-block"))
const ProjectTemplateListBlockComponentId = "blocks.project-template-list";

const PriceBadgeBlock = dynamic(() => import("../blocks/price-badge-block"))
const PriceBadgeBlockComponentId = "shared.price-badge"

const BadgeBlock = dynamic(() => import("../blocks/badge-block"))
const BadgeBlockComponentId = "shared.badge"

const SpacingBlock = dynamic(() => import("../blocks/spacing-block"))
const SpacingBlockComponentId = "blocks.spacing"

const TemplateListBlock = dynamic(() => import("../blocks/template-list-block"))
const TemplateListBlockId = "blocks.template-list"

const HeroBlock = dynamic(() => import("../blocks/hero-block"))
const HeroBlockComponentId = "blocks.hero"

const TextAndImageBlock = dynamic(() => import("../blocks/text-and-image"))
const TextAndImageBlockComponentId = "blocks.text-and-image"

const CtaWithBackgroundBlock = dynamic(() => import("../blocks/cta-with-background-block"))
const CtaWithBackgroundBlockComponentId = "cta.cta-with-background"

const CtasRowBlock = dynamic(() => import("../blocks/ctas-row-block"))
const CtasRowBlockId = 'cta.ctas-row'

const TextAndButtonsCtaBlock = dynamic(() => import("../blocks/text-and-buttons-cta-block"))
const TextAndButtonsCtaBlockComponentId = "cta.text-and-buttons-cta"

const CarouselBlock = dynamic(() => import("../blocks/carousel-block"))
const CarouselBlockComponentId = "blocks.carousel"

const SourcedCarouselBlock = dynamic(() => import("../blocks/sourced-carousel-block"))
const SourcedCarouselBlockComponentId = "blocks.sourced-carousel"

const DownloadBlock = dynamic(() => import("../blocks/download-block"))
const DownloadBlockComponentId = "blocks.download"

const EmbedStudioBlock = dynamic(() => import("../blocks/embed-studio-block"))
const EmbedStudioBlockId = "blocks.embed-studio"

import "./BlockArea.style.scss";

import { BlockStyles } from "./components/BlockStyles";
import { Logger } from "../../common/logger";

const cn = new Bem({
  name: "block-area",
  prefix: "pfx-",
});

const BlockAreaLogger = new Logger({
  name: "BlockArea",
});

const BLOCKS = {
  [GetStartedCTABlockComponentId]: {
    component: GetStartedCTABlock,
    getProps: ({ blocks, index }) => ({ isLast: index === blocks.length - 1 }),
  },
  [CtaWithBackgroundBlockComponentId]: {
    component: CtaWithBackgroundBlock,
  },
  [BreadcrumbsBlockComponentId]: { component: BreadcrumbsBlock },
  [MarkdownBlockComponentId]: { component: MarkdownBlock },
  [SubPagesListBlockComponentId]: { component: SubPagesListBlock },
  [ComboListBlockComponentId]: { component: ComboListBlock },
  [ImageBlockComponentId]: {
    component: ImageBlock,
    getProps: ({ index }) => ({ isFirst: index === 0 }),
  },
  [HeroBlockComponentId]: {
    component: HeroBlock,
    getProps: ({ index }) => ({ isFirst: index === 0 }),
  },
  [TextAndImageBlockComponentId]: {
    component: TextAndImageBlock,
  },
  [VideoBlockComponentId]: { component: VideoBlock },
  [AudioListBlockComponentId]: { component: AudioListBlock },
  [ProjectTemplateListBlockComponentId]: {
    component: ProjectTemplateListBlock,
  },
  [PriceBadgeBlockComponentId]: { component: PriceBadgeBlock },
  [BadgeBlockComponentId]: { component: BadgeBlock },
  [SpacingBlockComponentId]: { component: SpacingBlock },
  [TemplateListBlockId]: { component: TemplateListBlock },
  [TextAndButtonsCtaBlockComponentId]: { component: TextAndButtonsCtaBlock },
  [CarouselBlockComponentId]: { component: CarouselBlock },
  [SourcedCarouselBlockComponentId]: { component: SourcedCarouselBlock },
  [DownloadBlockComponentId]: { component: DownloadBlock },
  [EmbedStudioBlockId]: { component: EmbedStudioBlock },
  [FaqComponentId]: { component: Faq },
  [CtasRowBlockId]: { component: CtasRowBlock }
};

const BLOCK_MODIFIERS = {
  // Defaults, if not set in the back end
  [GetStartedCTABlockComponentId]: { width: "wide" },
  [HeroBlockComponentId]: { width: "wide", spacing: false },
  [BreadcrumbsBlockComponentId]: { width: "normal" },
  [SubPagesListBlockComponentId]: { width: "normal" },
  [ComboListBlockComponentId]: { width: "normal" },
  [AudioListBlockComponentId]: { width: "narrow" },
  [TextAndImageBlockComponentId]: { width: "wide", spacing: false },
  [CtaWithBackgroundBlockComponentId]: { width: "wide", spacing: false },
  [CarouselBlockComponentId]: { width: "normal", overflow: "right" },
  [SourcedCarouselBlockComponentId]: { width: "normal" },
  [DownloadBlockComponentId]: { width: "normal" },
  [FaqComponentId]: { width: "normal" },
  [CtasRowBlockId]: { width: "normal" },
  // Following components can be customized in strapi admin
  [SpacingBlockComponentId]: { width: "wide" },
  [MarkdownBlockComponentId]: {},
  [ImageBlockComponentId]: {},
  [VideoBlockComponentId]: {},
  [PriceBadgeBlockComponentId]: {},
  [BadgeBlockComponentId]: {},
  [TemplateListBlockId]: {},
  [TextAndButtonsCtaBlockComponentId]: {},
  [EmbedStudioBlockId]: {},
};

const getThemeableProps = ({ block = {}, blocks = [], index } = {}) => {
  if (!block?.themeable) return {};
  const theme = {};

  const { themeable } = block;

  const { background, backgroundFade = false } = themeable || {};

  theme.background = background?.color;

  const nextBlock = blocks[index + 1] || {};

  if (backgroundFade && nextBlock) {
    theme.backgroundFadeTo = getThemeableProps({ block: nextBlock }).background;
  }

  return theme;
};

const getBlockStyleProps = ({ block = {}, blocks = [], index }) => {
  const { __component: blockType } = block;

  const typeModifiers = { ...BLOCK_MODIFIERS[blockType] };
  const props = typeModifiers || {};
  const nextBlock = blocks[index + 1] || {};

  if (block.resizeable) {
    const { width } = block.resizeable;
    props.width = width;
  }

  props.theme = getThemeableProps({ block, blocks, index });

  if (index === 0 && props.width !== "wide") {
    props.isFirst = true;
  }

  // if the block if of type Spacing or the next block is a spacing block
  // it should not have any spacing
  if (
    isComponent(block, SpacingBlockComponentId) ||
    isComponent(nextBlock, SpacingBlockComponentId)
  ) {
    props.spacing = false;
  }
  return props;
};

export const getBlockComponent = ({ blocks = [], block, index }) => {
  if (!block) return {}

  const { __component: blockType, id } = block;
  const { component, getProps } = BLOCKS[blockType] || {};
  return {
    Component: component,
    blockType,
    id,
    block,
    props: getProps?.({ blocks, block, index }) || {},
  };
};

const BlockArea = ({ blocks = [], className }) => {
  return (
    <div className={cn(undefined, undefined, className)}>
      {(blocks || []).map((block, index) => {
        const { Component, id, blockType, props } = getBlockComponent({
          blocks,
          block,
          index,
        });

        if (!Component) return null;

        BlockAreaLogger.log(`Rendering block "${blockType}"`, {
          block,
          props,
          Component,
        });

        return (
          <ErrorBoundary
            key={`${blockType}_${id}`}
            error={`Failed to render block "${blockType}"`}
          >
            <BlockStyles
              className={cn("block")}
              {...getBlockStyleProps({ block, index, blocks })}
            >
              <Component {...block} {...props} />
            </BlockStyles>
          </ErrorBoundary>
        );
      })}
    </div>
  );
};

export const BlockAreaPropTypes = {
  blocks: PropTypes.array
};

BlockArea.propTypes = PropTypes.shape(BlockAreaPropTypes).isRequired;

export default withErrorBoundary(BlockArea, {
  error: "Failed to render BlockArea",
});
