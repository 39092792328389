import React from 'react'
import './style.scss'
import PropTypes from 'prop-types';
import {Bem, gotoLocation, Enum, isAccountCanceled} from '../../common/utils';
import Linkton, {SIZE, TYPE} from "../../components/linkton/index";
import { CarouselResponsive } from '@components/carousel/carousel';
import { LINKS } from '@common/consts';
import SingleViewTrack from '@components/carousel/components/single-view-track';
import BackgroundVideo from "../../components/background-video/index";
import { get } from 'lodash';
import {connect} from "react-redux";
import createProvider from "../../store/provider";
import {withBindHeightToWidth} from "@components/carousel/components/with-bind-height-to-width";
import {PERIOD } from '@containers/checkout-view/services/consts.jsx'
import withIcon from '../../components/linkton/with-icon'
import Icon from '../../components/icon'
import LoadingIndicator from '../../components/loading-indicator'
import { getDisplayPrice } from '@components/price-plans/utils';

const cn = new Bem({
  name: 'referral-landing-page',
  prefix: 'pfx-'
});

const LinktonWithIcon = withIcon(Linkton)

export const LAYOUTS = new Enum('ONLY_MONTHLY', 'MONTHLY_AND_YEARLY')

const BUTTON_SIZE_BY_BREAKPOINT = [
  SIZE.EXTRA_LARGE,
  SIZE.EXTRA_LARGE,
  SIZE.LARGE,
  SIZE.LARGE,
  SIZE.LARGE
]

const mapStateToProps = (state) => {
  return {
    buttonsSize: BUTTON_SIZE_BY_BREAKPOINT[get(state, 'app.screenBreakPointIndex')],
    isUserAuthenticated: !!get(state, 'backendData.currentUser'),
    currentUser: get(state, 'backendData.currentUser'),
    referralReward: get(state, 'backendData.referralReward'),
  }
}

class UserImage extends React.Component {
  render () {
    const { url } = this.props
    return (
      <div
        style={{backgroundImage: 'url(' + url + ')'}}
        className={cn('hero-avatar')}></div>
    )
  }
}

const ResponsiveUserImage = withBindHeightToWidth(UserImage)

class ReferralLandingPage extends React.Component {
  state = {
    hasVideoPlaybackStarted: false,
    isVideoPlaying: false,
  }

  static propTypes = {
    buttonsSize: PropTypes.string,
    isUserAuthenticated: PropTypes.bool,
    referralUser: PropTypes.string
  }

  componentDidMount () {
    this.refFullLengthVideo.addEventListener('play', () => {
      this.setState({isVideoPlaying: true})
    })
    this.refFullLengthVideo.addEventListener('pause', () => {
      this.setState({isVideoPlaying: false})
    })
    this.refFullLengthVideo.addEventListener('ended', () => {
      this.setState({isVideoPlaying: false})
    })
    this.refFullLengthVideo.addEventListener('waiting', () => {
      this.setState({isVideoLoading:true})
    })
    this.refFullLengthVideo.addEventListener('canplay', () => {
      this.setState({isVideoLoading:false})
    })
    this.preloadTheVideo()
  }

  preloadTheVideo () {
    const { refFullLengthVideo } = this
    if (!URL || !URL.createObjectURL) {
      return
    }

    const preloadRequest = new XMLHttpRequest()
    preloadRequest.open('GET', refFullLengthVideo.children[0].src, true)
    preloadRequest.responseType = 'blob'

    preloadRequest.addEventListener('load', () => {
      if (preloadRequest.status === 200) {
        const videoBlob = preloadRequest.response
        const video = URL.createObjectURL(videoBlob)
        const { currentTime, paused } = refFullLengthVideo
        refFullLengthVideo.src = video
        refFullLengthVideo.currentTime = currentTime
        if (!paused) {
          this.playVideo()
        }
      }
    })
    preloadRequest.send()
  }

  getReferralId() {
    return get(this.props, 'referralJson.id')
  }

  getFormattedDiscountAmount() {
    const amount = get(this.props, 'referralReward')
    const currency = get(this.props, 'currency')

    return getDisplayPrice(amount, currency, 0)
  }

  prepareCarouselView () {
    let userTracks = null

    if (this.props.userTracks || Array.isArray(this.props.userTracks)) {
      userTracks = this.props.userTracks.map((carouselItem, arr, idx) => {
        return <SingleViewTrack
          {...{
            id: carouselItem.id,
            key: idx,
            title: carouselItem.title,
            subtitle: carouselItem.user.username,
            image: carouselItem.imageLargeUrl,
            songUrl: carouselItem.audioMp3Url,
            fileName: carouselItem.fileName,
            date: carouselItem.createdAt,
            duration: carouselItem.duration,
            directWaveformUrl: carouselItem.directWaveformUrl,
            profileLink: carouselItem.user.profileLink,
            permalink: carouselItem.permalink,
            shortLinkId: carouselItem.shortLinkId
          }}
        />
      })
    }

    return userTracks
  }

  playVideo() {
    if (this.refFullLengthVideo) {
      if (this.refVideoAnchor) {
        this.refVideoAnchor.scrollIntoView({behavior: 'smooth', block: 'start'})
      }
      this.refFullLengthVideo.play()
      this.setState({hasVideoPlaybackStarted: true})
    }
  }

  toggleVideoState() {
    const {hasVideoPlaybackStarted} = this.state

    if (!hasVideoPlaybackStarted) {
      this.refFullLengthVideo.play()
      this.setState({hasVideoPlaybackStarted: true})
      return
    }

    if (this.refFullLengthVideo.paused) {
      this.refFullLengthVideo.play()
      this.setState({isPausedVideo: false})
    } else {
      this.refFullLengthVideo.pause()
      this.setState({isPausedVideo: true})
    }
  }

  isCurrentUserReferralPage() {
    const {currentUser, referralJson} = this.props
    return get(referralJson, 'owner.type') === 'user' && get(currentUser, 'username') === get(referralJson, 'owner.user.username')
  }

  isPaidUser() {
    const {currentUser} = this.props
    return currentUser && !!currentUser.accountType
  }

  isUserCancel() {
    const {currentUser} = this.props;
    return isAccountCanceled(get(currentUser, 'accountType'), get(currentUser, 'activeRecurringSubscription'))
  }

  shouldHidePrices() {
    return this.isCurrentUserReferralPage() || this.isPaidUser() || this.isUserCancel()
  }

  handleGotoPricingView() {
    const { isUserAuthenticated } = this.props

    const link = LINKS.AUTH_PLANS
    const params = { referral_id: this.getReferralId() }

    gotoLocation(link, params)
  }

  getHeaderByLayout() {
    return `Get ${this.getFormattedDiscountAmount()} off on any plans`
  }

  renderBottomAction({buttonLabel, subheader, period}) {
    const {buttonsSize} = this.props
    return (
      <div className={cn('bottom-action')}>
        <Linkton
          className={cn('hero-cta')}
          type={TYPE.OUTLINE}
          isDisabled={this.shouldHidePrices()}
          onClick={() => this.handleGotoPricingView()}
          size={buttonsSize}>{buttonLabel}</Linkton>
        {!this.shouldHidePrices() && <div className={cn('bottom-subheading')}>
          {subheader}
        </div>}
      </div>
    )
  }

  buttonLabelByUserType(label) {
    return this.shouldHidePrices() ? 'UNAVAILABLE': label
  }

  render() {
    const { hasVideoPlaybackStarted, isVideoPlaying } = this.state
    const { userTracks, buttonsSize, avatarUrl, referralJson } = this.props
    const username = get(referralJson, 'owner.user.username') || get(referralJson, 'owner.influencer.name')
    const profileLink = get(referralJson, 'owner.user.profileLink')
    const discountAmount = this.getFormattedDiscountAmount()

    return (
      <div className={cn()}>
        <div className={cn('hero-wrapper')}>
          <div className={cn('hero', 'only_monthly', 'container container--wider')}>
            <div className={cn('hero-content')}>
              <div className={cn('hero-left')}>
                <h1 className={cn('hero-heading')}>
                  Join {username} on Soundation. <br />
                  <span className={cn('hero-heading-orange')}>{ this.getHeaderByLayout() }</span>
                </h1>
                <Linkton
                  isDisabled={this.shouldHidePrices()}
                  onClick={() => this.handleGotoPricingView()}
                  className={cn('hero-cta')}
                  type={TYPE.OUTLINE}
                  size={buttonsSize}>{this.buttonLabelByUserType(`Get ${discountAmount}`)}</Linkton>
              </div>

              <div className={cn('hero-right')}>
                <ResponsiveUserImage url={avatarUrl}/>
              </div>
            </div>
          </div>

          {
            userTracks && !!userTracks.length &&
            [
              <div className={cn('separator')} key='featured-separator'></div>,
              <div className={cn('featured-tracks', null, 'container container--wider')} key='featured-tracks'>
                <h3 className={cn('subheader')}>Listen to music made by {username} on Soundation.</h3>
                <CarouselResponsive
                  className={cn('featured-tracks')}
                  theme='light'
                  onViewAllClick={() => gotoLocation(`${LINKS.USER}/${profileLink}`)}
                >{this.prepareCarouselView()}
                </CarouselResponsive>
              </div>
            ]
          }

          <div className={cn('separator')}></div>
          <div className={cn('video', null, 'container container--wider')}>
            <h3 className={cn('subheader')}>A real music studio in your browser</h3>
            <div className={cn('video-container', {'is-playing-video': hasVideoPlaybackStarted})}>
              <BackgroundVideo
                onClick={() => this.toggleVideoState()}
                urls={['https://s3.amazonaws.com/soundation.misc/product+loop.mp4']}
                className={cn('video-placeholder')}> </BackgroundVideo>
              <div className={cn('full-video-container')}>
                <video
                  preload="metadata"
                  onClick={() => this.toggleVideoState()}
                  className={cn('full-video-element')}
                  ref={(r) => this.refFullLengthVideo = r}>
                  <source src="https://s3.amazonaws.com/soundation.misc/product+vid.mp4" type="video/mp4" />
                </video>
                <div className={cn('full-video-button', {visible: this.state.isVideoLoading})}>
                  {
                    this.state.isVideoLoading ?
                      <LoadingIndicator className={cn('full-video-loading')}/> :
                      <Icon size={50} icon={isVideoPlaying ? 'pause' : 'play'} className={cn('full-video-button-icon')}/>
                  }
                </div>
              </div>
              <div
                className={cn('video-scroll-anchor')}
                ref={r => this.refVideoAnchor = r}
              />
            </div>

            <LinktonWithIcon
              icon='play'
              onClick={() => this.playVideo()}
              className={cn('video-cta')}
              type={TYPE.OUTLINE}
              size={buttonsSize}>See it in action</LinktonWithIcon>
          </div>

          <div className={cn('separator')}></div>

          <div className={cn('bottom-cta', null, 'container container--wider')}>
            <div className={cn('bottom-container')}>
              <h1 className={cn('bottom-heading')}>Try Soundation now</h1>

              <div className={cn('bottom-actions')}>
                {
                  this.renderBottomAction({
                    buttonLabel: this.buttonLabelByUserType(`Get ${discountAmount}`),
                    period: PERIOD.YEAR
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default createProvider(connect(mapStateToProps)(ReferralLandingPage, false))
