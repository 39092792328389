import { useState } from 'react'
import axios from 'axios'
import Linkton from '@components/linkton'
import CommunityActionSearch from '@containers/community/community-action-search'
import * as styles from './CommunityAddTrack.module.scss'

export default function CommunityAddTrack ({ groupId, groupLink, groupName }) {
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleAddTrack = (trackId) => {
    const url = `/group/${groupLink}/post_track/${trackId}`
    window.location.href = url
  }
  const searchForTrack = async (query) => {
    const url = '/tracks/my.json?group=' + groupId + '&search=' + query
    const res = await axios.get(url)

    return res.data.tracks.filter((track) => !track.posted).map((track) => ({
      id: track.id,
      img: track.img,
      name: track.title,
      link: track.user ? `/user/${track.user.link}/track/${track.link}` : undefined,
      actionable: true,
      actionLabel: 'Add',
      onAction: () => handleAddTrack(track.id)
    }))
  }

  return (
    <CommunityActionSearch
      open={isSearchOpen}
      onClose={() => setIsSearchOpen(false)}
      onToggle={setIsSearchOpen}
      onSearch={searchForTrack}
      onAction={handleAddTrack}
      title={`Add track to ${groupName}`}
      entityName='tracks'
      toggler={
        <Linkton
          className={styles.button}
          icon='Keys-or-notes'
          iconPlacement='left'
          type='outline'
        >
          Add Track
        </Linkton>
      }
    />
  )
}
