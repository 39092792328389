// extracted by mini-css-extract-plugin
var _1 = "pfx-Message-module__age--PW47Y";
var _2 = "pfx-Message-module__avatars--xXF42";
var _3 = "#000";
var _4 = "pfx-Message-module__body--gp9EB";
var _5 = "pfx-Message-module__body-preview--pjTY6";
var _6 = "1260px";
var _7 = "1020px";
var _8 = "668px";
var _9 = "1550px";
var _a = "pfx-Message-module__container--wWQe2";
var _b = "1140px";
var _c = "940px";
var _d = "100%";
var _e = "15px";
var _f = "620px";
var _10 = "pfx-Message-module__content--zvnUD";
var _11 = "pfx-Message-module__content-preview--CbRl8";
var _12 = "pfx-Message-module__crew--D_AOR";
var _13 = "#f53535";
var _14 = "rgba(0,0,0,.2)";
var _15 = "rgba(255,255,255,.38)";
var _16 = ".38";
var _17 = "rgba(255,255,255,.2)";
var _18 = "#0025ff";
var _19 = "pfx-Message-module__glitch-anim--dLhDS";
var _1a = "pfx-Message-module__glitch-anim-2--iGeyx";
var _1b = "#f60";
var _1c = "#41ead4";
var _1d = "#2cdd78";
var _1e = "#66ab23";
var _1f = "#f5f5f5";
var _20 = "#c3c3c3";
var _21 = "#ececec";
var _22 = "#252525";
var _23 = "#272727";
var _24 = "#c6c6c5";
var _25 = "#333";
var _26 = "#3c3c3c";
var _27 = "#9c9b9b";
var _28 = "#8c8c8c";
var _29 = "#6f6f6e";
var _2a = "#3c3c3b";
var _2b = "#373737";
var _2c = "#6a6a69";
var _2d = "#6f6f6f";
var _2e = "#9f9e9e";
var _2f = "#282828";
var _30 = "#828181";
var _31 = "#1d1d1d";
var _32 = "#979797";
var _33 = "#9c9b9b";
var _34 = "#a7a7a6";
var _35 = "#c6c6c5";
var _36 = "#d8d8d7";
var _37 = "#dddddc";
var _38 = "72px";
var _39 = "40px";
var _3a = "36px";
var _3b = "25px";
var _3c = "18px";
var _3d = "13px";
var _3e = "#0025ff";
var _3f = "rgba(255,255,255,.12)";
var _40 = "#6535f5";
var _41 = "2px";
var _42 = "56px";
var _43 = "56px";
var _44 = "#41ead4";
var _45 = "#f60";
var _46 = "#f83600";
var _47 = "#881f00";
var _48 = "18px";
var _49 = "15px";
var _4a = "pfx-Message-module__read--Otap4";
var _4b = "red";
var _4c = "13px";
var _4d = "rgba(255,255,255,.07)";
var _4e = "#35465c";
var _4f = "#55acee";
var _50 = "pfx-Message-module__unread-indicator--Ktbqn";
var _51 = "pfx-Message-module__unread-indicator-container--zOe15";
var _52 = "pfx-Message-module__username--Gp0EA";
var _53 = "pfx-Message-module__usernames--VpzUz";
var _54 = "pfx-Message-module__users-and-age--eRae5";
var _55 = "pfx-Message-module__view-message--jcufQ";
var _56 = "#fff";
var _57 = "#ffce36";
var _58 = "#ffcd00";
var _59 = "red";
export { _1 as "age", _2 as "avatars", _3 as "black", _4 as "body", _5 as "bodyPreview", _6 as "breakLarge", _7 as "breakMedium", _8 as "breakSmall", _9 as "breakVLarge", _a as "container", _b as "containerLarge", _c as "containerMedium", _d as "containerMobile", _e as "containerPaddingMobile", _f as "containerSmall", _10 as "content", _11 as "contentPreview", _12 as "crew", _13 as "danger", _14 as "darkElement", _15 as "disabled", _16 as "disabledOpacity", _17 as "extraLightElement", _18 as "facebook", _19 as "glitchAnim", _1a as "glitchAnim2", _1b as "google", _1c as "green", _1d as "green2", _1e as "greenColor", _1f as "grey1", _20 as "grey10", _21 as "grey2", _22 as "grey25", _23 as "grey27", _24 as "grey3", _25 as "grey33", _26 as "grey3C", _27 as "grey4", _28 as "grey41", _29 as "grey5", _2a as "grey6", _2b as "grey61", _2c as "grey6A", _2d as "grey6F", _2e as "grey7", _2f as "grey8", _30 as "grey82", _31 as "grey9", _32 as "grey97", _33 as "grey9C", _34 as "greyA7", _35 as "greyC6", _36 as "greyD8", _37 as "greyDd", _38 as "header1", _39 as "header2", _3a as "header3", _3b as "header4", _3c as "header5", _3d as "header6", _3e as "klein", _3f as "lightElement", _40 as "lilac", _41 as "menuBorder", _42 as "menuHeight", _43 as "menuMobileHeight", _44 as "ocean", _45 as "orange", _46 as "orange2", _47 as "orangeDark", _48 as "paragraph", _49 as "paragraphSmall", _4a as "read", _4b as "red", _4c as "subHeaderSmall", _4d as "subtleLightElement", _4e as "tumblr", _4f as "twitter", _50 as "unreadIndicator", _51 as "unreadIndicatorContainer", _52 as "username", _53 as "usernames", _54 as "usersAndAge", _55 as "viewMessage", _56 as "white", _57 as "yellow", _58 as "yellowDeep", _59 as "youtube" }
