import React from 'react'
import {
  getGroupComments,
  reportGroupComment,
  postGroupComment,
  deleteGroupComment
} from '@common/http'
import {now} from 'lodash'
import createProvider from "../../store/provider";
import {connect} from "react-redux";
import {setFlashMessageByValues} from "../../store/actions/flashMessage";

const delay = ms => new Promise(res => setTimeout(res, ms))
// todo see what else i need form here then delete
export default (WrapperComponent, groupId, initialCommentList = []) => {
  class GroupCommentsWithDatasource extends React.Component {

    constructor() {
      super()
      this.state = {
        commentsList: this.mapBackendResponse(initialCommentList),
        page: 1,
        items: 5,
        showItems: 5,
        commentsCount: initialCommentList.length
      }
    }

    mapBackendResponse(comments = []) {
      return comments.map((comment) => {
        return {
          createdAt: comment.createdAt,
          content: comment.content,
          username: comment.user.username,
          avatarUrl: comment.user.avatarSmallUrl,
          profileLink: comment.user.profileLink,
          id: comment.id
        }
      })
    }

    async componentWillMount() {
      this.setState({loading: true, startFrom: now()}, async () => {
        const {page, items, startFrom} = this.state
        const {data} = await getGroupComments(groupId, {page, items, startFrom})

        await delay(500)

        if (data.comments) {
          this.setState({
            commentsList: this.mapBackendResponse(data.comments),
            shouldShowViewMore: !!data.meta.nextPage,
            commentsCount: data.meta.count
          })
        }

        this.setState({loading: false})
      })
    }

    handleNewComment = async ({content}) => {
      const {startFrom, showItems, loading} = this.state
      const {setFlashMessageByValues} = this.props

      if (!loading) {
        this.setState({loading: true})
        await postGroupComment(groupId, content)
        const {data} = await getGroupComments(groupId, {page: 1, items: showItems, startFrom})

        if (data.comments) {
          this.setState({
            commentsList: this.mapBackendResponse(data.comments),
            startFrom: now(),
            shouldShowViewMore: !!data.meta.nextPage,
            commentsCount: data.meta.count
          })
        }

        this.setState({loading: false})
        setFlashMessageByValues({text: 'Comment posted successfully!'})
      }

    }

    handleDeleteComment = async (commentId) => {
      const {startFrom, showItems} = this.state
      const {setFlashMessageByValues} = this.props
      this.setState({loading: true})
      await deleteGroupComment(groupId, commentId)
      const {data} = await getGroupComments(groupId, {page: 1, items: showItems, startFrom})

      if (data.comments) {
        this.setState({
          commentsList: this.mapBackendResponse(data.comments),
          shouldShowViewMore: !!data.meta.nextPage,
          commentsCount: data.meta.count
        })
      }

      this.setState({loading: false})
      setFlashMessageByValues({text: 'Comment deleted successfully!'})
    }

    handleReportComment = async ({reason, commentId}) => {
      const {setFlashMessageByValues} = this.props
      this.setState({loading: true})
      await reportGroupComment(groupId, commentId, reason)
      this.setState({loading: false})
      setFlashMessageByValues({text: 'Comment reported successfully!'})
    }

    handleFetchMore = async () => {
      this.setState({loading: true})
      const {page, items, commentsList, startFrom, showItems} = this.state
      const nextPage = page + 1
      const {data} = await getGroupComments(groupId, {page: nextPage, items, startFrom})
      this.setState({
        commentsList: [...commentsList, ...this.mapBackendResponse(data.comments)],
        showItems: showItems + items,
        shouldShowViewMore: !!data.meta.nextPage,
        loading: false,
        commentsCount: data.meta.count,
        page: nextPage
      })
    }

    render() {
      return (
        <WrapperComponent
          commentsList={this.state.commentsList}
          totalCommentsCount={this.state.commentsCount}
          onPost={this.handleNewComment}
          onDelete={this.handleDeleteComment}
          onReport={this.handleReportComment}
          onFetchMore={this.handleFetchMore}
          shouldShowViewMore={this.state.shouldShowViewMore}
          {...this.props} />
      );
    }
  }

  const mapDispatchToProps = {
    setFlashMessageByValues
  }

  return createProvider(connect(null, mapDispatchToProps)(GroupCommentsWithDatasource, null))
}
