'use client'

import { useContext, createContext } from "react";

export const BlockStylesContext = createContext({
  isFirst: false,
  width: "normal",
  overflow: undefined,
  theme: {},
  spacing: true,
});

export const useBlockStylesContext = () => {
  const context = useContext(BlockStylesContext);

  return context;
};
