import React from "react";
import PropTypes from "prop-types";
import { MultiListItem } from "./components/MultiListItem";
import { multiListCnFn } from "./MultiList.cn";
import "./MultiList.style.scss";

const MultiList = ({ children, className, type = "unordered", start = 1 }) => {
  const List = type === "unordered" ? "ul" : "ol";
  return (
    <List className={multiListCnFn(className)}>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          ...child.props,
          type,
          number: start + index,
        });
      })}
    </List>
  );
};

export const MultiListPropTypes = {
  items: PropTypes.arrayOf(PropTypes.string),
  type: PropTypes.oneOf(["ordered", "unordered"]),
  children: PropTypes.arrayOf(PropTypes.instanceOf(MultiListItem)),
};

MultiList.propTypes = PropTypes.shape(MultiListPropTypes).isRequired;

export default MultiList;
