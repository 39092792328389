import { StudioConfiguration } from "./config";

export const oAuthPopupWindowName = 'OAuthPopupWindow'

export class MessageHandler {
  static postMessage = (action, payload = {}) => {
    window.parent?.postMessage(
      {
        action,
        payload,
      },
      "*"
    );

    window?.postMessage(
      {
        action,
        payload,
      },
      "*"
    );

    window.opener?.postMessage(
      {
        action,
        payload,
      },
      "*"
    );
  };

  static postSizeMessage = () => {
    MessageHandler.postMessage(StudioConfiguration.popup.common.events.resize, {
      height: document.body.scrollHeight,
      width: document.body.scrollWidth,
    });
  };

  static postSignInMessage = () => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.loginFlow.events.signIn,
      {}
    );
  };

  static postSignInOAuthMessage = (data) => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.loginFlow.events.signInOAuth,
      data
    );
  };

  static postSignedUpMessage = (accountType) => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.loginFlow.events.signUp,
      { accountType: accountType }
    );
  };

  static postPurchaseMessage = () => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.loginFlow.events.purchase,
      {}
    );
  };

  static postConsentAgreedMessage = () => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.common.events.consentAgreed,
      {}
    );
  };

  static postLogoutMessage = () => {
    MessageHandler.postMessage(
      StudioConfiguration.popup.common.events.logOut,
      {}
    );
  };

  static isInStudioLoginFlowPopup = () => this.isInStudioLoginFlowModal() || this.isInOAuthLoginPopup()

  static isInStudioLoginFlowModal = () => StudioConfiguration.popup.loginFlow.name === window.name;

  static isInOAuthLoginPopup = () => oAuthPopupWindowName === window.name;

  static isInIFrame = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };
}
