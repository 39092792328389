import './style.scss'

import React from 'react'
import PropTypes from 'prop-types'
import { Bem, pluralize, get } from '../../common/utils'
import { isMonthlyIntro } from '@components/price-plans/utils'
import Button from '../button/index'
import Icon from '../icon/index'
import List, { ListItem } from '../list/index'
import SlideBar from '../slide-bar/index'
import { LINKS, PLAN_PERIOD_STR, ACCOUNT_TYPES } from '../../common/consts'
import { first } from 'lodash'
import Switcher from '@components/switcher'
import PricingValue from './components/pricing-value'

const cn = new Bem({
  name: 'pricing-table',
  prefix: 'pfx-'
})

const periodToMonth = {
  [PLAN_PERIOD_STR.MONTHLY]: 'month',
  [PLAN_PERIOD_STR.YEARLY]: 'year'
}

class PricingTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      mobileDetailsOpen: false,
      selectedPeriod: this.props.selectedPeriod || PLAN_PERIOD_STR.YEARLY
    }

    this.openMobileDetails = this.openMobileDetails.bind(this)
    this.closeMobileDetails = this.closeMobileDetails.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.selectedPeriod !== this.state.selectedPeriod) {
      this.setState({
        selectedPeriod: nextProps.selectedPeriod
      })
    }
  }

  closeMobileDetails () {
    this.setState({
      mobileDetailsOpen: false
    })
  }

  openMobileDetails (column) {
    if (this.mounted) {
      this.setState({
        mobileDetailsOpen: true,
        activeColumn: column
      })
    }
  }

  componentDidMount () {
    this.mounted = true
  }

  prepareCyclesPromotionInfo (selectedPeriod) {
    return `Discount applies to ${selectedPeriod.cycles} ${pluralize(periodToMonth[this.state.selectedPeriod], selectedPeriod.cycles)}`
  }

  renderButton (column, forceType) {
    let selectedPeriod = get(column.columnConfig, this.state.selectedPeriod, {})
    if (!selectedPeriod && this.state.selectedPeriod === PLAN_PERIOD_STR.MONTHLY) selectedPeriod = get(column.columnConfig, 'yearlyToMonthly')
    const type = forceType || (column.highlight ? 'primary-full' : 'primary')

    return (
      <Button
        type={type}
        disabled={selectedPeriod.shouldDisable}
        onClick={() => selectedPeriod.onClick(this.props.isUserAuthenticated ? LINKS.CHECKOUT : LINKS.AUTH)}
      >
        {selectedPeriod.buttonLabel}
      </Button>
    )
  }

  renderFeatures (features = [], mobile) {
    return (
      <List className={cn('features', { mobile })}>
        {features.map((item, i) => {
          return (
            <ListItem key={i} disabled={!item.enable} className={cn('features-item')}>
              <div dangerouslySetInnerHTML={{ __html: item.safeHtml }} className={cn('features-item-html-wrapper')}/>
            </ListItem>
          )
        })}
      </List>
    )
  }

  getPeriodData(column) {
    const { columnConfig } = column

    if (!column || !columnConfig) return null

    const isDisabledIntro = isMonthlyIntro(this.state.selectedPeriod, ACCOUNT_TYPES[columnConfig.plan.toUpperCase()])
    const { monthly = {}, yearlyToMonthly = {}, discountedYearlyToMonthly = {} } = columnConfig

    let selectedPeriodData

    if (isDisabledIntro) {
      selectedPeriodData = yearlyToMonthly
    } else if (this.state.selectedPeriod === PLAN_PERIOD_STR.YEARLY) {
      selectedPeriodData = discountedYearlyToMonthly.total || discountedYearlyToMonthly.originalTotalPrice
          ? discountedYearlyToMonthly
          : yearlyToMonthly
    } else {
      selectedPeriodData = monthly
    }

    return selectedPeriodData
  }

  renderTableColumn (column, last) {
    const {
      name,
      features,
      highlight
    } = column

    const selectedPeriodData = this.getPeriodData(column)
    const isPromotion = selectedPeriodData.couponDiscountAmount > 0

    return (
      <div
        className={cn('column', { highlight, last, promotion: isPromotion })}
        key={name}
        onClick={() => this.openMobileDetails(column)}>
        <div className={cn('highlight-label')}>
          <div className={cn('highlight-label-wrapper')}>
            {isPromotion ? `${selectedPeriodData.couponDiscountPercentage}% off!` : 'Popular'}
          </div>
        </div>
        <div className={cn('top-container')}>
          <div className={cn('plan-name', null, 'pfx-sub-header pfx--align-center')}>{name}</div>
          <PricingValue
            price={selectedPeriodData || {}}
            period={periodToMonth[PLAN_PERIOD_STR.MONTHLY]}
            isPromotion={isPromotion}
            isHighlight={highlight}
          />
          <div className={cn('tablet-actions-container')}>
            { this.renderButton(column, isPromotion ? 'promotion' : null) }
          </div>
          {isPromotion && highlight && (
            <div className={cn('cycles-info', 'mobile')}>
              {this.prepareCyclesPromotionInfo(selectedPeriodData)}
            </div>
          )}
        </div>
        { this.renderFeatures(features, null, column.highlight) }
        <div className={cn('actions-container')}>
          { this.renderButton(column, (isPromotion && highlight) ? 'promotion' : null) }
        </div>
        {isPromotion && highlight && (
          <div className={cn('cycles-info')}>
            {this.prepareCyclesPromotionInfo(selectedPeriodData)}
          </div>
        )}

        <div className={cn('mobile-goto-details', highlight ? 'highlight' : null)}>
          {/* <Icon icon='go' /> */}
          <Icon
            size={30}
            icon='new-arrow-right'
            key='new-arrow-right'
            className={cn('arrow', 'right')}
          />
        </div>
      </div>
    )
  }

  renderMobileDetails () {
    const activeColumn = this.state.activeColumn || first(this.props.items)

    const {
      name,
      features,
      highlight
    } = activeColumn

    const selectedPeriodData = this.getPeriodData(activeColumn)

    if (!selectedPeriodData) return null

    const isPromotion = selectedPeriodData.couponDiscountAmount > 0

    return (
      <SlideBar className={cn('mobile-details', { highlight, promotion: isPromotion })} open={this.state.mobileDetailsOpen}>
        <Icon
          size={30}
          icon='new-arrow-left'
          key='new-arrow-left'
          className={cn('arrow', 'left')}
          onClick={this.closeMobileDetails}
        />
        <div className={cn('mobile-details-wrapper')}>
          <div className={cn('top-container')}>
            <div className={cn('plan-name', null, 'pfx-sub-header pfx--align-center')}>{name}</div>
            <PricingValue
              price={selectedPeriodData || {}}
              period={periodToMonth[PLAN_PERIOD_STR.MONTHLY]}
              isPromotion={isPromotion}
              isHighlight={highlight}
              centered
            />
          </div>
          <div className={cn('mobile-actions-container')}>
            { this.renderButton(activeColumn, (isPromotion && highlight) ? 'promotion' : null) }
            {isPromotion && highlight && (
              <div className={cn('cycles-info', 'mobile')}>
                {this.prepareCyclesPromotionInfo(selectedPeriodData)}
              </div>
            )}
          </div>
          { this.renderFeatures(features, true, highlight) }
        </div>
      </SlideBar>
    )
  }

  onSwitcherUpdate = (updatedValue) => {
    this.setState({
      selectedPeriod: updatedValue
    })
  }

  render () {
    return (
      <div>
        <Switcher className={cn('switcher', 'mobile', 'd-flex justify-content-center pb-3')} buttons={[
          { label: 'Billed annually', value: PLAN_PERIOD_STR.YEARLY },
          { label: 'Billed monthly', value: PLAN_PERIOD_STR.MONTHLY }
        ]} selected={this.state.selectedPeriod} onUpdate={this.onSwitcherUpdate} />
        <div className={cn(null, null, this.props.className)}>
          {this.props.items.map((column, idx, columns) => this.renderTableColumn(column, (idx === columns.length - 1)))}
          {this.renderMobileDetails()}
        </div>
      </div>
    )
  }
}

PricingTable.propTypes = {
  items: PropTypes.array.isRequired,
  isUserAuthenticated: PropTypes.bool.isRequired,
  selectedPeriod: PropTypes.oneOf([PLAN_PERIOD_STR.MONTHLY, PLAN_PERIOD_STR.YEARLY]),
  className: PropTypes.string
}

export default PricingTable
