import React from 'react'
import Linkton from '@components/linkton'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import { connect, useSelector } from 'react-redux'
import * as styles from './CommunityLikeButton.module.scss'

const CommunityLikeButton = (props) => {
  const { isLiked, toggleLike, isDisabled } = props

  const currentUser = useSelector(selectCurrentUser)

  if (!currentUser) return null

  return (
    <Linkton icon={isLiked ? 'Favorite-solid' : 'Favorite'} type='outline' iconPlacement='left' className={styles.button} onClick={toggleLike} isDisabled={isDisabled}>Like</Linkton>
  )
}

export default createProvider(connect(null)(CommunityLikeButton))
