import { identity } from 'lodash'
import store from 'store'
import { GTM_EVENTS, GTM_PARAMS_TRIGGERS, LOCAL_STORAGE_KEYS } from '../common/consts'
import { getQueryStringValue, removeUrlParameter } from '../common/utils'
import {
    getDataLayerFlat,
    sendAnalyticsEvent,
    getContentNameBasedOnSignUpUrl,
    getContentValueBasedOnSignUpUrl
} from '../common/analytics'
import { SUCCESSFUL_SIGN_UP_PARAM } from '../containers/auth-gateway-view/auth-gateway-view.consts'
import { setSignInRedirect } from '../store/actions/app'

function processQueryParams(reduxStore) {
    const {
        FACEBOOK_SIGN_UP,
        GOOGLE_SIGN_UP,
        EMAIL_SIGN_UP,
        CAMPAIGN_TRACK_POSTED_SUCCESS,
        CAMPAIGN_ID
    } = GTM_PARAMS_TRIGGERS

    const socialSignUpParams = [FACEBOOK_SIGN_UP, GOOGLE_SIGN_UP, EMAIL_SIGN_UP]
    const isAnyParam = socialSignUpParams
        .map(val => getQueryStringValue(val))
        .some(identity)

    const campaignSubmittion = getQueryStringValue(CAMPAIGN_TRACK_POSTED_SUCCESS)
    const campaignId = getQueryStringValue(CAMPAIGN_ID)

    if (getDataLayerFlat().userid) {
        const value = store.get(LOCAL_STORAGE_KEYS.RETURNING_USER)
        if (!value) store.set(LOCAL_STORAGE_KEYS.RETURNING_USER, true)
    }

    const history = window.history
    if (getQueryStringValue(SUCCESSFUL_SIGN_UP_PARAM) && history) {
        setSignInRedirect(null)(reduxStore.dispatch)

        // Remove param from url without reloading
        history.pushState(null, '', removeUrlParameter(window.location.href, SUCCESSFUL_SIGN_UP_PARAM))
    }

    if (isAnyParam) {
        const urlWithoutParam = socialSignUpParams
            .reduce((memo, val) => removeUrlParameter(memo, val), window.location.href)

        sendAnalyticsEvent(GTM_EVENTS.USER_SIGN_UP)

        if (window.history) {
            window.history.pushState(null, '', urlWithoutParam)
        }
    }

    if (campaignSubmittion && campaignId) {
        const urlWithoutParam = [CAMPAIGN_TRACK_POSTED_SUCCESS, CAMPAIGN_ID]
            .reduce((memo, val) => removeUrlParameter(memo, val), window.location.href)
        const campaignSubmissionValue = campaignSubmittion === 'studio_flow' ? 1 : 0

        sendAnalyticsEvent(GTM_EVENTS.GTM_EVENT_POSTED_CAMPAIGN_TRACK, campaignId, campaignSubmissionValue)

        if (window.history) {
            window.history.pushState(null, '', urlWithoutParam)
        }
    }

    if (window.dataLayer) {
        window.dataLayer.push({
            contentName: getContentNameBasedOnSignUpUrl(),
            contentValue: getContentValueBasedOnSignUpUrl()
        })
    }
}

export default processQueryParams
