import React from 'react'
import Linkton from '@components/linkton'
import * as styles from './CommunityButton.module.scss'

const CommunityButton = (props) => {
  const { fullWidthOnMobile = true, className = '', ...rest } = props

  return (
    <Linkton className={`${fullWidthOnMobile === false ? styles.button : styles.buttonFlex} ${className}`} {...rest} />
  )
}

export default CommunityButton
