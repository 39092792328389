import React from "react";
import createProvider from "@store/provider";
import { connect } from "react-redux";
import { pause, play, stop } from "@store/actions/player";
import PropTypes from "prop-types";
import "./with-player.scss";
import { Bem, Enum, get } from "../../../common/utils";
import Icon from "../../icon/index";

const cn = new Bem({
  name: "with-player",
  prefix: "pfx-",
});

export const PLAY_BUTTON_DISPLAY_MODE = new Enum(
  "HOVER-TRIGGERED",
  "ALWAYS-VISIBLE"
);

export const withPlayer = (WrappedComponent) => {
  class WithPlayer extends React.Component {
    static propTypes = {
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
      title: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      duration: PropTypes.number.isRequired,
      directWaveformUrl: PropTypes.string.isRequired,
      permalink: PropTypes.string,
      profileLink: PropTypes.string,
      shortLinkId: PropTypes.string,
      songUrl: PropTypes.string.isRequired,
      fileName: PropTypes.string,
      isPlaying: PropTypes.bool,
      isPaused: PropTypes.bool,
      currentSongUrl: PropTypes.string,
      currentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      doesUserLike: PropTypes.bool,
      playButton: PropTypes.oneOf([
        PLAY_BUTTON_DISPLAY_MODE["HOVER-TRIGGERED"],
        PLAY_BUTTON_DISPLAY_MODE["ALWAYS-VISIBLE"],
      ]),
    };

    static defaultProps = {
      playButton: PLAY_BUTTON_DISPLAY_MODE["HOVER-TRIGGERED"],
    };

    handleOnClick = (e) => {
      const {
        id,
        title,
        username,
        duration,
        directWaveformUrl,
        permalink,
        profileLink,
        shortLinkId,
        songUrl,
        fileName,
        isPlaying,
        dispatch,
        play,
        pause,
        dragging,
        doesUserLike,
      } = this.props;

      if (!dragging) {
        if (isPlaying) {
          if (this.isPlayingCurrentSong()) {
            dispatch(pause());
          } else {
            dispatch(stop());
            dispatch(
              play({
                id,
                title,
                username,
                duration,
                directWaveformUrl,
                permalink,
                profileLink,
                shortLinkId,
                songUrl,
                fileName,
                doesUserLike,
              })
            );
          }
        } else {
          dispatch(
            play({
              id,
              title,
              username,
              duration,
              directWaveformUrl,
              permalink,
              profileLink,
              shortLinkId,
              songUrl,
              fileName,
              doesUserLike,
            })
          );
        }
      }
    };

    isPlayingCurrentSong() {
      const { id, currentId, isPlaying } = this.props;
      return currentId === id && isPlaying;
    }

    isPausingCurrentSong() {
      const { id, currentId, isPaused } = this.props;
      return currentId === id && isPaused;
    }

    render() {
      const { className, playButton, ...passThroughProps } = this.props;

      const playing = this.isPlayingCurrentSong();
      const paused = this.isPausingCurrentSong();

      return (
        <div
          onClick={this.handleOnClick}
          className={cn(null, { playing, paused }, className)}
        >
          <WrappedComponent {...passThroughProps} />
          <div className={cn("overlay", playButton)}>
            <div className={cn("blue-square")}>
              <Icon
                size={30}
                className={cn("icon")}
                icon={playing ? "new-pasue" : "new-play"}
              />
            </div>
          </div>
        </div>
      );
    }
  }

  const mapStateToProps = (state) => {
    return {
      isPlaying: get(state, "player.isPlaying"),
      currentSongUrl: get(state, "player.songUrl"),
      currentId: get(state, "player.id"),
      isPaused: get(state, "player.isPaused"),
      dragging: get(state, "app.dragging"),
    };
  };

  const mapDispatchToProps = { play, stop, pause };

  return createProvider(
    connect(mapStateToProps, mapDispatchToProps)(WithPlayer, false)
  );
};
