import React, { Component } from 'react'
import Typeahead from '../../components/typeahead'
import PropTypes from 'prop-types'

class GenreList extends Component {
  render () {
    const { name, options, value, onChange } = this.props
    const mappedOptions = this.mapOptions(options)

    const handleChange = ({ value, label }) => {
      if (onChange) onChange({ value, label })
    }

    return <Typeahead
      placeholder={'Select genre'}
      name={name}
      options={mappedOptions}
      value={Number(value) || undefined}
      emptyResultsMessage="Genre not found. You're way ahead of your time, buddy."
      onChange={handleChange}
    />
  }

  mapOptions (options) {
    return options.map(option => {
      return { value: option.id, label: option.name }
    })
  }
}

GenreList.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })),
  value: PropTypes.number
}

export default GenreList
