import { useRef } from 'react'
import useOverflow from '@root/hooks/useOverflow'
import { denormalizeResponse } from '@common/http'
import * as styles from './CommunityAvatars.module.scss'

export default function CommunityAvatars (props) {
  const { count, morePath, users, small = false, denormalizeUsers = true, className = '' } = props

  const denormalizedUsers = denormalizeUsers ? denormalizeResponse(users) : users

  const containerRef = useRef(null)
  const itemsRef = useRef(null)
  const remainingRef = useRef(null)

  const { renderedCount } = useOverflow(containerRef, itemsRef, remainingRef)
  const remainingCount = count - renderedCount

  const MoreWrapper = morePath ? 'a' : 'div'

  return (
    <div ref={containerRef} className={`${styles.container} ${className}`}>
      <div ref={itemsRef} className={`${styles.innerContainer} ${!!small && styles.smallInnerContainer}`}>
        {denormalizedUsers.map((user) => (
          <a key={user.id} href={user.selfLink} title={user.username}>
            <img className={`${styles.avatar} ${!!small && styles.smallAvatar}`} src={user.avatarSmallUrl} alt={`${user.username} avatar`} />
          </a>
        ))}
        {remainingCount > 0 && <MoreWrapper ref={remainingRef} className={styles.moreCount} href={morePath}>+{remainingCount}</MoreWrapper>}
      </div>
    </div>
  )
}
