import React from 'react'
import * as styles from './Label.module.scss'

const Label = (props) => {
  const { label, children, className = '', labelClassName = '' } = props

  return (
    <label className={className}>
      <span className={`${styles.label} ${labelClassName}`}>{label}</span>
      {children}
    </label>
  )
}

export default Label
