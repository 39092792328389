import { Bem } from '../../../common/utils'

const cn = Bem({
  name: 'mobile-menu',
  prefix: 'pfx-'
})

export const mobileMenuCnFn = (modifiers) => cn(null, modifiers)

export const mobileOverlayCn = cn('overlay')

export const mobileMenuHeaderCn = cn('header')

export const mobileMenuContentCn = cn('content')

export const mobileMenuSlideCnFn = (modifiers) => cn('slide', modifiers)

export const mobileMenuHeaderTitleCn = cn('header-title')

export const mobileMenuCloseIconCn = cn('close-menu-icon')

export const mobileMenuBackIconCn = cn('back-icon')

export const mobileMenuItemListCn = cn('item-list')

export const mobileMenuIconCn = cn('item-icon')

export const mobileMenuItemCn = cn('item')

export const mobileMenuSubItemCn = cn('sub-item')

export const signupMenuCn = cn('signup-menu')
