import React from 'react'
import * as styles from './UnorderedList.module.scss'

const UnorderedList = (props) => {
  const { children } = props

  return (
    <ul className={styles.container}>
      {children}
    </ul>
  )
}

export default UnorderedList
