import React from 'react'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import { flashMessageModel } from '@models/flashMessage'
import { Bem, get } from '@common/utils'
import './styles.scss'
import Icon from '@components/icon/index'
import { hideFlashMessage } from '@store/actions/flashMessage'

const cn = new Bem({
  name: 'flash-message',
  prefix: 'pfx-'
})

export class FlashMessage extends React.Component {
  onCloseButtonClick = () => {
    this.props.hideFlashMessage()
  }

  render () {
    const { show, type, text, closeButton } = this.props

    if (!show) return null

    return (
      <div className={cn(null, { [type]: type, show })}>
        <div className={cn('content')}>
          <div dangerouslySetInnerHTML={{ __html: text }} />
        </div>
        {
          closeButton && show &&
          <Icon
            icon='close'
            size={30}
            type='blank'
            className={cn('close')}
            onClick={this.onCloseButtonClick} />
        }
      </div>
    )
  }
}

FlashMessage.propTypes = flashMessageModel

FlashMessage.defaultProps = {
  text: null,
  type: null,
  show: false
}

const mapStateToProps = (state) => {
  return {
    text: get(state, 'flashMessage.text'),
    type: get(state, 'flashMessage.type'),
    show: get(state, 'flashMessage.show'),
    closeButton: get(state, 'flashMessage.closeButton'),
  }
}

const mapDispatchToProps = {
  hideFlashMessage
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(FlashMessage))
