import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import createProvider from '@store/provider'
import { connect } from 'react-redux'
import LandingStudioFeatures from '../../components/landing-studio-features'
import LandingCommunity from '../../components/landing-community'
import LandingJoin from '../../components/landing-join'
import LandingCollabLive from '../../components/landing-collab-live'
import LandingHero from '../../components/landing-hero'
import { canUseDOM } from '../../common/utils'
import LandingPress from '../../components/landing-press'

import './style.scss'

class LandingPageView extends Component {
  render () {
    return (
      <div>
        <div>
          <LandingHero />
          <LandingStudioFeatures />
          <LandingCollabLive />
          <LandingPress />
          <LandingCommunity tracks={this.props.carouselData} />
          <LandingJoin />
        </div>
      </div>
    )
  }
}

LandingPageView.propTypes = PropTypes.shape({
  currentUser: PropTypes.object,
  carouselData: PropTypes.array
}).isRequired

LandingPageView.defaultProps = {
  currentUser: null,
  carouselData: null
}

const mapStateToProps = (state) => {
  const threshold = canUseDOM() ? window.innerHeight - 56 : 0
  return {
    currentUser: get(state, 'backendData.currentUser'),
    floatingMenuVisible: get(state, 'app.windowScrollTop') >= threshold
  }
}

export default createProvider(connect(mapStateToProps)(LandingPageView))
