import React from 'react'
import {PRODUCT_TYPE} from '../services/consts'
import ButtonWithStudioLink from "../../../components/button/button-with-studio-link";
import {
  Bem,
  canUseDOM,
  clearCustomSuccessPaymentButton,
  getCustomSuccessPaymentButton,
  clearLoginRedirectData,
  gotoLocation
} from '../../../common/utils'
import './checkout-thank-you-page.scss'
import Linkton, { COLOR, SIZE, TYPE } from '../../../components/linkton'
import Button from '../../../components/button'

const cn = new Bem({
  name: 'checkout-view',
  prefix: 'pfx-'
})

const SuccessButton = ({ closePage = false }) => {
  const customButton = getCustomSuccessPaymentButton()
  clearCustomSuccessPaymentButton()
  clearLoginRedirectData()

  const label = (customButton && customButton.label) || 'Get creative'
  const url = customButton && customButton.url
  const ButtonProps = {
    className: cn('thank-you-button'),
    type: 'secondary'
  }

  if (url) {
    const onClick = () => gotoLocation(url)
    return (
      <Linkton
        onClick={onClick}
        type={TYPE.OUTLINE}
        color={COLOR.WHITE}
        size={SIZE.LARGE}
      >
        { label }
      </Linkton>
    )
  } else if (closePage) {
    return <Button {...ButtonProps} onClick={() => window.close()}>Close page</Button>
  } else {
    return <ButtonWithStudioLink {...ButtonProps} isEdu={false}>{ label }</ButtonWithStudioLink>
  }
}

export const CheckoutThankYouPage = ({ productType, closePageAfterSuccess }) => {
  const headerMessage = productType === PRODUCT_TYPE.SUBSCRIPTION
    ? 'Your purchase was successful!'
    : 'Payment complete!'

  const subheaderMessage = productType === PRODUCT_TYPE.SUBSCRIPTION
    ? 'Subscription benefits are now unlocked. Good luck on your creative journey!'
    : 'New stuff has been added to your studio and/or is available for download on your profile orders page.'

  // If a window does not have a parent, its parent property is a reference to itself.
  const shouldShowButton = canUseDOM() && window.parent === window

  return (
    <div className={cn('thank-you')}>
      <div className={cn('thank-you-logo-container')}>
        <a href="/">
          <img src="assets/wordmark-white.svg" className={cn('thank-you-logo')} alt='White background' />
        </a>
      </div>
      <img src='assets/checkout-checkbox.svg' className={cn('thank-you-image')} alt='Thank you'/>
      <h2 className={cn('thank-you-header')}> { headerMessage } </h2>
      <h5 className={cn('thank-you-subheader')}> { subheaderMessage } </h5>
      {shouldShowButton && <SuccessButton closePage={closePageAfterSuccess} />}
    </div>
  )
}
