import * as React from 'react'
import AdminContent from '../../../components/admin-content/admin-content'
import { CarouselResponsive } from '../../../components/carousel/carousel'
import SingleViewAddTrack from '../../../components/carousel/components/single-view-add-track'
import GroupUpdates from '../../remix-group-view/components/group-updates'
import Comments from '../../../components/comments/comments'
import GroupCommentsWithDataSource from '../../../components/comments/group-comments-with-data-source'
import { Bem, mergeLikedTracksMeta } from '../../../common/utils'
import { map, get } from 'lodash'
import SingleViewTrack from '../../../components/carousel/components/single-view-track'
import '../../remix-group-view/containers/remix-content.scss'
import PropTypes from 'prop-types'
import GroupUpdatesWithDataSource from '../../remix-group-view/components/group-updates-with-data-source'
import { getAccountTracksByGroup, getUserTrack, getUserTrackByGroup, getHalloweekData } from '../../../common/http'
import createProvider from '../../../store/provider'
import { connect } from 'react-redux'
import CollabGroupLeaderboard from './collab-group-leaderboard'
import JSONAPISerializer from 'jsonapi-serializer'

import axios from 'axios'

const cn = new Bem({
  name: 'remix-content',
  prefix: 'pfx-'
})

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: 'camelCase'
})

const minCarouselUpdateTimeout = 500

class CollabGroupContent extends React.Component {
  static propTypes = {
    campaignDescription: PropTypes.string.isRequired,
    canPostUpdates: PropTypes.bool,
    canDeleteComments: PropTypes.bool,
    currentUser: PropTypes.object,
    groupId: PropTypes.string.isRequired,
    isMember: PropTypes.bool,
    initialUpdates: PropTypes.array,
    initialComments: PropTypes.array,
    slug: PropTypes.string,
    onCarouselViewChange: PropTypes.func,
    shouldShowGroupComments: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    gotoStudio: PropTypes.func
  }

  static defaultProps = {
    initialUpdates: [],
    initialComments: [],
    shouldShowGroupComments: true
  }

  state = {
    tracks: [],
    userTracks: [],
    userSubmittedTracks: [],
    projects: [],
    projectsNextPage: null,
    projectsLoading: false,
    projectsPageSize: 1,
    projectsLastDisplayedItem: 0,
    leaderboard: []
  }

  constructor(props) {
    super(props)
    this.GroupCommentsWithDataSource = GroupCommentsWithDataSource(Comments, props.groupId, props.initialComments)
    this.GroupUpdatesWithDataSource = GroupUpdatesWithDataSource(GroupUpdates, props.groupId, props.initialUpdates)
  }

  async getAndSetHalloweek() {
    const { data } = await getHalloweekData()
    if (data && data.projects) {
      this.setState({
        leaderboard: data.projects.map(({ id, title, max_active_users_count, members_count }) => ({
          id,
          title,
          maxActiveUsersCount: max_active_users_count,
          totalUsersJoined: members_count
        }))
      })
    }
  }

  async getAndSetTracks() {
    const { data } = await getUserTrack().then(mergeLikedTracksMeta)

    if (data && data.tracks) {
      this.setState({ tracks: data.tracks })
    }
  }

  async getAndSetUserGroupTracks() {
    const { data } = await getUserTrackByGroup(this.props.groupId).then(mergeLikedTracksMeta)

    if (data && data.tracks) {
      this.setState({ userTracks: data.tracks })
    }
  }

  async getAndSetAccountTracksByGroup() {
    const { data } = await getAccountTracksByGroup(this.props.groupId)

    if (data && data.tracks) {
      this.setState({ userSubmittedTracks: data.tracks.filter((t) => t.submitted) })
    }
  }

  fetchProjects = async (page = 1) => {
    this.setState({
      projectsLoading: true
    })

    const time1 = Date.now()
    const response = await axios.get('/api/projects', {
      params: {
        page,
        'q[created_at_gteq]': this.props.startDate,
        'q[created_at_lteq]': this.props.endDate
      }
    })
    const deserialized = await deserializer.deserialize(response.data)
    const { nextPage } = response.data.meta

    const time2 = Date.now()
    const timeDiff = time2 - time1
    if (this.state.projects.length && timeDiff < minCarouselUpdateTimeout) {
      // The purpose of following is to prevent breaking the carousel animation
      await new Promise((res) => setTimeout(res, minCarouselUpdateTimeout - timeDiff))
    }

    await this.setState({
      projectsLoading: false,
      projects: [...this.state.projects, ...deserialized],
      projectsNextPage: nextPage || null
    })
  }

  fetchProjectsIfNecessary = async () => {
    const { projectsLoading, projectsNextPage, projects, projectsPageSize, projectsLastDisplayedItem } = this.state

    if (!projectsLoading && projectsNextPage && projects.length < projectsLastDisplayedItem + projectsPageSize) {
      await this.fetchProjects(projectsNextPage)
    }
  }

  componentWillMount() {
    this.getAndSetTracks()
    this.getAndSetUserGroupTracks()
    this.getAndSetAccountTracksByGroup()
    this.getAndSetHalloweek()
    return this.fetchProjects()
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState !== this.state) {
      return this.fetchProjectsIfNecessary()
    }
  }

  onCarouselViewChange = async (items) => {
    this.setState({
      projectsPageSize: items.length,
      projectsLastDisplayedItem: Math.max.apply(Math, items)
    })
  }

  render() {
    const {
      campaignDescription,
      canPostUpdates,
      currentUser,
      isMember,
      canDeleteComments,
      initialUpdates,
      groupId,
      shouldShowGroupComments = true
    } = this.props

    const { leaderboard } = this.state

    const { GroupCommentsWithDataSource, GroupUpdatesWithDataSource } = this
    const shouldShowUpdates = canPostUpdates || initialUpdates.length
    const addTrackLabel = this.state.projects.length
      ? 'Start a collaboration project.'
      : 'Be the first to start a collab!'

    return (
      <div className={cn()}>
        <AdminContent html={campaignDescription} />
        <CarouselResponsive
          className={cn('featured-tracks')}
          title='Open collaborations'
          theme='light'
          onViewChange={this.onCarouselViewChange}>
          {[
            <SingleViewAddTrack
              innerLabel={'Start a collab'}
              onClick={this.props.gotoStudio}
              key={'single-view'}
              label={addTrackLabel}
            />,
            ...map(this.state.projects, (project) => (
              <SingleViewTrack
                id={project.id}
                key={project.id}
                subtitle={project.owner.username}
                image={project.coverPhotoUrl}
                profileLink={project.owner.profileLink}
                date={project.createdAt}
                title={project.title}
                isCollab={true}
              />
            ))
          ]}
        </CarouselResponsive>
        {leaderboard.length ? <CollabGroupLeaderboard items={this.state.leaderboard} title='Leaderboard' /> : null}
        {shouldShowUpdates && <GroupUpdatesWithDataSource className={cn('group-updates')} canPost={canPostUpdates} />}
        {shouldShowGroupComments ? (
          <GroupCommentsWithDataSource canDelete={canDeleteComments} canPost={!!currentUser && isMember} />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: get(state, 'backendData.currentUser')
  }
}

const mapDispatchToProps = {
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(CollabGroupContent))
