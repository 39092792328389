import { TextFieldControlled } from '@components/text-field'
import Linkton from '@components/linkton'
import * as styles from './SearchField.module.scss'
import { useState } from 'react'

const SearchField = (props) => {
  const { onSearch, initialValue = '', placeholder, className = '' } = props

  const [value, setValue] = useState(initialValue)
  const resetValue = () => setValue('')

  const handleSearch = (e) => {
    e.preventDefault()
    onSearch(value)
  }

  return (
    <form className={`${styles.container} ${className}`} onSubmit={handleSearch}>
      <TextFieldControlled
        className={styles.searchField}
        inputClassName={styles.searchFieldInput}
        layout='dark'
        isTransparent
        value={value}
        onChange={setValue}
        isLaidBack
        type='search'
        showBorderOnFocus={false}
        placeholder={placeholder}
      />

      <div className={`${styles.reset} ${!value && styles.hideReset}`}>
        <Linkton icon='cancel' type='hollow' color='grey' padding={0} iconSize={16} isDisabled={!value} isReset onClick={resetValue} />
        <div className={styles.resetDivider} />
      </div>

      <Linkton icon='search' type='hollow' color='grey' padding={0} iconSize={16} isDisabled={!value} isSubmit />
    </form>
  )
}

export default SearchField
