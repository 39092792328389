import { useStrapiContext } from "../../../contexts/StrapiContext";
import { prepareNavItem } from "../MegaMenu.utils";
import DropdownMenu from "./DropdownMenu";
import {
  menuItemsCn,
  menuItemsInnerCn,
  menuItemsLinkIconCn,
  menuItemsLinkCn,
} from "./MenuItems.cn";
import "./MenuItems.style.scss";
import Icon from '../../icon';

const MenuItemWrapper = ({ item, children, isLinkable }) => {
  const { navigation } = useStrapiContext();

  return isLinkable ? (
    <a className={menuItemsLinkCn} href={navigation.getPath(item)}>
      {children}
    </a>
  ) : (
    <div className={menuItemsLinkCn}>{children}</div>
  );
};

const MenuItems = ({ navigation }) => {
  return (navigation || []).map((navItem) => {
    const { item, items, isLinkable } = prepareNavItem(navItem);
    if (item.menuAttached === false) {
      return null;
    }
    return (
      <div
        className={menuItemsCn({ hover: isLinkable })}
        key={`${item.title}_${item.id}`}
      >
        <MenuItemWrapper item={item} isLinkable={isLinkable}>
          {item.title}
          {items ? (
            <Icon
              type="blank"
              icon="next"
              size={12}
              className={menuItemsLinkIconCn}
            />
          ) : null}
        </MenuItemWrapper>
        {items ? (
          <div className={menuItemsInnerCn}>
            <DropdownMenu items={items} />
          </div>
        ) : null}
      </div>
    );
  });
};

export { MenuItems };
