import React from 'react'
import * as styles from './OrderedList.module.scss'

const OrderedList = (props) => {
  const { children } = props

  return (
    <ol className={styles.container}>
      {children}
    </ol>
  )
}

export default OrderedList
