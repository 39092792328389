import React from 'react'
import './checkout-payment-forms.scss'
import PropTypes from 'prop-types'
import { Bem, get } from '@common/utils'
import { If } from 'react-if'
import { TABS, PRODUCT_TYPE } from '../services/consts'
import { toLower, mapKeys, snakeCase, upperCase } from 'lodash'
import { insertParamToLocation, requireExternalLibrary, formatDateWithTimezone } from '../../../common/utils'
import CheckoutPaypalContainer from './checkout-paypal-container'
import { Elements, StripeProvider } from 'react-stripe-elements'
import StripeForm from './checkout-stripe-form.jsx'
import { getButtonPrice, prepareChargeRequest, prepareDiscountMessage } from '../services/utils'
import { setSignInRedirect } from '../../../store/actions/app'
import { checkSubscriptionPrice, createCharge, createSubscription, createPaymentIntent } from '../../../common/http'
import DiscountInput from '../components/discount-input'
import Message from '@components/message/index'
import { getCurrencySymbol } from '@components/price-plans/utils';
import { stripeMessages, chargebeeMessages } from '../services/consts.jsx'
import { LINKS } from '../../../common/consts'
import LoadingIndicator from '../../../components/loading-indicator'

const cn = new Bem({
  name: 'checkout-view',
  prefix: 'pfx-'
})

class CheckoutPaymentForms extends React.PureComponent {
  state = {
    selectedPaymentTab: TABS.STRIPE,
    stripeLibLoaded: false
  }

  constructor (props) {
    super(props)
    this.state = {
      selectedPaymentTab: this.props.selectedPaymentTab || TABS.STRIPE
    }
    requireExternalLibrary(LINKS.STRIPE_LIBRARY)
      .then(() => {
        this.setState({stripeLibLoaded: true})
      })
  }

  componentWillReceiveProps (nextProps) {
    const { activePeriod } = this.props

    if (activePeriod !== nextProps.activePeriod) {
      this.handleDiscountCancel()
    }
  }

  componentDidMount () {
    this.componentDidUpdate(null)
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      if (this.props.discountCode) {
        this.setState({
          discountCheckbox: true,
          discountCode: this.props.discountCode
        })

        this.handleDiscountApply(this.props.discountCode)
      } else {
        this.refreshSubscriptionPayButtonPrice()
      }
    }
  }

  renderTabItem (val) {
    const { selectedPaymentTab } = this.state
    return (
      <div
        className={cn('payment-tab', {
          active: val === selectedPaymentTab,
          [val]: true,
          disabled: false
        })}
        value={val}
        onClick={() => this.setState({ selectedPaymentTab: val })}
      >
        <If condition={val === TABS.STRIPE}>{this.renderCreditCards()}</If>
        <If condition={val === TABS.PAYPAL}>
          <img alt='Paypal' src='assets/payments/paypal.png' />
        </If>
      </div>
    )
  }

  async refreshSubscriptionPayButtonPrice (discountCode = null) {
    const { discountAppliedCode } = this.state
    const { activeColumn, activePeriod, referralId, onPriceRefresh } = this.props

    try {
      this.setState({ priceLoaded: false })

      const { data } = await checkSubscriptionPrice({
        code: discountCode || discountAppliedCode,
        period: activePeriod + 'ly',
        accountType: activeColumn.columnConfig.plan,
        referralId
      })

      const selectedPlan = data[this.props.plan]
      this.setState({
        monthlyPrice: selectedPlan.monthly,
        yearlyPrice: selectedPlan.yearly,
        currency: selectedPlan.currency
      })

      if (onPriceRefresh) onPriceRefresh(data)

      return data
    } catch (e) {
      this.setState({
        monthlyPrice: null,
        yearlyPrice: null,
        currency: null,
        discountMessage: e.message,
        discountMessageType: 'error'
      })
    } finally {
      this.setState({ priceLoaded: true })
    }
  }

  renderCreditCards () {
    return (
      <div className={cn('credit-cards')}>
        <img src='assets/payments/visa.png' alt='Visa' />
        <img src='assets/payments/mastercard.png' alt='Card' />
        <img src='assets/payments/amex.png' alt='Amex' />
      </div>
    )
  }

  mapKeysToSnakecase = (obj) => {
    return mapKeys(obj, (val, key) => snakeCase(key))
  }

  isErrorCardResponse = (cardActionResponse) => {
    return !(
      cardActionResponse && (cardActionResponse.paymentIntent || cardActionResponse.setupIntent)
    )
  }

  handleSubscriptionPayment = async (stripeApi, stripeCard) => {
    const { email } = this.props.currentUser
    const stripeResponse = await stripeApi.createPaymentMethod(
      'card', { billing_details: { email } }
    )

    if (stripeResponse && stripeResponse.paymentMethod) {
      const payload = this.getPaymentActionPayload(stripeResponse.paymentMethod.id)
      const { data } = await createPaymentIntent(this.mapKeysToSnakecase(payload))

      if (data) {
        if (data.requiresAction) {
          let cardActionResponse
          if (data.setupIntent) {
            cardActionResponse = await stripeApi.handleCardSetup(data.paymentIntentClientSecret, stripeCard)
            payload.paymentIntentId = cardActionResponse.setupIntent.id
          } else {
            cardActionResponse = await stripeApi.handleCardPayment(data.paymentIntentClientSecret, stripeCard, { save_payment_method: true })
            payload.paymentIntentId = cardActionResponse.paymentIntent && cardActionResponse.paymentIntent.id
          }
          if (this.isErrorCardResponse(cardActionResponse)) {
            throw { response: { data: cardActionResponse } }
          }
        } else {
          payload.paymentIntentId = data.paymentIntentId
        }
      }
      return await createSubscription(this.mapKeysToSnakecase(payload))
    } else {
      throw { response: { data: stripeResponse } }
    }

  }

  getPaymentActionPayload = (paymentMethodId) => {
    const { discountAppliedCode } = this.state
    const {
      productType,
      activePeriod,
      products,
      referralId,
      activeColumn
    } = this.props
    const accountType = activeColumn && activeColumn.columnConfig.plan

    return prepareChargeRequest({
      discountAppliedCode,
      productType,
      products,
      accountType,
      activePeriod,
      referralId,
      paymentMethodId
    })
  }

  handleSoundshopPayment = async (stripeApi, stripeCard) => {
    const { email} = this.props.currentUser
    const stripeResponse = await stripeApi.createPaymentMethod('card', {billing_details: {email}})
    if (stripeResponse && stripeResponse.paymentMethod) {
      const payload = this.getPaymentActionPayload(stripeResponse.paymentMethod.id)
      let backendResponse = await createCharge(this.mapKeysToSnakecase(payload))
      if (get(backendResponse, 'data.requiresAction')) {
        const cardActionResponse = await stripeApi.handleCardPayment(backendResponse.data.paymentIntentClientSecret, stripeCard)
        if (cardActionResponse && cardActionResponse.paymentIntent) {
          backendResponse = await createCharge(this.mapKeysToSnakecase({
            paymentIntentId: cardActionResponse.paymentIntent.id
          }))
        } else {
          throw {response: {data: cardActionResponse}}
        }
      }
      return backendResponse
    } else {
      throw {response: {data: stripeResponse}}
    }

  }

  onSubmit = async (stripeApi, stripeCard, totalAmount) => {
    let { productType, currency, currentUser } = this.props
    let response

    try {
      switch (productType) {
        case PRODUCT_TYPE.SOUND:
          response = await this.handleSoundshopPayment(stripeApi, stripeCard)
          break;
        case PRODUCT_TYPE.SUBSCRIPTION:
          response = await this.handleSubscriptionPayment(stripeApi, stripeCard)
          break;
      }

      if (response && response.error) {
        this.handleResponseError(response.error)
      } else {
        this.storeOrderCompleteEvent({ token: response.data.token, totalAmount, currency, email: currentUser.email })
        this.handleResponseSuccess()
      }

    } catch (e) {
      console.error(e)
      this.onPaymentError(e)
    }
  }

  onPaymentError (e) {
    const errorMessage = get(e, 'response.data.error.message') || true
    const errorCode =
      get(e, 'response.data.error.declineCode') || get(e, 'response.data.error.code')

    const error = get(stripeMessages, errorCode) || { message: errorMessage }

    this.handleResponseError(error)
  }

  renderDiscountInput = () => {
    const { productType } = this.props
    const {
      discountCode,
      discountCheckbox,
      discountLoading,
      discountApplied,
      discountMessage,
      discountMessageType
    } = this.state

    return productType === PRODUCT_TYPE.SUBSCRIPTION && (
      <DiscountInput
        onApply={this.handleDiscountApply}
        onCancel={this.handleDiscountCancel}
        onCheckboxChange={this.handleDiscountCheckboxChange}
        onCodeChange={this.handleDiscountInputChange}
        code={discountCode}
        isOpen={discountCheckbox}
        message={discountMessage}
        applied={discountApplied}
        messageType={discountMessageType}
        isLoading={discountLoading}
      />
    )
  }

  renderPromotionalCreditNotice = () => {
    const { activePeriod, currency } = this.props

    const price = get(this.state, `${activePeriod}lyPrice`, {})
    const { refundableCredits, appliedPromoCredits, leftOverPromoCredits } = price || {}

    const currencySymbol = getCurrencySymbol(currency);

    return (
      <div>
        {
          appliedPromoCredits ? (
            <Message type='success'>
              Congratulations! You've got {`${currencySymbol}${appliedPromoCredits} credits applied.`}
              <If condition={!Number.isNaN(leftOverPromoCredits) && leftOverPromoCredits > 0}><span>{`${currencySymbol}${leftOverPromoCredits} credits will be applied to your next renewal.`}</span></If>
            </Message>
          ) : null
        }
        {
          refundableCredits ? (
            <Message type='success'>
              Remaining time on current plan has been discounted ({`${currencySymbol}${refundableCredits})`}
            </Message>
          ) : null
        }
      </div>
    )
  }

  isInTrialMode () {
    const { accountSubscription } = this.props

    const inTrial = get(accountSubscription, 'inTrial')

    return inTrial
  }

  renderStripeWrapper () {
    const {
      stripePublishableKey,
      currentUser,
      currency,
      productType,
      activePeriod,
      products,
      subscriptionType
    } = this.props

    const { priceLoaded, error, stripeLibLoaded } = this.state
    const price = get(this.state, `${activePeriod}lyPrice`, {}) || {}
    const isOfferAvailable = productType !== PRODUCT_TYPE.SUBSCRIPTION || price.available
    const subscriptionTotal = get(price, 'total')

    let isLoading = productType === PRODUCT_TYPE.SUBSCRIPTION ? !priceLoaded : false

    const totalAmount = getButtonPrice({ productType, activePeriod, products, currency, subscriptionTotal })

    return (
      <div className={cn('stripe-wrapper')}>
        {
          !stripeLibLoaded
            ? <LoadingIndicator/>
            : <StripeProvider apiKey={stripePublishableKey}>
              <Elements>
                <StripeForm
                  currentUser={currentUser}
                  inTrialMode={this.isInTrialMode()}
                  subscriptionType={subscriptionType}
                  currency={toLower(currency)}
                  totalAmount={totalAmount}
                  onSubmit={this.onSubmit}
                  error={error}
                  renderDiscountComponent={this.renderDiscountInput}
                  renderPromotionalCredit={this.renderPromotionalCreditNotice}
                  isLoading={isLoading}
                  isButtonDisabled={!isOfferAvailable}
                />
              </Elements>
            </StripeProvider>
        }
      </div>
    )
  }

  setInvalidDiscountState (error) {
    this.setState({
      discountMessage: error,
      discountMessageType: 'error',
      discountApplied: false,
      discountLoading: false,
      discountAppliedValue: null,
      discountAppliedType: null,
      discountAppliedAmount: null
    })
  }

  handleDiscountApply = async discountCode => {
    const { currency, activePeriod } = this.props

    try {
      this.setState({ discountLoading: true });
      const data = await this.refreshSubscriptionPayButtonPrice(discountCode)
      const planData = data[this.props.plan]
      const dataForPeriod = planData[activePeriod + 'ly']

      if (data.error) {
        return this.setInvalidDiscountState(data.error.message)
      }

      const {
        couponDiscountPercentage: discountAmount,
        couponDiscountAmount: discountValue,
        couponType: discountType
      } = dataForPeriod

      if (discountValue && discountValue > 0) {
        this.setState({
          discountApplied: true,
          discountMessage: prepareDiscountMessage({
            discountAmount,
            discountValue,
            discountType,
            currency
          }),
          discountMessageType: 'success',
          discountAppliedCode: discountCode,
          discountAppliedValue: discountValue,
          discountAppliedAmount: discountAmount,
          discountAppliedType: discountType,
        })
      } else {
        this.setInvalidDiscountState('The code is not valid on the selected plan or has expired. Please check and try again.')
      }
    } catch (e) {
      console.error(e)
      this.setInvalidDiscountState(
        get(
          e,
          'response.data.error',
          'Something went wrong. Please send email to support@soundation.com'
        )
      )
    } finally {
      this.setState({ discountLoading: false });
    }
  }

  handleDiscountCancel = async () => {
    this.setState({
      discountLoading: false,
      discountApplied: false,
      discountMessage: null,
      discountMessageType: null,
      discountAppliedCode: undefined,
      discountAppliedValue: null,
      discountAppliedType: null
    }, async () => {
      await this.refreshSubscriptionPayButtonPrice()
    })

  }

  handleDiscountCheckboxChange = discountCheckbox => {
    this.setState({ discountCheckbox })
  }

  handleDiscountInputChange = discountCode => {
    this.setState({ discountCode })
  }

  handleResponseError (error) {
    this.setState({ error })
  }

  handlePaypalError() {
    const { paypalPaymentError } = this.props
    if (!paypalPaymentError) {
      return null
    }

    return chargebeeMessages[paypalPaymentError].message || chargebeeMessages['default'].message
  }

  handleResponseSuccess () {
    setSignInRedirect(null)
    insertParamToLocation('success', 'true')
  }

  storeOrderCompleteEvent(response) {
    const { token, totalAmount, currency, email } = response
    const orderValue = Number.parseFloat((totalAmount||'').replace(` ${currency}`, ''))
    let orderCompleteData = {
      'event': 'order_complete',
      'order_id': token,
      'order_value': orderValue,
      'order_currency': currency,
      'enhanced_conversion_data': {
        'email': email
      }
    };
    let key = 'gtm-order-complete-data';
    sessionStorage.setItem(key, JSON.stringify(orderCompleteData));
  }

  render () {
    const { selectedPaymentTab, discountAppliedCode } = this.state

    const { currency, productType, activePeriod, products, currentUser, plan, referralId, subscriptionType } = this.props
    const price = get(this.state, `${activePeriod}lyPrice`, {}) || {}
    const isOfferAvailable = productType !== PRODUCT_TYPE.SUBSCRIPTION || price.available
    const subscriptionTotal = isOfferAvailable ? get(price, 'total') : 0.0

    return (
      <div className={cn('payment-options')}>
        <div className={cn('payment-tabs')}>
          {this.renderTabItem(TABS.STRIPE)}
          {this.renderTabItem(TABS.PAYPAL)}
        </div>
        <div className={cn('payment-options-content')}>
          <div className={cn('form-wrapper', { active: selectedPaymentTab === TABS.STRIPE })}>
            {this.renderStripeWrapper()}
          </div>
          <div className={cn('form-wrapper', { active: selectedPaymentTab === TABS.PAYPAL })}>
            <CheckoutPaypalContainer
              renderDiscountInput={this.renderDiscountInput}
              renderPromotionalCredit={this.renderPromotionalCreditNotice}
              currency={currency}
              productType={productType}
              activePeriod={activePeriod}
              referralId={referralId}
              products={products}
              currentUser={currentUser}
              inTrialMode={this.isInTrialMode()}
              subscriptionType={subscriptionType}
              plan={plan}
              paymentError={this.handlePaypalError()}
              subscriptionTotal={subscriptionTotal}
              discountAppliedCode={discountAppliedCode}
              isButtonDisabled={!isOfferAvailable}
            />
          </div>
        </div>
      </div>
    )
  }
}

CheckoutPaymentForms.propTypes = {
  productType: PropTypes.string,
  activePeriod: PropTypes.string,
  products: PropTypes.array,
  currency: PropTypes.string,
  currentUser: PropTypes.object,
  plan: PropTypes.string,
  currentPaymentMethod: PropTypes.string,
  stripePublishableKey: PropTypes.string,
  activeColumn: PropTypes.object,
  isUpgrade: PropTypes.bool,
  discountCode: PropTypes.string,
  referralId: PropTypes.string
}

CheckoutPaymentForms.defaultProps = {
  products: []
}

export default CheckoutPaymentForms;
