import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, clearCustomSuccessPaymentButton, get, clearLoginRedirectData } from '../../common/utils'
import { prepareTableConfig } from '../../components/price-plans/utils'
import { getPromoPeriod } from '@components/price-plans/utils'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import PricePlans from '@components/price-plans'
import { LINKS } from '@common/consts'
import MiniHeader from '@containers/mini-header'
import queryString from 'query-string'

const cn = new Bem({
  name: 'auth-plans-view',
  prefix: 'pfx-'
})

const priceShape = PropTypes.shape({
  price: PropTypes.string,
  currency: PropTypes.string
})

class AuthPlansView extends React.Component {
  constructor (props) {
    super(props)

    const { priceList, currentUser, queryParams, accountSubscription } = props
    const { customButton, referralId } = queryParams

    if (!customButton) {
      clearCustomSuccessPaymentButton()
      clearLoginRedirectData()
    }

    this.state = {
      pricingTable: prepareTableConfig({
        prices: priceList,
        user: currentUser,
        couponCode: queryParams.couponCode,
        accountSubscription: accountSubscription,
        referralId: referralId,
        closeAfterAuthentication: queryParams.closeAfterAuthentication
      })
    }
  }

  getLoginLink () {
    const baseLink = LINKS.AUTH_SIGN_IN
    if (this.props.queryParams.closeAfterAuthentication === 'true') {
      const { query, url } = queryString.parseUrl(baseLink)
      const newQuery = { ...query, closeAfterAuthentication: true }
      return `${url}?${queryString.stringify(newQuery)}`
    }

    return baseLink
  }

  render () {
    const { queryParams, priceList, theme, currentUser, accountSubscription, chargebeeDomain } = this.props
    const period = queryParams.couponCode ? getPromoPeriod(priceList) : undefined

    return (
      <div className={cn(null, queryParams.layout)}>
        <MiniHeader theme={theme} />
        <PricePlans
          className={cn('pricing')}
          items={this.state.pricingTable}
          title={queryParams.title}
          isUserAuthenticated={!!this.props.currentUser}
          currentUser={currentUser}
          accountSubscription={accountSubscription}
          chargebeeDomain={chargebeeDomain}
          queryParams={queryParams}
          selectedPeriod={period}
          theme={theme}
          loginLink={this.getLoginLink()}
        />
      </div>
    )
  }
}

AuthPlansView.propTypes = {
  currentUser: PropTypes.object,
  priceList: PropTypes.objectOf(priceShape).isRequired,
  queryParams: PropTypes.shape({
    layout: PropTypes.string,
    couponCode: PropTypes.string
  }),
  theme: PropTypes.oneOf(['light', 'dark'])
}
AuthPlansView.defaultProps = {
  theme: 'dark'
}

const mapStateToProps = state => {
  return {
    activeRecurringSubscription: get(state, 'backendData.currentUser.activeRecurringSubscription')
  }
}
export default createProvider(connect(mapStateToProps, null)(AuthPlansView))
