import PropTypes from "prop-types";

const dataLayer = PropTypes.shape({
  accountAssignedAt: PropTypes.string,
  accountType: PropTypes.number,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  currentPlan: PropTypes.string,
  email: PropTypes.string,
  facebookConnected: PropTypes.number,
  followersCount: PropTypes.number,
  followingCount: PropTypes.number,
  googleConnected: PropTypes.number,
  groupsCount: PropTypes.number,
  likesCount: PropTypes.number,
  mixdownsPrivateCount: PropTypes.number,
  mixdownsPublicCount: PropTypes.number,
  paymentProvider: PropTypes.string,
  signInCount: PropTypes.number,
  skoleTubeUser: PropTypes.number,
  songsCount: PropTypes.number,
  userId: PropTypes.number
})

const gtmSettings = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.shape({
    ENVIRONMENT: PropTypes.string,
    NUMBER_OF_DAYS_UPGRADE_POPUP: PropTypes.number,
    NUMBER_OF_LOGINS_UPGRADE_POPUP: PropTypes.number,
    NUMBER_OF_SONGS_UPGRADE_POPUP: PropTypes.number,
    shouldSendEvent: PropTypes.func,
  }),
]);

export const analytics = PropTypes.shape({
  dataLayer: dataLayer,
  gtmSettings: gtmSettings,
});
