// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "1260px";
var _3 = "1020px";
var _4 = "668px";
var _5 = "1550px";
var _6 = "pfx-CommunitySection-module__carousel--qGdfy";
var _7 = "1140px";
var _8 = "940px";
var _9 = "100%";
var _a = "15px";
var _b = "620px";
var _c = "#f53535";
var _d = "rgba(0,0,0,.2)";
var _e = "rgba(255,255,255,.38)";
var _f = ".38";
var _10 = "rgba(255,255,255,.2)";
var _11 = "#0025ff";
var _12 = "pfx-CommunitySection-module__glitch-anim--lLb8v";
var _13 = "pfx-CommunitySection-module__glitch-anim-2--ZLrNF";
var _14 = "#f60";
var _15 = "#41ead4";
var _16 = "#2cdd78";
var _17 = "#66ab23";
var _18 = "#f5f5f5";
var _19 = "#c3c3c3";
var _1a = "#ececec";
var _1b = "#252525";
var _1c = "#272727";
var _1d = "#c6c6c5";
var _1e = "#333";
var _1f = "#3c3c3c";
var _20 = "#9c9b9b";
var _21 = "#8c8c8c";
var _22 = "#6f6f6e";
var _23 = "#3c3c3b";
var _24 = "#373737";
var _25 = "#6a6a69";
var _26 = "#6f6f6f";
var _27 = "#9f9e9e";
var _28 = "#282828";
var _29 = "#828181";
var _2a = "#1d1d1d";
var _2b = "#979797";
var _2c = "#9c9b9b";
var _2d = "#a7a7a6";
var _2e = "#c6c6c5";
var _2f = "#d8d8d7";
var _30 = "#dddddc";
var _31 = "72px";
var _32 = "40px";
var _33 = "36px";
var _34 = "25px";
var _35 = "18px";
var _36 = "13px";
var _37 = "pfx-CommunitySection-module__hidden--VfzKX";
var _38 = "#0025ff";
var _39 = "pfx-CommunitySection-module__last-item--rbGsj";
var _3a = "rgba(255,255,255,.12)";
var _3b = "#6535f5";
var _3c = "pfx-CommunitySection-module__link--tX2yj";
var _3d = "2px";
var _3e = "56px";
var _3f = "56px";
var _40 = "#41ead4";
var _41 = "#f60";
var _42 = "#f83600";
var _43 = "#881f00";
var _44 = "18px";
var _45 = "15px";
var _46 = "red";
var _47 = "pfx-CommunitySection-module__section--ZI9Sj";
var _48 = "pfx-CommunitySection-module__section-header--bQr0h";
var _49 = "pfx-CommunitySection-module__section-header-container--zkRZH";
var _4a = "pfx-CommunitySection-module__show-all--UH46y";
var _4b = "13px";
var _4c = "rgba(255,255,255,.07)";
var _4d = "#35465c";
var _4e = "#55acee";
var _4f = "#fff";
var _50 = "#ffce36";
var _51 = "#ffcd00";
var _52 = "red";
export { _1 as "black", _2 as "breakLarge", _3 as "breakMedium", _4 as "breakSmall", _5 as "breakVLarge", _6 as "carousel", _7 as "containerLarge", _8 as "containerMedium", _9 as "containerMobile", _a as "containerPaddingMobile", _b as "containerSmall", _c as "danger", _d as "darkElement", _e as "disabled", _f as "disabledOpacity", _10 as "extraLightElement", _11 as "facebook", _12 as "glitchAnim", _13 as "glitchAnim2", _14 as "google", _15 as "green", _16 as "green2", _17 as "greenColor", _18 as "grey1", _19 as "grey10", _1a as "grey2", _1b as "grey25", _1c as "grey27", _1d as "grey3", _1e as "grey33", _1f as "grey3C", _20 as "grey4", _21 as "grey41", _22 as "grey5", _23 as "grey6", _24 as "grey61", _25 as "grey6A", _26 as "grey6F", _27 as "grey7", _28 as "grey8", _29 as "grey82", _2a as "grey9", _2b as "grey97", _2c as "grey9C", _2d as "greyA7", _2e as "greyC6", _2f as "greyD8", _30 as "greyDd", _31 as "header1", _32 as "header2", _33 as "header3", _34 as "header4", _35 as "header5", _36 as "header6", _37 as "hidden", _38 as "klein", _39 as "lastItem", _3a as "lightElement", _3b as "lilac", _3c as "link", _3d as "menuBorder", _3e as "menuHeight", _3f as "menuMobileHeight", _40 as "ocean", _41 as "orange", _42 as "orange2", _43 as "orangeDark", _44 as "paragraph", _45 as "paragraphSmall", _46 as "red", _47 as "section", _48 as "sectionHeader", _49 as "sectionHeaderContainer", _4a as "showAll", _4b as "subHeaderSmall", _4c as "subtleLightElement", _4d as "tumblr", _4e as "twitter", _4f as "white", _50 as "yellow", _51 as "yellowDeep", _52 as "youtube" }
