export default class EventListener {
  listeners = {}

  on (event, listener) {
    const eventListeners = this.listeners[event]
    if (!eventListeners) {
      this.listeners[event] = [listener]
    } else {
      eventListeners.push(listener)
    }

    return this
  }

  off (event, listener) {
    const eventListeners = this.listeners[event]
    if (eventListeners) {
      const index = eventListeners.indexOf(listener)
      eventListeners.splice(index, 1)
    }
    return this
  }

  emit (event, data) {
    const eventListeners = this.listeners[event]
    if (eventListeners) {
      eventListeners.forEach((listener) => listener(data))
    }

    return this
  }
}
