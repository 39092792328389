import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import "./Badge.style.scss";

const cn = new Bem({
  name: "badge",
  prefix: "pfx-",
});

const Badge = ({ children, className, variant = "normal", theme = 'classic', size = 'medium' }) => {
  return <div className={cn(undefined, [variant, theme, size], className)}>{children}</div>
}

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  theme: PropTypes.oneOf(["classic", "clean"]),
  size: PropTypes.oneOf(["small", "medium"]),
  variant: PropTypes.oneOf(["normal", "highlight", "danger"])
}

export default Badge
