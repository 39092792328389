function toFraction(x, tolerance) {
  if (x == 0) return [0, 1];
  if (x < 0) x = -x;
  if (!tolerance) tolerance = 0.001;
  var num = 1,
    den = 1;

  function iterate() {
    var R = num / den;
    if (Math.abs((R - x) / x) < tolerance) return;

    if (R < x) num++;
    else den++;
    iterate();
  }

  iterate();
  return [num, den];
}

export class AspectRatio {
  static fromDimensions({ width, height }) {
    return new AspectRatio(width / height);
  }

  constructor(value) {
    this.value = value;
  }
  toPaddingTop() {
    return (100 / this.value).toFixed(2) + "%";
  }
  toCssVariable() {
    const [num, den] = toFraction(this.value);

    return `${num} / ${den}`;
  }
  toArray() {
    const [num, den] = toFraction(this.value);

    return [num, den];
  }
  toCssVariables() {
    const [num, den] = toFraction(this.value);

    return {
      "--ar-numerator": num || 1,
      "--ar-denominator": den || 1,
    };
  }
}

export class ImageDimensions {
  constructor({ width, height }) {
    this.width = width;
    this.height = height;
  }

  getAspectRatio() {
    return AspectRatio.fromDimensions(this);
  }
}
