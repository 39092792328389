import React from "react";
import "./style.scss";
import PropTypes from "prop-types";
import { Bem, Enum } from "@common/utils";
import Icon from "../icon";
export const TYPE = new Enum("ERROR", "SUCCESS", "WARNING");

const cn = new Bem({
  name: "message",
  prefix: "pfx-",
});

const Icons = {
  [TYPE.ERROR]: "warning",
  [TYPE.WARNING]: "round-warning",
  [TYPE.SUCCESS]: "success-validation",
};

class Message extends React.Component {
  render() {
    const { children, type, className, isVertical } = this.props;
    return (
      <div
        className={cn(null, { [type]: true, vertical: isVertical }, className)}
      >
        <Icon
          className={cn("icon", { vertical: isVertical })}
          size={28}
          icon={Icons[type]}
        />
        {children}
      </div>
    );
  }
}

Message.propTypes = {
  type: PropTypes.oneOf([TYPE.ERROR, TYPE.SUCCESS, TYPE.WARNING]),
  className: PropTypes.string,
  isVertical: PropTypes.bool,
};

Message.defaultProps = {
  type: TYPE.SUCCESS,
  isVertical: false,
};

export default Message;
