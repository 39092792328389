// extracted by mini-css-extract-plugin
var _1 = "pfx-CommunityHeader-module__activity-indicator--c17zV";
var _2 = "pfx-CommunityHeader-module__avatar--b3MU4";
var _3 = "pfx-CommunityHeader-module__avatar-menu--f_ijl";
var _4 = "#000";
var _5 = "1260px";
var _6 = "1020px";
var _7 = "668px";
var _8 = "1550px";
var _9 = "pfx-CommunityHeader-module__container--q4mzn";
var _a = "1140px";
var _b = "940px";
var _c = "100%";
var _d = "15px";
var _e = "620px";
var _f = "#f53535";
var _10 = "rgba(0,0,0,.2)";
var _11 = "rgba(255,255,255,.38)";
var _12 = ".38";
var _13 = "rgba(255,255,255,.2)";
var _14 = "#0025ff";
var _15 = "pfx-CommunityHeader-module__glitch-anim--WFqqZ";
var _16 = "pfx-CommunityHeader-module__glitch-anim-2--lEYWM";
var _17 = "#f60";
var _18 = "#41ead4";
var _19 = "#2cdd78";
var _1a = "#66ab23";
var _1b = "#f5f5f5";
var _1c = "#c3c3c3";
var _1d = "#ececec";
var _1e = "#252525";
var _1f = "#272727";
var _20 = "#c6c6c5";
var _21 = "#333";
var _22 = "#3c3c3c";
var _23 = "#9c9b9b";
var _24 = "#8c8c8c";
var _25 = "#6f6f6e";
var _26 = "#3c3c3b";
var _27 = "#373737";
var _28 = "#6a6a69";
var _29 = "#6f6f6f";
var _2a = "#9f9e9e";
var _2b = "#282828";
var _2c = "#828181";
var _2d = "#1d1d1d";
var _2e = "#979797";
var _2f = "#9c9b9b";
var _30 = "#a7a7a6";
var _31 = "#c6c6c5";
var _32 = "#d8d8d7";
var _33 = "#dddddc";
var _34 = "pfx-CommunityHeader-module__guest-button--GFj_N";
var _35 = "pfx-CommunityHeader-module__guest-buttons--zmRaz";
var _36 = "pfx-CommunityHeader-module__hamburger-menu--r8G0X";
var _37 = "72px";
var _38 = "40px";
var _39 = "36px";
var _3a = "25px";
var _3b = "18px";
var _3c = "13px";
var _3d = "pfx-CommunityHeader-module__home-link--KyI2k";
var _3e = "pfx-CommunityHeader-module__inner-container--I5h7B";
var _3f = "#0025ff";
var _40 = "pfx-CommunityHeader-module__left-section--BzDu0";
var _41 = "rgba(255,255,255,.12)";
var _42 = "#6535f5";
var _43 = "pfx-CommunityHeader-module__logo--Vy03w";
var _44 = "2px";
var _45 = "56px";
var _46 = "56px";
var _47 = "pfx-CommunityHeader-module__messages-icon--ISz_O";
var _48 = "pfx-CommunityHeader-module__mobile-search-icon--HzQmR";
var _49 = "pfx-CommunityHeader-module__notifications-icon--PM5_n";
var _4a = "#41ead4";
var _4b = "#f60";
var _4c = "#f83600";
var _4d = "#881f00";
var _4e = "18px";
var _4f = "15px";
var _50 = "red";
var _51 = "pfx-CommunityHeader-module__right-section--dVd26";
var _52 = "pfx-CommunityHeader-module__right-section-auth--QlQIg";
var _53 = "pfx-CommunityHeader-module__search--pNEU3";
var _54 = "pfx-CommunityHeader-module__studio-btn--E6DSf";
var _55 = "13px";
var _56 = "rgba(255,255,255,.07)";
var _57 = "pfx-CommunityHeader-module__toggler-container--AzTox";
var _58 = "#35465c";
var _59 = "#55acee";
var _5a = "#fff";
var _5b = "#ffce36";
var _5c = "#ffcd00";
var _5d = "red";
export { _1 as "activityIndicator", _2 as "avatar", _3 as "avatarMenu", _4 as "black", _5 as "breakLarge", _6 as "breakMedium", _7 as "breakSmall", _8 as "breakVLarge", _9 as "container", _a as "containerLarge", _b as "containerMedium", _c as "containerMobile", _d as "containerPaddingMobile", _e as "containerSmall", _f as "danger", _10 as "darkElement", _11 as "disabled", _12 as "disabledOpacity", _13 as "extraLightElement", _14 as "facebook", _15 as "glitchAnim", _16 as "glitchAnim2", _17 as "google", _18 as "green", _19 as "green2", _1a as "greenColor", _1b as "grey1", _1c as "grey10", _1d as "grey2", _1e as "grey25", _1f as "grey27", _20 as "grey3", _21 as "grey33", _22 as "grey3C", _23 as "grey4", _24 as "grey41", _25 as "grey5", _26 as "grey6", _27 as "grey61", _28 as "grey6A", _29 as "grey6F", _2a as "grey7", _2b as "grey8", _2c as "grey82", _2d as "grey9", _2e as "grey97", _2f as "grey9C", _30 as "greyA7", _31 as "greyC6", _32 as "greyD8", _33 as "greyDd", _34 as "guestButton", _35 as "guestButtons", _36 as "hamburgerMenu", _37 as "header1", _38 as "header2", _39 as "header3", _3a as "header4", _3b as "header5", _3c as "header6", _3d as "homeLink", _3e as "innerContainer", _3f as "klein", _40 as "leftSection", _41 as "lightElement", _42 as "lilac", _43 as "logo", _44 as "menuBorder", _45 as "menuHeight", _46 as "menuMobileHeight", _47 as "messagesIcon", _48 as "mobileSearchIcon", _49 as "notificationsIcon", _4a as "ocean", _4b as "orange", _4c as "orange2", _4d as "orangeDark", _4e as "paragraph", _4f as "paragraphSmall", _50 as "red", _51 as "rightSection", _52 as "rightSectionAuth", _53 as "search", _54 as "studioBtn", _55 as "subHeaderSmall", _56 as "subtleLightElement", _57 as "togglerContainer", _58 as "tumblr", _59 as "twitter", _5a as "white", _5b as "yellow", _5c as "yellowDeep", _5d as "youtube" }
