import { actionTypes } from './types/player'

export const play = songData => {
  return { type: actionTypes.PLAY, payload: songData }
}

export const stop = _ => {
  return { type: actionTypes.STOP, payload: null }
}

export const pause = _ => {
  return { type: actionTypes.PAUSE, payload: null }
}

export const progress = (progressData) => {
  return { type: actionTypes.PROGRESS, payload: progressData }
}

export const fetching = (progress) => {
  return { type: actionTypes.FETCHING, payload: progress }
}

export const progressChange = (currentTime) => {
  return { type: actionTypes.PROGRESS_CHANGE, payload: currentTime }
}

export const likeRequest = () => {
  return { type: actionTypes.LIKE_REQUEST }
}

export const dislikeRequest = () => {
  return { type: actionTypes.DISLIKE_REQUEST }
}

export const likeConfirmed  = (trackId) => {
  return { type: actionTypes.LIKE_CONFIRMED, payload: trackId }
}

export const dislikeConfirmed  = (trackId) => {
  return { type: actionTypes.DISLIKE_CONFIRMED, payload: trackId }
}
