import { actionTypes } from './types/app'

export const updateUpgradeWindowLastShownDate = newDate => dispatch => {
  dispatch({ type: actionTypes.UPDATE_UPGRADE_WINDOW_LAST_SHOWN_DATE, payload: newDate })
}

export const setWindowSize = (width, height) => dispatch => {
  dispatch({ type: actionTypes.SET_WINDOW_SIZE, payload: { width, height} })
}

export const setWindowScroll = windowScroll => dispatch => {
  dispatch({ type: actionTypes.SET_WINDOW_SCROLL, payload: windowScroll })
}

export const setSignInRedirect = url => dispatch => {
  dispatch({ type: actionTypes.SET_SIGN_IN_REDIRECT, payload: url })
}

export const openMobileLimitationSidebar = (componentName) => dispatch => {
  dispatch({ type: actionTypes.OPEN_MOBILE_LIMITATION_SIDEBAR, payload: componentName })
}

export const closeMobileLimitationSidebar = () => dispatch => {
  dispatch({ type: actionTypes.CLOSE_MOBILE_LIMITATION_SIDEBAR })
}

export const setMobileDevice = (isMobile) => dispatch => {
  dispatch({ type: actionTypes.SET_MOBILE_DEVICE, payload: isMobile })
}

export const startDragging = _ => {
  return { type: actionTypes.START_DRAGGING }
}

export const endDragging = _ => {
  return { type: actionTypes.END_DRAGGING }
}

export const openMobileMenu = () => ({ type: actionTypes.OPEN_MOBILE_MENU })

export const closeMobileMenu = () => ({ type: actionTypes.CLOSE_MOBILE_MENU })

export const toggleMobileMenu = () => ({ type: actionTypes.TOGGLE_MOBILE_MENU })

export const dissmissHalloweekBanner = () => ({ type: actionTypes.DISSMISS_HALLOWEEK_BANNER })

export const setHighDevicePixelRatio = (value) => ({ type: actionTypes.SET_HIGH_DEVICE_PIXEL_RATIO, payload: { value } })
