import { useCallback, useState } from 'react'

// used to track open/closed state and toggle between states
export default function useOpen () {
  const [isOpen, setIsOpen] = useState(false)
  const open = useCallback(() => setIsOpen(true), [])
  const close = useCallback(() => setIsOpen(false), [])
  const toggle = useCallback(() => setIsOpen((cur) => !cur), [])
  return { isOpen, open, close, toggle, setIsOpen }
}
