import React, { useCallback, useState } from 'react'
import { COMMUNITY_BREAKPOINT } from '@common/consts'
import Icon from '@components/icon'
import InlinePlayer from '@components/inline-player/overlay'
import DottedMenu from '@components/dotted-menu'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import createProvider from '@store/provider'
import { connect, useSelector } from 'react-redux'
import * as styles from './CommunityCard.module.scss'

const CommunityCard = React.forwardRef((props, ref) => {
  const { isSubPage = false, picture, audioFile, link, title, subtitle, subtitleUrl, subtitleLines, stats = [], showEmptyStats = true, actions = [], contextMenuItems = [], className = '' } = props

  const windowSize = useSelector((state) => state.app.windowSize)

  const [isHovering, setIsHovering] = useState(false)
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false)

  const isImg4x3 = picture.aspectRatio === '4x3'
  const isLandscapeMode = picture.aspectRatio !== '4x3' && isSubPage

  const Wrapper = link ? 'a' : 'div'
  const SubtitleWrapper = subtitleUrl ? 'a' : 'p'

  const imgClassNames = `${styles.cover} ${isImg4x3 && styles.fourByThreeCover} ${isLandscapeMode && styles.coverLandscape} ${isSubPage && styles.coverSubPage} ${picture.className || ''}`
  const preventNavigation = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])
  const handleMouseEnter = useCallback(() => setIsHovering(true), [])
  const handleMouseLeave = useCallback(() => setIsHovering(false), [])

  return (
    <Wrapper
      ref={ref}
      className={`${styles.container} ${isSubPage && styles.subPage} ${isLandscapeMode && styles.landscapeContainer} ${className}`}
      href={link}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {audioFile ? (
        <div className={imgClassNames}>
          <InlinePlayer
            {...audioFile}
            visible={windowSize < COMMUNITY_BREAKPOINT || isHovering ? 'always' : 'hover'}
            size='big'
            image={{ src: picture.src, alt: picture.alt, fallbackSrc: picture.fallbackSrc, className: styles.playerImg }}
          />
        </div>
      ) : (
        <object className={imgClassNames} data={picture.src}>
          <img className={imgClassNames} src={picture.fallbackSrc} alt={picture.alt} />
        </object>
      )}

      <div className={`${styles.info} ${!!isLandscapeMode && styles.infoLandscape}`}>
        <p className={`${styles.title} ${!!isLandscapeMode && styles.titleLandscape}`}>{title}</p>
        {(subtitle || !isNaN(subtitleLines)) && <SubtitleWrapper style={{ '--subtitle-lines': subtitleLines }} className={`${styles.subtitle} ${!isNaN(subtitleLines) && styles.fixedSubtitleHeight}`} href={subtitleUrl}>{subtitle}</SubtitleWrapper>}

        {(stats.length || !!showEmptyStats) && (
          <div className={`${styles.stats} ${!!isLandscapeMode && styles.statsLandscape}`}>
            {stats.map(({ count, icon, className = '' }) => <div key={icon} className={`${styles.stat} ${className}`}><Icon icon={icon} size={16} /><span>{count}</span></div>)}
          </div>
        )}

        <div className={`${styles.actionsRow} ${(!!actions?.length || !!contextMenuItems?.length) && styles.actionsRowMinHeight}`}>
          <div className={styles.actions}>
            {actions.map((action) => (
              <div key={action.label} className={`${styles.action} ${!!action.disabled && styles.disabledAction}`} onClick={(e) => {
                action.onClick()
                preventNavigation(e)
              }}>
                <Icon className={styles.actionIcon} icon={action.icon} />
                {action.label}
              </div>
            ))}
          </div>

          {!!contextMenuItems.length && (
            <DropdownOrBottomSheet
              dropdownClassName={styles.dropdown}
              isOpen={isContextMenuOpen}
              toggler={<DottedMenu onClick={preventNavigation} />}
              onToggle={setIsContextMenuOpen}
              dropdownProps={{ portal: true }} // to prevent hover over dropdown to hover over card
            >
              {contextMenuItems.map(({ label, icon, href, onClick, closeOnClick = false, className = '', ...rest }) => (
                <a
                  key={label}
                  className={`${styles.contextMenuItem} ${className}`}
                  href={href}
                  onClick={(e) => {
                    if (onClick) onClick()

                    if (!href) preventNavigation(e)
                    if (closeOnClick) setIsContextMenuOpen(false)
                  }}
                  {...rest}
                >
                  <Icon icon={icon} size={20} />
                  <span>{label}</span>
                </a>
              ))}
            </DropdownOrBottomSheet>
          )}
        </div>
      </div>
    </Wrapper>
  )
})

CommunityCard.displayName = 'CommunityCard'
export default createProvider(connect(null)(CommunityCard))
