import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'

const cn = new Bem({
  name: 'circle',
  prefix: 'pfx-'
})

class Circle extends React.Component {
  render () {
    const styles = {
      backgroundImage: `url(${this.props.url})`
    }

    return (
      <div className={cn(null, null, this.props.className)} style={styles} />
    )
  }
}

Circle.propTypes = {
  url: PropTypes.string.isRequired,
  className: PropTypes.string
}

Circle.defaultProps = {}

export default Circle
