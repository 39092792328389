import { Bem } from '@common/utils'

const cn = new Bem({
  name: 'subscription-page',
  prefix: 'pfx-'
})

export const rootCn = cn('')
export const titleCn = cn('title')
export const paragraphCn = cn('paragraph')
export const buttonCn = cn('button')
export const linkCn = cn('link')
