'use client'
import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { noop, uniqueId } from 'lodash'
import { Bem, canUseDOM, Enum } from '../../common/utils'
import Icon from '../icon/index'

const cn = new Bem({
  name: 'text-field',
  prefix: 'pfx-'
})

export const TEXT_FIELD_TYPES = new Enum('TEXT', 'PASSWORD', 'NUMBER')
export const LAYOUT = new Enum('DEFAULT', 'BLANK', 'DARK')

class TextFieldControlled_ extends React.Component {
  constructor (props) {
    const { value, type } = props
    super(props)

    this.state = {
      hasValue: !!value,
      showPassword: false,
      initialType: type,
      type
    }

    this._bind('_onBlur', '_onChange', 'toggleShowPassword')
  }

  componentDidMount () {
    this._id = uniqueId()
    if (canUseDOM()) {
      // Chrome can see value of password input when is field by autocomplete mechanism
      // https://stackoverflow.com/questions/35049555/chrome-autofill-autocomplete-no-value-for-password
      setTimeout(() => {
        if (this.elRef?.querySelector('input:-webkit-autofill')) {
          this.setState({ hasValue: true })
        }
      }, 1000)
    }
  }

  _bind (...methods) {
    methods.map((method) => {
      this[method] = this[method].bind(this)
    })
  }

  _onBlur (event) {
    const { onBlur } = this.props
    this.setState({ hasValue: Boolean(event.currentTarget.value) })
    if (onBlur) onBlur(event)
  }

  _onChange (event) {
    const value = event.currentTarget.value

    this.setState({
      hasValue: Boolean(value)
    })

    this.props.onChange(value)
  }

  shouldShowEyeIcon () {
    return this.props.type === TEXT_FIELD_TYPES.PASSWORD
  }

  toggleShowPassword () {
    this.setState({
      showPassword: !this.state.showPassword,
      type: this.state.showPassword ? this.state.initialType : TEXT_FIELD_TYPES.TEXT
    })
  }

  render () {
    const { isDisabled, name, label, hasError = false, placeholder, layout, isTransparent, isLaidBack, enterKeyHint, value, onFocus, onMouseDown, onKeyDown, className = '', inputClassName = '', autoFocus } = this.props
    const { hasValue, type, showPassword } = this.state

    return (
      <div
        ref={(elRef) => this.elRef = elRef}
        className={cn(null, { password: this.shouldShowEyeIcon(), [layout]: true, transparent: isTransparent, error: hasError }, className)}>
        <input
          id={this._id}
          ref={this.props.forwardedRef}
          className={cn('input', { 'has-value': hasValue }, inputClassName)}
          name={name}
          value={value}
          onBlur={this._onBlur}
          onChange={this._onChange}
          onFocus={onFocus}
          onMouseDown={onMouseDown}
          onKeyDown={onKeyDown}
          disabled={isDisabled}
          type={type}
          placeholder={placeholder}
          enterKeyHint={enterKeyHint}
          autoFocus={autoFocus}
        />
        { label && <label className={cn('label')} htmlFor={this._id}>{label}</label> }
        {!isLaidBack && <span className={cn('input-bar')} />}
        <Icon
          icon='visible'
          size={25}
          onClick={this.toggleShowPassword}
          className={cn('eye-icon', { orange: showPassword })} />
      </div>
    )
  }
}
export const TextFieldControlled = React.forwardRef((props, ref) => <TextFieldControlled_ {...props} forwardedRef={ref} />)

class TextField extends React.Component {
  constructor (props) {
    const { value } = props
    super(props)

    this.state = {
      value
    }
  }

  onChange = (value) => {
    this.setState({
      value: value
    })

    this.props.onChange(value)
  }

  render () {
    const { value } = this.state

    return (
      <TextFieldControlled ref={this.props.forwardedRef} {...this.props} onChange={this.onChange} value={value} />
    )
  }
}

export default React.forwardRef((props, ref) => <TextField {...props} forwardedRef={ref} />)

TextField.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf([TEXT_FIELD_TYPES.TEXT, TEXT_FIELD_TYPES.NUMBER, TEXT_FIELD_TYPES.PASSWORD]),
  isDisabled: PropTypes.bool,
  isTransparent: PropTypes.bool,
  /** Doesn't show a border on focus */
  isLaidBack: PropTypes.bool,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  layout: PropTypes.oneOf(LAYOUT.getAll()),
  placeholder: PropTypes.string
}

TextField.defaultProps = {
  type: 'text',
  isDisabled: false,
  isTransparent: false,
  isLaidBack: false,
  onChange: noop,
  onBlur: noop,
  onFocus: noop,
  layout: LAYOUT.DEFAULT
}
