import { useEffect } from 'react'
import { isElement } from 'lodash'

export default function useIntersection ({ callback, elementRef }) {
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.9
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (callback) callback()
                }
            })
        }, options)

        if (elementRef.current && isElement(elementRef.current)) observer.observe(elementRef.current)

        return () => {
            observer.disconnect()
        }
    }, [callback, elementRef])
}
