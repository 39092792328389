import React from 'react'
import * as styles from './DottedMenu.module.scss'

const DottedMenu = React.forwardRef((props, ref) => {
  const { type = 'meatball', onClick, onMouseDown, className = '' } = props

  return (
    <div ref={ref} className={`${styles.container} ${styles[type]} ${className}`} onClick={onClick} onMouseDown={onMouseDown}>
      <div className={styles.dot} />
      <div className={styles.dot} />
      <div className={styles.dot} />
    </div>
  )
})

export default DottedMenu
