import { useCallback, useEffect, useState } from 'react'
import { COMMUNITY_CAROUSEL_ITEMS, PROJECT_SORT_BY } from '@common/consts'
import { denormalizeResponse, getPieces, getProject } from '@common/http'
import usePaginate from '@hooks/usePaginate'
import usePrevious from '@hooks/usePrevious'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import { setFlashMessageByValues as setFlashMessageByValuesAction } from '@store/actions/flashMessage'
import { connect, useSelector } from 'react-redux'
import CommunitySectionPlaceholder from '@containers/community/community-section-placeholder'
import CommunityProjectCard from '../community-project-card'
import CommunityCreateProjectButton from '../community-create-project-button'
import CommunitySubPage from '../community-sub-page'
import * as styles from './CommunityProjects.module.scss'

const CommunityProjects = (props) => {
  const { isMyProjectsPage, isProjectsPage, setFlashMessageByValues } = props

  const currentUser = useSelector(selectCurrentUser)
  const isStudioSupportedDevice = useSelector((state) => !state.app.mobileDevice)

  const [sort, setSort] = useState(PROJECT_SORT_BY.updatedAt.value)

  const fetchUserProjects = useCallback(async ({ page }) => {
    return getPieces({ items: isProjectsPage ? COMMUNITY_CAROUSEL_ITEMS : 20, page, sort })
  }, [isProjectsPage, sort])
  const { isLoading: isLoadingUserProjects, data: myProjectsData, fetchNextPage: fetchMyProjectsPage, nextPage: myProjectsNextPage, reset: resetMyProjects } = usePaginate({ fetcher: fetchUserProjects })
  const [myProjects, setMyProjects] = useState([])

  // fetch 1st page on startup, and after reset
  useEffect(() => {
    if (currentUser && myProjectsNextPage === 1) fetchMyProjectsPage()
  }, [currentUser, fetchMyProjectsPage, myProjectsNextPage])
  // Reset when the sort changes
  const previousSort = usePrevious(sort)
  useEffect(() => {
    if (previousSort !== sort) resetMyProjects()
  }, [previousSort, sort, resetMyProjects])

  // update state when data is fetched
  useEffect(() => {
    if (myProjectsData) setMyProjects(denormalizeResponse(myProjectsData))
  }, [myProjectsData])

  const reloadProject = useCallback((id) => {
    getProject(id).then((res) => {
      const project = denormalizeResponse(res.data)
      setMyProjects((prevProjects) => prevProjects.map((p) => p.id === id ? { ...project, project: { id, type: 'project' }, pieceType: 'Project' } : p))
    })
  }, [])

  const handleSort = useCallback((newSort) => setSort(newSort), [])

  let projects, isLoadingSubPage, fetchNextSubPage, title
  if (isMyProjectsPage) {
    title = 'My projects'
    projects = myProjects
    isLoadingSubPage = isLoadingUserProjects
    fetchNextSubPage = fetchMyProjectsPage
  }

  if (!isStudioSupportedDevice) {
    return <CommunitySectionPlaceholder icon='round-warning' message="Projects can't be created on mobile. Head over to your desktop computer and get creative." />
  }

  return (
    <div>
      <CommunitySubPage
        title={title}
        subHeader={<CommunityCreateProjectButton className={styles.createProjectBtn} />}
        fetchNextPage={fetchNextSubPage}
        isLoading={isLoadingSubPage}
        sortValue={sort}
        sortOptions={Object.values(PROJECT_SORT_BY)}
        onSort={handleSort}
      >
        {projects.map((piece) => <CommunityProjectCard key={piece.id} piece={piece} isSubPage reloadProject={reloadProject} reloadAll={resetMyProjects} onError={setFlashMessageByValues} currentUser={currentUser} />)}
      </CommunitySubPage>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    currentUser: selectCurrentUser(state)
  }
}
const mapDispatchToProps = {
  setFlashMessageByValues: setFlashMessageByValuesAction
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(CommunityProjects), false)
