import { Bem } from "@common/utils";
import React from "react";
import "./single-view-placeholder.scss";
import { withBindHeightToWidth } from "./with-bind-height-to-width";

const cn = new Bem({
  name: "carousel-view-placeholder",
  prefix: "pfx-",
});

export class Placeholder extends React.Component {
  render() {
    const { dispatch, ...passThroughProps } = this.props;
    return (
      <div
        className={cn("square", null, this.props.className)}
        {...passThroughProps}
      />
    );
  }
}

export const PlaceholderResponsive = withBindHeightToWidth(Placeholder);

export default class SingleViewPlaceholder extends React.Component {
  render() {
    return (
      <div className={cn()}>
        <PlaceholderResponsive />
        <div className={cn("title-placeholder")}></div>
      </div>
    );
  }
}
