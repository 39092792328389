import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '../../common/utils'

const cn = new Bem({
  name: 'vertical-title',
  prefix: 'pfx-'
})

const ALIGN_OPTIONS = new Enum('LEFT', 'RIGHT')

class VerticalTitle extends React.Component {
  render () {
    return (
      <div className={cn(null, this.props.align)}>
        <div className={cn('line')} />
        <div className={cn('text')}>
          { this.props.children }
        </div>
      </div>
    )
  }
}

VerticalTitle.propTypes = {
  align: PropTypes.oneOf([
    ALIGN_OPTIONS.LEFT,
    ALIGN_OPTIONS.RIGHT
  ])
}

VerticalTitle.defaultProps = {
  align: ALIGN_OPTIONS.LEFT
}

export default VerticalTitle
