import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import Icon from '@components/icon'
import "./Crumb.style.scss";

const cn = new Bem({
  name: "bread-crumb",
  prefix: "pfx-",
});

export const Crumb = ({ path, title, isLoggedIn }) => {
  return (
    <li className={cn()}>
      <a data-turbolinks={!(isLoggedIn && path === '/')} className={cn("link")} href={path}>
        {title}
      </a>
      <Icon type="blank" icon="next" size={12} className={cn("icon")} />
    </li>
  );
};

Crumb.propTypes = {
  path: PropTypes.string.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};
