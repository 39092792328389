// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "1260px";
var _3 = "1020px";
var _4 = "668px";
var _5 = "1550px";
var _6 = "1140px";
var _7 = "940px";
var _8 = "100%";
var _9 = "15px";
var _a = "620px";
var _b = "#f53535";
var _c = "rgba(0,0,0,.2)";
var _d = "rgba(255,255,255,.38)";
var _e = ".38";
var _f = "rgba(255,255,255,.2)";
var _10 = "#0025ff";
var _11 = "pfx-CommunityTracksSection-module__first-section--wHGbM";
var _12 = "pfx-CommunityTracksSection-module__glitch-anim--TH4Ui";
var _13 = "pfx-CommunityTracksSection-module__glitch-anim-2--DaNkF";
var _14 = "#f60";
var _15 = "#41ead4";
var _16 = "#2cdd78";
var _17 = "#66ab23";
var _18 = "#f5f5f5";
var _19 = "#c3c3c3";
var _1a = "#ececec";
var _1b = "#252525";
var _1c = "#272727";
var _1d = "#c6c6c5";
var _1e = "#333";
var _1f = "#3c3c3c";
var _20 = "#9c9b9b";
var _21 = "#8c8c8c";
var _22 = "#6f6f6e";
var _23 = "#3c3c3b";
var _24 = "#373737";
var _25 = "#6a6a69";
var _26 = "#6f6f6f";
var _27 = "#9f9e9e";
var _28 = "#282828";
var _29 = "#828181";
var _2a = "#1d1d1d";
var _2b = "#979797";
var _2c = "#9c9b9b";
var _2d = "#a7a7a6";
var _2e = "#c6c6c5";
var _2f = "#d8d8d7";
var _30 = "#dddddc";
var _31 = "72px";
var _32 = "40px";
var _33 = "36px";
var _34 = "25px";
var _35 = "18px";
var _36 = "13px";
var _37 = "#0025ff";
var _38 = "rgba(255,255,255,.12)";
var _39 = "#6535f5";
var _3a = "2px";
var _3b = "56px";
var _3c = "56px";
var _3d = "#41ead4";
var _3e = "#f60";
var _3f = "#f83600";
var _40 = "#881f00";
var _41 = "18px";
var _42 = "15px";
var _43 = "red";
var _44 = "pfx-CommunityTracksSection-module__section--WlWd8";
var _45 = "pfx-CommunityTracksSection-module__sharing-modal--VEuvd";
var _46 = "13px";
var _47 = "rgba(255,255,255,.07)";
var _48 = "#35465c";
var _49 = "#55acee";
var _4a = "#fff";
var _4b = "#ffce36";
var _4c = "#ffcd00";
var _4d = "red";
export { _1 as "black", _2 as "breakLarge", _3 as "breakMedium", _4 as "breakSmall", _5 as "breakVLarge", _6 as "containerLarge", _7 as "containerMedium", _8 as "containerMobile", _9 as "containerPaddingMobile", _a as "containerSmall", _b as "danger", _c as "darkElement", _d as "disabled", _e as "disabledOpacity", _f as "extraLightElement", _10 as "facebook", _11 as "firstSection", _12 as "glitchAnim", _13 as "glitchAnim2", _14 as "google", _15 as "green", _16 as "green2", _17 as "greenColor", _18 as "grey1", _19 as "grey10", _1a as "grey2", _1b as "grey25", _1c as "grey27", _1d as "grey3", _1e as "grey33", _1f as "grey3C", _20 as "grey4", _21 as "grey41", _22 as "grey5", _23 as "grey6", _24 as "grey61", _25 as "grey6A", _26 as "grey6F", _27 as "grey7", _28 as "grey8", _29 as "grey82", _2a as "grey9", _2b as "grey97", _2c as "grey9C", _2d as "greyA7", _2e as "greyC6", _2f as "greyD8", _30 as "greyDd", _31 as "header1", _32 as "header2", _33 as "header3", _34 as "header4", _35 as "header5", _36 as "header6", _37 as "klein", _38 as "lightElement", _39 as "lilac", _3a as "menuBorder", _3b as "menuHeight", _3c as "menuMobileHeight", _3d as "ocean", _3e as "orange", _3f as "orange2", _40 as "orangeDark", _41 as "paragraph", _42 as "paragraphSmall", _43 as "red", _44 as "section", _45 as "sharingModal", _46 as "subHeaderSmall", _47 as "subtleLightElement", _48 as "tumblr", _49 as "twitter", _4a as "white", _4b as "yellow", _4c as "yellowDeep", _4d as "youtube" }
