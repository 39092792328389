'use client'
import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import { Bem, Enum } from '@common/utils'
import { debounce } from 'lodash'

const cn = new Bem({
  name: 'button',
  prefix: 'pfx-'
})

export const BUTTON_TYPES = new Enum(
  'HOLLOW',
  'PRIMARY',
  'OUTLINE',
  'GRADIENT',
  'PRIMARY-FULL',
  'PRIMARY-FULL-NO-HOVER',
  'KLEIN-FULL-NO-HOVER',
  'SECONDARY-FULL',
  'OUTLINE-BLACK',
  'PRIMARY_HOLLOW',
  'SECONDARY-HOLLOW',
  'SECONDARY',
  'SECONDARY-FULL-INVERSE',
  'PROMOTION'
)

export const BUTTON_SIZES = new Enum('SMALL', 'MEDIUM', 'LARGE')

export const BUTTON_PROP_TYPES = {
  type: PropTypes.oneOf([
    BUTTON_TYPES['HOLLOW'],
    BUTTON_TYPES['PRIMARY'],
    BUTTON_TYPES['OUTLINE'],
    BUTTON_TYPES['GRADIENT'],
    BUTTON_TYPES['PRIMARY-FULL'],
    BUTTON_TYPES['PRIMARY-FULL-NO-HOVER'],
    BUTTON_TYPES['KLEIN-FULL-NO-HOVER'],
    BUTTON_TYPES['SECONDARY-FULL'],
    BUTTON_TYPES['OUTLINE-BLACK'],
    BUTTON_TYPES['PRIMARY_HOLLOW'],
    BUTTON_TYPES['SECONDARY-HOLLOW'],
    BUTTON_TYPES['SECONDARY'],
    BUTTON_TYPES['SECONDARY-FULL-INVERSE'],
    BUTTON_TYPES['PROMOTION']
  ]),
  size: PropTypes.oneOf([
    BUTTON_SIZES.SMALL,
    BUTTON_SIZES.MEDIUM,
    BUTTON_SIZES.LARGE
  ]),
  buttonType: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  children: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element, PropTypes.array]
  )
}

export const BUTTON_DEFAULT_PROPS = {
  type: BUTTON_TYPES.PRIMARY,
  size: BUTTON_SIZES.MEDIUM,
  disabled: false
}

/**
 * @deprecated since February 2019
 */
class Button extends React.Component {
  renderSpinner () {
    return (
      <div className='pfx-spinner'>
        <div className='pfx-double-bounce1' />
        <div className='pfx-double-bounce2' />
      </div>
    )
  }

  handleClick = debounce(event => {
    if (!this.props.isLoading && this.props.onClick) {
      this.props.onClick(event)
    }
  }, 300, { leading: true, trailing: false })

  render () {
    let { className, buttonType, type, size, isLoading, onClick, children, ...props } = this.props
    className = cn(null, [type, size, isLoading ? 'loading' : null], className)

    return (
      <button
        className={className}
        onClick={this.handleClick}
        {...buttonType && { type: buttonType }}
        {...props}>
        <div className={cn('child-wrapper')}>
          {children}
        </div>
        {this.renderSpinner()}
      </button>
    )
  }
}

Button.propTypes = BUTTON_PROP_TYPES
Button.defaultProps = BUTTON_DEFAULT_PROPS

export default Button
