import { Bem } from "../../common/utils";

const cn = new Bem({
  name: "mega-menu",
  prefix: "pfx-",
});

export const megaMenuCnFn = (modifiers) => cn(null, modifiers);

export const itemCn = cn("item");

export const menuContainerCn = cn("container");

export const menuItemCnFn = (modifiers) => cn("menu-item", modifiers);

export const menuLogoCn = cn("logo");

export const menuLogoImgCn = cn("logo-img");

export const mainMenuCn = cn("main-menu");

export const hamburgerMenuCn = cn("hamburger");

export const signupMenuCn = cn("signup-menu");

export const signupMenuItemCnFn = (modifiers) => cn("signup-item", modifiers);

export const megaMenuMobileCnFn = (modifiers) => cn("mobile-menu", modifiers);

export const currentUserCn = cn("current-user");

export const userNotificationButtonCn = cn("user-notification-button");

export const inviteFriendCn = cn("invite-friend");
