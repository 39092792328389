import { Component } from 'react'
import Button, { BUTTON_SIZES, BUTTON_TYPES } from '../button'
import Icon from '../icon'
import { Bem, get } from '@common/utils'
import './style.scss'
import { withIcon } from '../button/components/with-icon'
import connect from 'react-redux/es/connect/connect'
import createProvider from '../../store/provider'
import { SCREEN_BREAKPOINTS, LINKS } from '@common/consts'
import { downloadStems, getDownloadStemsUrl } from '../../common/http'
import { setFlashMessageByValues } from '../../store/actions/flashMessage'
import { actionTypes } from '../../store/actions/types/flashMessage'
import { downloadFile, gotoAuth, gotoLocation } from '../../common/utils'
import {sendAnalyticsEvent} from "../../common/analytics";
import {GTM_EVENTS} from "../../common/consts";

const cn = new Bem({
  prefix: 'pfx-',
  name: 'stems-download-button'
})

const ButtonWithIcon = withIcon(Button)

export class StemsDownloadButton extends Component
{

  constructor (props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  async downloadStems () {
    const { setFlashMessageByValues, groupId, currentUser } = this.props

    if (!currentUser) {
      return gotoAuth()
    }

    this.setState({isLoading: true})

    try {
      const { url } = (await getDownloadStemsUrl(groupId)).data
      downloadFile(url)

      await sendAnalyticsEvent(GTM_EVENTS.GTM_STARTED_REMIX, 'Download remix files')
    } catch(e) {
      setFlashMessageByValues({
        text: 'The download could not be processed. Please try again and if the problems remains, please contact our support at <a href=\'mailto:support@soundation.com\'>support@soundation.com</a>',
        type: 'error'
      })
    }
    this.setState({isLoading: false})
  }

  render () {
    const { isLarge } = this.props
    const { isLoading } = this.state

    return (
      <div className={cn()}>
        <ButtonWithIcon
          type={BUTTON_TYPES.PRIMARY}
          size={isLarge ? BUTTON_SIZES.MEDIUM : BUTTON_SIZES.SMALL}
          icon='new-download'
          data-pfx-require-sign-in
          onClick={() => this.downloadStems()}
          isLoading={isLoading}>
          Download remix files
        </ButtonWithIcon>
      </div>
    )
  }

}

const mapStateToProps = state => {
  return {
    isLarge: get(state, 'app.screenBreakPointIndex') < 1,
    currentUser: get(state, 'backendData.currentUser')
  }
}

const mapDispatchToProps = {
  setFlashMessageByValues
}


export default createProvider(connect(mapStateToProps, mapDispatchToProps)(StemsDownloadButton, false))
