import React from 'react'
import './style.scss'
import PropTypes from 'prop-types'
import Image from '@components/image'
import { Bem, Enum, gotoLocation } from '../../common/utils'
import IconLight from '@images/soundation-icon-light.svg?v=2'
import LogoTypeLight from '@images/soundation-logotype-light.svg?v=2'
import IconDark from '@images/soundation-icon-dark.svg?v=2'
import LogoTypeDark from '@images/soundation-logotype-dark.svg?v=2'

const cn = new Bem({
  name: 'logo',
  prefix: 'pfx-'
})
const soundationCn = cn('soundation')
const educationCn = cn('education')

const LOGO_COLORS = new Enum('GRADIENT', 'LIGHT', 'DARK')
export const LOGO_TYPES = new Enum('ICON', 'LOGOTYPE')

const Logo = (props) => {
  const {
    edu = false,
    color = LOGO_COLORS.LIGHT,
    type = LOGO_TYPES.LOGOTYPE,
    link = true,
    alt = '',
    url = '',
    className
  } = props

  const style = {
    cursor: link ? 'pointer' : 'default'
  }
  const src = color === LOGO_COLORS.LIGHT
    ? type === LOGO_TYPES.LOGOTYPE
      ? LogoTypeLight
      : IconLight
    : type === LOGO_TYPES.LOGOTYPE
      ? LogoTypeDark
      : IconDark

  return (
    <div className={cn(null, { edu })}>
      <Image
        onClick={() => link ? gotoLocation(url || '/') : null}
        style={style}
        alt={alt}
        className={cn('logo', null, className)}
        src={src}
      />

      {edu &&
        (<><p className={soundationCn}>Soundation</p><p className={educationCn}>Education</p></>)}
    </div>
  )
}

Logo.propTypes = {
  color: PropTypes.oneOf([
    LOGO_COLORS.LIGHT,
    LOGO_COLORS.DARK
  ]),
  type: PropTypes.oneOf([
    LOGO_TYPES.LOGOTYPE,
    LOGO_TYPES.ICON
  ]),
  link: PropTypes.bool,
  url: PropTypes.string,
  alt: PropTypes.string,
  edu: PropTypes.bool
}

export default Logo
