import React from "react";
import PropTypes from "prop-types";
import { Bem } from "../../common/utils";
import "./HamburgerToggle.style.scss";

const cn = new Bem({
  name: "hamburger-toggle",
  prefix: "pfx-",
});

const HamburgerToggle = ({
  onClick,
  isOpen = false,
  sizes = { close: 32, open: 24 },
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(undefined, { "is-open": isOpen })}
      style={{
        "--close-size": `${sizes.close}px`,
        "--open-size": `${sizes.open}px`,
      }}
    >
      <span className={cn("line")} />
      <span className={cn("line")} />
      <span className={cn("line")} />
    </button>
  );
};

HamburgerToggle.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  sizes: PropTypes.shape({
    close: PropTypes.number,
    open: PropTypes.number,
  }),
};

export default HamburgerToggle;
