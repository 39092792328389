import React from 'react'
import './tracks-list-view.scss'
import PropTypes from 'prop-types'
import { Bem, Enum, gotoLocation, mergeLikedTracksMeta } from '../../common/utils'
import Icon from '../../components/icon/index'
import Dropdown from '../../components/dropdown/index'
import TracksList from '../../components/tracks-list/tracks-list'
import SingleViewTrack from '../../components/carousel/components/single-view-track'
import { map, get } from 'lodash'
import { Parser } from 'html-to-react'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import { deleteGroupTrack, getGroupMixdowns } from '../../common/http'

const cn = new Bem({
  name: 'track-list-view',
  prefix: 'pfx-'
})

const htmlToReactParser = new Parser()

export const SORTING = new Enum('LATEST', 'AZ', 'PLAYS', 'LIKES', 'COMMENTS')
export const LAYOUTS = new Enum('SQUARES', 'LIST')

class TrackListView extends React.Component {
  static GRID_ITEMS_PER_ROW_BY_BREAKPOINT = [5, 4, 3, 2, 2]
  static ITEMS = 50

  constructor (props) {
    super(props)
    this.state = {
      tracks: props.initialTracks,
      currentSorting: SORTING.LATEST,
      currentLayout: LAYOUTS.LIST,
      pagesLoaded: 1,
      pageLoading: false,
      allPagesLoaded: false
    }
  }

  componentDidMount () {
    this.refreshTracks()
  }

  async refreshTracks () {
    const { data } = await getGroupMixdowns(
      this.props.groupId,
      { page: 1, items: TrackListView.ITEMS, ...TrackListView.SORTING_FIELDS_MAP[this.state.currentSorting] }
    ).then(mergeLikedTracksMeta)

    if (data && data.tracks) {
      this.setState({
        pageLoading: false,
        pagesLoaded: 1,
        tracks: data.tracks,
        allPagesLoaded: data.meta.nextPage === null
      })
    }
  }

  async appendTracks () {
    if (this.state.pageLoading) {
      return
    }
    this.setState({ pageLoading: true })
    const page = this.state.pagesLoaded + 1
    try {
      const { data } = await getGroupMixdowns(
        this.props.groupId,
        { page, items: TrackListView.ITEMS, ...TrackListView.SORTING_FIELDS_MAP[this.state.currentSorting] }
      ).then(mergeLikedTracksMeta)
      this.setState({ pageLoading: false })
      if (data && data.tracks) {
        this.setState((state) => {
          return {
            pagesLoaded: page,
            tracks: state.tracks.concat(data.tracks),
            allPagesLoaded: data.meta.nextPage === null
          }
        })
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.setState({
          pagesLoaded: page,
          allPagesLoaded: true,
          pageLoading: false
        })
        return
      }
      throw e
    }
  }

  static SORTING_LABELS_MAP = {
    [SORTING.LATEST]: 'Latest',
    [SORTING.AZ]: 'A-Z',
    [SORTING.PLAYS]: 'Plays',
    [SORTING.LIKES]: 'Likes',
    [SORTING.COMMENTS]: 'Comments'
  }

  static SORTING_FIELDS_MAP = {
    [SORTING.LATEST]: { sortBy: 'created_on', sortOrder: 'desc' },
    [SORTING.AZ]: { sortBy: 'title', sortOrder: 'asc' },
    [SORTING.PLAYS]: { sortBy: 'plays', sortOrder: 'desc' },
    [SORTING.LIKES]: { sortBy: 'likes', sortOrder: 'desc' },
    [SORTING.COMMENTS]: { sortBy: 'comments_count', sortOrder: 'desc' }
  }

  static SortingDropdown = class extends React.Component {
    renderToggle (currentSorting) {
      const currentSortingLabel = TrackListView.SORTING_LABELS_MAP[currentSorting]
      return (
        <div className={cn('dropdown-toggle')}>
          <span className={cn('dropdown-label')}>{ currentSortingLabel }</span>
          <Icon size={8} icon='new-arrow-2-down' className={cn('dropdown-icon')} />
        </div>
      )
    }
    render () {
      const { currentSorting } = this.props

      const sortingItemValues = [
        SORTING.LATEST,
        SORTING.AZ,
        SORTING.PLAYS,
        SORTING.LIKES,
        SORTING.COMMENTS
      ]

      const sortingItems = sortingItemValues.filter((val) => {
        return val !== currentSorting
      }).map((val) => {
        return this.renderSortingDropdownItem(val)
      })

      return (
        <Dropdown key={currentSorting} toggle={this.renderToggle(currentSorting)}>
          { sortingItems }
        </Dropdown>
      )
    }
    renderSortingDropdownItem (value) {
      const label = TrackListView.SORTING_LABELS_MAP[value]
      const { onItemSelect } = this.props
      return (
        <div
          size={16}
          className={cn('dropdown-item')}
          onClick={() => onItemSelect(value)}>{label}</div>
      )
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.currentSorting !== prevState.currentSorting) {
      this.refreshTracks()
    }
  }

  handleSortingChange (newSorting) {
    this.setState({ currentSorting: newSorting })
  }

  handleLayoutChange () {
    const { currentLayout } = this.state
    switch (currentLayout) {
      case LAYOUTS.LIST:
        return this.setState({ currentLayout: LAYOUTS.SQUARES })
      case LAYOUTS.SQUARES:
        return this.setState({ currentLayout: LAYOUTS.LIST })
    }
  }

  renderListLayout () {
    const { tracks } = this.state
    const { isCurrentUserAdmin } = this.props;

    const mapTracks = tracks.map(({
      duration,
      published,
      title,
      user,
      id,
      createdAt,
      audioMp3Url,
      fileName,
      imageSmallUrl,
      permalink,
      directWaveformUrl,
      shortLinkId,
      doesUserLike
    }) => {
      return {
        duration,
        title,
        songUrl: audioMp3Url,
        fileName,
        createdAt,
        published,
        imageSmallUrl,
        id,
        username: user.username,
        profileLink: user.profileLink,
        permalink,
        directWaveformUrl,
        shortLinkId,
        doesUserLike
      }
    })

    return (
      <TracksList
        shouldShowStats={false}
        tracks={mapTracks}
        player
        canRemoveTrack={isCurrentUserAdmin}
        onTrackRemove={(id) => this.onTrackRemove(id)}/>
    )
  }

  async onTrackRemove(id) {
    const {groupId} = this.props
    await deleteGroupTrack(groupId, id)
    this.refreshTracks()
  }

  renderSquaresLayout () {
    const { GRID_ITEMS_PER_ROW_BY_BREAKPOINT } = TrackListView
    const { screenBreakPointIndex } = this.props
    const squareWidth = 100 / GRID_ITEMS_PER_ROW_BY_BREAKPOINT[screenBreakPointIndex]
    const width = `calc(${squareWidth}% - 24px)`
    const { tracks } = this.state

    return (
      <div className={cn('grid-layout')}>
        {
          map(tracks, ({ id, title, user, imageLargeUrl, createdAt, audioMp3Url, permalink, duration, directWaveformUrl, shortLinkId, doesUserLike, fileName }, idx) => (
            <SingleViewTrack
              style={{
                width: width,
                flexBasis: width
              }}
              {...{
                songUrl: audioMp3Url,
                fileName,
                image: imageLargeUrl,
                id,
                title,
                subtitle: user.username,
                profileLink: user.profileLink,
                permalink,
                shortLinkId,
                duration,
                directWaveformUrl,
                date: createdAt,
                doesUserLike
              }} />
          ))
        }
      </div>
    )
  }

  renderContentByLayout () {
    const { currentLayout } = this.state
    switch (currentLayout) {
      case LAYOUTS.LIST:
        return this.renderListLayout()
      case LAYOUTS.SQUARES:
        return this.renderSquaresLayout()
      default:
        return this.renderSquaresLayout()
    }
  }

  render() {
    const { currentLayout, pageLoading, allPagesLoaded } = this.state
    const { title, backButtonUrl } = this.props

    const VIEWS_ICON_BY_CURRENT_LAYOUT = {
      [LAYOUTS.SQUARES]: 'new-layout-list',
      [LAYOUTS.LIST]: 'new-layout-grid'
    }

    const { SortingDropdown } = TrackListView

    return (
      <div className={cn(null, null, 'container container--wider')}>
        <div className={cn('top')}>
          <div className={cn('back-button')}>
            { backButtonUrl && <Icon
              size={16}
              icon='new-arrow-left'
              className={cn('back-icon')}
              onClick={() => gotoLocation(backButtonUrl)} />}
          </div>
          <div className={cn('header')}>
            <h3 className={cn('title')}>
              {htmlToReactParser.parse(title)}
            </h3>
            <div className={cn('header-right')}>
              <SortingDropdown
                onItemSelect={this.handleSortingChange.bind(this)}
                currentSorting={this.state.currentSorting}
                className={cn('sorting-dropdown')} />
              <Icon
                size={16}
                className={cn('layout-change')}
                onClick={() => this.handleLayoutChange()}
                icon={VIEWS_ICON_BY_CURRENT_LAYOUT[currentLayout]} />
            </div>
          </div>
        </div>
        {this.renderContentByLayout()}
        { !allPagesLoaded && <div className={cn('view-more')}>
          <a className={ cn('view-more-link', pageLoading ? 'disabled' : null) } href='#' onClick={ (e) => { e.preventDefault(); this.appendTracks() } }>View more tracks</a>
        </div> }
      </div>
    )
  }
}

TrackListView.propTypes = {
  initialTracks: PropTypes.array,
  title: PropTypes.string.isRequired,
  screenBreakPointIndex: PropTypes.number,
  groupId: PropTypes.string,
  isCurrentUserAdmin: PropTypes.bool
}

TrackListView.defaultProps = {
  initialTracks: [],
  screenBreakPointIndex: 4,
  isCurrentUserAdmin: false
}

const mapStateToProps = state => {
  return {
    screenBreakPointIndex: get(state, 'app.screenBreakPointIndex')
  }
}

export default createProvider(connect(mapStateToProps, null)(TrackListView))
