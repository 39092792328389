import React, { useEffect, useCallback, useState, useMemo } from 'react'
import useIntersection from '@root/hooks/useIntersection'
import SelectDropdown from '@components/select-dropdown'
import { throttle } from 'lodash'
import Spinner from '@components/spinner'
import * as styles from './CommunitySubPage.module.scss'

// community sub page which shows all results of a certain category (e.g. Featured groups)
/** @param props.landscapeOnMobile the cards become landscape on mobile. I.e. the image and the text are placed next to, instead of on top of, each other */
export default function CommunitySubPage (props) {
  const { title, subHeader = null, sortValue, sortOptions, onSort, isSortingDisabled = false, fetchNextPage, className = '', isLoading, landscapeOnMobile = false, children } = props
  const [clonedChildren, setClonedChildren] = useState([])
  const [lastElementRef, setLastElementRef] = useState({ current: null })

  const fetchNextPageThrottled = useMemo(() => {
    if (fetchNextPage) {
      return throttle(fetchNextPage, 500, { leading: true, trailing: false })
    }
  }, [fetchNextPage])

  useIntersection({ callback: fetchNextPageThrottled, elementRef: lastElementRef })

  const setLastChildRef = useCallback((r) => {
    setLastElementRef({ current: r })
  }, [])

  // attach ref to last child
  useEffect(() => {
    const childrenClone = []

    React.Children.forEach(children, (child, index) => {
      if (index === children.length - 1) {
        childrenClone.push(React.cloneElement(child, { ...child.props, ref: setLastChildRef }, child.props.children))
      } else {
        childrenClone.push(child)
      }
    })

    setClonedChildren(childrenClone)
  }, [children, setLastChildRef])

  return (
    <div className={`${styles.sectionPage} ${className}`}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        {sortValue && (
          <SelectDropdown
            className={styles.sortDropdownContainer}
            dropdownClassName={styles.sortDropdown}
            togglerClassName={styles.sortDropdownToggler}
            itemClassName={styles.sortDropdownItem}
            value={sortValue}
            items={sortOptions}
            onSelect={onSort}
            isDisabled={isSortingDisabled}
          />
        )}
      </div>

      {subHeader}

      <div className={`${styles.body} ${landscapeOnMobile && styles.mobileLandscape}`}>
        {clonedChildren}
      </div>

      {isLoading && <Spinner className={styles.spinner} />}
    </div>
  )
}
