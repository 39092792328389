import { Bem } from "../../../common/utils";

const cn = Bem({
  name: "mega-menu-items",
  prefix: "pfx-",
});

export const menuItemsCn = (modifiers) => cn("", modifiers);

export const menuItemsLinkCn = cn("link");

export const menuItemsLinkIconCn = cn("link-icon");

export const menuItemsInnerCn = cn("inner");
