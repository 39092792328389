import React from 'react'
import './discount-input.scss'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import Button from '@components/button/index'
import { noop } from 'lodash'
import Checkbox from '@components/checkbox/index'
import InputWrapper from './input-wrapper'
import Message from '@components/message/index'
import isEqual from 'react-fast-compare'

const cn = new Bem({
  name: 'discount-input',
  prefix: 'pfx-'
})

class DiscountInput extends React.Component {
  state = {}

  componentWillReceiveProps (nextProps) {
    const {
      code,
      isOpen: open,
      applied,
      message,
      messageType
    } = nextProps

    this.setState({
      open,
      code,
      applied,
      message,
      messageType
    })
  }

  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props, nextProps);
  }

  handleButtonClick = () => {
    const { onApply, onCancel } = this.props
    const { applied, code } = this.state
    applied ? onCancel() : onApply(code)
  }

  handleCheckboxChange (open) {
    const { onCheckboxChange, onCancel } = this.props
    this.setState({ open }, () => onCheckboxChange(open))
    if (!open) onCancel()
  }

  handleInputChange (code) {
    this.setState({ code }, () => this.props.onCodeChange(code))
  }

  handleKeyDown (e) {
    const { onApply } = this.props
    const { code } = this.state
    const keyCode = e.keyCode || e.which

    if (keyCode === 13) {
      onApply(code)
    }
  }

  render () {
    const { open, applied, code, messageType, message } = this.state
    const { className, isLoading } = this.props
    return (
      <div className={cn(null, null, className)}>
        <div className={cn('checkbox-container')}>
          <Checkbox
            value={open}
            className={cn('checkbox')}
            onChange={open => this.handleCheckboxChange(open)}
            label='I have a discount code' />
        </div>
        { open && (
          <form className={cn('open-container')}>
            <InputWrapper className={cn('input-wrapper')} disabled={applied}>
              <input
                data-pfx-discount-input
                type='text'
                placeholder='Enter it here'
                value={code}
                disabled={applied}
                onKeyDown={(e) => this.handleKeyDown(e)}
                onChange={(e) => this.handleInputChange(e.target.value)} />
            </InputWrapper>
            <Button
              onClick={this.handleButtonClick}
              disabled={!code}
              size='small'
              buttonType='button'
              type='outline-black'
              isLoading={isLoading}
              className={cn('submit')}>
              { applied ? 'Cancel discount' : 'Apply discount' }
            </Button>
          </form>
        )}
        { message && <Message type={messageType}>{message}</Message> }
      </div>
    )
  }
}

DiscountInput.propTypes = {
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onCodeChange: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  message: PropTypes.string,
  messageType: PropTypes.string,
  code: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  isLoading: PropTypes.bool
}

DiscountInput.defaultProps = {
  onApply: noop,
  onCancel: noop,
  onCodeChange: noop,
  onCheckboxChange: noop,
  message: null,
  messageType: null,
  className: null,
  code: '',
  isOpen: false,
  isLoading: false
}

export default DiscountInput
