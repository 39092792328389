import React, { useMemo } from 'react'
import * as styles from './Typography.module.scss'

export const variants = [
  'huge',
  'h1',
  'h2',
  'h3',
  'h4',
  'body-l',
  'body',
  'body-s',
  'body-xs',
  'mono',
  'mono-s'
]

const defaultTags = {
  'huge': 'h1',
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3',
  'h4': 'h4',
  'body-l': 'h6',
  'body': 'p',
  'body-s': 'span',
  'body-xs': 'small',
  'mono': 'span',
  'mono-s': 'small'
}

const variantClass = {
  'huge': 'huge',
  'h1': 'h1',
  'h2': 'h2',
  'h3': 'h3',
  'h4': 'h4',
  'body-l': 'bodyL',
  'body': 'body',
  'body-s': 'bodyS',
  'body-xs': 'bodyXs',
  'mono': 'mono',
  'mono-s': 'monoS'
}

const displayClass = {
  inline: 'displayInline',
  block: 'displayBlock'
}

const Typography = (props) => {
  const { as, variant, display, color = styles.black, children, ...rest } = props

  const Component = useMemo(() => {
    if (as) return as

    return defaultTags[variant]
  }, [as, variant])

  return (
    <Component
      {...rest}
      style={{ ...(rest.style || {}), color }}
      className={`${styles.typography} ${styles[variantClass[variant]]} ${display ? styles[displayClass[display]] : ''}`}
    >
      {children}
    </Component>
  )
}

export default Typography
