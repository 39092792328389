import React, { useCallback, useEffect, useMemo, useState } from 'react'
import usePaginate from '@hooks/usePaginate'
import useInfiniteScrolling from '@hooks/useInfiniteScrolling'
import { throttle } from 'lodash'
import { LINKS } from '@common/consts'
import { fetchConversations, denormalizeResponse } from '@common/http'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import { connect, useSelector } from 'react-redux'
import Spinner from '@components/spinner'
import CommunityTitle from '@containers/community/community-title'
import Message from '../message'
import * as styles from './Conversations.module.scss'

const Conversations = () => {
  const currentUser = useSelector(selectCurrentUser)
  const [defaultAvatarUrl, setDefaultAvatarUrl] = useState(null)

  const fetcher = useCallback(async ({ page }) => {
    const res = await fetchConversations({ page })
    setDefaultAvatarUrl(res.data.meta.default_avatar_url)
    return res
  }, [])
  const { isLoading, data, fetchNextPage } = usePaginate({ fetcher })
  const conversations = denormalizeResponse(data)

  const fetchNextPageThrottled = useMemo(() => throttle(fetchNextPage, 250, { leading: true, trailing: false }), [fetchNextPage])
  const handleScroll = useInfiniteScrolling(fetchNextPageThrottled, 150, 'down')

  useEffect(fetchNextPage, [fetchNextPage])

  const getLastParticipation = (conversation) => conversation.conversationParticipations?.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)).slice(-1)[0]
  const getUserParticipation = (conversation) => conversation.conversationParticipations.find((participation) => participation.user.id === currentUser.guid)

  return (
    <div className={styles.container}>
      <CommunityTitle className={styles.title} title='Messages' showOnDesktop />

      {conversations.length ? (
        <div className={styles.messages} onScroll={handleScroll}>
          {conversations.map((conversation) => (
            <Message
              key={conversation.id}
              isMessagePreview
              isUnread={!!getUserParticipation(conversation)?.updated}
              users={conversation.users.filter((user) => user.id !== currentUser.guid)}
              lastUpdated={getLastParticipation(conversation).updatedAt}
              message={conversation.lastMessage}
              messageUrl={`${LINKS.MESSAGES}/${conversation.id}`}
              defaultAvatarUrl={defaultAvatarUrl}
            />
          ))}
        </div>
      ) : (
        !isLoading ? (
          <div className={styles.noMessages}>
            You don't have any messages
          </div>
        ) : null
      )}

      {isLoading && <Spinner className={styles.spinner} />}
    </div>
  )
}

export default createProvider(connect(null, null)(Conversations, false))
