import React from 'react'
import './style.scss'
import BEM from 'react-bem-helper'

const bem = BEM({
  prefix: 'pfx-',
  name: 'scroll-bait'
})

const onClick = (event) => {
  const box = event.currentTarget.getBoundingClientRect()
  window.scrollTo({top: box.top + window.scrollY - 56, behavior: 'smooth'})
}

export default (props) => <div {...bem('', '', props.className)} onClick={onClick} />
