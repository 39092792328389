import PropTypes from 'prop-types'
import { LINKS } from '@common/consts'
import { noop } from 'lodash'
import Icon from '@components/icon'
import createProvider from '@store/provider'
import connect from 'react-redux/es/connect/connect'
import * as styles from './CommunitySidebar.module.scss'
import { useSelector } from 'react-redux'

// allowSearchParams: if false, the tab will not be active if there are search params in the URL
const tabs1 = [
  { label: 'Home', link: LINKS.COMMUNITY, validateLink: (link) => link === LINKS.COMMUNITY, icon: 'Home-ver-2' }
]
const tabs2 = [
  {
    label: 'Groups',
    link: LINKS.GROUPS,
    validateLink: (link) => {
      if (link === LINKS.GROUPS) {
        return true
      }
      const split = link.split('/').filter(Boolean)
      return split.length === 2 && split[0] === 'group'
    },
    icon: 'Sofa' },
  {
    label: 'Tracks',
    link: LINKS.TRACKS,
    validateLink: (link) => {
      if (link === LINKS.TRACKS || link === LINKS.MY_TRACKS) {
        return true
      }
      const split = link.split('/').filter(Boolean)
      return split.length === 4 && split[0] === 'user' && split[2] === 'track'
    },
    icon: 'Keys-or-notes' },
  {
    label: 'Users',
    link: LINKS.USERS,
    icon: 'User',
    validateLink: (link) => {
      if (link === LINKS.USERS) {
        return true
      }

      const split = link.split('/').filter(Boolean)
      return split.length === 2 && split[0] === 'user'
    }
  }
]
const tabs3 = [
  { label: 'News', link: LINKS.FEED, validateLink: (link) => link === LINKS.FEED, icon: 'Speaker' },
  { label: 'Tutorials', link: LINKS.TUTORIALS, validateLink: (link) => link === LINKS.TUTORIALS, icon: 'academy' }
]

const Tab = ({ tab, activeTab }) => {
  const { validateLink = noop } = tab

  const isActive = validateLink(activeTab)

  return (
    <a className={`${styles.tab} ${isActive && styles.tabActive}`} href={tab.link}>
      <Icon icon={tab.icon} size={24} />
      <div className={styles.tabLink}>{tab.label}</div>
    </a>
  )
}

const TabGroup = ({ tabs, queryParams, activeTab, title }) => {
  return (
    <>{title ? <h6 className={styles.tabGroupHeader}>{title}</h6> : null}{tabs.map((tab) => (
      <div key={tab.link}>
        <Tab tab={tab} activeTab={activeTab} queryParams={queryParams} />
      </div>
    ))}</>
  )
}

function CommunitySidebar (props) {
  const { activeTab, queryParams, footer } = props

  const currentUser = useSelector(state => state.backendData.currentUser)

  const activeTabFixed = activeTab.toLowerCase().replace('//', '/')

  return (
    <aside className={styles.container}>
      <div className={styles.innerContainer}>
        <TabGroup tabs={tabs1} activeTab={activeTabFixed} queryParams={queryParams} />

        <TabGroup tabs={tabs2} activeTab={activeTabFixed} queryParams={queryParams} title='COMMUNITY' />

        <TabGroup tabs={tabs3} activeTab={activeTabFixed} queryParams={queryParams} title='SOUNDATION' />
      </div>

      {!currentUser && (<div className={styles.footer}>
        {footer.links?.map(({ externalLink, name }) => (
          <a key={externalLink.url} className={styles.footerLink} href={externalLink.url}>{name}</a>
        ))}
      </div>)}
    </aside>
  )
}

CommunitySidebar.propTypes = {
  currentUser: PropTypes.string
}

export default createProvider(connect(null, null)(CommunitySidebar))
