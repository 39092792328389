// extracted by mini-css-extract-plugin
var _1 = "pfx-CommunityCard-module__action--j7vvH";
var _2 = "pfx-CommunityCard-module__action-icon--L2Dtc";
var _3 = "pfx-CommunityCard-module__actions--oEfto";
var _4 = "pfx-CommunityCard-module__actions-row--or8xo";
var _5 = "pfx-CommunityCard-module__actions-row-min-height--b0my7";
var _6 = "#000";
var _7 = "1260px";
var _8 = "1020px";
var _9 = "668px";
var _a = "1550px";
var _b = "pfx-CommunityCard-module__container--m1q43";
var _c = "1140px";
var _d = "940px";
var _e = "100%";
var _f = "15px";
var _10 = "620px";
var _11 = "pfx-CommunityCard-module__context-menu-item--tI7Aj";
var _12 = "pfx-CommunityCard-module__cover--koP9r";
var _13 = "pfx-CommunityCard-module__cover-landscape--L6pzI";
var _14 = "pfx-CommunityCard-module__cover-sub-page--JKGb8";
var _15 = "#f53535";
var _16 = "rgba(0,0,0,.2)";
var _17 = "rgba(255,255,255,.38)";
var _18 = "pfx-CommunityCard-module__disabled-action--nbHM3";
var _19 = ".38";
var _1a = "pfx-CommunityCard-module__dropdown--HVZOX";
var _1b = "rgba(255,255,255,.2)";
var _1c = "#0025ff";
var _1d = "pfx-CommunityCard-module__fixed-subtitle-height--ndjiI";
var _1e = "pfx-CommunityCard-module__four--by-three-cover--RsKB9";
var _1f = "pfx-CommunityCard-module__glitch-anim--QWT46";
var _20 = "pfx-CommunityCard-module__glitch-anim-2--mVWDW";
var _21 = "#f60";
var _22 = "#41ead4";
var _23 = "#2cdd78";
var _24 = "#66ab23";
var _25 = "#f5f5f5";
var _26 = "#c3c3c3";
var _27 = "#ececec";
var _28 = "#252525";
var _29 = "#272727";
var _2a = "#c6c6c5";
var _2b = "#333";
var _2c = "#3c3c3c";
var _2d = "#9c9b9b";
var _2e = "#8c8c8c";
var _2f = "#6f6f6e";
var _30 = "#3c3c3b";
var _31 = "#373737";
var _32 = "#6a6a69";
var _33 = "#6f6f6f";
var _34 = "#9f9e9e";
var _35 = "#282828";
var _36 = "#828181";
var _37 = "#1d1d1d";
var _38 = "#979797";
var _39 = "#9c9b9b";
var _3a = "#a7a7a6";
var _3b = "#c6c6c5";
var _3c = "#d8d8d7";
var _3d = "#dddddc";
var _3e = "72px";
var _3f = "40px";
var _40 = "36px";
var _41 = "25px";
var _42 = "18px";
var _43 = "13px";
var _44 = "pfx-CommunityCard-module__info--jPNoN";
var _45 = "pfx-CommunityCard-module__info-landscape--co_AD";
var _46 = "#0025ff";
var _47 = "pfx-CommunityCard-module__landscape-container--JRlbW";
var _48 = "rgba(255,255,255,.12)";
var _49 = "#6535f5";
var _4a = "2px";
var _4b = "56px";
var _4c = "56px";
var _4d = "#41ead4";
var _4e = "#f60";
var _4f = "#f83600";
var _50 = "#881f00";
var _51 = "18px";
var _52 = "15px";
var _53 = "pfx-CommunityCard-module__player-img--aObKA";
var _54 = "red";
var _55 = "pfx-CommunityCard-module__stat--evMy8";
var _56 = "pfx-CommunityCard-module__stats--B6Rs7";
var _57 = "pfx-CommunityCard-module__stats-landscape--gRO6I";
var _58 = "13px";
var _59 = "pfx-CommunityCard-module__sub-page--BlB_O";
var _5a = "pfx-CommunityCard-module__subtitle--k2sHw";
var _5b = "rgba(255,255,255,.07)";
var _5c = "pfx-CommunityCard-module__title--QHBEa";
var _5d = "pfx-CommunityCard-module__title-landscape--uPNq6";
var _5e = "#35465c";
var _5f = "#55acee";
var _60 = "#fff";
var _61 = "#ffce36";
var _62 = "#ffcd00";
var _63 = "red";
export { _1 as "action", _2 as "actionIcon", _3 as "actions", _4 as "actionsRow", _5 as "actionsRowMinHeight", _6 as "black", _7 as "breakLarge", _8 as "breakMedium", _9 as "breakSmall", _a as "breakVLarge", _b as "container", _c as "containerLarge", _d as "containerMedium", _e as "containerMobile", _f as "containerPaddingMobile", _10 as "containerSmall", _11 as "contextMenuItem", _12 as "cover", _13 as "coverLandscape", _14 as "coverSubPage", _15 as "danger", _16 as "darkElement", _17 as "disabled", _18 as "disabledAction", _19 as "disabledOpacity", _1a as "dropdown", _1b as "extraLightElement", _1c as "facebook", _1d as "fixedSubtitleHeight", _1e as "fourByThreeCover", _1f as "glitchAnim", _20 as "glitchAnim2", _21 as "google", _22 as "green", _23 as "green2", _24 as "greenColor", _25 as "grey1", _26 as "grey10", _27 as "grey2", _28 as "grey25", _29 as "grey27", _2a as "grey3", _2b as "grey33", _2c as "grey3C", _2d as "grey4", _2e as "grey41", _2f as "grey5", _30 as "grey6", _31 as "grey61", _32 as "grey6A", _33 as "grey6F", _34 as "grey7", _35 as "grey8", _36 as "grey82", _37 as "grey9", _38 as "grey97", _39 as "grey9C", _3a as "greyA7", _3b as "greyC6", _3c as "greyD8", _3d as "greyDd", _3e as "header1", _3f as "header2", _40 as "header3", _41 as "header4", _42 as "header5", _43 as "header6", _44 as "info", _45 as "infoLandscape", _46 as "klein", _47 as "landscapeContainer", _48 as "lightElement", _49 as "lilac", _4a as "menuBorder", _4b as "menuHeight", _4c as "menuMobileHeight", _4d as "ocean", _4e as "orange", _4f as "orange2", _50 as "orangeDark", _51 as "paragraph", _52 as "paragraphSmall", _53 as "playerImg", _54 as "red", _55 as "stat", _56 as "stats", _57 as "statsLandscape", _58 as "subHeaderSmall", _59 as "subPage", _5a as "subtitle", _5b as "subtleLightElement", _5c as "title", _5d as "titleLandscape", _5e as "tumblr", _5f as "twitter", _60 as "white", _61 as "yellow", _62 as "yellowDeep", _63 as "youtube" }
