import Linkton, { TYPE as LinktonType, TAG as LinktonTag } from '@components/linkton'
import * as styles from './CommunitySection.module.scss'

export default function CommunitySection ({ className = '', label, showMoreLink, subHeader, items }) {
  return (
    <div key={showMoreLink} className={`${styles.section} ${className}`}>
      <div className={styles.sectionHeaderContainer}>
        <h4 className={styles.sectionHeader}>{label}</h4>
        <Linkton isDisabled={!showMoreLink} className={`${styles.link} ${!showMoreLink ? styles.hidden :''}`} type={LinktonType.HOLLOW} href={showMoreLink} tag={LinktonTag.ANCHOR}>
          <div className={styles.showAll}>
            <span>Show all</span>
            <icon className='icon-next' />
          </div>
        </Linkton>
      </div>

      {subHeader}

      <div className={styles.carousel}>
        {items.map((item, i) => {
          const isLastItem = i === items.length - 1

          return (
            <div key={item.id ?? item.key} className={isLastItem && styles.lastItem}>{item}</div>
          )
        })}
      </div>
    </div>
  )
}
