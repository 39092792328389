import React from 'react'
import PropTypes from 'prop-types'
import buildUrl from 'build-url'
import { noop } from 'lodash'
import { If, Else, Then } from 'react-if'

import './checkout-paypal-container.scss'

import { getButtonPrice } from '../services/utils'
import { PRODUCT_TYPE } from '../services/consts'

import { PFX_REQUIRE_SIGN_IN } from '../../../global-events-bindings'
import { ENDPOINTS, LINKS } from '../../../common/consts'
import { gotoLocation, Bem, canUseDOM, get } from '../../../common/utils'
import Button from '../../../components/button/index'
import Message from '@components/message'

const cn = new Bem({
  name: 'checkout-paypal-container',
  prefix: 'pfx-'
});

class CheckoutPaypalContainer extends React.Component {

  state = {
    isLoading: false
  }


  redirectToPaypal () {
    const { plan, productType, discountAppliedCode, activePeriod, referralId } = this.props
    const sendMessage = (url) => window.parent.postMessage({
      action: 'IFRAME_REDIRECT_TO_PAYPAL',
      payload: url
    }, '*')
    const action = canUseDOM() && window.parent && window.parent !== window ? sendMessage : gotoLocation

    this.setState({isLoading: true})

    switch (productType) {
      case PRODUCT_TYPE.SOUND:
        return action(ENDPOINTS.CART_CHECKOUT)
      case PRODUCT_TYPE.SUBSCRIPTION:
        const url = buildUrl({
          path: `${ENDPOINTS.PAYPAL_GATEWAY}/${plan}/${activePeriod}ly`,
          queryParams: {
            coupon: discountAppliedCode,
            referral_id: referralId,
            product_type: productType
          }
        })
        return action(url)
    }
  }

  renderPayButton () {
    const {
      currency,
      currentUser,
      productType,
      activePeriod,
      products,
      subscriptionTotal,
      inTrialMode,
      subscriptionType
    } = this.props

    const buttonPrice = getButtonPrice({
      productType,
      activePeriod,
      products,
      currency,
      subscriptionTotal
    })

    return (
      <div className={cn('pay-button-container')}>
        <Button
          className={inTrialMode && subscriptionType !== 'addon' ? cn('paypal-button-trial') : cn('paypal-button')}
          type={inTrialMode ? 'primary-full-no-hover' : 'outline-black'}
          onClick={() => this.redirectToPaypal()}
          isLoading={this.state.isLoading}
          disabled={!currentUser || this.props.isButtonDisabled}>
          <If condition={inTrialMode && subscriptionType !== 'addon'}>
            <Then>
              <span className={cn('paypal-text')}>Start free trial</span>
            </Then>
            <Else>
              <div>
                <img className={cn('paypal-icon')} src='assets/paypal-icon.svg' alt='Paypal Icon' />
                <span className={cn('paypal-text')}>Pay {buttonPrice}</span>
              </div>
            </Else>
          </If>
        </Button>
      </div>
    )
  }

  renderErrorMessage() {
    return (
      <Message type="error">
        {this.props.paymentError}
      </Message>
    )
  }

  render() {
    const {
      renderDiscountInput,
      renderPromotionalCredit,
      currency,
      currentUser,
      productType,
      activePeriod,
      products,
      subscriptionTotal
    } = this.props

    const buttonPrice = getButtonPrice({
      productType,
      activePeriod,
      products,
      currency,
      subscriptionTotal
    })

    return (
      <div className={cn('paypal')}>
        <p className={cn('paypal-description')}>
          Click the button below to be re-directed to PayPal’s website to complete your transaction
        </p>

        { renderDiscountInput() }
        { renderPromotionalCredit() }
        <If condition={this.props.paymentError}>
          {this.renderErrorMessage()}
        </If>
        <br /><br />
        { this.renderPayButton() }

        <If condition={!currentUser}>
          <p className={cn('no-account', null, 'pfx--align-center')}>
            Please <a href={`${LINKS.AUTH}?tab=sign_in`} {...{[PFX_REQUIRE_SIGN_IN]: true}}>log in</a>
            or <a href={LINKS.AUTH_PLANS} {...{[PFX_REQUIRE_SIGN_IN]: true}}>sign up</a> before proceeding to checkout.
          </p>
        </If>
      </div>

    )
  }
}

CheckoutPaypalContainer.propTypes = {
  renderDiscountInput: PropTypes.func,
  currency: PropTypes.string,
  activePeriod: PropTypes.string,
  products: PropTypes.array,
  currentUser: PropTypes.object,
  plan: PropTypes.string,
  discountAppliedCode: PropTypes.string,
  subscriptionTotal: PropTypes.number,
  isButtonDisabled: PropTypes.bool,
  referralId: PropTypes.string
};

CheckoutPaypalContainer.defaultProps = {
  renderDiscountInput: noop,
  products: []
};

export default CheckoutPaypalContainer;
