import { useCallback, useEffect, useRef, useState } from 'react'

/** Fetches next page, tracks loading state, and merges the incoming results with the existing ones. Returns the error if request fails.
 * @param fetcher: accepts { page }, returns { data: { data, included }, meta }
 * @param setter: custom function to be used for merging the incoming data with the existing one. Receives 2 arguments, the 1st is the current data, the 2nd is the response data. */
export default function usePaginate ({ initialData, fetcher, setter }) {
  const [data, setData] = useState(initialData || { data: [], included: [] })
  const [nextPage, setNextPage] = useState(1)
  const [currentPage, setCurrentPage] = useState(null)
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const uniqueRequestId = useRef(0) // this is fixed until state is reset

  // we need a ref for `fetchNextPage` to rely on in place of the state variable so it won't update after every time it makes a request and updates the next page
  const nextPageRef = useRef(nextPage) 
  useEffect(() => {
    nextPageRef.current = nextPage  
  }, [nextPage])

  const upsertResponse = useCallback((incomingData) => {
    setData((cur) => {
      if (setter) return setter(cur, incomingData)

      const upsert = (cur, incoming) => {
        const existingToKeep = cur.map((item) => {
          const isNotInIncoming = incoming.findIndex(incomingItem => incomingItem?.id === item?.id && incomingItem?.type === item?.type) === -1
          if (isNotInIncoming) return item
        }).filter(Boolean)

        return [...existingToKeep, ...incoming]
      }

      return {
        data: upsert(cur.data, incomingData.data),
        included: upsert(cur.included, incomingData.included || [])
      }
    })
  }, [setter])

  const fetchNextPage = useCallback(() => {
    if (nextPageRef.current === null) return
    const requestId = uniqueRequestId.current

    setIsLoading(true)
    fetcher({ page: nextPageRef.current })
      .then(({ data: { data, included, meta } }) => {
        const hasBeenResetInflight = requestId !== uniqueRequestId.current
        if (hasBeenResetInflight) return

        upsertResponse({ data, included })
        setCurrentPage(nextPageRef.current)
        setNextPage(meta.nextPage ?? meta.next_page ?? null)
      })
      .catch((e) => { setError(e) })
      .finally(() => setIsLoading(false))
  }, [upsertResponse, fetcher])

  const reset = useCallback(() => {
    setData({ data: [], included: [] })
    setNextPage(1)
    setCurrentPage(null)
    setError(null)
    uniqueRequestId.current += 1
  }, [])

  return { isLoading, error, data, fetchNextPage, page: currentPage, nextPage, reset }
}
