import './tracks-list.scss'
import PropTypes from 'prop-types'
import { Bem } from '../../common/utils'
import * as React from 'react'
import { map, noop, includes } from 'lodash'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import Icon from '../icon/index'
import { AlbumCover } from '@components/album-cover/album-cover.jsx'
import { LINKS } from '../../common/consts'
import { withSelectable } from '../with-selectable/with-selectable'
import { withPlayer } from '../carousel/components/with-player'

const cn = new Bem({
  name: 'tracks-list',
  prefix: 'pfx-'
})

dayjs.extend(relativeTime)

class TracksList extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedTrackId: props.selectedTrackId
    }

    this.AlbumCover = AlbumCover

    if (this.props.player) {
      this.AlbumCover = withPlayer(this.AlbumCover)
    }

    if (this.props.selectable) {
      this.AlbumCover = withSelectable(this.AlbumCover)
    }
  }

  renderStatsItem = (icon, value) => {
    return (
      <li className={cn('track-stats-item')}>
        <Icon icon={icon} size={12} className={cn('track-stats-icon')} /> {value}
      </li>
    )
  }

  selectTrack = (id) => {
    this.setState({ selectedTrackId: id })
    this.props.onSelect(id)
  }

  renderRow ({
    duration,
    likes,
    plays,
    downloads,
    commentsCount,
    title,
    songUrl,
    fileName,
    createdAt,
    published,
    imageSmallUrl,
    id,
    username,
    profileLink,
    permalink,
    shortLinkId,
    directWaveformUrl,
    doesUserLike
  }, idx) {
    const { selectedTrackId } = this.state
    const { selectable, shouldShowArtist, shouldShowStats, onSelect, userTracksIds, canRemoveTrack } = this.props
    const { AlbumCover } = this
    const modifiers = {
      selected: id === selectedTrackId,
      selectable: selectable,
      disabled: includes(userTracksIds, id)
    }

    return (
      <tr
        key={idx}
        className={cn('row', modifiers)}
        onClick={() => selectable && this.selectTrack(id)}>
        <td className={cn('column', 'cover')}>
          <AlbumCover
            className={cn('cover')}
            onSelect={() => onSelect(id)}
            isSelected={selectedTrackId === id}
            coverUrl={imageSmallUrl}
            {...{
              id,
              title,
              username,
              permalink,
              profileLink,
              shortLinkId,
              directWaveformUrl,
              duration,
              songUrl,
              fileName,
              doesUserLike
            }} />
        </td>
        <td className={cn('column', 'info')}>
          <div className={cn('title-container')}>
            <a href={`${LINKS.USER}/${profileLink}/track/${permalink}`} className={cn('track-title')}>{`${title}`}</a>
            {
              shouldShowArtist && username &&
              [
                <span className={cn('track-title-spearator')}> - </span>,
                <a href={`${LINKS.USER}/${profileLink}`} className={cn('track-title', 'artist')}>{username}</a>
              ]
            }
          </div>
          {
            shouldShowStats &&
            <ul className={cn('track-stats')}>
              { this.renderStatsItem('new-play', plays) }
              { this.renderStatsItem('new-hearth', likes) }
              { this.renderStatsItem('new-arrow-down', downloads) }
              { this.renderStatsItem('new-conversation', commentsCount) }
            </ul>
          }
        </td>
        <td className={cn('column', 'duration')}>
          {
            Math.floor(duration / 60000).toString(10).padStart(2, '0') + ':' +
            Math.floor(duration % 60000 / 1000).toString(10).padStart(2, '0')
          }
        </td>
        <td className={cn('column', 'created-at')}>
          {dayjs(createdAt).fromNow()}
        </td>
        <td className={cn('column', 'visibility')}>
          {
            published
              ? 'Published'
              : 'Public'
          }
        </td>
        {
          canRemoveTrack &&
          <td className={cn('column', 'visibility')}>
            <Icon icon='new-close' onClick={() => this.removeTrack(id)} size={18} />
          </td>
        }
      </tr>
    )
  }

  removeTrack (id) {
    if (window.confirm('Are you sure you want to delete this track?')) {
      this.props.onTrackRemove(id)
    }
  }

  render () {
    const { tracks } = this.props
    return (
      <div className={cn()}>
        <table className={cn('table')}>
          <tbody>
            {map(tracks, (track, idx) => this.renderRow(track, idx))}
          </tbody>
        </table>
      </div>
    )
  }
}

TracksList.propTypes = {
  tracks: PropTypes.arrayOf(PropTypes.shape({
    duration: PropTypes.number,
    likes: PropTypes.number,
    plays: PropTypes.number,
    status: PropTypes.number,
    downloads: PropTypes.number,
    commentsCount: PropTypes.number,
    title: PropTypes.string,
    songUrl: PropTypes.string,
    fileName: PropTypes.string,
    createdAt: PropTypes.string,
    published: PropTypes.bool,
    filename: PropTypes.string,
    imageSmallUrl: PropTypes.string,
    id: PropTypes.number,
    permalink: PropTypes.string,
    shortLinkId: PropTypes.string,
    directWaveformUrl: PropTypes.string,
    doesUserLike: PropTypes.bool
  })),
  selectable: PropTypes.bool,
  player: PropTypes.bool,
  onSelect: PropTypes.func,
  selectedTrackId: PropTypes.string,
  shouldShowStats: PropTypes.bool,
  shouldShowArtist: PropTypes.bool,
  userTracksIds: PropTypes.arrayOf(PropTypes.number),
  canRemoveTrack: PropTypes.bool,
  onTrackRemove: PropTypes.func
}

TracksList.defaultProps = {
  tracks: [],
  selectable: false,
  onSelect: noop,
  shouldShowStats: false,
  shouldShowArtist: true,
  player: false,
  canRemoveTrack: false,
  onTrackRemove: noop
}

export default TracksList
