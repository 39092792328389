import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { getGroupTracks } from '@common/http'
import useDenormalizeCommunityTracks from '@root/hooks/community/useDenormalizeCommunityTracks'
import useGroupTracksContextMenuItems from '@root/hooks/community/useGroupTracksContextMenuItems'
import usePaginate from '@root/hooks/usePaginate'
import CommunitySubPage from '../community-sub-page'
import CommunityTrackCard from '../community-track-card'

const CommunityGroupTracksSubPage = (props) => {
  const { tracks: initialTracks, group, isGroupAdmin, isAdminUser, type = '' } = props

  const isFeaturedTracksPage = (type || '').toLowerCase() === 'featured'
  const pageTitle = `${isFeaturedTracksPage ? 'Featured tracks' : 'Tracks'} of ${group.name}`

  const fetcher = useCallback(async ({ page }) => {
    return getGroupTracks(group.groupLink, { page, type, items: 20 })
  }, [group.groupLink, type])
  const { isLoading, data: tracks, fetchNextPage } = usePaginate({ initialData: initialTracks, fetcher })

  const denormalizedMixdowns = useDenormalizeCommunityTracks(tracks)
  const tracksContextMenuItems = useGroupTracksContextMenuItems({ tracks: denormalizedMixdowns, groupLink: group.groupLink, isGroupAdmin, isAdminUser })

  // fetch on mount
  useEffect(fetchNextPage, [])

  return (
    <CommunitySubPage
      title={pageTitle}
      landscapeOnMobile
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
    >
      {denormalizedMixdowns.map(({ id, mixdown }) => <CommunityTrackCard key={id} mixdown={mixdown} contextMenuItems={tracksContextMenuItems[id]} isSubPage />)}
    </CommunitySubPage>
  )
}

export default createProvider(connect(null, null)(CommunityGroupTracksSubPage))
