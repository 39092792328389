import './style.scss'

import { PureComponent } from 'react'
import { Bem } from '@common/utils'
import PropTypes from 'prop-types'

const cn = new Bem({
  name: 'switcher',
  prefix: 'pfx-'
})

export default class Switcher extends PureComponent {
  switch (value) {
    this.props.onUpdate(value)
  }

  render () {
    const { theme = 'dark', modifiers = {}, className, selected } = this.props

    return (
      <div className={`${cn()} ${className}`}>
        {this.props.buttons.map(btn => {
          return (
            <button
              key={btn.value}
              className={cn(
                'button',
                { selected: selected === btn.value, light: theme === 'light', seamless: !!modifiers.seamless }
              )}
              onClick={() => this.switch(btn.value)}
            >
              {btn.label}
            </button>
          )
        })}
      </div>
    )
  }
}

Switcher.propTypes = {
  selected: PropTypes.string.isRequired,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
  // removes borders and uses a lighter color for unselected items
  modifiers: PropTypes.objectOf({
    seamless: PropTypes.bool
  })
}
