import React from 'react'
import {Bem} from "../../../common/utils";
import {take, map} from 'lodash'
import './group-contestants.scss'
import {LINKS} from "../../../common/consts";

const cn = new Bem({
  name: 'group-contestant',
  prefix: 'pfx-'
})

export const ContestantsAvatar = ({id, avatarSmallUrl, profileLink}, idx) => (
  <a
    className={cn('avatar')}
    href={`${LINKS.USER}/${profileLink}`}
    key={idx}>
    <div className={cn('image')} style={{backgroundImage: 'url(' + avatarSmallUrl + ')'}}></div>
  </a>
)

export default ({contestantsCount, contestantsToShow, contestants, groupId}) => {
  return (
    <div className={cn()}>
      <div className={cn('top')}>
        <h5 className={cn('header')}>{`${contestantsCount} contestants`}</h5>
        {contestantsCount && <a className={cn('link')} href={`/campaigns/${groupId}/contestants`}>View all</a>}
      </div>
      {contestantsCount && <ul className={cn('list')}>
        {take(map(contestants, ContestantsAvatar), contestantsToShow)}
      </ul>}
      {!contestantsCount && <p>
        No contestants yet – be the first one to join!
      </p>}
    </div>
  )
}
