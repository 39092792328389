import PropTypes from "prop-types";
import {
  multiListItemCnFn,
  multiListItemStyleCnFn,
  multiListItemChildCn,
} from "./MultiListItem.cn";
import "./MultiListItem.style.scss";

export const MultiListItem = ({ children, number, type = "unordered" }) => (
  <li className={multiListItemCnFn(type)}>
    <span className={multiListItemStyleCnFn(type)}>
      {type === "ordered" ? number : null}
    </span>
    <div className={multiListItemChildCn}>{children}</div>
  </li>
);

MultiListItem.propTypes = {
  children: PropTypes.any.isRequired,
  number: PropTypes.number.isRequired,
  type: PropTypes.oneOf(["ordered", "unordered"]),
};
