import {
  defaults,
  camelCase,
  isNumber,
  isString,
  isDate,
  constant,
  times,
  map,
  snakeCase
} from 'lodash'
import BEMHelper from 'react-bem-helper'
import dayjs from 'dayjs'
import queryString from 'query-string'
import mapKeysDeep from 'map-keys-deep-lodash'
import { ACCOUNT_TYPES, LINKS, LOCAL_STORAGE_KEYS } from './consts'
import { zonedTimeToUtc } from 'date-fns-tz'
import { format } from 'date-fns'

export function canUseDOM() {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )
}

export function getStudioHref(slug) {
  let url = LINKS.STUDIO

  if (slug) {
    url = `${url}?campaign=${slug}`
  }

  return url
}

export function isReturningUser() {
  return (
    canUseDOM() &&
    localStorage.getItem(LOCAL_STORAGE_KEYS.RETURNING_USER) === 'true'
  )
}

export function gotoAuth() {
  if (isReturningUser()) {
    gotoLocation(LINKS.AUTH, { tab: 'sign_in' })
  } else {
    gotoLocation(LINKS.AUTH_PLANS)
  }
}

export const getAuthSuccessUrl = (successType) => {
  return getLocationHref(LINKS.AUTH, {
    tab: 'success',
    success: successType
  })
}

export function goToAuthSuccess(successType) {
  gotoLocation(getAuthSuccessUrl(successType))
}

export function gotoStudio(isLoggedIn, isEdu, slug) {
  if (!isLoggedIn) {
    gotoAuth()
  } else if (isEdu) {
    gotoLocation(getStudioHref(slug))
  } else {
    let url = LINKS.COMMUNITY
    if (slug) url += `?campaign=${slug}`
    gotoLocation(url)
  }
}

export function assignToGlobalScope(obj) {
  if (canUseDOM()) {
    defaults(window, obj)
  } else {
    defaults(global, obj)
  }
}

export function Enum(...constantsList) {
  const allValues = []
  constantsList.forEach((val) => {
    if (typeof val === 'string') {
      allValues.push(val.toLowerCase())
      this[val] = val.toLowerCase()
    } else {
      allValues.push(val)
      this[val] = val
    }
  })
  this.getAll = () => {
    return allValues
  }
}

export function Bem(...BEMHelperOptions) {
  let bemHelper = new BEMHelper(...BEMHelperOptions)
  return function (...args) {
    return bemHelper(...args).className
  }
}

export function getLocationHref(url, params) {
  if (canUseDOM()) {
    let queryParams = ''
    url = url || window.location.pathname

    if (params && Object.keys(params).length > 0) {
      queryParams = '?' + queryString.stringify(params)
    }

    if (/^(\/\/|https:\/\/|http:\/\/).+/.test(url)) {
      return url + queryParams
    }

    return window.location.origin + url + queryParams
  }
}

export function reload() {
  window.location.reload()
}

export function gotoLocation(url, params) {
  const location = getLocationHref(url, params)
  window.location.href = location
}

export function replaceHref(url, params) {
  window.location.href = getLocationHref(url, params)
}

export const isFreeAccount = (userAccountType) => {
  return isNumber(userAccountType) && userAccountType === ACCOUNT_TYPES.FREE
}

export const isPaidAccount = (userAccountType) => {
  return isNumber(userAccountType) && userAccountType !== ACCOUNT_TYPES.FREE
}

export const isAccountCanceled = (
  userAccountType,
  activeRecurringSubscription
) => {
  return (
    isNumber(userAccountType) &&
    userAccountType !== ACCOUNT_TYPES.FREE &&
    !activeRecurringSubscription
  )
}

export const isAccountUpgradeable = (accountType) => {
  return (
    [ACCOUNT_TYPES.FREE, ACCOUNT_TYPES.STARTER, ACCOUNT_TYPES.CREATOR].indexOf(
      accountType
    ) > -1
  )
}

export function generateLink(url, params) {
  let queryParam = ''
  if (params) {
    queryParam = `?${queryString.stringify(params)}`
  }
  return url + queryParam
}

export function hasClass(ele, cls) {
  return ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
}

export function addClass(ele, cls) {
  ele.classList.add(cls)
}

export function removeClass(ele, cls) {
  ele.classList.remove(cls)
}

export function setDocumentScroll(state, { onlyMobile = false } = {}) {
  const classes = {
    mobile: 'pfx-scroll-lock-mobile',
    small: 'pfx-scroll-lock'
  }

  const classToAdd = onlyMobile ? classes.mobile : classes.small

  if (canUseDOM()) {
    const $html = document.querySelector('html')
    const $body = document.querySelector('body')
    if (state) {
      document.ontouchmove = function (e) {
        return true
      }

      Object.values(classes).forEach((c) => {
        removeClass($html, c)
        removeClass($body, c)
      })
    } else {
      document.ontouchmove = function (e) {
        e.preventDefault()
      }
      addClass($html, classToAdd)
      addClass($body, classToAdd)
    }
  }
}

export function isChrome() {
  if (!canUseDOM()) return false

  const isChromium = window.chrome,
    winNav = window.navigator,
    vendorName = winNav.vendor,
    isOpera = winNav.userAgent.indexOf('OPR') > -1,
    isIEedge = winNav.userAgent.indexOf('Edge') > -1,
    isIOSChrome = winNav.userAgent.match('CriOS')

  if (isIOSChrome) {
    return true
  } else if (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  ) {
    return true
  } else {
    return false
  }
}

export function unescapeHtml(unsafe) {
  return unsafe
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#039;/g, "'")
    .replace(/&#x27;/g, "'")
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export function deepCamelCase(obj) {
  return obj ? mapKeysDeep(obj, (_, key) => camelCase(key)) : null
}

export function deepSnakeCase(obj) {
  return obj ? mapKeysDeep(obj, (_, key) => snakeCase(key)) : null
}

export function insertParamToLocation(key, value) {
  let kvp = document.location.search.substr(1).split('&')
  let i = kvp.length
  let x

  key = encodeURI(key)
  value = encodeURI(value)

  while (i--) {
    x = kvp[i].split('=')
    if (x[0] === key) {
      x[1] = value
      kvp[i] = x.join('=')
      break
    }
  }

  if (i < 0) {
    kvp[kvp.length] = [key, value].join('=')
  }

  document.location.search = kvp.join('&')
}

export function getQueryStringValue(key) {
  if (canUseDOM()) {
    const regex = new RegExp(
      '^(?:.*[&\\?]' +
      encodeURIComponent(key).replace(/[\.\+\*]/g, '\\$&') +
      '(?:\\=([^&]*))?)?.*$',
      'i'
    )
    return decodeURIComponent(window.location.search.replace(regex, '$1'))
  } else {
    return null
  }
}

export function removeUrlParameter(url, parameter) {
  let urlParts = url.split('?')

  if (urlParts.length >= 2) {
    // Get first part, and remove from array
    let urlBase = urlParts.shift()

    // Join it back up
    let queryString = urlParts.join('?')

    let prefix = encodeURIComponent(parameter) + '='
    let parts = queryString.split(/[&;]/g)

    // Reverse iteration as may be destructive
    for (let i = parts.length; i-- > 0;) {
      // Idiom for string.startsWith
      if (parts[i].lastIndexOf(prefix, 0) !== -1) {
        parts.splice(i, 1)
      }
    }

    url = urlBase + '?' + parts.join('&')
  }

  return url
}

export const getDateDiffInDays = (date, dateToCompare) => {
  if (
    !date ||
    !dateToCompare ||
    (!isDate(date) && !isString(date)) ||
    (!isDate(dateToCompare) && !isString(dateToCompare))
  ) {
    return 0
  }

  const msPerDay = 1000 * 60 * 60 * 24
  const currentDate = isDate(date) ? date : new Date(date)
  const tmpDate = isDate(dateToCompare)
    ? dateToCompare
    : new Date(dateToCompare)
  const currentUtc = Date.UTC(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  )
  const tmpUtc = Date.UTC(
    tmpDate.getFullYear(),
    tmpDate.getMonth(),
    tmpDate.getDate()
  )

  return Math.floor((currentUtc - tmpUtc) / msPerDay)
}

export const onDomReady = (fn) => {
  const LOADING_STATE = 'loading'
  if (canUseDOM()) {
    if (document.readyState !== LOADING_STATE) {
      fn()
    } else {
      document.addEventListener('onreadystatechange', () => {
        if (document.readyState !== LOADING_STATE) fn()
      })
    }

    document.addEventListener('DOMContentLoaded', fn)
  }
}

export const get = (obj, deepKeyString, defaultValue = undefined) => {
  if (typeof deepKeyString !== 'string' || !obj) {
    return defaultValue
  }

  const deepKeyArray = deepKeyString.split('.')
  let currentResult = obj

  for (let i = 0; i < deepKeyArray.length; i += 1) {
    currentResult = currentResult[deepKeyArray[i]]

    if (typeof currentResult === 'undefined') {
      return defaultValue
    } else if (currentResult === null) {
      return currentResult
    }
  }

  return currentResult
}

export const getOriginFromUrl = (url) => {
  const pathArray = url.split('/')
  const [protocol, _, host] = pathArray
  return protocol + '//' + host
}

export function isWideContainerContext() {
  let widePageContext = false

  if (canUseDOM()) {
    const pathName = window.location.pathname
    widePageContext = pathName === '/' || pathName === '/accounts'
  }

  return widePageContext
}

export function populateArray(count, mapFn = noop) {
  return map(times(count, constant(null)), (_, idx) => mapFn(idx))
}

export function mergeLikedTracksMeta(response) {
  const { data } = response
  if (data.meta.likedTracks) {
    data.tracks.forEach((track) => {
      track.doesUserLike = data.meta.likedTracks.indexOf(track.id) !== -1
    })
  }
  return response
}

export function isAuthLocation() {
  return (
    canUseDOM() && location.pathname.substr(0, LINKS.AUTH.length) === LINKS.AUTH
  )
}

export function downloadFile(url, name) {
  const filename = name || url.match(/\/([^\/?]+)\?/)?.[1] || 'Unknown'

  const link = document.createElement('a')

  link.setAttribute('download', filename)
  link.setAttribute('href', url)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export async function requireExternalLibrary(url) {
  return new Promise((resolve) => {
    if (document.querySelector('script[src="' + url + '"]')) {
      resolve()
      return
    }
    const script = document.createElement('script')
    script.async = true
    script.type = 'application/javascript'
    script.src = url
    script.addEventListener('load', resolve)
    document.head.appendChild(script)
  })
}

export const pluralize = (word, quantity) =>
  quantity === 1 ? word : word + (word.match(/(?:[sxz]|[cs]h)$/i) ? 'es' : 's')

export const requestUpgrade = (
  redirect = true,
  successButtonUrl = null,
  successButtonLabel = null
) => {
  const params = {}
  if (successButtonUrl && successButtonLabel) {
    window.sessionStorage.setItem(
      'customSuccessButton',
      JSON.stringify({
        url: successButtonUrl,
        label: successButtonLabel
      })
    )
    params.customButton = '1'
  }
  if (redirect) {
    return gotoLocation(LINKS.AUTH_PLANS, params)
  } else {
    return getLocationHref(LINKS.AUTH_PLANS, params)
  }
}

export const requestLogin = (
  redirect = true,
  redirectUrl = null,
  successButtonLabel = null,
  params = {}
) => {
  if (redirectUrl) {
    window.sessionStorage.setItem(
      'loginRedirectData',
      JSON.stringify({
        url: redirectUrl,
        label: successButtonLabel
      })
    )

    params.customButton = '2'
  }

  params.tab = 'sign_in'

  if (redirect) {
    return gotoLocation(LINKS.AUTH, params)
  } else {
    return getLocationHref(LINKS.AUTH, params)
  }
}

export const requestSignUp = (
  redirect = true,
  redirectUrl = null,
  successButtonLabel = null,
  params = {}
) => {
  if (redirectUrl) {
    window.sessionStorage.setItem(
      'loginRedirectData',
      JSON.stringify({
        url: redirectUrl,
        label: successButtonLabel
      })
    )

    params.customButton = '2'
  }

  params.tab = 'sign_up'
  if (redirect) {
    return gotoLocation(LINKS.AUTH, params)
  } else {
    return getLocationHref(LINKS.AUTH, params)
  }
}

export const clearLoginRedirectData = () => {
  canUseDOM() && window.sessionStorage.removeItem('loginRedirectData')
}

export const clearCustomSuccessPaymentButton = () => {
  canUseDOM() && window.sessionStorage.removeItem('customSuccessButton')
}

export const getLoginRedirectData = () => {
  const item = window.sessionStorage.getItem('loginRedirectData')
  if (item) {
    try {
      return JSON.parse(item)
    } catch (e) {
      return null
    }
  }
  return null
}

export const getCustomSuccessPaymentButton = () => {
  const item = window.sessionStorage.getItem('customSuccessButton')
  if (item) {
    try {
      return JSON.parse(item)
    } catch (e) {
      return null
    }
  }
  return null
}

export const extractError = (e) => {
  const responseError = get(e, 'response.data.error')
  return responseError || String(e)
}

export const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    const pop = parts.pop()
    if (pop) {
      return pop.split(';').shift()
    }
  }
}

export const setCookie = (name, value, days = null) => {
  let expires

  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = `; expires=${date.toGMTString()}`
  } else {
    expires = ''
  }

  document.cookie = `${name}=${value}${expires}; path=/`
}

export const stdTimezoneOffset = () => {
  const now = new Date()
  const jan = new Date(now.getFullYear(), 0, 1)
  const jul = new Date(now.getFullYear(), 6, 1)
  return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset())
}

export const isDstObserved = () => {
  return new Date().getTimezoneOffset() < stdTimezoneOffset()
}

export const formatDateWithTimezone = (
  date,
  timezone = '+00:00',
  pattern = 'P'
) => {
  const utcDate = zonedTimeToUtc(date, timezone)
  return format(utcDate, pattern)
}

export const searchTree = (
  element,
  matcher,
  selectChildren = (element) => element.items
) => {
  if (matcher(element)) {
    return element
  }

  if (element.children !== null) {
    let result = null
    const children = selectChildren(element) || []
    for (let i = 0; result == null && i < children.length; i++) {
      result = searchTree(children[i], matcher)
    }
    return result
  }

  return null
}

export const humanFileSize = (bytes, separator = ' ') => {
  const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  let n = bytes
  let i = 0

  while (n >= 1000) {
    n /= 1024
    i++
  }

  // unit is gb or larger and n has decimals? show the decimals since they're significant
  const value = n % 1 && i > 2 ? n.toFixed(2) : String(Math.round(n))

  // parseFloat to get rid of any insignificant 0 decimals, e.g. 1.00, resulting from the `toFixed` method
  return `${parseFloat(value)}${separator}${units[i]}`
}

export function loadScript(tag, attributes) {
  return new Promise((resolve) => {
    if (document.getElementById(attributes.id)) {
      resolve()
      return
    }

    const element = document.createElement(tag)

    for (const [key, value] of Object.entries(attributes)) {
      element.setAttribute(key, value)
    }

    element.onload = function () {
      resolve()
    }

    document.head.appendChild(element)
  })
}

export const getEduHostnameByEnv = (env) => {
  if (env === 'production') {
    return 'https://edu.soundation.com'
  }

  return 'https://staging.edu.soundation.com'
}

export const thousandsToK = (num) => {
  return num > 999 ? (num / 1000).toFixed(1) + 'k' : num
}

export function trimLeadingAndTrailingSlash(str) {
  return str.replace(/^\/|\/$/g, '')
}

export function extractGroupsFromMemberships(denormalizedMemberships) {
  if (denormalizedMemberships && Array.isArray(denormalizedMemberships)) {
    return denormalizedMemberships.map((membership) => {
      if (!membership.group) return null

      return { ...membership.group, admin: membership.admin, membership: { id: membership.id } }
    }).filter(Boolean)
  }

  return denormalizedMemberships
}

/* @param type: 'follower' | 'followable' */
export function extractUsersFromFollowings(followings, type) {
  if (followings && followings.data) {
    if (type === 'followable') {
      return followings.data.map((d) => d.attributes.followable)
    }

    return followings.data.map((item) => followings.included.find((user) => user.id === item.relationships[type].data.id))
  }

  return followings
}

export function getCommunityEditMyTrackContextMenuItem(trackId) {
  return {
    label: 'Edit',
    icon: 'pen',
    href: LINKS.EDIT_MY_TRACK.replace(':id', trackId)
  }
}

export function parseNewsItemContent({ createdAt, news }) {
  const timestamp = dayjs(createdAt).fromNow()

  const stats = news ? [{ count: news.likesCount, icon: 'Favorite' }, { count: news.commentsCount, icon: 'Comments' }] : undefined

  const getTagIcon = (tag) => {
    if (tag === 'video') return 'video'
    if (tag === 'tutorial') return 'academy'
    if (tag === 'product_release') return 'Rocket'
    if (tag === 'news') return 'Speaker'
    if (tag === 'loops_and_samples') return 'Audio-sample'
    if (tag === 'mixing') return 'Audio-sample-edit'
    if (tag === 'instrument_preset') return 'virtual-instrument'
    if (tag === 'music_theory') return 'keys-or-notes'
    if (tag === 'tools') return 'Knobs'
    if (tag === 'beatmaking') return 'Beatmaking'
  }
  const getTagType = (tag) => {
    if (tag === 'video') return 'Video'
    if (tag === 'tutorial') return 'Tutorial'
    if (tag === 'product_release') return 'Product update'
    if (tag === 'news') return 'News'
    if (tag === 'instrument_preset') return 'Instrument presets'
    if (tag === 'loops_and_samples') return 'Loops & samples'
    if (tag === 'mixing') return 'Mixing & sound design'
    if (tag === 'music_theory') return 'Music theory & composition'
    if (tag === 'tools') return 'Tools & software'
    if (tag === 'beatmaking') return 'Beatmaking'
  }

  const audioPreview = news.audioPreviews ?? news.audioPreview ?? []

  return {
    body: news.content,
    img: { urlLarge: news.coverImageLargeUrl, urlXLarge: news.coverImageXlargeUrl, alt: news.title },
    title: news.title,
    timestamp,
    stats,
    tag: {
      icon: getTagIcon(news.tag),
      type: getTagType(news.tag)
    },
    externalUrl: news?.ctaUrl ? {
      text: news.ctaTitle,
      url: news.ctaUrl
    } : undefined,
    audioPreviews: Array.isArray(audioPreview) ? audioPreview : [audioPreview]
  }
}

export const STRAPI_MODEL = {
  PAGE: 'page',
  SITE_CONFIGURATION: 'site-configuration',
  SITE_FOOTER: 'site-footer',
  NAVIGATION: 'navigation'
}

export const nextFetchTags = {
  [STRAPI_MODEL.PAGE]: (id) => [`${STRAPI_MODEL.PAGE}:${id}`],
  [STRAPI_MODEL.SITE_CONFIGURATION]: () => [STRAPI_MODEL.SITE_CONFIGURATION],
  [STRAPI_MODEL.SITE_FOOTER]: (footerId) => [`${STRAPI_MODEL.SITE_FOOTER}:${footerId}`],
  [STRAPI_MODEL.NAVIGATION]: (id) => [`${STRAPI_MODEL.NAVIGATION}:${id}`]
}

// gets initials and color for a username to be used as avatar
export const getUsernameInitialsAndColor = (username) => {
  const colors = [
    '#6F3FD4',
    '#4739DE',
    '#3985DE',
    '#34C5C5',
    '#29C671',
    '#93D63F',
    '#E4CB42',
    '#E78B47',
    '#D43F3F',
    '#D43F6C',
    '#BF3FD4',
    '#923FD4'
  ]

  const white = 'white'
  const black = 'black'

  const textColorMap = new Map([
    ['#6F3FD4', white],
    ['#4739DE', white],
    ['#3985DE', black],
    ['#34C5C5', black],
    ['#29C671', black],
    ['#93D63F', black],
    ['#E4CB42', black],
    ['#E78B47', black],
    ['#D43F3F', white],
    ['#D43F6C', black],
    ['#BF3FD4', black],
    ['#923FD4', white]
  ])

  /**
   * The definitions of the colors starts at 32 (space), so we need to offset the charCode
   */
  const offset = 32
  const defaultLetter = 'u'
  const space = ' '

  const getLetters = () => {
    if (!username?.length) return { initials: defaultLetter, first: defaultLetter }
    const trimmed = username.trim()
    // usernames can have spaces. And in that case we want to display the first letter
    // of the first and second word.
    const [first, second] = !trimmed.length ? [defaultLetter] : trimmed.split(space)
    const firstLetter = first?.charAt(0) || defaultLetter
    return { initials: `${firstLetter}${second?.charAt(0) || ''}`, first: firstLetter }
  }

  const letters = getLetters()
  const getColor = () => {
    const first = letters.first === undefined ? defaultLetter : letters.first
    const charCode = first.charCodeAt(0)
    const index = charCode - offset
    const { length } = colors

    return colors[index % length]
  }

  const color = getColor()
  const textColor = textColorMap.get(color) || black

  return {
    letters,
    color,
    textColor
  }
}

export function toLocaleFormat(date, options = {}) {
  let language

  if (canUseDOM()) {
    language = navigator.languages ? navigator.languages[0] : navigator.language
  } else {
    language = 'en-US' // we can look at "Accept-Language" header in request
  }

  return new Intl.DateTimeFormat(language, options).format(dayjs(date).toDate())
}
