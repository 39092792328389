import { Component } from "react";
import BEM from "react-bem-helper";
import "./style.scss";
import { CarouselResponsive } from "@components/carousel/carousel";
import SingleViewTrack from "@components/carousel/components/single-view-track";
import PropTypes from "prop-types";
import renderTopControls from "../carousel/components/top-controls.jsx";
import createProvider from "../../store/provider";
import { connect } from "react-redux";
import { get } from "@common/utils";

const bem = BEM({
  prefix: "pfx-",
  name: "landing-featured-tracks",
});

class LandingFeaturedTracks extends Component {
  prepareCarouselView() {
    let finalTracks = null;

    const { tracks } = this.props;
    
    if (Array.isArray(tracks)) {
      finalTracks = tracks.map((carouselItem, arr, idx) => {
        return (
          <SingleViewTrack
            {...{
              id: carouselItem.id,
              key: idx,
              title: carouselItem.title,
              subtitle: carouselItem.user?.username || "",
              image: carouselItem.imageLargeUrl,
              songUrl: carouselItem.audioMp3Url,
              fileName: carouselItem.fileName,
              date: carouselItem.createdAt,
              duration: carouselItem.duration,
              directWaveformUrl: carouselItem.directWaveformUrl,
              profileLink: carouselItem.user?.profileLink || "",
              permalink: carouselItem.permalink,
              shortLinkId: carouselItem.shortLinkId,
            }}
          />
        );
      });
    }

    return finalTracks;
  }

  renderTopControls = (options) => {
    const { screenBreakPointIndex } = this.props;

    const iconSize = screenBreakPointIndex >= 3 ? 16 : 21;
    return (
      <div {...bem("carousel-controls")}>
        {renderTopControls({ ...options, iconSize, indicateActive: true })}
      </div>
    );
  };

  render() {
    const { title } = this.props;
    return (
      <div {...bem()}>
        <h3 {...bem("header")}>{title || "Featured tracks"}</h3>
        <CarouselResponsive
          theme="light"
          renderProgressBar={null}
          renderTopControls={this.renderTopControls}
          {...bem("featured-tracks")}
        >
          {this.prepareCarouselView()}
        </CarouselResponsive>
      </div>
    );
  }
}

LandingFeaturedTracks.propTypes = {
  tracks: PropTypes.array,
};

const mapStateToProps = (state) => ({
  screenBreakPointIndex: get(state, "app.screenBreakPointIndex"),
});

export default createProvider(connect(mapStateToProps)(LandingFeaturedTracks));
