import React, { useRef } from 'react'
import createProvider from '@store/provider'
import connect from 'react-redux/es/connect/connect'
import { useSelector } from 'react-redux'
import { selectCurrentUser } from '@store/selectors/backendData'
import { LINKS, MAX_IMAGE_SIZE_MB } from '@common/consts'
import { getSignatureForFileUpload, uploadToS3, updateAccountProfile } from '@common/http'
import { gotoLocation, getUsernameInitialsAndColor } from '@common/utils'
import TextField from '@components/text-field'
import Linkton from '@components/linkton'
import TextArea from '@components/text-area/text-area'
import CommunityTitle from '@containers/community/community-title'
import Label from '@components/label'
import Uploader from '@components/uploader/uploader'
import { noop } from 'lodash'
import * as styles from './EditProfile.module.scss'

const AvatarUploader = (props) => {
  const { onUploadRequest = noop, onUploadRejected = noop, uploaded = false, maxSizeKbs = MAX_IMAGE_SIZE_MB * 1024, username = '', className = '', existingPictureUrl, avatarFallback, name } = props
  const inputRef = useRef(null)

  const [uploadProgress, setUploadProgress] = React.useState(0)
  const [pictureUrl, setPictureUrl] = React.useState(existingPictureUrl)

  const handleUploadRequest = async (file) => {
    setUploadProgress(0.05)
    const { fields, filename, headers, method, url } = await getSignatureForFileUpload(file.name, file.type)

    await uploadToS3({ fields, headers, method, url, file, onProgress: (progressEvent) => setUploadProgress(progressEvent.loaded / progressEvent.total) })

    onUploadRequest(JSON.stringify({
      id: filename,
      metadata: {}
    }))

    setUploadProgress(1)
  }

  const renderPlaceholder = () => {
    const { letters, color, textColor } = getUsernameInitialsAndColor(username)
    const style = { width: '100%', height: '100%', position: 'absolute' }

    return pictureUrl ? <img style={style} src={pictureUrl} /> : <div className={styles.avatarInitial} style={{ backgroundColor: color, color: textColor, ...style }}><span className={styles.avatarInitialText}>{letters.initials}</span></div>
  }
  const removeAvatar = () => {
    setPictureUrl(avatarFallback)
    onUploadRequest(null)
    setUploadProgress(0)
  }

  const setAvatar = async (file) => {
    setPictureUrl(file.preview)
    return handleUploadRequest(file)
  }

  const hasNonDefaultAvatar = !!pictureUrl && !pictureUrl.includes('/missing.png')

  return (
    <div className={styles.avatarContainer}>
      <Uploader
        ref={inputRef}
        className={`${styles.avatar} ${className}`}
        name={name}
        theme='checkered'
        mime='.png,.jpeg,.jpg,.gif'
        moreThanOneErrorMessage={'You can upload only one image'}
        dropzoneIcon={''}
        placeholder={renderPlaceholder()}
        onUploadRequest={setAvatar}
        onRejected={onUploadRejected}
        uploaded={uploaded}
        progress={uploadProgress}
        maxSize={maxSizeKbs * 1024}
        filename=''
      />
      {!!hasNonDefaultAvatar && <Linkton className={styles.avatarBtn} type='outline' color='light' onClick={removeAvatar}>Remove</Linkton>}
      <Linkton className={styles.avatarBtn} color='secondary_light' onClick={() => inputRef.current?.open()}>{pictureUrl ? 'Change' : 'Upload'}</Linkton>
    </div>
  )
}

const EditProfile = (props) => {
  const { pageUrlSlug = '', description = '', instagramLink = '', tiktokLink = '', facebookLink = '', twitterLink = '', youtubeLink = '', username = '', realName = '', avatarLargeUrl, avatarFallback } = props

  const currentUser = useSelector(selectCurrentUser)

  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const [form, setForm] = React.useState({ username, realName, pageUrlSlug, description, facebookLink, twitterLink, instagramLink, tiktokLink, youtubeLink })
  const [error, setError] = React.useState('')

  const handleFormChange = (key, value) => {
    setForm((cur) => ({ ...cur, [key]: value }))
  }

  const handleSubmit = () => {
    const fixedForm = { ...form }

    // tiktok handles start with @
    if (!!fixedForm.tiktokLink && !fixedForm.tiktokLink.startsWith('@')) {
      fixedForm.tiktokLink = `@${fixedForm.tiktokLink}`
    }

    setIsSubmitting(true)
    updateAccountProfile({ userGuid: currentUser.guid, data: fixedForm })
      .then(() => {
        gotoLocation(`${LINKS.USER}/${form.pageUrlSlug}`)
      })
      .catch((e) => {
        setError(e.response.data.error)
      })
      .finally(() => setIsSubmitting(false))
  }

  const isValid = () => {
    return !!form.pageUrlSlug && !!form.username
  }

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <CommunityTitle className={styles.title} title='My profile' showOnDesktop />

        <div className={styles.innerContainer}>
          <div className={styles.form}>
            <Label label='PROFILE PICTURE' />
            <AvatarUploader
              name='user[avatar]'
              existingPictureUrl={avatarLargeUrl}
              avatarFallback={avatarFallback}
              username={form.username}
              onUploadRequest={(v) => handleFormChange('avatar', v)}
            />

            <Label label='USERNAME'>
              <TextField name='user[username]' value={form.username} placeholder='Enter a username' layout='dark' onChange={(v) => handleFormChange('username', v)} />
            </Label>

            <Label label='REAL NAME'>
              <TextField name='user[real_name]' placeholder='Enter a name' value={form.realName} layout='dark' onChange={(v) => handleFormChange('realName', v)} />
            </Label>

            <div>
              <Label label='PAGE URL SLUG' className={styles.slug}>
                <TextField name='user[profile_link]' value={form.pageUrlSlug} layout='dark' onChange={(v) => handleFormChange('pageUrlSlug', v)} />
              </Label>

              <small className={styles.fineprint}>
                Your public URL (only letters and numbers):
              </small>
              <small className={styles.fineprint}>https://soundation.com/user/{form.pageUrlSlug}</small>
              <br />
              <small className={styles.fineprint}>
                Warning: Changing your profile URL will have a negative effect on search engine ranking and result in broken links.
              </small>
            </div>

            <Label label='ABOUT (OPTIONAL)'>
              <TextArea name='user[about]' placeholder='Write a little bit about yourself' value={form.description} isTransparent={false} isModern layout='dark' onChange={(v) => handleFormChange('description', v)} maxLength={1000} />
            </Label>

            <Label label='LINK TO INSTAGRAM'>
              <TextField name='user[instagram]' placeholder='Instagram username' value={form.instagramLink} layout='dark' onChange={(v) => handleFormChange('instagramLink', v)} />
            </Label>

            <Label label='LINK TO TIKTOK'>
              <TextField name='user[tiktok]' placeholder='Tiktok username' value={form.tiktokLink} layout='dark' onChange={(v) => handleFormChange('tiktokLink', v)} />
            </Label>

            <Label label='LINK TO YOUTUBE'>
              <TextField name='user[youtube]' placeholder='YouTube username' value={form.youtubeLink} layout='dark' onChange={(v) => handleFormChange('youtubeLink', v)} />
            </Label>

            <Label label='LINK TO FACEBOOK'>
              <TextField name='user[facebook]' placeholder='Facebook username or user ID' value={form.facebookLink} layout='dark' onChange={(v) => handleFormChange('facebookLink', v)} />
            </Label>

            <Label label='LINK TO TWITTER'>
              <TextField name='user[twitter]' placeholder='Twitter username' value={form.twitterLink} layout='dark' onChange={(v) => handleFormChange('twitterLink', v)} />
            </Label>
          </div>

          <div>
            <div className={styles.error}>{error}</div>

            <div className={styles.actions}>
              <Linkton className={styles.button} type='outline' tag='anchor' href={`${LINKS.USER}/${pageUrlSlug}`}>View my page</Linkton>
              <Linkton className={styles.button} isDisabled={!isValid()} isLoading={isSubmitting} onClick={handleSubmit}>Save</Linkton>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default createProvider(connect(null, null)(EditProfile))
