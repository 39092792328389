import React, { useEffect, useRef, useState } from 'react'
import { updateAccountProfile } from '@common/http'
import { TextFieldControlled } from '@components/text-field'
import CommunityButton from '@components/community-button'
import createProvider from '@store/provider'
import { extractError } from '@common/utils'
import connect from 'react-redux/es/connect/connect'
import { setFlashMessageByValues as setFlashMessageByValuesAction } from '@store/actions/flashMessage'
import * as styles from './EditUserEmail.module.scss'

const EditUserEmail = (props) => {
  const { userGuid, email, setFlashMessageByValues } = props

  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [accountEmail, setAccountEmail] = useState(email)
  const [hasError, setHasError] = useState(false)
  const [serverError, serServerError] = useState(null)

  const emailBeforeEditing = useRef(accountEmail)

  // update email after fetching it from server
  useEffect(() => setAccountEmail(email), [email])

  // validate email
  useEffect(() => {
    // taken from zod validation
    const isValid = /^(?!\.)(?!.*\.\.)([A-Z0-9_'+\-\.]*)[A-Z0-9_+-]@([A-Z0-9][A-Z0-9\-]*\.)+[A-Z]{2,}$/i.test(accountEmail)
    setHasError(!isValid)
  }, [accountEmail])

  const startEditing = () => {
    setIsEditingEmail(true)
    emailBeforeEditing.current = accountEmail
  }
  const reverseChange = () => {
    setIsEditingEmail(false)
    setAccountEmail(emailBeforeEditing.current)
  }

  const handleSubmit = () => {
    if (hasError) return

    setIsEditingEmail(false)
    serServerError(null)
    updateAccountProfile({ data: { email: accountEmail }, userGuid })
      .then(() => {
        setFlashMessageByValues({ text: `We've sent an email to ${accountEmail}. Please click the link to change your account's email address` })
      })
      .catch((e) => serServerError(extractError(e)))
  }

  return (
    <div className={isEditingEmail ? '' : styles.container}>
      <div className={styles.emailContainer}>
        {isEditingEmail ? <TextFieldControlled className={styles.input} layout='dark' hasError={hasError} value={accountEmail} onChange={setAccountEmail} /> : (
          <div>
            <div className={styles.email}>{accountEmail}</div>
            {!!serverError && <div className={`${styles.serverError} ${styles.error}`}>{serverError}</div>}
          </div>
        )}
      </div>

      {isEditingEmail ? (
        <div className={styles.editContainer}>
          <div className={styles.error}>{hasError && 'Invalid email'}</div>

          <div className={styles.actions}>
            <CommunityButton fullWidthOnMobile={false} type='outline' onClick={reverseChange}>Cancel</CommunityButton>
            <CommunityButton fullWidthOnMobile={false} isDisabled={hasError} onClick={handleSubmit}>Done</CommunityButton>
          </div>
        </div>
      ) : <CommunityButton fullWidthOnMobile={false} color='secondary_light' onClick={startEditing}>Edit</CommunityButton>}
    </div>
  )
}

export default createProvider(connect(null, { setFlashMessageByValues: setFlashMessageByValuesAction })(EditUserEmail))
