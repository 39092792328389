import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { CTAButton, CTAButtonPropTypes } from "../../button/cta-button";
import { SIZE } from "../../linkton";

import { Bem } from "@common/utils";

import "./GetStartedCTABlock.style.scss";
import { BlockStyles } from "../../block-area/components/BlockStyles";
import { getTemplateLink } from "../../../common/strapi.helpers";

const cn = new Bem({
  name: "get-started-cta-block",
  prefix: "pfx-",
});

export const GetStartedCTABlockComponentId = "cta.get-started";

const blockStylesProps = {
  normal: {
    width: "wide",
  },
  cute: {
    width: "narrow",
  },
};

const ctaButtonProps = {
  normal: {
    size: SIZE.LARGE,
  },
  cute: {
    size: SIZE.MEDIUM,
  },
};

const GetStartedCTABlock = ({
  text,
  link,
  mode,
  className,
  isLast = false,
  overrideType,
  alternativeLink,
}) => {
  const modeOrDefault = mode || "normal";
  const modifiers = { [modeOrDefault]: true, last: isLast };

  const finalLink = useMemo(() => {
    if (link) {
      return {
        type: link.type,
        text: link.text || text,
        to: link.to,
      };
    }

    const { template, external } = alternativeLink || {};

    if (template?.templateId) {
      const { name, templateId, options } = template;
      return {
        type: alternativeLink.type,
        text: alternativeLink.text || text || name,
        url: getTemplateLink(templateId, options),
      };
    }

    if (external) {
      const { name, url } = external;
      return {
        type: alternativeLink.type,
        text: alternativeLink.text || text || name,
        url,
      };
    }
  }, [link, alternativeLink, text]);

  return (
    <div className={cn("wrapper", { last: isLast })}>
      <BlockStyles {...(blockStylesProps[modeOrDefault] || {})}>
        <div className={cn(undefined, modifiers, className)}>
          <h2 className={cn("text", modifiers)}>{text}</h2>
          <CTAButton
            {...(ctaButtonProps[mode] || {})}
            text={finalLink.text}
            type={overrideType || finalLink.type || "full"}
            to={finalLink.to}
            url={finalLink.url}
            className={cn("button")}
          />
        </div>
      </BlockStyles>
    </div>
  );
};

export const GetStartedCTABlockPropTypes = {
  id: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  link: CTAButtonPropTypes,
  alternativeLink: PropTypes.shape({
    overrideType: PropTypes.oneOf(["full", "outline", "hollow", "underline"]),
    external: PropTypes.shape({}),
    template: PropTypes.shape({}),
    text: PropTypes.string,
  }),

  mode: PropTypes.oneOf(["normal", "cute"]),
  className: PropTypes.string,
  isLast: PropTypes.bool,
};

GetStartedCTABlock.propTypes = PropTypes.shape(
  GetStartedCTABlockPropTypes
).isRequired;

export default GetStartedCTABlock;
