import React from 'react'
import '../remix-group-view/remix-group-view.scss'
import PropTypes from 'prop-types'
import { Bem, gotoStudio } from '@common/utils'
import { get } from 'lodash'
import createProvider from '../../store/provider'
import { connect } from 'react-redux'
import CollabGroupHero from './components/collab-group-hero'
import CollabGroupSidebar from './components/collab-group-sidebar'
import CrtContainer from '@components/crt-container'
import CollabGroupContent from './components/collab-group-content'
import DiscordWidgetBot from '@components/discord-widget-bot'
import MediaQuery from '@components/mediaQuery'
import './collab-group-view.scss'
import { denormalizeResponse, joinGroup } from '../../common/http'
import mobileDetect from '@common/mobile-detection'
import { LIMITATION_SIDEBAR } from '@containers/mobile-studio-limitation-sidebar/index'
import { sendAnalyticsEvent } from '../../common/analytics'
import { GTM_EVENTS, DISCORD } from '../../common/consts'
import Linkton, { COLOR, TYPE } from '../../components/linkton'
import Glitch from '../../common/glitch'

const GlitchText = new Glitch(null, { selector: '.pfx-glitchable, h3', percentage: 30, randomWeight: 0.3 })

const cn = new Bem({
  name: 'remix-group-view',
  prefix: 'pfx-'
})

class CollabGroupView extends React.Component {
  static propTypes = {
    isCurrentUserAdmin: PropTypes.bool,
    isCurrentUserGroupMember: PropTypes.bool,
    screenBreakPointIndex: PropTypes.number,
    isCurrentUserGroupFollower: PropTypes.bool,
    projectsCount: PropTypes.number,
    collaboratorsCount: PropTypes.number,
    groupData: PropTypes.shape({
      createdAt: PropTypes.string,
      description: PropTypes.string,
      followersCount: PropTypes.number,
      contestantsCount: PropTypes.number,
      groupLink: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.number,
      tracksCount: PropTypes.number,
      campaign: PropTypes.shape({
        artistName: PropTypes.string,
        description: PropTypes.string,
        endDate: PropTypes.string,
        heroImageUrl: PropTypes.string,
        id: PropTypes.number,
        startDate: PropTypes.string,
        trackCoverUrl: PropTypes.string,
        trackFileUrl: PropTypes.string,
        videoUrl: PropTypes.string,
        trackName: PropTypes.string,
        winnerAnnouncementAt: PropTypes.string,
        videoMp4Url: PropTypes.string,
        videoWebmUrl: PropTypes.string
      }),
      comments: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          createdAt: PropTypes.string,
          id: PropTypes.number,
          status: PropTypes.number,
          user: PropTypes.shape({
            avatarSmallUrl: PropTypes.string,
            id: PropTypes.number,
            profileLink: PropTypes.string,
            username: PropTypes.string
          })
        })
      ),
      contestants: PropTypes.arrayOf(
        PropTypes.shape({
          avatarSmallUrl: PropTypes.string,
          id: PropTypes.number,
          profileLink: PropTypes.string,
          username: PropTypes.string
        })
      ),
      news: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          createdAt: PropTypes.string,
          id: PropTypes.number,
          title: PropTypes.string,
          updatedAt: PropTypes.string
        })
      )
    })
  }

  rootRef = {
    current: null
  }
  timer = null

  constructor(props) {
    super(props)
    this.state = {
      isCurrentUserGroupMember: this.props.isCurrentUserGroupMember,
      groupData: denormalizeResponse(this.props.groupData)
    }
  }

  async startCollab() {
    const slug = this.state.groupData.campaign.slug
    const { currentUser } = this.props
    await sendAnalyticsEvent(GTM_EVENTS.GTM_STARTED_REMIX, 'Start Collab')
    gotoStudio(currentUser, false, slug)
  }

  gotoStudio = async () => {
    const groupId = this.state.groupData.groupLink
    const { isCurrentUserGroupMember } = this.state

    if (mobileDetect.mobile()) {
      this.props.openMobileLimitationSidebar(LIMITATION_SIDEBAR.NO_MOBILE_APP_REMIX)
      return
    }

    if (isCurrentUserGroupMember) {
      return this.startCollab()
    }

    try {
      await joinGroup(groupId)
      this.setState({ isCurrentUserGroupMember: true })
      this.startCollab()
    } catch (e) {
      this.startCollab()
    }
  }

  componentWillUnmount() {
    GlitchText.kill()
  }

  static mapStateToProps = (state) => {
    return {
      screenBreakPointIndex: get(state, 'app.screenBreakPointIndex'),
      currentUser: get(state, 'backendData.currentUser')
    }
  }

  goToDiscord() {
    window.open(DISCORD.inviteUrl, '_blank')
  }

  renderDiscrodLink = () => {
    return (
      <Linkton color={COLOR.OCEAN} type={TYPE.OUTLINE} onClick={this.goToDiscord} className={cn('invite-button')}>
        Join the conversation on Discord
      </Linkton>
    )
  }

  setContainerRef = (ref) => {
    GlitchText.setRoot(ref).initialize()
    return (this.rootRef.current = ref)
  }

  static CONTESTANTS_TO_SHOW_BY_BREAKPOINT = [24, 24, 20, 20, 20]
  static SHOW_ACCORDION_BY_BREAKPOINT = [false, false, false, true, true]

  render() {
    const {
      isCurrentUserAdmin,
      isCurrentUserGroupMember,
      screenBreakPointIndex,
      currentUser,
      isCurrentUserGroupFollower,
      projectsCount,
      collaboratorsCount
    } = this.props
    const { groupData } = this.state

    const {
      createdAt,
      followersCount,
      contestantsCount,
      groupLink: groupId,
      campaign: {
        artistName,
        description: campaignDescription,
        startDate,
        endDate,
        heroImageUrl,
        trackCoverUrl,
        trackFileUrl,
        videoUrl,
        trackName,
        winnerAnnouncementAt,
        slug,
        artistDescription,
        subtitle,
        name,
        videoMp4Url,
        videoWebmUrl,
        trackDuration,
        trackWaveformUrl
      },
      contestants,
      news: initialUpdates,
      comments: initialComments,
      id
    } = groupData

    const { CONTESTANTS_TO_SHOW_BY_BREAKPOINT, SHOW_ACCORDION_BY_BREAKPOINT } = CollabGroupView

    const contestantsToShow = CONTESTANTS_TO_SHOW_BY_BREAKPOINT[screenBreakPointIndex]
    const isAccordionEnabled = SHOW_ACCORDION_BY_BREAKPOINT[screenBreakPointIndex]

    return (
      <div className={cn('', '', 'collab-group')} ref={this.setContainerRef}>
        <CrtContainer className={cn('crt')} hasFlicker={false}>
          <CollabGroupHero
            startDate={startDate}
            endDate={endDate}
            winnerAnnouncementAt={winnerAnnouncementAt}
            heroImageUrl={heroImageUrl}
            collaboratorsCount={collaboratorsCount}
            projectsCount={projectsCount}
            currentUser={currentUser}
            isCurrentUserGroupFollower={isCurrentUserGroupFollower}
            groupId={groupId}
            isCurrentUserAdmin={isCurrentUserAdmin}
            isCurrentUserGroupMember={isCurrentUserGroupMember}
            id={id}
            slug={slug}
            subtitle={subtitle}
            name={name}
            videoMp4Url={videoMp4Url}
            videoWebmUrl={videoWebmUrl}
            gotoStudio={this.gotoStudio}
          />
          <div className={cn('main', null, 'container container--wider')}>
            <div className={cn('main-row', null, 'row')}>
              <div className={cn('main-content', null, 'col-xs-9')}>
                <CollabGroupContent
                  campaignDescription={campaignDescription}
                  canPostUpdates={isCurrentUserAdmin}
                  canDeleteComments={isCurrentUserAdmin}
                  currentUser={currentUser}
                  isMember={isCurrentUserGroupMember}
                  groupId={groupId}
                  slug={slug}
                  initialComments={initialComments}
                  initialUpdates={initialUpdates}
                  shouldShowGroupComments={false}
                  startDate={startDate}
                  endDate={endDate}
                  gotoStudio={this.gotoStudio}
                />
                <MediaQuery maxDevice='small'>
                  <DiscordWidgetBot className={cn('discord-chat')} />
                  {this.renderDiscrodLink()}
                </MediaQuery>
              </div>
              <div className={cn('main-sidebar', 'is-sticky-medium', 'col-xs-3')}>
                <CollabGroupSidebar
                  trackDuration={trackDuration}
                  trackDirectWaveformUrl={trackWaveformUrl}
                  trackName={trackName}
                  artistName={artistName}
                  trackCoverUrl={trackCoverUrl}
                  trackFileUrl={trackFileUrl}
                  videoUrl={videoUrl}
                  artistDescription={artistDescription}
                  contestants={contestants}
                  contestantsCount={contestantsCount}
                  isAccordionEnabled={isAccordionEnabled}
                  contestantsToShow={contestantsToShow}
                  groupId={groupId}
                  screenBreakPointIndex={screenBreakPointIndex}>
                  <MediaQuery minDevice='small'>
                    <DiscordWidgetBot className={cn('discord-chat')} />
                    {this.renderDiscrodLink()}
                  </MediaQuery>
                </CollabGroupSidebar>
              </div>
            </div>
          </div>
        </CrtContainer>
      </div>
    )
  }
}

export default createProvider(connect(CollabGroupView.mapStateToProps, null)(CollabGroupView))
