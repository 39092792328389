import React from 'react'
import { denormalizeResponse } from '@common/http'
import CommunityUserCard from '../community-user-card'
import CommunitySection from '../community-section'

const CommunityUsersSection = (props) => {
  const { users, showMoreLink, subHeader, label, className = '' } = props

  const denormalizedUsers = denormalizeResponse(users)

  const items = denormalizedUsers.map((user) => <CommunityUserCard key={user.id} user={user} />)

  return (
    <CommunitySection items={items} label={label} showMoreLink={showMoreLink} className={className} subHeader={subHeader} />
  )
}

export default CommunityUsersSection
