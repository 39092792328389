import React, { useState } from 'react'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import connect from 'react-redux/es/connect/connect'
import GenreList from '@containers/genre-list'
import Label from '@components/label'
import ImageUploader from '@components/image-uploader'
import TextField, { LAYOUT } from '@components/text-field'
import Linkton from '@components/linkton'
import TextArea from '@components/text-area/text-area'
import FormElement from '@components/form-element'
import { editMixdown, deleteMixdown, getSignatureForFileUpload, uploadToS3, denormalizeResponse } from '@common/http'
import { JUST_REQUIRED } from '@common/validator-models'
import { LINKS, MIXDOWN_STATUS } from '@common/consts'
import { isFreeAccount } from '@common/utils'
import CommunityTitle from '../community-title'
import CommunityToggle from '../community-toggle'
import * as styles from './CommunityEditTrack.module.scss'
import { useSelector } from 'react-redux'

const CommunityEditTrack = (props) => {
  const { mixdown: mixdownNormalized, genres } = props
  const mixdown = denormalizeResponse(mixdownNormalized)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uploadProgress, setUploadProgress] = useState(0)
  const isUploadingPicture = uploadProgress > 0 && uploadProgress < 1

  const currentUser = useSelector(selectCurrentUser)

  const [form, setForm] = useState({
    'mixdown[title]': mixdown.title,
    'mixdown[description]': mixdown.description,
    'mixdown[published]': !!mixdown.published,
    'mixdown[genre_id]': mixdown.genreId,
    'mixdown[allow_comments]': !!mixdown.allowComments,
    'mixdown[allow_download]': !!mixdown.allowDownload
  })
  const [error, setError] = useState('')

  const handlePickImage = async (file) => {
    setUploadProgress(0.05)
    const { fields, filename, headers, method, url } = await getSignatureForFileUpload(file.name, file.type)

    await uploadToS3({ fields, headers, method, url, file, onProgress: (progressEvent) => setUploadProgress(progressEvent.loaded / progressEvent.total) })

    setForm((cur) => ({
      ...cur,
      'mixdown[picture]': JSON.stringify({
        id: filename,
        metadata: {}
      })
    }))
    setUploadProgress(1)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const data = {
      mixdown: {
        title: form['mixdown[title]'],
        description: form['mixdown[description]'],
        published: form['mixdown[published]'],
        genre_id: form['mixdown[genre_id]'],
        allow_comments: form['mixdown[allow_comments]'],
        allow_download: form['mixdown[allow_download]'],
        image: form['mixdown[picture]']
      }
    }

    setIsSubmitting(true)
    editMixdown({ id: mixdown.id, data })
      .then(({ data }) => {
        window.location.href = data.data.links.self
      })
      .catch((e) => setError(e.response.data.error))
      .finally(() => setIsSubmitting(false))
    return false
  }
  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this track?')) {
      deleteMixdown({ id: mixdown.id })
        .then(() => {
          window.location.href = LINKS.TRACKS
        })
    }
  }

  const isValid = !!form['mixdown[title]']
  const isFailedBounce = mixdown.status === MIXDOWN_STATUS.ERROR
  const communitySettings = [
    { name: 'mixdown[published]', label: 'Visible to other users' },
    { name: 'mixdown[allow_comments]', label: 'Allow comments' },
    {
      name: 'mixdown[allow_download]',
      label: 'Allow download',
      disabled: isFreeAccount(currentUser.accountType),
      tooltip: isFreeAccount(currentUser.accountType) && {
        content: (
          <div>
            <p className={styles.tooltipContent}>Upgrade to enable downloading of tracks.</p>
            <div className={styles.tooltipBtn}><Linkton tag='anchor' href={LINKS.PRICING} target='_blank'>Upgrade</Linkton></div>
          </div>
        ),
        className: styles.tooltip
      }
    }
  ]

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div>
          <CommunityTitle title='Publish track' showOnDesktop />

          <div className={styles.uploaderContainer}>
            <Label label='COVER ART' />
            <ImageUploader
              name='mixdown[picture]'
              existingPictureUrl={mixdown.imageLargeUrl}
              className={styles.uploader}
              onUploadRequest={handlePickImage}
              uploadProgress={uploadProgress}
            />
          </div>

          <div className={styles.title}>
            <Label label='TITLE'>
              <FormElement
                validation={JUST_REQUIRED}
                field={'mixdown[title]'}
                onChange={value => setForm((cur) => ({ ...cur, 'mixdown[title]': value }))}
                errorSize='small'
              >
                <TextField
                  name='mixdown[title]'
                  placeholder='Enter a name'
                  value={form['mixdown[title]']}
                  layout={LAYOUT.DARK}
                />
              </FormElement>
            </Label>
          </div>

          <div className={styles.genre}>
            <Label label='GENRE'>
              <GenreList
                name='mixdown[genre_id]'
                options={genres}
                value={form['mixdown[genre_id]']}
                onChange={(({ value }) => setForm((cur) => ({ ...cur, 'mixdown[genre_id]': value })))}
              />
            </Label>
          </div>

          <div className={styles.description}>
            <Label label='DESCRIPTION'>
              <TextArea
                className={styles.descriptionInput}
                name='mixdown[description]'
                layout={'dark'}
                isTransparent={false}
                isModern
                placeholder='Describe your track in a few words'
                value={form['mixdown[description]']}
                onChange={(value) => setForm((cur) => ({ ...cur, 'mixdown[description]': value }))}
              />
            </Label>
          </div>

          <div className={styles.communitySettings}>
            <Label label='COMMUNITY SETTINGS' />
            {communitySettings.map((option) => (
              <CommunityToggle
                key={option.name}
                name={option.name}
                label={option.label}
                disabled={option.disabled}
                value={form[option.name]}
                tooltip={option.tooltip}
                onToggle={({ name, value }) => {
                  setForm((cur) => ({ ...cur, [name]: value }))
                }}
              />
            ))}
          </div>
        </div>

        <div>
          {!!error && <div className={styles.error}>{error}</div>}

          <div className={styles.actions}>
            <Linkton className={styles.button} onClick={handleDelete} type='outline' isDisabled={isUploadingPicture || isSubmitting}>Delete this track</Linkton>
            {!isFailedBounce && <Linkton className={styles.button} isDisabled={!isValid || isUploadingPicture} isSubmit isLoading={isUploadingPicture || isSubmitting}>Save</Linkton>}
          </div>
        </div>
      </form>
    </div>
  )
}

export default createProvider(connect(null)(CommunityEditTrack))
