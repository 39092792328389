import {
  setHighDevicePixelRatio,
  setMobileDevice,
  setSignInRedirect,
  setWindowScroll,
  setWindowSize,
} from "./store/actions/app";
import { canUseDOM, onDomReady } from "./common/utils";
import { debounce, throttle } from "lodash";
import createStore from "./store/index";
import mobileDetect from "@common/mobile-detection";
import { MessageHandler } from "./common/messageHandler";

export const PFX_REQUIRE_SIGN_IN = "data-pfx-require-sign-in";
const reduxStore = createStore();
const dispatchWindowSize = () => {
  setWindowSize(window.innerWidth, window.innerHeight)(reduxStore.dispatch);
  setMobileDevice(mobileDetect.mobile())(reduxStore.dispatch);
  MessageHandler.postSizeMessage();
  return dispatchWindowSize;
};

const dispatchWindowScroll = () => {
  setWindowScroll(window.scrollY)(reduxStore.dispatch);
  return dispatchWindowScroll;
};
const dispatchCurrentSignInRedirect = (el) => {
  const url = el.getAttribute(PFX_REQUIRE_SIGN_IN);
  setSignInRedirect(url === "true" ? window.location.href : url)(
    reduxStore.dispatch
  );
  return dispatchCurrentSignInRedirect;
};

if (canUseDOM()) {
  onDomReady(() => {
    const myResize = new ResizeObserver(debounce(dispatchWindowSize(), 50));
    myResize.observe(document.body);

    window.addEventListener("scroll", throttle(dispatchWindowScroll(), 50));

    document.body.addEventListener("click", (e) => {
      if (
        e.target.matches(`[${PFX_REQUIRE_SIGN_IN}], [${PFX_REQUIRE_SIGN_IN}] *`)
      ) {
        dispatchCurrentSignInRedirect(
          e.target.closest(`[${PFX_REQUIRE_SIGN_IN}]`)
        );
      }
    });

    if (typeof window.matchMedia === "function") {
      const listener = (e) => {
        if (e.matches) {
          reduxStore.dispatch(setHighDevicePixelRatio(true));
        } else {
          reduxStore.dispatch(setHighDevicePixelRatio(false));
        }
      };
      const mediaQuery = window.matchMedia("screen and (min-resolution: 2dppx");

      if (typeof mediaQuery.addEventListener === "function") {
        mediaQuery.addEventListener("change", listener);
      } else if (typeof mediaQuery.addListener === "function") {
        mediaQuery.addListener(listener);
      }
    }

    const setVh = () => {
      // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
      // Then we set the value in the --vh custom property to the root of the document
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVh();
    // We listen to the resize event
    window.addEventListener("resize", debounce(setVh, 50));
  });
}
