import AdminContent from '../../../components/admin-content/admin-content'
import SimpleAccordion from '../../../components/simple-accordion/simple-accordion'
import AlbumCoverWithPlayer from '../../../components/album-cover/album-cover'
import React from 'react'
import { Bem } from '../../../common/utils'
import '../../remix-group-view/containers/remix-sidebar.scss'
import GroupContestants from '../../remix-group-view/components/group-contestants.jsx'
import PropTypes from 'prop-types'
import VideoPlayer from '../../../components/video-player'

const cn = new Bem({
  name: 'remix-sidebar',
  prefix: 'pfx-'
})

export default class CollabGroupSidebar extends React.Component {
  static propTypes = {
    trackDuration: PropTypes.number,
    trackDirectWaveformUrl: PropTypes.string,
    trackFileUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    trackCoverUrl: PropTypes.string,
    trackName: PropTypes.string.isRequired,
    isAccordionEnabled: PropTypes.bool,
    artistName: PropTypes.string.isRequired,
    artistDescription: PropTypes.string.isRequired,
    contestantsCount: PropTypes.number.isRequired,
    contestantsToShow: PropTypes.number.isRequired,
    contestants: PropTypes.array.isRequired,
    groupId: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    children: PropTypes.node
  }

  static defaultProps = {
    members: [],
    isAccordionEnabled: true
  }

  render () {
    const {
      trackDuration,
      trackDirectWaveformUrl,
      trackFileUrl,
      videoUrl,
      trackCoverUrl,
      trackName,
      isAccordionEnabled,
      artistName,
      artistDescription,
      contestantsCount,
      contestantsToShow,
      contestants,
      groupId,
      children
    } = this.props
    return (
      <div className={cn('', '', 'collab-group')}>
        {children}
        {/*<div className={cn('cover-container')}>*/}
        {/*  {*/}
        {/*    videoUrl*/}
        {/*      ? <VideoPlayer {...{ videoUrl }} />*/}
        {/*      : <AlbumCoverWithPlayer*/}
        {/*        {...{*/}
        {/*          id: -1,*/}
        {/*          title: trackName,*/}
        {/*          username: artistName,*/}
        {/*          duration: trackDuration,*/}
        {/*          directWaveformUrl: trackDirectWaveformUrl,*/}
        {/*          songUrl: trackFileUrl,*/}
        {/*          coverUrl: trackCoverUrl,*/}
        {/*          playButton: 'always-visible'*/}
        {/*        }} />*/}
        {/*  }*/}
        {/*</div>*/}
        {/*<div className={cn('name')}>{trackName}</div>*/}
        {/*<div>{artistName}</div>*/}
        {/* <SimpleAccordion isEnabled={isAccordionEnabled}>
          <div className={cn('description')}>
            <AdminContent html={artistDescription} />
          </div>
          <GroupContestants
            contestantsCount={contestantsCount}
            contestantsToShow={contestantsToShow}
            contestants={contestants}
            groupId={groupId}
          />
        </SimpleAccordion> */}
      </div>
    )
  }
}
