'use client'

import React from 'react'
import PropTypes from 'prop-types'
import { Bem, Enum } from '../../common/utils'
import { debounce, compact } from 'lodash'
import Icon from '../icon'
import './style.scss'

export const SIZE = new Enum('SMALL', 'MEDIUM', 'LARGE', 'EXTRA_LARGE')

export const PADDING = new Enum(
  'SMALL',
  'MEDIUM-SMALL',
  'MEDIUM',
  'LARGE',
  0,
  25,
  50,
  75,
  100,
  125,
  150
)

export const TYPE = new Enum('OUTLINE', 'FULL', 'HOLLOW', 'UNDERLINED_LINK', 'TERTIARY')

export const HOVER = new Enum(
  'COLOR',
  'TRANSPARENCY',
  'UNDERSCORE',
  'OUTLINE',
  'NONE',
  'GLITCH'
)

export const COLOR = new Enum(
  'OCEAN',
  'KLEIN',
  'KLEIN_WHITE',
  'YELLOW',
  'PRIMARY',
  'SECONDARY',
  'GREY',
  'WHITE',
  'BLACK',
  'LIGHT',
  'SECONDARY_LIGHT',
  'DANGER'
)

export const TAG = new Enum('BUTTON', 'ANCHOR')

const cn = Bem({
  prefix: 'pfx-',
  name: 'linkton'
})

class Linkton extends React.Component {
  static propTypes = {
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconPlacement: PropTypes.oneOf(['left', 'right']),
    iconClassName: PropTypes.string,
    size: PropTypes.oneOfType([
      PropTypes.oneOf(SIZE.getAll()),
      PropTypes.number
    ]),
    padding: PropTypes.oneOf(PADDING.getAll()),
    type: PropTypes.oneOf(TYPE.getAll()),
    hover: PropTypes.oneOf(HOVER.getAll()),
    color: PropTypes.oneOf(COLOR.getAll()),
    tag: PropTypes.oneOf(TAG.getAll()),
    thickness: PropTypes.number,
    onClick: PropTypes.func,
    isDisabled: PropTypes.bool,
    isLoading: PropTypes.bool,
    isResponsive: PropTypes.bool,
    isSubmit: PropTypes.bool,
    isReset: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
      PropTypes.array
    ])
  };

  static defaultProps = {
    size: SIZE.MEDIUM,
    padding: PADDING.MEDIUM,
    type: TYPE.FULL,
    color: COLOR.PRIMARY,
    tag: TAG.BUTTON,
    isResponsive: false,
    isSubmit: false,
    isReset: false,
    thickness: 1
  };

  state = {
    hover: null
  };

  componentDidMount () {
    this.determineHover()
  }

  componentDidUpdate (prevProps) {
    if (prevProps !== this.props) {
      this.determineHover()
    }
  }

  determineHover () {
    const { type, hover } = this.props
    if (!hover) {
      let defaultHover
      switch (type) {
        case TYPE.OUTLINE:
          defaultHover = HOVER.TRANSPARENCY
          break
        case TYPE.FULL:
          defaultHover = HOVER.COLOR
          break
        case TYPE.HOLLOW:
          defaultHover = HOVER.TRANSPARENCY
        case TYPE.UNDERLINED_LINK:
          defaultHover = HOVER.NONE
      }

      this.setState({
        hover: defaultHover
      })
    } else {
      this.setState({
        hover
      })
    }
  }

  handleClick = debounce(
    (event) => {
      const { isLoading, onClick } = this.props
      if (!isLoading && onClick) {
        onClick(event)
      }
    },
    300,
    { leading: true, trailing: false }
  );

  getElementProps () {
    const {
      size,
      padding,
      type,
      hover: hoverProp,
      color,
      tag,
      thickness,
      onClick,
      isDisabled,
      isLoading,
      children,
      className,
      isResponsive,
      isSubmit,
      isReset,
      icon,
      iconSize,
      ...restProps
    } = this.props
    const { hover } = this.state

    return {
      className: cn(
        '',
        {
          [`size-${size}`]: true,
          [`padding-${padding}`]: true,
          [`type-${type}`]: true,
          [`hover-${hover}`]: true,
          [`color-${color}`]: true,
          disabled: isDisabled,
          loading: isLoading,
          responsive: isResponsive
        },
        className
      ),
      style: {
        '--thickness': `${thickness}px`
      },
      onClick: onClick && this.handleClick,
      disabled: isDisabled,
      ...restProps
    }
  }

  renderChildren () {
    const { children, icon, iconSize, iconPlacement = 'right', iconClassName = '' } = this.props

    return compact([,
      <div
        key='content'
        className={cn('child-wrapper', {
          'with-icon': !!icon,
          'with-only-icon': !!icon && !children
        })}
        data-text={typeof children === 'string' ? children : ''}
      >
        {children}
        {icon && (
          <Icon
            className={cn('icon', {
              alone: !children,
              left: iconPlacement === 'left'
            }, [iconClassName])}
            icon={icon}
            size={iconSize || 18}
          />
        )}
      </div>,
      this.renderSpinner()
    ])
  }

  render () {
    const { tag, isSubmit, isReset, forwardedRef } = this.props
    const buttonType = isSubmit ? 'submit' : (isReset ? 'reset' : 'button')

    if (tag === TAG.BUTTON) {
      return (
        <button ref={forwardedRef} type={buttonType} {...this.getElementProps()}>{this.renderChildren()}</button>
      )
    } else if (tag === TAG.ANCHOR) {
      return <a ref={forwardedRef} {...this.getElementProps()}>{this.renderChildren()}</a>
    }
  }

  renderSpinner () {
    return (
      <div key='spinner' className={cn('spinner', '', 'pfx-spinner')}>
        <div className='pfx-double-bounce1' />
        <div className='pfx-double-bounce2' />
      </div>
    )
  }
}

export default React.forwardRef((props, ref) => <Linkton {...props} forwardedRef={ref} />)
