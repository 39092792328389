import { useEffect, useRef, useState } from 'react'
import DropdownOrBottomSheet from '@components/dropdown-or-bottom-sheet'
import Icon from '@components/icon'
import TextField from '@components/text-field'
import Linkton from '@components/linkton'
import Spinner from '@components/spinner'
import { noop } from 'lodash'
import * as styles from './CommunityActionSearch.module.scss'

// renders as either a bottom sheet or a dropdown depending on screen size
export default function CommunityActionSearch ({ open, onClose, onToggle, title, toggler, onSearch, entityName }) {
  const [search, setSearch] = useState('')
  const [results, setResults] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const inputRef = useRef(null)

  const handleInputUpdate = (v) => {
    setSearch(v)
  }
  const handleSearch = async (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (isLoading) return

    setIsLoading(true)

    try {
      const searchResults = await onSearch(search)
      setResults(searchResults ?? [])
    } finally {
      setIsLoading(false)
    }
  }

  // focus input field on open
  useEffect(() => {
    if (open) {
      inputRef.current?.focus?.()
    }
  }, [open])

  return (
    <DropdownOrBottomSheet
      dropdownClassName={styles.dropdown}
      bottomSheetClassName={styles.bottomSheet}
      isOpen={open}
      onToggle={onToggle}
      toggler={toggler}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>{title}</div>
          <Icon icon='new-close' onClick={onClose} size={16} />
        </div>

        <form className={styles.searchContainer} onSubmit={handleSearch}>
          <TextField 
            ref={inputRef}
            className={styles.input}
            type='text'
            value={search}
            onChange={handleInputUpdate} 
            onKeyDown={(e) => {
              if (e.code === 'Enter') handleSearch(e)
            }}
            layout='dark'
            placeholder={`Search by ${entityName}`} 
          />
          <Linkton className={styles.searchBtn} iconClassName={styles.searchIcon} icon='new-search' iconPlacement='left' isSubmit onClick={noop}>Search</Linkton>
        </form>

        {isLoading && <Spinner />}

        {!isLoading && results && (
            results.length ? results.map((result) => {
              return (
                <div key={result.id} className={styles.row}>
                  <div className={styles.result}>
                    <img className={styles.avatar} src={result.img} alt={result.name} />
                    <div>
                      <a className={styles.resultName} href={result.link}>{result.name}</a>
                      {result.extraName && <span className={styles.extraName}>{result.extraName}</span>}
                    </div>
                  </div>
  
                  {!result.actionable ? <span className={styles.actionDone}>{result.actionDoneLabel}</span> : <Linkton onClick={result.onAction} className={styles.actionBtn} type='outline' padding='small'>{result.actionLabel}</Linkton>}
                </div>
              )
            }) : <p className={styles.noResults}><Icon className={styles.errorIcon} icon='error' size={16} /> Oops! No {entityName} found. Please try again.</p>
        )}
      </div>
    </DropdownOrBottomSheet>
  )
}
