'use client'

import React, { useState, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import './style.scss'
import RenderMarkdown from '../render-markdown'
import { bodyCn, bodyContainerCnFn, chevronCnFn, itemCn, rootCn, titleCn, titleContainerCn } from './index.cn'
import Icon from '../icon'

const Accordion = ({ items, isBodyMarkdown }) => {
  if (!items?.length) return null

  const bodyRefs = useRef({})

  const [openItemId, setOpenItemId] = useState()

  return (
    <div className={rootCn}>
      {items.map((item) => {
        const open = openItemId === item.id

        const handleItemClick = useCallback(() => {
          setOpenItemId((cur) => cur === item.id ? undefined : item.id)
        }, [])

        return (
          <div className={itemCn} key={item.id}>
            <div className={titleContainerCn} onClick={handleItemClick}>
              <h4 className={titleCn}>{item.title}</h4>
              <Icon className={chevronCnFn({ open })} icon='new-arrow-2-down' size={11} />
            </div>

            <div
              ref={(r) => {
                bodyRefs.current[item.id] = r
              }}
              className={bodyContainerCnFn({ open })}
              style={{ '--scroll-height': `${bodyRefs.current[item.id]?.scrollHeight}px` }}
            >
              {isBodyMarkdown
                ? <RenderMarkdown markdownContent={item.body} attributes={{ p: { className: bodyCn } }} />
                : <p className={bodyCn}>{item.body}</p>}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export const AccordionPropTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, title: PropTypes.string, body: PropTypes.string })),
  isBodyMarkdown: PropTypes.bool
}

Accordion.propTypes = PropTypes.shape(AccordionPropTypes).isRequired

export default Accordion
