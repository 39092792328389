import { useCallback, useState } from 'react'
import {
  getGroupComments, deleteGroupComment, denormalizeResponse
} from '@common/http'
import CommunityComments from '../community-comments'

function CommunityGroupComments (props) {
  const { groupId, isAdminUser, isGroupAdmin } = props

  const [comments, setComments] = useState([])

  const fetchPage = useCallback(async (page) => {
    const { data } = await getGroupComments(groupId, { page, items: 10 })
    const denormalizedComments = denormalizeResponse(data)
    setComments([...comments, ...denormalizedComments])
    return { nextPage: data.meta?.next_page }
  }, [comments])

  const handleDeleteComment = (commentId) => {
    if (window.confirm('Are you sure you want to delete this comment?')) {
      deleteGroupComment(groupId, commentId)
    }
  }

  return (
    <CommunityComments
      comments={comments}
      onDeleteComment={handleDeleteComment}
      fetchPage={fetchPage}
      isAdmin={isAdminUser || isGroupAdmin}
    />
  )
}

export default CommunityGroupComments
