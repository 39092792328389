import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import {Bem, gotoLocation, gotoAuth, canUseDOM} from '../../../common/utils'
import Button from '../../../components/button/index'
import * as React from 'react'
import '../../remix-group-view/containers/remix-hero.scss'
import './collab-group-hero.scss'
import PropTypes from 'prop-types'
import {followGroup, unfollowGroup} from '../../../common/http'
import {openMobileLimitationSidebar} from '@store/actions/app'
import createProvider from '@store/provider'
import {connect} from 'react-redux'
import BackgroundVideo from "../../../components/background-video/index";
import Linkton, { TYPE, HOVER, COLOR } from '../../../components/linkton'
import { get } from '@common/utils'
import MediaQuery from "../../../components/mediaQuery";

const cn = new Bem({
  name: 'remix-hero',
  prefix: 'pfx-'
})

dayjs.extend(relativeTime)

const images = {
  mobile: {
    placeholder: require('lqip-loader?emitFile=false!@images/halloweek/hero-mobile-still.jpg').preSrc,
    src: 'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero-mobile-still.jpg',
  },
  desktop: {
    placeholder: require('lqip-loader?emitFile=false!@images/halloweek/hero-still.jpg').preSrc,
    src: 'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero-still.jpg',
  }
}

class CollabGroupHero extends React.Component {
  static propTypes = {
    collaboratorsCount: PropTypes.number.isRequired,
    projectsCount: PropTypes.number.isRequired,
    groupId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    heroImageUrl: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    isCurrentUserGroupFollower: PropTypes.bool,
    isCurrentUserAdmin: PropTypes.bool,
    id: PropTypes.number,
    slug: PropTypes.string,
    subtitle: PropTypes.string,
    gotoStudio: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      isCurrentUserGroupFollower: this.props.isCurrentUserGroupFollower
    }
  }

  renderStatsItem = (label, count) => {
    return (
      <div className={cn('summary-stats-item')}>
        <div className={cn('summary-stats-count')}>{count}</div>
        <div className={cn('summary-stats-label', null, 'pfx--mono')}>{label}</div>
      </div>
    )
  }

  getPoster = () => {

    if (this.props.screenBreakPointIndex < 2) {
      return images.desktop
    } else if (this.props.screenBreakPointIndex < 4 || this.props.orientation === 'landscape') {
      return images.desktop
    } else {
      return images.mobile
    }
  }

  getVideo = () => {
    if (
        this.props.screenBreakPointIndex < 2
        || this.props.screenBreakPointIndex < 3
        || this.props.screenBreakPointIndex < 4 || this.props.orientation === 'landscape'
    ) {
      return [
        'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero.webm',
        'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero.mp4'
      ]
    } else {
      return [
        'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero-mobile.webm',
        'https://s3.amazonaws.com/soundation.misc/campaign_videos/halloweek/halloweek-hero-mobile.mp4'
      ]
    }
  }



  renderHeroImage = ({name, startDate, endDate, heroImageUrl, slug, currentUser, videoMp4Url, videoWebmUrl}) => {
    const poster = this.getPoster()

    return (
      <div className={cn('hero')}>
        <div className={cn('background')}>
          <div
              className={cn('video-overcontainer')}
          >
            <div
                className={cn('video-container')}
            >
              <BackgroundVideo
                  urls={ this.getVideo() }
                  poster={ poster.src }
                  className={cn('video')}
              />
            </div>
          </div>
        </div>

        <div className={cn('hero-container', null, 'container container--wider')}>
          <h1 className={cn('hero-header', '', 'pfx-glitchable')}>{name}</h1>
          <h2 className={cn('header2', '', 'pfx-glitchable')}>
            Get 7 days of free unlimited collaboration.
          </h2>
          <ul className={cn('hero-dates')}>
            <li className={cn('hero-date', '', 'pfx-glitchable')}>
              {startDate && dayjs(startDate).format('D MMMM YYYY')} - {endDate && dayjs(endDate).format('D MMMM YYYY')}
            </li>
          </ul>
        </div>
      </div>
    )
  }

  async handleFollow(isFollowing) {
    const {groupId, currentUser} = this.props

    if (!currentUser) {
      return gotoAuth()
    }

    this.setState({followButtonLoading: true})

    if (isFollowing) {
      await unfollowGroup(groupId)
    } else {
      await followGroup(groupId)
    }

    this.setState({
      followButtonLoading: false,
      isCurrentUserGroupFollower: !isFollowing
    })
  }

  renderSummaryPanel = ({
                          collaboratorsCount,
                          projectsCount,
                          slug,
                          currentUser,
                          isCurrentUserGroupFollower,
                          followButtonLoading,
                          isCurrentUserAdmin,
                          id,
                          subtitle
                        }) => {
    return (
      <div className={cn('summary', null, 'container container--wider')}>
        <div className={cn('summary-details')}>
          <div className={cn('summary-name', '', 'pfx-glitchable')}>{subtitle}</div>
        </div>
        <div className={cn('summary-stats')}>
          {this.renderStatsItem('Collaborations', projectsCount)}
          {this.renderStatsItem('Collaborators', collaboratorsCount)}
        </div>
        <div className={cn('summary-buttons')}>
          {isCurrentUserAdmin && (
            <Button onClick={() => gotoLocation(`/account/groups/${id}/edit`)} type='outline'>
              Edit group
            </Button>
          )}
          <Linkton
            type={TYPE.OUTLINE}
            hover={HOVER.GLITCH}
            color={COLOR.WHITE}
            size={18}
            isLoading={followButtonLoading}
            data-pfx-require-sign-in
            onClick={() => this.handleFollow(isCurrentUserGroupFollower)}
            className={cn('summary-button')}
          >
            {isCurrentUserGroupFollower ? 'Unfollow' : 'Follow'}
          </Linkton>
          <MediaQuery minDevice={"small"}>
            <Linkton
              type={TYPE.OUTLINE}
              hover={HOVER.GLITCH}
              size={18}
              data-pfx-require-sign-in
              onClick={this.props.gotoStudio}
            >
              Start a collaboration
            </Linkton>
          </MediaQuery>
        </div>
      </div>
    )
  }

  render() {
    const {
      collaboratorsCount,
      projectsCount,
      groupId,
      name,
      startDate,
      endDate,
      heroImageUrl,
      currentUser,
      isCurrentUserAdmin,
      id,
      slug,
      subtitle,
      videoMp4Url,
      videoWebmUrl
    } = this.props

    const {followButtonLoading, isCurrentUserGroupFollower} = this.state

    const style = canUseDOM() ? {'--vh': window.innerHeight / 100 + 'px'} : {}

    return (
      <div className={cn('', '', 'collab-group')} style={{style}}>
        {this.renderHeroImage({
          name,
          startDate,
          endDate,
          heroImageUrl,
          groupId,
          currentUser,
          slug,
          videoWebmUrl,
          videoMp4Url
        })}
        {this.renderSummaryPanel({
          collaboratorsCount,
          projectsCount,
          groupId,
          currentUser,
          followButtonLoading,
          isCurrentUserGroupFollower,
          isCurrentUserAdmin,
          id,
          slug,
          subtitle
        })}
      </div>
    )
  }
}

const mapState = (state) => ({
  screenBreakPointIndex: get(state, 'app.screenBreakPointIndex'),
  orientation: get(state, 'app.windowSize') >= get(state, 'app.windowHeight') ? 'landscape' : 'portrait'
})

const mapDispatchToProps = {
  openMobileLimitationSidebar
}

export default createProvider(
  connect(
    mapState,
    mapDispatchToProps
  )(CollabGroupHero)
)
