import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createProvider from '@store/provider'
import { gotoLocation, get, capitalizeFirstLetter } from '@common/utils'
import { removeSubscriptionCancellationRequested } from '@store/actions/backendData'
import { user as userModel } from '@models/user'
import { LINKS, ACCOUNT_TYPES, ACCOUNT_TYPES_IDX } from '@common/consts'
import CurrentPlan from '../../components/current-plan'
import CurrentAddons from '../../components/current-addons'
import {
  CURRENCIES_IDX,
  PAYMENT_METHOD
} from '../../common/consts'
import * as styles from './Subscriptions.module.scss'
import IconDark from '@images/soundation-logo-dark-bg.svg'
import europaLogo from '@images/reason-studios.png'

const subscriptionLogos = {
  AccountSubscription: IconDark,
  AddonSubscription: europaLogo
}

const getSubscriptionUpgradeInfo = (subscription, userCanUpgrade) => {
  if (subscription?.type === 'AddonSubscription') return undefined

  if (subscription?.status === 'non_renewing') {
    return 'Reactivate your subscription to avoid losing access to your projects, extra storage space, and sound library'
  }

  if (!subscription || userCanUpgrade) {
    return 'Upgrade now to get a bigger sound library, and increase storage space'
  }

  return undefined
}

const getStatus = (subscription) => {
  if (!subscription) return undefined

  if (subscription.inTrial || subscription.status === 'in_trial') {
    return 'trial'
  }

  return subscription.status
}
const getStatusText = (status) => {
  let text = status
  if (status === 'non_renewing') text = 'cancelled'

  return text ? capitalizeFirstLetter(text) : undefined
}
const getName = (subscription, accountType, period) => {
  if (subscription.name) return subscription.name

  if (subscription.type === 'AccountSubscription') {
    const type = capitalizeFirstLetter(ACCOUNT_TYPES_IDX[accountType])
    if (period) return `Soundation ${type} ${capitalizeFirstLetter(period)}`
    return `Soundation ${type}`
  }
}

export const mapSubscription = (subscription, accountType, options) => {
  const status = getStatus(subscription)
  const statusText = getStatusText(status)
  const canUpgradeAccount = accountType < ACCOUNT_TYPES.PRO
  const showNextPayment = !!subscription && (subscription.type === 'AccountSubscription' ? accountType !== ACCOUNT_TYPES.FREE : true)

  return {
    active: [subscription?.active === true, status === 'active'].includes(true),
    id: subscription?.id || '',
    description: getSubscriptionUpgradeInfo(subscription, canUpgradeAccount),
    logo: subscription ? subscriptionLogos[subscription.type] : undefined,
    type: subscription?.type,
    name: getName(subscription, accountType, options?.period),
    status,
    canUpgradeAccount,
    isCancelledOrNonRenewing:
      subscription?.cancellationScheduled || (status && ['cancelled', 'non_renewing'].includes(status)),
    token: subscription?.token || '',
    statusText,
    nextPayment: showNextPayment
      ? {
        date: status === 'trial' ? subscription.trialEnd : subscription.subscriptionEndDate || new Date(),
        price: subscription.nextBillingAmount || 0,
        currency: options?.currency || 'USD'
      }
      : null
  }
}

class Subscriptions extends React.PureComponent {
  handleSubscriptionReactivate = (subscriptionId) => {
    if (!this.props.loading) {
      this.props.removeSubscriptionCancellationRequested(subscriptionId)
    }
  }

  render() {
    const { user, account } = this.props
    const currencyText = CURRENCIES_IDX[account.currency]
    const addons = user.subscriptions.filter(sub => sub.type === 'AddonSubscription')
    const accountSubscription = user.subscriptions.find(sub => sub.type === 'AccountSubscription')

    const accountSub = mapSubscription({ ...accountSubscription, type: 'AccountSubscription' }, user.accountType, { currency: currencyText, period: account.currentPaymentPeriod })
    const addonsSubs = addons.map(addon => mapSubscription(addon, user.accountType, { currency: currencyText }))

    if (!account) return null

    return (
      <div className={styles.container}>
        <CurrentPlan
          subscription={accountSub}
          onUpgrade={() => gotoLocation(LINKS.PRICING)}
          onSubscriptionReactivate={this.handleSubscriptionReactivate}
        />
        {addons && addons.length > 0 && <CurrentAddons
          addons={addonsSubs}
          loading={this.props.loading}
          onSubscriptionReactivate={this.handleSubscriptionReactivate}
        />}
      </div>
    )
  }
}

Subscriptions.propTypes = {
  user: userModel.isRequired,
  loading: PropTypes.bool.isRequired,
  subscriptionEndDate: PropTypes.string,
  account: PropTypes.shape({
    currency: PropTypes.number.isRequired,
    currentPaymentAmount: PropTypes.number.isRequired,
    currentPaymentPeriod: PropTypes.oneOf(['monthly', 'yearly']).isRequired,
    nextBillingAmount: PropTypes.number.isRequired,
    currentPaymentMethod: PropTypes.oneOf([PAYMENT_METHOD.chargebee, PAYMENT_METHOD.stripe, PAYMENT_METHOD.paypal, PAYMENT_METHOD.none]),
    prizeCount: PropTypes.number
  })
}

const mapStateToProps = (state) => {
  return {
    user: get(state, 'backendData.currentUser'),
    loading: get(state, 'backendData.loading'),
    account: get(state, 'backendData.account'),
    newPricePlansEnabled: get(state, 'backendData.featureFlags.newPricePlans'),
    backendData: state.backendData
  }
}

const mapDispatchToProps = {
  removeSubscriptionCancellationRequested
}

export { Subscriptions }
export default createProvider(connect(mapStateToProps, mapDispatchToProps)(Subscriptions))
