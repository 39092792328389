import { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import * as uuid from 'uuid'
import * as styles from './BottomSheet.module.scss'

const id = 'bottom-sheet-anchor'
const SHEET_ID_ATTRIBUTE = 'sheet-id'

// action sheet which opens from the bottom of the screen. Used natively as an alternative to dropdowns on mobile
export default function BottomSheet (props) {
  const { onClose, isOpen, className = '', children } = props

  const instanceId = useRef(uuid.v4())
  const anchorRef = useRef(null)
  const [, forceReload] = useState(false)
  const [sheetRef, setSheetRef] = useState(null)

  // create the mobile dropdown anchor on mount if it doesn't exist
  useEffect(() => {
    let mobileDropdownAnchor = document.body.querySelector(`#${id}`)

    if (!mobileDropdownAnchor) {
      mobileDropdownAnchor = document.createElement('div')
      mobileDropdownAnchor.id = id

      document.body.appendChild(mobileDropdownAnchor)
    }

    anchorRef.current = mobileDropdownAnchor
    forceReload((val) => !val)
  }, [])

  const handleOutsideClick = useCallback((e) => {
    // we close the dropdown when clicking anywhere outside the dropdown, but not when we click the dropdown itself.
    // if we close the dropdown here, any handlers on dropdown items will not capture the click event so we don't close it here
    const isClickOutsideDropdown = sheetRef && !sheetRef.contains(e.target)
    const isInstanceOpen = anchorRef.current.getAttribute(SHEET_ID_ATTRIBUTE) === instanceId.current
    if (isClickOutsideDropdown && isInstanceOpen) {
      onClose()
    }
  }, [onClose, sheetRef])

  // resets anchor attributes only if it's a match with current instance
  const resetAnchorAttributes = useCallback(() => {
    if (anchorRef.current && anchorRef.current.getAttribute(SHEET_ID_ATTRIBUTE) === instanceId.current) {
      anchorRef.current.classList = []
      anchorRef.current.removeEventListener('click', handleOutsideClick)
      anchorRef.current.setAttribute(SHEET_ID_ATTRIBUTE, '')
    }
  }, [handleOutsideClick])

  // add/remove attributes to dom element on state change
  useEffect(() => {
    if (!anchorRef.current) return

    if (isOpen) {
      anchorRef.current.classList.add(styles.container)
      anchorRef.current.addEventListener('click', handleOutsideClick)
      anchorRef.current.setAttribute(SHEET_ID_ATTRIBUTE, instanceId.current)
    } else {
      resetAnchorAttributes()
    }
  }, [isOpen, handleOutsideClick, resetAnchorAttributes])

  // reset anchor on unmount
  useEffect(() => {
    return () => {
      resetAnchorAttributes()
    }
  }, [resetAnchorAttributes])

  const handleSheetRefAssignment = useCallback((r) => { setSheetRef(r) }, [])

  const renderSheet = useCallback(() => (
    isOpen ? (
      <div ref={handleSheetRefAssignment} className={`${styles.mobileDropdown} ${className}`}>
        <div className={styles.innerContainer}>
          {children}
        </div>
      </div>
    ) : null
  ), [children, isOpen, handleSheetRefAssignment])

  if (!anchorRef.current) return null

  return createPortal(renderSheet(), anchorRef.current)
}
