import React, { useCallback, useRef, memo, Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'
import { ControlledMenu, MenuItem, useClick } from '@szhsin/react-menu'
import { Bem, Enum } from '@common/utils'
import { extend } from 'lodash'
import './style.scss'

const ALIGNMENTS = new Enum('START', 'CENTER', 'END')
const DIRECTION = new Enum('BOTTOM', 'TOP', 'LEFT', 'RIGHT')

const cn = new Bem({
  name: 'dropdown',
  prefix: 'pfx-'
})

export const DropdownControlled = memo(({ isOpen = false, onToggle, toggle, closeOnInsideClick = true, closeOnOutsideClick = true, className = '', containerClassName = '', children, ...rest }) => {
  const ref = useRef(null)

  const open = useCallback(() => onToggle(true), [onToggle])
  const close = useCallback(() => onToggle(false), [onToggle])
  const anchorProps = useClick(isOpen, open)

  const Children = useMemo(() => {
    const childrenArray = React.Children.toArray(children)
    return childrenArray.map((child, i) => {
      if (!child) return null

      const extendedPropsChild = {
        key: i
      }

      const props = Object.assign({}, child.props, extendedPropsChild)

      const cloned = React.cloneElement(
        child,
        { ...props, className: cn('item', null, child.props.className) },
        child.props.children
      )

      return <MenuItem key={child.key || i}>{cloned}</MenuItem>
    })
  }, [children])

  const Toggle = useMemo(() => {
    return React.cloneElement(
      toggle,
      {
        ...toggle.props,
        ...anchorProps,
        onClick: (e) => {
          if (toggle.props.onClick) toggle.props.onClick(e)

          if (closeOnOutsideClick) {
            anchorProps.onClick(e)
          } else {
            // otherwise an open dropdown won't close when clicking the toggler
            onToggle(!isOpen)
          }
        },
        ref
      },
      toggle.props.children
    )
  }, [toggle, ref, anchorProps, closeOnOutsideClick, onToggle, isOpen])

  const handleItemClick = useCallback((e) => {
    e.keepOpen = !closeOnInsideClick
  }, [closeOnInsideClick])
  const handleClose = useCallback(({ reason }) => {
    if (closeOnOutsideClick === false && reason === 'blur') return
    close()
  }, [close, closeOnOutsideClick])

  return (
    <Fragment>
      {Toggle}

      <ControlledMenu
        className={containerClassName}
        menuClassName={cn(null, {}, className)}
        state={isOpen ? 'open' : 'closed'}
        onItemClick={handleItemClick}
        anchorRef={ref}
        onClose={handleClose}
        {...rest}
      >
        {Children}
      </ControlledMenu>
    </Fragment>
  )
})
DropdownControlled.displayName = 'DropdownControlled'

/** Uncontrolled dropdown
 * make sure to always pass memoized props to this Dropdown as the recomputation is expensive due to cloning passed items/children
 */
class Dropdown extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isMenuOpen: false
    }
  }

  render () {
    const menuOptions = extend({}, this.props, {
      isOpen: this.state.isMenuOpen,
      onToggle: (value) => this.setState({ isMenuOpen: value }),
      toggle: this.props.toggle
    })

    return (
      <DropdownControlled {...menuOptions} >
        {this.props.children}
      </DropdownControlled>
    )
  }
}

Dropdown.propTypes = DropdownControlled.propTypes = {
  align: PropTypes.oneOf([ALIGNMENTS.CENTER, ALIGNMENTS.START, ALIGNMENTS.END]),
  direction: PropTypes.oneOf([DIRECTION.TOP, DIRECTION.BOTTOM, DIRECTION.LEFT, DIRECTION.RIGHT]),
  portal: PropTypes.bool,
  gap: PropTypes.number,
  shift: PropTypes.number,
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func,
  /** toggle: has to be able to receive a forwarded "ref", in addition to onClick and onMouseDown handlers */
  toggle: PropTypes.node,
  closeOnInsideClick: PropTypes.bool,
  closeOnOutsideClick: PropTypes.bool,
  children: PropTypes.node
}

export default Dropdown
