import React, { useCallback, useEffect } from 'react'
import usePaginate from '@root/hooks/usePaginate'
import useDenormalizeCommunityTracks from '@root/hooks/community/useDenormalizeCommunityTracks'
import { getTracksForUser } from '@common/http'
import CommunitySubPage from '../community-sub-page'
import CommunityTrackCard from '../community-track-card'

// a subpage showing a specific user's tracks
const CommunityUserTracksSubPage = (props) => {
  const { guid, username } = props

  const fetcher = useCallback(async ({ page }) => {
    return getTracksForUser(guid, { page, items: 20 })
  }, [guid])
  const { isLoading, data: tracks, fetchNextPage: fetchTracks } = usePaginate({ fetcher })

  // fetch tracks on mount
  useEffect(fetchTracks, [])

  const denormalizedTracks = useDenormalizeCommunityTracks(tracks)

  return (
    <CommunitySubPage
      title={`"${username}" tracks`}
      fetchNextPage={fetchTracks}
      isLoading={isLoading}
      landscapeOnMobile
    >
      {denormalizedTracks.map(({ id, ...rest }) => <CommunityTrackCard key={id} mixdown={{ ...rest }} isSubPage />)}
    </CommunitySubPage>
  )
}

export default CommunityUserTracksSubPage
