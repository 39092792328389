import { canUseDOM } from '@common/utils'
import CommunityShareModal from '../community-share-modal'
import { LINKS } from '@common/consts'

export default function CommunityShareTrack (props) {
  const { isOpen, mixdown, onClose } = props
  const { title, selfLink: permalink, shortLinkId } = mixdown ?? {}

  const fullShortlink = (canUseDOM() ? window.location.origin : '') + `${LINKS.TRACK_SHORT}/${shortLinkId}`
  const playerLink = (canUseDOM() ? window.location.origin : '') + `${LINKS.PLAYER}/${shortLinkId}`

  return (
    <CommunityShareModal
      isOpen={isOpen}
      title={title}
      link={fullShortlink}
      embedLink={playerLink}
      facebookLink={encodeURIComponent(permalink)}
      twitterLink={encodeURIComponent(fullShortlink)}
      onClose={onClose}
    />
  )
}
