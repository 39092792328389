import { clamp, sampleSize } from 'lodash'
import './glitch.scss'

const defaultSettings = {
  /** Elements to apply glitch to */
  selector: '.pfx-glitchable',
  /** Glitch class */
  glitchClass: 'pfx-glitchable',
  /** Active class on glitched elements */
  activeClass: 'pfx-glitchable--on',
  /** Percentage of elements to glitch */
  percentage: 100,
  /** Random weight applied to percentage */
  randomWeight: 0,
  /** Length in ms each element should glitch */
  glitchLengthMs: 500,
  /** Length in ms when nothing glitches */
  waitMs: 500
}

class Glitch {
  selector = ''
  glitchClass = ''
  root = null
  glitchLengthMs = 500
  waitMs = 1000
  percentage = 100
  glitchedElements = []
  timer
  constructor(root, settings = defaultSettings) {
    const mergedSettings = { ...defaultSettings, ...settings }
    const { activeClass, percentage, glitchClass, randomWeight, waitMs, selector, glitchLengthMs } = mergedSettings
    this.selector = selector
    this.randomWeight = randomWeight
    this.glitchLengthMs = glitchLengthMs
    this.waitMs = waitMs
    this.root = root
    this.glitchClass = glitchClass
    this.allElements = this.root ? this.root.querySelectorAll(selector) : []
    this.activeClass = activeClass
    this.percentage = clamp(percentage, 0, 100)
  }

  wait = async (ms = 1000) => {
    await new Promise((resolve) => {
      this.timer = setTimeout(resolve, ms)
    })
  }

  stop = () => {
    for (const el of this.glitchedElements) {
      el.classList.remove(this.activeClass)
    }
    return this
  }

  initialize = () => {
    this.setGlitchedElements().applyGlitch().scheduleGlitchLoop()
  }

  scheduleGlitchLoop = async () => {
    await this.wait(Math.random() * this.waitMs)
    this.setGlitchedElements().applyGlitch()
    await this.wait(Math.random() * this.glitchLengthMs + this.glitchLengthMs)
    this.stop()
    this.scheduleGlitchLoop()
    return this
  }

  applyGlitchTo = (elem) => {
    if (!elem.getAttribute('data-text')) {
      elem.setAttribute('data-text', elem.textContent)
      elem.style.setProperty('--anim-delay', `${-Math.round(Math.random() * 600)}ms`)
    }

    elem.classList.add(this.activeClass, this.glitchClass)
    return this
  }

  applyGlitch = () => {
    for (const element of this.glitchedElements) {
      this.applyGlitchTo(element)
    }
    return this
  }

  setGlitchedElements = () => {
    if (!this.allElements || !this.root) return
    const random = 1 - Math.random() * this.randomWeight
    const nElementsToTake = Math.floor(this.allElements.length * ((random * this.percentage) / 100))
    const elements = sampleSize(this.allElements, nElementsToTake)
    this.glitchedElements = elements
    return this
  }

  setRoot = (root) => {
    if (!root) return
    this.root = root
    this.allElements = this.root.querySelectorAll(this.selector)
    return this
  }

  kill = () => {
    clearTimeout(this.timer)
  }
}

export default Glitch
