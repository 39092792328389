import { useCallback, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import createProvider from '@store/provider'
import { selectCurrentUser } from '@store/selectors/backendData'
import { LINKS, COMMUNITY_CAROUSEL_ITEMS } from '@common/consts'
import { extractGroupsFromMemberships } from '@common/utils'
import { denormalizeResponse, getUserGroups, getFollowedTracks, getNewsFeed, getTutorialsFeed, getTrendingGroups, getTrendingUsers, getTrendingTracks, getPieces, getProject } from '@common/http'
import useRequest from '@hooks/useRequest'
import Icon from '@components/icon'
import Spinner from '@components/spinner'
import PopoverSurvey from '@components/popover-survey'
import CommunityTracksSectionByType from '../community-tracks-section-by-type'
import CommunityGroupsSection from '../community-groups-section'
import CommunityProjectsSection from '../community-projects-section'
import CommunityUsersSection from '../community-users-section'
import CommunityFeedCard from '../community-feed-card'
import CommunitySection from '../community-section'
import * as styles from './CommunityHome.module.scss'

function CommunityHome () {
  const currentUser = useSelector(selectCurrentUser)
  const isStudioSupportedDevice = useSelector((state) => !state.app.mobileDevice)

  const fetchUserProjects = useCallback(() => getPieces({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingUserProjects, data: myProjectsData, makeRequest: makeUserProjectsRequest, nextPage: myProjectsNextPage } = useRequest({ callImmediately: false, callback: fetchUserProjects })
  const [myProjects, setMyProjects] = useState([])
  useEffect(() => {
    if (myProjectsData) setMyProjects(denormalizeResponse(myProjectsData))
  }, [myProjectsData])

  const fetchProject = useCallback((id) => {
    getProject(id).then((res) => {
      const project = denormalizeResponse(res.data)
      setMyProjects((prevProjects) => prevProjects.map((p) => p.id === id ? { ...project, project: { id, type: 'project' }, pieceType: 'Project' } : p))
    })
  }, [])

  const fetchUserGroups = useCallback(() => getUserGroups(currentUser.guid, { items: COMMUNITY_CAROUSEL_ITEMS }), [currentUser])
  const processUserGroupsResponse = useCallback((res) => {
    const denormalizedMemberships = denormalizeResponse(res.data)
    return { data: { ...res.data, data: extractGroupsFromMemberships(denormalizedMemberships) } }
  }, [])
  const { isLoading: isLoadingUserGroups, data: myGroupsData = { data: [], included: [] }, makeRequest: makeUserGroupsRequest, nextPage: myGroupsNextPage } = useRequest({ callImmediately: false, callback: fetchUserGroups, processResponse: processUserGroupsResponse })
  const myGroups = myGroupsData.data
  useEffect(() => {
    if (currentUser) {
      makeUserGroupsRequest()
      makeUserProjectsRequest()
    }
  }, [currentUser])

  const fetchTrendingGroups = useCallback(() => getTrendingGroups({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingTrendingGroups, data: trendingGroups = { data: [], included: [] }, nextPage: trendingGroupsNextPage } = useRequest({ callback: fetchTrendingGroups })

  const denormalizeFeed = useCallback((res) => ({ data: { ...res.data, data: denormalizeResponse(res.data) } }), [])
  const fetchNews = useCallback(() => getNewsFeed({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingFeedItems, data: feedItemsData = { data: [], included: [] }, nextPage: feedItemsNextPage } = useRequest({ callback: fetchNews, processResponse: denormalizeFeed })
  const feedItems = feedItemsData.data

  const fetchTutorials = useCallback(() => getTutorialsFeed({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingTutorials, data: tutorialsData = { data: [], included: [] }, nextPage: tutorialsNextPage } = useRequest({ callback: fetchTutorials, processResponse: denormalizeFeed })
  const tutorials = tutorialsData.data

  const fetchFollowedTracks = useCallback(() => getFollowedTracks({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingFollowedTracks, data: followedTracks = { data: [], included: [] }, makeRequest: makeFollowedTracksRequest, nextPage: followedTracksNextPage } = useRequest({ callImmediately: false, callback: fetchFollowedTracks })
  useEffect(() => {
    if (currentUser) makeFollowedTracksRequest()
  }, [currentUser])

  const fetchTrendingTracks = useCallback(() => getTrendingTracks({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingTrendingTracks, data: trendingTracks = { data: [], included: [] }, nextPage: trendingTracksNextPage } = useRequest({ callback: fetchTrendingTracks })

  const fetchTrendingUsers = useCallback(() => getTrendingUsers({ items: COMMUNITY_CAROUSEL_ITEMS }), [])
  const { isLoading: isLoadingTrendingUsers, data: trendingUsers = { data: [], included: [] }, nextPage: trendingUsersNextPage } = useRequest({ callback: fetchTrendingUsers })

  const isShowingMyGroups = !!currentUser && myGroups.length > 0

  return (
    <div>
      <PopoverSurvey />
      {!isStudioSupportedDevice ? (
        <div className={`${styles.studioWarning} ${styles.section} ${styles.sectionFirst}`}><Icon size={21} icon='round-warning' /> Projects can't be created on mobile. Head over to your desktop computer and get creative.</div>
      ) : (
        <CommunityProjectsSection className={`${styles.section} ${styles.sectionFirst}`} pieces={myProjects} label='My projects' showMoreLink={!!myProjectsNextPage && LINKS.MY_PROJECTS} subHeader={isLoadingUserProjects && <Spinner />} reloadProject={fetchProject} reloadAll={makeUserProjectsRequest} />
      )}
      {isShowingMyGroups && <CommunityGroupsSection className={styles.section} groups={myGroups} label='My groups' showMoreLink={!!myGroupsNextPage && LINKS.MY_GROUPS} denormalize={false} subHeader={isLoadingUserGroups && <Spinner />} />}
      <CommunityGroupsSection className={styles.section} groups={trendingGroups} label='Trending groups' showMoreLink={!!trendingGroupsNextPage && LINKS.TRENDING_GROUPS} subHeader={isLoadingTrendingGroups && <Spinner />} />
      {!!feedItems.length && <CommunitySection className={styles.section} label='News' showMoreLink={!!feedItemsNextPage && LINKS.FEED} items={feedItems.map((item) => <CommunityFeedCard key={item.id} item={item} />)} subHeader={isLoadingFeedItems && <Spinner />} />}
      {!!tutorials.length && <CommunitySection className={styles.section} label='Tutorials' showMoreLink={!!tutorialsNextPage && LINKS.TUTORIALS} items={tutorials.map((item) => <CommunityFeedCard key={item.id} item={item} />)} subHeader={isLoadingTutorials && <Spinner />} />}
      {!!currentUser && !!followedTracks.data.length && <CommunityTracksSectionByType className={styles.section} followedTracks={followedTracks} isLoading={isLoadingFollowedTracks} showMore={!!followedTracksNextPage} />}
      <CommunityTracksSectionByType className={styles.section} trendingTracks={trendingTracks} isLoading={isLoadingTrendingTracks} showMore={!!trendingTracksNextPage} />
      <CommunityUsersSection className={styles.section} users={trendingUsers} label='Trending users' showMoreLink={!!trendingUsersNextPage && LINKS.TRENDING_USERS} subHeader={isLoadingTrendingUsers && <Spinner />} />
    </div>
  )
}

export default createProvider(connect(null)(CommunityHome))
