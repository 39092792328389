import React from 'react'
import {connect} from "react-redux";
import createProvider from "@store/provider";
import InvitePage from './index'
import {getAccountReferral, getAccountReferredUsers} from "../../common/http";
import {get} from '../../common/utils'

const withDataSource = (InvitePage) => {
  class InvitePageWithDataSoruce extends React.Component {

    constructor(props) {
      super(props)
      this.fetchInvities()
      this.fetchReferral()
    }

    state = {
      invitesLoading: true,
      referralLoading: true,
      referralLoaded: false,
      invitesLoaded: false,

      invites: [],
      referral: null,
      initialInvitiesTotal: 0,
      initialInvitiesPerPage: 5
    }

    async fetchReferral() {
      this.setState({referralLoading: true})

      try {
        const res = await getAccountReferral()
        this.setState({referralLoading: false, referral: res.data.referral, referralLoaded: true})
      } catch(e) {
        this.setState({referralLoading: false})
      }
    }

    async fetchInvities() {
      const {initialInvitiesPerPage} = this.state
      this.setState({invitesLoading: true})

      try {
        const res = await getAccountReferredUsers({page: 1, items: initialInvitiesPerPage})
        this.setState({invitesLoading: false, invites: res.data.users, invitesLoaded: true, initialInvitiesTotal: res.data.meta.total})
      } catch(e) {
        this.setState({invitesLoading: false})
      }
    }

    render() {
      const {
        invitesLoading,
        referralLoading,
        invites,
        referral,
        invitesLoaded,
        referralLoaded,
        initialInvitiesTotal,
      } = this.state;

      const {currentUser, referralJson: {prizesCount}} = this.props

      const dataProps = {
        invitesLoading,
        referralLoading,
        invites,
        referral,
        invitesLoaded,
        referralLoaded,
        initialInvitiesTotal,
        currentUser,
        prizesCount: prizesCount
      }

      return (
        <InvitePage
          {...this.props}
          {...dataProps}/>
      )
    }
  }

  const mapStateToProps = state => {
    return {
      currentUser: get(state, 'backendData.currentUser')
    }
  }


  return createProvider(connect(mapStateToProps, null)(InvitePageWithDataSoruce))
}

export default withDataSource(InvitePage)
