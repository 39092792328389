import React from 'react'
import PropTypes from 'prop-types'
import { Bem, gotoLocation } from '../../../common/utils'
import './collab-group-leaderboard.scss'
import { slice } from 'lodash'

const cn = new Bem({
  name: 'collab-group-leaderboard',
  prefix: 'pfx-'
})

class LeaderboardItem extends React.PureComponent {
  handleOnClick = () => {
    gotoLocation(`/projects/${this.props.id}`)
  }

  render() {
    const { number, id, title, maxActiveUsersCount = 0, totalUsersJoined = 0 } = this.props
    return (
      <li className={cn('item')} key={id} onClick={this.handleOnClick}>
        <label className={cn('number')}>{number}</label>
        <div className={cn('item-content')}>
          <label className={cn('item-label')}>{title}</label>
          <span className={cn('item-collaborators')}>{maxActiveUsersCount} collaborated live. {totalUsersJoined} joined.</span>
        </div>
      </li>
    )
  }
}

class CollabGroupLeaderboard extends React.Component {
  render() {
    const { title, show, items = [] } = this.props

    const sliced = slice(items, 0, show)

    return (
      <div className={cn('', '', 'collab-group')}>
        {title ? <h2 className={cn('title')}>{title}</h2> : null}

        <ul className={cn('grid')}>
          {sliced.map(({ id, ...rest }, index) => {
            const props = {
              number: index + 1,
              id
            }
            return <LeaderboardItem key={id} {...rest} {...props} />
          })}
        </ul>
      </div>
    )
  }
}

CollabGroupLeaderboard.propTypes = {
  title: PropTypes.string,
  show: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      maxActiveUsersCount: PropTypes.number,
      totalUsersJoined: PropTypes.number
    })
  )
}

CollabGroupLeaderboard.defaultProps = {
  show: 10,
  items: []
}

export default CollabGroupLeaderboard
