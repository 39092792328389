import React, { useMemo, useRef } from 'react'
import { MIXDOWN_STATUS, LINKS } from '@common/consts'
import useOpen from '@hooks/useOpen'
import useCommunityTrackLike from '@hooks/community/useCommunityTrackLike'
import uuid from 'uuid'
import CommunityShareTrack from '../community-share-track'
import CommunityCard from '../community-card'
import * as styles from './CommunityTrackCard.module.scss'

const CommunityTrackCard = React.forwardRef((props, ref) => {
  const { mixdown, contextMenuItems, isSubPage = false } = props

  const uniquePlayerId = useRef(uuid.v4()).current
  const { toggle: toggleTrackLike, isLiked } = useCommunityTrackLike({ fullTrackLink: mixdown.selfLink, initialIsLiked: mixdown.currentUserLiked })
  const { isOpen: isShareOpen, close: closeShareModal, open: openShareModal } = useOpen()

  if (!mixdown.user) return null

  const isMixdownReady = mixdown.status === MIXDOWN_STATUS.DONE
  const isMixdownPublic = isMixdownReady && mixdown.published

  const stats = useMemo(() => {
    const isBeingProcessed = [MIXDOWN_STATUS.WAITING, MIXDOWN_STATUS.PROCESSING].includes(mixdown.status)
    if (isBeingProcessed) return [{ count: 'Being processed...', icon: 'Cog', className: styles.mixinNotPublic }]

    const hasErrorPublishing = mixdown.status === MIXDOWN_STATUS.ERROR
    if (hasErrorPublishing) return [{ count: 'Error while publishing', icon: 'error', className: `${styles.mixinNotPublic} ${styles.errorPublishing}` }]

    const isPrivate = isMixdownReady && !mixdown.published
    if (isPrivate) return [{ count: 'Only visible to you', icon: 'Eye-hide', className: styles.mixinNotPublic }]

    return [
      { count: mixdown.plays, icon: 'Play-outline' },
      { count: mixdown.likesCount, icon: 'Favorite' },
      { count: mixdown.commentsCount, icon: 'Comments' }
    ]
  }, [mixdown, isMixdownReady])

  return (
    <div ref={ref}>
      <CommunityShareTrack mixdown={mixdown} isOpen={isShareOpen} onClose={closeShareModal} />

      <CommunityCard
        isSubPage={isSubPage}
        picture={{ src: mixdown.imageLargeUrl, alt: mixdown.title }}
        audioFile={isMixdownReady && {
          id: mixdown.id,
          songUrl: mixdown.audioMp3Url,
          fileName: mixdown.fileName,
          isDisabled: !isMixdownReady,
          uniquePlayerId
        }}
        link={isMixdownReady ? mixdown.selfLink || LINKS.USER_TRACK.replace(':profileLink', mixdown.user.profileLink).replace(':mixdownPermalink', mixdown.permalink) : ''}
        title={mixdown.title}
        subtitle={mixdown.user.username}
        subtitleUrl={mixdown.user.selfLink || LINKS.USER_PAGE.replace(':profileLink', mixdown.user.profileLink)}
        stats={stats}
        actions={isMixdownPublic ? [
          { icon: isLiked ? 'Favorite-solid' : 'Favorite', label: 'Like', onClick: toggleTrackLike, disabled: !isMixdownReady },
          { icon: 'Share', label: 'Share', onClick: openShareModal, disabled: !isMixdownReady }
        ] : []}
        contextMenuItems={contextMenuItems}
      />
    </div>
  )
})

CommunityTrackCard.displayName = 'CommunityTrackCard'
export default CommunityTrackCard
