import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import React from "react";
import "./single-view-track.scss";
import { withBindHeightToWidth } from "./with-bind-height-to-width";
import { withPlayer } from "./with-player";
import { LINKS } from "../../../common/consts";
import LazyImage from "@components/lazy-image";

const cn = new Bem({
  name: "carousel-track",
  prefix: "pfx-",
});

dayjs.extend(relativeTime);

export default class SingleViewTrack extends React.Component {
  static propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    date: PropTypes.string,
    songUrl: PropTypes.string,
    fileName: PropTypes.string,
    permalink: PropTypes.string,
    profileLink: PropTypes.string,
    shortLinkId: PropTypes.string,
    duration: PropTypes.number,
    directWaveformUrl: PropTypes.string,
    doesUserLike: PropTypes.bool,
    isCollab: PropTypes.bool,
  };

  static TrackImage = class extends React.Component {
    render() {
      const { image, date, href, title } = this.props;

      return (
        <div className={cn("image")}>
          {href ? (
            <a href={href} target={"_blank"} rel="noopener noreferrer">
              <LazyImage src={image} alt={title} />
            </a>
          ) : (
            <LazyImage src={image} alt={title} />
          )}
          <div className={cn("date")}>{dayjs(date).fromNow()}</div>
        </div>
      );
    }
  };

  static TrackImageWithPlayer = withBindHeightToWidth(
    withPlayer(SingleViewTrack.TrackImage)
  );

  static NoPlayer = withBindHeightToWidth(SingleViewTrack.TrackImage);

  render() {
    const self = SingleViewTrack;
    const {
      image,
      date,
      id,
      title,
      subtitle,
      songUrl,
      fileName,
      style,
      profileLink,
      permalink,
      shortLinkId,
      directWaveformUrl,
      duration,
      doesUserLike,
      isCollab,
    } = this.props;
    const TrackView = isCollab ? self.NoPlayer : self.TrackImageWithPlayer;
    const href = isCollab
      ? `/projects/${id}`
      : `${LINKS.USER}/${profileLink}/track/${permalink}`;
    return (
      <div className={cn()} style={style}>
        <TrackView
          {...{
            id,
            username: subtitle,
            image,
            date,
            songUrl,
            fileName,
            title,
            directWaveformUrl,
            duration,
            permalink,
            profileLink,
            shortLinkId,
            doesUserLike,
            href: isCollab ? href : void 0,
          }}
        />
        <a className={cn("title")} href={href}>
          {title}
        </a>
        <a href={`${LINKS.USER}/${profileLink}`} className={cn("subtitle")}>
          {subtitle}
        </a>
      </div>
    );
  }
}
