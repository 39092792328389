import { useState } from 'react'
import { LINKS } from '@common/consts'
import { followUser, acceptGroupInvitation } from '@common/http'
import { format, parseISO } from 'date-fns'
import Markdown from 'react-markdown'
import Linkton from '@components/linkton'
import * as styles from './Notification.module.scss'

const Notification = (props) => {
    const { notification, onMarkRead } = props

    const [hasTakenAction, setHasTakenAction] = useState(false)
    const [isRead, setIsRead] = useState(notification.read)

    const handleMarkAsRead = () => {
        if (isRead) return

        setIsRead(true)
        onMarkRead(notification.id)
    }

    const handleAction = async () => {
        const { kind } = notification

        if (kind === 'new_group_invitation') {
            await acceptGroupInvitation(notification.target.groupLink)
            window.location.href = LINKS.GROUP_PAGE.replace(':groupLink', notification.target.groupLink)
        }
        if (kind === 'new_follow_user') {
            followUser(notification.author.profileLink)
        }
        if (notification.kind === 'collab_join_request' || notification.kind === 'collab_request_accept') {
            const projectLink = LINKS.STUDIO_PROJECT.replace(':id', notification.target.joinable.id)

            if (notification.kind === 'collab_join_request') {
                window.location.href = `${projectLink}?openShareModal=true`
            } else {
                window.location.href = projectLink
            }
        }

        if (notification.kind === 'collab_invitation') {
            window.location.href = `${LINKS.MEMBERSHIPS}/${notification.target.id}`
        }
        if (notification.kind === 'project_message') {
            const projectLink = LINKS.STUDIO_PROJECT.replace(':id', notification.target.id)
            window.location.href = projectLink
        }

        handleMarkAsRead()
        setHasTakenAction(true)
    }

    const getCtaLabel = () => {
        if (hasTakenAction) return undefined

        switch (notification.kind) {
            case 'collab_invitation':
            case 'collab_join_request':
            case 'collab_request_accept':
            case 'project_message':
                return 'Go to project'
            case 'new_group_invitation':
                return 'Accept'
            case 'new_follow_user':
                return 'Follow back'
            case 'new_follow_group':
            default:
                return undefined
        }
    }

    const getMessage = () => {
        switch (notification.kind) {
            case 'collab_invitation':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> invited you to collaborate on <strong>{notification.target.joinable.title}</strong></p>
            case 'collab_join_request':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> requested access to collaborate on <strong>{notification.target.joinable.title}</strong></p>
            case 'collab_request_accept':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> gave you access to collaborate on <strong>{notification.target.joinable.title}</strong></p>
            case 'project_message':
                return <p><strong>{notification.extras.unread}</strong> chat messages have been added to <strong>{notification.target.title}</strong></p>
            case 'new_group_invitation':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> invited you to <a href={notification.target.selfLink}><strong>{notification.target.name}</strong></a></p>
            case 'new_follow_user':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> started to follow you.</p>
            case 'new_follow_group':
                return <p><strong><a href={notification.author.selfLink}>{notification.author.username}</a></strong> started to follow <a href={notification.target.selfLink}><strong>{notification.target.name}</strong></a>.</p>
            default:
                return notification.message ? <Markdown>{notification.message}</Markdown> : undefined
        }
    }

    const renderThumbnail = () => {
        let link
        if (notification.kind === 'new_group_invitation') {
            link = notification.target.selfLink
        }
        if (['collab_invitation', 'collab_join_request', 'collab_request_accept', 'new_follow_group', 'new_follow_user'].includes(notification.kind)) {
            link = notification.author.selfLink
        }
        const Wrapper = link ? 'a' : 'div'
        return <Wrapper href={link}><img className={`${styles.thumbnail} ${notification.kind === 'new_group_invitation' && styles.thumbnailWide}`} src={notification.thumbnail} /></Wrapper>
    }

    const showCta = () => {
        switch (notification.kind) {
            case 'new_group_invitation':
            case 'new_follow_user':
                return notification.showCta
            default:
                return true
        }
    }

    return (
        <div key={notification.id} className={`${styles.root} ${!isRead && styles.unread}`}>
            <div className={styles.statusContainer}>
                <div className={`${styles.readStatus} ${!isRead && styles.readStatusVisible}`} />
            </div>

            <div className={styles.body}>
                <div className={styles.firstRow}>
                    <div className={styles.thumbnailContainer}>
                        {renderThumbnail()}
                    </div>

                    <div className={styles.stampAndMsg}>
                        <small className={styles.timestamp}>
                            {format(parseISO(notification.updatedAt ?? notification.createdAt), 'kk:mm, dd MMM')}
                        </small>
                        <div className={styles.message}>
                            {getMessage()}
                        </div>
                    </div>
                </div>

                <div className={styles.ctasRow}>
                    <div>
                        {!isRead && <Linkton className={styles.btn} onClick={handleMarkAsRead} type='outline' color='light'>Mark as read</Linkton>}
                    </div>

                    <div>
                        {!!showCta() && !!getCtaLabel() && <Linkton className={styles.btn} onClick={handleAction} color='secondary_light'>{getCtaLabel()}</Linkton>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Notification