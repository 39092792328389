import {Enum} from "../../../common/utils";

export const PERIOD = new Enum('YEAR', 'MONTH')

export const PRODUCT_TYPE = new Enum('SUBSCRIPTION', 'SOUND')

export const TABS = new Enum('STRIPE', 'PAYPAL')

export const DISCOUNT_TYPE = new Enum('AMOUNT', 'PERCENTAGE')

export const stripeMessages = {
  "approve_with_id": {
    "message": "The payment cannot be authorized. Please try again. If it still cannot be processed, contact the card issuer."
  },
  "call_issuer": {
    "message": "The card has been declined for an unknown reason. Please contact the card issuer for more information."
  },
  "card_not_supported": {
    "message": "The card does not support this type of purchase. Please contact the card issuer for more information."
  },
  "card_velocity_exceeded": {
    "message": "The customer has exceeded the balance or credit limit available on their card. Please contact the card issuer for more information."
  },
  "currency_not_supported": {
    "message": "The card does not support the specified currency. Please contact the card issuer for more information."
  },
  "do_not_honor": {
    "message": "The card has been declined for an unknown reason. Please contact the card issuer for more information."
  },
  "do_not_try_again": {
    "message": "The card has been declined for an unknown reason. Please contact the card issuer for more information."
  },
  "duplicate_transaction": {
    "message": "A transaction with identical amount and credit card information was submitted very recently. Please check if a recent payment already exists."
  },
  "expired_card": {
    "message": "The card has expired. Please use another card."
  },
  "fraudulent": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "generic_decline": {
    "message": "The card has been declined for an unknown reason. Please contact the card issuer for more information."
  },
  "incorrect_number": {
    "message": "The card number is incorrect. Please try again with the correct card number.",
    "field": "stripeCard"
  },
  "incorrect_cvc": {
    "message": "The CVC number is incorrect. Please try again with the correct CVC.",
    "field": "stripeCvc"
  },
  "insufficient_funds": {
    "message": "The card has insufficient funds to complete the purchase. Please try again with another card."
  },
  "invalid_account": {
    "message": "The card, or account the card is connected to, is invalid. Please contact the card issuer for more information."
  },
  "invalid_amount": {
    "message": "The payment amount is invalid, or exceeds the amount that is allowed. Please contact the card issuer to make sure you're allowed to make purchases of this amount."
  },
  "invalid_cvc": {
    "message": "The CVC number is incorrect. Please try again with the correct CVC.",
    "field": "stripeCvc"
  },
  "invalid_expiry_year": {
    "message": "The expiration date is invalid. Please try again with the correct expiration date.",
    "field": "stripeExpiry"
  },
  "invalid_number": {
    "message": "The card number is incorrect. Please try again with the correct card number.",
    "field": "stripeCard"
  },
  "issuer_not_available": {
    "message": "The card issuer could not be reached. Please try again. If it still cannot be processed, contact the card issuer."
  },
  "lost_card": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "merchant_blacklist": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "new_account_information_available": {
    "message": "The card, or account the card is connected to, is invalid. Please contact the card issuer for more information."
  },
  "no_action_taken": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "not_permitted": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "pickup_card": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "pin_try_exceeded": {
    "message": "The allowable number of PIN tries has been exceeded. Please use another card."
  },
  "processing_error": {
    "message": "An error occurred while processing the card. Please try again. If it still cannot be processed, contact the card issuer."
  },
  "reenter_transaction": {
    "message": "The payment could not be processed by the issuer for an unknown reason. Please contact the card issuer for more information."
  },
  "restricted_card": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "revocation_of_all_authorizations": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "revocation_of_authorization": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "security_violation": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "service_not_allowed": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "stolen_card": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "stop_payment_order": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "transaction_not_allowed": {
    "message": "The payment cannot be authorized. Please contact the card issuer for more information."
  },
  "try_again_later": {
    "message": "The payment cannot be authorized. Please try again. If it still cannot be processed, contact the card issuer."
  },
  "withdrawal_count_limit_exceeded": {
    "message": "The card has exceeded the balance or credit limit. Please try again with another card."
  }
}

export const chargebeeMessages = {
  payment_processing_failed: {
    message: 'Subscription cannot be created as the payment collection failed. Please retry the transaction using an alternative payment method'
  },
  payment_method_verification_failed: {
    message: 'Problem while adding the card.'
  },
  payment_method_not_present: {
    message: 'Card is not present when reactivating the subscription.',
  },
  payment_gateway_currency_incompatible: {
    message: 'The configured gateway account does not support transactions in INR.'
  },
  payment_intent_invalid: {
    message: 'Problem while processing payment intent.'
  },
  payment_intent_invalid_amount: {
    message: 'Problem while processing payment intent.'
  },
  default: {
    message: 'The payment cannot be authorized. Please try again. If it still cannot be processed, contact the card issuer.'
  }
}
