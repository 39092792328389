import React from 'react'
import './simple-accordion.scss'
import { Bem } from '../../common/utils'
import PropTypes from 'prop-types'
import {noop} from 'lodash'

const cn = new Bem({
  name: 'simple-accordion',
  prefix: 'pfx-'
})

class SimpleAccordion extends React.Component {
  constructor({isOpen, isEnabled}) {
    super()
    this.state = { isOpen, isEnabled }
  }

  componentWillReceiveProps({isOpen, isEnabled}) {
    this.setState({ isOpen: isOpen && isEnabled, isEnabled })
  }

  renderOpenState() {
    const {children} = this.props

    return (
      <div>
        <div>{children}</div>
        <div
          onClick={() => this.handleOpenChange(false)}
          className={cn('link')}>
            Show less
        </div>
      </div>
    )
  }

  renderCloseState() {
    return (
      <div
        onClick={() => this.handleOpenChange(true)}
        className={cn('link')}>
          Show more
      </div>
    )
  }

  handleOpenChange(isOpen) {
    this.setState({isOpen})
    this.props.onChange(isOpen)
  }

  render () {
    const {isOpen, isEnabled} = this.state
    const {children} = this.props

    return (
      <div className={cn()}>
        {isEnabled ? (isOpen ? this.renderOpenState() : this.renderCloseState()) : children}
      </div>
    )
  }
}

SimpleAccordion.propTypes = {
  isOpen: PropTypes.bool,
  isEnabled: PropTypes.bool,
  children: PropTypes.any,
  onChange: PropTypes.func
}

SimpleAccordion.defaultProps = {
  isOpen: false,
  isEnabled: true,
  children: [],
  onChange: noop
}

export default SimpleAccordion
