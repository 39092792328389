import * as React from "react";
import AdminContent from "../../../components/admin-content/admin-content";
import {CarouselResponsive} from "../../../components/carousel/carousel";
import SingleViewAddTrack from "../../../components/carousel/components/single-view-add-track";
import GroupUpdates from "../components/group-updates";
import Comments from "../../../components/comments/comments";
import GroupCommentsWithDataSource from "../../../components/comments/group-comments-with-data-source";
import { Bem, gotoLocation, gotoStudio, mergeLikedTracksMeta, gotoAuth } from '../../../common/utils'
import {map, get} from "lodash";
import SingleViewTrack from "../../../components/carousel/components/single-view-track";
import './remix-content.scss'
import PropTypes from 'prop-types'
import {LINKS} from '@common/consts'
import GroupUpdatesWithDataSource from '../components/group-updates-with-data-source'
import Modal from "../../../components/modal/index";
import TracksList from "../../../components/tracks-list/tracks-list";
import Button from "../../../components/button/index";
import Link from "../../../components/link/index";
import {getAccountTracksByGroup, getUserTrack, getUserTrackByGroup, postTrackToGroup} from "../../../common/http";
import createProvider from "../../../store/provider";
import {connect} from "react-redux";
import {setFlashMessageByValues} from "../../../store/actions/flashMessage";
import { PFX_REQUIRE_SIGN_IN } from '../../../global-events-bindings'

const cn = new Bem({
  name: 'remix-content',
  prefix: 'pfx-'
})


class RemixContent extends React.Component {

  static propTypes = {
    campaignDescription: PropTypes.string.isRequired,
    canPostUpdates: PropTypes.bool,
    canDeleteComments: PropTypes.bool,
    currentUser: PropTypes.object,
    groupId: PropTypes.string.isRequired,
    isMember: PropTypes.bool,
    initialUpdates: PropTypes.array,
    initialComments: PropTypes.array,
    initialTracks: PropTypes.array,
    slug: PropTypes.string
  }

  static defaultProps = {
    initialUpdates: [],
    initialComments: [],
    initialTracks: []
  }

  state = {
    isModalOpen: false,
    tracks: [],
    userTracks: [],
    userSubmittedTracks: []
  }

  constructor(props) {
    super(props)
    this.GroupCommentsWithDataSource = GroupCommentsWithDataSource(Comments, props.groupId, props.initialComments)
    this.GroupUpdatesWithDataSource = GroupUpdatesWithDataSource(GroupUpdates, props.groupId, props.initialUpdates)
  }

  async getAndSetTracks() {
    const {data} = await getUserTrack().then(mergeLikedTracksMeta)

    if (data && data.tracks) {
      this.setState({tracks: data.tracks})
    }
  }

  async getAndSetUserGroupTracks() {
    const {data} = await getUserTrackByGroup(this.props.groupId).then(mergeLikedTracksMeta)

    if (data && data.tracks) {
      this.setState({userTracks: data.tracks})
    }
  }

  async getAndSetAccountTracksByGroup() {
    const {data} = await getAccountTracksByGroup(this.props.groupId)

    if (data && data.tracks) {
      this.setState({userSubmittedTracks: data.tracks.filter((t) => t.submitted)})
    }
  }

  componentWillMount() {
    this.getAndSetTracks()
    this.getAndSetUserGroupTracks()
    this.getAndSetAccountTracksByGroup()
  }

  renderModalBody = () => {
    const {currentUser, groupId} = this.props
    const {userSubmittedTracks} = this.state
    const userTracksIds = userSubmittedTracks.map((t) => t.id)

    return this.state.tracks.length
      ? (
        <div className={cn('modal-body')}>
          <TracksList
            tracks={this.state.tracks}
            userTracksIds={userTracksIds}
            selectable={true}
            player={false}
            shouldShowArtist={false}
            shouldShowStats={true}
            onSelect={this.handleTrackSelect}/>
        </div>
      )
      : (
        <div className={cn('modal-body', 'empty')}>
          <h5 className={cn('modal-header', null, 'pfx--mono')}>You don't have any tracks</h5>
          <Button
            type='primary-full'
            onClick={() => gotoStudio(!!currentUser, false, groupId)}
            {...{[PFX_REQUIRE_SIGN_IN]: true}}>
            Start making music!
          </Button>
        </div>
      )
  }

  handleTrackSelect = (selectedTrack) => {
    this.setState({selectedTrack})
  }

  handlePostTrack = async (id) => {
    const {groupId, setFlashMessageByValues} = this.props
    await postTrackToGroup(groupId, id)
    this.setState({isModalOpen: false})
    setFlashMessageByValues({
      text: 'Track posted successfully. Good luck!',
    })
    await this.getAndSetUserGroupTracks(groupId)
  }

  renderModalFooter = () => {
    const {slug, currentUser} = this.props
    const {selectedTrack} = this.state
    return (
      <div className={cn('modal-footer')}>
        <Link
          type='outline'
          href={ `/campaigns/${slug}/tracks/new` }
          requireAuth={true}
        >Upload from disc</Link>
        <Button
          type='primary-full'
          disabled={!selectedTrack}
          onClick={() => this.handlePostTrack(selectedTrack)}>Post track</Button>
      </div>
    )
  }

  render() {
    const {
      campaignDescription,
      canPostUpdates,
      currentUser,
      isMember,
      canDeleteComments,
      initialUpdates,
      initialTracks,
      groupId,
    } = this.props

    const {isModalOpen} = this.state

    const {GroupCommentsWithDataSource, GroupUpdatesWithDataSource} = this
    const shouldShowUpdates = canPostUpdates || initialUpdates.length
    const addTrackLabel = initialTracks.length
      ? 'Post your remix to the competition.'
      : 'Be the first to post your remix!'

    return (
      <div className={cn()}>
        <AdminContent html={campaignDescription}/>
        <Modal
          title={'Select track'}
          isOpen={isModalOpen}
          windowStyle={{
            maxWidth: '920px'
          }}
          footer={() => this.renderModalFooter() }
          onCloseButtonClick={() => this.setState({isModalOpen: false})}>
          {() => this.renderModalBody()}
        </Modal>
        <CarouselResponsive
          className={cn('featured-tracks')}
          title='Entries'
          theme='light'
          onViewAllClick={() => gotoLocation(`${LINKS.GROUP}/${groupId}/tracks`)}>
          {[
            <SingleViewAddTrack
              onClick={() => this.setState({isModalOpen: true})}
              key={'single-view'} label={addTrackLabel}/>,
            ...map(initialTracks, ({id, title, user, imageLargeUrl, createdAt, audioMp3Url, permalink, shortLinkId, duration, directWaveformUrl, fileName}, idx) => (
              <SingleViewTrack
                {...{
                  id,
                  key: idx,
                  subtitle: user.username,
                  image: imageLargeUrl,
                  songUrl: audioMp3Url,
                  fileName,
                  profileLink: user.profileLink,
                  date: createdAt,
                  title,
                  permalink,
                  duration,
                  directWaveformUrl,
                  shortLinkId
                }}
              />
            ))
          ]}
        </CarouselResponsive>
        {
          shouldShowUpdates &&
          <GroupUpdatesWithDataSource
            className={cn('group-updates')}
            canPost={canPostUpdates}/>
        }
        <GroupCommentsWithDataSource
          canDelete={canDeleteComments}
          canPost={!!currentUser && isMember}
        />
      </div>
    )
  }
}


const mapStateToProps = state => {
  return {
    currentUser: get(state, 'backendData.currentUser')
  }
}

const mapDispatchToProps = {
  setFlashMessageByValues
}

export default createProvider(connect(mapStateToProps, mapDispatchToProps)(RemixContent))
