import {Bem} from "@common/utils";
import React from 'react'
import './single-view-add-track.scss'
import {withBindHeightToWidth} from "./with-bind-height-to-width";
import Icon from "../../icon/index";

const cn = new Bem({
  name: 'carousel-add-track',
  prefix: 'pfx-'
})

export class PostRemixButton extends React.Component {
  render() {
    return (
      <div className={cn('square')}>
        <div className={cn('square-title')}>{ this.props.label }</div>
        <Icon className={cn('plus-icon')} icon='new-thin-plus' size={120}/>
      </div>
    )
  }

  static defaultProps = {
    label: 'Post Remix'
  }
}

export const PostRemixButtonResponsive = withBindHeightToWidth(PostRemixButton)

export default class SingleViewAddTrack extends React.Component {
  render() {
    const {label, className, ...passThroughProps} = this.props
    return (
      <div {...passThroughProps} className={cn(null, null, className)}>
        <PostRemixButtonResponsive label={ this.props.innerLabel } />
        <div className={cn('title')}>{label}</div>
      </div>
    )
  }
}
