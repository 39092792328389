import StorageService from '@common/localStorage'

const BANNERS_KEY = '_soundation_com_banners'

class DismissedBannersLocalStorage {
  static dismissedBannersStorage = new StorageService({ key: BANNERS_KEY })

  static all = () => {
    return { ...this.dismissedBannersStorage.get() }
  }

  static get = (bannerId) => {
    return this.all()[bannerId]
  }

  static set = (bannerId, value) => {
    this.dismissedBannersStorage.set({ ...this.all(), [bannerId]: { dismissedAt: value } })
  }

  static purge = () => {
    this.dismissedBannersStorage.set({})
  }
}

export default DismissedBannersLocalStorage
