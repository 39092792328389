'use client'
import React from "react";
import PropTypes from "prop-types";
import { Bem } from "@common/utils";
import { useSelector } from "react-redux";
import { selectIsUserLoggedIn } from "@store/selectors/backendData"
import { Crumb } from "./components/Crumb";
import { useStrapiContext } from "../../../contexts/StrapiContext";
import "./BreadcrumbsBlock.style.scss";

const cn = new Bem({
  name: "bread-crumbs-block",
  prefix: "pfx-",
});

export const BreadcrumbsBlockComponentId = "blocks.breadcrumbs";

const BreadcrumbsBlock = ({ className }) => {
  const {
    navigation: { breadcrumbs, getPath },
  } = useStrapiContext();
  const isLoggedIn = useSelector(selectIsUserLoggedIn)

  return (
    <ul className={cn(undefined, undefined, className)}>
      {breadcrumbs.map((item) => {
        const crumb = {
          ...item,
          path: getPath(item),
          isLoggedIn
        };

        return <Crumb key={`${crumb.path}_${crumb.link}`} {...crumb} />;
      })}
    </ul>
  );
};

export const BreadcrumbsBlockPropTypes = {
  className: PropTypes.string,
};

BreadcrumbsBlock.propTypes = PropTypes.shape(
  BreadcrumbsBlockPropTypes
).isRequired;

export default BreadcrumbsBlock;
