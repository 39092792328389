import { actionTypes } from './types/group'

export const created = group => dispatch => {
  dispatch({ type: actionTypes.CREATED, payload: {group} })
}

export const modified = group => dispatch => {
  dispatch({ type: actionTypes.MODIFIED, payload: {group} })
}

export const dismissSticky = () => dispatch => {
  dispatch({ type: actionTypes.DISMISS_STICKY, payload: null})
}