import { searchTree } from "@common/utils"
import { isComponent } from "@common/strapi.helpers"
import { RelationTypesIds } from "@common/strapi.consts";

const getPaths = (paths) => {
  return ['/', ...paths.split("/").filter(Boolean)]
};

export const getPath = (item) => {
  let path = item?.additionalPath || item?.additional_path || item?.path || "/";
  // if someone in the strapi admin section innocently wrote the path as "some-path" instead of "/some-path", this will fix it:
  if (!path.startsWith('/') && item.path.startsWith('/')) path = '/' + path
  return path
};

export const findNavigationNode = (navigation = [], matcher) => {
  for (const navItem of navigation) {
    const node = searchTree(navItem, matcher);
    if (node) {
      return { root: navItem, node };
    }
  }
  return { root: null, node: null };
};

export const getCrumbsFromNavigation = (navigation, pathname) => {
  const result = [];
  if (!navigation) {
    return result;
  }
  let currentPath = "";

  const splitPaths = getPaths(pathname)
  for (const path of splitPaths) {
    currentPath = `${currentPath}/${path}`.replace(/\/\//g, "/");

    const { node } = findNavigationNode(navigation, (item) => {
      return (
        [item.additional_path, path, item.path].some(
          (p) => p === currentPath
        ) && item.type !== "WRAPPER"
      );
    });

    if (node) {
      result.push(node);
    }
  }

  return result;
};

export const searchById = ({ id, uids, navigation }) => {
  if (!id) return null;

  const matchesId = (item) => {
    return item?.related?.id === id;
  };

  // navigation can contain related items of different content types
  // entities with different content types can have the same id
  // Therefore we sometimes need to filter them
  const matchesUid = (item) => {
    if (!uids?.length) return true;
    if (!item?.related?.__contentType) return true;
    return uids.includes(item?.related?.__contentType);
  };

  return findNavigationNode(
    navigation,
    (navItem) => matchesId(navItem) && matchesUid(navItem)
  );
}

export const getStrapiLinkFromFooter = ({ item, navigation }) => {
  if (isComponent(item, RelationTypesIds.link)) {
    return {
      url: item.externalLink.url,
      label: item.name || item.externalLink.name,
      target: "_blank",
    };
  }

  if (isComponent(item, RelationTypesIds.page)) {
    const { node } = searchById({ id: item.page?.id, navigation }) || {};
    if (node) {
      return {
        url: getPath(node),
        label: item.name || item.page.name,
      };
    }
  }

  return null
}
