import React, { useCallback, useEffect } from 'react'
import usePaginate from '@root/hooks/usePaginate'
import { getUserFollowers, denormalizeResponse } from '@common/http'
import { extractUsersFromFollowings } from '@common/utils'
import CommunitySubPage from '../community-sub-page'
import CommunityUserCard from '../community-user-card'

const CommunityUserFollowersSubPage = (props) => {
  const { guid, username } = props

  const fetcher = useCallback(async ({ page }) => {
    return getUserFollowers(guid, { page, items: 20 })
  }, [guid])

  const { isLoading, data, fetchNextPage } = usePaginate({ fetcher })
  const users = { data: extractUsersFromFollowings(data, 'follower') }
  const followers = denormalizeResponse(users)

  // fetch groups on mount
  useEffect(fetchNextPage, [])

  return (
    <CommunitySubPage
      title={`"${username}" followers`}
      fetchNextPage={fetchNextPage}
      isLoading={isLoading}
      landscapeOnMobile
    >
      {followers.map(({ id, ...rest }) => <CommunityUserCard key={id} user={{ ...rest }} isSubPage />)}
    </CommunitySubPage>
  )
}

export default CommunityUserFollowersSubPage
