import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import { TextFieldControlled } from '../text-field'
import Icon from '../icon'
import './style.scss'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'typeahead'
})

class Typeahead extends Component {
  state = {
    open: false,
    value: null,
    input: '',
    filter: '',
    highlighted: null,
    filteredOptions: []
  }

  itemRefs = []

  async componentDidMount () {
    const { value } = this.props

    if (value) {
      const option = this.getOptionByValue(value)
      if (option) {
        await this.setState({
          value: option.value,
          input: option.label
        })
      }
    }
  }

  render () {
    const { placeholder, name, emptyResultsMessage } = this.props
    const { open, input, value, filteredOptions, highlighted } = this.state

    this.itemRefs = []

    const items = filteredOptions.map((option, index) => <li
      key={option.value}
      className={cn('list-item', { highlighted: index === highlighted })}
      onMouseOver={() => this.highlight(index)}
      onClick={() => this.chooseOption(option)}
      ref={(r) => this.itemRefs[index] = r}
    >{ option.label }</li>)

    return (
      <div
        className={cn()}
        ref={(r) => this.ref = r}
      >
        <div className={cn('dropdown')}>
          <input
            type={'hidden'}
            value={value || ''}
            name={name}
          />
          <TextFieldControlled
            type={'text'}
            layout='dark'
            isLaidBack
            className={cn('dropdown-input')}
            placeholder={placeholder}
            value={input}
            onChange={(e) => this.onInputChange(e)}
            onKeyDown={(e) => this.handleKeyDown(e)}
            onMouseDown={this.open}
          />

          <div
            className={cn('dropdown-icon')}
            onClick={() => this.toggle()}
          >
            <Icon icon='small-down' size={16} />
          </div>
        </div>

        <ul className={cn('list', { open })}>
          { items.length > 0 ? items : <li className={cn('list-message')}>{ emptyResultsMessage }</li> }
        </ul>
      </div>
    )
  }

  exitCallback = (event) => {
    if (!this.ref.contains(event.target)) {
      this.close()
    }
  }

  open = async () => {
    const { open } = this.state

    if (!open) {
      await this.filterList()
      await this.setState({ open: true })
      const { filteredOptions, value } = this.state
      const index = filteredOptions.findIndex(option => option.value === value)
      if (index !== -1) {
        await this.highlight(index)
      }

      document.addEventListener('click', this.exitCallback)
      document.addEventListener('focusin', this.exitCallback)
    }
  }

  close = async () => {
    const { value, filteredOptions, input } = this.state

    document.removeEventListener('click', this.exitCallback)
    document.removeEventListener('focusin', this.exitCallback)

    const selectedOption = filteredOptions.find(option => option.label === input)

    if (selectedOption) {
      await this.chooseOption(selectedOption, false)
    } else if (input === '' || !value) {
      await this.chooseOption(null, false)
    } else {
      await this.chooseOption(this.getOptionByValue(value), false)
    }

    await this.setState({
      open: false,
      filter: '',
      highlighted: null
    })
  }

  toggle = () => {
    this[this.state.open ? 'close' : 'open']()
  }

  filterList = async () => {
    let { options } = this.props
    const { filter } = this.state

    if (filter) {
      const filterRegExp = new RegExp(filter.toLowerCase().split('').join('.*'))
      options = options.filter(option => option.label.toLowerCase().match(filterRegExp))

      // sort by relevance so that 'Rock' will be higher than 'Reggeaton' when user searches for 'ro'
      options.sort((a, b) => {
        const chars = filter.toLowerCase().split('')
        for (let i = 0; i < chars.length; ++i) {
          const aIndex = a.label.toLowerCase().indexOf(chars[i])
          const bIndex = b.label.toLowerCase().indexOf(chars[i])
          if (aIndex !== bIndex) {
            return aIndex - bIndex
          }
        }
      })
    } else {
      // sort alphabetically
      options = options.slice().sort((a, b) => a.label.localeCompare(b.label))
    }
    await this.setState({ filteredOptions: options })
  }

  getOptionByValue = (value) => {
    return this.props.options.find(option => option.value === value)
  }

  chooseOption = async (option, andClose = true) => {
    const oldValue = this.state.value
    const newValue = option ? option.value : null

    if (oldValue !== newValue && this.props.onChange) {
      this.props.onChange(option)
    }

    await this.setState({
      value: newValue,
      input: option ? option.label : ''
    })
    if (andClose) {
      await this.close()
    }
  }

  onInputChange = async (value) => {
    await this.setState({
      input: value,
      filter: value,
      highlighted: null
    })
    await this.filterList()
  }

  handleKeyDown = async (event) => {
    event.persist()

    await this.open()

    const { filteredOptions } = this.state
    const highlighted = this.state.highlighted
    const count = filteredOptions.length

    if (event.keyCode === 38) { // up
      event.preventDefault()
      return this.highlight((highlighted === null ? count - 1 : (highlighted - 1 + count)) % count)
    } else if (event.keyCode === 40) { // down
      event.preventDefault()
      return this.highlight((highlighted === null ? 0 : (highlighted + 1 + count)) % count)
    } else if (event.keyCode === 13 && highlighted !== null) {
      event.preventDefault()
      return this.chooseOption(filteredOptions[highlighted])
    }
  }

  highlight = async (index) => {
    this.itemRefs[index].scrollIntoViewIfNeeded()
    return this.setState({ highlighted: index })
  }
}

Typeahead.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]).isRequired,
    label: PropTypes.string.isRequired
  })).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  emptyResultsMessage: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

Typeahead.defaultProps = {
  emptyResultsMessage: 'No results found'
}

export default Typeahead
