import PropTypes from 'prop-types'

export const user = PropTypes.shape({
  about: PropTypes.string,
  abuseLevel: PropTypes.number,
  accessToken: PropTypes.string,
  accessTokenCreatedAt: PropTypes.string,
  accountAssignedAt: PropTypes.string,
  accountType: PropTypes.number,
  avatarContentType: PropTypes.string,
  avatarFileName: PropTypes.string,
  avatarFileSize: PropTypes.number,
  avatarUpdatedAt: PropTypes.string,
  campaign: PropTypes.number,
  campaignUserId: PropTypes.number,
  country: PropTypes.string,
  createdAt: PropTypes.string,
  currency: PropTypes.number,
  downloadsCount: PropTypes.number,
  email: PropTypes.string,
  facebook: PropTypes.string,
  facebookConnected: PropTypes.bool,
  followersCount: PropTypes.number,
  followingGroupsCount: PropTypes.number,
  followingUsersCount: PropTypes.number,
  googleConnected: PropTypes.bool,
  gplus: PropTypes.string,
  groupsCount: PropTypes.number,
  guid: PropTypes.string,
  id: PropTypes.number,
  likesCount: PropTypes.number,
  mixdownsCount: PropTypes.number,
  oldSystemAccount: PropTypes.string,
  ordersCount: PropTypes.number,
  playsCount: PropTypes.number,
  profileLink: PropTypes.string,
  promoCode: PropTypes.string,
  realName: PropTypes.string,
  recurringProfileId: PropTypes.number,
  registeredAt: PropTypes.number,
  storageUsage: PropTypes.number,
  subscribedToNewsletter: PropTypes.bool,
  twitter: PropTypes.string,
  updatedAt: PropTypes.string,
  userGroupId: PropTypes.number,
  username: PropTypes.string,
  youtube: PropTypes.string,

  // method result from ruby
  activeRecurringSubscription: PropTypes.bool
})
