import React from 'react'
import Icon from '@components/icon'
import * as styles from './CommunitySectionPlaceholder.module.scss'

// placeholder when a section has no items
const CommunitySectionPlaceholder = (props) => {
  const { icon, message } = props

  return (
    <div className={styles.container}>
      <Icon icon={icon} size={24} />
      <span className={styles.message}>{message}</span>
    </div>
  )
}

export default CommunitySectionPlaceholder
