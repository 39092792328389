import { Bem } from '@common/utils'
import PropTypes from 'prop-types'
import './Toggle.style.scss'

const cn = new Bem({
  name: 'toggle',
  prefix: 'pfx-'
})
export const toggleRootCnFn = (...args) => cn('', ...args)
export const toggleSwitchCnFn = (...args) => cn('switch', ...args)
export const toggleLabelCnFn = (...args) => cn('label', ...args)
export const toggleTitleCn = cn('title')

const Toggle = ({
  value,
  name,
  label,
  onToggle,
  className
}) => {
  const handleOnChange = () => {
    onToggle({ name, value: !value })
  }

  return (
    <div className={toggleRootCnFn({}, className)}>
      <label
        className={toggleLabelCnFn()}
      >
        <input type='checkbox' name={name} checked={value} onChange={handleOnChange} />
        <div
          role='switch'
          aria-label={label}
          aria-checked={value}
          className={toggleSwitchCnFn({ checked: value })}
        />
      </label>
    </div>
  )
}

Toggle.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onToggle: PropTypes.func
}

export default Toggle
