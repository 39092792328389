import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Bem } from '@common/utils'
import './style.scss'
import Icon from '../icon'
import {noop} from 'lodash'

const cn = new Bem({
  prefix: 'pfx-',
  name: 'copy-to-clipboard'
})

class CopyToClipboard extends Component {

  static propTypes = {
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
    onCopy: PropTypes.func,
    onTextClick: PropTypes.func
  }

  static defaultProps = {
    onCopy: noop,
    onTextClick: noop
  }

  state = {
    copied: false
  }

  render () {
    const { value, className, onTextClick, onCopy, ...restProps } = this.props
    const { copied } = this.state

    return <div className={cn('', '', className)} { ...restProps }>
      <input
        type="text"
        className={cn('input')}
        value={value}
        readOnly={true}
        onClick={() => onTextClick() }
        ref={r => this.inputRef = r}
        onFocus={() => this.selectAll()}
      />
      <div
        className={cn('button')}
        onClick={() => this.copyValue()}
      >copy</div>

      <div className={cn('confirmation', {visible: copied})}>
        <Icon icon={'check'} className={cn('confirmation-icon')} size={16} />
        Copied
      </div>
    </div>
  }

  async copyValue () {
    const { inputRef } = this
    const { onCopy } = this.props
    onCopy()
    inputRef.contentEditable = true
    inputRef.readOnly = false
    inputRef.focus()
    this.selectAll()
    document.execCommand('copy')
    inputRef.contentEditable = false
    inputRef.readOnly = true
    await this.setState({copied: false})
    setTimeout(() => {
      this.setState({copied: true})
    })
    this.clearSelection()
    inputRef.blur()
  }

  selectAll() {
    const { inputRef } = this
    const { value } = this.props
    const range = document.createRange()
    range.selectNodeContents(inputRef)
    this.clearSelection()
    if (window.getSelection) {
      window.getSelection().addRange(range)
      inputRef.setSelectionRange(0, value.length)
    } else {
      inputRef.select()
    }
  }

  clearSelection() {
    if (window.getSelection) {
      window.getSelection().removeAllRanges()
    } else if (document.selection) {
      document.selection.empty()
    }
  }

}

export default CopyToClipboard