import React from 'react'
import PropTypes from 'prop-types';
import {Bem} from '../../common/utils';
import {noop} from 'lodash'
import './style.scss'

const cn = new Bem({
  name: 'load-more',
  prefix: 'pfx-'
});

class LoadMore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      page: props.initialPage,
      total: props.initialTotal,
      shouldShowViewMore: props.initialTotal > props.itemsPerPage
    }
  }

  async requestLoadMore() {
    this.setState({loading: true})
    const {page} = this.state
    const {backendAction, onLoadedMore, itemsPerPage} = this.props
    const nextPage = page + 1
    const {data} = await backendAction({page: nextPage, items: itemsPerPage})

    this.setState({
      shouldShowViewMore: !!data.meta.nextPage,
      loading: false,
      commentsCount: data.meta.total,
      page: nextPage
    })

    onLoadedMore(data)
  }

  render() {
    const {children, className} = this.props;
    const {shouldShowViewMore} = this.state;
    return (
      <div className={cn(null, null, className)}>
        <div className={cn('children')}>{children}</div>
        {
          shouldShowViewMore && <div className={cn('button')} onClick={() => this.requestLoadMore()}>
            <span>See more</span>
            <Icon icon='arrow-down' size={11} className={cn('icon')}/>
          </div>
        }
      </div>
    );
  }
}

LoadMore.propTypes = {
  onLoadedMore: PropTypes.func,
  page: PropTypes.number,
  backendAction: PropTypes.func,
  initialPage: PropTypes.string,
  initialTotal: PropTypes.number,
  itemsPerPage: PropTypes.number,
  className: PropTypes.string
};

LoadMore.defaultProps = {
  onLoadedMore: noop,
  backendAction: () => Promise.resolve(),
  itemsPerPage: 5,
  initialTotal: 0,
  initialPage: 1
};

export default LoadMore;
