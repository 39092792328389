import React from 'react'
import CommunityCard from '../community-card'

const CommunityUserCard = React.forwardRef((props, ref) => {
  const { user, isSubPage = false } = props

  return (
    <div ref={ref}>
      <CommunityCard
        isSubPage={isSubPage}
        picture={{ src: user.avatarLargeUrl, alt: user.username }}
        link={user.selfLink}
        title={user.username}
        stats={[
          { count: user.playsCount || 0, icon: 'Play-outline' },
          { count: user.mixdownsCount || 0, icon: 'project-file' },
          { count: user.followersCount || 0, icon: 'Collab-2' }
        ]}
      />
    </div>
  )
})

export default CommunityUserCard
