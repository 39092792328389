import { useEffect, useRef, useState, useCallback } from 'react'
import Icon from '@components/icon'
import * as styles from './CopyTextButton.module.scss'

// Returns the icon and text CTA used in a button responsible for copying text to clipboard. On click, it momentarily shows a success indicator.
export default function CopyTextButton (props) {
  const { successDuration = 1500, textToBeCopied, isOutline = true, className = '', ...buttonProps } = props

  const timeout = useRef()
  const [isCopied, setIsCopied] = useState(false)

  // copy and set success indicator
  const handleCopyLink = useCallback(() => {
    navigator.clipboard.writeText(textToBeCopied)
      .then(() => {
        setIsCopied(true)
      })
      .catch(() => {
        setIsCopied(false)
      })
  }, [textToBeCopied])

  // clear success indicator after timeout
  useEffect(() => {
    if (isCopied) {
      timeout.current = setTimeout(() => {
        setIsCopied(false)
        if (timeout.current) clearTimeout(timeout.current)
      }, successDuration)

      return () => {
        if (timeout.current) clearTimeout(timeout.current)
      }
    }
  }, [isCopied, successDuration])

  return (
    <button className={`${styles.btn} ${isOutline ? styles.outline : ''} ${className}`} onClick={handleCopyLink} type='hollow' {...buttonProps}>
      <div className={styles.content}>
        <Icon icon={isCopied ? 'check' : 'duplicate'} size={16} />
        <span>{isCopied ? '' : 'Copy'}</span>
      </div>
    </button>
  )
}
