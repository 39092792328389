import React, { useEffect, useRef, useState } from 'react'
import CommunityCard from '../community-card'
import { LINKS, PIECE_TYPE } from '@common/consts'
import { extractError } from '@common/utils'
import { disableTemplateForProject, enableTemplateForProject, cloneProject, removeProjectMember, deleteSoloProject, deleteProject, updateProject } from '@common/http'
import dayjs from 'dayjs'
import CommunityAvatars from '../community-avatars'
import * as styles from './CommunityProjectCard.module.scss'

const constructProjectLink = (projectId) => {
  return LINKS.STUDIO_PROJECT.replace(':id', projectId)
}
const constructTemplateLink = (templateId) => {
  return LINKS.STUDIO_TEMPLATE.replace(':id', templateId)
}

const getOpenType = ({ isMember, isOwner, isTemplate }) => {
  if (isTemplate) {
    return 'open-as-new'
  }

  if (isOwner || isMember) {
    return 'open'
  }

  return null
}

const getPermission = ({ userGuid, project }) => {
  const isTemplate = !!project.template?.id
  const isOwner = !!userGuid && project?.owner?.id === userGuid
  const isMember = project.collaborators?.some(({ id }) => id === userGuid) || false
  const isSolo = project.pieceType === PIECE_TYPE.SONG

  let projectType = 'project'
  if (isSolo) projectType = 'solo'
  if (isTemplate) projectType = 'template'

  return {
    canLeave: isSolo ? false : isMember && !isOwner,
    canRename: isOwner,
    canDelete: isOwner,
    canDuplicate: !isTemplate,
    canCopyTemplateLink: !isSolo && isTemplate,
    open: getOpenType({ isMember, isOwner, isTemplate }),
    canDisableTemplate: !isSolo && isTemplate && isOwner,
    canEnableTemplate: !isSolo && !isTemplate && isOwner,
    projectType,
    isTemplate,
    isSolo,
    isMember,
    isOwner
  }
}

const CommunityProjectCard = React.forwardRef((props, ref) => {
  const { piece, currentUser, onError, reloadProject, reloadAll, isSubPage = false } = props

  const titleInputRef = useRef(null)

  const [title, setTitle] = useState(piece.title)
  const [isEditingTitle, setIsEditingTitle] = useState(false)

  const project = piece.project || piece.song

  const permission = getPermission({ userGuid: currentUser?.guid, project: piece })

  const getContextMenuItems = ({ piece }) => {
    if (!permission || !piece) return []

    const contextMenuItems = []

    if (['open', 'open-as-new'].includes(permission.open)) {
      if (permission.open === 'open') {
        const url = constructProjectLink(piece.id)
        contextMenuItems.push({
          label: 'Open in studio',
          icon: 'open-in-new-window1',
          href: url,
          target: '_blank',
          className: styles.lastContextItemInSection
        })
      }

      if (permission.open === 'open-as-new') {
        const url = constructTemplateLink(piece.template.id)
        contextMenuItems.push({
          label: 'Open as new project',
          icon: 'open-in-new-window1',
          href: url,
          target: '_blank',
          className: styles.lastContextItemInSection
        })
      }
    }

    if (permission.canCopyTemplateLink || permission.canDisableTemplate || permission.canEnableTemplate) {
      if (permission.canCopyTemplateLink) {
        const url = constructTemplateLink(piece.template.id)
        contextMenuItems.push({
          label: 'Copy template link',
          icon: 'duplicate',
          closeOnClick: true,
          onClick: () => navigator.clipboard.writeText(url).catch(),
          className: !(permission.canDisableTemplate || permission.canEnableTemplate) && styles.lastContextItemInSection
        })
      }

      if (permission.canDisableTemplate) {
        contextMenuItems.push({
          label: 'Remove as template',
          icon: 'template',
          onClick: () => disableTemplateForProject(piece.template.id).then(() => reloadProject(piece.id)).catch((e) => onError({ text: extractError(e) })),
          className: !permission.canEnableTemplate && styles.lastContextItemInSection
        })
      }

      if (permission.canEnableTemplate) {
        contextMenuItems.push({
          label: 'Convert to template',
          icon: 'template',
          className: styles.lastContextItemInSection,
          onClick: () => enableTemplateForProject(piece.id).then(() => reloadProject(piece.id)).catch((e) => onError({ text: extractError(e) }))
        })
      }
    }

    if (permission.canRename || permission.canDuplicate) {
      if (permission.canRename) {
        contextMenuItems.push({
          label: 'Rename',
          icon: 'pen',
          closeOnClick: true,
          onClick: () => setIsEditingTitle(true),
          className: !permission.canDuplicate && styles.lastContextItemInSection
        })
      }

      if (permission.canDuplicate) {
        contextMenuItems.push({
          label: 'Duplicate',
          icon: 'Duplicate-ver-2',
          closeOnClick: true,
          className: styles.lastContextItemInSection,
          onClick: () => {
            cloneProject(piece.id)
              .then(() => reloadAll())
              .catch((e) => onError({ text: extractError(e) || 'An unexpected error occurred. Please try again.' }))
          }
        })
      }
    }

    if (permission.canLeave) {
      contextMenuItems.push({
        label: 'Leave project',
        icon: 'trash',
        className: styles.deleteProject,
        onClick: () => {
          const memberId = piece.members.find((m) => m.user?.id === currentUser.guid)?.id
          if (memberId && window.confirm('Are you sure you want to leave this project?')) {
            removeProjectMember(piece.id, memberId)
              .then(() => reloadAll())
              .catch((e) => onError({ text: extractError(e) }))
          }
        }
      })
    }

    if (permission.canDelete) {
      contextMenuItems.push({
        label: 'Delete',
        icon: 'trash',
        className: styles.deleteProject,
        onClick: () => {
          if (window.confirm('Are you sure you want to delete this project?')) {
            if (permission.projectType === 'solo') {
              deleteSoloProject(piece.id)
                .then(() => reloadAll())
                .catch((e) => onError({ text: extractError(e) || 'Failed to delete song' }))
            } else {
              deleteProject(piece.id)
                .then(() => reloadAll())
                .catch((e) => onError({ text: extractError(e) || 'Failed to delete project' }))
            }
          }
        }
      })
    }

    return contextMenuItems
  }
  const contextMenuItems = getContextMenuItems({ piece })

  // focus title input when starting to edit
  useEffect(() => {
    if (isEditingTitle) {
      titleInputRef.current.focus()
    }
  }, [isEditingTitle])

  const stopEditing = () => {
    setIsEditingTitle(false)
    titleInputRef.current.blur()
  }

  const updateProjectTitle = () => {
    stopEditing()

    if (!title) {
      setTitle(piece.title)
    } else if (title !== piece.title) {
      updateProject(piece.id, { title })
        .then(() => reloadProject(piece.id))
        .catch((e) => onError({ text: extractError(e) }))
    }
  }

  const titleInput = (
    <input
      ref={titleInputRef}
      className={`${styles.titleInput} ${isEditingTitle && styles.titleEditMode}`}
      value={title}
      onClick={(e) => isEditingTitle && e.preventDefault()}
      onChange={(e) => setTitle(e.target.value)}
      onBlur={updateProjectTitle}
      onKeyDown={(e) => {
        if (e.key === 'Enter') updateProjectTitle()
        if (e.key === 'Escape') {
          setTitle(piece.title)
          stopEditing()
        }
      }}
    />
  )

  const members = [piece.owner, ...(piece.collaborators ?? [])]

  const thumbnailPlaceholder = permission.isSolo ? LINKS.DEFAULT_SOLO_THUMBNAIL : LINKS.DEFAULT_COLLAB_THUMBNAIL
  const thumbnail = permission.isSolo ? undefined : piece.thumbnail

  return (
    <div className={styles.container} ref={ref}>
      {(permission.isTemplate || permission.isSolo) && <div className={styles.badge}>{permission.isTemplate ? 'Template' : 'Solo'}</div>}
      <CommunityCard
        className={styles.card}
        isSubPage={isSubPage}
        picture={{ src: thumbnail, fallbackSrc: thumbnailPlaceholder, alt: piece.title, aspectRatio: '4x3', className: styles.cover }}
        link={piece.selfLink || (permission.isMember || permission.isOwner ? constructProjectLink(project.id) : constructTemplateLink(piece.template.id))}
        title={titleInput}
        showEmptyStats={false}
        subtitle={(
          members.length ? <CommunityAvatars className={styles.avatars} count={members.length} users={members} denormalizeUsers={false} /> : null
        )}
        actions={[{ icon: 'Clock', label: dayjs(piece.createdAt).fromNow(), onClick: () => { } }]}
        contextMenuItems={contextMenuItems}
      />
    </div>
  )
})

CommunityProjectCard.displayName = 'CommunityProjectCard'
export default CommunityProjectCard
