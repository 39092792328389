import Button from "../button/index";
import React from "react";
import PropTypes from "prop-types";
import {
  Bem,
  canUseDOM,
  gotoLocation,
  requestUpgrade,
  extractError,
  requestLogin,
} from "../../common/utils";
import "./index.scss";
import URI from "urijs";
import { connect } from "react-redux";
import createProvider from "@store/provider";
import { ENDPOINTS, LINKS, ACCOUNT_TYPES } from "@common/consts";
import axios from "axios";
import JSONAPISerializer from "jsonapi-serializer";
import LoadingIndicator from "../loading-indicator";
import Linkton, { COLOR, HOVER, TAG, SIZE } from "../linkton";

const cn = new Bem({
  name: "collab-invite-page",
  prefix: "pfx-",
});

const deserializer = new JSONAPISerializer.Deserializer({
  keyForAttribute: "camelCase",
});

const errors = {
  BANNED: "You have been banned by the project owner",
};

class CollabInvitePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.url = canUseDOM() ? window.location.href : "";
    this.state = {
      isSend: false,
      message: null,
      loading: false,
      error: null,
      project: null,
    };
  }

  async componentDidMount() {
    this.setState({
      project: await deserializer.deserialize(this.props.project),
    });
  }

  isDataLoaded = () => {
    return this.state.project && !this.state.loading;
  };

  handleRequestAccess = async () => {
    if (!this.props.currentUser) {
      requestLogin(
        true,
        window.location.href,
        "GO BACK TO COLLABORATION INVITATION"
      );
    } else {
      const url = URI(ENDPOINTS.PROJECTS)
        .segment(this.props.projectId)
        .segment(ENDPOINTS.MEMBERSHIP_REQUESTS)
        .toString();

      this.setState({ loading: true });

      try {
        const { data } = await axios.post(
          url,
          {
            membership_request: { },
          },
          { withCredentials: true }
        );
        this.setState({ isSend: true, loading: false });
        return data;
      } catch (e) {
        this.setState({ error: extractError(e), loading: false });
        throw new Error(e);
      }
    }
  };

  handleUpdateMembership = async (status) => {
    const hasMembership = this.hasMembership();
    const id = hasMembership
      ? this.props.membership.data.id
      : this.props.membershipId;
    const url = URI(ENDPOINTS.MEMBERS)
      .segment(id)
      .segment("respond")
      .toString();
    this.setState({ loading: true });
    try {
      const { data } = await axios.put(url, status, { withCredentials: true });
      this.setState({ loading: false });
      return data;
    } catch (e) {
      this.setState({ loading: false, error: extractError(e) });
      throw new Error(e);
    }
  };

  handleDecline = async () => {
    if (this.props.membershipId) {
      await this.handleUpdateMembership({ membership: { declined: true } });
    }

    if (this.props.currentUser) {
      gotoLocation(LINKS.FEED);
    } else {
      gotoLocation("/");
    }
  };

  handleAccept = async () => {
    const membership = this.hasMembership();

    if (!this.props.currentUser) {
      return requestLogin(true, window.location.href, "START COLLABORATING");
    } else if (this.props.membershipId || membership) {
      if (!this.props.isMember) {
        await this.handleUpdateMembership({ membership: { accepted: true } });
      }
      this.gotoProject();
    } else {
      if (!this.props.isMember) {
        await this.handleRequestAccess();
      }
      this.gotoProject();
    }
  };

  gotoProject = () => {
    gotoLocation(LINKS.STUDIO + "?joinRoom=" + this.props.projectId);
  };

  renderProjectDetails() {
    const { isMember, ownerUsername, membershipId } = this.props;
    const { project } = this.state;
    const shouldShowJoinCopy = isMember || !membershipId;

    if (!project) {
      return null;
    }

    return (
      <div>
        <div className={cn("cover")}>
          <img
            src={project.coverPhotoUrl}
            alt="Project Cover Photo"
            className={cn("cover-image")}
          />
        </div>
        <div className={cn("header")}>
          {!shouldShowJoinCopy ? (
            <div>
              <strong className={cn("highlight-text")}>{ownerUsername}</strong>{" "}
              has invited you to collaborate on the track <br />
              {project.title}
            </div>
          ) : null}
          {shouldShowJoinCopy && (
            <div>
              Join{" "}
              <strong className={cn("highlight-text")}>{ownerUsername}</strong>{" "}
              and collaborate on the track <br />
              {project.title}
            </div>
          )}
        </div>
        {project.description ? (
          <div className={cn("description")}>{project.description}</div>
        ) : null}
      </div>
    );
  }

  renderGoToDashboard = () => {
    const { eduMode } = this.props

    return (
      <div className={cn("actions")}>
        <Linkton
          size={SIZE.MEDIUM}
          color={COLOR.OCEAN}
          hover={HOVER.OUTLINE}
          tag={TAG.ANCHOR}
          href={eduMode ? LINKS.STUDIO : LINKS.COMMUNITY}
        >
          {eduMode ? 'Go to dashboard' : 'Take me home'}
        </Linkton>
      </div>
    );
  };

  renderStatus() {
    const { isSend, error } = this.state;
    const {
      ownerUsername,
      isMember,
      requestedMembership,
      hasReachedTheLimit
    } = this.props

    return (
      <div>
        {(isSend ||
          error ||
          hasReachedTheLimit ||
          isMember ||
          requestedMembership) && (
          <div className={cn("status")}>
            {isSend && (
              <>
                <div className={cn("status-accepted")}>
                  Request sent! <br /> We will send you an email as soon as{" "}
                  {ownerUsername} has accepted it.
                </div>
                {this.renderGoToDashboard()}
              </>
            )}
            {error && <div className={cn("status-error")}>{error}</div>}
            {requestedMembership && !isMember && (
              <>
              <div className={cn("status-error")}>
                You already requested membership to this project.
              </div>
              {this.renderGoToDashboard()}
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  hasMembership = () => {
    const { membership } = this.props;
    return !!(membership && membership.data);
  };

  renderButtons() {
    const { isSend, error } = this.state;
    const {
      isMember,
      autoAccept,
      requestedMembership,
      hasReachedTheLimit,
      membershipId,
    } = this.props;
    const membership = this.hasMembership();

    if (isSend) return <div />;

    if (error === errors.BANNED) {
      return this.renderGoToDashboard();
    }
    const showdeclineButton = !isMember && !hasReachedTheLimit && !membership;

    const showCollabButton =
      (autoAccept || membershipId || membership) &&
      (!hasReachedTheLimit || isMember);

    const showRequestButton =
      !(isMember || membership || autoAccept || membershipId) &&
      !hasReachedTheLimit;

    return (
      <div>
        <div className={cn("actions")}>
          {showdeclineButton && !requestedMembership && (
            <Button
              type="secondary"
              size="small"
              className={cn("decline-button")}
              onClick={this.handleDecline}
              disabled={requestedMembership}
            >
              No thanks
            </Button>
          )}

          {showCollabButton && (
            <Button
              size="small"
              onClick={this.handleAccept}
              disabled={requestedMembership && !isMember}
            >
              Let's collaborate
            </Button>
          )}
          {showRequestButton && !requestedMembership && (
            <Button
              size="small"
              onClick={this.handleRequestAccess}
              disabled={requestedMembership}
            >
              Request access
            </Button>
          )}
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <div className={cn("footer")}>
        Soundation is an online music studio where you can make music and
        collaborate without boundaries.
      </div>
    );
  }

  render() {
    return (
      <div className={cn()}>
        {this.renderProjectDetails()}
        {this.isDataLoaded() ? (
          <div>
            {this.renderStatus()}
            {this.renderButtons()}
          </div>
        ) : (
          <LoadingIndicator
            color={"white"}
            className={cn("loading-indicator")}
          />
        )}
        {this.renderFooter()}
      </div>
    );
  }
}

CollabInvitePage.propTypes = {
  eduMode: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  membershipId: PropTypes.string,
  isMember: PropTypes.bool,
  ownerUsername: PropTypes.string,
  autoAccept: PropTypes.bool,
  requestedMembership: PropTypes.bool,
  project: PropTypes.object.isRequired,
  hasReachedTheLimit: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.backendData.currentUser,
  };
};

export default createProvider(connect(mapStateToProps)(CollabInvitePage));
