import React, { Fragment, useEffect, useState } from 'react'
import { denormalizeResponse, updateConsents } from '@common/http'
import { getAccount } from '@api/user'
import { LINKS } from '@common/consts'
import CommunityButton from '@components/community-button'
import { humanFileSize } from '@common/utils'
import Label from './components/label'
import CommunityToggle from '@containers/community/community-toggle'
import useRequest from '@hooks/useRequest'
import useOpen from '@hooks/useOpen'
import { differenceInHours, format } from 'date-fns'
import CommunityTitle from '@containers/community/community-title'
import DeleteAccount from './components/delete-account'
import EditUserEmail from './components/edit-user-email'
import EditUserPassword from './components/edit-user-password'
import Section from './components/section'
import Subscriptions from '../subscriptions'
import * as styles from './Account.module.scss'

const Account = (props) => {
  const { orders: ordersNormalized } = props
  const orders = denormalizeResponse(ordersNormalized)

  const { isOpen: isChangePasswordOpen, open: openChangePassword, close: closeChangePassword } = useOpen()
  const { isOpen: isDeleteAccountOpen, open: openDeleteAccount, close: closeDeleteAccount } = useOpen()
  
  const [account, setAccount] = useState({})
  const { isLoading, data: accountRes } = useRequest({ callback: getAccount })
  useEffect(() => { if (accountRes) setAccount(accountRes.user) }, [accountRes])

  const toggleMarketingEmails = () => {
    updateConsents({ marketing: !account.consents.marketing })
    setAccount((cur) => ({ ...cur, consents: { ...cur.consents, marketing: !cur.consents.marketing } }))
  }

  const renderProductDownloads = (product, order) => {
    if (product.class === 'SingleSound') {
      return <a key={product.id} href={`${LINKS.DOWNLOAD}/${product.id}`}>{product.title} ({humanFileSize(product.fileSize)})</a>
    }
    if (product.class === 'SoundationContent') {
      return <div key={product.id}>No download for this product</div>
    }
    if (differenceInHours(new Date(), new Date(order.updatedAt)) > 24) {
      return <div key={product.id}>Download expired (contact us for info)</div>
    }

    return (
      <Fragment key={product.id}>
        {product.downloads?.map((download) => (
          <Fragment key={download.id}>
            <a key={download.id} href={`${LINKS.DOWNLOAD}/${product.id}/${download.id}`} className={styles.download}>{download.fileName} ({humanFileSize(download.fileSize)})</a>
          </Fragment>
        ))}
      </Fragment>
    )
  }

  return (
    <div className={styles.root}>
      <Section>
        <CommunityTitle className={styles.title} title='My account' showOnDesktop />

        <div className={styles.accountFields}>
          <div>
            <Label>
              EMAIL ADDRESS
            </Label>
            <EditUserEmail email={account.email} userGuid={account.guid} />
          </div>

          <div>
            <Label>PASSWORD</Label>
            <CommunityButton fullWidthOnMobile={false} color='secondary_light' onClick={openChangePassword}>Change Password</CommunityButton>
            <EditUserPassword guid={account.guid} isOpen={isChangePasswordOpen} onClose={closeChangePassword} />
          </div>

          <div>
            <Label>NEWS AND UPDATES</Label>
            <CommunityToggle label='I want to receive news and updates' name='marketingEmails' value={account.consents?.marketing} disabled={isLoading} onToggle={toggleMarketingEmails} />
          </div>
        </div>
      </Section>

      <Section>
        <CommunityTitle className={styles.title} title='My subscriptions' showOnDesktop />

        <Label>SUBSCRIPTIONS</Label>
        <Subscriptions />
      </Section>

      <Section>
        <CommunityTitle className={styles.title} title='My orders' showOnDesktop />

        {orders.length ? (
          <div className={styles.table}>
            {orders.map((order) => (
              <div key={order.id} className={styles.order}>
                <div className={styles.row}>
                  <div className={styles.header}>ORDER #</div>
                  <div>{order.number}</div>
                </div>

                <div key={order.id} className={styles.row}>
                  <div className={styles.header}>DATE</div>
                  <div>{format(new Date(order.createdAt), 'y-MM-II HH:mm:ss')}</div>
                </div>

                {order.products.map((product) => {
                  return (
                    <Fragment key={product.id}>
                      <div key={order.id} className={styles.row}>
                        <div className={styles.header}>PRODUCT</div>
                        <div>
                          <a key={product.id} href={`${LINKS.SHOP}/${product.id}`}>{product.title}</a>
                        </div>
                      </div>

                      <div key={order.id} className={styles.row}>
                        <div className={styles.header}>Downloads</div>
                        <div>
                          {renderProductDownloads(product, order)}
                        </div>
                      </div>
                    </Fragment>
                  )
                })}

                <div key={order.id} className={styles.row}>
                  <div className={styles.header}>SUBTOTAL</div>
                  <div>{order.totalAmount.toFixed(2)} {order.currency}</div>
                </div>
              </div>
            ))}
          </div>
        ) : <div className={styles.emptyPlaceholder}>You don't have any completed orders</div>}
      </Section>

      <Section style={{ marginBottom: 0 }}>
        <CommunityTitle className={styles.title} title='Delete account' showOnDesktop />

        <Label>WARNING</Label>
        <p className={styles.warningMsg}>Now just a minute. Are you sure you want to delete your account and your music. This action can not be undone.</p>
        <div className={styles.deleteAccount}><CommunityButton fullWidthOnMobile={false} color='danger' type='outline' onClick={openDeleteAccount}>Delete account</CommunityButton></div>
        {!!account?.id && <DeleteAccount isOpen={isDeleteAccountOpen} closeModal={closeDeleteAccount} guid={account.guid} />}
      </Section>
    </div>
  )
}

export default Account
