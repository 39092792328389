// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "1260px";
var _3 = "1020px";
var _4 = "668px";
var _5 = "1550px";
var _6 = "pfx-CommunitySidebar-module__container--zxjW0";
var _7 = "1140px";
var _8 = "940px";
var _9 = "100%";
var _a = "15px";
var _b = "620px";
var _c = "#f53535";
var _d = "rgba(0,0,0,.2)";
var _e = "rgba(255,255,255,.38)";
var _f = ".38";
var _10 = "rgba(255,255,255,.2)";
var _11 = "#0025ff";
var _12 = "pfx-CommunitySidebar-module__footer--LySzA";
var _13 = "pfx-CommunitySidebar-module__footer-link--yqPhf";
var _14 = "pfx-CommunitySidebar-module__glitch-anim--uYmx8";
var _15 = "pfx-CommunitySidebar-module__glitch-anim-2--Td5UY";
var _16 = "#f60";
var _17 = "#41ead4";
var _18 = "#2cdd78";
var _19 = "#66ab23";
var _1a = "#f5f5f5";
var _1b = "#c3c3c3";
var _1c = "#ececec";
var _1d = "#252525";
var _1e = "#272727";
var _1f = "#c6c6c5";
var _20 = "#333";
var _21 = "#3c3c3c";
var _22 = "#9c9b9b";
var _23 = "#8c8c8c";
var _24 = "#6f6f6e";
var _25 = "#3c3c3b";
var _26 = "#373737";
var _27 = "#6a6a69";
var _28 = "#6f6f6f";
var _29 = "#9f9e9e";
var _2a = "#282828";
var _2b = "#828181";
var _2c = "#1d1d1d";
var _2d = "#979797";
var _2e = "#9c9b9b";
var _2f = "#a7a7a6";
var _30 = "#c6c6c5";
var _31 = "#d8d8d7";
var _32 = "#dddddc";
var _33 = "72px";
var _34 = "40px";
var _35 = "36px";
var _36 = "25px";
var _37 = "18px";
var _38 = "13px";
var _39 = "pfx-CommunitySidebar-module__inner-container--pcfl5";
var _3a = "#0025ff";
var _3b = "rgba(255,255,255,.12)";
var _3c = "#6535f5";
var _3d = "2px";
var _3e = "56px";
var _3f = "56px";
var _40 = "#41ead4";
var _41 = "#f60";
var _42 = "#f83600";
var _43 = "#881f00";
var _44 = "18px";
var _45 = "15px";
var _46 = "red";
var _47 = "pfx-CommunitySidebar-module__section-title--aLb4O";
var _48 = "13px";
var _49 = "rgba(255,255,255,.07)";
var _4a = "pfx-CommunitySidebar-module__tab--xemba";
var _4b = "pfx-CommunitySidebar-module__tab-active--ezJDW pfx-CommunitySidebar-module__tab--xemba";
var _4c = "pfx-CommunitySidebar-module__tab-group-header--eP6Cq";
var _4d = "pfx-CommunitySidebar-module__tab-link--_V5c4";
var _4e = "#35465c";
var _4f = "#55acee";
var _50 = "#fff";
var _51 = "#ffce36";
var _52 = "#ffcd00";
var _53 = "red";
export { _1 as "black", _2 as "breakLarge", _3 as "breakMedium", _4 as "breakSmall", _5 as "breakVLarge", _6 as "container", _7 as "containerLarge", _8 as "containerMedium", _9 as "containerMobile", _a as "containerPaddingMobile", _b as "containerSmall", _c as "danger", _d as "darkElement", _e as "disabled", _f as "disabledOpacity", _10 as "extraLightElement", _11 as "facebook", _12 as "footer", _13 as "footerLink", _14 as "glitchAnim", _15 as "glitchAnim2", _16 as "google", _17 as "green", _18 as "green2", _19 as "greenColor", _1a as "grey1", _1b as "grey10", _1c as "grey2", _1d as "grey25", _1e as "grey27", _1f as "grey3", _20 as "grey33", _21 as "grey3C", _22 as "grey4", _23 as "grey41", _24 as "grey5", _25 as "grey6", _26 as "grey61", _27 as "grey6A", _28 as "grey6F", _29 as "grey7", _2a as "grey8", _2b as "grey82", _2c as "grey9", _2d as "grey97", _2e as "grey9C", _2f as "greyA7", _30 as "greyC6", _31 as "greyD8", _32 as "greyDd", _33 as "header1", _34 as "header2", _35 as "header3", _36 as "header4", _37 as "header5", _38 as "header6", _39 as "innerContainer", _3a as "klein", _3b as "lightElement", _3c as "lilac", _3d as "menuBorder", _3e as "menuHeight", _3f as "menuMobileHeight", _40 as "ocean", _41 as "orange", _42 as "orange2", _43 as "orangeDark", _44 as "paragraph", _45 as "paragraphSmall", _46 as "red", _47 as "sectionTitle", _48 as "subHeaderSmall", _49 as "subtleLightElement", _4a as "tab", _4b as "tabActive", _4c as "tabGroupHeader", _4d as "tabLink", _4e as "tumblr", _4f as "twitter", _50 as "white", _51 as "yellow", _52 as "yellowDeep", _53 as "youtube" }
