import {Bem} from "@common/utils";
import './progress-bar.scss'

const cn = new Bem({
  name: 'carousel-progress',
  prefix: 'pfx-'
})

export default ({viewsCount, activeIndices, viewsToShow}) => {
  const [firstItem] = activeIndices
  const width = (parseInt(viewsToShow, 10) || 1) / viewsCount
  const left = firstItem / viewsCount
  
  return (
    <div className={cn('container')}>
      <div
        style={{
          width: `calc(${width * 100}% - ${24 / viewsCount}px)`,
          left: `calc(${left * 100}% - ${24 * firstItem / viewsCount}px)`
        }}
        className={cn('bar')}></div>

      <div
        style={{
          width: `calc(${width * 100}% - ${24 / viewsCount}px)`,
          left: (left * 100 - 100) + '%'
        }}
        className={cn('bar')}></div>

      <div className={cn('border')}></div>
    </div>
  )
}